import { SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_SUCCESSED, SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_REQUESTED } from './consignment-agreement.actions';
import * as _ from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

function* watchFetchProxyPagesRequest() {
  yield takeEvery(SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).user.saveCustomerHistoryPurchaseToken(action.data.retailerEmail, action.data).toPromise();
      yield put({ type: SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_SUCCESSED, data: result.access_token });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchProxyPagesRequest];
