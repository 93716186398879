import * as _ from 'lodash';
import { GET_PERCENTAGE_RETAILER_SUCCESSED } from './percentage.actions';

export const percentages = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case GET_PERCENTAGE_RETAILER_SUCCESSED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    default:
      return state;
  }
};
