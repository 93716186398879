import { DataOperators } from './../../../models/DatasetOperator';
import { DataObjectMarketplaces, DataTypes } from './../../../models/DatasetObject';
import * as _ from 'lodash';
export const listRouter = () => ['/', 'retailer', 'dataset'];
export const editRouter = (id) => ['/', 'retailer', 'dataset', 'edit', id];
export const listGroupRouter = (id) => ['/', 'retailer', 'dataset', id, 'groups'];
export const createGroupRouter = (id) => ['/', 'retailer', 'dataset', id, 'groups', 'create'];
export const addToGroupRouter = (id) => ['/', 'retailer', 'dataset', id, 'groups', 'attach'];
export const editGroupRouter = (id) => ['/', 'retailer', 'dataset', 'edit', id, 'groups'];

export const INPUT_TYPES: any = {
  text: 'text',
  number: 'number',
  search: 'search',
  date_time: 'date_time',
  mutiple_search: 'mutiple_search'
};

export const default_item = {
  label: 'NULL',
  value: 'null',
  id: 'null'
};

export const selectDatasetOperator = (dataset_operator, current_condition) => {
  let input_type = INPUT_TYPES.text;
  let is_dynamic_date = _.isObject(current_condition.value);
  const { data_type, is_equal_dropdown_enabled, label } = current_condition.dataset_object;
  if (_.includes([DataObjectMarketplaces.is_not_published, DataObjectMarketplaces.is_published, DataObjectMarketplaces.product_item_location], label)) {
    return _.assign(current_condition, { input_type: INPUT_TYPES.search });
  }
  switch (true) {
    case data_type === DataTypes.varchar && is_equal_dropdown_enabled && [DataOperators.is_equal_to, DataOperators.is_not_equal_to].indexOf(dataset_operator.label) > -1:
      input_type = INPUT_TYPES.search;
      break;
    case data_type === DataTypes.varchar && [DataOperators.not_in_list, DataOperators.in_list].indexOf(dataset_operator.label) > -1:
      input_type = INPUT_TYPES.mutiple_search;
      break;
    case data_type === DataTypes.timestamp:
      input_type = INPUT_TYPES.date_time;
      break;
    default:
      break;
  }
  if (_.isArray(current_condition.constants) && [DataOperators.is_equal_to, DataOperators.is_not_equal_to].indexOf(dataset_operator.label) < 0) {
    _.remove(current_condition.constants, (e: any) => e.label === default_item.label);
  } else if (_.isArray(current_condition.constants) && _.isNil(_.find(current_condition.constants, (e) => e.label === default_item.label))) {
    current_condition.constants.unshift(default_item);
  }
  return _.assign(current_condition, { input_type: input_type, is_dynamic_date: is_dynamic_date });
};

export const frequencies = [
  {
    label: '1 hour',
    value: 3600
  },
  {
    label: '3 hours',
    value: 10800
  },
  {
    label: '6 hours',
    value: 21600
  },
  {
    label: '12 hours',
    value: 43200
  },
  {
    label: 'daily',
    value: 86400
  }
];

export const days =  [
  {
    name:'Mon',
    value: '1',
    checked:false
  },
  {
    name:'Tue',
    value: '2',
    checked:false
  },
  {
    name:'Wed',
    value: '3',
    checked:false
  },
  {
    name:'Thu',
    value: "4",
    checked:false
  },
  {
    name:'Fri',
    value: "5",
    checked:false
  },
  {
    name:'Sat',
    value: "6",
    checked:false
  },
  {
    name:'Sun',
    value: "7",
    checked:false
  }
]
