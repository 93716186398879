import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../base.component';
import {
    RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST,
    RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_REQUEST,
    FETCH_REPURCHASE_REQUESTED
} from './repurchase.const';
import { ApiService } from '../../../api/api.service';

@Component({
  selector: 'app-repurchase',
  templateUrl: './repurchase.component.html',
  styleUrls: ['./repurchase.component.scss', '../../../../../node_modules/c3/c3.min.css', '../../../../assets/charts/radial/css/radial.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RepurchaseComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.Repurchase';
  isQuicksight = false;
  constructor(private api: ApiService) {
    super();
  }

  ngOnInit() {
    this.init();
    this.api.user.profile({}).subscribe((res) => {
      if (res.retailer.quicksight_repurchase_id) {
        this.isQuicksight = true;
        this.dispatch({ type: FETCH_REPURCHASE_REQUESTED });
      } else {
        this.isQuicksight = false;
        this.dispatch({ type: RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST, data: 12 });
        this.dispatch({ type: RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_REQUEST });
      }
    });
  }

  mapStateToProps(state) {
    return { payload: state.Retailer.Repurchase };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
