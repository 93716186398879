import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from './../../../../store/store.module';
import {
  FETCH_USER_DETAIL_REQUESTED,
  UPDATED_USER_REQUESTED,
  ATTACH_ROLE_TO_USER_REQUESTED,
  DETACH_ROLE_REQUESTED,
  ATTACH_ROLES_USER_REQUESTED,
  CHANGE_PASSWORD_BY_ADMIN_REQUESTED
} from './edit.actions';
import { USER_COMP } from '../user.const';
import * as _ from 'lodash';
import { AppInjector } from '../../../../app-injector';
import { FETCH_LANGUAGE_REQUESTED } from '../../language/list/list.actions';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy {
  public store;
  protected navigationSupscription: Subscription;
  protected roles = [];
  public data: any = {
    password: '',
    confirm_password: ''
  };

  constructor(private notification: NotificationService, private activatedRoute: ActivatedRoute, private router: Router, store: Store) {
    this.store = store.getInstance();
    this.navigationSupscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: FETCH_USER_DETAIL_REQUESTED,
          data: this.activatedRoute.snapshot.params.id,
          com: USER_COMP
        });
      }
    });
  }

  ngOnInit() {
    this.store.dispatch({ type: FETCH_LANGUAGE_REQUESTED });
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSupscription)) {
      this.navigationSupscription.unsubscribe();
    }
  }

  onSubmit(form) {
    const store = AppInjector.get(Store).getInstance();
    const data = (store as any).getState().Admin.Users.editUser.item;
    const roles = _.map(
      _.filter(store.getState().Admin.Users.editUser.roles, (item) => item.checked),
      (i: any) => {
        return i.id;
      }
    );
    data.role_ids = roles;
    data.user_id = this.activatedRoute.snapshot.params.id;
    data.is_do_not_receive_email = data.is_do_not_receive_email === "true" ? true : false;
    if (_.isEmpty(data.address)) {
      delete data.address;
    }
    delete data.retailer_services;

    if (form.valid) {
      store.dispatch({
        type: UPDATED_USER_REQUESTED,
        com: USER_COMP,
        data: data
      });
      // store.dispatch({
      //   type: ATTACH_ROLES_USER_REQUESTED,
      //   data: {
      //     user_id: this.activatedRoute.snapshot.params.id,
      //     role_ids: roles
      //   }
      // });
    }
  }

  attachRolesToUser() {
    this.store.dispatch({
      type: ATTACH_ROLE_TO_USER_REQUESTED,
      com: USER_COMP,
      data: {
        userId: this.activatedRoute.snapshot.params.id,
        roleId: (this.roles as any).getId()
      }
    });
  }

  detachRoleFromUser(role) {
    this.store.dispatch({
      type: DETACH_ROLE_REQUESTED,
      com: USER_COMP,
      data: {
        userId: this.activatedRoute.snapshot.params.id,
        roleId: role.getId()
      }
    });
  }

  changePassword() {
    this.store.dispatch({
      type: CHANGE_PASSWORD_BY_ADMIN_REQUESTED,
      userId: this.activatedRoute.snapshot.params.id,
      data: this.data
    });
  }
}
