import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import { RENDER_BULK_PUSH_FORM_REQUESTED, BULK_PUSH_PRODUCT_REQUESTED, DELIST_PRODUCT_REQUESTED, FETCH_BULK_OPERATION_DATA_REQUESTED, BULK_ENABLE_PRODUCT_REQUESTED } from './bulk-push.actions';
import { Validators } from '@angular/forms';
import { Select2, InputBase } from '@reflaunt/reactive-form';
import * as _ from 'lodash';
import { listRouter } from '../product.const';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { BaseComponent } from '../../../base.component';
import { NavigationEnd, Router } from '@angular/router';
import { FETCH_ASYNC_PROCESSES_REQUESTED } from '../async-process/asnyc-process.action';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bulk-push',
  templateUrl: './bulk-push.component.html',
  styleUrls: ['./bulk-push.component.scss']
})
export class BulkPushComponent extends BaseComponent implements OnInit {
  public count;
  public product_ids;
  public listRouter = listRouter();
  public reducer = 'Admin.Product.bulkPush';
  public paramsList = ['sort', 'constraints', 'page', 'per_page', 'search'];

  constructor(private router: Router) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let data: any = this.queryParser.parse(this.paramsList);
        this.dispatch({ type: FETCH_BULK_OPERATION_DATA_REQUESTED, data });
      }
    });
  }

  ngOnInit() {
    this.init();
    let inputs: InputBase<any>[] = [
      new Select2({
        key: 'marketplaces',
        label: 'Marketplaces',
        classes: ['col-12', 'rf-form-group', 'input--select2'],
        group_classes: ['col-12'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      })
    ];
    this.dispatch({
      type: RENDER_BULK_PUSH_FORM_REQUESTED,
      data: { inputs: inputs }
    });
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    this.product_ids = productSelected ? Object.keys(productSelected) : [];
    this.count = productSelected ? Object.keys(productSelected).length : 0;
  }

  onSubmit = (form) => {
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    const product_ids = Object.keys(productSelected);
    if (form.valid && product_ids && product_ids.length > 0) {
      this.dispatch({
        type: BULK_PUSH_PRODUCT_REQUESTED,
        data: { product_ids, marketplace_ids: form.value.marketplaces }
      });
    } else {
      AppInjector.get(NotificationService).show('warning', 'Product cannot be empty', 3000);
    }
    // tslint:disable-next-line:semicolon
  };

  customSubmit = (form) => {
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    const product_ids = Object.keys(productSelected);

    if (form.valid && product_ids && product_ids.length > 0) {
      this.dispatch({
        type: DELIST_PRODUCT_REQUESTED,
        data: { product_ids: Object.keys(productSelected), marketplace_ids: form.value.marketplaces }
      });
    } else {
      AppInjector.get(NotificationService).show('warning', 'Product cannot be empty', 3000);
    }
  };

  disable() {}

  bulkAction(action) {
    (Swal as any)
      .fire({
        title: `Are you sure to ${action} this products?`,
        // html: "<p>Politely explain to the seller what information is missing on this listing</p><p>NB: Please manually translate your message to seller's language</p>",
        // input: 'textarea',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      })
      .then((result) => {
        const productSelected = this.store.getState().Admin.Product.list.selected_products;
        const product_ids = Object.keys(productSelected);
        if (product_ids && product_ids.length > 0) {
          this.dispatch({
            type: BULK_ENABLE_PRODUCT_REQUESTED,
            data: { product_ids, action}
          });
        } else {
          AppInjector.get(NotificationService).show('warning', 'Product cannot be empty', 3000);
        }
      });
  }

  mapStateToProps(state) {
    return { payload: state.Admin.Product.bulkPush };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
