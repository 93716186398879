import { CREATE_PROVIDER_REQUESTED } from './create.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from './../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public store;
  public provider = {
    name: ''
  };

  constructor(store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {}

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      store.dispatch({ type: CREATE_PROVIDER_REQUESTED, data: this.provider });
    }
  }
}
