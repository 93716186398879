import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationRoutingModule } from './integration-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../../template/shared/shared.module';
import { AngularReactiveFormModule } from '@reflaunt/reactive-form';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ClickOutsideModule } from 'ng-click-outside';
import { ListComponent } from './list/list.component';
import { MarketplaceManagementComponent } from './marketplace-management/marketplace-management.component';
import { EditActionComponent } from './edit-action/edit-action.component';
import { DefaultConfigComponent } from './default-config/default-config.component';

@NgModule({
  imports: [
    CommonModule,
    IntegrationRoutingModule,
    HttpClientModule,
    FormsModule,
    DirectivesModule,
    PipesModule,
    NgSelectModule,
    SharedModule,
    ReactiveFormsModule,
    AngularReactiveFormModule,
    SweetAlert2Module,
    ClickOutsideModule
  ],
  declarations: [ListComponent, MarketplaceManagementComponent, EditActionComponent, DefaultConfigComponent]
})
export class IntegrationModule {}
