import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import ProductManagement from '../../../models/ProductManagement';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';
import { tap, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

@Injectable()
export class ProductManagementService extends ServiceProvider {
  public url = '/api/v1/admin/product-management';
  public model = ProductManagement;

  cancelSale(product_management_id, product_item_id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + product_management_id + '/product-item-id/' + product_item_id + '/cancel').pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  resolveOperation(id, data) {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + id + '/resolve', data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  recalculatePrice(id) {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + id + '/recalculate-price').pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  markAsFail(id) {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + id + '/mark-as-fail', {}).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  get(params = {}): Observable<any> {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(this.url), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) =>
        _.assign(
          {},
          {
            items: (result as any).data.map((item) => _.assign(new this.model(item), { user_inputted_price: item.user_inputted_price })),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
  getHistoryPush(params = {}): Observable<any> {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(this.url + '/get-history-push/paginate'), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) =>
        _.assign(
          {},
          {
            items: (result as any).data.map((item) => _.assign(new this.model(item), { user_inputted_price: item.user_inputted_price })),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
}
