import { FETCH_TAGS_SUCCEEDED, FETCH_TAGS_REQUESTED } from './list.actions';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery } from 'redux-saga/effects';

function* getTags(action) {
  try {
    let results = yield AppInjector.get(ApiService)
      .admin.tag.get(action.data)
      .toPromise();
    yield put({ type: FETCH_TAGS_SUCCEEDED, data: results.items, pagination: results.pagination });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchTagsRequest() {
  yield takeEvery(FETCH_TAGS_REQUESTED, getTags);
}

export default [watchFetchTagsRequest];
