export const CUSTOMER_PRODUCT_COMP = 'CUSTOMER_PRODUCT_COMP';

export const listRouter = id => {
  return ['/', 'customer', id, 'product'];
};
export const createRouter = id => {
  return ['/', 'customer', id, 'product', 'create'];
};

export const editRouter = (idRetailer, idProduct) => {
  return ['/', 'customer', idRetailer, 'product', 'edit', idProduct];
};
