import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../base.component';
import {
  RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_REQUEST,
  RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST,
  RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST,
  RETAILER_RESALE_GET_AVG_PRICE_DATA_REQUEST,
  RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_REQUEST,
  RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_REQUEST, FETCH_RESALE_ACTIVITTY_REQUESTED
} from './resale.const';
import { ApiService } from '../../../api/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resale',
  templateUrl: './resale.component.html',
  styleUrls: ['./resale.component.scss', '../../../../../node_modules/c3/c3.min.css', '../../../../assets/charts/radial/css/radial.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResaleComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.Resale';
  public isQuicksight = false;
  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService) {
    super();
  }

  ngOnInit() {
    this.init();
    this.api.user.profile({}).subscribe((res) => {
      if (res.retailer.quicksight_resale_activity_id) {
        this.isQuicksight = true;
        this.dispatch({ type: FETCH_RESALE_ACTIVITTY_REQUESTED });
      } else {
        this.isQuicksight = false;
        this.dispatch({ type: RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST, data: 12 });
        this.dispatch({ type: RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST, data: 12 });
        this.dispatch({ type: RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_REQUEST });
        this.dispatch({ type: RETAILER_RESALE_GET_AVG_PRICE_DATA_REQUEST });
        this.dispatch({ type: RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_REQUEST });
        this.dispatch({ type: RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_REQUEST });
      }
    });
  }

  get2Digital(number) {
    const data = number ? number : 0;
    return data.toFixed(2);
  }

  mapStateToProps(state) {
    return { payload: state.Retailer.Resale };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
