export const ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED = 'ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED';
export const ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_SUCCESSED = 'ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_SUCCESSED';
export const DELETE_SIZE_ATTRIBUTE_REQUESTED = 'DELETE_SIZE_ATTRIBUTE_REQUESTED';
export const DELETE_SIZE_ATTRIBUTE_SUCCESSED = 'DELETE_SIZE_ATTRIBUTE_SUCCESSED';
export const FETCH_SIZE_DICTIONARY_REQUESTED = 'FETCH_SIZE_DICTIONARY_REQUESTED';
export const FETCH_SIZE_DICTIONARY_SUCCESSED = 'FETCH_SIZE_DICTIONARY_SUCCESSED';
export const CREATE_SIZE_DICTIONARY_SUCCESSED = 'CREATE_SIZE_DICTIONARY_SUCCESSED';
export const RESET_SIZE_DICTIONARY = 'RESET_SIZE_DICTIONARY';
export const EDIT_SIZE_DICTIONARY_REQUESTED = 'EDIT_SIZE_DICTIONARY_REQUESTED';
export const EDIT_SIZE_DICTIONARY_SUCCEEDED = 'EDIT_SIZE_DICTIONARY_SUCCEEDED';
export const ADMIN_ATTRIBUTES_SIZE_FILTER_SELECTED = 'ADMIN_ATTRIBUTES_SIZE_FILTER_SELECTED';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_SUCCESS';
export const ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_SUCCESS';
export const ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_REQUESTED = 'ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_REQUESTED';
export const ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_SUCCESS = 'ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_SUCCESS';
export const ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_REQUESTED = 'ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_REQUESTED';
export const ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_SUCCESS = 'ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_SUCCESS';
export const ADMIN_ATTRIBUTES_SIZE_SYNCHRONISE_ATTRIBUTES_REQUEST = 'ADMIN_ATTRIBUTES_SIZE_SYNCHRONISE_ATTRIBUTES_REQUEST';
export const TOGGLE_SIZE_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_SIZE_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_SIZE_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_SIZE_DICTIONARY_VALIDATION_SUCCEEDED';
