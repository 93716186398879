export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED';
export const UPDATE_PRODUCT_SUCCEEDED = 'UPDATE_PRODUCT_SUCCEEDED';
export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_SUCCEEDED = 'DELETE_PRODUCT_SUCCEEDED';

export const RENDER_EDIT_PRODUCT_FORM_REQUESTED = 'RENDER_EDIT_PRODUCT_FORM_REQUESTED';
export const GET_PRODUCT_REQUESTED = 'GET_PRODUCT_REQUESTED';
export const GET_PRODUCT_SUCCEEDED = 'GET_PRODUCT_SUCCEEDED';
export const PRODUCT_DATA_MODIFIED = 'PRODUCT_DATA_MODIFIED';
export const GET_PRODUCT_IMAGES_SUCCEEDED = 'GET_PRODUCT_IMAGES_SUCCEEDED';

export const UPDATE_EDIT_PRODUCT_INPUT_OPTIONS = 'UPDATE_EDIT_PRODUCT_INPUT_OPTIONS';
export const FILL_PRODUCT_DETAIL_FORM = 'FILL_PRODUCT_DETAIL_FORM';
export const UPDATE_MARKETPLACE_INFORMATION_REQUESTED = 'UPDATE_MARKETPLACE_INFORMATION_REQUESTED';
export const UPDATE_MARKETPLACE_INFORMATION_SUCCESSED = 'UPDATE_MARKETPLACE_INFORMATION_SUCCESSED';
export const EDIT_PUBLISH_PRODUCT_REQUESTED = 'EDIT_PUBLISH_PRODUCT_REQUESTED';
export const EDIT_PUBLISH_PRODUCT_SUCCESSED = 'EDIT_PUBLISH_PRODUCT_SUCCESSED';
export const EDIT_FETCH_MARKETPLACE_SUCCEEDED = 'EDIT_FETCH_MARKETPLACE_SUCCEEDED';
export const EDIT_FETCH_MARKETPLACE_REQUESTED = 'EDIT_FETCH_MARKETPLACE_REQUESTED';
export const UPDATE_STATUS_PRODUCT_REQUESTED = 'UPDATE_STATUS_PRODUCT_REQUESTED';
export const CREATE_SHIPMENT_ITEM_REQUESTED = 'CREATE_SHIPMENT_ITEM_REQUESTED';
export const CREATE_SHIPMENT_ITEM_SUCCESSED = 'CREATE_SHIPMENT_ITEM_SUCCESSED';
export const GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED = 'GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED';
export const GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED = 'GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED';

export const GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED = 'GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED';
export const GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED = 'GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED';
export const GET_MORE_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED = 'GET_MORE_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED';
export const PAGINATE_PRODUCT_ITEM_REQUESTED = 'PAGINATE_PRODUCT_ITEM_REQUESTED';
export const PAGINATE_PRODUCT_ITEM_SUCCESSED = 'PAGINATE_PRODUCT_ITEM_SUCCESSED';

export const FETCH_ALL_STATUS_REQUESTED = 'FETCH_ALL_STATUS_REQUESTED';
export const FETCH_ALL_STATUS_SUCCESSED = 'FETCH_ALL_STATUS_SUCCESSED';

export const GET_HISTORY_PUSH_BY_PRODUCT_ID_REQUESTED = 'GET_HISTORY_PUSH_BY_PRODUCT_ID_REQUESTED';
export const GET_HISTORY_PUSH_BY_PRODUCT_ID_SUCCESSED = 'GET_HISTORY_PUSH_BY_PRODUCT_ID_SUCCESSED';

export const GET_MEDIA_SUPPORT_REQUESTED = 'GET_MEDIA_SUPPORT_REQUESTED';
export const GET_MEDIA_SUPPORT_SUCCEEDED = 'GET_MEDIA_SUPPORT_SUCCEEDED';

export const UPDATE_EDIT_PRODUCT_OUTLINES = 'UPDATE_EDIT_PRODUCT_OUTLINES';
export const ASSIGN_IMAGE_OUTLINES = 'ASSIGN_IMAGE_OUTLINES';
export const CANCEL_SALE_ON_PRODUCT_REQUESTED = 'CANCEL_SALE_ON_PRODUCT_REQUESTED';
export const CANCEL_SALE_ON_PRODUCT_SUCCESSED = 'CANCEL_SALE_ON_PRODUCT_SUCCESSED';
export const GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED = 'GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED';
export const GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_SUCCESSED = 'GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_SUCCESSED';
export const DELIST_PRODUCT_FROM_MARKETPLACE_REQUESTED = 'DELIST_PRODUCT_FROM_MARKETPLACE_REQUESTED';
export const DELIST_PRODUCT_FROM_MARKETPLACE_SUCCESSED = 'DELIST_PRODUCT_FROM_MARKETPLACE_SUCCESSED';

export const MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_REQUESTED = 'MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_REQUESTED';
export const MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_SUCCESSED = 'MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_SUCCESSED';

export const RESOLVE_PRODUCT_FROM_MARKETPLACE_REQUESTED = 'RESOLVE_PRODUCT_FROM_MARKETPLACE_REQUESTED';
export const RESOLVE_PRODUCT_FROM_MARKETPLACE_SUCCESSED = 'RESOLVE_PRODUCT_FROM_MARKETPLACE_SUCCESSED';

export const GET_PRODUCT_TRANSLATION_REQUEST = 'GET_PRODUCT_TRANSLATION_REQUEST';
export const GET_PRODUCT_TRANSLATION_SUCCESS = 'GET_PRODUCT_TRANSLATION_SUCCESS';

export const SAVE_COMMENT_FROM_SMART_PRICER = 'SAVE_COMMENT_FROM_SMART_PRICER';
export const SAVE_SMART_PRICER_COMMENT_REQUEST = 'SAVE_SMART_PRICER_COMMENT_REQUEST';

export const CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_REQUESTED = 'CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_REQUESTED';
export const CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_SUCCESSED = 'CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_SUCCESSED';
export const UPDATE_INPUTS_AFTER_CHECK_PRODUCT_ITEMS = 'UPDATE_INPUTS_AFTER_CHECK_PRODUCT_ITEMS';

export const CLONE_PRODUCT_REQUESTED = 'CLONE_PRODUCT_REQUESTED';
export const CLONE_PRODUCT_SUCCESSED = 'CLONE_PRODUCT_SUCCESSED';

export const UPDATE_INPUTS_RENDER_AFTER_CHECK_COLLECTION = 'UPDATE_INPUTS_RENDER_AFTER_CHECK_COLLECTION';

export const PRODUCT_DELETED_ACTION = 'PRODUCT_DELETED_ACTION';

export const GET_PRODUCT_CLONE_HISTORY_REQUESTED = 'GET_PRODUCT_CLONE_HISTORY_REQUESTED';
export const GET_PRODUCT_CLONE_HISTORY_SUCCESSED = 'GET_PRODUCT_CLONE_HISTORY_SUCCESSED';

export const UPDATE_FORM_INPUT = 'UPDATE_FORM_INPUT';
