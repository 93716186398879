import Model from './Model';
import NotificationAction from './NotificationAction';
import MarketplaceManagement from './MarketplaceManagement';

export default class NotificationActionMarketplaceManagement extends Model {
  constructor(options) {
    super();
    (this as any).notification_action = (d) => {
      return new NotificationAction(d.data);
    };
    (this as any).marketplace_management = (d) => {
      return new MarketplaceManagement(d.data);
    };
    this.bind(options);
  }
}
