import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, pipe } from 'rxjs';
import Product from '../../../models/Product';
import { HttpParams } from '@angular/common/http';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

@Injectable()
export class ProductService extends ServiceProvider {
  public url = '/api/v1/admin/products';
  public url1 = '/api/v1/products';
  public sendQuoteUrl = '/api/v1/admin/products/send-quote';
  public rejectBuybackUrl = '/api/v1/admin/products/reject-buyback';
  public finalQuoteBuybackUrl = '/api/v1/admin/products/send-final';
  public model = Product;

  get(params = {}): Observable<any> {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params });
    // const queryParams = new HttpParams({ fromObject: {constraints: JSON.stringify({image_validated_status: 'pending'})}});
    return this.http.get(this.apiUrl.getApiUrl(this.url), { params: queryParams }).pipe(
      tap((result) => {
        (result as any).data.forEach((product) => (product.images.data = _.sortBy(product.images.data, 'position')));
        this.preloader.hide();
      }),
      map((result) =>
        _.assign(
          {},
          {
            items: (result as any).data.map((item) => new this.model(item)),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  getMarketplace(params): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/marketplaces`, { params: queryParams }).pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new this.model(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getItemBySku(sku): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/sku/' + sku).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  syncRequest(id): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + id + '/sync-request').pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  cloneProduct(id, data?): Observable<any> {
    this.preloader.show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + id + '/clone', data).pipe(
      map((result) => {
        this.preloader.hide();
        return new Product((result as any).data);
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  // Changed the status
  updateProductBySku(sku, status, reason): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/sku/image-validated-status`, { sku: sku, status: status, image_validated_reason: reason }).pipe(
      map((result) => {
        return _.assign(
          {},
          {
            items: (result as any).data,
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        );
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  // Created a new endpoint
  updateProductById(id, status, reason): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/image-validated-status`, { image_validated_status: status, image_validated_reason: reason }).pipe(
      map((result) => {
        return _.assign(
          {},
          {
            items: (result as any).data
            // pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        );
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  updateImagesByPosition(sku, imagesArray): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/images/position`, imagesArray).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  changeStatus(id, params): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/status`, params).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  publish(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/publish`, params).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  syncMarketplaces(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/sync-marketplaces`, params).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  importFromCsv(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/import`, params).pipe(
      map((result) => {
        console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  previewCsv(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/preview`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  modifyQuantityCsv(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/modify-quantity-csv`, params).pipe(
      map((result) => {
        // console.log(result);
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getLandingPageProduct(id, param?): Observable<any> {
    param = param || {};
    let query = [];
    // tslint:disable-next-line:forin
    for (const prop in param) {
      query.push(prop + '=' + param[prop]);
    }
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/landing-page/' + id + '?' + _.join(query, '&')).pipe(
      tap((result) => {}),
      map((result) => new this.model((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }

  changeStatusProductToSold(id, data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url1) + '/' + id + '/marketplace', data).pipe(
      tap((result) => {}),
      map((result) => {
        return new this.model((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  delist(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/delist`, params).pipe(
      // map(result => {
      //   console.log(result);
      // }),
      catchError((error) => {
        throw error;
      })
    );
  }

  delistFromAllMarket(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/delist-all`, params).pipe(
      // map(result => {
      //   console.log(result);
      // }),
      catchError((error) => {
        throw error;
      })
    );
  }


  delistProductsFromSpecificMarketplace(params): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/delist/sync`, params).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      // map(result => {
      //   console.log(result);
      // }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  createOrderWithNonStockQuantity(id, data): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}` + '/' + id + '/marketplace/sold', data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
  bulkPush(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/bulk-push`, params).pipe(
      // map(result => {
      //   console.log(result);
      // }),
      catchError((error) => {
        throw error;
      })
    );
  }

  bulkAction(params): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/bulk-action`, params).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  bulkDiscount(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/bulk-discount`, params).pipe(
      // map(result => {
      //   console.log(result);
      // }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getMediaSupport(id, params): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url1) + '/' + id + '/media-support', params).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  setProductToSoldConfirm(id, data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/' + id + '/sold-confirmed', data).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  setProductToSoldConfirmProductItem(id, data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + id + '/sold-confirmed-product-item', data).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }

  setAllWareHouse(id, data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/set-all-warehouse`, data).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  disable(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/disable/${id}`), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  enable(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/enable/${id}`), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  completeListing(id, data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/complete-listing`, data).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  updateQuantity(id, data): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}` + '/' + id + '/quantity', data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  markAsTest(id, data): Observable<any> {
    this.preloader.show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}` + '/' + id + '/mark-as-test', data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  publish1(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/publish1`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  publish2(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/publish2`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  publish3(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/publish3`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  publish4(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/publish4`, params).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  sendQuote(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.sendQuoteUrl)}`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  finalQuoteBuyback(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.finalQuoteBuybackUrl)}`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  rejectBuyback(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.rejectBuybackUrl)}`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  getRetailerServiceByProductId(id): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${id}/retailer-service`).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  modifyDescription(data: { file_url: string }): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/modify-description`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  syncProducts(data): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/retailer-sync`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  removeBulkDiscount(data): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/remove-bulk-discount`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
}
