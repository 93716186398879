import {
  EDIT_ADMIN_LOCATION_SUCCESSED,
  FETCH_ADMIN_LOCATION_SUCCESSED,
  RENDER_EDIT_ADMIN_LOCATION_FORM_REQUESTED,
  FILL_ADMIN_LOCATION_DETAIL_FORM,
  UPDATE_ADMIN_LOCATION_DETAIL_FORM_INPUT_OPTIONS
} from './edit.actions';
import * as _ from 'lodash';
import { UpdateFormValue, UpdateInputsValue, support } from '@reflaunt/reactive-form';

export const edit = (state = { updated: false, fetched: false, inputs: [] }, action) => {
  switch (action.type) {
    case RENDER_EDIT_ADMIN_LOCATION_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_ADMIN_LOCATION_DETAIL_FORM:
      const data = _.assign({}, action.data, { address_id: action.data.address, country_id: action.data.country });
      return _.assign({}, state, { inputs: support.UpdateFormValue(state.inputs, data) });
    case UPDATE_ADMIN_LOCATION_DETAIL_FORM_INPUT_OPTIONS:
      return _.assign({}, state, { inputs: support.UpdateInputsValue(state.inputs, action.data) });
    case FETCH_ADMIN_LOCATION_SUCCESSED:
      return _.assign({}, state, { fetched: true, item: action.data });
    case EDIT_ADMIN_LOCATION_SUCCESSED:
      return _.assign({}, state, { updated: true });

    default:
      return state;
  }
};
