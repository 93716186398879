import { ORDER_STATUS_PROCESSED } from '../../../../models/Order';
export const RENDER_EDIT_ADMIN_ORDER_FORM_REQUESTED = 'RENDER_EDIT_ADMIN_ORDER_FORM_REQUESTED';
export const FILL_ADMIN_ORDER_DETAIL_FORM = 'FILL_ADMIN_ORDER_DETAIL_FORM';
export const FETCH_ADMIN_ORDER_REQUESTED = 'FETCH_ADMIN_ORDER_REQUESTED';
export const FETCH_ADMIN_ORDER_SUCCESSED = 'FETCH_ADMIN_ORDER_SUCCESSED';
export const EDIT_ADMIN_ORDER_REQUESTED = 'EDIT_ADMIN_ORDER_REQUESTED';
export const EDIT_ADMIN_ORDER_SUCCESSED = 'EDIT_ADMIN_ORDER_SUCCESSED';
export const DELETE_ADMIN_ORDER_REQUESTED = 'DELETE_ADMIN_ORDER_REQUESTED';
export const DELETE_ADMIN_ORDER_SUCCESSED = 'DELETE_ADMIN_ORDER_SUCCESSED';
export const UPDATE_ADMIN_ORDER_DETAIL_FORM_INPUT_OPTIONS = 'UPDATE_ADMIN_ORDER_DETAIL_FORM_INPUT_OPTIONS';
export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_PROCESSING = 'processing';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_FULLY_READY_TO_BE_SHIPPED = 'fully_ready_to_be_shipped';
export const ORDER_STATUS_PARTIALLY_READY_TO_BE_SHIPPED = 'partially_ready_to_be_shipped';
export const ORDER_STATUS_PARTIALLY_DELIVERY = 'partially_delivery';
export const ORDER_STATUS_FULLY_DELIVERY = 'fully_delivery';
export const ORDER_STATUS_CANCELLED = 'cancelled';
export const orderStatus = [
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_PROCESSED,
];

export const fetchAdminOrderRequested = (payload) => {
  return {
    type: FETCH_ADMIN_ORDER_REQUESTED,
    data: payload
  };
};

export const fetchAdminOrderSuccessed = (payload) => {
  return {
    type: FETCH_ADMIN_ORDER_SUCCESSED,
    data: payload
  };
};
