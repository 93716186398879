import * as _ from 'lodash';
import { RENDER_EDIT_ATTRIBUTE_FORM_DATA_SUCCEEDED } from './edit.action';
import { Dropdown, TextBox } from '@reflaunt/reactive-form';

export const Edit = (
  state = {
    updated: false
  },
  action
) => {
  switch (action.type) {
    case RENDER_EDIT_ATTRIBUTE_FORM_DATA_SUCCEEDED:
      let inputs = action.data.inputs;
      let attribute = action.data.attribute;
      for (let input of inputs) {
        const value = attribute[input.key];
        if (value) {
          if (input.controlType === 'textbox') {
            input.value = value;
          } else if (input.controlType === 'dropdown') {
            input.value = input.options.find(s => s.value === value);
          }
        }
      }
      return _.assign({}, state, { inputs: action.data.inputs });
    default:
      return state;
  }
};
