import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Size from '../../models/Size';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import User from '../../models/User';
import { PreloaderService } from '../../common/services/preloader/preloader.service';

@Injectable()
export class SizeService extends ServiceProvider {
  public url = '/api/v1/sizes';
  public model = Size;

  getSizesUnit(type = ''): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + `/unit?type=${type}`).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }

  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
