import { API_CALL_ERROR } from './../../../store/action';
import { AppInjector } from './../../../app-injector';
import { takeEvery, put } from 'redux-saga/effects';
import { ApiService } from '../../../api/api.service';
import {
  GET_TRANSACTION_BY_ID_REQUESTED,
  GET_TRANSACTION_BY_ID_SUCCEEDED,
  ADD_PAYMENT_REQUESTED,
  ADD_PAYMENT_SUCCEEDED,
  GET_BANK_ACCOUNT_DETAIL_REQUESTED,
  SAVE_BANK_ACCOUNT_DETAIL_REQUESTED,
  UPDATE_BANK_ACCOUNT_FROM_REQUESTED,
  RENDER_FILL_BANK_ACCOUNT_FORM_REQUESTED,
  UPDATE_INPUTS_ACCOUNT_PAYMENT_FORM_REQUESTED
} from './checkout-payment.actions';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { Countries } from '../../admin/user/address/countries';
import * as _ from 'lodash';

function* watchChoosePaymentRequest() {
  yield takeEvery(ADD_PAYMENT_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      let result = yield api.wallet.choosePayment(action.data).toPromise();
      yield put({ type: ADD_PAYMENT_SUCCEEDED, data: result });
      AppInjector.get(PreloaderService).hide();
      window.scroll(0, 0);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetTransactionByIDRequest() {
  yield takeEvery(GET_TRANSACTION_BY_ID_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      let result = yield api.transactionHistory.getLandingPageTransaction(action.transaction_id).toPromise();
      yield put({ type: GET_TRANSACTION_BY_ID_SUCCEEDED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetBankAccountDetailRequest() {
  yield takeEvery(GET_BANK_ACCOUNT_DETAIL_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      let result = yield api.user.getBankAccountByToken(action.data.token, action.data).toPromise();
      if (Object.keys(result).length > 0) {
        yield put({ type: UPDATE_BANK_ACCOUNT_FROM_REQUESTED, data: result });
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSaveBankAccountDetailRequest() {
  yield takeEvery(SAVE_BANK_ACCOUNT_DETAIL_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      let result = yield api.user.savePaymentAccountByToken(action.data_transaction.token, action.data_bank_account).toPromise();
      if (result) {
        yield put({ type: ADD_PAYMENT_REQUESTED, data: action.data_transaction });
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRenderAccountPaymnetFormRequest() {
  yield takeEvery(RENDER_FILL_BANK_ACCOUNT_FORM_REQUESTED, function*(action: any) {
    const COUNTRIES = Countries;
    const optionsData = {
      country: _.map(COUNTRIES, (item, key) => _.assign(item, { value: item.name, label: item.name }))
    };
    yield put({
      type: UPDATE_INPUTS_ACCOUNT_PAYMENT_FORM_REQUESTED,
      data: optionsData
    });
  });
}

export default [
  watchChoosePaymentRequest,
  watchGetTransactionByIDRequest,
  watchGetBankAccountDetailRequest,
  watchSaveBankAccountDetailRequest,
  watchRenderAccountPaymnetFormRequest
];
