import { GET_PROVIDER_REQUESTED, EDIT_PROVIDER_REQUESTED } from './edit.actions';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from './../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public store;

  constructor(private route: ActivatedRoute, store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.store.dispatch({ type: GET_PROVIDER_REQUESTED, data: this.getItemId() });
  }

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      store.dispatch({ type: EDIT_PROVIDER_REQUESTED, data: (store as any).getState().Admin.Provider.edit.item });
    }
  }

  getItemId() {
    return this.route.snapshot.paramMap.get('id');
  }
}
