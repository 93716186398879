import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MarketplaceRetailerConfiguration } from '../../../models/MarketplaceRetailerConfiguration';
import { AppInjector } from '../../../app-injector';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class marketplaceRetailerConfigService extends ServiceProvider {
  public url = '/api/v1/admin/marketplace-management-configuration';
  public model = MarketplaceRetailerConfiguration;


  configuration(marketplaceId, data?) {
    return AppInjector.get(HttpClient)
      .put(this.apiUrl.getApiUrl(`${this.url}/marketplace/${marketplaceId}/configuration`), data)
      .pipe(
        map((result) => _.map((result as any).data, (item) => new this.model(item)))
      );
  }


}
