import * as _ from 'lodash';
import { GET_DETAIL_EMAIL_SUCCEEDED } from './detail-email.actions';

export const detailEmail = (state = { fetched: false }, action) => {
  switch (action.type) {
    case GET_DETAIL_EMAIL_SUCCEEDED:
      return _.assign({}, state, { fetched: true, data: action.data, item: action.result });
    default:
      return state;
  }
};
