import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {
  BULK_UPDATE_ISSUE_REQUESTED,
  EXPORT_PRODUCT_ACTIVITY_LOG_REQUESTED,
  FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED,
  MARK_ISSUE_AT_IT_REQUESTED,
  REPROCESS_ISSUE_REQUESTED,
  SKIP_ISSUE_REQUESTED,
  TOGGLE_ALL_ISSUES,
  TOGGLE_ISSUE
} from './event.actions';
import * as _ from 'lodash';
import { ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_REQUEST } from '../../attributes/attributes.action';
import { DateTimePicker, Dropdown, InputBase } from '@reflaunt/reactive-form';
import { ACTIVITY_TYPE_PRODUCT, ACTIVITY_TYPE_SHIPMENT } from '../../../../../app/models/ActivityLog';
import { AppInjector } from './../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent extends BaseComponent implements OnInit, AfterViewInit {
  public reducer = 'Admin.Product.Event';
  search_value: String;
  searchTypes = [
    { label: 'Key', value: 'key' },
    { label: 'Message', value: 'message' },
    { label: 'Request ID', value: 'request_id' }
  ];
  search_type = 'key';

  public filters = {
    type: null,
    closed: null,
    it_issue: null,
    email: null,
    multi_date: null,
    last_operation: null,
    last_operation_status: null
  };
  public supParams = ['sort', 'constraints', 'page', 'per_page', 'search', 'key', 'search_type', 'search_value', ...Object.keys(this.filters)];
  public dateFilterType = [
    { label: '', value: '' },
    { label: 'Created date', value: 'created_date' },
    { label: 'Issue close date', value: 'issue_close_date' }
  ];
  public filter_date: InputBase<any>[] = [
    new Dropdown({
      key: 'date_filter_type',
      label: 'Date type',
      options: this.dateFilterType,
      classes: ['col-4', 'rf-form-group', 'custom-css', 'input--select'],
      group_classes: ['col-12'],
      group: 1
    }),
    new DateTimePicker({
      key: 'start_date',
      label: 'From',
      classes: ['col-lg-3'],
      group_classes: ['col-lg-12'],
      monthNavigator: true,
      yearNavigator: true,
      showIcon: true,
      group: 1
    }),
    new DateTimePicker({
      key: 'end_date',
      label: 'To',
      classes: ['col-lg-3'],
      group_classes: ['col-lg-12'],
      monthNavigator: true,
      yearNavigator: true,
      showIcon: true,
      group: 1
    })
  ];
  ACTIVITY_TYPE_PRODUCT = ACTIVITY_TYPE_PRODUCT;
  ACTIVITY_TYPE_SHIPMENT = ACTIVITY_TYPE_SHIPMENT;
  @ViewChild('search_date', { static: false }) public searchDateForm;

  constructor(private route: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.setupFilter();
        this.search_type = this.activatedRoute.snapshot.queryParams.search_type || 'key';
        this.search_value = this.activatedRoute.snapshot.queryParams.search_value || undefined;
        this.store.dispatch({
          type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED,
          data: this.getParams()
        });
        this.store.dispatch({
          type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_REQUEST,
          data: {
            roles: 'marketplace',
            includes: 'marketplace'
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  getParams() {
    let data: any = this.queryParser.parse(this.supParams);
    return _.assign({}, data, {
      constraints: JSON.stringify({
        ...this.filters
      })
    });
  }

  toggleIssue(item) {
    if (item.issue_tracking) {
      this.store.dispatch({
        type: TOGGLE_ISSUE,
        data: item.id
      });
    }
  }

  toggleAllIssues() {
    this.store.dispatch({
      type: TOGGLE_ALL_ISSUES
    });
  }

  isChecked(item) {
    return this.payload.selected[item.id] === true;
  }

  getChecked() {
    return Object.keys(this.payload.selected).filter((key) => this.payload.selected[key] === true);
  }

  getCheckedIssues() {
    const issueTrackingObject = this.getChecked().reduce((acc, checked) => {
      const issueTracking = this.payload.issue_type_store[checked];
      if (issueTracking) {
        acc[issueTracking.id] = true;
      }
      return acc;
    }, {});
    return Object.keys(issueTrackingObject);
  }

  getCheckedTotal() {
    return this.getChecked().length;
  }

  isLessThanMax(max) {
    const checked = this.getChecked();
    return checked.length < max && checked.length > 0;
  }

  canBulkSkip() {
    return !this.getChecked().find((checked) => {
      const issueTracking = this.payload.issue_type_store[checked];
      if (issueTracking) {
        if (issueTracking.is_closed === true) {
          return true;
        }
      }
      return false;
    });
  }

  canBulkReprocess() {
    return !this.getChecked().find((checked) => {
      const issueTracking = this.payload.issue_type_store[checked];
      if (issueTracking) {
        if (issueTracking.issue_type === 'product_publish_failed') {
          return true;
        }
        if (issueTracking.is_closed === true) {
          return true;
        }
      }
      return false;
    });
  }

  bulkReprocess() {
    this.store.dispatch({
      type: BULK_UPDATE_ISSUE_REQUESTED,
      data: {
        operation: 'reprocess',
        ids: this.getCheckedIssues()
      }
    });
  }

  bulkSkip() {
    this.store.dispatch({
      type: BULK_UPDATE_ISSUE_REQUESTED,
      data: {
        operation: 'skip',
        ids: this.getCheckedIssues()
      }
    });
  }

  bulkMarkAsIt() {
    this.store.dispatch({
      type: BULK_UPDATE_ISSUE_REQUESTED,
      data: {
        operation: 'mark_as_it',
        ids: this.getCheckedIssues()
      }
    });
  }

  hasResolutionUrl(item) {
    return !!(item.issue_tracking && item.issue_tracking.resolution_url);
  }

  openResolutionUrl(item) {
    if (this.hasResolutionUrl(item)) {
      window.open(item.issue_tracking.resolution_url, '_blank');
    }
  }

  updateMultiDateFilterByData(data) {
    const form = this.searchDateForm.form;
    const multiDate = data['multi_date'];
    if (!multiDate) {
      return false;
    }
    const typeSplit = multiDate.split(':');
    if (typeSplit.length <= 1) {
      return false;
    }

    const val = this.dateFilterType.find((option) => option.value === typeSplit[0]);
    if (!val) {
      return false;
    }

    const dates = typeSplit.slice(1).join('').split('-');
    if (dates.length <= 1) {
      return false;
    }

    if (!dates[0] && !dates[1]) {
      return false;
    }

    form.get('date_filter_type').setValue(val);

    if (dates[0]) {
      setTimeout(() => {
        form.get('start_date').setValue(new Date(dates[0]));
      });
    }

    if (dates[1]) {
      setTimeout(() => {
        form.get('end_date').setValue(new Date(dates[1]));
      });
    }

    return true;
  }

  setupMultiDateFilter(data) {
    if (this.searchDateForm && this.searchDateForm.form) {
      const isUpdated = this.updateMultiDateFilterByData(data);
      if (!isUpdated) {
        const form = this.searchDateForm.form;
        form.get('date_filter_type').setValue(null);
        setTimeout(() => {
          form.get('start_date').setValue(' ');
          form.get('end_date').setValue(' ');
        });
      }
    }
  }

  setupFilter() {
    let data: any = this.queryParser.parse(this.supParams);
    this.setupMultiDateFilter(data);
    Object.keys(this.filters).forEach((key) => {
      if (data[key]) {
        this.filters[key] = data[key];
      } else {
        this.filters[key] = null;
      }
    });
  }

  reprocessIssue(id) {
    this.store.dispatch({
      type: REPROCESS_ISSUE_REQUESTED,
      data: id,
      params: this.getParams()
    });
  }

  skipIssue(id) {
    this.store.dispatch({
      type: SKIP_ISSUE_REQUESTED,
      data: id,
      params: this.getParams()
    });
  }

  markIssueAsIt(id) {
    this.store.dispatch({
      type: MARK_ISSUE_AT_IT_REQUESTED,
      data: id,
      params: this.getParams()
    });
  }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return { payload: state.Admin.Product.Event };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }

  filterEvent(type, value) {
    this.filters[type] = value || null;
    let queryParams = this.activatedRoute.snapshot.queryParams;
    queryParams = _.assign({}, queryParams, { ...this.filters });
    let params = _.assign({}, this.activatedRoute.snapshot.params, { queryParams });
    this.route.navigate([window.location.pathname], {...params, queryParamsHandling: 'merge'});
  }

  filterDateEvent(reactiveForm) {
    const form = reactiveForm.form;
    const type = form.value.date_filter_type && form.value.date_filter_type.value ? form.value.date_filter_type.value : null;
    let start_date = form.value.start_date || '';
    let end_date = form.value.end_date || '';
    if (start_date === ' ') {
      start_date = '';
    }
    if (end_date === ' ') {
      end_date = '';
    }
    
    if (type && (start_date || end_date)) {
      this.filterEvent('multi_date', type + ':' + start_date + '-' + end_date);
    } else {
      this.filterEvent('multi_date', null);
    }
  }

  exportEventsAsCsv(reactiveForm) {
    const form = reactiveForm.form;
    const type = form.value.date_filter_type && form.value.date_filter_type.value ? form.value.date_filter_type.value : null;
    
    const start_date = form.value.start_date || '';
    const end_date = form.value.end_date || '';

    if (!start_date || !end_date) {
      AppInjector.get(NotificationService).show('warning', 'Please select the date from since when you want to export the report.');
      return;      
    }

    this.store.dispatch({
      type: EXPORT_PRODUCT_ACTIVITY_LOG_REQUESTED,
      data: this.getParams(),
      filters: {
        ...this.filters,
        date_field: type,
        start_date: start_date.getTime(),
        end_date: end_date.getTime()
      }
    });
  }

  changeCustomType(search_type) {
    this.search_type = search_type;
  }
  searchByType(form) {
    console.log(form.value);

    console.log(this.search_type);
    this.route.navigate([], {
      queryParams: {
        search_type: this.search_type !== 'undefined' ? this.search_type : undefined,
        search_value: form.value.search_value !== 'undefined' ? form.value.search_value : undefined
      },
      queryParamsHandling: 'merge'
    });
  }
}
