import { environment } from "../../../../environments/environment";

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  states: string[];
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  class: string;
  children?: ChildrenItems[];
  includes: {};
}

export interface Menu {
  label: string;
  class: string;
  main: MainMenuItems[];
}

export const AppMenunItems = [
  {
    label: 'Analytics',
    main: [
      {
        states: ['/', 'retailer', 'dashboard'],
        short_label: 'RS',
        name: 'Dashboard',
        type: 'link',
        icon: 'fa fa-star-o',
        permissions: ['analytic.view'],
        level: 1
      },
      {
        states: ['/', 'retailer', 'resale'],
        short_label: 'DB',
        name: 'Resale activity',
        type: 'link',
        icon: 'fa fa-star-o',
        permissions: ['analytic.view'],
        level: 1
      },
      {
        states: ['/', 'retailer', 'second-hand-sales'],
        short_label: 'SH',
        name: '2nd Hand Sales',
        type: 'link',
        icon: 'fa fa-life-ring',
        permissions: ['analytic.view'],
        level: 1
      }
      // {
      //   states: ['/', 'retailer', 'repurchase'],
      //   short_label: 'RC',
      //   name: 'Repurchase',
      //   type: 'link',
      //   icon: 'fa fa-money',
      //   permissions: ['analytic.view'],
      //   level: 1
      // }
      // {
      //   states: ['/', 'retailer', 'acquisition'],
      //   short_label: 'AS',
      //   name: 'Acquisition',
      //   type: 'link',
      //   icon: 'fa fa-american-sign-language-interpreting',
      //   permissions: ['analytic.view'],
      //   level: 1
      // },
      // {
      //   states: ['/', '5'],
      //   short_label: 'OSP',
      //   name: 'On site perfomance',
      //   type: 'link',
      //   icon: 'fa fa-steam',
      //   permissions: ['analytic.view'],
      //   level: 1
      // }
    ]
  },

  {
    label: 'Products',
    main: [
      {
        states: ['/', 'admin', 'product'],
        short_label: 'P',
        name: 'Products list',
        type: 'link',
        linkWithQuery: 'true',
        icon: 'fa fa-shopping-bag',
        permissions: ['product.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'attributes'],
        short_label: 'P',
        name: 'Attribute matching',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'attributes-retailer'],
        short_label: 'P',
        name: 'Attribute matching',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['retailer.attribute.view'],
        level: 1
      },
      {
        states: ['/', 'retailer', 'CURRENT_USER_ID', 'product'],
        short_label: 'P',
        name: 'Products list',
        type: 'link',
        icon: 'fa fa-shopping-bag',
        permissions: ['product.own.view'],
        level: 1
      },
      {
        states: ['/', 'customer', 'CURRENT_USER_ID', 'product'],
        short_label: 'P',
        name: 'Products list',
        type: 'link',
        icon: 'fa fa-shopping-bag',
        permissions: ['product.customer.view'],
        level: 1
      },
      {
        states: ['/', 'retailer', 'dataset'],
        short_label: 'D',
        name: 'Dataset',
        type: 'link',
        icon: 'fa fa-share-alt',
        permissions: ['dataset.view'],
        level: 1
      }
    ]
  },
  {
    label: 'Orders',
    main: [
      {
        states: ['/', 'admin', 'order'],
        short_label: 'R',
        name: 'Orders',
        type: 'link',
        icon: 'fa fa-file',
        level: 1,
        permissions: ['order.view']
      },
      {
        states: ['/', 'admin', 'negotiations', 'products'],
        short_label: 'R',
        name: 'Negotiations',
        type: 'link',
        icon: 'fa fa-refresh',
        level: 1,
        permissions: ['order.view']
      }
    ]
  },
  {
    label: 'Logistic',
    main: [
      {
        states: ['/', 'admin', 'collection'],
        short_label: 'R',
        name: 'Collection',
        type: 'link',
        icon: 'fa fa-file',
        permissions: ['logistic.collection.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'shipment'],
        short_label: 'S',
        name: 'Shipment',
        type: 'link',
        icon: 'fa fa-truck',
        level: 1,
        permissions: ['logistic.shipment.view']
      },
      {
        states: ['/', 'admin', 'locations'],
        short_label: 'S',
        name: 'Location',
        type: 'link',
        icon: 'fa fa-file',
        level: 1,
        permissions: ['logistic.location.view']
      },
      {
        states: ['/', 'admin', 'items-cost'],
        short_label: 'S',
        name: 'Items cost',
        type: 'link',
        icon: 'fa fa-truck',
        level: 1,
        permissions: ['logistic.location.view']
      },
      {
        states: ['/', 'admin', 'fulfilments'],
        short_label: 'D',
        name: 'Fulfilment',
        type: 'link',
        icon: 'fa fa-refresh',
        level: 1,
        permissions: ['logistic.fulfilment.view']
      }
    ]
  },
  {
    label: 'Accounting',
    main: [
      {
        states: ['/', 'admin', 'payment-to-send'],
        short_label: 'R',
        name: 'Payments to send',
        type: 'external',
        external: environment.appUrl + '/v2/finance/payment-to-send',
        icon: 'fa fa-file',
        level: 1,
        permissions: ['order.view'],
      },
      {
        states: ['/', 'admin', 'payment-to-collect'],
        short_label: 'R',
        name: 'Payments to collect',
        type: 'link',
        icon: 'fa fa-file',
        level: 1,
        permissions: ['order.view'],
      },
      {
        states: ['/', 'admin', 'payment-to-collect-grouped'],
        short_label: 'R',
        name: 'Group payments to collect',
        type: 'link',
        icon: 'fa fa-file',
        level: 1,
        permissions: ['order.view'],
      },
      {
        states: ['/', 'admin', 'order-item'],
        short_label: 'R',
        name: 'Order Item P&L',
        type: 'link',
        icon: 'fa fa-file',
        level: 1,
        permissions: ['order.view']
      }
    ]
  },
  {
    label: 'All User',
    class: 'all_user',
    main: [
      {
        states: ['/', 'admin', 'user'],
        short_label: 'U',
        name: 'User',
        type: 'link',
        icon: 'fa fa-users',
        permissions: ['user.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'retailer'],
        short_label: 'R',
        name: 'Retailer',
        type: 'link',
        icon: 'fa fa-users',
        permissions: ['user.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'logistic'],
        short_label: 'R',
        name: 'Logistic',
        type: 'link',
        icon: 'fa fa-users',
        permissions: ['logistic.location.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'customer'],
        short_label: 'C',
        name: 'Customer',
        type: 'link',
        icon: 'fa fa-users',
        permissions: ['user.view'],
        level: 1
      },
      {
        states: ['/', 'admin', 'marketplace'],
        short_label: 'C',
        name: 'Marketplace',
        type: 'link',
        icon: 'fa fa-users',
        class: 'all_user_marketplace',
        permissions: ['user.view'],
        // permissions: ['user.view', 'retailer.view.marketplace'],
        level: 1
      }
    ]
  },
  // {
  //   label: 'Dataset',
  //   main: [
  //     {
  //       states: ['/', 'retailer', 'dataset'],
  //       short_label: 'D',
  //       name: 'Dataset',
  //       type: 'link',
  //       icon: 'fa fa-share-alt',
  //       permissions: ['dataset.view']
  //     }
  //   ]
  // },
  {
    label: 'Notification',
    main: [
      {
        states: ['/', 'admin', 'notifications'],
        short_label: 'D',
        name: 'Notifications',
        type: 'link',
        icon: 'fa fa-envelope-o',
        level: 1,
        permissions: ['action.view']
      },
      {
        states: ['/', 'admin', 'email'],
        short_label: 'D',
        name: 'Emailling',
        type: 'link',
        icon: 'fa fa-envelope-o',
        level: 1,
        permissions: ['action.view']
      },
      {
        states: ['/', 'admin', 'configuration'],
        short_label: 'C',
        name: 'Configuration',
        type: 'link',
        icon: 'fa fa-cogs',
        level: 1,
        permissions: ['action.view']
      },
      {
        states: ['/', 'retailer', 'email'],
        short_label: 'D',
        name: 'Emailling',
        type: 'link',
        icon: 'fa fa-envelope-o',
        level: 1,
        permissions: ['notification.view']
      }
    ]
  },
  {
    label: 'Vouchers',
    main: [
      {
        states: ['/', 'customer', 'voucher'],
        short_label: 'V',
        name: 'Voucher',
        type: 'link',
        icon: 'fa fa-barcode',
        level: 1,
        permissions: ['voucher.view']
      }
    ]
  },{
    label: 'Customers',
    main: [
      {
        states: ['/', 'retailer', 'CURRENT_USER_ID', 'user'],
        short_label: 'U',
        name: 'Customer list',
        type: 'link',
        icon: 'fa fa-users',
        permissions: ['customer.view'],
        level: 1
      }
    ]
  },
  {
    label: 'Attributes',
    main: [
      {
        states: ['/', 'attribute', 'categories'],
        short_label: 'R',
        name: 'Category',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.create'],
        level: 2
      },
      {
        states: ['/', 'attribute', 'colors'],
        short_label: 'R',
        name: 'Color',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.create'],
        level: 2
      },
      {
        states: ['/', 'attribute', 'conditions'],
        short_label: 'R',
        name: 'Condition',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.create'],
        level: 2
      },
      {
        states: ['/', 'attribute', 'designers'],
        short_label: 'R',
        name: 'Designer',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.create'],
        level: 2
      },
      {
        states: ['/', 'attribute', 'materials'],
        short_label: 'R',
        name: 'Material',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.create'],
        level: 2
      },
      {
        states: ['/', 'attribute', 'sizes'],
        short_label: 'R',
        name: 'Size',
        type: 'link',
        icon: 'fa fa-cube',
        permissions: ['product.attribute.create'],
        level: 2
      },
      {
        states: ['/', 'admin', 'tag'],
        short_label: 'T',
        name: 'Tag',
        type: 'link',
        icon: 'fa fa-tag',
        permissions: ['tag.view'],
        level: 2
      },
      {
        states: ['/', 'admin', 'currency'],
        short_label: '$',
        name: 'Currency',
        type: 'link',
        icon: 'fa fa-money',
        permissions: ['currency.view'],
        level: 2
      },
    ]
  },
  {
    label: 'Status',
    main: [
      {
        states: ['/', 'admin', 'status'],
        short_label: 'D',
        name: 'Status',
        type: 'link',
        icon: 'fa fa-circle',
        permissions: ['status.view'],
        level: 2
      }
    ]
  },
  {
    label: 'Language',
    main: [
      {
        states: ['/', 'admin', 'language'],
        short_label: 'D',
        name: 'Language',
        type: 'link',
        icon: 'fa fa-circle',
        permissions: ['status.view'],
        level: 2
      }
    ]
  },
  {
    label: 'Integration',
    main: [
      {
        states: ['/', 'integration', 'create'],
        short_label: 'D',
        name: 'Integration',
        type: 'link',
        icon: 'fa fa-share-alt',
        permissions: ['integration.view'],
        roles: ['retailer'],
        level: 2
      },
      {
        states: ['/', 'admin', 'integration'],
        short_label: 'D',
        name: 'Integration',
        type: 'link',
        icon: 'fa fa-share-alt',
        permissions: ['integration.view'],
        roles: ['admin'],
        level: 2
      }
    ]
  },
  {
    label: 'Blockchain',
    main: [
      {
        states: ['/', 'blockchain'],
        short_label: 'CF',
        name: 'Certificates',
        type: 'link',
        icon: 'fa fa-certificate',
        permissions: ['certificate.view'],
        level: 1
      }
    ]
  },
  {
    label: 'Users',
    main: [
      {
        states: ['/', 'test'],
        short_label: 'CF',
        name: 'None',
        type: 'link',
        icon: 'fa fa-certificate',
        permissions: ['retailer.view'],
        level: 2
      }
    ]
  },

  {
    label: 'ACL',
    main: [
      {
        main_state: 'acl',
        states: ['/', 'admin', 'acl', 'roles'],
        short_label: 'R',
        name: 'Role',
        type: 'link',
        icon: 'fa fa-book',
        permissions: ['role.view'],
        level: 2
      }
    ]
  },
  {
    label: 'History',
    main: [
      {
        states: ['/', 'customer', 'transaction-history'],
        short_label: 'TH',
        name: 'Transaction',
        type: 'link',
        icon: 'fa fa-history',
        permissions: ['product.customer.view'],
        level: 1
      }
    ]
  },
  {
    label: 'Campaign',
    main: [
      {
        states: ['/', 'retailer', 'percentage'],
        short_label: 'P',
        name: 'Percentage',
        type: 'link',
        icon: 'fa fa-percent',
        permissions: ['campaign.view'],
        level: 1
      }
    ]
  },
  {
    label: 'Api',
    main: [
      {
        states: ['/', 'retailer', 'endpoint'],
        short_label: 'E',
        name: 'Endpoint',
        type: 'link',
        icon: 'fa fa-hourglass-end',
        permissions: ['endpoint.retailer.view'],
        level: 2
      },
      {
        states: ['/', 'retailer', 'credential'],
        short_label: 'C',
        name: 'Credential',
        type: 'link',
        icon: 'fa fa-shield',
        permissions: ['oauth_client.retailer.view'],
        roles: ['retailer'],
        level: 2
      },
      {
        states: ['/', 'marketplace', 'credential'],
        short_label: 'C',
        name: 'Credential',
        type: 'link',
        icon: 'fa fa-shield',
        permissions: ['oauth_client.marketplace.view'],
        roles: ['marketplace'],
        level: 2
      }
    ]
  },
  {
    label: 'Smart Button',
    main: [
      {
        states: ['/', 'retailer', 'smart-button'],
        short_label: 'SB',
        name: 'Custom Attributes',
        type: 'link',
        icon: 'fa fa-eye',
        permissions: ['smart_button.update'],
        level: 1
      }
    ]
  }
];
