import Model from './Model';

class RetailerServiceShippingCost extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default RetailerServiceShippingCost;
