import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery } from 'redux-saga/effects';
import {
  GET_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
  GET_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED,
  UPDATE_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
  UPDATE_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED
} from './smart-button.actions';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchGetRenderConfigRequested() {
  yield takeEvery(GET_SMART_BUTTON_RENDER_CONFIG_REQUESTED, function*(action: any) {
    try {
      let result = yield AppInjector.get(ApiService)
        .smartButton.getRenderConfig(action.user_id)
        .toPromise();
      yield put({ type: GET_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateRenderConfigRequested() {
  yield takeEvery(UPDATE_SMART_BUTTON_RENDER_CONFIG_REQUESTED, function*(action: any) {
    try {
      const notification = AppInjector.get(NotificationService);
      let result = yield AppInjector.get(ApiService)
        .smartButton.updateRenderConfig(action.user_id, action.data)
        .toPromise();
      yield put({ type: UPDATE_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED, data: result });
      yield put({ type: GET_SMART_BUTTON_RENDER_CONFIG_REQUESTED, user_id: action.user_id });
      notification.show('success', 'Configuration saved', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetRenderConfigRequested, watchUpdateRenderConfigRequested];
