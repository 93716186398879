import { takeEvery, put } from 'redux-saga/effects';

import {
  BUY_SHIPPING_LABEL_LANDING_PAGE_REQUESTED,
  GET_SHIPMENT_LANDING_PAGE_REQUESTED,
  GET_SHIPMENT_LANDING_PAGE_SUCCEEDED,
  BUY_SHIPPING_LABEL_LANDING_PAGE_SUCCEEDED, BUY_SHIPPING_LABEL_LANDING_PAGE_FAILED
} from './buy-shipping-label.actions';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { API_CALL_ERROR } from '../../../store/action';
import Shipment from '../../../models/Shipment';
import { UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED } from '../landing-page.actions';

function* watchGetShipmentLandingPageRequest() {
  yield takeEvery(GET_SHIPMENT_LANDING_PAGE_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    let retrieve = null;
    try {
      AppInjector.get(PreloaderService).show();
      let shipment = yield api.admin.shipment.getLandingPageShipment(action.data.shipmentId).toPromise();
      let product = yield api.admin.product.getLandingPageProduct(action.data.productId).toPromise();

      yield put({ type: UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED, data: product, entity_type: 'product' });
      yield put({ type: GET_SHIPMENT_LANDING_PAGE_SUCCEEDED, data: { shipment: shipment, product: product, retrieve: retrieve } });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchBuyShippingLabelLandingPageRequest() {
  yield takeEvery(BUY_SHIPPING_LABEL_LANDING_PAGE_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      const result = yield api.admin.shipment.buyShippingLabel(action.data).toPromise();
      yield put({ type: BUY_SHIPPING_LABEL_LANDING_PAGE_SUCCEEDED, data: new Shipment(result.data) });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      const shipment = yield AppInjector.get(ApiService).admin.shipment.update(action.data.shipmentId, {
        courier_name: 'manual',
        type: 'manual'
      }).toPromise();
      yield api.admin.shipment.invalidateToken(action.data).toPromise();
      yield put({ type: BUY_SHIPPING_LABEL_LANDING_PAGE_FAILED, data: shipment });
      AppInjector.get(PreloaderService).hide();
    }
  });
}

export default [watchGetShipmentLandingPageRequest, watchBuyShippingLabelLandingPageRequest];
