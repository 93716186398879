import { ServiceConfiguration } from './service-configuration/service-configuration.reducer';
import { Address } from './address/address.reducer';
import { combineReducers } from 'redux';
import { listUser } from './list/list.reducer';
import { createUser } from './create/create.reducer';
import { editUser } from './edit/edit.reducer';
import { FETCH_USER_DETAIL_SUCCEEDED } from './edit/edit.actions';
import * as _ from 'lodash';
import { FETCH_LIST_USER_SUCCEEDED } from './list/list.actions';
import { GET_ALL_USERS_SUCCEEDED } from './user.actions';

const common = (
  state = {
    fetched: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_USER_DETAIL_SUCCEEDED:
      return _.assign({}, state, {
        fetched: false,
        items: []
      });
    default:
      return state;
  }
};

const all = (state = { fetched: false, loading: false, items: [] }, action) => {
  switch (action.type) {
    case FETCH_LIST_USER_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };
    case GET_ALL_USERS_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };

    default:
      return state;
  }
};

export const Users = combineReducers({ ServiceConfiguration, Address, common, all, listUser, createUser, editUser });
