import {
  FETCH_COLOR_ATTRIBUTE_REQUESTED,
  FETCH_COLOR_ATTRIBUTE_SUCCESSED,
  CREATE_COLOR_REQUESTED,
  CREATE_COLOR_SUCCESSED,
  FETCH_COLOR_DICTIONARIES_SUCCESSED,
  FETCH_COLOR_DICTIONARY_REQUESTED,
  CREATE_COLOR_DICTIONARY_SUCCESSED,
  DELETE_COLOR_ATTRIBUTE_REQUESTED,
  DELETE_COLOR_ATTRIBUTE_SUCCESSED,
  EDIT_COLOR_REQUESTED,
  EDIT_COLOR_SUCCESSED,
  EDIT_COLOR_DICTIONARY_REQUESTED,
  EDIT_COLOR_DICTIONARY_SUCCESSED
} from './color.action';
import { takeEvery, put, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { DICTIONARY_COLOR_TYPE } from '../../../../app.const';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

export function* fetchAllcolor() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.color.get({ per_page: 10000 }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchColors(action) {
  try {
    let results = yield AppInjector.get(ApiService).color.get({ per_page: 10000 }).toPromise();
    yield put({ type: FETCH_COLOR_ATTRIBUTE_SUCCESSED, data: results.items });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchColorAttributeRequested() {
  yield takeEvery(FETCH_COLOR_ATTRIBUTE_REQUESTED, fetchColors);
}

function* createColor(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.color.create(action.data).toPromise();
    yield put({ type: CREATE_COLOR_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateColorRequested() {
  yield takeEvery(CREATE_COLOR_REQUESTED, createColor);
}

function* fetchColorDictionaries(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_COLOR_DICTIONARIES_SUCCESSED, data: results });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchColorDictionaryRequested() {
  yield takeEvery(FETCH_COLOR_DICTIONARY_REQUESTED, fetchColorDictionaries);
}

function* createColorDictionary(action) {
  const fetched_marketplace = yield select((state) => (state as any).Admin.Attribute.all.marketplace.fetched);
  if (fetched_marketplace) {
    const marketplaces = yield select((state) => (state as any).Admin.Attribute.all.marketplace.items);
    for (const marketplace of marketplaces) {
      try {
        const api = AppInjector.get(ApiService);

        let data = {
          type: DICTIONARY_COLOR_TYPE,
          attribute_id: action.data.getId(),
          marketplace_id: marketplace.getId(),
          value: action.data.getName()
        };

        let result = yield api.admin.dictionary.create(data).toPromise();
        yield put({ type: CREATE_COLOR_DICTIONARY_SUCCESSED, data: result });

        let marketplace_id = AppInjector.get(ActivatedRoute).snapshot.queryParams.marketplace_id;
        if (!_.isNil(marketplace_id)) {
          yield put({
            type: FETCH_COLOR_DICTIONARY_REQUESTED,
            data: {
              marketplace_id: marketplace_id,
              type: DICTIONARY_COLOR_TYPE
            }
          });
        }
      } catch (e) {
        AppInjector.get(PreloaderService).hide();
        yield put({ type: API_CALL_ERROR, error: e });
      }
    }
  }
}

function* watchCreateColorSucceeded() {
  yield takeEvery(CREATE_COLOR_SUCCESSED, createColorDictionary);
}

function* watchDeleteItemRequest() {
  yield takeEvery(DELETE_COLOR_ATTRIBUTE_REQUESTED, deleteItem);
}

function* deleteItem(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.color.delete(action.data.id).toPromise();
    yield put({ type: DELETE_COLOR_ATTRIBUTE_SUCCESSED, data: action.data.id });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditColorRequested() {
  yield takeEvery(EDIT_COLOR_REQUESTED, editColor);
}

function* editColor(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.color.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_COLOR_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update color success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditColorDictionaryRequested() {
  yield takeEvery(EDIT_COLOR_DICTIONARY_REQUESTED, editColorDictionary);
}

function* editColorDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_COLOR_DICTIONARY_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [
  watchFetchColorAttributeRequested,
  watchCreateColorRequested,
  watchFetchColorDictionaryRequested,
  watchCreateColorSucceeded,
  watchDeleteItemRequest,
  watchEditColorRequested,
  watchEditColorDictionaryRequested
];
