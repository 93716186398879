export const FETCH_PRODUCTS_REQUESTED = 'FETCH_PRODUCTS_REQUESTED';
export const FETCH_PRODUCTS_SUCCEEDED = 'FETCH_PRODUCTS_SUCCEEDED';
export const SELECT_ALL_PRODUCT = 'SELECT_ALL_PRODUCT';
export const FETCH_CATEGORIES_OF_PRODUCT_REQUESTED = 'FETCH_CATEGORIES_OF_PRODUCT_REQUESTED';
export const FETCH_CATEGORIES_OF_PRODUCT_SUCCEEDED = 'FETCH_CATEGORIES_OF_PRODUCT_SUCCEEDED';
export const MAP_CATEGORIES_TO_PRODUCTS = 'MAP_CATEGORIES_TO_PRODUCTS';
export const BROADCAST_ITEM_REQUESTED = 'BROADCAST_ITEM_REQUESTED';
export const BROADCAST_ITEM_SUCCESSED = 'BROADCAST_ITEM_SUCCESSED';
export const ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED = 'ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED';
export const ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED = 'ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED';
export const FETCH_PRODUCT_IMAGE_SUCCESSED = 'FETCH_PRODUCT_IMAGE_SUCCESSED';
export const FETCH_CUSTOMER_INFORMATION_REQUESTED = 'FETCH_CUSTOMER_INFORMATION_REQUESTED';
export const FETCH_CUSTOMER_INFORMATION_SUCCEEDED = 'FETCH_CUSTOMER_INFORMATION_SUCCEEDED';
export const FETCH_PRODUCT_MARKETPLACE_REQUESTED = 'FETCH_PRODUCT_MARKETPLACE_REQUESTED';
export const FETCH_PRODUCT_MARKETPLACE_SUCCESSED = 'FETCH_PRODUCT_MARKETPLACE_SUCCESSED';
export const FETCH_ALL_MARKETPLACE_REQUESTED = 'FETCH_ALL_MARKETPLACE_REQUESTED';
export const FETCH_ALL_MARKETPLACE_SUCCESSED = 'FETCH_ALL_MARKETPLACE_SUCCESSED';
export const PUBLISH_PRODUCT_REQUESTED = 'PUBLISH_PRODUCT_REQUESTED';
export const PUBLISH_PRODUCT_SUCCESSED = 'PUBLISH_PRODUCT_SUCCESSED';
export const EXPORT_PRODUCT_REQUESTED = 'EXPORT_PRODUCT_REQUESTED';
export const EXPORT_PRODUCT_SUCCEEDED = 'EXPORT_PRODUCT_SUCCEEDED';
export const EXPORT_PRODUCT_FAILED = 'EXPORT_PRODUCT_FAILED';

export const ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED = 'ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED';
export const ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED = 'ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED';
export const FETCH_ALL_CONDITION_REQUESTED = 'FETCH_ALL_CONDITION_REQUESTED';
export const FETCH_ALL_CONDITION_SUCCESSED = 'FETCH_ALL_CONDITION_SUCCESSED';
export const FETCH_ALL_DESIGNER_REQUESTED = 'FETCH_ALL_DESIGNER_REQUESTED';
export const FETCH_ALL_DESIGNER_SUCCESSED = 'FETCH_ALL_DESIGNER_SUCCESSED';
export const FETCH_ALL_CATEGORY_REQUESTED = 'FETCH_ALL_CATEGORY_REQUESTED';
export const FETCH_ALL_CATEGORY_SUCCESSED = 'FETCH_ALL_CATEGORY_SUCCESSED';
export const GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED = 'GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED';
export const GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED = 'GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED';
export const FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED = 'FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED';
export const FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED = 'FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED';
export const UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED = 'UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED';
export const UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED = 'UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED';
export const FETCH_ALL_STATUS_REQUESTED = 'FETCH_ALL_STATUS_REQUESTED';
export const FETCH_ALL_STATUS_SUCCESSED = 'FETCH_ALL_STATUS_SUCCESSED';
export const RESYNC_PRODUCT_FROM_RETAILER_REQUESTED = 'RESYNC_PRODUCT_FROM_RETAILER_REQUESTED';
export const RESYNC_PRODUCT_FROM_RETAILER_SUCCEEDED = 'RESYNC_PRODUCT_FROM_RETAILER_SUCCEEDED';

export const MODIFY_BY_CSV_REQUESTED = 'MODIFY_BY_CSV_REQUESTED';
export const MODIFY_BY_CSV_SUCCESSED = 'MODIFY_BY_CSV_SUCCESSED';

export const REMOVE_ALL_DISCOUNT_REQUESTED = 'REMOVE_ALL_DISCOUNT_REQUESTED';
export const REMOVE_ALL_DISCOUNT_SUCCESSED = 'REMOVE_ALL_DISCOUNT_SUCCESSED';

export const ADD_OR_REMOVE_SELECTED_PRODUCT = 'ADD_OR_REMOVE_SELECTED_PRODUCT';
export const ADD_ALL_PRODUCTS_IN_PAGE = 'ADD_ALL_PRODUCTS_IN_PAGE';
export const REMOVE_ALL_PRODUCTS_IN_PAGE = 'REMOVE_ALL_PRODUCTS_IN_PAGE';
export const REMOVE_ALL_SELECTED_PRODUCT = 'REMOVE_ALL_SELECTED_PRODUCT';
