import { AuthGuardService } from './../../../auth/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { AttributeComponent } from './attribute.component';
import { DesignerComponent } from './designer/designer.component';
import { SizeComponent } from './size/size.component';
import { ConditionComponent } from './condition/condition.component';
import { ColorComponent } from './color/color.component';
import { MaterialComponent } from './material/material.component';

const routes: Routes = [
  {
    path: '',
    component: AttributeComponent,
    children: [
      {
        path: '',
        component: CategoryComponent
      },
      {
        path: 'category',
        component: CategoryComponent
      },
      {
        path: 'designer',
        component: DesignerComponent
      },
      {
        path: 'size',
        component: SizeComponent
      },
      {
        path: 'condition',
        component: ConditionComponent
      },
      {
        path: 'color',
        component: ColorComponent
      },
      {
        path: 'material',
        component: MaterialComponent
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttributeRoutingModule {}
