import Model from './Model';
import User from './User';
import * as _ from 'lodash';
import WalletMeta from './WalletMeta';

class Wallet extends Model {
  seller: any;
  constructor(options) {
    super();
    (this as any).seller = d => {
      return new User(d.data);
    };
    (this as any).wallet_meta = d => {
      return _.map(d.data, item => new WalletMeta(item));
    };
    this.bind(options);
  }
}

export default Wallet;
