import {
  FETCH_CATEGORY_ATTRIBUTE_REQUESTED,
  FETCH_CATEGORY_ATTRIBUTE_SUCCESSED,
  DELETE_CATEGORY_ATTRIBUTE_REQUESTED,
  DELETE_CATEGORY_ATTRIBUTE_SUCCESSED,
  FETCH_CATEGORY_DICTIONARY_SUCCESSED,
  CREATE_CATEGORY_DICTIONARY_SUCCESSED,
  FETCH_CATEGORY_DICTIONARY_REQUESTED,
  EDIT_CATEGORY_DICTIONARY_REQUESTED,
  EDIT_CATEGORY_DICTIONARY_SUCCEEDED
} from './category.action';
import { takeEvery, select, put } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { DICTIONARY_CATEGORY_TYPE } from '../../../../app.const';
import { ActivatedRoute } from '@angular/router';
import { CREATE_CATEGORY_SUCCEEDED } from '../../../admin/category/create/create.actions';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* fetchCategories(action) {
  try {
    const api = AppInjector.get(ApiService);
    const category = yield select(state => (state as any).Admin.Category.all.fetched);
    if (!category) {
      try {
        let results = yield api.category.list().toPromise();
        yield put({ type: FETCH_CATEGORY_ATTRIBUTE_SUCCESSED, data: results });
      } catch (e) {
        yield put({ type: API_CALL_ERROR, error: e });
      }
    } else {
      const categories = yield select(state => (state as any).Admin.Category.all.items);
      yield put({ type: FETCH_CATEGORY_ATTRIBUTE_SUCCESSED, data: categories });
    }
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchCategoryAttributeRequest() {
  yield takeEvery(FETCH_CATEGORY_ATTRIBUTE_REQUESTED, fetchCategories);
}

function* deleteCategory(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.category.delete(action.data.id).toPromise();
    yield put({ type: DELETE_CATEGORY_ATTRIBUTE_SUCCESSED, data: action.data.id });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* fetchCategoryDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_CATEGORY_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteCategoryRequest() {
  yield takeEvery(DELETE_CATEGORY_ATTRIBUTE_REQUESTED, deleteCategory);
}

function* watchFetchCategoryDictionaryRequested() {
  yield takeEvery(FETCH_CATEGORY_DICTIONARY_REQUESTED, fetchCategoryDictionary);
}

function* createCategoryDictionary(action) {
  const fetched_marketplace = yield select(state => (state as any).Admin.Attribute.all.marketplace.fetched);
  if (fetched_marketplace) {
    const marketplaces = yield select(state => (state as any).Admin.Attribute.all.marketplace.items);
    for (const marketplace of marketplaces) {
      try {
        const api = AppInjector.get(ApiService);

        let data = {
          type: DICTIONARY_CATEGORY_TYPE,
          attribute_id: action.data.getId(),
          marketplace_id: marketplace.getId(),
          value: action.data.getName()
        };

        let result = yield api.admin.dictionary.create(data).toPromise();
        yield put({ type: CREATE_CATEGORY_DICTIONARY_SUCCESSED, data: result });

        let marketplace_id = AppInjector.get(ActivatedRoute).snapshot.queryParams.marketplace_id;
        if (!_.isNil(marketplace_id)) {
          yield put({
            type: FETCH_CATEGORY_DICTIONARY_REQUESTED,
            data: {
              marketplace_id: marketplace_id,
              type: DICTIONARY_CATEGORY_TYPE
            }
          });
        }
      } catch (e) {
        AppInjector.get(PreloaderService).hide();
        yield put({ type: API_CALL_ERROR, error: e });
      }
    }
  }
}

function* watchCreateCategorySucceeded() {
  yield takeEvery(CREATE_CATEGORY_SUCCEEDED, createCategoryDictionary);
}

function* watchEditCategoryDictionaryRequested() {
  yield takeEvery(EDIT_CATEGORY_DICTIONARY_REQUESTED, editCategoryDictionary);
}

function* editCategoryDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_CATEGORY_DICTIONARY_SUCCEEDED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [
  watchDeleteCategoryRequest,
  watchFetchCategoryAttributeRequest,
  watchFetchCategoryDictionaryRequested,
  watchCreateCategorySucceeded,
  watchEditCategoryDictionaryRequested
];
