import { CREATE_ADMIN_LOCATION_REQUESTED, CREATE_ADMIN_LOCATION_SUCCESSED} from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery } from 'redux-saga/effects';
import { listAdminLocation } from '../location.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchCreateLocationRequest() {
  yield takeEvery(CREATE_ADMIN_LOCATION_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).admin.location.create(action.data).toPromise();
      yield put({ type: CREATE_ADMIN_LOCATION_SUCCESSED, data: result });
      router.navigate(listAdminLocation());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchCreateLocationRequest];
