import { FETCH_ALL_MARKETPLACE_SUCCESSED, UPDATE_INTEGRATION_FIELD, FETCH_ALL_INTEGRATED_MARKETPLACE_SUCCESSED } from './create.actions';
import * as _ from 'lodash';

const Marketplace = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_INTEGRATION_FIELD:
      if ((state as any).id === action.data.id) {
        state[action.field] = action.value;
      }
      return state;
    case FETCH_ALL_INTEGRATED_MARKETPLACE_SUCCESSED:
      if (!_.isUndefined(_.find(action.data, item => item.marketplace_id === (state as any).id))) {
        (state as any).integrated = true;
      }
      return state;
    default:
      return state;
  }
};

export const create = (state = { fetched: false, item: {}, marketplaces: [] }, action) => {
  switch (action.type) {
    case UPDATE_INTEGRATION_FIELD:
    case FETCH_ALL_INTEGRATED_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, { fetched: true, marketplaces: _.map(state.marketplaces, item => Marketplace(item, action)) });
    case FETCH_ALL_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, { marketplaces: action.data });
    default:
      return state;
  }
};
