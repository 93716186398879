export const RENDER_EDIT_ADMIN_ORDER_ITEM_FORM_REQUESTED = 'RENDER_EDIT_ADMIN_ORDER_ITEM_FORM_REQUESTED';
export const FILL_ADMIN_ORDER_ITEM_DETAIL_FORM = 'FILL_ADMIN_ORDER_ITEM_DETAIL_FORM';
export const FETCH_ADMIN_ORDER_ITEM_REQUESTED = 'FETCH_ADMIN_ORDER_ITEM_REQUESTED';
export const FETCH_ADMIN_ORDER_ITEM_SUCCESSED = 'FETCH_ADMIN_ORDER_ITEM_SUCCESSED';
export const EDIT_ADMIN_ORDER_ITEM_REQUESTED = 'EDIT_ADMIN_ORDER_ITEM_REQUESTED';
export const EDIT_ADMIN_ORDER_ITEM_SUCCESSED = 'EDIT_ADMIN_ORDER_ITEM_SUCCESSED';
export const DELETE_ADMIN_ORDER_ITEM_REQUESTED = 'DELETE_ADMIN_ORDER_ITEM_REQUESTED';
export const DELETE_ADMIN_ORDER_ITEM_SUCCESSED = 'DELETE_ADMIN_ORDER_ITEM_SUCCESSED';
export const UPDATE_ADMIN_ORDER_ITEM_DETAIL_FORM_INPUT_OPTIONS = 'UPDATE_ADMIN_ORDER_ITEM_DETAIL_FORM_INPUT_OPTIONS';


export const fetchAdminOrderItemRequested = (payload) => {
    return {
        type: FETCH_ADMIN_ORDER_ITEM_REQUESTED,
        data: payload,
    };
};

export const fetchAdminOrderItemSuccessed = (payload) => {
    return {
        type: FETCH_ADMIN_ORDER_ITEM_SUCCESSED,
        data: payload,
    };
};

