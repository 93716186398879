import { put, takeEvery } from 'redux-saga/effects';
// import { GET_ALL_ATTRIBUTES_BY_TYPE_REQUESTED, GET_ALL_ATTRIBUTES_BY_TYPE_SUCCEEDED } from '../attribute.action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import {
    FETCH_MEASUREMENT_UNIT_SUCCESSED,
    FETCH_MEASUREMENT_UNIT_REQUESTED,
    DELETE_MEASUREMENT_UNIT_REQUESTED,
    DELETE_MEASUREMENT_UNIT_SUCCESSED
} from './list.action';
import { NotificationService } from '../../../common/services/notification/notification.service';

function* watchGetMeasurementRequested() {
  yield takeEvery(FETCH_MEASUREMENT_UNIT_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .measurementUnit.get(action.data.params)
        .toPromise();
      yield put({ type: FETCH_MEASUREMENT_UNIT_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function * watchDeleteMeasurementRequested() {
  yield takeEvery(DELETE_MEASUREMENT_UNIT_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .measurementUnit.delete(action.data.id)
        .toPromise();
      AppInjector.get(NotificationService).show('success', `Successfully deleted measurement`, 3000);
      yield put({ type: DELETE_MEASUREMENT_UNIT_SUCCESSED, data: { id: action.data.id } });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}


export default [watchGetMeasurementRequested, watchDeleteMeasurementRequested];
