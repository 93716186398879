import Model from './Model';
import Product from './Product';
import * as _ from 'lodash';
import Shipment from './Shipment';
import Order from './Order';
import Currency from './Currency';
import TransactionHistory from './TransactionHistory';
export const ORDER_PRODUCT_STATUS_SHIPPED_CONFIRMED = 'shipped confirmed';
export const ORDER_PRODUCT_STATUS_SOLD_CONFIRMED = 'sold confirmed';
export const ORDER_PRODUCT_STATUS_SOLD_RETURNED = 'sold returned';
export const ORDER_PRODUCT_STATUS_PENDING = 'pending';
export const ORDER_PRODUCT_STATUS_SOLD_CANCELED = 'sold canceled';
export const ORDER_ITEM_STATUS_SOLD = 'sold';
export const ORDER_ITEM_STATUS_CANCELED = 'canceled';
export const ORDER_ITEM_STATUS_RETURNED = 'returned';
export const ORDER_ITEM_STATUS_SOLD_CONFIRMED = 'sold confirmed';
export const ORDER_ITEM_STATUS_RETURN_CONFIRMED = 'return confirmed';
export const ORDER_ITEM_STATUS_SUPPLIER_INVOICED = 'supplier_invoiced';
export const ORDER_ITEM_STATUS_RETURN_IN_PROGRESS = 'return_in_progress';
export const ORDER_ITEM_STATUS_RETURN_IN_QC = 'return_in_qc';
export const ORDER_ITEM_STATUS_RETURN_COMPLETE = 'return_complete';

export const ORDER_PRODUCT_STATUS_LIST = [
  {
    value: ORDER_ITEM_STATUS_SOLD,
    label: 'Sold'
  },
  {
    value: ORDER_ITEM_STATUS_RETURN_IN_PROGRESS,
    label: 'Return in Progress'
  },
  {
    value: ORDER_ITEM_STATUS_RETURN_IN_QC,
    label: 'Return in QC'
  },
  {
    value: ORDER_ITEM_STATUS_RETURN_COMPLETE,
    label: 'Return in Complete'
  },
  {
    value: ORDER_ITEM_STATUS_SOLD_CONFIRMED,
    label: 'Sold confirmed'
  },
  {
    value: ORDER_ITEM_STATUS_CANCELED,
    label: 'Canceled'
  }
];
class OrderProduct extends Model {
  constructor(options) {
    super();
    (this as any).product = (d) => {
      return new Product(d.data);
    };
    (this as any).order = (d) => {
      return new Order(d.data);
    };
    (this as any).payment = (d) => {
      return new Payment(d.data);
    };
    (this as any).shipments = (d) => {
      return _.map(d.data, (item) => new Shipment(item));
    };
    (this as any).product_price_currency = (d) => {
      return new Currency(d.data);
    };
    this.bind(options);
  }

  getShipmentStatus() {
    const { shipments } = this as any;
    if (shipments.length) {
      return shipments[0].getStatus();
    }
    return '';
  }

  getDateFromCurrentDateType(current_date_type) {
    let result = this['created_at'];
    if (!_.isNil(current_date_type) && current_date_type !== '') {
      result = this[current_date_type];
      if (this[current_date_type] !== 'sold_date') {
        result = _.replace(this[current_date_type], 'order_item_', '');
      }
    }
    return result;
  }

  getProfit() {
    const { status, shipping_cost, shipments, paid_to_supplier, paid_to_original_seller, paid_to_reflaunt, product_item_management } = this as any;
    let shipping_costs = shipments.length > 0 && shipments[0].shipment_charge_total ? shipments[0].shipment_charge_total : 0;
    let return_costs = 0;
    let customs_cost = shipments.length > 0 && shipments[0].custom_fee ? shipments[0].custom_fee : 0;
    let other_cost = 0;

    let est_shipping_costs = product_item_management ? product_item_management.data.estimated_shipping_costs : 0;
    let est_return_costs = product_item_management ? product_item_management.data.estimated_return_costs : 0;
    let est_customs_cost = product_item_management ? product_item_management.data.estimated_customs_costs : 0;
    let est_other_cost = product_item_management ? product_item_management.data.estimated_other_costs : 0;
    let result = {
      value: 0,
      est: 0
    };

    const retailer_commission = paid_to_supplier && paid_to_original_seller ? paid_to_supplier - paid_to_original_seller : 0;
    const payout = paid_to_original_seller ? paid_to_original_seller : 0;
    const paid_to_reflauntt = paid_to_reflaunt ? paid_to_reflaunt : 0;

    const est_retailer_commission =
      product_item_management && product_item_management.data.payout_to_supplier && product_item_management.data.payout_to_original_seller
        ? product_item_management.data.payout_to_supplier - product_item_management.data.payout_to_original_seller
        : 0;
    const est_payout = product_item_management && product_item_management.data.payout_to_supplier ? product_item_management.data.payout_to_supplier : 0;
    const est_paid_to_reflauntt = product_item_management && product_item_management.data.payout_to_reflaunt ? product_item_management.data.payout_to_reflaunt : 0;
    switch (status) {
      case ORDER_ITEM_STATUS_RETURN_IN_PROGRESS:
      case ORDER_ITEM_STATUS_RETURN_IN_QC:
      case ORDER_ITEM_STATUS_RETURN_COMPLETE:
      case ORDER_ITEM_STATUS_CANCELED:
        result.value = 0 - shipping_costs - return_costs - customs_cost - other_cost;
        result.est = 0 - est_shipping_costs - est_return_costs - est_customs_cost - est_other_cost;
        break;
      default:
        result.value = paid_to_reflauntt - payout - retailer_commission - shipping_costs - return_costs - customs_cost - other_cost;
        result.est = est_paid_to_reflauntt - est_payout - est_retailer_commission - est_shipping_costs - est_return_costs - est_customs_cost - other_cost;
    }
    return result;
  }
}

class Payment extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default OrderProduct;
