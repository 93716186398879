import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import editSaga from './edit/edit.saga';
import createSaga from './create/create.saga';
import detailSaga from './detail/detail.saga';
import percentageSaga from './percentage/percentage.saga';
import endpointAdminSata from './endpoint/endpoint.saga';
import { API_CALL_ERROR } from '../../../store/action';
import { GET_ALL_RETAILERS_REQUESTED, GET_ALL_RETAILERS_SUCCEEDED } from './retailer.action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';
import smartButtonSaga from './smart-button/smart-button.saga';
import commissionConfig from './commission-config/commission-config.saga';

export function* fetchAllretailer() {
  const fetchStatus = yield select((state) => (state as any).retailer.all.fetched);
  if (!fetchStatus) {
    try {
      let result = yield AppInjector.get(ApiService).retailer.list().toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select((state) => (state as any).retailer.all.items);
    return data;
  }
}

function* allSaga(action) {
  const data = yield call(fetchAllretailer);
  yield put({ type: GET_ALL_RETAILERS_SUCCEEDED, component: action.component, data: data });
}

function* watchFetchAllRetailersRequest() {
  yield takeLatest(GET_ALL_RETAILERS_REQUESTED, allSaga);
}
export default [
  ...listSaga,
  ...editSaga,
  ...commissionConfig,
  ...createSaga,
  ...detailSaga,
  ...percentageSaga,
  ...endpointAdminSata,
  ...smartButtonSaga,
  watchFetchAllRetailersRequest
];
