import { PipesModule } from './../../../common/pipes/pipes.module';
import { AttributeRoutingModule } from './attribute-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { CategoryComponent } from './category/category.component';
import { AttributeComponent } from './attribute.component';
import { DesignerComponent } from './designer/designer.component';
import { SizeComponent } from './size/size.component';
import { ConditionComponent } from './condition/condition.component';
import { ColorComponent } from './color/color.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MaterialComponent } from './material/material.component';

@NgModule({
  imports: [CommonModule, AttributeRoutingModule, FormsModule, PipesModule, DirectivesModule, SweetAlert2Module],
  declarations: [AttributeComponent, CategoryComponent, DesignerComponent, SizeComponent, ConditionComponent, ColorComponent, MaterialComponent]
})
export class AttributeModule {}
