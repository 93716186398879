import { Router } from '@angular/router';
import { FETCH_ALL_ADMIN_ORDER_REQUESTED } from './../list/list.actions';
import {
  DELETE_ADMIN_ORDER_REQUESTED,
  FETCH_ADMIN_ORDER_REQUESTED,
  fetchAdminOrderRequested,
  FETCH_ADMIN_ORDER_SUCCESSED,
  fetchAdminOrderSuccessed,
  EDIT_ADMIN_ORDER_REQUESTED,
  EDIT_ADMIN_ORDER_SUCCESSED,
  RENDER_EDIT_ADMIN_ORDER_FORM_REQUESTED,
  FILL_ADMIN_ORDER_DETAIL_FORM,
  orderStatus
} from './edit.actions';
import { put, takeLatest, select } from 'redux-saga/effects';
import { listAdminOrder } from '../order.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';

function* watchEditOrderRequest() {
  yield takeLatest(EDIT_ADMIN_ORDER_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      const result = yield AppInjector.get(ApiService).order.update(action.id, action.data).toPromise();
      yield put({ type: EDIT_ADMIN_ORDER_SUCCESSED, data: result });
      router.navigate(listAdminOrder());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetOrderRequest() {
  yield takeLatest(FETCH_ADMIN_ORDER_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).order.show(action.data).toPromise();
      yield put(fetchAdminOrderSuccessed(result));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteOrderRequest() {
  yield takeLatest(DELETE_ADMIN_ORDER_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).order.delete(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_ORDER_REQUESTED });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRenderOrderDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_ADMIN_ORDER_FORM_REQUESTED, function* (action: any) {
    const inputs = yield select((state) => (state as any).Admin.Order.edit.inputs);
    const product_inputs = _.find(inputs, (e) => e.key === 'product_id');
    const status_input = _.find(inputs, (e) => e.key === 'status');
    status_input.options = _.map(orderStatus, (e) => _.assign({}, { value: e, label: e }));
    yield put(fetchAdminOrderRequested(action.data.id));
  });
}

function* watchFetchOrderDetailSuccessed() {
  yield takeLatest(FETCH_ADMIN_ORDER_SUCCESSED, function* (action: any) {
    // const inputs = yield select((state) => (state as any).Admin.Order.edit.inputs);
    // const currencies = yield AppInjector.get(ApiService).currency.get({per_page: 10000}).toPromise();
    // const currency_input = _.find(inputs, (e) => e.key === 'currency');
    // const product_id_input = _.find(inputs, (e) => e.key === 'product_id');
    // currency_input.options = _.map(currencies, (e) => _.assign({ value: e.id, label: e.code }));
    // product_id_input.value = _.map(action.data.order_products, (e) => e.product);
    if (action.data.sold_date) {
      action.data.sold_date = new Date(action.data.sold_date);
    }
    yield put({ type: FILL_ADMIN_ORDER_DETAIL_FORM, data: action.data });
  });
}

export default [watchEditOrderRequest, watchGetOrderRequest, watchDeleteOrderRequest, watchRenderOrderDetailFormRequested, watchFetchOrderDetailSuccessed];
