import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import {
  FETCH_ALL_CREDENTIALS_REQUESTED,
  CREATE_CREDENTIAL_REQUESTED,
  DELETE_CREDENTIAL_REQUESTED
} from './credential.actions';
import { BaseComponent } from '../base.component';
import { MARKETPLACE_COMP } from '../marketplace/marketplace.const';
import { ADMIN_RETAILER_COMP } from '../admin/retailer/retailer.const';
import { RETAILER_COMP } from '../retailer/retailer.const';

@Component({
  selector: 'app-credential',
  templateUrl: './credential.component.html',
  styleUrls: ['./credential.component.scss']
})
export class CredentialComponent extends BaseComponent implements OnInit, OnDestroy {
  public reducer = 'Credential';
  public availableParams = ['sort', 'constraints', 'page', 'search', 'email'];

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    super();
    const { fetchAllCredentialRequest } = this as any;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const requestQueryParams = this.queryParser.parse(this.availableParams);
        fetchAllCredentialRequest(requestQueryParams);
      }
    });
  }

  getComp() {
    return this.activatedRoute.snapshot.data.comp;
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Credential
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch,
      fetchAllCredentialRequest: data => {
        dispatch({ type: FETCH_ALL_CREDENTIALS_REQUESTED, data: data, comp: this.getComp(), params: this.activatedRoute.snapshot.params });
      },
      generateCredentialRequest: data => {
        dispatch({ type: CREATE_CREDENTIAL_REQUESTED, data: data, comp: this.getComp(), params: this.activatedRoute.snapshot.params });
      },
      deleteCredentialRequest: id => {
        dispatch({ type: DELETE_CREDENTIAL_REQUESTED, id: id, comp: this.getComp(), params: this.activatedRoute.snapshot.params });
      }
    };
  }

  create() {
    const { generateCredentialRequest } = this as any;
    const data = {};
    generateCredentialRequest(data);
  }

  delete(id) {
    const { deleteCredentialRequest } = this as any;
    deleteCredentialRequest(id);
  }
}
