import { fork, put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import editSaga from './edit/edit.saga';
import createSaga from './create/create.saga';
import { GET_ALL_CURRENCYS_REQUESTED, GET_ALL_CURRENCYS_SUCCEEDED } from './currency.actions';
import { API_CALL_ERROR } from '../../../store/action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';

export function* fetchAllcurrency() {
  const api = AppInjector.get(ApiService);
  const processType = yield select((state) => (state as any).Admin.Currency.all.fetched);
  if (!processType) {
    try {
      let results = yield api.currency.get({ per_page: 10000 }).toPromise();
      return results.items;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const processTypeData = yield select((state) => (state as any).Admin.Currency.all.items);
    return processTypeData;
  }
}

function* allSaga(action) {
  const data = yield call(fetchAllcurrency);
  yield put({ type: GET_ALL_CURRENCYS_SUCCEEDED, data: data });
}

function* watchFetchAllCurrencysRequest() {
  yield takeLatest(GET_ALL_CURRENCYS_REQUESTED, allSaga);
}
export default [...listSaga, ...editSaga, ...createSaga, watchFetchAllCurrencysRequest];
