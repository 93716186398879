import Model from './Model';

export const SMART_BUTTON_TYPE = 'smart-button';
export const SMART_LINK_TYPE = 'smart-link';
export const SMART_PRICER_TYPE = 'smart-pricer'

export const object_types = [
  {
    label: 'Smart Button',
    type: SMART_BUTTON_TYPE,
    url: ''
  },
  {
    label: 'Smart Link',
    type: SMART_LINK_TYPE,
    url: ''
  },
  {
    label: 'Smart Pricer',
    type: SMART_PRICER_TYPE,
    url: ''
  }
];

class SmartObject extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default SmartObject;
