export const ADMIN_CONFIGURATION_FETCH_MARKETPLACE_REQUESTED = 'ADMIN_CONFIGURATION_FETCH_MARKETPLACE_REQUESTED';
export const ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED = 'ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED';
export const ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED = 'ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED';

export const ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE = 'ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE';
export const ADMIN_INTEGRATION_EDIT_CHANGE_SETTING = 'ADMIN_INTEGRATION_EDIT_CHANGE_SETTING';
export const ADMIN_INTEGRATION_EDIT_UPDATE_ALL_SETTING = 'ADMIN_INTEGRATION_EDIT_UPDATE_ALL_SETTING';
export const ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED = 'ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED';
export const ADMIN_SAVE_INTEGRATION_CONFIGURATION_REQUESTED = 'ADMIN_SAVE_INTEGRATION_CONFIGURATION_REQUESTED';
export const ADMIN_SAVE_INTEGRATION_CONFIGURATION_SUCCESSED = 'ADMIN_SAVE_INTEGRATION_CONFIGURATION_SUCCESSED';

export const adminFetchMarketplaceRequested = (payload) => {
  return {
    type: ADMIN_CONFIGURATION_FETCH_MARKETPLACE_REQUESTED,
    data: payload
  };
};
