import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import { FETCH_RETAILER_PRODUCT_DETAIL_REQUESTED, FETCH_RETAILER_PRODUCT_DETAIL_SUCCEEDED } from './detail.actions';
import { takeEvery, put } from 'redux-saga/effects';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';

function* getProductDetail(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (!_.isNil(action.param)) {
      result = yield api.product.getItemById(action.data, action.param).toPromise();
    } else {
      result = yield api.product.getItemById(action.data).toPromise();
    }

    yield put({ type: FETCH_RETAILER_PRODUCT_DETAIL_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchProductDetailRequest() {
  yield takeEvery(FETCH_RETAILER_PRODUCT_DETAIL_REQUESTED, getProductDetail);
}

export default [watchFetchProductDetailRequest];
