import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { FETCH_ALL_DATASETS_SUCCEEDED } from './dataset.action';
import { EDIT_DATASET_SUCCEEDED, DELETE_DATASET_SUCCEEDED } from './edit/edit.actions';
import { CREATE_DATASET_SUCCEEDED } from './create/create.actions';
import { createGroup } from './create-group/create-group.reducer';
import { listGroup } from './list-group/list-group.reducer';
import { addToGroup } from './add-to-group/add-to-group.reducer';
import { editGroup } from './edit-group/edit-group.reducer';
const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case CREATE_DATASET_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case EDIT_DATASET_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case DELETE_DATASET_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case FETCH_ALL_DATASETS_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};
export const Dataset = combineReducers({
  list,
  edit,
  create,
  all,
  createGroup,
  listGroup,
  addToGroup,
  editGroup
});
