import * as _ from 'lodash';
import { FETCH_TRANSACTION_HISTORY_CUSTOMER_SUCCEEDED } from './transaction-history.actions';

export const TransactionsHistory = (
  state = {
    fetched: false,
    loading: false,
    items: [],
    pagination: {},
    deleted: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_TRANSACTION_HISTORY_CUSTOMER_SUCCEEDED:
    return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    default:
      return state;
  }
};
