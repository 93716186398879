import { Router } from '@angular/router';
import { FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED } from './../list/list.actions';
import {
  DELETE_ADMIN_ORDER_ITEM_REQUESTED,
  FETCH_ADMIN_ORDER_ITEM_REQUESTED,
  fetchAdminOrderItemRequested,
  FETCH_ADMIN_ORDER_ITEM_SUCCESSED,
  fetchAdminOrderItemSuccessed,
  EDIT_ADMIN_ORDER_ITEM_REQUESTED,
  EDIT_ADMIN_ORDER_ITEM_SUCCESSED,
  RENDER_EDIT_ADMIN_ORDER_ITEM_FORM_REQUESTED,
  FILL_ADMIN_ORDER_ITEM_DETAIL_FORM
} from './edit.actions';
import { put, takeLatest } from 'redux-saga/effects';
import { listAdminOrderItem } from '../order-item.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchEditOrderItemRequest() {
  yield takeLatest(EDIT_ADMIN_ORDER_ITEM_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      const result = yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
      yield put({ type: EDIT_ADMIN_ORDER_ITEM_SUCCESSED, data: result });
      router.navigate(listAdminOrderItem());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetOrderItemRequest() {
  yield takeLatest(FETCH_ADMIN_ORDER_ITEM_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.fulfilment.show(action.data).toPromise();
      yield put(fetchAdminOrderItemSuccessed(result));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteOrderItemRequest() {
  yield takeLatest(DELETE_ADMIN_ORDER_ITEM_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.fulfilment.delete(action.data).toPromise();
      // yield put({ type: FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRenderOrderItemDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_ADMIN_ORDER_ITEM_FORM_REQUESTED, function*(action: any) {
    yield put(fetchAdminOrderItemRequested(action.data.id));
  });
}

function* watchFetchOrderItemDetailSuccessed() {
  yield takeLatest(FETCH_ADMIN_ORDER_ITEM_SUCCESSED, function* (action: any) {
    yield put({ type: FILL_ADMIN_ORDER_ITEM_DETAIL_FORM, data: action.data });
  });
}

export default [watchEditOrderItemRequest, watchGetOrderItemRequest, watchDeleteOrderItemRequest, watchRenderOrderItemDetailFormRequested, watchFetchOrderItemDetailSuccessed];
