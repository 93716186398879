import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { EXPORT_LIST_VOUCHER_REQUESTED, FETCH_VOUCHER_CUSTOMER_REQUESTED, FETCH_VOUCHER_CUSTOMER_SUCCEEDED } from './list.actions';
import Swal from 'sweetalert2';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';

function* watchFetchVoucherCustomerRequested() {
  yield takeEvery(FETCH_VOUCHER_CUSTOMER_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .voucher.get(action.data)
        .toPromise();
      yield put({ type: FETCH_VOUCHER_CUSTOMER_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchExportVoucherRequest() {
  yield takeLatest(EXPORT_LIST_VOUCHER_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const swal = Swal as any;
    try {
      swal
        .fire({
          icon: 'info',
          title: 'Processing...',
          showConfirmButton: false,
          onBeforeOpen: () => {
            swal.showLoading();
          }
        })
        .then((result) => {});
      const result = yield api.voucher.export({ ids: action.data }).toPromise();
      const async = yield api.asyncProcess.checkAsyncStatusByCode(result.async_process_code, 3000).toPromise();
      if (async === 'Time out' || async.status === 'CANCELLED') {
        AppInjector.get(PreloaderService).hide();
        swal
          .fire({
            icon: 'error',
            title: 'Error',
            text: async.message
          })
          .then((result) => {});

        return false;
      }
      if (async.status === 'COMPLETED') {
        AppInjector.get(PreloaderService).hide();
        const { output } = async;
        swal
          .fire({
            icon: 'success',
            html: ` <h4>Collection information</h4>
            <p style="font-size: 16px; margin-top: 30px; font-weight: bold; text-align: left">Link: ${output}</p>`,
            confirmButtonText: `Download`
          })
          .then((data) => {
            if (data.value) {
              const a = document.createElement('a');
              a.href = output;
              a.click();
            }
          });
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchVoucherCustomerRequested, watchExportVoucherRequest];
