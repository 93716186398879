import {
    FETCH_CONDITION_ATTRIBUTE_SUCCESSED,
    CREATE_CONDITION_SUCCESSED,
    FETCH_CONDITION_DICTIONARIES_SUCCESSED,
    RESET_CONDITION_DICTIONARY,
    DELETE_CONDITION_ATTRIBUTE_SUCCESSED,
    EDIT_CONDITION_SUCCESSED,
    EDIT_CONDITION_DICTIONARY_SUCCESSED
} from './condition.action';
import * as _ from 'lodash';

export const Condition = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_CONDITION_ATTRIBUTE_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_form = false;
        return item;
      });
      return { ...state, ...{ fetched: true, items: action.data } };

    case CREATE_CONDITION_SUCCESSED:
      state.items.push(action.data);
      return _.assign({}, state, { create: { created: true, data: {} } });

    case FETCH_CONDITION_DICTIONARIES_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_dictionary_form = false;
        return item;
      });
      return _.assign({}, state, { dictionary: { fetched: true, items: action.data } });

    case RESET_CONDITION_DICTIONARY:
      return _.assign({}, state, { dictionary: { fetched: false, items: [] } });

    case DELETE_CONDITION_ATTRIBUTE_SUCCESSED:
      return {
        ...state,
        ...{
          items: _.filter(state.items, i => i.id !== action.data),
          dictionary: { ...state.dictionary, ...{ items: _.filter(state.dictionary.items, i => i.attribute_id !== action.data) } }
        }
      };

    case EDIT_CONDITION_SUCCESSED:
      const condition = _.find(state.items, { id: action.data.id });
      if (!_.isNil(condition)) {
        condition.is_show_edit_form = false;
        condition.name = action.data.name;
      }
      return { ...state, ...{ items: state.items } };

    case EDIT_CONDITION_DICTIONARY_SUCCESSED:
      const item = _.find(state.dictionary.items, { id: action.data.id });
      if (!_.isNil(item)) {
        item.is_show_edit_dictionary_form = false;
        item.value = action.data.value;
      }
      return _.assign({}, state, { dictionary: { items: state.dictionary.items } });

    default:
      return state;
  }
};
