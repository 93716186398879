import { AuthGuardService } from './../../auth/auth-guard.service';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { AddDatasetComponent } from './add-dataset/add-dataset.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigMarketplaceComponent } from './config-marketplace/config-marketplace.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: ':id/edit/:marketplace_id',
        component: EditComponent
      },
      {
        path: ':id/config',
        component: ConfigMarketplaceComponent
      },
      {
        path: 'add-dataset/:id',
        component: AddDatasetComponent
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationRoutingModule {}
