import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { ProductCloneHistory } from '../../../models/ProductCloneHistory';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class ProductCloneHistoryService extends ServiceProvider {
  public url = '/api/v1/admin/product-clone-history';
  public model = ProductCloneHistory;

  get(params): Observable<any> {
    this.preloader.show();

    const result = this.http.get(this.apiUrl.getApiUrl(`${this.url}/${params.id}`))

    return result
  }
}
