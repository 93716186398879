export const FETCH_USER_DETAIL_REQUESTED = 'FETCH_USER_DETAIL_REQUESTED';
export const FETCH_USER_DETAIL_SUCCEEDED = 'FETCH_USER_DETAIL_SUCCEEDED';
export const UPDATED_USER_REQUESTED = 'UPDATED_USER_REQUESTED';
export const UPDATED_USER_SUCCEEDED = 'UPDATED_USER_SUCCEEDED';
export const ATTACH_ROLE_TO_USER_REQUESTED = 'ATTACH_ROLE_TO_USER_REQUESTED';
export const ATTACH_ROLE_TO_USER_SUCCEEDED = 'ATTACH_ROLE_TO_USER_SUCCEEDED';
export const DETACH_ROLE_REQUESTED = 'DETACH_ROLE_REQUESTED';
export const DETACH_ROLE_SUCCEEDED = 'DETACH_ROLE_SUCCEEDED';
export const ATTACH_ROLES_USER_REQUESTED = 'ATTACH_ROLES_USER_REQUESTED';
export const ATTACH_ROLES_USER_SUCCESSED = 'ATTACH_ROLES_USER_SUCCESSED';
export const CHANGE_PASSWORD_BY_ADMIN_REQUESTED = 'CHANGE_PASSWORD_BY_ADMIN_REQUESTED';
export const CHANGE_PASSWORD_BY_ADMIN_SUCCESSED = 'CHANGE_PASSWORD_BY_ADMIN_SUCCESSED';

