import AbstractEventData from './AbstractEventData';
import Product from '../../Product';

class Transfer extends AbstractEventData {
  constructor(options) {
    super(options);
    (this as any).product = d => {
      return new Product(d.data);
    };
    this.bind(options);
  }
}

export default Transfer;
