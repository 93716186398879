import * as _ from 'lodash';
import { ADMIN_INTEGRATION_GET_ALL_RETAILERS_SUCCESS, ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_SUCCESS } from './list.actions';

export const list = (state = { fetched: false, items: [], pagination: {}, marketplace: { fetched: false, items: [] } }, action) => {
  switch (action.type) {
    case ADMIN_INTEGRATION_GET_ALL_RETAILERS_SUCCESS:
      return _.assign({}, state, { fetched: true, items: action.items, pagination: action.pagination });
    case ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_SUCCESS:
      return _.assign({}, state, {
        marketplace: _.assign({ fetched: true, items: action.items }),
        items: _.map(state.items, item => {
          return _.assign(item, { availableMarketplaces: mapAvailableMarketplaces(item, action.items) });
        })
      });
    default:
      return state;
  }
};

function mapAvailableMarketplaces(item, marketplaces) {
  const integrated_marketplaces = _.map(_.intersectionBy(_.cloneDeep(marketplaces), item.marketplaces, 'id'), marketplace => _.assign(marketplace, { selected: true }));
  const not_integrated_marketplaces = _.map(_.differenceBy(_.cloneDeep(marketplaces), item.marketplaces, 'id'), marketplace => _.assign(marketplace, { selected: false }));

  return integrated_marketplaces.concat(not_integrated_marketplaces);
}
