import { takeEvery, put, takeLatest, all, call, select } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { GET_TEMPLATES_EMAIL_REQUESTED, GET_TEMPLATES_EMAIL_SUCCEEDED, GET_INFO_MANDRILL_REQUESTED, GET_EMAILS_REQUESTED, GET_EMAILS_SUCCEEDED } from './list.actions';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';

function* fetchEmailsRequested(action) {
  try {
    const results = yield AppInjector.get(ApiService)
      .admin.email.get(action.data)
      .toPromise();
    yield put({
      type: GET_EMAILS_SUCCEEDED,
      data: results
    });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* watchFetchEmailsRequested() {
  yield takeEvery(GET_EMAILS_REQUESTED, fetchEmailsRequested);
}

function* fetchTemplatesRequested(action) {
  try {
    const results = yield AppInjector.get(ApiService)
      .admin.email.getListTemplate()
      .toPromise();
    yield put({
      type: GET_TEMPLATES_EMAIL_SUCCEEDED,
      data: results
    });
    yield put({
      type: GET_EMAILS_REQUESTED,
      data: action.data
    });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* watchFetchTemplatesRequested() {
  yield takeEvery(GET_TEMPLATES_EMAIL_REQUESTED, fetchTemplatesRequested);
}

function* fetchInfomandrillRequested(action) {
  yield put({
    type: GET_TEMPLATES_EMAIL_REQUESTED,
    data: action.data
  });
}

function* watchFetchInfoMandrillEmailRequested() {
  yield takeEvery(GET_INFO_MANDRILL_REQUESTED, fetchInfomandrillRequested);
}

export default [watchFetchInfoMandrillEmailRequested, watchFetchTemplatesRequested, watchFetchEmailsRequested];
