import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import User from '../../../models/User';
import { ServiceProvider } from '../../service.provider';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';
import { Address } from '../../../models/Address';
import Image from '../../../models/Image';
import Endpoint from '../../../models/Endpoint';
import ServiceConfiguration from '../../../models/ServiceConfiguration';
import { HttpParams } from '@angular/common/http';
import RetailerService from '../../../models/RetailerService';

@Injectable()
export class UserService extends ServiceProvider {
  public url = '/api/v1/admin/users';
  public model = User;
  public address = Address;

  profile(params: {}): Observable<any> {
    const meUrl = '/api/v1/me';
    return this.http.get(this.apiUrl.getApiUrl(meUrl) + '/profile', { params }).pipe(
      map((result) => new User((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }

  addCustomer(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data.user_id + '/customer', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  attachRoleToUser(userId, roleId): Observable<any> {
    return this.http
      .put(this.apiUrl.getApiUrl(this.url) + `/${userId}/role`, {
        role_id: roleId
      })
      .pipe(
        tap((result) => {
          console.log(result);
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  detachRoleFromUser(data): Observable<any> {
    return this.http.delete(this.apiUrl.getApiUrl(this.url) + `/${data.userId}/role/${data.roleId}`).pipe(
      tap((result) => {
        console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  saveRoleUser(userId, roles): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + `/${userId}/roles`, roles).pipe(
      tap((result) => {
        console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getCustomersOfRetailer(id, param: object = { page: 1, per_page: 100 }, sort: any = null, filter: any = null, search: any = null): Observable<any> {
    let query = [];
    let sorts = [];
    // tslint:disable-next-line:forin
    for (const prop in param) {
      query.push(prop + '=' + param[prop]);
    }
    if (sort) {
      for (const prop in sort) {
        if (sort[prop] !== null) {
          sorts.push(sort[prop] + prop);
        }
      }
    }
    AppInjector.get(PreloaderService).show();
    return this.http
      .get(
        this.apiUrl.getApiUrl(this.url) +
          '/' +
          id +
          '/customers' +
          '?' +
          _.join(query, '&') +
          (sort ? '&sort=' + _.join(sorts, ',') : '') +
          (filter ? '&constraints=' + JSON.stringify(filter) : '') +
          (search ? '&search=' + search : '')
      )
      .pipe(
        tap((result) => {
          AppInjector.get(PreloaderService).hide();
        }),
        map((result) =>
          _.assign(
            {},
            {
              items: (result as any).data.map((item) => new User(item)),
              total: (result as any).meta.pagination.total,
              pagination: new LengthAwarePaginator((result as any).meta.pagination)
            }
          )
        ),
        catchError((error) => {
          AppInjector.get(PreloaderService).hide();
          throw error;
        })
      );
  }

  changePassword(idUser, data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/' + idUser + '/change-password', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getAddressesOfUser(userId, params?) {
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/' + userId + '/addresses', { params: queryParams }).pipe(
      map((result) => _.map((result as any).data, (item) => new this.address(item))),
      catchError((error) => {
        throw error;
      })
    );
  }

  /**
   * Change User Image
   *
   * @param user_id
   * @param type
   * @param url
   */
  addImage(user_id, type, url) {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + user_id + '/images', { type, url }).pipe(
      map((result) => _.map((result as any).data, (item) => new Image(item))),
      catchError((error) => {
        throw error;
      })
    );
  }
  /**
   * Get User Profile Picture
   *
   * @param user_id
   */
  getProfilePicture(user_id) {
    const queryParams = new HttpParams({ fromObject: { user_ids: [user_id] } });
    return this.http.get(this.apiUrl.getApiUrl('/api/v1/admin/images/profile-picture'), { params: queryParams }).pipe(
      map((result) => new Image((result as any).data[0])),
      catchError((error) => {
        throw error;
      })
    );
  }

  /**
   * Get User Profile Picture of many user
   *
   * @param user_id
   */
  getProfilePictureOfManyUser(user_ids: []) {
    const queryParams = new HttpParams({ fromObject: { user_ids: user_ids } });
    return this.http.get(this.apiUrl.getApiUrl('/api/v1/admin/images/profile-picture'), { params: queryParams }).pipe(
      map((result) => _.map((result as any).data, (item) => new Image(item))),
      catchError((error) => {
        throw error;
      })
    );
  }

  changeStatus(data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + `/${data.id}/change-status`, data).pipe(
      tap((result) => {
        console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getMarketplaces(params = {}) {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/marketplaces/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllEndpointById(id, params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/${id}/list-endpoints`), JSON.stringify(params)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new Endpoint(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllServiceConfigurations(id, params = {}): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params });
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/${id}/service-configurations`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new ServiceConfiguration(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllRetailerServiceShippingCost(retailer_service_id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/retailer-services/${retailer_service_id}`)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => new RetailerService(result.data)),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  syncServiceConfigurations(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/${id}/service-configurations/sync`), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new ServiceConfiguration(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getRetailerService(id, params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/${id}/retailer-services`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new RetailerService(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: { ...params, per_page: '10000' } });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
