export const RETAILER_UPDATE_PRODUCT_REQUESTED = 'RETAILER_UPDATE_PRODUCT_REQUESTED';
export const RETAILER_UPDATE_PRODUCT_SUCCEEDED = 'RETAILER_UPDATE_PRODUCT_SUCCEEDED';
export const RETAILER_DELETE_PRODUCT_REQUESTED = 'RETAILER_DELETE_PRODUCT_REQUESTED';
export const RETAILER_DELETE_PRODUCT_SUCCEEDED = 'RETAILER_DELETE_PRODUCT_SUCCEEDED';

export const RETAILER_RENDER_EDIT_PRODUCT_FORM_REQUESTED = 'RETAILER_RENDER_EDIT_PRODUCT_FORM_REQUESTED';
export const RETAILER_GET_PRODUCT_REQUESTED = 'RETAILER_GET_PRODUCT_REQUESTED';
export const RETAILER_GET_PRODUCT_SUCCEEDED = 'RETAILER_GET_PRODUCT_SUCCEEDED';

export const RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS = 'RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS';
export const RETAILER_FILL_PRODUCT_DETAIL_FORM = 'RETAILER_FILL_PRODUCT_DETAIL_FORM';
