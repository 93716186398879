export const FETCH_RETAILER_SIZES_SUCCESS = 'FETCH_RETAILER_SIZES_SUCCESS';
export const ADMIN_ATTRIBUTES_RETAILER_SIZE_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_RETAILER_SIZE_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_RETAILER_SIZE_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_RETAILER_SIZE_UPDATE_DICTIONARY_SUCCESS';

export const ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_SIZE_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_SIZE_REQUESTED';
export const TOGGLE_SIZE_RETAILER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_SIZE_RETAILER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_SIZE_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_SIZE_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED';

export const DELETE_RETAILER_SIZE_MATCHING_REQUESTED = 'DELETE_RETAILER_SIZE_MATCHING_REQUESTED';
export const ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_SIZE_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_SIZE_REQUESTED';
