import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../template/shared/shared.module';
import { DirectivesModule } from '../../common/directives/directives.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../common/pipes/pipes.module';
import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { CredentialModule } from '../credential/credential.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    FormsModule,
    PipesModule,
    CredentialModule,
    MarketplaceRoutingModule
  ],
  declarations: [
  ]
})
export class MarketplaceModule {}
