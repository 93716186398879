import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { GET_ALL_RETAILERS_SUCCEEDED } from './retailer.action';
import { EDIT_RETAILER_SUCCEEDED, DELETE_RETAILER_SUCCEEDED } from './edit/edit.actions';
import { CREATE_RETAILER_SUCCEEDED } from './create/create.actions';
import { detail } from './detail/detail.reducer';
import { percentages } from './percentage/percentage.reducer';
import { Endpoint } from './endpoint/endpoint.reducer';
import { SmartButton } from './smart-button/smart-button.reducer';
import { CommissionConfig } from './commission-config/commission-config.reducer';

const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case CREATE_RETAILER_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case EDIT_RETAILER_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case DELETE_RETAILER_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case GET_ALL_RETAILERS_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};
export const AdminRetailer = combineReducers({
  list,
  edit,
  create,
  detail,
  all,
  CommissionConfig,
  percentages,
  Endpoint,
  SmartButton
});
