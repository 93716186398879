import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Voucher from '../../models/Voucher';
import { AppInjector } from '../../app-injector';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class VoucherService extends ServiceProvider {
  public url = '/api/v1/vouchers';
  public model = Voucher;

  sendManual(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/send-manual`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  issued(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/issued`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  wrongEmail(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/wrong-email`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  export(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/export`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  getVoucherCodeByProduct(id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url + '/' + id + '/voucher-code-by-product')).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  importCSV(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/import-csv`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
