import * as _ from 'lodash';
import { FETCH_SHIPMENT_SUCCEEDED, UPDATE_OPTION_FOR_SHIPPING_FEE_REQUESTED, UPDATE_INPUT_FILTER_DATE, SHIPMENT_FETCH_ALL_COURIERS_SUCCESSED } from './list.actions';

export const list = (state = { filter_date: [], shipping_couriers: [], warehouses: [] }, action) => {
  switch (action.type) {
    case FETCH_SHIPMENT_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    case UPDATE_INPUT_FILTER_DATE:
      action.inputs = _.map(action.inputs, (i) => {
        if (i.key === 'date_filter_type') {
          i.value = _.head(i.options);
        }
        return i;
      });
      return _.assign({}, state, { filter_date: action.inputs });
    default:
      return state;
  }
};
