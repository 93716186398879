import { put, takeEvery } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { FETCH_PRODUCT_HISTORY_REQUESTED, FETCH_PRODUCT_HISTORY_SUCCEEDED } from './history.action';

function* watchFetchProductHistoryRequested() {
  yield takeEvery(FETCH_PRODUCT_HISTORY_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService).admin.productHistory.get(action.data).toPromise();
      yield put({ type: FETCH_PRODUCT_HISTORY_SUCCEEDED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchProductHistoryRequested];
