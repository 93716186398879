import Model from '../Model';
import Retailer from '../Retailer';
import Marketplace from '../Marketplace';
import * as _ from 'lodash';
import Customer from '../Customer';

interface UserInterface {
  email: String;
  retailer: Retailer;
  marketplace: Marketplace;
  customer: Customer;
  getCompanyName(): String;
  getWebsite(): String;
  getDescription(): String;
  getNoteContent(): String;
}
export default class UserBase extends Model implements UserInterface {
  email: String;
  retailer: Retailer;
  marketplace: Marketplace;
  customer: Customer;
  images: any;
  constructor(options) {
    super();
    this.bind(options);
  }

  getCompanyName() {
    if (!_.isNil(this.retailer) && !_.isNil(this.retailer.company) && this.retailer.company !== '') {
      return this.retailer.company;
    }
    if (!_.isNil(this.marketplace) && !_.isNil(this.marketplace.company) && this.marketplace.company !== '') {
      return this.marketplace.company;
    }
    return this.email;
  }

  getWebsite() {
    if (!_.isUndefined(this.retailer)) {
      return this.retailer.website;
    }
    if (!_.isUndefined(this.marketplace)) {
      return this.marketplace.website;
    } else {
      return '';
    }
  }

  getDescription() {
    if (!_.isUndefined(this.retailer)) {
      return this.retailer.description;
    }
    if (!_.isUndefined(this.marketplace)) {
      return this.marketplace.description;
    }
    return '';
  }

  getNoteContent() {
    if (!_.isUndefined(this.retailer) && !_.isNil(this.retailer.note) && this.retailer.note !== '') {
      return this.retailer.note;
    } else {
      return '';
    }
  }

  getFullPhoneNumber() {
    let phone_area_code = '';
    let phone_number = '';
    if (!_.isUndefined(this.customer) && !_.isNil(this.customer.phone_area_code) && this.customer.phone_area_code !== '') {
      phone_area_code = this.customer.phone_area_code;
    }
    if (!_.isUndefined(this.customer) && !_.isNil(this.customer.phone_number) && this.customer.phone_number !== '') {
      phone_number = this.customer.phone_number;
    }
    return '+(' + phone_area_code + ') ' + phone_number;
  }

  getBirthContent() {
    if (!_.isUndefined(this.customer) && !_.isNil(this.customer.birth) && this.customer.birth !== '') {
      return this.customer.birth;
    } else {
      return '';
    }
  }

  getGenderContent() {
    if (!_.isUndefined(this.customer) && !_.isNil(this.customer.gender) && this.customer.gender !== '') {
      return this.customer.gender;
    } else {
      return '';
    }
  }

  getAvatar() {
    if (!_.isUndefined(this.images) && !_.isNil(this.images.data) && !_.isEmpty(this.images.data)) {
      return this.images.data[0].url;
    } else {
      return '//media.reflaunt.com/assets/images/reflaunt/reflaunt-logo.png';
    }
  }

  getMarketplaceProfilePicture() {
    return !_.isUndefined((this as any).image) && !_.isUndefined((this as any).image.url) ? (this as any).image.url : '';
  }
}
