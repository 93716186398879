import {
  EDIT_ADMIN_ORDER_ITEM_SUCCESSED,
  FETCH_ADMIN_ORDER_ITEM_SUCCESSED,
  RENDER_EDIT_ADMIN_ORDER_ITEM_FORM_REQUESTED,
  FILL_ADMIN_ORDER_ITEM_DETAIL_FORM,
  UPDATE_ADMIN_ORDER_ITEM_DETAIL_FORM_INPUT_OPTIONS
} from './edit.actions';
import * as _ from 'lodash';

export const edit = (state = { updated: false, fetched: false, inputs: [] }, action) => {
  switch (action.type) {
    case RENDER_EDIT_ADMIN_ORDER_ITEM_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_ADMIN_ORDER_ITEM_DETAIL_FORM:
    // return _.assign({}, state, { inputs: UpdateFormValue(state.inputs, action.data) });
    case UPDATE_ADMIN_ORDER_ITEM_DETAIL_FORM_INPUT_OPTIONS:
    // return _.assign({}, state, { inputs: UpdateInputsValue(state.inputs, action.data) });
    case FETCH_ADMIN_ORDER_ITEM_SUCCESSED:
      return _.assign({}, state, { fetched: true, item: action.data });

    case EDIT_ADMIN_ORDER_ITEM_SUCCESSED:
      return _.assign({}, state, { updated: true });

    default:
      return state;
  }
};
