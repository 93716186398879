import { VOUCHERS_EXIST_IN_SYSTEM_TYPE, VOUCHERS_MANUAL_TYPE, VOUCHERS_NOT_EXIST_IN_SYSTEM_TYPE } from '../../../models/Retailer';
export const ADMIN_RETAILER_COMP = 'ADMIN_RETAILER_COMP';

export const SHOPIFY_SHOP_CONFIG_KEY = 'SHOPIFY_SHOP_NAME';

export const listRouter = () => {
  return ['/', 'admin', 'retailer'];
};
export const createRouter = () => {
  return ['/', 'admin', 'retailer', 'create'];
};
export const detailRouter = (id) => {
  return ['/', 'admin', 'retailer', id, 'detail'];
};
export const editRouter = (id) => {
  return ['/', 'admin', 'retailer', id, 'edit'];
};
export const percentageRetailerRouter = (id) => {
  return ['/', 'admin', 'retailer', id, 'percentage'];
};

export const listProductByRetailerRouter = (id) => {
  return ['/', 'admin', 'product'];
};

export const listRetailerCredentialRouter = (id) => {
  return ['/', 'admin', 'retailer', id, 'credential'];
};

export const configSmartButtonRouter = (id) => {
  return ['/', 'admin', 'retailer', id, 'smart-button'];
};

export const configCommissionRouter = (id) => {
  return ['/', 'admin', 'retailer', id, 'commission-config'];
};

export const voucherTypes = [
  { value: VOUCHERS_EXIST_IN_SYSTEM_TYPE, label: 'Exist In System' },
  { value: VOUCHERS_NOT_EXIST_IN_SYSTEM_TYPE, label: 'Not Exist In System' },
  { value: VOUCHERS_MANUAL_TYPE, label: 'Manual voucher' }
];
