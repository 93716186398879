export const FETCH_DESIGNER_ATTRIBUTE_REQUESTED = 'FETCH_DESIGNER_ATTRIBUTE_REQUESTED';
export const FETCH_DESIGNER_ATTRIBUTE_SUCCESSED = 'FETCH_DESIGNER_ATTRIBUTE_SUCCESSED';
export const CREATE_DESIGNER_REQUESTED = 'CREATE_DESIGNER_REQUESTED';
export const CREATE_DESIGNER_SUCCESSED = 'CREATE_DESIGNER_SUCCESSED';
export const DELETE_DESIGNER_ATTRIBUTE_REQUESTED = 'DELETE_DESIGNER_ATTRIBUTE_REQUESTED';
export const DELETE_DESIGNER_ATTRIBUTE_SUCCESSED = 'DELETE_DESIGNER_ATTRIBUTE_SUCCESSED';
export const FETCH_DESIGNER_DICTIONARY_REQUESTED = 'FETCH_DESIGNER_DICTIONARY_REQUESTED';
export const FETCH_DESIGNER_DICTIONARY_SUCCESSED = 'FETCH_DESIGNER_DICTIONARY_SUCCESSED';
export const RESET_DESIGNER_DICTIONARY = 'RESET_DESIGNER_DICTIONARY';
export const CREATE_DESIGNER_DICTIONARY_SUCCESSED = 'CREATE_DESIGNER_DICTIONARY_SUCCESSED';
export const EDIT_DESIGNER_REQUESTED = 'EDIT_DESIGNER_REQUESTED';
export const EDIT_DESIGNER_SUCCESSED = 'EDIT_DESIGNER_SUCCESSED';
export const EDIT_DESIGNER_DICTIONARY_REQUESTED = 'EDIT_DESIGNER_DICTIONARY_REQUESTED';
export const EDIT_DESIGNER_DICTIONARY_SUCCESSED = 'EDIT_DESIGNER_DICTIONARY_SUCCESSED';
