
export const COLLECTION_GET_PRODUCT_DETAIL_SUCCEEDED = 'COLLECTION_GET_PRODUCT_DETAIL_SUCCEEDED';

export const SAVE_COLLECTION_EDIT_PRODUCT_ALL_SIZES_OPTIONS_SUCCEEDED = 'SAVE_COLLECTION_EDIT_PRODUCT_ALL_SIZES_OPTIONS_SUCCEEDED';

export const UPDATE_COLLECTION_EDIT_PRODUCT_INPUT_OPTIONS = 'UPDATE_COLLECTION_EDIT_PRODUCT_INPUT_OPTIONS';

export const RENDER_COLLECTION_EDIT_PRODUCT_FORM_REQUESTED = 'RENDER_COLLECTION_EDIT_PRODUCT_FORM_REQUESTED';

export const COLLECTION_EDIT_PRODUCT_SUCCEEDED = 'COLLECTION_EDIT_PRODUCT_SUCCEEDED';
export const COLLECTION_EDIT_PRODUCT_REQUESTED = 'COLLECTION_EDIT_PRODUCT_REQUESTED';
