import {
  ACCEPT_CONSIGNMENT_AGREEMENT_REQUESTED,
  GET_ALL_COLLECTION_DATA_REQUESTED,
  GET_ALL_COLLECTION_DATA_SUCCEEDED
} from './consignment-agreement.actions';
import { put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { listAdminCollection } from '../../admin/collection/collection.const';
import { API_CALL_ERROR } from '../../../store/action';
import { Router } from '@angular/router';


function* watchGetAllCollectionData() {
  yield takeLatest(GET_ALL_COLLECTION_DATA_REQUESTED, function*(action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).admin.collection.getCollectionData(action.data.id,
        action.data.token).toPromise();
      yield put({ type: GET_ALL_COLLECTION_DATA_SUCCEEDED, data: result });
      if (result && result.consignment_accepted_at) {
        router.navigate(['/landing-page/confirm-agreement']);
      }

    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAcceptConsignmentAgreement() {
  yield takeLatest(ACCEPT_CONSIGNMENT_AGREEMENT_REQUESTED, function*(action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).admin.collection.acceptConsignmentAgreement(action.data.id,
        action.data.params).toPromise();
      if (result) {
        router.navigate(['/landing-page/confirm-agreement']);
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchGetAllCollectionData,
  watchAcceptConsignmentAgreement
];
