import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from './../../../../store/store.module';
import { FETCH_ALL_USER_REQUESTED, DELETE_USER_REQUESTED } from './list.actions';
import { USER_COMP, customerDetailRouter } from '../user.const';
import * as _ from 'lodash';
import { FETCH_ALL_ROLE_REQUESTED } from '../../../admin/acl/roles/roles.actions';
import { detailRouter } from '../../retailer/retailer.const';
import { marketplaceDetailRouter } from '../../marketplace/marketplace.const';
import { CHANGE_USER_STATUS_REQUESTED } from '../../retailer/list/list.actions';
import { editServiceConfigurationRouter } from '../user.const';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  public role_id: any;
  public store;
  public navigationSubscription: Subscription;
  public filters = { roles: [] };
  public detailRetailerRouter = detailRouter;
  public customerDetailRouter = customerDetailRouter;
  public marketplaceDetailRouter = marketplaceDetailRouter;
  public status = [
    {
      label: 'Pending',
      value: 2
    },
    {
      label: 'Active',
      value: 1
    }
  ];
  public editServiceConfigurationRouter = editServiceConfigurationRouter;

  constructor(private notification: NotificationService, private activatedRoute: ActivatedRoute, private route: Router, store: Store) {
    this.store = store.getInstance();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: FETCH_ALL_USER_REQUESTED,
          data: _.assign(this.parseQuery(), { includes: 'roles' }),
          com: USER_COMP
        });
      }
    });
  }

  ngOnInit() {
    this.store.dispatch({
      type: FETCH_ALL_ROLE_REQUESTED,
      except: ['superadmin']
    });
  }

  filterUsers(role) {
    this.filters.roles = [role.slug];
    let url = window.location.pathname;
    let queryParams = this.activatedRoute.snapshot.queryParams;
    queryParams = _.assign({}, queryParams, { roles: this.filters.roles.join(',') });
    let params = _.assign({}, this.activatedRoute.snapshot.params, { queryParams });
    this.route.navigate([url], params);
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  removeUser(item) {
    if (!_.isUndefined(item)) {
      this.store.dispatch({
        type: DELETE_USER_REQUESTED,
        data: item.getId(),
        com: USER_COMP
      });
    }
  }

  public parseQuery(): object {
    let limit = 100;
    let supportedParams = ['sort', 'constraints', 'page', 'search', 'roles'];
    let queryParams = { sort: '-created_at' };
    if (_.keys(this.activatedRoute.snapshot.queryParams).length > 0) {
      queryParams = _.assign(queryParams, this.activatedRoute.snapshot.queryParams);
    }
    return _.pick(queryParams, supportedParams);
  }

  changeUserStatus(item) {
    this.store.dispatch({
      type: CHANGE_USER_STATUS_REQUESTED,
      data: item
    });
  }
}
