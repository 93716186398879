export const RENDER_EDIT_CUSTOMER_FORM_REQUESTED = 'RENDER_EDIT_CUSTOMER_FORM_REQUESTED';

export const ADMIN_GET_CUSTOMER_REQUESTED = 'ADMIN_GET_CUSTOMER_REQUESTED';
export const ADMIN_GET_CUSTOMER_SUCCEEDED = 'ADMIN_GET_CUSTOMER_SUCCEEDED';

export const FILL_CUSTOMER_DETAIL_FORM = 'FILL_CUSTOMER_DETAIL_FORM';

export const UPDATE_EDIT_CUSTOMER_INPUT_OPTIONS = 'UPDATE_EDIT_CUSTOMER_INPUT_OPTIONS';

export const ADMIN_UPDATE_CUSTOMER_REQUESTED = 'ADMIN_UPDATE_CUSTOMER_REQUESTED';
export const ADMIN_UPDATE_CUSTOMER_SUCCEEDED = 'ADMIN_UPDATE_CUSTOMER_SUCCEEDED';

export const CHANGE_PAYMENT_METHOD_REQUESTED = 'CHANGE_PAYMENT_METHOD_REQUESTED';
export const PREFILL_CHANGE_PAYMENT_METHOD_REQUESTED = 'PREFILL_CHANGE_PAYMENT_METHOD_REQUESTED';
export const UPDATE_OR_CREATE_PAYMENT_DETAIL = 'UPDATE_OR_CREATE_PAYMENT_DETAIL';





