import {
    FETCH_TRANSACTION_HISTORY_CUSTOMER_REQUESTED,
    FETCH_TRANSACTION_HISTORY_CUSTOMER_SUCCEEDED
} from './transaction-history.actions';
import { takeEvery, put } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';


function* watchFetchTransactionHistoryCustomerRequested() {
  yield takeEvery(FETCH_TRANSACTION_HISTORY_CUSTOMER_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .transactionHistory.get(action.data)
        .toPromise();
      yield put({ type: FETCH_TRANSACTION_HISTORY_CUSTOMER_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchTransactionHistoryCustomerRequested];
