import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ServiceProvider } from '../service.provider';
import OAuthClient from '../../models/OAuthClient';

@Injectable()
export class OAuthClientService extends ServiceProvider {
  public url = '/api/v1/oauth-clients';
  public model = OAuthClient;

  showCredentialAfterCreateSuccess(data) {
    return new Promise((resolve, reject) => {
      const detail = `
      <div class="credential-info__wrapper">
        <div class="credential-info__item">
          <span class="credential-info__item__label">Client Key: </span><span>${data.getClientId()}</span>
        </div>
        <div class="credential-info__item">
          <span class="credential-info__item__label">Client Secret: </span><span>${data.getClientSecret()}</span>
        </div>
      </div>
      `;
      (Swal as any)
        .fire({
          title: 'Generate Credential Success',
          type: 'success',
          html: detail,
          showCloseButton: true,
          focusConfirm: true,
          confirmButtonText: 'OK'
        })
        .then(result => {
          resolve(result);
        });
    });
  }
}
