import Model from "./Model";

export const COMMON_TAG_TYPE = 1;
class Tag extends Model {
    constructor(options) {
        super();
        this.bind(options);
    }
}

export default Tag;
