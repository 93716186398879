import Model from './Model';


export const MARKETPLACE_EVENTS = [
  {
    name: "New shipping label",
    value: "new_shipping_label"
  },
  {
    name: "Shipment update",
    value: "shipment_update"
  },
  {
    name: "Order product update",
    value: "order_product_update"
  },
  {
    name: "Marketplace api shipment update",
    value: "marketplace_api_shipment_update"
  },
]
class Endpoint extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default Endpoint;
