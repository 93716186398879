import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import Shipment from '../../../models/Shipment';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';
import Product from '../../../models/Product';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

@Injectable()
export class ShipmentService extends ServiceProvider {
  public url = '/api/v1/admin/shipments';
  public urlv2 = '/api/v2/admin/shipments';

  public model = Shipment;

  getLandingPageShipment(id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/landing-page/' + id).pipe(
      tap((result) => {}),
      map((result) => {
        if (Object.keys(result).length > 0) {
          return new this.model((result as any).data);
        } else {
          return null;
        }
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  buyShippingLabel(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data.shipmentId + '/buy-shipping-label', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  invalidateToken(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data.shipmentId + '/token/invalidate', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getRetrieveAvailable(id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/' + id + '/retrieve-available').pipe(
      tap((result) => {}),
      catchError((error) => {
        throw error;
      })
    );
  }

  sendShipmentToSeller(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data.shipmentId + '/send-to-seller', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  sendConfirmAddressToSeller(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl('/api/v1/transfer/confirm-address'), data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }


  getCourierRates(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/rates', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  updateStatusShippingByOrderProductId(order_product_id, data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + order_product_id + '/update-status-shipment-by-order-product-id', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  reGenerateShippingLabel(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/re-generate-shipping-label', data,{ headers: { Authorization: `Bearer ${this.token}` } }).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  
  generateCustomShippingLabel(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.urlv2) + '/custom-generate-shipping-label', data,{ headers: { Authorization: `Bearer ${this.token}` } }).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }

  generateShippingInvoice(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.urlv2) + '/generate-shipping-invoice', data,{ headers: { Authorization: `Bearer ${this.token}` } }).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }

  getAllProduct(id): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + `/${id}/get-all-product`).pipe(
      tap((result) => {}),
      map((result) => _.map((result as any).data, (item) => new Product(item))),
      catchError((error) => {
        throw error;
      })
    );
  }
  getForwardShipment(id): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + `/${id}/get-forward-shipment`).pipe(
      tap((result) => {}),
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  getAllFulfilmentShipment(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/get-all-fulfilment-shipment', data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) =>
        _.assign(
          {},
          {
            items: (result as any).data.map((item) => new this.model(item)),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  exportCsv(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/export-csv', data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  sendNotificationWhenShippingLabelUpload(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + `/${id}/send-notification-when-label-upload`).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  sendNotificationWhenShippingNoteEdited(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + `/${id}/send-notification-when-note-edit`).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  cancelShipment(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + `/cancel-shipment`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getShipingMethodsRates(): Observable<any> {
    return  this.http.get(this.apiUrl.getApiUrl(this.urlv2) + '/shipping-methods-rates',{ headers: { Authorization: `Bearer ${this.token}` } }).pipe(
      tap((result) => {
        //console.log(result);
      }),
     catchError((error) => {
        //console.log(error);
        throw error;
      })
    );
  }
}
