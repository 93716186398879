import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import ServiceType from '../../../models/ServiceType';

@Injectable()
export class ServiceTypeService extends ServiceProvider {
  public url = '/api/v1/admin/service-types';
  public model = ServiceType;
}
