import { BaseComponent } from '../../../base.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ADD_DATASET_TO_GROUP_REQUEST, ADD_TO_GROUP_GET_LIST_GROUP_DATASET_REQUEST } from './add-to-group.actions';
import { FormBuilder, Validators } from '@angular/forms';
import { editRouter, listRouter } from '../dataset.const';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-to-group',
  templateUrl: './add-to-group.component.html',
  styleUrls: ['./add-to-group.component.scss']
})
export class AddToGroupComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.Dataset.addToGroup';
  public addToGroupFrom = this.fb.group({
    group: ['', Validators.required]
  });
  public data = [];
  public datasetId;
  public listRouter = listRouter;
  public editRouter = editRouter;

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    super();
    this.datasetId = Number(this.activatedRoute.snapshot.params.id);
    const { dispatchGetListGroupDatasetRequest } = this as any;
    this.navigationSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      dispatchGetListGroupDatasetRequest();
    });
  }

  get group() {
    return this.addToGroupFrom.get('group');
  }

  ngOnInit() {
    this.init();
  }

  onSubmit() {
    const { dispatchAddDatasetToGroupRequest } = this as any;
    this.addToGroupFrom.markAllAsTouched();
    if (this.addToGroupFrom.valid) {
      const groupId = this.addToGroupFrom.value.group;
      const childrenIds = [this.datasetId];
      const data = {
        children_ids: childrenIds
      };
      dispatchAddDatasetToGroupRequest(groupId, data, this.datasetId);
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Dataset.addToGroup
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch,
      dispatchGetListGroupDatasetRequest: () => {
        dispatch({ type: ADD_TO_GROUP_GET_LIST_GROUP_DATASET_REQUEST });
      },
      dispatchAddDatasetToGroupRequest: (groupId, payload, datasetId) => {
        dispatch({ type: ADD_DATASET_TO_GROUP_REQUEST, data: { groupId, payload, datasetId } });
      }
    };
  }
}
