import { PENDING_STATUS, SELLING_STATUS } from './../../../../models/Status';
import * as _ from 'lodash';
import {
  FETCH_RETAILER_PRODUCTS_SUCCEEDED,
  SELECT_ALL_RETAILER_PRODUCT,
  FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED,
  FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_SUCCEEDED,
  RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED,
  RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED
} from './list.actions';
import { ProductStatuses } from '../../../../app.const';
import { GET_PRODUCT_DETAIL_SUCCEEDED } from '../../../admin/product/detail/detail.actions';
import {
  FETCH_ALL_CONDITION_SUCCESSED,
  FETCH_ALL_DESIGNER_SUCCESSED,
  FETCH_ALL_MARKETPLACE_SUCCESSED,
  FETCH_ALL_CATEGORY_SUCCESSED,
  SELECT_ALL_PRODUCT
} from '../../../admin/product/list/list.actions';
const Product = (state: any = {}, action) => {
  switch (action.type) {
    case FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED:
      return _.assign(state, { image: _.find(action.data, (item) => item.object_id === state.getId()) });
    case RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED:
      const product = _.find(action.data, (item) => item.id === state.id);
      if (!_.isUndefined(product)) {
        return _.assign(state, { marketplaces: product.marketplaces });
      } else {
        return state;
      }
    case RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED: {
      return _.assign(state, { availableMarketplaces: _.differenceBy(_.clone(action.data), state.marketplaces, 'id') });
    }
    default:
      return state;
  }
};

export const list = (state = { fetched: false, items: [], items_status: [], integrated_marketplaces: [] }, action) => {
  switch (action.type) {
    case FETCH_RETAILER_PRODUCTS_SUCCEEDED:
      const { retailer_services } = action;
      action.data = _.map(action.data, (item) => {
        if (!_.isNil(item.status)) {
          item.status_item = _.find(ProductStatuses, { value: item.status });
        }
        return item;
      });
      const productSelected = JSON.parse(localStorage.getItem('productRetailerSelected'));
      const data = _.map(action.data, (item) => {
        let total = item.product_items.filter((i) => {
          return i.status.slug === PENDING_STATUS || i.status.slug === SELLING_STATUS;
        }).length;
        if (item.service_type_id) {
          const primaryLocation = retailer_services.find((i) => i.service_type_id === item.service_type_id);
          let primaryLocationId = primaryLocation ? primaryLocation.default_location_id : 0;
          let productAtPrimaryLocation = [];
          productAtPrimaryLocation = item.product_items.filter((i) => {
            if (i.product_item_location[0]) {
              return i.product_item_location[0].location_id == primaryLocationId;
            }
          });
          const productPendingAtPrimaryLocation = productAtPrimaryLocation.filter((i) => {
            return i.status.slug === PENDING_STATUS || i.status.slug === SELLING_STATUS;
          });
          let extraStockAtReflaunt = total - productPendingAtPrimaryLocation.length;
          _.assign(item, { extraStockAtReflaunt: extraStockAtReflaunt, productAtPrimaryLocation: productPendingAtPrimaryLocation.length });
        }
        if (productSelected && _.find(productSelected, (i) => i.id === item.id)) {
          _.assign(item, { selected: true });
        } else {
          _.assign(item, { selected: false });
        }
        return item;
      });
      return _.assign({}, state, {
        fetched: true,
        items: data,
        pagination: action.pagination,
        com: action.com,
        items_status: _.map(action.data, (item) => {
          return {
            id: item.id,
            status_id: item.product_items.length ? item.product_items[0].status_id : 0,
            product_item_id: item.product_items.length ? item.product_items[0].id : 0
          };
        })
      });

    case SELECT_ALL_RETAILER_PRODUCT:
      return _.assign({}, state, { fetched: true, items: _.map(state.items, (item) => _.assign(item, { selected: action.selected })), total: action.total });

    case RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED:
    case FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED:
      return _.assign({}, state, { items: _.map(state.items, (item) => Product(item, action)) });

    case RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, {
        items: _.map(state.items, (item) => Product(item, action)),
        integrated_marketplaces: _.filter(action.data, (item) => item.marketplace.is_integrated)
      });
    case FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_SUCCEEDED:
      let products = state.items;
      products = _.map(products, (item) => {
        if (!_.isNil(item.seller_id) && item.seller_id !== 0) {
          item.seller = _.find(action.data, { id: item.seller_id });
        }
        return item;
      });
      return _.assign({}, state, { items: products });
    case GET_PRODUCT_DETAIL_SUCCEEDED:
      return _.assign({}, state, {
        items_status: _.map(state.items_status, (item) => {
          if (item.id === action.data.id) {
            item.status_id = action.data.product_items ? action.data.product_items[0].status_id : 0;
          }
          return item;
        })
      });
    case 'RETAILER_REMOVE_ALL_SELECTED_PRODUCT':
      return _.assign({}, state, {
        items: _.map(state.items, (item) => _.assign(item, { selected: false }))
      });
    default:
      return state;
    case SELECT_ALL_PRODUCT:
      return _.assign({}, state, { fetched: true, items: _.map(state.items, (item) => _.assign(item, { selected: true })), total: action.total });
    case FETCH_ALL_CONDITION_SUCCESSED:
      return _.assign({}, state, { conditions: action.data });
    case FETCH_ALL_DESIGNER_SUCCESSED:
      return _.assign({}, state, { designers: action.data });
    case FETCH_ALL_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, { fetched: true, marketplaces: action.data });
    case FETCH_ALL_CATEGORY_SUCCESSED:
      return _.assign({}, state, { fetched: true, categories: action.data });
  }
};
