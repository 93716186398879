export const ADMIN_FETCH_WEBHOOK_REQUESTED = 'ADMIN_FETCH_WEBHOOK_REQUESTED';
export const ADMIN_FETCH_WEBHOOK_SUCCEEDED = 'ADMIN_FETCH_WEBHOOK_SUCCEEDED';


export const ADMIN_CREATE_WEBHOOK_REQUESTED = 'ADMIN_CREATE_WEBHOOK_REQUESTED';
export const ADMIN_CREATE_WEBHOOK_SUCCEEDED = 'ADMIN_CREATE_WEBHOOK_SUCCEEDED';

export const ADMIN_UPDATE_WEBHOOK_REQUESTED = 'ADMIN_UPDATE_WEBHOOK_REQUESTED';
export const ADMIN_UPDATE_WEBHOOK_SUCCEEDED = 'ADMIN_UPDATE_WEBHOOK_SUCCEEDED';


export const ADMIN_DELETE_WEBHOOK_REQUESTED = 'ADMIN_DELETE_WEBHOOK_REQUESTED';
export const ADMIN_DELETE_WEBHOOK_SUCCEEDED = 'ADMIN_DELETE_WEBHOOK_SUCCEEDED';
