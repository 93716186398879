import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../base.component';
import {
    RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_REQUEST,
    FETCH_2ND_HAND_SALES_REQUESTED
} from './second-hand-sales.const';
import { ApiService } from '../../../api/api.service';

@Component({
  selector: 'app-second-hand-sales',
  templateUrl: './second-hand-sales.component.html',
  styleUrls: ['./second-hand-sales.component.scss', '../../../../../node_modules/c3/c3.min.css', '../../../../assets/charts/radial/css/radial.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecondHandSalesComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.SecondHandSales';
  isQuicksight = false;
  constructor(private api: ApiService) {
    super();
  }

  ngOnInit() {
    this.init();
    this.api.user.profile({}).subscribe((res) => {
      if (res.retailer.quicksight_2nd_hand_sales_id) {
        this.isQuicksight = true;
        this.dispatch({ type: FETCH_2ND_HAND_SALES_REQUESTED });
      } else {
        this.isQuicksight = false;
        this.dispatch({ type: RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST, data: 12 });
        this.dispatch({ type: RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST, data: 12 });
        this.dispatch({ type: RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_REQUEST });
        this.dispatch({ type: RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_REQUEST });
        this.dispatch({ type: RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_REQUEST });
      }
    });
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  get2Digital(number) {
    const data = number ? number : 0;
    return data.toFixed(2);
  }

  mapStateToProps(state) {
    return { payload: state.Retailer.SecondHandSales };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
