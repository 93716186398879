import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import list from './list/list.saga';
import { GET_ALL_ADMIN_FULFILMENT_REQUESTED, GET_ALL_ADMIN_FULFILMENT_SUCCESSED } from './fulfilment.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAllFulfilments(params?) {
  const fetched = yield select((state) => (state as any).Admin.Fulfilment.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.fulfilment.list(params).toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select((state) => (state as any).Admin.Fulfilment.all.items);
    return data;
  }
}

function* watchFetchAllFulfilmentsRequest() {
  yield takeLatest(GET_ALL_ADMIN_FULFILMENT_REQUESTED, function* (action: any) {
    const data = yield call(fetchAllFulfilments);
    yield put({ type: GET_ALL_ADMIN_FULFILMENT_SUCCESSED, data: data });
  });
}

export default [...list, watchFetchAllFulfilmentsRequest];
