import Model from './Model';

export enum SERVICE_TYPES {
  BUYBACK = 'buyback',
  SMART_BUTTON = 'smart-button',
  TAKEBACK = 'takeback'
}
class ServiceType extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default ServiceType;
