import { combineReducers } from 'redux';
import { consignmentAgreement } from './consignment-agreement/consignment-agreement.reducer'
const all = (state = { fetched: false, items: []}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export const ProxyPage = combineReducers({
  consignmentAgreement, all
});
