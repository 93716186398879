import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { ActivityLog } from '../../../models/ActivityLog';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';


@Injectable()
export class ActivityLogService extends ServiceProvider {
  public url = '/api/v1/admin/activity-log';
  public model = ActivityLog;

  exportCsv(params): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/export`, { params: queryParams }).pipe(
      map((result) => {
        return result
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
