import { PreloaderService } from './../../../../common/services/preloader/preloader.service';
import { ApiService } from './../../../../api/api.service';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';

import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { Router } from '@angular/router';
import { CREATE_PERCENTAGE_REQUESTED, CREATE_PERCENTAGE_SUCCEEDED } from './create.actions';

function* watchCreatePercentageRequested() {
  yield takeEvery(CREATE_PERCENTAGE_REQUESTED, function*(action: any) {
    try {
      const router = AppInjector.get(Router);
      let results = yield AppInjector.get(ApiService)
        .percentage.create(action.data)
        .toPromise();
      yield put({ type: CREATE_PERCENTAGE_SUCCEEDED, data: results });
      AppInjector.get(NotificationService).show('success', 'Percentage created success!', 3000);
      router.navigate(['retailer/percentage']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchCreatePercentageRequested];
