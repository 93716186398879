import { PreloaderService } from './../../../common/services/preloader/preloader.service';
import { AppInjector } from './../../../app-injector';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import Tag from '../../../models/Tag';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class TagService extends ServiceProvider {
  public url = '/api/v1/admin/tags';
  public model = Tag;

  addTagsToProducts(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/add-to-products', data).pipe(
      tap((result) => {}),
      catchError((error) => {
        throw error;
      })
    );
  }
  removeTag(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/remove-to-products', data).pipe(
      tap((result) => {}),
      catchError((error) => {
        throw error;
      })
    );
  }

  getProductTags(id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/product/${id}`)).pipe(
      tap((result) => {}),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        throw error;
      })
    );
  }

  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
