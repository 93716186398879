import ServiceConfiguration from './service-configuration/service-configuration.saga';
import Address from './address/address.saga';
import * as _ from 'lodash';
import listUserSaga from './list/list.saga';
import createUser from './create/create.saga';
import editUser from './edit/edit.saga';
import { takeEvery, put, select, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../store/action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import {
  GET_ALL_USERS_SUCCEEDED,
  GET_ALL_USERS_REQUESTED,
  CHANGE_USER_IMAGE_REQUESTED,
  CHANGE_USER_IMAGE_SUCCESSED,
  GET_USER_PROFILE_IMAGE_REQUESTED,
  GET_USER_PROFILE_IMAGE_SUCCESSED
} from './user.actions';
import {
    UPDATE_OR_CREATE_SMART_OBJECT_REQUESTED,
    UPDATE_OR_CREATE_SMART_OBJECT_SUCCEEDED
} from './user.const';
import { NotificationService } from '../../../common/services/notification/notification.service';

export function* fetchAllUsers() {
  const fetchUser = yield select(state => (state as any).Admin.Users.all.fetched);
  if (!fetchUser) {
    try {
      let result = yield AppInjector.get(ApiService)
        .user.list()
        .toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select(state => (state as any).Admin.Users.all.items);
    return data;
  }
}

function* watchFetchAllUsersRequested() {
  yield takeEvery(GET_ALL_USERS_REQUESTED, function*(action: any) {
    const data = yield call(fetchAllUsers);
    yield put({ type: GET_ALL_USERS_SUCCEEDED, data: data });
  });
}

function* watchChangeUserAvatarRequested() {
  yield takeEvery(CHANGE_USER_IMAGE_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const request = yield api.admin.user.addImage(action.data.user_id, 'avatar', action.data.url).toPromise();
      yield put({ type: CHANGE_USER_IMAGE_SUCCESSED, data: request });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchFetchUserProfilePictureRequested() {
  yield takeEvery(GET_USER_PROFILE_IMAGE_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const data = yield call(fetchUserProfilePicture, action.data.user_id);
      yield put({ type: GET_USER_PROFILE_IMAGE_SUCCESSED, data: data });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* fetchUserProfilePicture(user_id) {
  let result = yield AppInjector.get(ApiService)
    .admin.user.getProfilePicture(user_id)
    .toPromise();
  return result;
}

export function* fetchUserProfilePictureOfManyUser(user_ids: any) {
  let result = yield AppInjector.get(ApiService)
    .admin.user.getProfilePictureOfManyUser(user_ids)
    .toPromise();
  return result;
}

function* watchUpdateOrCreateSmartObjectRequested() {
  yield takeEvery(UPDATE_OR_CREATE_SMART_OBJECT_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const data = yield api.admin.smartObject.updateOrCreate(action.data).toPromise();
      yield put({ type: UPDATE_OR_CREATE_SMART_OBJECT_SUCCEEDED, data: data });
      AppInjector.get(NotificationService).show('success', `Update successed`, 2000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [...ServiceConfiguration, ...Address, ...listUserSaga, ...createUser, ...editUser, watchFetchAllUsersRequested, watchChangeUserAvatarRequested, watchFetchUserProfilePictureRequested, watchUpdateOrCreateSmartObjectRequested];
