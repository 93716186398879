import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import ItemsCost from '../../../models/ItemsCost';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class CustomsCostService extends ServiceProvider {
  public url = '/api/v1/customs-cost';
  public model = ItemsCost;


  uploadCsv(params): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/upload`, params).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map((result) => {
        return result;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  exportCsv(params?): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/export`, params).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map((result) => {
        return result;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
}
