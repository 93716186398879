import {
  EDIT_RETAILER_SUCCEEDED,
  GET_RETAILER_SUCCEEDED,
  RENDER_EDIT_RETAILER_FORM_REQUESTED,
  FILL_RETAILER_DETAIL_FORM,
  UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
  SET_SHIPPING_METHOD_RULE_CONSTANTS,
  GET_COURIER_RATES_SUCCEEDED,
  UPDATE_COUNTRIES_INPUT_OPTIONS,
  RETAILER_UPDATE_INPUTS,
  RETAILER_REMOVE_INPUTS
} from './edit.actions';
import * as _ from 'lodash';
import { object_types } from '../../../../models/SmartObject';
import { Dropdown, support } from '@reflaunt/reactive-form';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case FILL_RETAILER_DETAIL_FORM:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
      }
      if (!_.isUndefined(_.get(action.data.retailer, state.key))) {
        state.value = _.get(action.data.retailer, state.key);
      }
      return state;
    default:
      return state;
  }
};

export const edit = (
  state = { updated: false, fetched: false, inputs: [], default_address: {}, shipping_method_rule_constants: null, show_shipping_mask: false, countries: [], item: {} },
  action
) => {
  switch (action.type) {
    case RENDER_EDIT_RETAILER_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_RETAILER_DETAIL_FORM:
      let default_address: any;
      const { address } = action.data;
      if (!_.isNil(address) && !_.isEmpty(address)) {
        default_address = _.find(address, (i) => i.is_default);
        action.default_address = !_.isNil(default_address) ? default_address : _.head(address);
        if (action.default_address.country_id) {
          action.default_address.country_id = _.find(state.countries, (e) => e.id === action.default_address.country_id);
        }
      }
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (item) => Input(item, action)).map((i) => {
          if (i.key === 'shipping_method_id') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.shipping_method_id);
            return i;
          }
          if (i.key === 'blockchain_provider_id') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.blockchain_provider_id);
            return i;
          }
          if (i.key === 'voucher_type') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.voucher_type);
            return i;
          }
          if (i.key === 'dropoff_status') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.dropoff_status);
            return i;
          }
          if (i.key === 'default_is_stock_image') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.default_is_stock_image);
            return i;
          }
          if (i.key === 'is_require_retouch_image') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.is_require_retouch_image);
            return i;
          }
          if (i.key === 'are_images_aldready_retouched') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.are_images_aldready_retouched);
            return i;
          }
          if (i.key === 'is_notify_when_order_placed') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.is_notify_when_order_placed);
            return i;
          }
          if (i.key === 'number_of_retouch_image_required') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.number_of_retouch_image_required);
            return i;
          }
          if (i.key === 'quicksight_dashboard_id') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.quicksight_dashboard_id);
            return i;
          }
          if (i.key === 'quicksight_resale_activity_id') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.quicksight_resale_activity_id);
            return i;
          }
          if (i.key === 'quicksight_2nd_hand_sales_id') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.quicksight_2nd_hand_sales_id);
            return i;
          }
          if (i.key === 'quicksight_repurchase_id') {
            i.value = _.find(i.options, (item) => item.value === action.data.retailer.quicksight_repurchase_id);
            return i;
          }
          if (!_.isNil(action.default_address) && !_.isUndefined(_.get(action.default_address, i.key))) {
            i.value = _.get(action.default_address, i.key);
          }
          return i;
        })
      });
    case GET_RETAILER_SUCCEEDED:
      const smartObjects = _.cloneDeep(object_types);
      _.forEach(smartObjects, function (value: any) {
        const check = _.find(action.data.smart_objects, (i) => i.type === value.type);
        if (check) {
          value.url = check.url;
        }
      });
      if (action.data.user_id_override && state.shipping_method_rule_constants.retailers) {
        const retailer_parent_ids = action.data.user_id_override.split(',');
        if (retailer_parent_ids.length > 0) {
          action.data.user_id_override = state.shipping_method_rule_constants.retailers.filter((e) => retailer_parent_ids.indexOf(String(e.id)) > -1);
        }
      }
      if (action.data.user_configs && action.data.user_configs.length > 0) {
        action.data.store_app_config = JSON.stringify(action.data.user_configs[0].store_app_config);
      }
      return _.assign({}, state, { fetched: true, item: action.data, smart_objects: smartObjects });
    case EDIT_RETAILER_SUCCEEDED:
      return _.assign({}, state, { updated: true });
    case SET_SHIPPING_METHOD_RULE_CONSTANTS:
      return _.assign({}, state, {
        shipping_method_rule_constants: action.data
      });
    // case UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM:
    //   state.inputs = support.UpdateInputsValue(state.inputs, action.data);
    //   return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (input) => {
          if (input.key === action.input) {
            input.options = action.data.options;
          }
          return input;
        })
      });
    case RETAILER_UPDATE_INPUTS:
      return _.assign(state, {
        inputs: [...state.inputs, ...action.data].map((i) => {
          if (i.key === 'is_require_retouch_image') {
            i.value = _.find(i.options, (item) => item.value === action.value);
            return i;
          }
          return i;
        })
      });
    case RETAILER_REMOVE_INPUTS:
      const newInputs = _.filter(state.inputs, (item) => {
        return item.key !== 'are_images_aldready_retouched';
      }).map((i) => {
        if (i.key === 'is_require_retouch_image') {
          i.value = _.find(i.options, (item) => item.value === action.data);
          return i;
        }
        return i;
      });
      return _.assign({}, state, {
        inputs: newInputs
      });
    case UPDATE_COUNTRIES_INPUT_OPTIONS:
      return _.assign({}, state, {
        countries: action.data.countries
      });
    case GET_COURIER_RATES_SUCCEEDED:
      return _.assign({}, state, {
        shipping_method_rule_constants: _.assign(state.shipping_method_rule_constants, { rates: action.data.data }),
        show_shipping_mask: false
      });
    default:
      return state;
  }
};
