import { Component, OnInit } from '@angular/core';
import { listRouter, editRouter } from '../product.const';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { GET_PRODUCT_DETAIL_REQUESTED, GET_DETAIL_PRODUCT_REQUESTED } from './detail.actions';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent extends BaseComponent implements OnInit {
  public listRouter: Function = listRouter;
  public editRouter: Function = editRouter;
  public reducer: String = 'Admin.Product.detail';
  constructor(private router: Router, private activateRouter: ActivatedRoute) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        // dispatch an action
        const id = this.activateRouter.snapshot.params.id;
        this.dispatch({type: GET_DETAIL_PRODUCT_REQUESTED, data: id});
      }
    });
  }

  ngOnInit() {
    this.init();
  }
  mapStateToProps(state) {
    return {
      payload: state.Admin.Product.detail
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }
}
