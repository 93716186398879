import { FETCH_FULL_TRANSFER_HISTORY_EVENTS_SUCCEEDED, FETCH_FULL_TRANSFER_HISTORY_EVENTS_REQUESTED } from './list.actions';
import { API_CALL_ERROR } from '../../../store/action';
import { AppInjector } from '../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../api/api.service';
import { put, takeLatest } from 'redux-saga/effects';

function* fetchFullTransferHistoryEvents(action) {
  try {
    const result = yield AppInjector.get(ApiService)
      .blockchain.get(action.data)
      .toPromise();
    yield put({ type: FETCH_FULL_TRANSFER_HISTORY_EVENTS_SUCCEEDED, data: result.items, pagination: result.pagination });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchFullTransferHistoryEventsRequested() {
  yield takeLatest(FETCH_FULL_TRANSFER_HISTORY_EVENTS_REQUESTED, fetchFullTransferHistoryEvents);
}

export default [watchFetchFullTransferHistoryEventsRequested];
