import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import SmartObject from '../../../models/SmartObject';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable()
export class SmartObjectService extends ServiceProvider {
  public url = '/api/v1/admin/smart-object';
  public model = SmartObject;

  updateOrCreate(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/updateOrCreate', data).pipe(
      tap(result => {
        // console.log(result);
      }),
      map(result => {
        return new this.model((result as any).data);
      }),
      catchError(error => {
        throw error;
      })
    );
  }
}
