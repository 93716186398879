import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Order from '../../models/Order';

@Injectable()
export class OrderService extends ServiceProvider {

  public url = '/api/v1/orders';
  public model = Order;

}
