import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

@Component({
  selector: 'app-quicksight-dashboard',
  templateUrl: './quicksight-dashboard.component.html',
  styleUrls: ['./quicksight-dashboard.component.scss']
})
export class QuicksightDashboardComponent implements OnInit, OnChanges {
  @Input() dashboardUrl: string;
  dashboard;

  constructor() {
  }

  isEmbeddedUrlChanged() {
    if (this.dashboard) {
      if (this.dashboard.getUrl() !== this.dashboardUrl) {
        return true;
      }
    }
    return false;
  }

  renderQuicksight() {
    if (this.dashboardUrl && (!this.dashboard || this.isEmbeddedUrlChanged())) {
      const options = {
        url: this.dashboardUrl,
        container: document.getElementById("embeddingContainer"),
        parameters: {
          country: "United States",
          states: [
            "California",
            "Washington"
          ]
        },
        scrolling: "no",
        height: "AutoFit",
        locale: "en-US",
        footerPaddingEnabled: true,
        printEnabled: true
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(options);
      this.dashboard.on("error", (err) => {
        console.log(err);
      });
      this.dashboard.on("load", (data) => {
        console.log(data);
      });
    }
  }

  ngOnInit() {
    this.renderQuicksight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.renderQuicksight();
  }
}
