import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { ProductCatalogue } from '../../../models/ProductCatalogue';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class ProductCatalogueService extends ServiceProvider {
  public url = '/api/v1/admin/product-catalogues';
  public model = ProductCatalogue;

  get(params): Observable<any> {
    this.preloader.show();

    const result = this.http.get(this.apiUrl.getApiUrl(`${this.url}/${params.id}`))

    return result
  }
}
