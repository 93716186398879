export const ATTRIBUTE_COMPONENT = 'ATTRIBUTE_COMPONENT';
export const GET_ALL_ATTRIBUTES_REQUESTED = 'GET_ALL_ATTRIBUTES_REQUESTED';
export const GET_ALL_ATTRIBUTES_SUCCEEDED = 'GET_ALL_ATTRIBUTES_SUCCEEDED';
export const FETCH_DICTIONARY_BY_RETAILER = 'FETCH_DICTIONARY_BY_RETAILER';
export const ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_SUCCESS';
export const ADMIN_ATTRIBUTES_SELECT_RETAILER = 'ADMIN_ATTRIBUTES_SELECT_RETAILER';
export const ADMIN_ATTRIBUTES_PRESELECT_RETAILER = 'ADMIN_ATTRIBUTES_PRESELECT_RETAILER';
export const ADMIN_ATTRIBUTES_PRESEARCH_RETAILER = 'ADMIN_ATTRIBUTES_PRESEARCH_RETAILER';
export const ADMIN_ATTRIBUTES_RETAILER_SELECT_ANY = 'ADMIN_ATTRIBUTES_RETAILER_SELECT_ANY';
export const ADMIN_ATTRIBUTES_RETAILER_FILTER_NONE = 'ADMIN_ATTRIBUTES_RETAILER_FILTER_NONE';
export const REQUEST_NEW_ATTRIBUTE_REQUESTED = 'REQUEST_NEW_ATTRIBUTE_REQUESTED';
