import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { USER_ROLES_SLUG } from '../../../../app.const';
import {
  ADMIN_INTEGRATION_GET_ALL_RETAILERS_REQUEST,
  ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_REQUEST,
  ADMIN_INTEGRATION_RETAILER_SYNC_MARKETPLACE_REQUEST,
  ADMIN_INTEGRATION_REDIRECT_TO_MANAGERMENT_REQUEST
} from './list.actions';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseComponent implements OnInit, OnDestroy {
  public reducer = 'Admin.Integration.list';
  public retailerParams = {
    roles: USER_ROLES_SLUG.RETAILER,
    includes: 'marketplaces'
  };
  public marketplaceParams = {
    roles: USER_ROLES_SLUG.MARKETPLACE,
    includes: 'marketplace'
  };
  public availableParams = ['sort', 'constraints', 'page', 'search', 'email'];
  constructor(private activedRoute: ActivatedRoute, private router: Router) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const requestQueryParams = this.queryParser.parse(this.availableParams);
        const data = _.assign({}, requestQueryParams, this.retailerParams);
        this.dispatch({ type: ADMIN_INTEGRATION_GET_ALL_RETAILERS_REQUEST, data: data, marketplace_params: this.marketplaceParams });
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.Integration.list
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch
    };
  }

  integrate(item) {
    const marketplaces = _.filter(item.availableMarketplaces, i => i.selected === true);
    this.dispatch({ type: ADMIN_INTEGRATION_RETAILER_SYNC_MARKETPLACE_REQUEST, id: item.id, data: { marketplace_ids: _.map(marketplaces, marketplace => marketplace.id) } });
  }
}
