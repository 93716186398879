import { put, takeEvery } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { CREATE_NEW_MEASUREMENT_UNIT_REQUESTED, CREATE_NEW_MEASUREMENT_UNIT_SUCCEEDED } from './create.action';
import { colorAttributes } from '../../admin/attributes/color/color.const';
import { Router } from '@angular/router';
import { NotificationService } from '../../../common/services/notification/notification.service';

function* watchCreateNewAttributeRequested() {
  yield takeEvery(CREATE_NEW_MEASUREMENT_UNIT_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .measurementUnit.create(action.data)
        .toPromise();
      yield put({ type: CREATE_NEW_MEASUREMENT_UNIT_SUCCEEDED });
      const router = AppInjector.get(Router);
      AppInjector.get(NotificationService).show('success', `Successfully created new measurement unit`, 3000);
      router.navigate(['/', 'measurement-unit']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [watchCreateNewAttributeRequested];
