import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FETCH_ASYNC_PROCESSES_REQUESTED, RETRY_FAILED_ASYNC_PROCESS_REQUESTED } from './asnyc-process.action';
import * as _ from 'lodash';
import { DateTimePicker, Dropdown, InputBase, TextBox } from '@reflaunt/reactive-form';

@Component({
  selector: 'app-async-process',
  templateUrl: './async-process.component.html',
  styleUrls: ['./async-process.component.scss']
})
export class AsyncProcessComponent extends BaseComponent implements OnInit, AfterViewInit {

  public filter_date: InputBase<any>[] = [
    new DateTimePicker({
      key: 'start_date',
      label: 'From',
      classes: ['col-lg-3'],
      group_classes: ['col-lg-12'],
      monthNavigator: true,
      yearNavigator: true,
      showIcon: true,
      group: 1
    }),
    new DateTimePicker({
      key: 'end_date',
      label: 'To',
      classes: ['col-lg-3'],
      group_classes: ['col-lg-12'],
      monthNavigator: true,
      yearNavigator: true,
      showIcon: true,
      group: 1
    }),
    new TextBox({
      key: 'min_duration',
      label: 'Min. Duration',
      classes: ['col-lg-2', 'rf-form-group', 'duration-separator', 'duration-field'],
      group_classes: ['col-lg-12'],
      group: 1,
      type: 'number',
      min: 0
    }),
    new TextBox({
      key: 'max_duration',
      label: 'Max. Duration',
      classes: ['col-lg-2', 'rf-form-group', 'duration-field'],
      group_classes: ['col-lg-12'],
      group: 1,
      type: 'number',
      min: 0
    })
  ];
  @ViewChild('search_date', { static: false }) public searchDateForm;

  public filters = {
    is_from_cron: null,
    status: null,
    multi_date: null,
    min_duration: '',
    max_duration: ''
  };

  public paramsList = ['sort', 'constraints', 'page', 'per_page', 'search', ...Object.keys(this.filters)];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const params: any = this.queryParser.parse(this.paramsList);
        this.setupFilter();
        this.dispatch({ type: FETCH_ASYNC_PROCESSES_REQUESTED, data: this.getParams() });
      }
   });
  }

  getParams() {
    let data: any = this.queryParser.parse(this.paramsList);
    return _.assign({}, data, {
      constraints: JSON.stringify({
        ...this.filters
      })
    });
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  ngOnInit() {
    this.init();
  }

  filterEvent(type, value) {
    this.filters[type] = value || null;
    let queryParams = this.activatedRoute.snapshot.queryParams;
    queryParams = _.assign({}, queryParams, { ...this.filters });
    let params = _.assign({}, this.activatedRoute.snapshot.params, { queryParams });
    this.router.navigate([window.location.pathname], params);
  }

  filterDateEvent(reactiveForm) {
    const form = reactiveForm.form;
    let start_date = form.value.start_date || '';
    let end_date = form.value.end_date || '';
    let min_duration = form.value.min_duration || '';
    let max_duration = form.value.max_duration || '';
    if (start_date === ' ') {
      start_date = '';
    }
    if (end_date === ' ') {
      end_date = '';
    }
    if (min_duration) {
      this.filters['min_duration'] = min_duration;
    } else {
      this.filters['min_duration'] = '';
    }
    if (max_duration) {
      this.filters['max_duration'] = max_duration;
    } else {
      this.filters['max_duration'] = '';
    }
    if (start_date || end_date) {
      this.filterEvent('multi_date', start_date + '-' + end_date);
    } else {
      this.filterEvent('multi_date', null);
    }
  }

  mapStateToProps(state) {
    return { payload: state.Admin.Product.AsyncProcess };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }

  setupFilter() {
    let data: any = this.queryParser.parse(this.paramsList);
    this.setupMultiDateFilter(data);
    Object.keys(this.filters).forEach((key) => {
      if (data[key]) {
        this.filters[key] = data[key];
      } else {
        this.filters[key] = null;
      }
    });
  }

  setupMultiDateFilter(data) {
    if (this.searchDateForm && this.searchDateForm.form) {
      const isUpdated = this.updateMultiDateFilterByData(data);
      if (!isUpdated) {
        const form = this.searchDateForm.form;
        setTimeout(() => {
          form.get('start_date').setValue(' ');
          form.get('end_date').setValue(' ');
        });
      }
    }
  }

  updateMultiDateFilterByData(data) {
    const form = this.searchDateForm.form;
    const multiDate = data['multi_date'];
    if (!multiDate) {
      return false;
    }

    const dates = multiDate.split('-');
    if (dates.length <= 1) {
      return false;
    }

    if (!dates[0] && !dates[1]) {
      return false;
    }

    if (dates[0]) {
      setTimeout(() => {
        form.get('start_date').setValue(new Date(dates[0]));
      });
    }

    if (dates[1]) {
      setTimeout(() => {
        form.get('end_date').setValue(new Date(dates[1]));
      });
    }

    return true;
  }

  retry(id) {
    this.dispatch({ type: RETRY_FAILED_ASYNC_PROCESS_REQUESTED, data: { id } });
  }

}
