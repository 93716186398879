import { fork } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import detailSaga from './detail/detail.saga';
import editSaga from './edit/edit.saga';
import createSaga from './create/create.saga';
import importSaga from './import/import.saga';
import eventSaga from './event/event.saga';
import addTagSaga from './add-tag/add-tag.saga';
import bulkDiscountSaga from './bulk-discount/bulk-discount.saga';
import bulkPushSaga from './bulk-push/bulk-push.saga';
import asyncProcessSaga from './async-process/asnyc-process.saga';
import historySaga from './history/history.saga';
import removeBulkDiscountSaga from './remove-bulk-discount/remove-bulk-discount.saga';

export default [
  ...listSaga,
  ...detailSaga,
  ...editSaga,
  ...createSaga,
  ...importSaga,
  ...eventSaga,
  ...addTagSaga,
  ...bulkPushSaga,
  ...asyncProcessSaga,
  ...bulkDiscountSaga,
  ...historySaga,
  ...removeBulkDiscountSaga
];
