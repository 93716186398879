import { FETCH_INTEGRATIONS_SUCCEEDED, FETCH_INTEGRATIONS_REQUESTED } from './list.actions';
import { API_CALL_ERROR } from './../../../store/action';
import { AppInjector } from './../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../api/api.service';
import { put, takeLatest } from 'redux-saga/effects';

function* fetch(action) {
  try {
    const result = yield AppInjector.get(ApiService)
      .integration.list()
      .toPromise();
    yield put({ type: FETCH_INTEGRATIONS_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchIntegrationRequested() {
  yield takeLatest(FETCH_INTEGRATIONS_REQUESTED, fetch);
}

export default [watchFetchIntegrationRequested];
