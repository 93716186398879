import { Component, OnDestroy, OnInit } from '@angular/core';
import { Dropdown, TextBox } from '@reflaunt/reactive-form';
import { Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import { CREATE_NEW_ATTRIBUTE_REQUESTED, RENDER_ATTRIBUTE_FORM_SUCCEEDED } from '../create/create.action';
import { RENDER_EDIT_ATTRIBUTE_FORM_DATA_REQUESTED, UPDATE_ATTRIBUTE_REQUESTED } from './edit.action';
import * as _ from 'lodash';
import { AppInjector } from '../../../app-injector';
import { Store } from '../../../store/store.module';
import { SIZE_TYPES } from '../attribute.consts';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseComponent implements OnInit, OnDestroy {
  public availableInputs = {
    categories: {
      sizing_type: {
        class: Dropdown,
        default_data: {
          key: 'sizing_type',
          label: 'Sizing Type',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: [
            { label: 'Any', value: '' },
            { label: 'Clothing', value: 'CLOTHING' },
            { label: 'Shoes', value: 'SHOES' },
            { label: 'Accessories', value: 'ACCESSORIES' }
          ]
        }
      }
    },
    colors: {},
    conditions: {
      sort_order: {
        class: TextBox,
        default_data: {
          key: 'sort_order',
          label: 'Sort order',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1
        }
      }
    },
    designers: {},
    materials: {},
    sizes: {
      age_group: {
        class: Dropdown,
        default_data: {
          key: 'age_group',
          label: 'Age Group',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: [
            { label: 'Any', value: '' },
            { label: 'Adult', value: 'ADULT' },
            { label: 'Kid', value: 'KID' }
          ]
        }
      },
      type: {
        class: Dropdown,
        default_data: {
          key: 'type',
          label: 'Type',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: SIZE_TYPES
        }
      },
      gender: {
        class: Dropdown,
        default_data: {
          key: 'gender',
          label: 'Gender',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: [
            { label: 'Any', value: '' },
            { label: 'Male', value: 'MALE' },
            { label: 'Female', value: 'FEMALE' }
          ]
        }
      },
      unit: {
        class: TextBox,
        default_data: {
          key: 'unit',
          label: 'Unit',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1
        }
      }
    }
  };

  public commonInputs = {
    name: {
      class: TextBox,
      default_data: {
        key: 'name',
        label: 'Name *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-12'],
        group_classes: ['col-12'],
        order: 1
      }
    }
  };
  public type;
  public combinedInputs;
  public attributeId;

  constructor(private activatedRoute: ActivatedRoute, private route: Router) {
    super();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.generateInput();
      }
    });
  }

  generateInput() {
    let test;
    const renderer = this.activatedRoute.parent.params.subscribe((params) => {
      this.type = params.type;
      this.attributeId = this.activatedRoute.snapshot.params.id;
      if (this.type !== 'models') {
        this.combinedInputs = [
          ...Object.keys(this.commonInputs)
            .map((s) => {
              const input = this.commonInputs[s];
              if (input) {
                return new input.class({
                  ...input.default_data
                });
              }
              return null;
            })
            .filter((s) => !!s),
          ...Object.keys(this.availableInputs[this.type])
            .map((s) => {
              const input = this.availableInputs[this.type][s];
              if (input) {
                return new input.class({
                  ...input.default_data
                });
              }
              return null;
            })
            .filter((s) => !!s)
        ];
      } else {
        this.combinedInputs = [
          ...Object.keys(this.commonInputs).map((s) => {
            const input = this.commonInputs[s];
            if (input) {
              return new input.class({
                ...input.default_data
              });
            }
            return null;
          })
        ];
      }
      this.store.dispatch({
        type: RENDER_EDIT_ATTRIBUTE_FORM_DATA_REQUESTED,
        data: {
          inputs: this.combinedInputs,
          type: this.type,
          id: this.attributeId
        }
      });
    });
    renderer.unsubscribe();
  }

  ngOnInit() {}

  mapStateToProps(state) {
    return {
      payload: state.Attribute.Edit
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onSubmit = (form) => {
    const formCopy = { ...form.value };
    const type = this.type;
    if (!form.valid) {
      return;
    }
    let data = {
      name: formCopy.name,
      // status: formCopy.status.value,
      sizing_type: formCopy.sizing_type ? formCopy.sizing_type.value : '',
      age_group: formCopy.age_group ? formCopy.age_group.value : '',
      type: formCopy.type ? formCopy.type.value : '',
      gender: formCopy.gender ? formCopy.gender.value : '',
      unit: formCopy.unit,
      sort_order: formCopy.sort_order
    };
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({ type: UPDATE_ATTRIBUTE_REQUESTED, data: { attribute: data, type: type, id: this.attributeId } });
  };
}
