import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from './../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';
import { RENDER_CREATE_TAG_FORM_REQUESTED, CREATE_TAG_REQUESTED } from './create.actions';
import { InputBase, TextBox } from '@reflaunt/reactive-form';
import { listTagRouter } from '../tag.const';
import * as _ from 'lodash';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public store;
  public listTagRouter = listTagRouter;

  constructor(public activatedRoute: ActivatedRoute, store: Store) {
    this.store = AppInjector.get(Store).getInstance();
  }

  ngOnInit() {
    let inputs: InputBase<any>[] = [
      new TextBox({
        key: 'name',
        label: 'Name',
        required: true,
        classes: ['col'],
        group_classes: ['col-12'],
        order: 1
      })
    ];
    this.store.dispatch({ type: RENDER_CREATE_TAG_FORM_REQUESTED, data: { inputs: inputs } });
  }

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      const dt = _.assign(form.value, {
        name: form.value.name
          .toLowerCase()
          .split(' ')
          .join('-')
      });
      store.dispatch({ type: CREATE_TAG_REQUESTED, data: dt });
    }
  }
}
