import { CONDITION_MODIFIED, CREATE_DATASET_SUCCEEDED, DATASET_CREATE_COMPONENT, FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED } from './create.actions';
import * as _ from 'lodash';
import { GET_ALL_DATASET_OBJECTS_SUCCEEDED, GET_ALL_DATASET_OPERATORS_SUCCEEDED } from '../dataset.action';
import { selectDatasetOperator } from '../dataset.const';

export const create = (
  state = {
    created: false,
    item: {
      type: '1',
      fetched: false,
      conditions: [{ index: 0, input_type: 'text', constants: [], changed: true }]
    },
    dataset_objects: {
      fetched: false,
      items: []
    },
    dataset_operators: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case CREATE_DATASET_SUCCEEDED:
      return _.assign({}, state, {
        created: true,
        item: {
          fetched: true,
          type: '1',
          conditions: [{}]
        }
      });

    case GET_ALL_DATASET_OBJECTS_SUCCEEDED:
      if (!_.isUndefined(action.component)) {
        switch (action.component) {
          case DATASET_CREATE_COMPONENT:
            (_.head(state.item.conditions) as any).dataset_object = _.head(action.data) as any;
            return _.assign({}, state, { dataset_objects: { fetched: true, items: action.data } });
        }
      }
      return state;

    case GET_ALL_DATASET_OPERATORS_SUCCEEDED:
      if (!_.isUndefined(action.component)) {
        switch (action.component) {
          case DATASET_CREATE_COMPONENT:
            (_.head(state.item.conditions) as any).dataset_operator = _.head(action.data);
            selectDatasetOperator(_.head(action.data), _.head(state.item.conditions) as any);
            return _.assign({}, state, { dataset_operators: { fetched: true, items: action.data } });
        }
      }
      return state;
    case CONDITION_MODIFIED:
      state.item.conditions = _.map(state.item.conditions, (condition) => _.assign(condition, { changed: false }));
      const default_operator = _.head(state.dataset_operators.items);
      let current_condition: any = state.item.conditions[action.data.condition_index];
      current_condition = selectDatasetOperator(default_operator, _.assign(current_condition, { dataset_operator: default_operator, changed: true }));
      return _.assign({}, state);
    case FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED:
      let condition: any = _.find(state.item.conditions, (i) => i.index === action.condition.index);
      if (condition) {
        condition = _.assign(condition, { constants: action.constants || [], changed: !condition.changed });
      }
      return _.assign({}, state);
    default:
      return state;
  }
};
