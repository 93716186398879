import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import {
  FETCH_CONDITION_ATTRIBUTE_REQUESTED,
  CREATE_CONDITION_REQUESTED,
  FETCH_CONDITION_DICTIONARY_REQUESTED,
  RESET_CONDITION_DICTIONARY,
  DELETE_CONDITION_ATTRIBUTE_REQUESTED,
  EDIT_CONDITION_REQUESTED,
  EDIT_CONDITION_DICTIONARY_REQUESTED
} from './condition.action';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { DICTIONARY_CONDITION_TYPE } from '../../../../app.const';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {
  public store;
  public edit_data: any = {};
  public edit_dictionary_data: any = {};
  public name_valid = true;

  constructor(store: Store, private router: Router, private activatedRoute: ActivatedRoute) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    // tslint:disable-next-line:radix
    const marketplace_id = parseInt(this.activatedRoute.snapshot.queryParams.marketplace_id);
    if (!_.isNil(marketplace_id)) {
      this.store.dispatch({
        type: FETCH_CONDITION_DICTIONARY_REQUESTED,
        data: {
          marketplace_id: marketplace_id,
          type: DICTIONARY_CONDITION_TYPE
        }
      });
    } else {
      this.store.dispatch({ type: RESET_CONDITION_DICTIONARY });
    }
    this.store.dispatch({ type: FETCH_CONDITION_ATTRIBUTE_REQUESTED });
  }

  openEditForm(item) {
    item.is_show_edit_form = true;
    this.edit_data = _.clone(item);
  }

  closeEditForm(item) {
    item.is_show_edit_form = false;
  }

  openEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = true;
    this.edit_dictionary_data = _.clone(item);
  }

  closeEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = false;
  }

  updateItem(form, item) {
    this.store.dispatch({
      type: EDIT_CONDITION_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  updateDictionaryItem(form, item) {
    this.store.dispatch({
      type: EDIT_CONDITION_DICTIONARY_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  submit(form) {
    this.store.dispatch({
      type: CREATE_CONDITION_REQUESTED,
      data: this.store.getState().Admin.Attribute.Condition.create.data
    });
    form.resetForm();
  }

  deleteItem(item) {
    this.store.dispatch({
      type: DELETE_CONDITION_ATTRIBUTE_REQUESTED,
      data: item
    });
  }

  addCondition(event) {
    if (event.key === 'Enter') {
      let data = this.store.getState().Admin.Attribute.Condition.create.data;
      if (!_.isNil(data.name) && data.name !== '') {
        this.name_valid = true;
        this.store.dispatch({
          type: CREATE_CONDITION_REQUESTED,
          data: this.store.getState().Admin.Attribute.Condition.create.data
        });
      } else {
        this.name_valid = false;
      }
    }
  }

  selectMarketplace(marketplace) {
    let router = window.location.pathname;
    this.router.navigate([router], { queryParams: { marketplace_id: marketplace.getId() } });

    this.store.dispatch({
      type: FETCH_CONDITION_DICTIONARY_REQUESTED,
      data: {
        marketplace_id: marketplace.getId(),
        type: DICTIONARY_CONDITION_TYPE
      }
    });
  }
}
