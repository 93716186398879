import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Size from '../../../models/Size';

@Injectable()
export class SizeService extends ServiceProvider {
  public url = '/api/v1/admin/sizes';
  public model = Size;
}
