export const GET_SHIPMENT_DETAIL_ADMIN_REQUESTED = 'GET_SHIPMENT_DETAIL_ADMIN_REQUESTED';
export const GET_SHIPMENT_DETAIL_ADMIN_SUCCEEDED = 'GET_SHIPMENT_DETAIL_ADMIN_SUCCEEDED';

export const UPDATE_SHIPMENT_ADMIN_REQUESTED = 'UPDATE_SHIPMENT_ADMIN_REQUESTED';
export const UPDATE_SHIPMENT_ADMIN_SUCCEEDED = 'UPDATE_SHIPMENT_ADMIN_SUCCEEDED';

export const BUY_SHIPPING_LABEL_REQUESTED = 'BUY_SHIPPING_LABEL_REQUESTED';
export const BUY_SHIPPING_LABEL_SUCCEEDED = 'BUY_SHIPPING_LABEL_SUCCEEDED';

export const SEND_SHIPMENT_TO_SELLER_REQUESTED = 'SEND_SHIPMENT_TO_SELLER_REQUESTED';

export const RENDER_UPDATE_SHIPMENT_FORM_REQUESTED = 'RENDER_UPDATE_SHIPMENT_FORM_REQUESTED';

export const UPDATE_SHIPMENT_STATUS_ADMIN_REQUESTED = 'UPDATE_SHIPMENT_STATUS_ADMIN_REQUESTED';
export const UPDATE_SHIPMENT_STATUS_ADMIN_SUCCESSED = 'UPDATE_SHIPMENT_STATUS_ADMIN_SUCCESSED';

export const SHIPMENT_DETAIL_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED = 'SHIPMENT_DETAIL_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED';
export const SHIPMENT_DETAIL_UPDATE_STATUS_ORDER_PRODUCT_SUCCESSED = 'SHIPMENT_DETAIL_UPDATE_STATUS_ORDER_PRODUCT_SUCCESSED';

export const SEND_CONFIRM_ADDRESS_REQUESTED = 'SEND_CONFIRM_ADDRESS_REQUESTED';

export const RE_GENERATE_SHIPPING_LABEL_REQUESTED = 'RE_GENERATE_SHIPPING_LABEL_REQUESTED';
export const RE_GENERATE_SHIPPING_LABEL_SUCCESSED = 'RE_GENERATE_SHIPPING_LABEL_SUCCESSED';
export const GENERATE_INVOICE_REQUESTED = 'GENERATE_INVOICE_REQUESTED';
