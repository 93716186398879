import list from './list/list.saga';
import marketplaceManagement from './marketplace-management/marketplace-management.saga';
import editAction from './edit-action/edit-action.saga';
import defaultConfigSaga from './default-config/default-config.saga';
import { takeLatest, put, select } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import {
  ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_REQUEST,
  ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_SUCCESS,
  ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST,
  ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS
} from './integration.actions';
import { API_CALL_ERROR } from '../../../store/action';

function* watchGetAllAvailableTemplateRequest() {
  yield takeLatest(ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const availableTemplateState = yield select((state: any) => state.Admin.Integration.All.availableTemplate);
      if (!availableTemplateState.fetched) {
        let result = yield api.admin.email.getListTemplate().toPromise();
        yield put({ type: ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_SUCCESS, data: result });
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllLanguageRequest() {
  yield takeLatest(ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const languageState = yield select((state: any) => state.Admin.Integration.All.language);
      let result = languageState.items;
      if (!languageState.fetched) {
        result = yield api.admin.language.list().toPromise();
      }
      yield put({ type: ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS, data: result, component: action.component });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [...list, ...marketplaceManagement, ...editAction, ...defaultConfigSaga, watchGetAllAvailableTemplateRequest, watchGetAllLanguageRequest];
