import Model from './Model';
import Dictionary from './Dictionary';
import * as _ from 'lodash';

export const CONDITIONS = {
  EXCELLENT: 'excellent',
  GENTLY_LOVE: 'gently loved',
  NEW_WITH_TAG: 'new with tag',
  VERY_GOOD: 'very good',
  GOOD: 'good'
};
class Condition extends Model {
  constructor(options) {
    super();
    (this as any).dictionaries = (d) => {
      return _.map(d.data, (item) => new Dictionary(item));
    };
    this.bind(options);
  }
}

export default Condition;
