import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";
@Pipe({
  name: "filterByProp"
})
export class FilterByPropertyPipe implements PipeTransform {
  transform(obj: Array<object>, prop: string): Array<object>{
    if(_.isArray(obj) && obj.length > 0 ){
      let parsedItems = []
      obj.map((item) => _.has(item, prop) ? parsedItems.push(item) : null)
      return parsedItems
    }
  }
}
