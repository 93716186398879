import EditOrderItem from './edit-order-item/edit-order-item.saga';
import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from "lodash";
import list from "./list/list.saga";
import detail from "./detail/detail.saga";
import edit from "./edit/edit.saga";
import create from "./create/create.saga";
import { GET_ALL_ADMIN_ORDER_REQUESTED, GET_ALL_ADMIN_ORDER_SUCCESSED } from './order.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';


export function* fetchAllOrders(params?) {
  const fetched = yield select((state) => (state as any).Admin.Order.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.order.list(params).toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select(state => (state as any).Admin.Order.all.items);
    return data;
  }
}

function* watchFetchAllOrdersRequest() {
  yield takeLatest(GET_ALL_ADMIN_ORDER_REQUESTED, function* (action:any) {
    const data = yield call(fetchAllOrders);
    yield put({type: GET_ALL_ADMIN_ORDER_SUCCESSED, data: data});
  });
}

export default [...EditOrderItem, ...list, ...detail, ...edit, ...create, watchFetchAllOrdersRequest];
