import { PipesModule } from './../../../common/pipes/pipes.module';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { RetailerRoutingModule } from './retailer-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateComponent } from './create/create.component';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from '../../../template/shared/shared.module';
import { DetailComponent } from './detail/detail.component';
import { AngularReactiveFormModule, UPLOAD_DIRECTIVE_API_URL, UPLOAD_DIRECTIVE_HEADERS } from '@reflaunt/reactive-form';
import { RetailerComponent } from './retailer.component';
import { PercentageComponent } from './percentage/percentage.component';
import { EndpointComponent } from './endpoint/endpoint.component';
import { SmartButtonComponent } from './smart-button/smart-button.component';
import { CredentialModule } from '../../credential/credential.module';
import { CommissionConfigComponent } from './commission-config/commisison-config.component';
import { environment } from '../../../../environments/environment';
import * as Cookies from 'js-cookie';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    RetailerRoutingModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    SweetAlert2Module,
    SharedModule,
    AngularReactiveFormModule,
    ReactiveFormsModule,
    CredentialModule,
    NgSelectModule
  ],
  declarations: [
    RetailerComponent,
    ListComponent,
    EditComponent,
    CreateComponent,
    DetailComponent,
    PercentageComponent,
    EndpointComponent,
    SmartButtonComponent,
    CommissionConfigComponent
  ],
  providers: [
    {
      provide: UPLOAD_DIRECTIVE_HEADERS,
      useValue: {
        Authorization: 'Bearer ' + Cookies.get(environment.jwtTokenKey)
      }
    },
    {
      provide: UPLOAD_DIRECTIVE_API_URL,
      useValue: environment.apiUrl
    }
  ]
})
export class RetailerModule {}
