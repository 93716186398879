import { CREATE_ADMIN_ORDER_REQUESTED, CREATE_ADMIN_ORDER_SUCCESSED, RENDER_CREATE_ADMIN_ORDER_REQUESTED } from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery, select } from 'redux-saga/effects';
import { listAdminOrder } from '../order.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchCreateOrderRequest() {
  yield takeEvery(CREATE_ADMIN_ORDER_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).order.create(action.data).toPromise();
      yield put({ type: CREATE_ADMIN_ORDER_SUCCESSED, data: result });
      AppInjector.get(NotificationService).show('success', 'Create order success', 2000);
      router.navigate(listAdminOrder());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* updateCurrencyInput() {
  yield takeEvery(RENDER_CREATE_ADMIN_ORDER_REQUESTED, function* (action: any) {
    try {
      const inputs = yield select((state) => (state as any).Admin.Order.create.inputs);
      let currencies = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      currencies = currencies.items;
      const currency_input = _.find(inputs, (e) => e.key === 'currency');
      currency_input.options = _.map(currencies, (e) => _.assign({ value: e.id, label: e.code }));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchCreateOrderRequest, updateCurrencyInput];
