import { RENDER_CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED, CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_SUCCESSED, FILL_ORDER_ITEM_EDIT_FORM } from './edit-order-item.actions';
import * as _ from 'lodash';
import { support } from '@reflaunt/reactive-form';

export const EditOrderItem = (state = { inputs: [], item: {} }, action) => {
  switch (action.type) {
    case CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_SUCCESSED:
      return _.assign({}, state, { created: true, item: action.data });
    case RENDER_CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_ORDER_ITEM_EDIT_FORM:
      state.inputs = support.UpdateFormValue(state.inputs, action.data);
      if (action.data.product_price_currency_id) {
        const product_price_currency_input = _.find(state.inputs, (e) => e.key === 'product_price_currency');
        product_price_currency_input.value = _.find(product_price_currency_input.options, (e) => e.value === action.data.product_price_currency_id);
      }
      if (action.data.paid_to_supplier_currency_id) {
        const paid_to_supplier_currency_input = _.find(state.inputs, (e) => e.key === 'paid_to_supplier_currency_id');
        paid_to_supplier_currency_input.value = _.find(paid_to_supplier_currency_input.options, (e) => e.value === action.data.paid_to_supplier_currency_id);
      }
      if (action.data.paid_to_reflaunt_currency_id) {
        const paid_to_reflaunt_currency_input = _.find(state.inputs, (e) => e.key === 'paid_to_reflaunt_currency_id');
        paid_to_reflaunt_currency_input.value = _.find(paid_to_reflaunt_currency_input.options, (e) => e.value === action.data.paid_to_reflaunt_currency_id);
      }
      if (action.data.cancel_reason) {
        const cancel_reason_input = _.find(state.inputs, (e) => e.key === 'cancel_reason');
        cancel_reason_input.value = _.find(cancel_reason_input.options, (e) => e.value === action.data.cancel_reason);
      }
      if (action.data.return_reason) {
        const return_reason_input = _.find(state.inputs, (e) => e.key === 'return_reason');
        return_reason_input.value = _.find(return_reason_input.options, (e) => e.value === action.data.return_reason);
      }
      return _.assign({}, state, { inputs: state.inputs, item: action.data });
    default:
      return state;
  }
};
