import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'product', pathMatch: 'full' },
      {
        path: 'acl',
        loadChildren: () => import('./acl/acl.module').then((m) => m.AclModule)
      },
      {
        path: 'category',
        loadChildren: () => import('./category/category.module').then((m) => m.CategoryModule)
      },
      {
        path: 'attributes',
        loadChildren: () => import('./attributes/attributes.module').then((m) => m.AttributesModule)
      },
      {
        path: 'attributes-retailer',
        loadChildren: () => import('./attributes-retailer/attributes-retailer.module').then((m) => m.AttributesRetailerModule)
      },
      {
        path: 'currency',
        loadChildren: () => import('./currency/currency.module').then((m) => m.CurrencyModule)
      },
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then((m) => m.OrderModule)
      },
      {
        path: 'order-item',
        loadChildren: () => import('./order-item/order-item.module').then((m) => m.OrderItemModule)
      },
      {
        path: 'product',
        loadChildren: () => import('./product/product.module').then((m) => m.ProductModule)
      },
      {
        path: 'provider',
        loadChildren: () => import('./provider/provider.module').then((m) => m.ProviderModule)
      },
      {
        path: 'status',
        loadChildren: () => import('./status/status.module').then((m) => m.StatusModule)
      },
      {
        path: 'retailer',
        loadChildren: () => import('./retailer/retailer.module').then((m) => m.RetailerModule)
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule)
      },
      {
        path: 'collection',
        loadChildren: () => import('./collection/collection.module').then((m) => m.CollectionModule)
      },
      {
        path: 'locations',
        loadChildren: () => import('./location/location.module').then((m) => m.LocationModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule)
      },
      {
        path: 'tag',
        loadChildren: () => import('./tag/tag.module').then((m) => m.TagModule)
      },
      {
        path: 'customer',
        loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule)
      },
      {
        path: 'email',
        loadChildren: () => import('./email/email.module').then((m) => m.EmailModule)
      },
      {
        path: 'shipment',
        loadChildren: () => import('./shipment/shipment.module').then((m) => m.ShipmentModule)
      },
      {
        path: 'integration',
        loadChildren: () => import('./integration/integration.module').then((m) => m.IntegrationModule)
      },
      {
        path: 'configuration',
        loadChildren: () => import('./configuration/configuration.module').then((m) => m.ConfigurationModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notification/notification.module').then((m) => m.NotificationModule)
      },
      {
        path: 'items-cost',
        loadChildren: () => import('./items-cost/items-cost.module').then((m) => m.ItemsCostModule)
      },
      {
        path: 'fulfilments',
        loadChildren: () => import('./fulfilment/fulfilment.module').then((m) => m.FulfilmentModule)
      },
      {
        path: 'language',
        loadChildren: () => import('./language/language.module').then((m) => m.LanguageModule)
      },
      {
        path: 'payment-to-send',
        loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: 'payment-to-collect',
        loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: 'logistic',
        loadChildren: () => import('./logistic/logistic.module').then((m) => m.LogisticModule)
      },
      {
        path: 'negotiations',
        loadChildren: () => import('./negotiation/negotiation.module').then((m) => m.NegotiationModule)
      },
      {
        path: 'payment-to-collect-grouped',
        loadChildren: () => import('./grouped-payment/grouped-payment.module').then((m) => m.GroupedPaymentModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
