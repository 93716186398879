import * as _ from 'lodash';
import { EDIT_STATUS_REQUESTED, GET_STATUS_REQUESTED } from './edit.actions';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public store;
  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    this.activatedRoute = activatedRoute;
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.store.dispatch({
      type: GET_STATUS_REQUESTED,
      data: this.activatedRoute.snapshot.params.id
    });
  }

  onSubmit(form) {
    const store = AppInjector.get(Store).getInstance();
    if (form.valid) {
      store.dispatch({
        type: EDIT_STATUS_REQUESTED,
        data: _.assign(form.value, { id: store.getState().Admin.Status.edit.item.id })
      });
    }
  }

  getItemId() {
    return this.activatedRoute.snapshot.params.id;
  }
}
