import { AfterViewInit, ElementRef, HostListener, Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { FETCH_DASHBOARD_REQUESTED, GET_ANALYTIC_DATA_REQUEST } from './analytic.actions';
import '../../../../assets/charts/amchart/amcharts.js';
import '../../../../assets/charts/amchart/gauge.js';
import '../../../../assets/charts/amchart/pie.js';
import '../../../../assets/charts/amchart/serial.js';
import '../../../../assets/charts/amchart/light.js';
import '../../../../assets/charts/amchart/ammap.js';
import '../../../../assets/charts/amchart/usaLow.js';
import { ApiService } from '../../../api/api.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import * as $ from 'jquery';

declare const AmCharts: any;

@Component({
  selector: 'app-analytic',
  templateUrl: './analytic.component.html',
  styleUrls: ['./analytic.component.scss']
})
export class AnalyticComponent extends BaseComponent implements OnInit, AfterViewInit {
  isQuicksight = false;
  options: any = {
    position: ['bottom', 'right']
  };
  public thisMonthSaleData: any;
  public thisMonthSoldData: any;
  public thisMonthOption: any;
  public reducer = 'Retailer.Analytic';
  public checkPending;

  constructor(private api: ApiService, private el: ElementRef) {
    super();
    this.api.user.profile({}).subscribe((res) => {
      if (res.retailer.quicksight_dashboard_id) {
        this.isQuicksight = true;
        this.dispatch({ type: FETCH_DASHBOARD_REQUESTED });
      } else {
        this.isQuicksight = false;
        setTimeout(() => {
          AmCharts.makeChart('sales-analytics', {
            type: 'serial',
            theme: 'light',
            hideCredits: true,
            marginRight: 40,
            marginLeft: 40,
            autoMarginOffset: 20,
            dataDateFormat: 'YYYY-MM-DD',
            valueAxes: [
              {
                id: 'v1',
                axisAlpha: 0,
                position: 'left',
                ignoreAxisWidth: true
              }
            ],
            balloon: {
              borderThickness: 1,
              shadowAlpha: 0
            },
            graphs: [
              {
                id: 'g1',
                balloon: {
                  drop: true,
                  adjustBorderColor: false,
                  color: '#ffffff',
                  type: 'smoothedLine'
                },
                fillAlphas: 0.5,
                bullet: 'round',
                bulletBorderAlpha: 1,
                bulletColor: '#FFFFFF',
                lineColor: '#11c15b',
                bulletSize: 5,
                hideBulletsCount: 50,
                lineThickness: 3,
                title: 'red line',
                useLineColorForBulletBorder: true,
                valueField: 'value',
                balloonText: '<span style="font-size:18px;">[[value]]</span>'
              }
            ],
            chartCursor: {
              valueLineEnabled: true,
              valueLineBalloonEnabled: true,
              cursorAlpha: 0,
              zoomable: false,
              valueZoomable: true,
              valueLineAlpha: 0.5
            },
            chartScrollbar: {
              autoGridCount: true,
              graph: 'g1',
              oppositeAxis: true,
              scrollbarHeight: 90
            },
            categoryField: 'date',
            categoryAxis: {
              parseDates: true,
              dashLength: 1,
              minorGridEnabled: true
            },
            export: {
              enabled: true
            },
            dataProvider: this.payload.data.product_pushed_to_Reflaunt_network ? this.payload.data.product_pushed_to_Reflaunt_network.data : []
          });

          this.thisMonthSaleData = avgValChart('#11c15b', this.payload.data.products_on_sale ? this.payload.data.products_on_sale.data : [], '#11c15b');
          this.thisMonthSoldData = avgValChart('#11c15b', this.payload.data.product_on_sold ? this.payload.data.product_on_sold.data : [], '#11c15b');
          this.thisMonthOption = buildChartOption();
        }, 3000);
        this.dispatch({ type: GET_ANALYTIC_DATA_REQUEST });
      }
    });
  }

  round(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  substring(str) {
    return str.substring(0, 40);
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    if (!this.isQuicksight) {
      setTimeout(() => {
        this.options = {
          position: ['bottom', 'right'],
          maxStack: 8,
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: true,
          lastOnBottom: true,
          clickToClose: true,
          preventDuplicates: false,
          preventLastDuplicates: false,
          theClass: 'bg-c-red no-icon',
          rtl: false,
          animate: 'rotate'
        };
      }, 75);
    }
  }

  get2Digital(number) {
    const data = number ? number : 0;
    return data.toFixed(2);
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Analytic
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch
    };
  }

  downloadNow() {
    this.checkPending = true;
    const html = $('#displayDownload');
    html2canvas(html[0]).then(canvas => {
      const contentData = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4');
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentData, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('analytic.pdf');
      this.checkPending = false;
    });
  }
}

function avgValChart(a, b, f) {
  if (f == null) {
    f = 'rgba(0,0,0,0)';
  }
  return {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
    datasets: [
      {
        label: '',
        borderColor: a,
        borderWidth: 2,
        hitRadius: 30,
        pointHoverRadius: 4,
        pointBorderWidth: 50,
        pointHoverBorderWidth: 12,
        pointBackgroundColor: '#000',
        pointBorderColor: '#000',
        pointHoverBackgroundColor: a,
        pointHoverBorderColor: '#fff',
        fill: true,
        backgroundColor: f,
        data: b
      }
    ]
  };
}
function buildChartOption() {
  return {
    title: {
      display: !1
    },
    tooltips: {
      position: 'nearest',
      mode: 'index',
      intersect: false,
      yPadding: 10,
      xPadding: 10
    },
    legend: {
      display: !1,
      labels: {
        usePointStyle: !1
      }
    },
    responsive: !0,
    maintainAspectRatio: !0,
    hover: {
      mode: 'index'
    },
    scales: {
      xAxes: [
        {
          display: !1,
          gridLines: !1,
          scaleLabel: {
            display: !0,
            labelString: 'Month'
          }
        }
      ],
      yAxes: [
        {
          display: !1,
          gridLines: !1,
          scaleLabel: {
            display: !0,
            labelString: 'Value'
          },
          ticks: {
            beginAtZero: !0
          }
        }
      ]
    },
    elements: {
      point: {
        radius: 4,
        borderWidth: 12
      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
}

// import { Directive } from '@angular/core';

// @Directive({
//   selector: '[appToDoListRead]'
// })
// export class AppToDoListReadDirective {
//   constructor(public el: ElementRef) {}

//   @HostListener('click', ['$event'])
//   onClick($event) {
//     this.el.nativeElement.parentElement.classList.toggle('done-task');
//   }
// }

// @Directive({
//   selector: '[appToDoListRemove]'
// })
// export class AppToDoListRemoveDirective {
//   constructor(public el: ElementRef) {}

//   @HostListener('click', ['$event'])
//   onClick($event) {
//     this.el.nativeElement.parentElement.parentElement.classList.add('d-none');
//   }
// }
