import { FETCH_ALL_ADMIN_COLLECTION_SUCCESSED, GET_ALL_RETAILER_IN_COLLECTION_SUCCESSED } from './list.actions';
import * as _ from 'lodash';
import { format } from 'date-fns';

export const list = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case FETCH_ALL_ADMIN_COLLECTION_SUCCESSED:
      action.data.forEach((element) => {
        if (element.pickup_date) {
          const dt = new Date(element.pickup_date);
          const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
          _.assign(element, { formatted_pickup_date: format(dtDateOnly, 'dd/MM/yyyy') });
        }
      });
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    case GET_ALL_RETAILER_IN_COLLECTION_SUCCESSED:
      return _.assign({}, state, {
        fetched: true,
        retailer: action.data
      });
    default:
      return state;
  }
};
