import * as _ from 'lodash';
import * as c3 from 'c3';
import {
  RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS,
  RETAILER_SECOND_HAND_SALES_DRAW_ITEMS_PUT_ON_SALE_CHART,
  RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS,
  RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_SUCCESS,
  RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_SUCCESS,
  RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_SUCCESS,
  FETCH_2ND_HAND_SALES_SUCCEEDED, FETCH_2ND_HAND_SALES_REQUESTED
} from './second-hand-sales.const';
import {
  getItemsPutOnSaleData,
  getItemsSoldData,
  getXAxisData,
  getItemsSoldCategorySplitChartLabels,
  getItemsSoldCategorySplitChartPercentages,
  getItemsPurchasedChartData,
  getItemsPurchasedChartRadialBarData
} from '../retailer.const';

export const SecondHandSales = (
  state: any = { putOnSaleChart: { fetched: false }, itemsSoldCategorySplit: { fetched: false }, itemsPurchasedChart: { fetched: false } },
  action
) => {
  switch (action.type) {
    case RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS:
      let putOnSaleChart = {
        fetched: true,
        itemsPutOnSale: getItemsPutOnSaleData(action.data),
        itemsSold: getItemsSoldData(action.data),
        xAxisData: getXAxisData(action.data)
      };
      // let putOnSaleChart = {
      //   fetched: true,
      //   itemsPutOnSale: [95, 79, 57, 45, 34, 42],
      //   itemsSold: [62, 60, 42, 27, 18, 29],
      //   xAxisData: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
      // };
      let itemsPurchasedChartData = getItemsPurchasedChartData(action.data);
      // let itemsPurchasedChartData = Math.floor((238 / 352) * 100);
      let itemsPurchasedChart = {
        fetched: true,
        label: `${itemsPurchasedChartData}%`,
        class: `radial-bar-${getItemsPurchasedChartRadialBarData(itemsPurchasedChartData)}`
      };
      return _.assign({}, state, { putOnSaleChart: putOnSaleChart, itemsPurchasedChart: itemsPurchasedChart });
    case RETAILER_SECOND_HAND_SALES_DRAW_ITEMS_PUT_ON_SALE_CHART:
      const chart = c3.generate({
        bindto: '#chart',
        data: {
          columns: [
            ['Item put on sale', ...state.putOnSaleChart.itemsPutOnSale],
            ['Item Sold', ...state.putOnSaleChart.itemsSold]
          ],
          types: {
            'Item put on sale': 'area-spline',
            'Item Sold': 'area-spline'
            // 'line', 'spline', 'step', 'area', 'area-step' are also available to stack
          },
          colors: {
            'Item put on sale': 'rgba(1, 192, 200, 0.92)',
            'Item Sold': 'rgba(26, 188, 156, 0.93)'
          },
          groups: [['Item put on sale', 'Item Sold']]
        },
        axis: {
          x: {
            type: 'category',
            categories: state.putOnSaleChart.xAxisData
          }
        }
      });
      return _.assign({}, state);
    case RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS:
      if (!action.data.length) {
        return _.assign({}, state, { itemsSoldCategorySplit: { fetched: true } });
      }
      let labels = getItemsSoldCategorySplitChartLabels(action.data);
      let values = getItemsSoldCategorySplitChartPercentages(action.data);
      // let labels = ['Wallet', 'Bag', 'Shoes', 'Ready to wear', 'Accessories'];
      // let values = [60, 140, 35, 79, 38];
      const chartType = 'pie';
      const categorySplitChartData = {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: ['#25A6F7', '#FB9A7D', '#01C0C8', '#ff5370', '#4c5667']
          }
        ]
      };
      const options = {
        legend: {
          position: 'top',
          align: 'start'
        }
      };
      return _.assign({}, state, { itemsSoldCategorySplit: { fetched: true, type: chartType, data: categorySplitChartData, options } });
    case RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_SUCCESS:
      return _.assign({}, state, { avgDisccount: action.data, avgDisccountFetched: true });
    case RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_SUCCESS:
      console.log('__', action.data);
      return _.assign({}, state, { saleInformation: action.data, saleInformationFetched: true });
    case RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_SUCCESS:
      return _.assign({}, state, { avgSoldItemPerCustomer: action.data, avgSoldItemPerCustomerFetched: true });
    case FETCH_2ND_HAND_SALES_REQUESTED:
      return _.assign({}, state, { quicksight: null });
    case FETCH_2ND_HAND_SALES_SUCCEEDED:
      return _.assign({}, state, { quicksight: action.data });
    default:
      return state;
  }
};
