import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import {
  FETCH_COLOR_ATTRIBUTE_REQUESTED,
  CREATE_COLOR_REQUESTED,
  FETCH_COLOR_DICTIONARY_REQUESTED,
  RESET_COLOR_DICTIONARY,
  DELETE_COLOR_ATTRIBUTE_REQUESTED,
  EDIT_COLOR_REQUESTED,
  EDIT_COLOR_DICTIONARY_REQUESTED
} from './color.action';
import * as _ from 'lodash';
import { FETCH_DICTIONARY_BY_MARKETPLACE } from '../attribute.action';
import { Router, ActivatedRoute } from '@angular/router';
import { DICTIONARY_COLOR_TYPE } from '../../../../app.const';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})
export class ColorComponent implements OnInit {
  public store;
  public edit_data: any = {};
  public edit_dictionary_data: any = {};
  public name_valid = true;

  constructor(store: Store, private router: Router, private activatedRoute: ActivatedRoute) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    // tslint:disable-next-line:radix
    const marketplace_id = parseInt(this.activatedRoute.snapshot.queryParams.marketplace_id);
    if (!_.isNil(marketplace_id)) {
      this.store.dispatch({
        type: FETCH_COLOR_DICTIONARY_REQUESTED,
        data: {
          marketplace_id: marketplace_id,
          type: DICTIONARY_COLOR_TYPE
        }
      });
    } else {
      this.store.dispatch({ type: RESET_COLOR_DICTIONARY });
    }
    this.store.dispatch({ type: FETCH_COLOR_ATTRIBUTE_REQUESTED });
  }

  openEditForm(item) {
    item.is_show_edit_form = true;
    this.edit_data = _.clone(item);
  }

  closeEditForm(item) {
    item.is_show_edit_form = false;
  }

  openEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = true;
    this.edit_dictionary_data = _.clone(item);
  }

  closeEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = false;
  }

  updateItem(form, item) {
    this.store.dispatch({
      type: EDIT_COLOR_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  updateDictionaryItem(form, item) {
    this.store.dispatch({
      type: EDIT_COLOR_DICTIONARY_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  submit(form) {
    this.store.dispatch({
      type: CREATE_COLOR_REQUESTED,
      data: this.store.getState().Admin.Attribute.Color.create.data
    });
    form.resetForm();
  }

  deleteItem(item) {
    this.store.dispatch({
      type: DELETE_COLOR_ATTRIBUTE_REQUESTED,
      data: item
    });
  }

  selectMarketplace(marketplace) {
    let router = window.location.pathname;
    this.router.navigate([router], { queryParams: { marketplace_id: marketplace.getId() } });

    this.store.dispatch({
      type: FETCH_COLOR_DICTIONARY_REQUESTED,
      data: {
        marketplace_id: marketplace.getId(),
        type: DICTIONARY_COLOR_TYPE
      }
    });
  }
}
