import * as _ from 'lodash';
import {
  BULK_UPDATE_ISSUE_SUCCEEDED, FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED,
  FETCH_PRODUCT_ACTIVITY_LOG_SUCCESSED, TOGGLE_ALL_ISSUES, TOGGLE_ISSUE
} from './event.actions';

export const Event = (state: any = { fetched: false, selected: {}, issue_type_store: {}, is_all_checked: false }, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED:
      if (action.reset_selection === true) {
        return _.assign({}, state, {
          query: action.data,
          selected: {}
        });
      } else {
        return _.assign({}, state, {
          query: action.data
        });
      }
    case FETCH_PRODUCT_ACTIVITY_LOG_SUCCESSED:
      return _.assign({}, state, {
        fetched: true, items: action.data.items, pagination: action.data.pagination, issue_type_store: {
          ...state.issue_type_store,
          ...action.data.items.filter(item => !!item.issue_tracking).reduce((acc, item) => {
            acc[item.id] = item.issue_tracking;
            return acc;
          }, {})
        }, is_all_checked: false
      });
    case TOGGLE_ISSUE:
      const newSelected = {
        ...state.selected,
        [action.data]: !state.selected[action.data]
      };
      const activityLogsWithIssue = state.items.filter(cur => !!cur.issue_tracking);
      const unCheckedInThisPage = activityLogsWithIssue.filter(item => {
        return newSelected[item.id] !== true;
      });
      return _.assign({}, state, {
        selected: newSelected,
        is_all_checked: unCheckedInThisPage.length === 0
      });
    case TOGGLE_ALL_ISSUES:
      return _.assign({}, state, {
        selected: {
          ...state.selected,
          ...state.items.reduce((acc, cur) => {
            if (cur.issue_tracking) {
              acc[cur.id] = !state.is_all_checked;
            }
            return acc;
          }, {})
        },
        is_all_checked: !state.is_all_checked
      });
    case BULK_UPDATE_ISSUE_SUCCEEDED:
      return _.assign({}, state, {
        selected: {}
      });
    default:
      return state;
  }
};
