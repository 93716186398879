import Model from './Model';
import Currency from './Currency';
import * as _ from 'lodash';
import MarketplaceCommission from './MarketplaceCommission';
import { environment } from '../../environments/environment';
interface MarketplaceInterface {
  company: String;
  website: String;
  description: String;
}

export const POSITION_IMAGES = [
  { label: 'No require', value: null },
  { label: 'Primary', value: 'primary' },
  { label: 'All', value: 'all' }
];
export const DEFAULT_MKT_COMMISION = { is_default: true, percentage: null, amount: null };
export const TO_MARKETPLACE = 'to_marketplace';
export const DIRECT_TO_BUYER = 'direct_to_buyer';
export const FULLY_MANUAL = 'fully_manual';
export const MARKETPLACE_SHIPPING_TYPES = [
  { label: 'To marketplace', value: TO_MARKETPLACE },
  { label: 'Direct to buyer', value: DIRECT_TO_BUYER },
  { label: 'Fully manual', value: FULLY_MANUAL }
];

export const MARKETPLACE_PARTNER_PRICE = 'MARKETPLACE_PARTNER_PRICE';
export const PAYOUT_SUPPLIER = 'PAYOUT_SUPPLIER';
export const MARKETPLACE_DISCOUNT_TYPES = [
  { label: 'Marketplace listing price discount', value: MARKETPLACE_PARTNER_PRICE },
  { label: 'Payout to supplier discount', value: PAYOUT_SUPPLIER }
];

export const DIRECT_CSV_URL_MARKETPLACES = [
  {
    email: 'seller+vestiairecollectiveuk396@reflaunt.com',
    url: `${environment.mediaUrl}/feeds/vestiaire-collective-uk-20237598/vestiaire-collective-uk_feed.csv`
  },
  {
    email: 'seller+vestiairecollectivefr483@reflaunt.com',
    url: `${environment.mediaUrl}/feeds/vestiaire-collective-fr-20237598/vestiaire-collective-fr_feed.csv`
  },
  {
    email: 'seller+vestiairecollectiveus173@reflaunt.com',
    url: `${environment.mediaUrl}/feeds/vestiaire-collective-us-82302243/vestiaire-collective-us_feed.csv`
  }
];
export default class Marketplace extends Model implements MarketplaceInterface {
  description: String;
  company: String;
  website: String;
  constructor(options) {
    super();
    (this as any).currency = (d) => {
      return new Currency(d.data);
    };
    this.bind(options);
  }
}
