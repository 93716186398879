import Model from './Model';
import * as _ from 'lodash';
import Payment from './Payment';

class GroupedPayment extends Model {
  constructor(options) {
    super();
    (this as any).payments = (d) => {
      return _.map(d.data, (item) => new Payment(item));
    };
    this.bind(options);
  }

  getTotal() {
    return _.sumBy((this as any).payments, function (o) {
      return (o as any).paid_to_reflaunt;
    });
  }
}

export default GroupedPayment;
