import { ADD_TO_GROUP_GET_LIST_GROUP_DATASET_SUCCESS } from './add-to-group.actions';

export const addToGroup = (state = { groupDataset: { items: [], fetched: false } }, action) => {
  switch (action.type) {
    case ADD_TO_GROUP_GET_LIST_GROUP_DATASET_SUCCESS: {
      return Object.assign({}, state, { groupDataset: { items: action.data, fetched: true } });
    }
    default:
      return state;
  }
};
