export const GET_TRANSACTION_BY_ID_REQUESTED = 'GET_TRANSACTION_BY_ID_REQUESTED';
export const GET_TRANSACTION_BY_ID_SUCCEEDED = 'GET_TRANSACTION_BY_ID_SUCCEEDED';

export const ADD_PAYMENT_REQUESTED = 'ADD_PAYMENT_REQUESTED';
export const ADD_PAYMENT_SUCCEEDED = 'ADD_PAYMENT_SUCCEEDED';

export const RENDER_FILL_BANK_ACCOUNT_FORM_REQUESTED = 'RENDER_FILL_BANK_ACCOUNT_FORM_REQUESTED';
export const RENDER_FILL_BANK_ACCOUNT_FORM_SUCCEEDED = 'RENDER_FILL_BANK_ACCOUNT_FORM_SUCCEEDED';

export const GET_BANK_ACCOUNT_DETAIL_REQUESTED = 'GET_BANK_ACCOUNT_DETAIL_REQUESTED';
export const GET_BANK_ACCOUNT_DETAIL_SUCCEEDED = 'GET_BANK_ACCOUNT_DETAIL_SUCCEEDED';

export const SAVE_BANK_ACCOUNT_DETAIL_REQUESTED = 'SAVE_BANK_ACCOUNT_DETAIL_REQUESTED';
export const SAVE_BANK_ACCOUNT_DETAIL_SUCCEEDED = 'SAVE_BANK_ACCOUNT_DETAIL_SUCCEEDED';

export const UPDATE_BANK_ACCOUNT_FROM_REQUESTED = 'UPDATE_BANK_ACCOUNT_FROM_REQUESTED';

export const RENDER_FILL_ADDRESS_FORM_REQUESTED = 'RENDER_FILL_ADDRESS_FORM_REQUESTED';
export const RENDER_FILL_ADDRESS_FORM_SUCCEEDED = 'RENDER_FILL_ADDRESS_FORM_SUCCEEDED';

export const GET_SELLER_ADDRESS_REQUESTED = 'GET_SELLER_ADDRESS_REQUESTED';
export const GET_SELLER_ADDRESS_SUCCEEDED = 'GET_SELLER_ADDRESS_SUCCEEDED';

export const UPDATE_SELLER_ADDRESS_FROM_REQUESTED = 'UPDATE_SELLER_ADDRESS_FROM_REQUESTED';

export const SAVE_SELLER_ADDRESS_DETAIL_REQUESTED = 'SAVE_SELLER_ADDRESS_DETAIL_REQUESTED';

export const UPDATE_INPUTS_ACCOUNT_PAYMENT_FORM_REQUESTED = 'UPDATE_INPUTS_ACCOUNT_PAYMENT_FORM_REQUESTED';
export const SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED = 'SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED';
export const UPDATE_INPUTS_VALUES_PAYMENT_FORM_REQUESTED = 'UPDATE_INPUTS_VALUES_PAYMENT_FORM_REQUESTED';
