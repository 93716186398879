import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import CustomerAddress from '../../models/CustomerAddress';

@Injectable()
export class CustomerAddressService extends ServiceProvider {
  public url = '/api/v1/addresses';
  public model = CustomerAddress;

  public setDefaultAddress(data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/default', data).pipe(
      tap(result => {
        console.log(result);
      }),
      catchError(error => {
        throw error;
      })
    );
  }
}
