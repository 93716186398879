import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import list from './list/list.saga';
import { GET_ALL_ADMIN_NOTIFICATION_REQUESTED, GET_ALL_ADMIN_NOTIFICATION_SUCCESSED } from './notification.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAllNotifications(params?) {
  const fetched = yield select(state => (state as any).Admin.Notification.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.notification.list(params).toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select(state => (state as any).Admin.Notification.all.items);
    return data;
  }
}

function* watchFetchAllNotificationsRequest() {
  yield takeLatest(GET_ALL_ADMIN_NOTIFICATION_REQUESTED, function*(action: any) {
    const data = yield call(fetchAllNotifications);
    yield put({ type: GET_ALL_ADMIN_NOTIFICATION_SUCCESSED, data: data });
  });
}

export default [...list, watchFetchAllNotificationsRequest];
