export const FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED = 'FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED';
export const FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED = 'FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED';

export const LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_REQUESTED = 'LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_REQUESTED';
export const LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_SUCCESSED = 'LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_SUCCESSED';

export const MARKETPLACE_CONFIGURATION_FETCH_ALL_RETAILER_REQUEST = 'MARKETPLACE_CONFIGURATION_FETCH_ALL_RETAILER_REQUEST';
export const MARKETPLACE_CONFIGURATION_FETCH_ALL_RETAILER_SUCCESS = 'MARKETPLACE_CONFIGURATION_FETCH_ALL_RETAILER_SUCCESS';

export const RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED = 'RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED';
export const RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED = 'MAKETPLACE_GET_ALL_ZONE_SUCCESSED';

export const MARKET_PLACE_RETAILER_SHIPPING_SUPPORT_PARAMS = ['sort', 'constraints', 'page', 'per_page', 'search', 'shipping_to', 'key_filters', 'shipping_from'];

export const fetchAdminMarketplaceListRetailerRequested = (payload) => {
  return {
    type: FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED,
    data: payload
  };
};
