import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import list from './list/list.saga';
import edit from './edit/edit.saga';
import detail from './detail/detail.saga';
import create from './create/create.saga';
import product from './product/product.saga';
import { GET_ALL_ADMIN_COLLECTION_REQUESTED, GET_ALL_ADMIN_COLLECTION_SUCCESSED } from './collection.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAllCollection() {
  const fetched = yield select(state => (state as any).Admin.Collection.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      let result = yield api.admin.collection.list().toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select(state => (state as any).Admin.Collection.all.items);
    return data;
  }
}

function* allSaga(action) {
  const data = yield call(fetchAllCollection);
  yield put({ type: GET_ALL_ADMIN_COLLECTION_SUCCESSED, component: action.component, data: data });
}

function* watchFetchAllCollectionRequest() {
  yield takeLatest(GET_ALL_ADMIN_COLLECTION_REQUESTED, allSaga);
}
export default [...list, ...edit, ...detail, ...create, ...product, watchFetchAllCollectionRequest];
