import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit {
  public bc_options = [
    {
      length: 5,
      bc_margin_left: 30,
      bc_margin_right: 30,
      bc_margin_top: 24,
      bc_margin_bottom: 3,
      width: 2.3
    },
    {
      length: 6,
      bc_margin_left: 12,
      bc_margin_right: 12,
      bc_margin_top: 24,
      bc_margin_bottom: 3,
      width: 2.3
    },
    {
      length: 7,
      bc_margin_left: 8,
      bc_margin_right: 10,
      bc_margin_top: 24,
      bc_margin_bottom: 3,
      width: 2.1
    },
    {
      length: 8,
      bc_margin_left: 6,
      bc_margin_right: 6,
      bc_margin_top: 24,
      bc_margin_bottom: 3,
      width: 1.9
    },
  ];
  public config = this.bc_options[0];
  @Input() values;

  constructor() {
  }

  ngOnInit() {
    if (this.values) {
      let config = this.bc_options.find((i) => i.length === this.values[0].product_id.toString().length);
      if (config) this.config = config;
    }
  }

  getMargin() {
    return Math.floor(Math.random() * 10) + 2;
  }
}
