import { PipesModule } from '../../common/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../common/directives/directives.module';
import { SharedModule } from '../../template/shared/shared.module';
import { CredentialComponent } from './credential.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SweetAlert2Module,
    DirectivesModule,
    FormsModule,
    PipesModule,
  ],
  declarations: [
    CredentialComponent
  ]
})
export class CredentialModule { }
