import { FETCH_ADMIN_ITEMS_COST_SUCCESSED, FETCH_ALL_ADMIN_CUSTOM_COST_SUCCESSED, SET_SHIPPING_COST_CONSTANTS } from './edit.actions';
import * as _ from 'lodash';

export const edit = (
  state = {
    updated: false,
    cost_setting: {
      return_rate: null,
      return_average_cost: null,
      return_rate_currency_id: null,
      other_rate: null,
      other_average_cost: null,
      other_rate_currency_id: null
    },
    fetched: false,
    inputs: [],
    shipping_cost_items: [],
    shipping_cost_constants: null
  },
  action
) => {
  switch (action.type) {
    case SET_SHIPPING_COST_CONSTANTS:
      return _.assign({}, state, { shipping_cost_constants: action.data, fetched: true });
      case FETCH_ADMIN_ITEMS_COST_SUCCESSED:
        let cost_setting: any = _.head(action.data);
        let default_currency: any = _.head(state.shipping_cost_constants.currencies);
        let shipping_items = [];
        if (cost_setting.return_rate_currency_id) {
          cost_setting.return_rate_currency_id = default_currency.id;
        }
        if (cost_setting.other_rate_currency_id) {
          cost_setting.other_rate_currency_id = default_currency.id;
        }
        if (cost_setting.custom_rate_currency_id) {
          cost_setting.custom_rate_currency_id = default_currency.id;
        }
        if (!_.isNil(cost_setting) && cost_setting.shipping_cost.length > 0) {
          shipping_items = cost_setting.shipping_cost.map((config, k) => ({
            key: k,
            from_zone_id: config.from_zone_id,
            to_zone_id: config.to_zone_id,
            currency_id: default_currency.id,
            cost: config.cost,
            custom_rate: config.custom_rate,
            custom_average_cost: config.custom_average_cost,
            custom_cost_percentage: config.custom_cost_percentage,
          }));
        } else {
          shipping_items.push({
            key: shipping_items.length,
            from_zone_id: null,
            to_zone_id: null,
            currency_id: default_currency.id,
            cost: null,
            custom_rate: null,
            custom_average_cost: null,
            custom_cost_percentage: null,
          });
        }
        return _.assign({}, state, { cost_setting: !_.isNil(cost_setting) ? cost_setting : state.cost_setting, shipping_cost_items: shipping_items });
      case FETCH_ALL_ADMIN_CUSTOM_COST_SUCCESSED:
        return _.assign({}, state, { custom_costs: action.data, custom_cost_pagination: action.pagination });
    default:
      return state;
  }
};
