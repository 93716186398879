import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { IssueTracking } from '../../../models/IssueTracking';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class IssueTrackingService extends ServiceProvider {
  public url = '/api/v1/admin/issue-tracking';
  public model = IssueTracking;

  reprocess(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data + '/reprocess').pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }
  skip(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data + '/skip').pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }
  markAsIt(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data + '/mark-as-it').pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }

  bulkUpdate(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/bulk', data).pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }
}
