import { Router } from '@angular/router';

import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { GET_DETAIL_RETAILER_REQUESTED, GET_DETAIL_RETAILER_SUCCESSED } from './detail.actions';

function* getDetail(action) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.user.getItemById(action.data).toPromise();
      yield put({ type: GET_DETAIL_RETAILER_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  }

function* watchGetDetailRetailerRequest() {
    yield takeEvery(GET_DETAIL_RETAILER_REQUESTED, getDetail);
  }

export default [watchGetDetailRetailerRequest];
