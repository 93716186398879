import Model from './Model';
import ShippingMethod from './ShippingMethod';
import RetailerShippingMethodRule from './RetailerShippingMethodRule';
import RetailerCommission from './RetailerCommission';
import RetailerService from './RetailerService';
import * as _ from 'lodash';

export const VOUCHERS_EXIST_IN_SYSTEM_TYPE = 'vouchers-exist-in-system';
export const VOUCHERS_NOT_EXIST_IN_SYSTEM_TYPE = 'vouchers-not-exist-in-system';
export const VOUCHERS_MANUAL_TYPE = 'vouchers-manual';

interface RetailerInterface {
  company: String;
  website: String;
  description: String;
  note: String;
  getWebsite(): String;
}
class Retailer extends Model implements RetailerInterface {
  description: String;
  company: String;
  website: String;
  note: String;
  constructor(options) {
    super();
    (this as any).shipping_method = (d) => {
      return new ShippingMethod(d.data);
    };
    (this as any).retailer_shipping_method_rules = (d) => {
      return _.map(d.data, (item) => new RetailerShippingMethodRule(item));
    };
    (this as any).retailer_commissions = (d) => {
      return _.map(d.data, (item) => new RetailerCommission(item));
    };
    this.bind(options);
  }

  getWebsite() {
    return this.website || '';
  }

  getServiceSlug() {
    return (this as any).service_slug ? (this as any).service_slug : '';
  }
}

export default Retailer;
