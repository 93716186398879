import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  GET_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
  UPDATE_SMART_BUTTON_RENDER_CONFIG_REQUESTED
} from './smart-button.actions';

@Component({
  selector: 'app-smart-button',
  templateUrl: './smart-button.component.html',
  styleUrls: ['./smart-button.component.scss']
})
export class SmartButtonComponent extends BaseComponent implements OnInit {
  public reducer = 'Admin.AdminRetailer.SmartButton';
  public render_config: object = {};

  constructor(private router: Router, private route: ActivatedRoute) {
    super();
    this.route = route;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: GET_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
          user_id: this.route.snapshot.params.id
        });
      }
    });
   }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return {
      render_config: state.Admin.AdminRetailer.SmartButton.render.config
    };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }

  onRenderConfigSaved(config) {
    this.render_config = config;
    this.store.dispatch({
      type: UPDATE_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
      user_id: this.route.snapshot.params.id,
      data: config
    });
  }
}
