import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import ShippingMethod from '../../../models/ShippingMethod';
import { Observable } from 'rxjs';
import { tap,map, catchError } from 'rxjs/operators';

@Injectable()
export class ShippingMethodService extends ServiceProvider {
  public url = '/api/v1/admin/shipping-method';
  public model = ShippingMethod;
}
