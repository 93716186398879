import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Color from '../../../models/Color';

@Injectable()
export class ColorService extends ServiceProvider {
  public url = '/api/v1/admin/colors';
  public model = Color;
}
