import { USER_ROLES } from './../../../../app.const';
import { put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED,
  FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED,
  LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_REQUESTED,
  LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_SUCCESSED,
  MARKETPLACE_CONFIGURATION_FETCH_ALL_RETAILER_REQUEST,
  RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED,
  RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED
} from './list-retailer.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import * as _ from 'lodash';

function* watchCreateListRetailerRequest() {
  yield takeLatest(FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.marketplaceRetailerConfig.list(action.data).toPromise();
      yield put({ type: FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchListRetailerUpdateInputRequest() {
  yield takeLatest(LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_REQUESTED, function* (action: any) {
    try {
      yield put({ type: LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_SUCCESSED, data: action.inputs });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRenderAdminMaketPlaceListRequest() {
  yield takeLatest(RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_REQUESTED, function* (action: any) {
    try {
      let zones = yield AppInjector.get(ApiService).admin.zone.list().toPromise();
      let currencies = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      // let retailers = yield AppInjector.get(ApiService)
      //   .user.get({ constraints: JSON.stringify({ role_ids: USER_ROLES.RETAILER }) })
      //   .toPromise();
      yield put({
        type: RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED,
        data: {
          zones: zones,
          currencies: currencies.items
        }
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchCreateListRetailerRequest, watchListRetailerUpdateInputRequest, watchRenderAdminMaketPlaceListRequest];
