import { fork, takeEvery, select, put, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import editSaga from './edit/edit.saga';
import createSaga from './create/create.saga';
import {
  FETCH_ITEM_TYPE_REQUESTED, FETCH_SUB_CATEGORY_REQUESTED,
  GET_ALL_CATEGORIES_REQUESTED,
  GET_ALL_CATEGORIES_SUCCEEDED
} from './category.actions';
import { API_CALL_ERROR } from '../../../store/action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import {
  GET_NOTIFICATION_ACTION_REQUESTED,
  GET_NOTIFICATION_ACTION_SUCCEEDED, RENDER_UPDATE_NOTIFICATION_FORM_REQUESTED
} from '../configuration/edit/edit.actions';

export function* fetchAllcategory() {
  const api = AppInjector.get(ApiService);
  const category = yield select(state => (state as any).Admin.Category.all.fetched);
  if (!category) {
    try {
      // let results = yield api.category.allWithExtras().toPromise();
      let results = yield api.category.all().toPromise();
      return results;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const categoryData = yield select(state => (state as any).Admin.Category.all.items);
    return categoryData;
  }
}

export function* fetchMainCategory() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.category.getMainCategory().toPromise();
    return results;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchSubCategory(main_category) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.category.getSubCategory(main_category).toPromise();
    return results;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchItemType(main_category, sub_category) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.category.getItemType(main_category, sub_category).toPromise();
    return results;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchChildCategory(main_category, sub_category, item_type) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.category.getChildCategory(main_category, sub_category, item_type).toPromise();
    return results;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* watchFetchItemTypeRequested(main_category, sub_category) {
  yield takeEvery(FETCH_ITEM_TYPE_REQUESTED, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      let results = yield api.category.getItemType(action.data.main_category, action.data.sub_category).toPromise();
      return results;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* watchFetchSubCategoryRequested(main_category, sub_category) {
  yield takeEvery(FETCH_SUB_CATEGORY_REQUESTED, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      let results = yield api.category.getSubCategory(action.data.main_category).toPromise();
      return results;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* allSaga(action) {
  const data = yield call(fetchAllcategory);
  yield put({ type: GET_ALL_CATEGORIES_SUCCEEDED, data: data });
}

function* watchAllSagaRequest() {
  yield takeEvery(GET_ALL_CATEGORIES_REQUESTED, allSaga);
}

export default [...listSaga, ...editSaga, ...createSaga, watchAllSagaRequest];
