import { FETCH_DATASETS_SUCCEEDED, FETCH_DATASETS_REQUESTED, SORT_DATASETS_REQUESTED, SORT_DATASETS_SUCCEEDED, DATASET_LIST_COMPONENT } from './list.actions';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { GET_ALL_DATASET_CONDITIONS_REQUESTED } from '../dataset.action';

function* getDatasets(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.dataset.get(action.data).toPromise();
    yield put({ type: FETCH_DATASETS_SUCCEEDED, data: results.items, pagination: results.pagination });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchDatasetsRequest() {
  yield takeEvery(FETCH_DATASETS_REQUESTED, getDatasets);
}

function* mapConditionsToDataset(action) {
  const api = AppInjector.get(ApiService);
  try {
    let data = {
      dataset_ids: _.map(action.data, 'id'),
      includes: 'dataset_object,dataset_operator'
    };
    yield put({ type: GET_ALL_DATASET_CONDITIONS_REQUESTED, data: data, component: DATASET_LIST_COMPONENT });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchDatasetsSucceeded() {
  yield takeLatest(FETCH_DATASETS_SUCCEEDED, mapConditionsToDataset);
}

export default [watchFetchDatasetsRequest, watchFetchDatasetsSucceeded];
