export const EDIT_GROUP_DATASET_ENABLE_ADD_DATASET_INPUT = 'EDIT_GROUP_DATASET_ENABLE_ADD_DATASET_INPUT';
export const EDIT_GROUP_DATASET_DISABLE_ADD_DATASET_INPUT = 'EDIT_GROUP_DATASET_DISABLE_ADD_DATASET_INPUT';

export const EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_REQUEST = 'EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_REQUEST';
export const EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_SUCCESS = 'EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_SUCCESS';

export const EDIT_GROUP_DATASET_GET_DATASET_DETAIL_REQUEST = 'EDIT_GROUP_DATASET_GET_DATASET_DETAIL_REQUEST';
export const EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS = 'EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS';

export const EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_REQUEST = 'EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_REQUEST';
export const EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_SUCCESS = 'EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_SUCCESS';

export const EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_REQUEST = 'EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_REQUEST';
export const EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_SUCCESS = 'EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_SUCCESS';

export const EDIT_GROUP_DATASET_ADD_DATASET_TO_GROUP = 'EDIT_GROUP_DATASET_ADD_DATASET_TO_GROUP';
export const EDIT_GROUP_DATASET_REMOVE_DATASET_TO_GROUP = 'EDIT_GROUP_DATASET_REMOVE_DATASET_TO_GROUP';

export const EDIT_GROUP_DATASET_INIT_FORM = 'EDIT_GROUP_DATASET_INIT_FORM';
