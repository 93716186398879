import * as _ from 'lodash';
import listUserSaga from './list/list.saga';
import detailUserSaga from './detail/detail.saga';
import transactionHistorySaga from './transaction-history/transaction-history.saga';
import { put, select, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../store/action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { RETAILER_USER_COMP } from './user.const';
import { GET_ALL_RETAILER_USERS_SUCCEEDED } from './user.actions';

export function* fetchAllUsers() {
  const fetchUser = yield select(state => (state as any).Users.all.fetched);
  if (!fetchUser) {
    try {
      let result = yield AppInjector.get(ApiService)
        .user.list()
        .toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select(state => (state as any).Users.all.items);
    return data;
  }
}

function* allUsers(action) {
  const data = yield call(fetchAllUsers);
  yield put({ type: GET_ALL_RETAILER_USERS_SUCCEEDED, com: RETAILER_USER_COMP, data: data });
}

export default [...listUserSaga, ...detailUserSaga, ...transactionHistorySaga];
