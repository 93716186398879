import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acquisition',
  templateUrl: './acquisition.component.html',
  styleUrls: ['./acquisition.component.scss']
})
export class AcquisitionComponent implements OnInit {
  public item: any;

  constructor() {}

  ngOnInit() {}

  delete(item) {}
}
