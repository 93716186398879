import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { put, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import {
  DELETE_LOGISTIC_USER_REQUESTED,
  DELETE_LOGISTIC_USER_SUCCEEDED,
  FETCH_LOGISTICS_REQUESTED,
  FETCH_LOGISTICS_SUCCEEDED
} from './list.actions';
import * as _ from 'lodash';

function* getLogistics() {
  yield takeLatest(FETCH_LOGISTICS_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let results = yield api.user.get(_.assign({}, action.data, { roles: 'logistic'} )).toPromise();
      yield put({ type: FETCH_LOGISTICS_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* deleteLogistics() {
  yield takeLatest(DELETE_LOGISTIC_USER_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let results = yield api.user.delete(action.data.id).toPromise();
      yield put({ type: DELETE_LOGISTIC_USER_SUCCEEDED });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  getLogistics,
  deleteLogistics,
];

