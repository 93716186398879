import { takeLatest, put, select } from 'redux-saga/effects';
import {
  DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_REQUEST,
  DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_SUCCESS,
  DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  DEFAULT_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION,
  DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  DEFAULT_CONFIG_GET_RETAILER_INFO_REQUEST,
  DEFAULT_CONFIG_GET_RETAILER_INFO_SUCCESS,
  DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE
} from './default-config.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST, ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS } from '../integration.actions';

function* watchGetRetailerInfoRequest() {
  yield takeLatest(DEFAULT_CONFIG_GET_RETAILER_INFO_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.user.getItemById(action.data).toPromise();
      yield put({ type: DEFAULT_CONFIG_GET_RETAILER_INFO_SUCCESS, data: result });
      yield put({ type: ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST, component: 'DEFAULT_CONFIG' });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllNotificationActionRequest() {
  yield takeLatest(DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.notificationAction.list().toPromise();
      yield put({ type: DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllLanguageSuccess() {
  yield takeLatest(ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS, function* (action: any) {
    if (action.component === 'DEFAULT_CONFIG') {
      const initActiveLanguage = action.data.find((item) => item.short_name === 'en');
      yield put({ type: DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE, data: initActiveLanguage });
    }
  });
}

function* watchSetActiveLanguage() {
  yield takeLatest(DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.notificationAction.list().toPromise();
      yield put({ type: DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST, data: action.data });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllRetailerEmailConfigRequest() {
  yield takeLatest(DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const currentState = yield select((state: any) => state.Admin.Integration.DefaultConfig);
    try {
      const data = {
        retailer_id: currentState.retailer.item.id,
        language_id: currentState.activeLanguage.id
      };
      const result = yield api.admin.communication.getDefaultRetailerEmailConfigByLanguage(data).toPromise();
      yield put({ type: DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS, data: result });
      yield put({ type: DEFAULT_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateReatilerEmailConfigRequest() {
  yield takeLatest(DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notify = AppInjector.get(NotificationService);
    try {
      let result = yield api.admin.integrationNotification.createIntegrationNotification(action.data).toPromise();
      notify.show('success', 'Integration created!', 2000);
      yield put({ type: DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS, data: result });
      yield put({ type: DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteReatilerEmailConfigRequest() {
  yield takeLatest(DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notify = AppInjector.get(NotificationService);
    try {
      let result = yield api.admin.integrationNotification.deleteIntegrationNotification(action.data).toPromise();
      notify.show('success', 'Integration deleted!', 2000);
      yield put({ type: DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_SUCCESS, data: result });
      yield put({ type: DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateReatilerEmailConfigRequest() {
  yield takeLatest(DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notify = AppInjector.get(NotificationService);
    try {
      let result = yield api.admin.integrationNotification.updateIntegrationNotification(action.data).toPromise();
      notify.show('success', 'Integration updated!', 2000);
      yield put({ type: DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS, data: result });
      yield put({ type: DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchGetRetailerInfoRequest,
  watchGetAllNotificationActionRequest,
  watchGetAllLanguageSuccess,
  watchSetActiveLanguage,
  watchGetAllRetailerEmailConfigRequest,
  watchCreateReatilerEmailConfigRequest,
  watchDeleteReatilerEmailConfigRequest,
  watchUpdateReatilerEmailConfigRequest
];
