export const FETCH_COLOR_ATTRIBUTE_REQUESTED = 'FETCH_COLOR_ATTRIBUTE_REQUESTED';
export const FETCH_COLOR_ATTRIBUTE_SUCCESSED = 'FETCH_COLOR_ATTRIBUTE_SUCCESSED';
export const CREATE_COLOR_REQUESTED = 'CREATE_COLOR_REQUESTED';
export const CREATE_COLOR_SUCCESSED = 'CREATE_COLOR_SUCCESSED';
export const FETCH_COLOR_DICTIONARY_REQUESTED = 'FETCH_COLOR_DICTIONARY_REQUESTED';
export const FETCH_COLOR_DICTIONARIES_SUCCESSED = 'FETCH_COLOR_DICTIONARIES_SUCCESSED';
export const RESET_COLOR_DICTIONARY = 'RESET_COLOR_DICTIONARY';
export const CREATE_COLOR_DICTIONARY_SUCCESSED = 'CREATE_COLOR_DICTIONARY_SUCCESSED';
export const DELETE_COLOR_ATTRIBUTE_REQUESTED = 'DELETE_COLOR_ATTRIBUTE_REQUESTED';
export const DELETE_COLOR_ATTRIBUTE_SUCCESSED = 'DELETE_COLOR_ATTRIBUTE_SUCCESSED';
export const EDIT_COLOR_REQUESTED = 'EDIT_COLOR_REQUESTED';
export const EDIT_COLOR_SUCCESSED = 'EDIT_COLOR_SUCCESSED';
export const EDIT_COLOR_DICTIONARY_REQUESTED = 'EDIT_COLOR_DICTIONARY_REQUESTED';
export const EDIT_COLOR_DICTIONARY_SUCCESSED = 'EDIT_COLOR_DICTIONARY_SUCCESSED';
