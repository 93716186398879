import { Router } from '@angular/router';
import { FETCH_ALL_ADMIN_COLLECTION_REQUESTED } from '../list/list.actions';
import {
  DELETE_ADMIN_COLLECTION_REQUESTED,
  GET_ADMIN_COLLECTION_REQUESTED,
  GET_ADMIN_COLLECTION_SUCCESSED,
  EDIT_ADMIN_COLLECTION_REQUESTED,
  RENDER_EDIT_ADMIN_COLLECTION_FORM_REQUESTED,
  FILL_ADMIN_COLLECTION_DETAIL_FORM,
} from './edit.actions';
import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { listAdminCollection } from '../collection.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';


function* watchEditCollectionRequest() {
  yield takeEvery(EDIT_ADMIN_COLLECTION_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).admin.collection.update(action.id, action.data).toPromise();
      router.navigate(listAdminCollection());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetCollectionRequest() {
  yield takeEvery(GET_ADMIN_COLLECTION_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.collection.getItemById(action.data).toPromise();
      yield put({ type: GET_ADMIN_COLLECTION_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRenderCollectionDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_ADMIN_COLLECTION_FORM_REQUESTED, function*(action: any) {
    yield put({ type: GET_ADMIN_COLLECTION_REQUESTED, data: action.data.id });
  });
}

function* watchFetchCollectionDetailSuccessed() {
  yield takeLatest(GET_ADMIN_COLLECTION_SUCCESSED, function* (action: any) {
    yield put({ type: FILL_ADMIN_COLLECTION_DETAIL_FORM, data: action.data });
  });
}

export default [watchEditCollectionRequest, watchGetCollectionRequest, watchRenderCollectionDetailFormRequested, watchFetchCollectionDetailSuccessed];
