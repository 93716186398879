import { ApiService } from './../api/api.service';
import { environment } from './../../environments/environment';
import { Cookies } from 'js-cookie';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { isNil } from 'lodash';
import { NotificationService } from '../common/services/notification/notification.service';
@Injectable()
export class PermissionGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private apiService: ApiService, private notification: NotificationService) {}
  canActivate(): boolean | Promise<boolean> {
    const navigation = this.router.getCurrentNavigation();
    const path_urls = navigation.extractedUrl.toString().split('/');
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          redirect: encodeURIComponent(document.location.pathname),
          search: encodeURIComponent(document.location.search)
        }
      });
      return false;
    } else {
      return new Promise((resolve) => {
        this.apiService.user
          .profile({ includes: 'roles' })
          .toPromise()
          .then((user) => {
            if (!this.isCanAccess(path_urls, user)) {
              this.notification.show('warning', "Don't have permission to access this page!", 3000);
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 2000);
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((e) => {
            resolve(false);
          });
      });
    }
  }

  isCanAccess(path_urls, user) {
    switch (path_urls[path_urls.length - 1]) {
      case 'dataset':
        return user.can('dataset.view');
      case 'attributes':
        return user.can('product.attribute.view');
      default:
        return true;
    }
  }
}
