import { RETAILER_CREATE_PRODUCT_SUCCEEDED, RETAILER_RENDER_CREATE_PRODUCT_FORM_REQUESTED, RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS } from './create.actions';
import * as _ from 'lodash';

export const create = (state: any = { created: false }, action) => {
  switch (action.type) {
    case RETAILER_RENDER_CREATE_PRODUCT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case RETAILER_CREATE_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { created: true, last_product: action.data });
    case RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, input => {
          if (input.key === action.input) {
            input.options = action.data;
            // const selected = _.find(input.options, item => item.selected);
            // if (!_.isUndefined(selected)) {
            //   input.value = selected;
            // }
          }
          return input;
        })
      });
    default:
      return state;
  }
};
