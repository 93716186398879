export const RENDER_ADMIN_ITEMS_COST_FORM_REQUESTED = 'RENDER_ADMIN_ITEMS_COST_FORM_REQUESTED';
export const SET_SHIPPING_COST_CONSTANTS = 'SET_SHIPPING_COST_CONSTANTS';
export const SAVE_ITEMS_COST_SETTING_REQUESTED = 'SAVE_ITEMS_COST_SETTING_REQUESTED';
export const SAVE_ITEMS_COST_SETTING_SUCCESSED = 'SAVE_ITEMS_COST_SETTING_SUCCESSED';
export const FETCH_ADMIN_ITEMS_COST_REQUESTED = 'FETCH_ADMIN_ITEMS_COST_REQUESTED';
export const FETCH_ADMIN_ITEMS_COST_SUCCESSED = 'FETCH_ADMIN_ITEMS_COST_SUCCESSED';
export const FETCH_ALL_ADMIN_CUSTOM_COST_REQUESTED = 'FETCH_ALL_ADMIN_CUSTOM_COST_REQUESTED';
export const FETCH_ALL_ADMIN_CUSTOM_COST_SUCCESSED = 'FETCH_ALL_ADMIN_CUSTOM_COST_SUCCESSED';
export const ADMIN_UPLOAD_CUSTOM_COST_REQUESTED = 'ADMIN_UPLOAD_CUSTOM_COST_REQUESTED';
export const ADMIN_UPLOAD_CUSTOM_COST_SUCCESSED = 'ADMIN_UPLOAD_CUSTOM_COST_SUCCESSED';
export const ADMIN_UPLOAD_COMMISSION_REQUESTED = 'ADMIN_UPLOAD_COMMISSION_REQUESTED';
export const ADMIN_UPLOAD_COMMISSION_SUCCESSED = 'ADMIN_UPLOAD_COMMISSION_SUCCESSED';
