import { combineReducers } from 'redux';
import { listUser } from './list/list.reducer';
import * as _ from 'lodash';
import { FETCH_LIST_RETAILER_USER_SUCCEEDED } from './list/list.actions';
import { detailUser } from './detail/detail.reducer';
import { TransactionHistory } from './transaction-history/transaction-history.reducer';
import { FETCH_RETAILERS_SUCCEEDED } from '../../admin/retailer/list/list.actions';

const common = (
  state = {
    fetched: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_RETAILERS_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};

const all = (state = { fetched: false, loading: false, items: [] }, action) => {
  switch (action.type) {
    case FETCH_LIST_RETAILER_USER_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };

    default:
      return state;
  }
};

export const RetailerUsers = combineReducers({
  common,
  all,
  listUser,
  detailUser,
  TransactionHistory
});
