import * as _ from 'lodash';

export const listAdminAttributes = () => ['/', 'admin', 'attributes'];
export const editAdminAttributes = (id) => ['/', 'admin', 'attributes', id];
export const createAdminAttributes = () => ['/', 'admin', 'attributes', 'create'];

export const ngSelectOnCompare = (a, b) => {
  const normalizedA = _.deburr(_.lowerCase(a)).replace(/\s/g, '');
  const normalizedB = _.deburr(_.lowerCase(b.name)).replace(/\s/g, '');
  return normalizedB.indexOf(normalizedA) > -1;
};

export const HOODIES_EMAIL = 'hoodies@reflaunt.com';
export const STYLETRIBUTE_EMAIL = 'styletribute@reflaunt.com';
export const HEWISTREET_EMAIL = 'hewistreet@reflaunt.com';
export const NEW_DEMO_MARKETPLACE_EMAIL = 'demo-marketplace@reflaunt.com';
// export const HEWILONDON_EMAIL = 'HEWILondon@reflaunt.com';
export const HEWILONDON_EMAIL = 'hewilondon@reflaunt.com';
export const LAZADA_EMAIL = 'lazada@reflaunt.com';
export const LAZADA_STYLETRIBUTE_EMAIL = 'lazada_styletribute@reflaunt.com';
export const HUNTSTREET_EMAIL = 'philippe@styletribute.com';
export const MODSIE_EMAIL = 'modsie@reflaunt.com';
export const ZALORA_SINGAPORE_EMAIL = 'zalora+singapore@reflaunt.com';
export const ZALORA_SINGAPORE_PRELOVED_EMAIL = 'seller+zalora324pl@reflaunt.com';
export const ZALORA_SINGAPORE_PRELOVED_VIP_EMAIL = 'seller+zaloracollections@reflaunt.com';
export const ZALORA_STYLETRIBUTE_SINGAPORE_EMAIL = 'zalora-st+singapore@reflaunt.com';
export const ZALORA_STYLETRIBUTE_MALAYSIA_EMAIL = 'zalora-st+malaysia@reflaunt.com';
export const CATAWIKI_EMAIL = 'catawiki@reflaunt.com';
export const MY_PRIVATE_DRESSING_EMAIL = 'myprivatedressing@reflaunt.com';
export const VIDEDRESSING_EMAIL = 'videdressing@reflaunt.com';
export const VIDEDRESSINGSG_EMAIL = 'vide-dressing-sg@reflaunt.com';
export const AFOUND_SWE_EMAIL = 'afound+sweden@reflaunt.com';
export const AFOUND_NL_EMAIL = 'afound+netherlands@reflaunt.com';
export const CAMPADRE_EMAIL = 'campadre@reflaunt.com';
export const SELLBRITE_EMAIL = 'sellbrite@reflaunt.com';
export const TRADERA_EMAIL = 'tradera@reflaunt.com';
export const TRADESY_EMAIL = 'tradesy@reflaunt.com';
export const VESTIAIRE_COLLECTIVE_EMAIL = 'vestiaire_collective@reflaunt.com';
export const VESTIAIRE_COLLECTIVE_ST_EMAIL = 'vestiaire_collective_st@reflaunt.com';
export const REBELLE_EMAIL = 'rebelle@reflaunt.com';
export const MIINTO_EAMIL = 'miinto@reflaunt.com';
export const EBAY_US_EMAIL = 'ebay_us@reflaunt.com';
export const EBAY_UK_EMAIL = 'ebay+uk@reflaunt.com';
export const EBAY_FR_EMAIL = 'ebay+fr@reflaunt.com';
export const REEBONZ_EMAIL = 'reebonz@reflaunt.com';
export const CDON_EMAIL = 'cdon@reflaunt.com';
export const DTUT_EMAIL = 'dtut@reflaunt.com';
export const ASPECT_EMAIL = 'aspect@reflaunt.com';
export const LUXO_EMAIL = 'luxo@reflaunt.com';
export const SOTT_EMAIL = 'sott@reflaunt.com';
export const RITE_EMAIL = 'rite@reflaunt.com';
export const CAVALLI_EMAIL = 'cavallienastri@reflaunt.com';
export const THE_VINTAGE_BAR_EMAIL = 'thevintagebar@reflaunt.com';
export const SAKS_EMAIL = 'saks5th@reflaunt.com';

export const activeMarketplaces = () => [
  HOODIES_EMAIL,
  STYLETRIBUTE_EMAIL,
  HEWISTREET_EMAIL,
  NEW_DEMO_MARKETPLACE_EMAIL,
  HEWILONDON_EMAIL,
  LAZADA_EMAIL,
  HUNTSTREET_EMAIL,
  MODSIE_EMAIL,
  ZALORA_SINGAPORE_EMAIL,
  ZALORA_STYLETRIBUTE_SINGAPORE_EMAIL,
  ZALORA_STYLETRIBUTE_MALAYSIA_EMAIL,
  CATAWIKI_EMAIL,
  MY_PRIVATE_DRESSING_EMAIL,
  VIDEDRESSING_EMAIL,
  AFOUND_SWE_EMAIL,
  AFOUND_NL_EMAIL,
  VIDEDRESSINGSG_EMAIL,
  LAZADA_STYLETRIBUTE_EMAIL,
  SELLBRITE_EMAIL,
  TRADERA_EMAIL,
  VESTIAIRE_COLLECTIVE_EMAIL,
  VESTIAIRE_COLLECTIVE_ST_EMAIL,
  REBELLE_EMAIL,
  MIINTO_EAMIL,
  EBAY_US_EMAIL,
  TRADESY_EMAIL,
  EBAY_UK_EMAIL,
  ZALORA_SINGAPORE_PRELOVED_EMAIL,
  ZALORA_SINGAPORE_PRELOVED_VIP_EMAIL,
  CDON_EMAIL,
  DTUT_EMAIL,
  ASPECT_EMAIL,
  CAMPADRE_EMAIL,
  EBAY_FR_EMAIL,
  LUXO_EMAIL,
  SOTT_EMAIL,
  RITE_EMAIL,
  CAVALLI_EMAIL,
  THE_VINTAGE_BAR_EMAIL,
  SAKS_EMAIL
];

const categoryAttribute = {
  name: 'Category',
  link: 'category',
  isSupportGender: true,
  isSupportAgeGroup: true
};
const designerAttribute = {
  name: 'Designer',
  link: 'designer',
  isSupportGender: false,
  isSupportAgeGroup: false
};
const sizeAttribute = {
  name: 'Size',
  link: 'size',
  isSupportGender: true,
  isSupportAgeGroup: true
};
const conditionAttribute = {
  name: 'Condition',
  link: 'condition',
  isSupportGender: false,
  isSupportAgeGroup: false
};
const colorAttribute = {
  name: 'Color',
  link: 'color',
  isSupportGender: false,
  isSupportAgeGroup: false
};
const materialAttribute = {
  name: 'Material',
  link: 'material',
  isSupportGender: false,
  isSupportAgeGroup: false
};
export const defaultAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute];
export const getAttributeDetails = (val: string) => defaultAvailableAttributes().find((cur: any) => cur.link === val) || {};
export const styletributeAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute];
export const hoodiesAvailableAttributes = () => [categoryAttribute];
export const hewiStreetAvailableAttributes = () => [categoryAttribute];
export const newDemoMarketplaceAvailableAttributes = () => [categoryAttribute];
export const hewiLondonAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute];
export const lazadaAvailableAttributes = () => [categoryAttribute, conditionAttribute];
export const lazadaStyletributeAvailableAttributes = () => [categoryAttribute, conditionAttribute];
export const huntstreetAvailableAttributes = () => [categoryAttribute, designerAttribute, conditionAttribute, colorAttribute];
export const modsieAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute];
export const zaloraAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute];
export const myPrivateDressingAvailableAttributes = () => [categoryAttribute, designerAttribute, conditionAttribute];
export const catawikiAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute];
export const videdressingAvailableAttributes = () => [categoryAttribute, designerAttribute, conditionAttribute, colorAttribute, sizeAttribute];
export const videdressingSgAvailableAttributes = () => [categoryAttribute, designerAttribute, conditionAttribute, colorAttribute, sizeAttribute];
export const afoundSweAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute];
export const afoundNlAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute];
export const sellbriteAvailableAttributes = () => [];
export const traderaAvailableAttributes = () => [conditionAttribute];
export const vestiaireCollectiveAvailableAttributes = () => [categoryAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute, designerAttribute];
export const vestiaireCollectiveSTAvailableAttributes = () => [categoryAttribute, sizeAttribute, conditionAttribute, colorAttribute, materialAttribute, designerAttribute];
export const rebelleAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, conditionAttribute, materialAttribute, colorAttribute];
export const miintoAvailableAttributes = () => [categoryAttribute, conditionAttribute, designerAttribute];
export const eBayUSAvailableAttributes = () => [categoryAttribute, conditionAttribute, designerAttribute, materialAttribute, colorAttribute, sizeAttribute];
export const eBayUKAvailableAttributes = () => [categoryAttribute, conditionAttribute, designerAttribute, materialAttribute, colorAttribute, sizeAttribute];
export const eBayFRAvailableAttributes = () => [categoryAttribute, conditionAttribute, designerAttribute, materialAttribute, colorAttribute, sizeAttribute];
export const tradesyAvailableAttributes = () => [categoryAttribute, conditionAttribute, colorAttribute, materialAttribute];
export const saksAvailableAttributes = () => [categoryAttribute, sizeAttribute, conditionAttribute, colorAttribute, designerAttribute];
export const reebonzAvailableAttributes = () => [categoryAttribute, conditionAttribute];
export const CDONAvailableAttributes = () => [categoryAttribute, conditionAttribute];
export const DTUTAvailableAttributes = () => [categoryAttribute];
export const aspectAvailableAttributes = () => [categoryAttribute];
export const campadreAvailableAttributes = () => [categoryAttribute, designerAttribute, sizeAttribute, colorAttribute, materialAttribute];
export const luxoAvailableAttributes = () => [categoryAttribute, designerAttribute];
export const sottAvailableAttributes = () => [categoryAttribute, sizeAttribute, designerAttribute];
export const riteAvailableAttributes = () => [categoryAttribute, sizeAttribute, designerAttribute, conditionAttribute];
export const cavalliAvailableAttributes = () => [categoryAttribute, sizeAttribute, designerAttribute];
export const theVintageBarAvailableAttributes = () => [categoryAttribute, conditionAttribute, materialAttribute, colorAttribute, sizeAttribute];
