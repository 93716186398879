import * as _ from 'lodash';
export const RETAILER_COMP = 'RETAILER_COMP';

export const listProductRouter = (id, isAdmin) => {
  if (isAdmin) {
    return ['/', 'admin', 'product'];
  } else {
    return ['/', 'retailer', id, 'product'];
  }
};

export const listUserRouter = (id) => {
  return ['/', 'retailer', id, 'user'];
};

export const retailerDashboardRouter = () => {
  return ['/', 'retailer', 'dashboard'];
};
export const retailerCustomerCareDashboardRouter = () => {
  return ['/', 'customer', 'voucher'];
};

export const retailerCredentialRouter = () => {
  return ['/', 'retailer', 'credential'];
};

export const getItemsPutOnSaleData = (data) => {
  let itemsPutOnSale = _.map(data, (item) => item.items_sale);
  return itemsPutOnSale;
};

export const getItemsSoldData = (data) => {
  let itemsSold = _.map(data, (item) => item.items_sold);
  return itemsSold;
};

export const getXAxisData = (data) => {
  let xMonthInNumber = _.map(data, (item) => {
    let dateItems = _.split(item.date, '-', 2);
    return _.last(dateItems);
  });
  let xMonthInString = _.map(xMonthInNumber, (item) => {
    switch (item) {
      case '01':
        return 'Jan';
      case '02':
        return 'Feb';
      case '03':
        return 'Mar';
      case '04':
        return 'Apr';
      case '05':
        return 'May';
      case '06':
        return 'Jun';
      case '07':
        return 'Jul';
      case '08':
        return 'Aug';
      case '09':
        return 'Sep';
      case '10':
        return 'Oct';
      case '11':
        return 'Nov';
      case '12':
        return 'Dec';
    }
  });
  return xMonthInString;
};

export const getItemsSoldCategorySplitChartLabels = (data) => {
  let labels = _.map(data, (item) => item.name);
  return labels;
};

export const getItemsSoldCategorySplitChartPercentages = (data) => {
  let total = _.sumBy(data, (item: any) => item.lenght);
  let percentages = _.map(data, (item) => {
    return (item.lenght / total) * 100;
  });
  return percentages;
};

export const getItemsPurchasedChartData = (data) => {
  let totalSaleItems = _.sumBy(data, (item: any) => item.items_sale);
  let totalSoldItems = _.sumBy(data, (item: any) => item.items_sold);
  if (totalSoldItems === 0) {
    return totalSoldItems;
  }
  return Math.floor((totalSoldItems / totalSaleItems) * 100);
};

export const getItemsPurchasedChartRadialBarData = (number) => {
  let numberDigits = _.split(number.toString(), '');
  let lastDigit = Number(numberDigits.pop());
  if (lastDigit <= 2 || lastDigit >= 8) {
    lastDigit = 0;
  } else {
    lastDigit = 5;
  }
  numberDigits.push(lastDigit.toString());

  return parseInt(_.join(numberDigits, ''), 10);
};
