import * as _ from 'lodash';
import { RENDER_BUY_VOUCHER_FORM_REQUESTED, BUY_VOUCHER_SUCCEEDED } from './buy.actions';

export const buyVoucher = (
  state = {
    bought: false,
    item: {},
    inputs: []
  },
  action
) => {
  switch (action.type) {
    case RENDER_BUY_VOUCHER_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case BUY_VOUCHER_SUCCEEDED:
      return _.assign({}, state, { item: action.data, bought: true });
    default:
      return state;
  }
};
