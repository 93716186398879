import { USER_ROLES } from './../../../../app.const';
import { AGEGROUPS, DataObjectMarketplaces, GENDERS, otherReferences } from './../../../../models/DatasetObject';
import { Router } from '@angular/router';
import { FETCH_DATASETS_REQUESTED } from './../list/list.actions';
import {
  DELETE_DATASET_REQUESTED,
  GET_DATASET_REQUESTED,
  GET_DATASET_SUCCEEDED,
  EDIT_DATASET_REQUESTED,
  BINDING_DATASET_DATA,
  EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED,
  EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED,
  RESET_STATE_EDIT_DATASET,
  BINDING_DATASET_DATA_SUCCESS,
  REFRESH_DATASET_REQUEST,
  REFRESH_DATASET_SUCCESS
} from './edit.actions';
import { takeEvery, put, takeLatest, take, select, all, call, delay } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { GET_ALL_DATASET_CONDITIONS_SUCCEEDED, GET_ALL_DATASET_OBJECTS_SUCCEEDED, GET_ALL_DATASET_OPERATORS_SUCCEEDED } from '../dataset.action';
import * as _ from 'lodash';
import { default_item, listRouter, selectDatasetOperator } from '../dataset.const';
import { assign, head, includes, isNil } from 'lodash';
import { DataOperators } from '../../../../models/DatasetOperator';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* edit(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.dataset.update(action.data.id, action.data).toPromise();
    if (action.data.refresh) {
      yield api.dataset.refresh(action.data.id).toPromise();
      notification.show('success', 'Refresh dataset success', 3000);
      yield put({ type: REFRESH_DATASET_SUCCESS });
    }
    yield put({
      type: RESET_STATE_EDIT_DATASET
    });
    router.navigate(listRouter());
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditDatasetRequest() {
  yield takeEvery(EDIT_DATASET_REQUESTED, edit);
}

function* getDataset(action) {
  const api = AppInjector.get(ApiService);
  const { datasetId, params } = action.data;
  try {
    let result = yield api.dataset.getItemById(datasetId, params).toPromise();
    yield put({ type: GET_DATASET_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetDatasetRequest() {
  yield takeEvery(GET_DATASET_REQUESTED, getDataset);
}

function* deleteDataset(action) {
  const api = AppInjector.get(ApiService);
  const { queryParams } = action;
  try {
    yield api.dataset.delete(action.data).toPromise();
    yield put({ type: FETCH_DATASETS_REQUESTED, data: queryParams });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteDatasetRequest() {
  yield takeEvery(DELETE_DATASET_REQUESTED, deleteDataset);
}

function* watchDatasetDataFetched() {
  while (true) {
    const result = yield take([GET_ALL_DATASET_CONDITIONS_SUCCEEDED, GET_ALL_DATASET_OBJECTS_SUCCEEDED, GET_ALL_DATASET_OPERATORS_SUCCEEDED]);
    const dataset_conditions = yield select((state) => (state as any).Retailer.Dataset.edit.dataset_conditions);
    const dataset_objects = yield select((state) => (state as any).Retailer.Dataset.edit.dataset_objects);
    const dataset_operators = yield select((state) => (state as any).Retailer.Dataset.edit.dataset_operators);
    yield put({
      type: BINDING_DATASET_DATA,
      dataset_conditions,
      dataset_objects,
      dataset_operators
    });
  }
}

function* mapReferenceContants(condition, dataset_objects, dataset_operators) {
  let constants = [];
  const api = AppInjector.get(ApiService);
  condition.dataset_object = _.find(dataset_objects, { id: condition.dataset_object_id });
  condition.dataset_operator = _.find(dataset_operators, { id: condition.dataset_operator_id });
  switch (true) {
    case includes([DataObjectMarketplaces.is_not_published, DataObjectMarketplaces.is_published], condition.dataset_object.label):
      constants = yield api.admin.user.getMarketplaces().toPromise();
      constants = constants.map((i) => assign(i, { label: i.marketplace.company }));
      constants.unshift(default_item);
      break;
    case includes([DataObjectMarketplaces.product_item_location], condition.dataset_object.label):
      const locations = yield api.admin.location.get({ per_page: 10000 }).toPromise();
      constants = locations.items;
      constants = constants.map((i) => assign(i, { label: i.name }));
      constants.unshift(default_item);
      break;
    case !isNil(condition.dataset_object.sql_reference) && condition.dataset_object.sql_reference !== '':
      let references = condition.dataset_object.sql_reference.split('.');
      let params = {};
      if (head(references) === otherReferences.agegroup) {
        constants = AGEGROUPS;
      } else if (head(references) === otherReferences.gender) {
        constants = GENDERS;
      } else if (head(references) === otherReferences.retailer) {
        constants = yield AppInjector.get(ApiService)
          .user.list({ constraints: JSON.stringify({ role_ids: USER_ROLES.RETAILER }) })
          .toPromise();
        constants = constants.map((i) => assign(i, { label: i.email }));
      } else {
        constants = yield api.admin.magic.getListBaseOnReference(head(references), params).toPromise();
        constants = constants.map((i) => assign(i, { label: i.name }));
      }
      if (condition.dataset_operator && [DataOperators.is_equal_to, DataOperators.is_not_equal_to].indexOf(condition.dataset_operator.label) > -1) {
        constants.unshift(default_item);
      }
      break;
    default:
      break;
  }
  condition.constants = constants;
  condition.fetched = true;
  selectDatasetOperator(condition.dataset_operator, condition);
  return condition;
}

function* bindingDatasetData(action) {
  if (action.dataset_conditions.items.length > 0 && action.dataset_objects.items.length > 0 && action.dataset_operators.items.length > 0) {
    let dataset_conditions = action.dataset_conditions.items;
    let dataset_objects = action.dataset_objects.items;
    let dataset_operators = action.dataset_operators.items;
    yield all(dataset_conditions.map((condition) => call(mapReferenceContants, condition, dataset_objects, dataset_operators)));
    yield delay(100);
    yield put({
      type: BINDING_DATASET_DATA_SUCCESS,
      state_fetched: true
    });
  }
}

function* watchBindingDatasetData(action) {
  yield takeEvery(BINDING_DATASET_DATA, bindingDatasetData);
}

function* watchFillContantDropdownConditionRequest() {
  yield takeEvery(EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result;
      const { dataset_object, dataset_operator } = action.condition;
      switch (true) {
        case includes([DataObjectMarketplaces.is_not_published, DataObjectMarketplaces.is_published], dataset_object.label):
          result = yield api.admin.user.getMarketplaces().toPromise();
          result = result.map((i) => assign(i, { label: i.marketplace.company }));
          result.unshift(default_item);
          break;
        case includes([DataObjectMarketplaces.product_item_location], dataset_object.label):
          result = yield api.admin.location.get({ per_page: 10000 }).toPromise();
          result = result.map((i) => assign(i, { label: i.name }));
          result.unshift(default_item);
          break;
        case !isNil(dataset_object.sql_reference):
          let references = dataset_object.sql_reference.split('.');
          let params = {};
          if (!isNil(head(references)) && references.length > 0) {
            if (head(references) === otherReferences.agegroup) {
              result = AGEGROUPS;
            } else if (head(references) === otherReferences.gender) {
              result = GENDERS;
            } else if (head(references) === otherReferences.retailer) {
              result = yield AppInjector.get(ApiService)
                .user.list({ constraints: JSON.stringify({ role_ids: USER_ROLES.RETAILER }) })
                .toPromise();
              result = result.map((i) => assign(i, { label: i.email }));
            } else {
              result = yield api.admin.magic.getListBaseOnReference(head(references), params).toPromise();
              result = result.map((i) => assign(i, { label: i.name }));
            }
          }
          if (dataset_operator && [DataOperators.is_equal_to, DataOperators.is_not_equal_to].indexOf(dataset_operator.label) > -1) {
            result.unshift(default_item);
          }
          break;
        default:
          break;
      }
      yield put({ type: EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED, constants: result, condition: action.condition });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRefreshDatasetRequest() {
  yield takeLatest(REFRESH_DATASET_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notification = AppInjector.get(NotificationService);
    const { dataset } = action.data;
    try {
      yield api.dataset.refresh(dataset.id).toPromise();
      notification.show('success', 'Refresh dataset success', 3000);
      yield put({ type: REFRESH_DATASET_SUCCESS });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchEditDatasetRequest,
  watchGetDatasetRequest,
  watchDeleteDatasetRequest,
  watchDatasetDataFetched,
  watchBindingDatasetData,
  watchFillContantDropdownConditionRequest,
  watchRefreshDatasetRequest
];
