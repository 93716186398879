import Model from './Model';
import * as _ from 'lodash';
import Country from './Country';

export class Address extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}
