import * as _ from 'lodash';
import { GET_DETAIL_CUSTOMER_SUCCESSED } from './detail.actions';

export const detail = (state = { fetched: false }, action) => {
  switch (action.type) {
    case GET_DETAIL_CUSTOMER_SUCCESSED:
      return _.assign({}, state, { fetched: true, item: action.data });
    default:
      return state;
  }
};
