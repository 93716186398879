import Model from './Model';

interface ProductCloneHistoryResult {
  has_cloned: String
  cloned_from: String
  created_at: any
  updated_at: any
  id: Number
}

export class ProductCloneHistory extends Model {
  product_clone_history: Array<ProductCloneHistoryResult>

  constructor(options) {
    super();
    this.bind(options);
  }

  get(): Array<any> {
    return this.product_clone_history
  }
}
