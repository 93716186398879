import { Component, OnInit } from '@angular/core';
import { listRouter, editRouter } from '../product.const';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ApiService } from '../../../../api/api.service';
declare const $: any;

@Component({
  selector: 'app-modify-description',
  templateUrl: './modify-description.component.html',
  styleUrls: ['./modify-description.component.scss']
})
export class ModifyDescriptionComponent implements OnInit {
  public listRouter = listRouter();
  public detailProductRouter = editRouter;
  public uploading = {
    process: false,
    error: false
  };
  startModifying;
  public repsonseUpload;

  constructor(private api: ApiService, private notification: NotificationService) {}

  ngOnInit() {}
  completeUpload($event) {
    this.repsonseUpload = $event.response.data[0];
    this.startModifying = true;
    setTimeout(() => {
      this.api.admin.product.modifyDescription({ file_url: $event.response.data[0].full_path }).subscribe(
        (res) => {
          let resultUrl = res.url ? res.url : '';
          this.notification.show(
            'success',
            `Modify description success <a href="${resultUrl}">Click here
          </a>`,
            20000
          );
          this.startModifying = false;
        },
        (err) => {
          this.notification.show('warning', err.error.message, 2000);
          this.startModifying = false;
        }
      );
    }, 1000);
  }

  errorUpload($event) {
    console.log('error', $event);
  }

  importBtnClicked() {
    let importSection = document.getElementById('import-section');
    importSection.click();
  }
}
