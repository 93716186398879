import Model from './Model';
import UserBase from './Base/UserBase';
import * as _ from 'lodash';
import Dataset from './Dataset';
import Markup from './Markup';
import ArrayList from './ArrayList';
import Category from './Category';

interface IntegrationInterface {
  retailer: any;
  marketplace: any;
  markup: any;
  datasets: any

  getCompanyName(): String;
  getWebsite(): String;
  getDescription(): String;
}
class Retailer extends UserBase {
  constructor(options) {
    super(options);

    (this as any).retailer = d => {
      return new Retailer(d.data);
    };

    (this as any).marketplace = d => {
      return new Marketplace(d.data);
    };

    this.bind(options);
  }
}
class Marketplace extends UserBase {
  constructor(options) {
    super(options);

    (this as any).retailer = d => {
      return new Retailer(d.data);
    };

    (this as any).marketplace = d => {
      return new Marketplace(d.data);
    };

    this.bind(options);
  }
}

export default class Integration extends Model implements IntegrationInterface {
  retailer: any;
  marketplace: any;
  datasets: any;
  markup: any;

  constructor(options) {
    super();
    this.retailer = d => new Retailer(d.data);
    this.marketplace = d => new Marketplace(d.data);
    this.datasets = d => new ArrayList<Dataset>(Dataset, d.data);
    this.markup = d => new Markup(d.data);
    this.bind(options);
  }

  getCompanyName() {
    if (!_.isUndefined(this.marketplace)) {
      return this.marketplace.getCompanyName();
    } else {
      return '';
    }
  }

  getWebsite() {
    if (!_.isUndefined(this.marketplace)) {
      return this.marketplace.marketplace.website;
    } else {
      return '';
    }
  }

  getDescription() {
    if (!_.isUndefined(this.marketplace)) {
      return this.marketplace.marketplace.description;
    } else {
      return '';
    }
  }
}
