export const FETCH_SIZE_ATTRIBUTE_REQUESTED = 'FETCH_SIZE_ATTRIBUTE_REQUESTED';
export const FETCH_SIZE_ATTRIBUTE_SUCCESSED = 'FETCH_SIZE_ATTRIBUTE_SUCCESSED';
export const CREATE_SIZE_REQUESTED = 'CREATE_SIZE_REQUESTED';
export const CREATE_SIZE_SUCCESSED = 'CREATE_SIZE_SUCCESSED';
export const FETCH_SIZE_DICTIONARY_REQUESTED = 'FETCH_SIZE_DICTIONARY_REQUESTED';
export const FETCH_SIZE_DICTIONARY_SUCCESSED = 'FETCH_SIZE_DICTIONARY_SUCCESSED';
export const RESET_SIZE_DICTIONARY = 'RESET_SIZE_DICTIONARY';
export const CREATE_SIZE_DICTIONARY_SUCCESSED = 'CREATE_SIZE_DICTIONARY_SUCCESSED';
export const DELETE_SIZE_ATTRIBUTE_REQUESTED = 'DELETE_SIZE_ATTRIBUTE_REQUESTED';
export const DELETE_SIZE_ATTRIBUTE_SUCCESSED = 'DELETE_SIZE_ATTRIBUTE_SUCCESSED';
export const EDIT_SIZE_REQUESTED = 'EDIT_SIZE_REQUESTED';
export const EDIT_SIZE_SUCCESSED = 'EDIT_SIZE_SUCCESSED';
export const EDIT_SIZE_DICTIONARY_REQUESTED = 'EDIT_SIZE_DICTIONARY_REQUESTED';
export const EDIT_SIZE_DICTIONARY_SUCCESSED = 'EDIT_SIZE_DICTIONARY_SUCCESSED';
