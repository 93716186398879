import Model from '../Model';

class TransactionHistoryBase extends Model {
  constructor(options) {
    super();

    this.bind(options);
  }
}

export default TransactionHistoryBase;
