import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FETCH_PRODUCT_HISTORY_REQUESTED } from './history.action';
import * as _ from 'lodash';
import { asyncProcessRouter, editRouter } from '../product.const';

@Component({
  selector: 'app-product-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends BaseComponent implements OnInit {
  public paramsList = ['sort', 'constraints', 'page', 'per_page', 'search', 'includes'];
  private includes = ['user', 'async_process'];
  public asyncProcessRouter = asyncProcessRouter;
  public editRouter = editRouter;

  get productId() {
    return this.activatedRoute.snapshot.params.id;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const params: any = this.queryParser.parse(this.paramsList);
        this.dispatch({ type: FETCH_PRODUCT_HISTORY_REQUESTED, data: this.getParams() });
      }
    });
  }

  getConstraints({ productId, data }) {
    const constraintsFromQuery = data && data.constraints ? JSON.parse(data.constraints) : {};
    return {
      constraints: JSON.stringify({
        ...constraintsFromQuery,
        product_id: productId
      })
    };
  }

  getParams() {
    let data: any = this.queryParser.parse(this.paramsList);
    return {
      ...data,
      ...this.getIncludes({ data }),
      ...this.getConstraints({ productId: this.productId, data })
    };
  }

  getIncludes({ data }) {
    const includesFromQuery = data && data.includes ? data.includes.split(',') : [];
    return { includes: this.includes.concat(includesFromQuery).join(',') };
  }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return { payload: state.Admin.Product.History };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
