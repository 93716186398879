import { put, takeLatest } from 'redux-saga/effects';
import { FETCH_DATA_ADMIN_LOCATION_REQUESTED, FETCH_DATA_ADMIN_LOCATION_SUCCESSED } from './detail.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';


function* watchCreateLocationRequest() {
    yield takeLatest(FETCH_DATA_ADMIN_LOCATION_REQUESTED, function* (action: any) {
      try {
        const result = yield AppInjector.get(ApiService).admin.location.show(action.data.id).toPromise();
        yield put({ type: FETCH_DATA_ADMIN_LOCATION_SUCCESSED, data: result });
      } catch (e) {
        yield put({ type: API_CALL_ERROR, error: e });
      }
    });
  }

  export default [watchCreateLocationRequest];
