import { takeEvery, put } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import {
    RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS,
    RETAILER_SECOND_HAND_SALES_DRAW_ITEMS_PUT_ON_SALE_CHART,
    RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS,
    RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_SUCCESS,
    RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_SUCCESS,
    RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_REQUEST,
    RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_SUCCESS,
    FETCH_2ND_HAND_SALES_REQUESTED, FETCH_2ND_HAND_SALES_SUCCEEDED
} from './second-hand-sales.const';
import { FETCH_RESALE_ACTIVITTY_REQUESTED } from '../resale/resale.const';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

function* watchRetailerGerItemsPutOnSaleChartDataRequest() {
  yield takeEvery(RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getNumberSoldSaleProductsByDate(action.data).toPromise();
      yield put({ type: RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerGerItemsPutOnSaleChartDataSuccess() {
  yield takeEvery(RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS, function*(action: any) {
    yield put({ type: RETAILER_SECOND_HAND_SALES_DRAW_ITEMS_PUT_ON_SALE_CHART });
  });
}

function* watchRetailerGerItemsSoldCategorySplitChartDataRequest() {
  yield takeEvery(RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getNumberSoldProductsByCategorySplit(action.data).toPromise();
      yield put({ type: RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerSecondHandGeAvgDisscountDataRequest() {
  yield takeEvery(RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getAvgDisscount().toPromise();
      yield put({ type: RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerSecondHandGetSaleInfoDataRequest() {
  yield takeEvery(RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getSaleInformation().toPromise();
      yield put({ type: RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerSecondHandGetAvgItemsPerCusDataRequest() {
  yield takeEvery(RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getAvgSoldItemPerCus().toPromise();
      yield put({ type: RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* fetch2ndHandSales(action) {
  yield takeEvery(FETCH_2ND_HAND_SALES_REQUESTED, function*(action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const api = AppInjector.get(ApiService);
      let result = yield api.analytic.get2ndHandSalesEmbeddedUrl().toPromise();
      AppInjector.get(PreloaderService).hide();
      yield put({ type: FETCH_2ND_HAND_SALES_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}


export default [
  watchRetailerSecondHandGeAvgDisscountDataRequest,
  watchRetailerGerItemsPutOnSaleChartDataRequest,
  watchRetailerGerItemsPutOnSaleChartDataSuccess,
  watchRetailerGerItemsSoldCategorySplitChartDataRequest,
  watchRetailerSecondHandGetSaleInfoDataRequest,
  watchRetailerSecondHandGetAvgItemsPerCusDataRequest,
  fetch2ndHandSales
];
