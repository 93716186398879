import Model from './Model';
import { Address } from './Address';
import User from './User';
import Customer from './Customer';
import * as _ from 'lodash';
import Product from './Product';
import Shipment from './Shipment';
import CollectionExpectedListItem from './CollectionExpectedListItem';

export const COLLECTION_TYPES = {
  BUYBACK: 'BUYBACK',
  TAKEBACK: 'TAKEBACK'
};

export const COLLECTION_TYPE = {
  BUYBACK: 'BUYBACK',
  PICKUP: 'PICKUP',
  DROP_OFF: 'DROP_OFF',
  TAKEBACK: 'TAKEBACK'
};

class Collection extends Model {
  constructor(options) {
    super();
    (this as any).address = (d) => {
      return new Address(d.data);
    };
    (this as any).seller = (d) => {
      return new User(d.data);
    };
    (this as any).retailer = (d) => {
      return new User(d.data);
    };
    (this as any).customer = (d) => {
      return new Customer(d.data);
    };
    (this as any).shipments = (d) => {
      return _.map(d.data, (item) => new Shipment(item));
    };
    (this as any).approved_products = (product) => {
      return _.map(product.data, (item) => new Product(item));
    };
    (this as any).products = (product) => {
      return _.map(product.data, (item) => new Product(item));
    };
    (this as any).rejected_products = (product) => {
      return _.map(product.data, (item) => new Product(item));
    };
    (this as any).collection_expected_list_items = (d) => {
      return _.map(d.data, (item) => new CollectionExpectedListItem(item));
    };
    this.bind(options);
  }

  isTakeBack() {
    return (this as any).type === COLLECTION_TYPES.TAKEBACK;
  }
}

export default Collection;
