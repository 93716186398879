import { Address } from './Address';
import * as _ from 'lodash';
import Model from './Model';
import UserBase from './Base/UserBase';
import Product from './Product';
import Customer from './Customer';
import Currency from './Currency';
import * as moment from 'moment';
import ProductItem from './ProductItem';

export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_FULLY_READY_TO_BE_SHIPPED = 'fully_ready_to_be_shipped';
export const ORDER_STATUS_FULLY_DELIVERY = 'fully_delivery';
export const ORDER_STATUS_PROCESSING = 'processing';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_PARTIALLY_READY_TO_BE_SHIPPED = 'partially_ready_to_be_shipped';
export const ORDER_STATUS_PARTIALLY_DELIVERY = 'partially_delivery';
export const ORDER_STATUS_CANCELLED = 'cancelled';
export const ORDER_STATUS_PROCESSED = 'processed';

export const LIST_ORDER_STATUS = [
  { label: 'Processing', value: ORDER_STATUS_PROCESSING },
  { label: 'Processed', value: ORDER_STATUS_PROCESSED }
];

export const ORDER_CANCEL_REASONS = [
  { value: 'Out of stock - double sale' },
  { value: 'Out of stock - by seller' },
  { value: 'Order expired/missing on MP' },
  { value: 'Shipping issues/delay' },
  { value: 'Buyer cancellation' },
  { value: 'Seller cancellation' },
  { value: 'Internal - Admin user error' },
  { value: 'Internal - Listing error' },
  { value: 'Internal - Test' },
  { value: 'Selling price - Incorrect' }
];

export const ORDER_RETURN_REASONS = [
  { value: 'Shipping issues' },
  { value: 'Item does not fit' },
  { value: 'Buyer unsatisfied with item' },
  { value: 'Buyer no longer interested' },
  { value: 'Item differs from description' },
  { value: 'Item quality issues' },
  { value: 'Other' },
  { value: 'Order expired/missing on MP' },
  { value: 'Wrong item shipped' },
  { value: 'Authenticity issues' },
  { value: 'Not mentioned' }
];

export enum ORDER_TYPES {
  CANCEL = 'CANCEL',
  SALE = 'SALE',
  RETURN = 'RETURN'
}
class OrderProduct extends Model {
  constructor(options) {
    super();
    (this as any).product = (d) => {
      return new Product(d.data);
    };
    (this as any).payment = (d) => {
      return new Payment(d.data);
    };
    (this as any).product_item = (d) => {
      return new ProductItem(d.data);
    };
    this.bind(options);
  }

  getDateFromCurrentDateType(current_date_type) {
    let result = this['created_at'];
    if (!_.isNil(current_date_type) && current_date_type !== '' && current_date_type !== 'sold_date') {
      result = this[_.replace(current_date_type, 'order_item_', '')];
    }
    return result;
  }

  getRetailer() {
    const item = this as any;
    if (item.retailer) {
      if (item.retailer.data) {
        if (item.retailer.data.retailer) {
          if (item.retailer.data.retailer.data) {
            return item.retailer.data.retailer.data;
          }
        }
      }
    }
    return null;
  }
}

class Payment extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

class Order extends Model {
  constructor(options) {
    super();
    (this as any).products = (d) => {
      return _.map(d.data, (item) => new Product(item));
    };

    (this as any).addresses = (d) => {
      return _.map(d.data, (item) => new Address(item));
    };

    (this as any).shipment_address = (d) => {
      return new Address(d.data);
    };

    (this as any).billing_address = (d) => {
      return new Address(d.data);
    };

    (this as any).customer = (d) => {
      return new Customer(d.data);
    };
    (this as any).currency = (d) => {
      return new Currency(d.data);
    };
    (this as any).order_products = (d) => {
      return _.map(d.data, (item) => new OrderProduct(item));
    };

    this.bind(options);
  }

  getFullName() {
    return (this as any).first_name + ' ' + (this as any).last_name;
  }

  getItemsCount() {
    const { order_products } = this as any;
    let count = 0;
    if (order_products.length) {
      count = order_products.length;
    }
    return count;
  }

  getPurchaseOn() {
    const { created_at } = this as any;
    return moment(created_at).format('MMM D, YYYY');
  }

  getOrderSubTotal() {
    const { sub_total, currency } = this as any;
    let result = '';
    if (sub_total) {
      result = `${currency.code}${sub_total}`;
    }
    return result;
  }

  getShipToName() {
    const { shipment_address } = this as any;
    return shipment_address ? shipment_address.company : '';
  }

  getDateFromCurrentDateType(current_date_type) {
    let result = this['sold_date'];
    return result;
  }
}

export default Order;
