import { GET_LIST_GROUP_DATASET_SUCCESS } from './list-group.actions';
export const listGroup = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case GET_LIST_GROUP_DATASET_SUCCESS: {
      return Object.assign({}, state, { fetched: true, items: action.data });
    }
    default:
      return state;
  }
};
