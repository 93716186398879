import * as _ from 'lodash';
import * as moment from 'moment';
import { GET_TEMPLATES_EMAIL_SUCCEEDED, GET_EMAILS_SUCCEEDED } from './list.actions';

export const list = (state = { fetchedTemplate: false, fetchedEmail: false, fetchedMoreEmail: false }, action) => {
  switch (action.type) {
    case GET_TEMPLATES_EMAIL_SUCCEEDED:
      return _.assign({}, state, { fetchedTemplate: true, templates: action.data });
    case GET_EMAILS_SUCCEEDED:
      action.data.items.map(i => {
        i.sent_date = moment(new Date(i.sent_date * 1000)).format('YYYY/DD/MM HH:mm:ss');
        return i;
      });
      return _.assign({}, state, { fetchedEmail: true, emails: action.data });
    // case GET_EMAILS_SUCCEEDED:
    //   const data = action.data.map(i => {
    //     i.ts = moment(new Date(i.ts * 1000)).format("YYYY/DD/MM HH:mm:ss");
    //     return i;
    //   });
    //   return _.assign({}, state, { fetchedEmail: true, emails: data, fetchedMoreEmail: true });
    default:
      return state;
  }
};
