import * as _ from 'lodash';
import { RENDER_CREATE_ADDRESS_FORM_REQUESTED, FILL_DATA_INPUTS_OPTIONS_REQUESTED, UPDATE_ALPHA_2_CODE_VALUE_REQUESTED } from './create.actions';
import { support } from '@reflaunt/reactive-form';

export const create = (state: any = { created: false, item: {} }, action) => {
  switch (action.type) {
    case RENDER_CREATE_ADDRESS_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_DATA_INPUTS_OPTIONS_REQUESTED:
      state.inputs = support.UpdateInputsValue(state.inputs, action.data);
      state.inputs = support.UpdateFormValue(state.inputs, {
        phonecode: {
          code: '44',
          value: '',
          alpha2Code: 'GB'
        },
        country_alpha2_code: 'GB',
        country: _.find(action.data.country, item => item.value === 'United Kingdom')
      });
      return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_ALPHA_2_CODE_VALUE_REQUESTED:
      return _.assign({}, state, {
        inputs: support.UpdateFormValue(
          state.inputs,
          _.assign({}, action.data, {
            country_alpha2_code: action.data.country.alpha2Code,
            phonecode: {
              code: action.data.country.callingCodes[0],
              value: action.data.country.value,
              alpha2Code: action.data.country.alpha2Code
            }
          })
        )
      });
    default:
      return state;
  }
};
