import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import list from './list/list.saga';
import detail from './detail/detail.saga';
import edit from './edit/edit.saga';
import create from './create/create.saga';
import { GET_ALL_ADMIN_LOCATION_REQUESTED, GET_ALL_ADMIN_LOCATION_SUCCESSED } from './location.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAllLocations(params?) {
  const fetched = yield select((state) => (state as any).Admin.Location.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.location.get({ ...params, per_page: 10000 }).toPromise();
      return result.items;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select((state) => (state as any).Admin.Location.all.items);
    return data;
  }
}

function* watchFetchAllLocationsRequest() {
  yield takeLatest(GET_ALL_ADMIN_LOCATION_REQUESTED, function* (action: any) {
    const data = yield call(fetchAllLocations);
    yield put({ type: GET_ALL_ADMIN_LOCATION_SUCCESSED, data: data });
  });
}

export default [...list, ...detail, ...edit, ...create, watchFetchAllLocationsRequest];
