import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { GET_ALL_TAGS_SUCCEEDED } from './tag.action';
import { EDIT_TAG_SUCCEEDED, DELETE_TAG_SUCCEEDED } from './edit/edit.actions';
import { CREATE_TAG_SUCCEEDED } from './create/create.actions';
const all = (state = { fetched: false, items: []}, action) => {
  switch (action.type) {
    case CREATE_TAG_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case EDIT_TAG_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case DELETE_TAG_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case GET_ALL_TAGS_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};
export const Tag = combineReducers({
  list, edit, create, all
});
