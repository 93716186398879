import { BaseComponent } from '../../../base.component';
import { ADMIN_INTEGRATION_GET_RETAILER_REQUEST, CREATE_RETAILER_DEFAULT_INTEGRATION_REQUEST } from './marketplace-management.actions';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TemplateRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { defaultConfigRouter } from '../integration.const';

@Component({
  selector: 'app-marketplace-management',
  templateUrl: './marketplace-management.component.html',
  styleUrls: ['./marketplace-management.component.scss']
})
export class MarketplaceManagementComponent extends BaseComponent implements OnInit {
  @ViewChild('marketplaceConfigBoxes', { static: true }) marketplaceConfigBoxes: TemplateRef<any>;
  @ViewChild('retailerDefaultConfigBox', { static: true }) retailerDefaultConfigBox: TemplateRef<any>;

  public reducer = 'Admin.Integration.MarketplaceManagement';
  public isDisplayOptions = false;
  public data;
  public defaultConfigRouter: any = defaultConfigRouter;

  constructor(private activatedRoute: ActivatedRoute) {
    super();
    this.store.dispatch({
      type: ADMIN_INTEGRATION_GET_RETAILER_REQUEST,
      data: this.activatedRoute.snapshot.params.retailerId
    });
  }

  ngOnInit() {
    this.init();
  }

  onCreateRetailerDefaultIntegration(e, retailerId) {
    e.preventDefault();
    const { dispatchCreateRetailerDefaultIntegration } = this as any;
    dispatchCreateRetailerDefaultIntegration(retailerId);
  }

  config(val) {
    this.isDisplayOptions = true;
    this.data = val;
  }

  close(event) {
    this.isDisplayOptions = event.currentState;
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.Integration.MarketplaceManagement
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch,
      dispatchCreateRetailerDefaultIntegration: (retailerId) => {
        dispatch({ type: CREATE_RETAILER_DEFAULT_INTEGRATION_REQUEST, data: retailerId });
      }
    };
  }
}
