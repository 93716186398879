import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Material from '../../../models/Material';

@Injectable()
export class MaterialService extends ServiceProvider {
  public url = '/api/v1/admin/materials';
  public model = Material;
}
