import * as _ from 'lodash';
import Role from './Role';
import Customer from './Customer';
import UserBase from './Base/UserBase';
import Retailer from './Retailer';
import Marketplace from './Marketplace';
import Voucher from './Voucher';
import Image from './Image';
import { Address } from './Address';
import SmartObject from './SmartObject';
import Model from './Model';
import RetailerService from './RetailerService';
import MarketplaceCommission from './MarketplaceCommission';
import ServiceConfiguration from './ServiceConfiguration';

class MarketplaceManagement extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

class User extends UserBase {
  constructor(options) {
    super(options);
    (this as any).roles = (d) => {
      return _.map(d.data, (item) => new Role(item));
    };

    (this as any).address = (d) => {
      return _.map(d.data, (item) => new Address(item));
    };

    (this as any).vouchers = (d) => {
      return _.map(d.data, (item) => new Voucher(item));
    };

    (this as any).customer = (d) => {
      return new Customer(d.data);
    };

    (this as any).retailer = (d) => {
      return new Retailer(d.data);
    };

    (this as any).marketplace = (d) => {
      return new Marketplace(d.data);
    };
    (this as any).marketplaces = (d) => {
      return _.map(d.data, (item) => new User(item));
    };
    (this as any).images = (d) => {
      return _.map(d.data, (item) => new Image(item));
    };
    (this as any).smart_objects = (d) => {
      return _.map(d.data, (item) => new SmartObject(item));
    };
    (this as any).user_configs = (d) => {
      return _.map(d.data, (item) => new SmartObject(item));
    };
    (this as any).default_integration = (d) => {
      return new MarketplaceManagement(d.data);
    };
    (this as any).retailer_services = (d) => {
      return _.map(d.data, (item) => new RetailerService(item));
    };
    (this as any).marketplace_commissions = (d) => {
      return _.map(d.data, (item) => new MarketplaceCommission(item));
    };
    (this as any).service_configurations = (d) => {
      return _.map(d.data, (item) => new ServiceConfiguration(item));
    };

    this.bind(options);
  }

  getName() {
    return (this as any).customer ? (this as any).customer.getFullName() : '';
  }

  getFirstName() {
    return (this as any).customer ? (this as any).customer.getFirstName() : '';
  }

  getLastName() {
    return (this as any).customer ? (this as any).customer.getLastName() : '';
  }

  getGender() {
    return (this as any).customer ? (this as any).customer.getGender() : '';
  }

  getRoleName() {
    if (_.isArray((this as any).roles) && (this as any).roles.length > 0) {
      return _.map((this as any).roles, (role) => role.getName()).join(',');
    } else {
      return '';
    }
  }

  getMainAddress() {
    return (this as any).customer ? (this as any).customer.getMainAddress() : '';
  }

  getRetailerCompany() {
    return (this as any).retailer ? (this as any).retailer.company : this.email;
  }

  getMarketplaceCompany() {
    return (this as any).marketplace ? (this as any).marketplace.company : this.email;
  }

  getPermissions() {
    if (!_.isArray((this as any).roles)) {
      return [];
    } else {
      let permissions = [];
      _.forEach((this as any).roles, (role) => {
        permissions = [...permissions, ...(role as any).getAllPermissions()];
      });
      return permissions;
    }
  }

  isRole(role) {
    if (!_.isArray((this as any).roles)) {
      return false;
    } else {
      let isRole = false;
      _.forEach((this as any).roles, (item) => {
        if (item.getSlug() === role) {
          isRole = true;
        }
      });
      return isRole;
    }
  }

  isAdmin() {
    return this.isRole('superadmin') || this.isRole('admin');
  }

  isMarketplace() {
    return this.isRole('marketplace');
  }

  isSuperAdmin() {
    return this.isRole('superadmin');
  }

  hasOneOf(permissions: String[]) {
    return this.isSuperAdmin() || _.intersection(permissions, this.getPermissions()).length > 0;
  }

  can(permission: String) {
    return this.isSuperAdmin() || _.includes(this.getPermissions(), permission);
  }

  getAvatarStyleObject() {
    return { 'background-image': `url("${this.getAvatar()}")`, 'background-size': 'cover' };
  }
}

export default User;
