import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from '../../../base.component';
import { ApiService } from '../../../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { GET_ALL_USER_SERVICE_CONFIGURATION_REQUEST, SYNC_USER_SERVICE_CONFIGURATION_REQUEST, GET_USER_DETAIL_REQUEST } from './service-configuration.actions';
import { listAdminMarketplace } from '../../marketplace/marketplace.const';

@Component({
  selector: 'app-service-configuration',
  templateUrl: './service-configuration.component.html',
  styleUrls: ['./service-configuration.component.scss']
})
export class ServiceConfigurationComponent extends BaseComponent implements OnInit {
  public reducer: String = 'Admin.Users.ServiceConfiguration';
  public form = this.initForm();
  public listAdminMarketplace = listAdminMarketplace;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private api: ApiService) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const id = this.activatedRoute.snapshot.params.id;
        this.dispatch({ type: GET_USER_DETAIL_REQUEST, data: { id } });
        this.dispatch({ type: GET_ALL_USER_SERVICE_CONFIGURATION_REQUEST, data: { id } });
        this.api.admin.user.getAllServiceConfigurations(id).subscribe(result => {
          this.form = this.initForm(result);
        });
      }
    });
  }

  get variables() {
    return this.form.get('variables') as FormArray;
  }

  initForm(items = []) {
    const variables = (() => {
      if (items.length) {
        return this.fb.array(items.map(item => this.newFormGroup({ key: item.key, value: item.value })));
      }
      return this.fb.array([this.newFormGroup()]);
    })();
    return this.fb.group({ variables });
  }

  ngOnInit() {
    this.init();
  }

  newFormGroup({ key, value } = { key: '', value: '' }) {
    return this.fb.group({
      key: [key, Validators.required],
      value: [value]
    });
  }

  add() {
    this.variables.push(this.newFormGroup());
  }

  remove(key) {
    this.variables.removeAt(key);
  }

  submit() {
    if (this.form.valid) {
      const id = this.activatedRoute.snapshot.params.id;
      const data = this._transformFormValue(this.form.value.variables);
      this.dispatch({ type: SYNC_USER_SERVICE_CONFIGURATION_REQUEST, data: { id, data } });
    }
  }

  _transformFormValue(data) {
    return data.reduce((object, item) => {
      return Object.assign(object, { [item.key]: item.value });
    }, {});
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.Users.ServiceConfiguration
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }
}
