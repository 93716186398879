//TODO
import { put, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { COURIERS_OPTIONS_REQUESTED, COURIERS_OPTIONS_SUCCEDED, RATES_OPTIONS_REQUESTED, RATES_OPTIONS_SUCCEDED, REQUEST_CUSTOM_GENERATION_REQUESTED, REQUEST_CUSTOM_GENERATION_SUCEEDED } from './generate-shipment-label-v2.actions';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { Router } from '@angular/router';


export function* fetchAllCouriers() {
    yield takeEvery(COURIERS_OPTIONS_REQUESTED, function* (action: any) {
      AppInjector.get(PreloaderService).show();
        try{
            const courier_input = _.find(action.data.inputs, (e) => e.key === 'courier_name');
            courier_input.options = _.map(action.data.couriers, (i) => ({ ...i, ...{ label: i.name, value: i.code } }))
            AppInjector.get(PreloaderService).hide();
            yield put({ type: COURIERS_OPTIONS_SUCCEDED});
        } catch (e) {
          AppInjector.get(PreloaderService).hide();
            yield put({ type: API_CALL_ERROR, error: e });
        }
    });
}

  export function* fetchAllRatesByCourierId() {
    yield takeEvery(RATES_OPTIONS_REQUESTED, function* (action: any) {
        try {
          AppInjector.get(PreloaderService).show();
          const rate_input = _.find(action.data.inputs, (e) => e.key === 'rate_name');
          rate_input.options = _.map(action.rates_options.options, (i) => ({ ...i, ...{ label: `${i.service} ${i.description}`, value: i.id } }))
          AppInjector.get(PreloaderService).hide();
          yield put({ type: RATES_OPTIONS_SUCCEDED});
        } catch (e) {
          AppInjector.get(PreloaderService).hide();
          yield put({ type: API_CALL_ERROR, error: e });
        }
    });
  }

  export function* watchGenerateCustomLabel() {
    yield takeEvery(REQUEST_CUSTOM_GENERATION_REQUESTED, function* (action: any) {

        try {
          AppInjector.get(PreloaderService).show();
          
        yield AppInjector.get(ApiService).admin.shipment.generateCustomShippingLabel({
             shipment_id: action.shipment_id,
             shipment_courier: action.selected_courier.value.code!== 'my-flying-box' ? action.selected_courier.value.code : action.selected_rate.value.productCode,
             shipment_rate: `${action.selected_rate.value.id}`,
            }).toPromise();
          AppInjector.get(PreloaderService).hide();
        } catch (e) {
          AppInjector.get(PreloaderService).hide();
          yield put({ type: API_CALL_ERROR, error: e });
        } finally {
          yield AppInjector.get(Router).navigate(['admin', 'shipment',  action.shipment_id ,'detail']);
        }
    });
  }
  
export default [fetchAllCouriers,fetchAllRatesByCourierId,watchGenerateCustomLabel];
