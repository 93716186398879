import { takeEvery, put } from 'redux-saga/effects';

import { GET_DECLARE_PRODUCT_REQUESTED, GET_DECLARE_PRODUCT_SUCCEEDED, DECLARE_PRODUCT_NOT_AVAILABLE_REQUESTED, DECLARE_PRODUCT_NOT_AVAILABLE_SUCCEEDED } from './item-not-available.actions';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { API_CALL_ERROR } from '../../../store/action';

function* watchGetDeclareProductRequest() {
  yield takeEvery(GET_DECLARE_PRODUCT_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      let result = yield api.admin.product.getLandingPageProduct(action.data).toPromise();
      yield put({ type: GET_DECLARE_PRODUCT_SUCCEEDED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeclareProductNotAvailableRequest() {
  yield takeEvery(DECLARE_PRODUCT_NOT_AVAILABLE_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      yield api.product.declareNotAvailable(action.data).toPromise();
      yield put({ type: DECLARE_PRODUCT_NOT_AVAILABLE_SUCCEEDED });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetDeclareProductRequest, watchDeclareProductNotAvailableRequest];
