import Model from './Model';
import User from './User';

class HistoryPush extends Model {
  constructor(options) {
    super();
    (this as any).user = d => {
      return new User(d.data);
    };
    this.bind(options);
  }
}

export default HistoryPush;
