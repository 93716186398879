import {
  CREATE_PRODUCT_SUCCEEDED,
  RENDER_CREATE_PRODUCT_FORM_REQUESTED,
  SAVE_ALL_SIZES_OPTIONS_SUCCEEDED,
  UPDATE_CREATE_PRODUCT_INPUT_OPTIONS
} from './create.actions';
import * as _ from 'lodash';
import { AppInjector } from '../../../../app-injector';
import { Router } from '@angular/router';

export const create = (state: any = { created: false }, action) => {
  switch (action.type) {
    case RENDER_CREATE_PRODUCT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case CREATE_PRODUCT_SUCCEEDED:
      const url = `/admin/product/edit/${action.data.id}`;
      const param = {
        referer: `/admin/collection/detail/${action.data.collection_id}`,
        highlight: 'image',
        collection_id: action.data.collection_id,
        extra_params: JSON.stringify({ retailer_email: action.data.retailer.email })
      };
      const router = AppInjector.get(Router);
      router.navigate([url], { queryParams: param });
      return _.assign({}, state, { created: true, last_product: action.data });
    case UPDATE_CREATE_PRODUCT_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, input => {
          _.map(action.data, (value, key) => {
            if (input.key === key) {
              input.options = value;
            }
            return input;
          });
          return input;
        })
      });
    case SAVE_ALL_SIZES_OPTIONS_SUCCEEDED:
      return _.assign({}, state, { size_options: action.data, locations: action.locations });
    default:
      return state;
  }
};
