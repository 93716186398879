export const FETCH_RETAILER_CONDITIONS_SUCCESS = 'FETCH_RETAILER_CONDITIONS_SUCCESS';
export const ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_SUCCESS';

export const ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_CONDITION_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_CONDITION_REQUESTED';
export const TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED';

export const DELETE_RETAILER_CONDITION_MATCHING_REQUESTED = 'DELETE_RETAILER_CONDITION_MATCHING_REQUESTED';
export const ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_CONDITION_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_CONDITION_REQUESTED';
