import { Observable } from 'rxjs';
import { ApiUrl } from './../api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, map } from 'rxjs/operators';
import Constant from '../../models/Constant';
import * as _ from 'lodash';

@Injectable()
export class ConstantService {
  private url = '/api/v1/constants';

  constructor(private http: HttpClient, private apiUrl: ApiUrl) {}

  list(): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/list', {}).pipe(
      tap((results) => {}),
      map((results: any) =>
        Object.keys(results.data).reduce((acc: any, cur: any) => {
          acc[cur] = results.data[cur].map((s) => new Constant(s));
          return acc;
        }, {})
      ),
      catchError((error) => {
        throw error;
      })
    );
  }
  get(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url), {}).pipe(
      tap((results) => {}),
      map((results: any) =>
        Object.keys(results.data).reduce((acc: any, cur: any) => {
          acc[cur] = results.data[cur].map((s) => new Constant(s));
          return acc;
        }, {})
      ),
      catchError((error) => {
        throw error;
      })
    );
  }
}
