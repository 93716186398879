import { put, fork, takeLatest, select } from 'redux-saga/effects';
import * as _ from 'lodash';
import { FETCH_LOGIN_DETAIL_SUCCEEDED } from './auth/login/login.actions';
import { AppMenunItems } from '../template/shared/app-menu-items/app-menu-items';
import { Router } from '@angular/router';
import { AppInjector } from '../app-injector';
import { redirectToHome } from '../app.const';
import { FETCH_CONSTANTS_REQUESTED, FETCH_CONSTANTS_SUCCEEDED } from './main.action';
import { ApiService } from '../api/api.service';
import { API_CALL_ERROR } from '../store/action';

function* initAppMenu(action) {
  const MenuItems = AppMenunItems;
  const router = AppInjector.get(Router);

  if (router.routerState.snapshot.url === '' || router.routerState.snapshot.url === '/') {
    redirectToHome(action.data, router);
  }

  yield put({
    type: 'INIT_APP_MENU',
    data: MenuItems,
    user: action.data
  });
}

function* watchFetchLoginDetailSuccessed() {
  yield takeLatest(FETCH_LOGIN_DETAIL_SUCCEEDED, initAppMenu);
}

function* swapAppMenu(action) {
  yield put({
    type: 'INIT_APP_MENU',
    data: yield select((state) => (state as any).RootReducer.MenuItems),
    user: yield select((state) => (state as any).Auth.login.profile),
    levelMenu: action.levelMenu,
    isShowBtnSettings: yield select((state) => !(state as any).RootReducer.isShowBtnSettings)
  });
}

function* watchSwapAppMenu() {
  yield takeLatest('SHOW_MENU_BY_LEVEL_REQUESTED', swapAppMenu);
}

export function* fetchAllConstants() {
  const api = AppInjector.get(ApiService);
  try {
    const result = yield api.constant.get().toPromise();
    return result;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchConstants(action) {
  const api = AppInjector.get(ApiService);
  try {
    const result = yield api.constant.get().toPromise();
    yield put({ type: FETCH_CONSTANTS_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchConstantsRequested() {
  yield takeLatest(FETCH_CONSTANTS_REQUESTED, fetchConstants);
}

export default [watchFetchLoginDetailSuccessed, watchSwapAppMenu, watchFetchConstantsRequested];
