import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { list } from './list/list.reducer';
import { GET_ALL_ADMIN_FULFILMENT_SUCCESSED } from './fulfilment.action';
const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case GET_ALL_ADMIN_FULFILMENT_SUCCESSED:
      return _.assign({}, state, {
        fetched: true,
        items: [...action.data]
      });
    default:
      return state;
  }
};
export const Fulfilment = combineReducers({
  list,
  all
});
