import * as _ from 'lodash';
import {
    RENDER_ADD_PRODUCT_TAGS_FORM_REQUESTED,
    UPDATE_ADD_PRODUCT_TAGS_INPUT_OPTIONS
} from './add-tag.actions';

export const addTags = (state: any = { created: false }, action) => {
  switch (action.type) {
    case RENDER_ADD_PRODUCT_TAGS_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case UPDATE_ADD_PRODUCT_TAGS_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, input => {
          _.map(action.data, (value, key) => {
            if (input.key === key) {
              input.options = value;
            }
            return input;
          });
          return input;
        })
      });
    default:
      return state;
  }
};
