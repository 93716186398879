export const FETCH_MATERIAL_ATTRIBUTE_REQUESTED = 'FETCH_MATERIAL_ATTRIBUTE_REQUESTED';
export const FETCH_MATERIAL_ATTRIBUTE_SUCCESSED = 'FETCH_MATERIAL_ATTRIBUTE_SUCCESSED';
export const CREATE_MATERIAL_REQUESTED = 'CREATE_MATERIAL_REQUESTED';
export const CREATE_MATERIAL_SUCCESSED = 'CREATE_MATERIAL_SUCCESSED';
export const FETCH_MATERIAL_DICTIONARY_REQUESTED = 'FETCH_MATERIAL_DICTIONARY_REQUESTED';
export const FETCH_MATERIAL_DICTIONARIES_SUCCESSED = 'FETCH_MATERIAL_DICTIONARIES_SUCCESSED';
export const RESET_MATERIAL_DICTIONARY = 'RESET_MATERIAL_DICTIONARY';
export const CREATE_MATERIAL_DICTIONARY_SUCCESSED = 'CREATE_MATERIAL_DICTIONARY_SUCCESSED';
export const DELETE_MATERIAL_ATTRIBUTE_REQUESTED = 'DELETE_MATERIAL_ATTRIBUTE_REQUESTED';
export const DELETE_MATERIAL_ATTRIBUTE_SUCCESSED = 'DELETE_MATERIAL_ATTRIBUTE_SUCCESSED';
export const EDIT_MATERIAL_REQUESTED = 'EDIT_MATERIAL_REQUESTED';
export const EDIT_MATERIAL_SUCCESSED = 'EDIT_MATERIAL_SUCCESSED';
export const EDIT_MATERIAL_DICTIONARY_REQUESTED = 'EDIT_MATERIAL_DICTIONARY_REQUESTED';
export const EDIT_MATERIAL_DICTIONARY_SUCCESSED = 'EDIT_MATERIAL_DICTIONARY_SUCCESSED';
