export const ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_REQUESTED = 'ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_REQUESTED';
export const ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_SUCCESSED = 'ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_SUCCESSED';
export const DELETE_MATERIAL_ATTRIBUTE_REQUESTED = 'DELETE_MATERIAL_ATTRIBUTE_REQUESTED';
export const DELETE_MATERIAL_ATTRIBUTE_SUCCESSED = 'DELETE_MATERIAL_ATTRIBUTE_SUCCESSED';
export const FETCH_MATERIAL_DICTIONARY_REQUESTED = 'FETCH_MATERIAL_DICTIONARY_REQUESTED';
export const FETCH_MATERIAL_DICTIONARY_SUCCESSED = 'FETCH_MATERIAL_DICTIONARY_SUCCESSED';
export const CREATE_MATERIAL_DICTIONARY_SUCCESSED = 'CREATE_MATERIAL_DICTIONARY_SUCCESSED';
export const RESET_MATERIAL_DICTIONARY = 'RESET_MATERIAL_DICTIONARY';
export const EDIT_MATERIAL_DICTIONARY_REQUESTED = 'EDIT_MATERIAL_DICTIONARY_REQUESTED';
export const EDIT_MATERIAL_DICTIONARY_SUCCEEDED = 'EDIT_MATERIAL_DICTIONARY_SUCCEEDED';
export const ADMIN_ATTRIBUTES_MATERIAL_FILTER_SELECTED = 'ADMIN_ATTRIBUTES_MATERIAL_FILTER_SELECTED';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_SUCCESS';
export const ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_SUCCESS';
export const ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_REQUESTED = 'ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_REQUESTED';
export const ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_SUCCESS = 'ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_SUCCESS';
export const ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_REQUESTED = 'ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_REQUESTED';
export const ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_SUCCESS = 'ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_SUCCESS';
export const ADMIN_ATTRIBUTES_MATERIAL_SYNCHRONISE_ATTRIBUTES_REQUEST = 'ADMIN_ATTRIBUTES_MATERIAL_SYNCHRONISE_ATTRIBUTES_REQUEST';
export const TOGGLE_MATERIAL_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_MATERIAL_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_MATERIAL_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_MATERIAL_DICTIONARY_VALIDATION_SUCCEEDED';
