import { STYLETRIBUTE_SINGAPORE_LOCATION, WAANT_FR_LOCATION } from './../../../../models/Location';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import {
  CREATE_PRODUCT_REQUESTED,
  CREATE_PRODUCT_SUCCEEDED,
  RENDER_CREATE_PRODUCT_FORM_REQUESTED,
  SAVE_ALL_SIZES_OPTIONS_SUCCEEDED,
  UPDATE_CREATE_PRODUCT_INPUT_OPTIONS
} from './create.actions';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { Router } from '@angular/router';
import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { fetchAllcurrency } from '../../currency/currency.saga';
import { fetchAllsize, fetchAllsizeUnit } from '../../attribute/size/size.saga';
import { fetchAlldesigner } from '../../attribute/designer/designer.saga';
import { fetchAllcolor } from '../../attribute/color/color.saga';
import { fetchItemType, fetchMainCategory, fetchSubCategory } from '../../category/category.saga';
import { listRouter } from '../product.const';
import { fetchAllmaterial } from '../../attribute/material/material.saga';
import { fetchAllConditions } from '../../attribute/condition/condition.saga';
import { fetchAllLocations } from '../../location/location.saga';
import { ActivatedRoute } from '@angular/router';

function* fetchAllDataProduct(action) {
  const [currencies, sizes, designers, colors, categories, materials, conditions, locations, size_units] = yield all([
    call(fetchAllcurrency),
    call(fetchAllsize, {
      constraints: JSON.stringify({
        is_old_size: false
      })
    }),
    call(fetchAlldesigner),
    call(fetchAllcolor),
    call(fetchMainCategory),
    call(fetchAllmaterial),
    call(fetchAllConditions),
    call(fetchAllLocations),
    call(fetchAllsizeUnit)
  ]);
  const optionsData = {
    designer_id: _.map(designers, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    currency_id: _.map(currencies, (item, key) => _.assign(item, { value: item.id, label: item.code })),
    size_id: _.map(sizes, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    color_id: _.map(colors, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    main_category: _.map(categories.data.main_categories, (item, key) => _.assign({}, { value: item, label: item })),
    material_id: _.map(materials, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    condition_id: _.map(conditions, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    warehouse: _.map(locations, (item, key) => _.assign(item, { value: item.slug, label: item.name })),
    size_type: _.map(size_units, (item, key) => _.assign(item, { value: item.unit, label: item.unit }))
  };
  yield put({ type: SAVE_ALL_SIZES_OPTIONS_SUCCEEDED, data: optionsData.size_id, locations: optionsData.warehouse });
  yield put({ type: UPDATE_CREATE_PRODUCT_INPUT_OPTIONS, data: optionsData });

  const retailer_email_input = _.find(action.data.inputs, (i) => i.key === 'retailer_email');
  if (_.includes(['balenciaga@reflaunt.com'], retailer_email_input.value) || checkRetailerSlug() === 'VIP') {
    const warehouse_input = _.find(action.data.inputs, (i) => i.key === 'warehouse');
    warehouse_input.value = _.find(warehouse_input.options, (o) => o.slug === WAANT_FR_LOCATION);
  }
  if (_.includes(['zalora-vip@reflaunt.com'], retailer_email_input.value)) {
    const warehouse_input = _.find(action.data.inputs, (i) => i.key === 'warehouse');
    warehouse_input.value = _.find(warehouse_input.options, (o) => o.slug === STYLETRIBUTE_SINGAPORE_LOCATION);
  }
}

function* watchCreateProductFormRequested() {
  yield takeEvery(RENDER_CREATE_PRODUCT_FORM_REQUESTED, fetchAllDataProduct);
}

function* createProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.product.create(action.data).toPromise();
    yield put({ type: CREATE_PRODUCT_SUCCEEDED, data: result });
    if (action.referer) {
      // to get url only without query string
      const url = action.referer.split('?')[0];
      // const url = `/admin/product/edit/${action.data.id}`;
      router.navigate(url.split('/'));
    } else {
      const redirect = listRouter();
      router.navigate(redirect);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateProductRequested() {
  yield takeEvery(CREATE_PRODUCT_REQUESTED, createProduct);
}

function checkRetailerSlug() {
  let extra_params = AppInjector.get(ActivatedRoute).snapshot.queryParams.extra_params;
  if (extra_params) {
    extra_params = JSON.parse(extra_params);
    const retailer_slug = extra_params.retailer_slug;
    return retailer_slug;
  }
  return '';
}

export default [watchCreateProductRequested, watchCreateProductFormRequested];
