import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Dictionary from '../../../models/Dictionary';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { map, tap, catchError } from 'rxjs/operators';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';

@Injectable()
export class DictionaryService extends ServiceProvider {
  public url = '/api/v1/admin/dictionaries';
  public model = Dictionary;

  put(params): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/matching/${params.id}`, params).pipe(
      map(result =>
        _.assign(
          {},
          {
            items: (result as any).data.map(item => new this.model(item)),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          })),
      catchError(error => {
        this.preloader.hide();
        throw error;
      }));
  }

  updateOrCreate(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/update-or-create`, data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  validate(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/validate`, {}).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  invalidate(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/invalidate`, {}).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  actualize(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/actualize`, data).pipe(
      tap(() => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  synchronise(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/synchronise`, data).pipe(
      tap(() => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
