import { ServiceProvider } from '../../service.provider';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { tap, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import Integration from '../../../models/Integration';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class IntegrationService extends ServiceProvider {
  public url = '/api/v1/admin/integrations/retailer';
  public model = Integration;

  sync(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/marketplace/sync`, data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllByRetailer(retailerId, params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/${retailerId}`), JSON.stringify(params)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  configuration(retailerId, params = {}) {
    return AppInjector.get(HttpClient)
      .put(this.apiUrl.getApiUrl(`${this.url}/${retailerId}/configuration`), params)
      .pipe(
        tap((result) => {}),
        map((result) => _.map((result as any).data, (item) => new Integration(item)))
      );
  }

  createDefault(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/default`).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
