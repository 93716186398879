import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { put } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';


export function* fetchAllmodel() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.model.get({ per_page: 10000 }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}


export default [

];
