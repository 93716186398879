import { PreloaderService } from './../../common/services/preloader/preloader.service';
import { AppInjector } from './../../app-injector';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Status from '../../models/Status';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class StatusService extends ServiceProvider {
  public url = '/api/v1/admin/statuses';
  public url1 = '/api/v1/admin/status';
  public model = Status;

  getByName(name): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/show/' + name).pipe(
      map((result) => new Status((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }

  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
