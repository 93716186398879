import { put, takeEvery } from 'redux-saga/effects';
import { DEMO_FETCH_DATA_ADMIN_MARKETPLACE_DETAIL_REQUESTED, DEMO_FETCH_DATA_ADMIN_MARKETPLACE_DETAIL_SUCCESSED } from './detail.actions';
function* watchFetchDataRequest() {
  yield takeEvery(DEMO_FETCH_DATA_ADMIN_MARKETPLACE_DETAIL_REQUESTED, function* (action: any) {
    try {
      yield put({ type: DEMO_FETCH_DATA_ADMIN_MARKETPLACE_DETAIL_SUCCESSED});
    } catch (e) {
      yield put({ type: 'API_CALL_ERROR', error: e });
    }
  });
}

export default [];
