import { DataObjectMarketplaces, otherReferences } from './../../../../models/DatasetObject';
import { CREATE_DATASET_REQUESTED, CREATE_DATASET_SUCCEEDED, FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED, FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED } from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { default_item, listRouter } from '../dataset.const';
import { includes, assign, isNil, head } from 'lodash';
import { AGEGROUPS, GENDERS } from '../../../../models/DatasetObject';
import { USER_ROLES } from '../../../../app.const';
import { DataOperators } from '../../../../models/DatasetOperator';

function* createDataset(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.dataset.create(action.data).toPromise();
    yield put({ type: CREATE_DATASET_SUCCEEDED, data: result });
    if (action.meta && action.meta.retailerId && action.meta.marketplaceId) {
      router.navigate(['/', 'admin', 'integration', action.meta.retailerId, 'marketplace-management', 'edit', action.meta.marketplaceId]);
    } else {
      router.navigate(listRouter());
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* watchCreateDatasetRequest() {
  yield takeEvery(CREATE_DATASET_REQUESTED, createDataset);
}

function* watchFillContantDropdownConditionRequest() {
  yield takeEvery(FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result;
      const { dataset_object, dataset_operator } = action.condition;
      switch (true) {
        case includes([DataObjectMarketplaces.is_not_published, DataObjectMarketplaces.is_published], dataset_object.label):
          result = yield api.admin.user.getMarketplaces().toPromise();
          result = result.map((i) => assign(i, { label: i.marketplace.company }));
          result.unshift(default_item);
          break;
        case includes([DataObjectMarketplaces.product_item_location], dataset_object.label):
          result = yield api.admin.location.get({ per_page: 10000 }).toPromise();
          result = result.items;
          result = result.map((i) => assign(i, { label: i.name }));
          result.unshift(default_item);
          break;
        case !isNil(dataset_object.sql_reference):
          let references = dataset_object.sql_reference.split('.');
          let params = {};
          if (!isNil(head(references)) && references.length > 0) {
            if (head(references) === otherReferences.agegroup) {
              result = AGEGROUPS;
            } else if (head(references) === otherReferences.gender) {
              result = GENDERS;
            } else if (head(references) === otherReferences.retailer) {
              result = yield AppInjector.get(ApiService)
                .user.list({ constraints: JSON.stringify({ role_ids: USER_ROLES.RETAILER }) })
                .toPromise();
              result = result.map((i) => assign(i, { label: i.email }));
            } else {
              result = yield api.admin.magic.getListBaseOnReference(head(references), params).toPromise();
              result = result.map((i) => assign(i, { label: i.name }));
            }
          }
          if (dataset_operator && [DataOperators.is_equal_to, DataOperators.is_not_equal_to].indexOf(dataset_operator.label) > -1) {
            result.unshift(default_item);
          }
          break;
        default:
          break;
      }
      yield put({ type: FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED, constants: result, condition: action.condition });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchCreateDatasetRequest, watchFillContantDropdownConditionRequest];
