import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { ServiceProvider } from '../../service.provider';
import TransactionHistory from '../../../models/TransactionHistory';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';

@Injectable()
export class TransactionHistoryService extends ServiceProvider {
  public url = '/api/v1/admin/transaction-history';
  public model = TransactionHistory;

  getBySeller(sellerId, params = {}): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/' + sellerId, { params: queryParams }).pipe(
      tap(result => {}),
      map(result =>
        _.assign(
          {},
          {
            items: (result as any).data.map(item => new this.model(item))
            // pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError(error => {
        throw error;
      })
    );
  }
}
