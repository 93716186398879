import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { addToGroupRouter, editRouter, listRouter } from '../dataset.const';
import { BaseComponent } from '../../../base.component';
import { GET_LIST_GROUP_DATASET_REQUEST, DETACH_DATASET_FROM_GROUP_REQUEST } from './list-group.actions';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.scss']
})
export class ListGroupComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.Dataset.listGroup';
  public datasetId;
  public listRouter = listRouter;
  public editRouter = editRouter;
  public addToGroupRouter = addToGroupRouter;

  constructor(private activedRoute: ActivatedRoute, private router: Router) {
    super();
    this.datasetId = Number(this.activedRoute.snapshot.params.id);
    const { dispatchGetListGroupDatasetRequest } = this as any;
    this.navigationSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      dispatchGetListGroupDatasetRequest(this.datasetId);
    });
  }

  ngOnInit() {
    this.init();
  }

  delete(id) {
    const { dispatchDetachDatasetFromGroupRequest } = this as any;
    const payload = {
      children_ids: [this.datasetId]
    };
    dispatchDetachDatasetFromGroupRequest(id, payload, this.datasetId);
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Dataset.listGroup
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch,
      dispatchGetListGroupDatasetRequest: (datasetId) => {
        dispatch({ type: GET_LIST_GROUP_DATASET_REQUEST, data: datasetId });
      },
      dispatchDetachDatasetFromGroupRequest: (groupId, payload, datasetId) => {
        dispatch({ type: DETACH_DATASET_FROM_GROUP_REQUEST, data: { groupId, payload, datasetId } });
      }
    };
  }
}
