import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GET_PERCENTAGE_RETAILER_REQUESTED } from './percentage.actions';

@Component({
  selector: 'app-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss']
})
export class PercentageComponent extends BaseComponent implements OnInit {
  public reducer = 'Admin.AdminRetailer.percentages';
  public supParams = ['sort', 'constraints', 'page', 'per_page', 'search'];
  constructor(private router: Router, private route: ActivatedRoute) {
    super();
    this.route = route;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let data: any = this.queryParser.parse(this.supParams);
        this.store.dispatch({
          type: GET_PERCENTAGE_RETAILER_REQUESTED,
          retailerId: this.route.snapshot.params.id,
          data: data
        });
      }
    });
   }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return { payload: state.Admin.AdminRetailer.percentages };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }

}
