import { PipesModule } from '../../common/pipes/pipes.module';
import { MeasurementUnitRoutingModule } from './measurement-unit-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../common/directives/directives.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../../template/shared/shared.module';
import { AngularReactiveFormModule } from '@reflaunt/reactive-form';

@NgModule({
  imports: [CommonModule, MeasurementUnitRoutingModule, FormsModule, ReactiveFormsModule, PipesModule, DirectivesModule, SweetAlert2Module, SharedModule, AngularReactiveFormModule],
  declarations: [CreateComponent, EditComponent, ListComponent]
})
export class MeasurementUnitModule {}
