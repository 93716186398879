import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Designer from '../../../models/Designer';

@Injectable()
export class DesignerService extends ServiceProvider {
  public url = '/api/v1/admin/designers';
  public model = Designer;
}
