import { DataOperators } from './../../../../models/DatasetOperator';
import { DataObjectMarketplaces, DataTypes } from './../../../../models/DatasetObject';
import { BaseComponent } from './../../../base.component';
import * as _ from 'lodash';
import {
  EDIT_DATASET_REQUESTED,
  GET_DATASET_REQUESTED,
  DATASET_EDIT_COMPONENT,
  EDIT_CONDITION_MODIFIED,
  EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED,
  RESET_STATE_EDIT_DATASET,
  REFRESH_DATASET_REQUEST,
  DELETE_DATASET_REQUESTED
} from './edit.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterContentChecked, AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '../../../../store/store.module';
import { GET_ALL_DATASET_CONDITIONS_REQUESTED, GET_ALL_DATASET_OBJECTS_REQUESTED, GET_ALL_DATASET_OPERATORS_REQUESTED } from '../dataset.action';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { INPUT_TYPES, selectDatasetOperator, frequencies } from '../dataset.const';
import { compact, get, isEmpty } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  @ViewChild('datasetConditionForm', { static: false }) datasetConditionForm: NgForm;
  public id = this.activatedRoute.snapshot.params.id;
  value: any;
  public formChangesSubscription: any;
  public subscriptionCondidtions: any;
  public store;
  public reducer = 'Retailer.Dataset.edit';
  public default_operator_list = [DataOperators.in_list, DataOperators.not_in_list];
  public default_operator_containt = [DataOperators.contains, DataOperators.does_not_contain];
  public numberPattern = '^([1-9][0-9]+|[1-9])(\\.[0-9]+)?$';
  public isPushAllProduct = true;
  public retailerId = null;
  public marketplaceId = null;
  public INPUT_TYPES = INPUT_TYPES;
  public time_operators = [
    { value: '+', label: '+ (Plus)' },
    { value: '-', label: '- (Minus)' }
  ];
  public time_interval_types = [
    { value: 'year', label: 'Years' },
    { value: 'month', label: 'Months' },
    { value: 'day', label: 'Days' },
    { value: 'hour', label: 'Hours' },
    { value: 'minute', label: 'Minutes' },
    { value: 'second', label: 'Seconds' }
  ];
  private is_skip_cache = true;
  public frequencies = frequencies;
  public includes = 'children_datasets';
  public isDailyOrWeekly;
  public selectedDatesRequired = false;
  constructor(private activatedRoute: ActivatedRoute, store: Store, private notification: NotificationService, private cdref: ChangeDetectorRef, private router: Router) {
    super();
    this.activatedRoute = activatedRoute;
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.init();
    this.store.dispatch({
      type: GET_DATASET_REQUESTED,
      data: { datasetId: this.id, params: { is_skip_cache: this.is_skip_cache } }
    });

    this.store.dispatch({
      type: GET_ALL_DATASET_CONDITIONS_REQUESTED,
      component: DATASET_EDIT_COMPONENT,
      data: {
        dataset_ids: [this.id]
      }
    });

    this.store.dispatch({
      type: GET_ALL_DATASET_OBJECTS_REQUESTED,
      component: DATASET_EDIT_COMPONENT
    });

    this.store.dispatch({
      type: GET_ALL_DATASET_OPERATORS_REQUESTED,
      component: DATASET_EDIT_COMPONENT
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (!_.isNil(this.datasetConditionForm)) {
        this.watchFormValueChange();
        clearInterval(interval);
      }
    }, 100);
  }

  selectDatasetObject = (dataset_object, condition_index) => {
    this.store.dispatch({
      type: EDIT_CONDITION_MODIFIED,
      data: {
        dataset_object,
        condition_index
      }
    });
  };

  datasetOperatorChange = (dataset_operator, condition_index) => {
    let current_condition = this.payload.dataset_conditions.items[condition_index];
    selectDatasetOperator(dataset_operator, current_condition);
  };

  isDisabledBaseOnCondition(operator, condition_index) {
    let disabled = false;
    let current_condition = this.payload.dataset_conditions.items[condition_index];
    if (_.isNil(current_condition) || _.isNil(current_condition.dataset_object)) {
      return disabled;
    }
    const { data_type, is_contains_enabled, is_multiple_choice, label } = current_condition.dataset_object;
    if (_.includes([DataObjectMarketplaces.is_not_published, DataObjectMarketplaces.is_published, DataObjectMarketplaces.product_item_location], label)) {
      return operator.label !== DataOperators.is_equal_to;
    }
    switch (true) {
      case data_type === DataTypes.varchar:
        let default_disable = [DataOperators.is_greater_than, DataOperators.is_less_than];
        if (!is_multiple_choice) {
          default_disable = [...default_disable, ...this.default_operator_list];
        }
        if (!is_contains_enabled) {
          default_disable = [...default_disable, ...this.default_operator_containt];
        }
        disabled = default_disable.indexOf(operator.label) > -1;
        break;
      case [DataTypes.double, DataTypes.timestamp].indexOf(data_type) > -1:
        disabled = [...this.default_operator_containt, ...this.default_operator_list].indexOf(operator.label) > -1;
        break;
      case is_contains_enabled && is_multiple_choice:
        return false;
      case !is_contains_enabled && is_multiple_choice:
        disabled = [...this.default_operator_containt].indexOf(operator.label) > -1;
        break;
      case is_contains_enabled && !is_multiple_choice:
        disabled = this.default_operator_list.indexOf(operator.label) > -1;
        break;
      case !is_contains_enabled && !is_multiple_choice:
        disabled = [...this.default_operator_containt, ...this.default_operator_list].indexOf(operator.label) > -1;
        break;
    }
    return disabled;
  }

  watchFormValueChange = () => {
    this.formChangesSubscription = this.datasetConditionForm.valueChanges.pipe(debounceTime(300)).subscribe((selectedValue) => {
      const conditions = this.payload.dataset_conditions.items;
      const current_condition_change = _.find(conditions, (i) => i.changed);
      if (conditions.length > 0 && !_.isNil(current_condition_change)) {
        if ([this.INPUT_TYPES.search, this.INPUT_TYPES.mutiple_search].indexOf(current_condition_change.input_type) > -1) {
          this.store.dispatch({
            type: EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED,
            condition: current_condition_change
          });
        }
      }
    });
  };

  addCondition() {
    const last_index = _.findLastIndex(this.payload.dataset_conditions.items);
    let new_condition = {
      dataset_object: _.head(this.payload.dataset_objects.items),
      dataset_operator: _.head(this.payload.dataset_operators.items),
      constants: [],
      index: last_index + 1
    };
    this.payload.dataset_conditions.items.push(new_condition);
    this.store.dispatch({
      type: EDIT_CONDITION_MODIFIED,
      data: {
        dataset_object: new_condition.dataset_object,
        condition_index: last_index + 1
      }
    });
  }

  dynamicDateChange(value, current_condition) {
    current_condition.value = value
      ? {
          value: '',
          time_interval_type: _.head(this.time_interval_types).value,
          time_interval_operator: _.head(this.time_operators).value
        }
      : new Date();
  }

  removeCondition(condition) {
    _.remove(this.store.getState().Retailer.Dataset.edit.dataset_conditions.items, condition);
  }

  onSubmit(form) {
    if (form.valid) {
      let data = this.payload.item;
      if (!this.isDisplayAutoPublishSection()) {
        data = Object.assign(data, {
          is_auto_publish: false,
          publish_frequency: null,
          first_run_at: null,
          auto_publish_chunk_limit: null,
          daily_or_weekly: null,
          selected_dates:null,
          time_slot:null,
          limit_push_item_per_day:null,
          limit_push_item_per_week:null
        });
      }
      data.conditions = _.chain(_.cloneDeep(this.payload.dataset_conditions.items))
        .map((condition) => {
          condition.dataset_operator_id = condition.dataset_operator.getId();
          condition.dataset_object_id = condition.dataset_object.getId();
          condition.value = condition.dataset_object && condition.dataset_object.data_type === 'double' ? Number(condition.value) : condition.value;
          return _.pick(condition, ['dataset_object_id', 'dataset_operator_id', 'value']);
        })
        .value();
      data.user_id = this.store.getState().Auth.login.profile.getId();
      data.daily_or_weekly = form.value.daily_or_weekly;
      data.time_slot = form.value.time_slot;
      if (this.payload.old_selected_dates.length) {
        data.selected_dates = _.sortBy(this.payload.old_selected_dates).toString();
      }
      if (form.value.is_auto_publish && form.value.daily_or_weekly === 'weekly' && this.payload.old_selected_dates.length < 1) {
        this.selectedDatesRequired = true;
        return false;
      }
      if (data.conditions.length === 0) {
        this.notification.show('warning', 'Please select at least 1 condition', 5000);
        return false;
      } else {
        this.store.dispatch({
          type: EDIT_DATASET_REQUESTED,
          data: _.assign(data, { id: this.payload.item.id }, { refresh: false })
        });
      }
    }
  }

  getItemId() {
    return this.activatedRoute.snapshot.params.id;
  }

  delete() {
    const { is_skip_cache, includes } = this as any;
    this.store.dispatch({ type: DELETE_DATASET_REQUESTED, data: this.id, queryParams: { is_skip_cache, includes } });
    this.router.navigateByUrl('/retailer/dataset');
    this.notification.show('success', 'Delete dataset success', 5000);
  }

  dynamicType(condition) {
    let input_type = 'text';
    if (_.isNil(condition.dataset_object)) {
      return input_type;
    }
    switch (true) {
      case condition.dataset_object.data_type === DataTypes.double:
        input_type = 'number';
        break;
    }
    return input_type;
  }

  onRefresh(form) {
    if (form.valid) {
      let data = this.payload.item;
      if (!this.isDisplayAutoPublishSection()) {
        data = Object.assign(data, {
          is_auto_publish: false,
          publish_frequency: null,
          first_run_at: null,
          auto_publish_chunk_limit: null,
          daily_or_weekly: null,
          selected_dates:null,
          time_slot:null,
          limit_push_item_per_day:null,
          limit_push_item_per_week:null
        });
      }
      data.conditions = _.chain(_.cloneDeep(this.payload.dataset_conditions.items))
        .map((condition) => {
          condition.dataset_operator_id = condition.dataset_operator.getId();
          condition.dataset_object_id = condition.dataset_object.getId();
          condition.value = condition.dataset_object && condition.dataset_object.data_type === 'double' ? Number(condition.value) : condition.value;
          return _.pick(condition, ['dataset_object_id', 'dataset_operator_id', 'value']);
        })
        .value();
      data.user_id = this.store.getState().Auth.login.profile.getId();
      data.daily_or_weekly = form.value.daily_or_weekly;
      data.time_slot = form.value.time_slot;
      if (this.payload.old_selected_dates.length) {
        data.selected_dates = _.sortBy(this.payload.old_selected_dates).toString();
      }
      if (form.value.is_auto_publish && form.value.daily_or_weekly === 'weekly' && this.payload.old_selected_dates.length < 1) {
        this.selectedDatesRequired = true;
        return false;
      }
      if (data.conditions.length === 0) {
        this.notification.show('warning', 'Please select at least 1 condition', 5000);
        return false;
      } else {
        this.store.dispatch({
          type: EDIT_DATASET_REQUESTED,
          data: _.assign(data, { id: this.payload.item.id }, { refresh: true })
        });
      }
    }
  }

  isValidFrequency(item) {
    const data = this.frequencies.map((frequency) => frequency.value);
    return data.includes(item.publish_frequency);
  }

  isDisplayAutoPublishSection() {
    let result = false;
    if (!this.payload.dataset_conditions.items || !this.payload.dataset_conditions.items.length) {
      return result;
    }

    const selectedDatasetObjects = this.payload.dataset_conditions.items.map((condition) => {
      let objectLabel = null;
      if (condition.dataset_object) {
        objectLabel = get(condition.dataset_object, 'label');
      }
      return objectLabel;
    });
    if (selectedDatasetObjects.includes('Is not published on marketplace')) {
      result = true;
    }
    return result;
  }

  onChangeCk(event) {
    if (event.checked) {
      this.payload.old_selected_dates.push(event.value);
    } else {
      this.payload.old_selected_dates = this.payload.old_selected_dates.filter((s) => {
        return s !== event.value;
      });
    }
    this.payload.old_selected_dates = this.payload.old_selected_dates.filter((s) => {
      return !isEmpty(s);
    });
    if (this.payload.old_selected_dates.length) {
      this.selectedDatesRequired = false;
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Dataset.edit
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch
    };
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (!_.isNil(this.formChangesSubscription)) {
      this.formChangesSubscription.unsubscribe();
    }
    if (!_.isNil(this.subscriptionCondidtions)) {
      this.subscriptionCondidtions.unsubscribe();
    }
    this.store.dispatch({
      type: RESET_STATE_EDIT_DATASET
    });
  }
}
