import { GET_PRODUCT_CLAIM_CERTIFICATE_REQUESTED, GET_PRODUCT_CLAIM_CERTIFICATE_SUCCEEDED, CLAIM_CERTIFICATE_REQUESTED, CLAIM_CERTIFICATE_SUCCEEDED } from './certificate.actions';
import { takeEvery, put } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { NotificationService } from '../../../common/services/notification/notification.service';


function* watchFetchClaimCertificateRequested() {
  yield takeEvery(GET_PRODUCT_CLAIM_CERTIFICATE_REQUESTED, function*(action: any) {
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.product.getLandingPageProduct(action.data)
        .toPromise();
      yield put({ type: GET_PRODUCT_CLAIM_CERTIFICATE_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchClaimCertificateRequested() {
  yield takeEvery(CLAIM_CERTIFICATE_REQUESTED, function*(action: any) {
    try {
      const notify = AppInjector.get(NotificationService);
      yield AppInjector.get(ApiService)
        .blockchain.claimCertificate(action.data)
        .toPromise();
      yield put({ type: CLAIM_CERTIFICATE_SUCCEEDED });
      notify.show('success', 'Claim Certificate success!', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchClaimCertificateRequested, watchClaimCertificateRequested];
