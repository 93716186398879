import Model from './Model';
import ShippingCost from './ShippingCost';
import * as _ from 'lodash';

class ItemsCost extends Model {
  constructor(options) {
    super();
    (this as any).shipping_cost = (d) => {
      return _.map(d.data, (i) => new ShippingCost(i));
    };
    this.bind(options);
  }
}

export default ItemsCost;
