import {
  ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_SUCCESSED,
  FETCH_DESIGNER_DICTIONARY_SUCCESSED,
  DELETE_DESIGNER_ATTRIBUTE_SUCCESSED,
  EDIT_DESIGNER_DICTIONARY_SUCCEEDED,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_DESIGNER_FILTER_SELECTED,
  TOGGLE_DESIGNER_DICTIONARY_VALIDATION_SUCCEEDED
} from './designer.action';
import * as _ from 'lodash';
import { ATTRIBUTE_COMPONENT, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { CONDITION_INPUTS } from './designer.const';
import { DICTIONARY_DESIGNER_TYPE } from '../../../../models/Dictionary';
import { ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED } from '../size/size.action';
import { ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED } from '../category/category.action';
import { ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_REQUESTED } from '../condition/condition.action';
import { ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_REQUESTED } from '../material/material.action';
import { ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_REQUESTED } from '../color/color.action';

export const Designer = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    },
    marketplace_designers: {
      fetched: false,
      items: []
    },
    conditionInputs: CONDITION_INPUTS,
    conditionInputValue: CONDITION_INPUTS[0]
  },
  action
) => {
  switch (action.type) {
    case ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, (item) => {
        item.is_show_edit_form = false;
        return item;
      });
      return { ...state, ...{ fetched: true, items: action.data, pagination: action.pagination } };

    case ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_SUCCESS:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            state.items.push(action.data);
            return _.assign({}, state, { create: { created: true, data: {} } });
        }
      }
      return state;

    case ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_SUCCESS:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            // tslint:disable-next-line:no-shadowed-variable
            const item = _.find(state.items, { id: action.data.id });
            if (!_.isNil(item)) {
              item.is_show_edit_form = false;
              item.name = action.data.name;
            }
            return { ...state, ...{ items: state.items } };
        }
      }
      return state;

    case DELETE_DESIGNER_ATTRIBUTE_SUCCESSED:
      return {
        ...state,
        ...{
          items: _.filter(state.items, (i) => i.id !== action.data),
          dictionary: { ...state.dictionary, ...{ items: _.filter(state.dictionary.items, (i) => i.attribute_id !== action.data) } }
        }
      };

    case FETCH_DESIGNER_DICTIONARY_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, (item) => {
        item.is_show_edit_dictionary_form = false;
        return item;
      });
      return _.assign({}, state, { dictionary: { fetched: true, items: action.data } });

    case EDIT_DESIGNER_DICTIONARY_SUCCEEDED:
      const item = _.find(state.dictionary.items, { id: action.data.id });
      if (!_.isNil(item)) {
        item.is_show_edit_dictionary_form = false;
        item.value = action.data.value;
      }
      return _.assign({}, state, { dictionary: { items: state.dictionary.items } });

    case ADMIN_ATTRIBUTES_DESIGNER_FILTER_SELECTED:
      return _.assign({}, state, {
        items: _.map(state.items, (designer) => {
          designer.selectedDictionary = designer.dictionaries
            ? designer.dictionaries.find((d) => d.marketplace_id === action.data.marketplace.selected.id && d.type === action.dictionary_type)
            : null;
          return designer;
        })
      });
    case TOGGLE_DESIGNER_DICTIONARY_VALIDATION_SUCCEEDED:
      return _.assign({}, state, {
        items: _.map(state.items, (cat) => {
          if (cat.selectedDictionary && cat.selectedDictionary.id === action.data.id) {
            return {
              ...cat,
              selectedDictionary: action.data
            };
          } else {
            return {
              ...cat
            };
          }
        })
      });

    case ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_SUCCESS:
      return _.assign({}, state, {
        marketplace_designers: {
          fetched: true,
          items: action.data
        },
        items: _.map(state.items, (designer) => {
          let mapped;
          if (designer.selectedDictionary) {
            mapped = action.data.find((d) => d.id === designer.selectedDictionary.source_id && d.name === designer.selectedDictionary.value);
          }
          designer.mappedAttribute = mapped ? mapped : null;
          return designer;
        })
      });

    case ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_SUCCESS:
      return _.assign({}, state, {
        items: _.map(state.items, (designer) => {
          if (designer.id === action.data.attribute_id) {
            designer.is_show_edit_dictionary_form = false;
            designer.dictionaries = [...designer.dictionaries, action.data];
            designer.selectedDictionary = designer.dictionaries.find((s) => s.id === action.data.id);
          }
          return designer;
        })
      });

    case ADMIN_ATTRIBUTES_SELECT_MARKETPLACE:
      if (action.direct) {
        return _.assign({}, state, { marketplace_designers: _.assign({}, state.marketplace_designers, { fetched: false }), conditionInputValue: CONDITION_INPUTS[0] });
      } else {
        return state;
      }

    case ADMIN_ATTRIBUTES_DESIGNER_SYNCHRONISE_ATTRIBUTES_REQUEST:
      return _.assign({}, state, { marketplace_designers: _.assign({}, state.marketplace_designers, { fetched: false }) });

    case ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED:
    case ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED:
    case ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_REQUESTED:
    case ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_REQUESTED:
    case ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_REQUESTED:
      return _.assign({}, state, { conditionInputValue: CONDITION_INPUTS[0] });

    default:
      return state;
  }
};
