import ItemsCost from './items-cost/items-cost.saga';
import Location from './location/location.saga';
import OrderItem from './order-item/order-item.saga';
import Fulfilment from './fulfilment/fulfilment.saga';
import Order from './order/order.saga';
import Notification from './notification/notification.saga';
import Collection from './collection/collection.saga';
import Attributes from './attributes/attributes.saga';
import AttributesRetailer from './attributes-retailer/attributes-retailer.saga';
import Marketplace from './marketplace/marketplace.saga';
import * as _ from 'lodash';
import user from './user/user.saga';
import acl from './acl/acl.saga';
import attribute from './attribute/attribute.saga';
import category from './category/category.saga';
import currency from './currency/currency.saga';
import tag from './tag/tag.saga';
import customer from './customer/customer.saga';
import product from './product/product.saga';
import provider from './provider/provider.saga';
import retailer from './retailer/retailer.saga';
import status from './status/status.saga';
import email from './email/email.saga';
import shipment from './shipment/shipment.saga';
import integration from './integration/integration.saga';
import configuration from './configuration/configuration.saga';
import languageSaga from './language/language.saga';
import negotiation from './negotiation/negotiation.saga';
import logistic from './logistic/logistic.saga';

export default [
  ...ItemsCost,
  ...Location,
  ...OrderItem,
  ...Fulfilment,
  ...Order,
  ...Notification,
  ...Collection,
  ...Attributes,
  ...AttributesRetailer,
  ...Marketplace,
  ...user,
  ...acl,
  ...attribute,
  ...category,
  ...currency,
  ...tag,
  ...customer,
  ...product,
  ...provider,
  ...retailer,
  ...status,
  ...email,
  ...shipment,
  ...integration,
  ...configuration,
  ...languageSaga,
  ...negotiation,
  ...logistic
];
