import { Injectable } from '@angular/core';
import Percentage from '../../models/Percentage';
import { Observable } from 'rxjs';
import { ServiceProvider } from '../service.provider';
import { tap, catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import LengthAwarePaginator from '../../models/LengthAwarePaginator';


@Injectable()
export class PercentageService extends ServiceProvider {

  public url = '/api/v1/percentages';
  public model = Percentage;

  active(id): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/' + id + '/active').pipe(
      tap(result => {
        // console.log(result);
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  getPercentagesOfRetailer(retailerId, params = {}): Observable<any> {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/retailer/' + retailerId, { params: queryParams }).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map(result =>
        _.assign(
          {},
          {
            items: (result as any).data.map(item => new this.model(item)),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError(error => {
        this.preloader.hide();
        throw error;
      })
    );
  }
}
