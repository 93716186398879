import * as _ from 'lodash';
import {
  UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED,
  RENDER_FILL_CONFIRM_ADDRESS_FORM_REQUESTED,
  SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_SUCCEEDED,
  UPDATE_INPUTS_ADDRESS_LANDING_PAGE_REQUESTED,
  UPDATE_ALPHA_2_CODE_VALUE_ADDRESS_LANDINGPAGE_REQUESTED
} from '../landing-page.actions';
import { support } from '@reflaunt/reactive-form';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
      }
      return state;
    default:
      return state;
  }
};

export const ConfirmAddress = (state: any = { fetched: false, isConfirmed: false }, action) => {
  switch (action.type) {
    case RENDER_FILL_CONFIRM_ADDRESS_FORM_REQUESTED:
      return _.assign({}, state, { addressInputs: action.data.addressInputs });
    case UPDATE_INPUTS_ADDRESS_LANDING_PAGE_REQUESTED:
      state.addressInputs = support.UpdateInputsValue(state.addressInputs, action.data);
      state.addressInputs = support.UpdateFormValue(state.addressInputs, {
        phonecode: {
          code: '44',
          value: '',
          alpha2Code: 'GB'
        },
        country_alpha2_code: 'GB',
        country: _.find(action.data.country, (item) => item.value === 'United Kingdom')
      });
      return _.assign({}, state, { addressInputs: state.addressInputs });
    case UPDATE_ALPHA_2_CODE_VALUE_ADDRESS_LANDINGPAGE_REQUESTED:
      const inputs = support.UpdateFormValue(
        state.addressInputs,
        _.assign({}, action.data, {
          country_alpha2_code: action.data.country ? action.data.country.alpha2Code : '',
          phonecode: {
            code: action.data.country ? action.data.country.callingCodes[0] : '',
            alpha2Code: action.data.country ? action.data.country.alpha2Code : '',
            value: ''
          }
        })
      );
      return _.assign({}, state, {
        addressInputs: inputs
      });
    case 'UPDATE_COUNTRY_VALUE_ADDRESS_LANDINGPAGE_REQUESTED':
      const ips = support.UpdateFormValue(
        state.addressInputs,
        _.assign({}, action.data, {
          country: _.find(_.find(state.addressInputs, i => i.key === 'country').options, (item) => item.alpha2Code === action.data.phonecode.alpha2Code)
        })
      );
      console.log(ips);
      return _.assign({}, state, {
        addressInputs: ips
      });
    case UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED:
      let addressInputs = _.map(state.addressInputs, (item) => Input(item, action));
      addressInputs = _.map(addressInputs, (i) => {
        if (action.entity_type === 'product') {
          if (i.key === 'country' && action.data.retailer.email.indexOf('cos') > -1) {
            i.options = i.options.filter((x) => ['GB', 'DE'].indexOf(x.alpha2Code) > -1);
          }
        } else {
          if (i.key === 'phonecode') {
            const phone = action.data.phone.split('-');
            const code = phone[0].replace('+', '');
            const value = phone[1];
            i.value = {
              code: code,
              value: value,
              alpha2Code: action.data.country_alpha2_code
            };
          }
          if (i.key === 'country') {
            i.value = _.find(i.options, (o) => o.value === i.value);
          }
          // remove state if country is Germany or UK
          if (i.key === 'state') {
            if (action.data.country_alpha2_code === 'DE' || action.data.country_alpha2_code === 'GB') {
              i.classes = i.classes.concat(['display-none']);
            } else {
              i.classes = i.classes.filter((x) => x !== 'display-none');
            }
          }
        }

        return i;
      });
      return _.assign({}, state, { addressInputs: addressInputs });
    case SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_SUCCEEDED:
      return _.assign({}, state, { isConfirmed: true });
    default:
      return state;
  }
};
