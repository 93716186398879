import { fork, put, select, takeLatest, call, delay } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import editSaga from './edit/edit.saga';
import createSaga from './create/create.saga';
import createGroupSaga from './create-group/create-group.saga';
import listGroupSaga from './list-group/list-group.saga';
import addToGroup from './add-to-group/add-to-group.saga';
import editGroup from './edit-group/edit-group.saga';
import { API_CALL_ERROR } from '../../../store/action';
import {
  FETCH_ALL_DATASETS_REQUESTED,
  FETCH_ALL_DATASETS_SUCCEEDED,
  GET_ALL_DATASET_CONDITIONS_REQUESTED,
  GET_ALL_DATASET_CONDITIONS_SUCCEEDED,
  GET_ALL_DATASET_OBJECTS_REQUESTED,
  GET_ALL_DATASET_OBJECTS_SUCCEEDED,
  GET_ALL_DATASET_OPERATORS_REQUESTED,
  GET_ALL_DATASET_OPERATORS_SUCCEEDED
} from './dataset.action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAlldataset() {
  const fetchStatus = yield select((state) => (state as any).Retailer.Dataset.all.fetched);
  // if (!fetchStatus) {
  try {
    let result = yield AppInjector.get(ApiService).dataset.list().toPromise();
    return result;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
  // } else {
  //   const data = yield select(state => (state as any).Retailer.Dataset.all.items);
  //   return data;
  // }
}

function* allSaga(action) {
  const data = yield call(fetchAlldataset);
  yield put({ type: FETCH_ALL_DATASETS_SUCCEEDED, component: action.component, data: data });
}

function* watchFetchAllDatasetsRequest() {
  yield takeLatest(FETCH_ALL_DATASETS_REQUESTED, allSaga);
}

function* fetchAllDatasetObjectsRequested(action) {
  const fetchStatus = yield select((state) => (state as any).dataset.all.fetched);
  if (!fetchStatus) {
    try {
      let result = yield AppInjector.get(ApiService).dataset.list().toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select((state) => (state as any).dataset.all.items);
    return data;
  }
}

function* getAllDatasetConditions(action) {
  try {
    let result = yield AppInjector.get(ApiService).datasetCondition.list(action.data).toPromise();

    yield put({ type: GET_ALL_DATASET_CONDITIONS_SUCCEEDED, component: action.component, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetAllDatasetConditionsRequested() {
  yield takeLatest(GET_ALL_DATASET_CONDITIONS_REQUESTED, getAllDatasetConditions);
}

function* getAllDatasetObjectsRequested(action) {
  try {
    let result = yield AppInjector.get(ApiService).datasetObject.list(action.data).toPromise();

    yield put({ type: GET_ALL_DATASET_OBJECTS_SUCCEEDED, component: action.component, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetAllDatasetObjectsRequested() {
  yield takeLatest(GET_ALL_DATASET_OBJECTS_REQUESTED, getAllDatasetObjectsRequested);
}

function* getAllDatasetOperatorsRequested(action) {
  try {
    let result = yield AppInjector.get(ApiService).datasetOperator.list(action.data).toPromise();
    yield delay(400);
    yield put({ type: GET_ALL_DATASET_OPERATORS_SUCCEEDED, component: action.component, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetAllDatasetOperatorsRequested() {
  yield takeLatest(GET_ALL_DATASET_OPERATORS_REQUESTED, getAllDatasetOperatorsRequested);
}

export default [
  ...listSaga,
  ...editSaga,
  ...createSaga,
  ...createGroupSaga,
  ...listGroupSaga,
  ...addToGroup,
  ...editGroup,
  watchFetchAllDatasetsRequest,
  watchGetAllDatasetConditionsRequested,
  watchGetAllDatasetObjectsRequested,
  watchGetAllDatasetOperatorsRequested
];
