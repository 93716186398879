import { Store } from '../../../../store/store.module';
import { RENDER_ADD_PRODUCT_TAGS_FORM_REQUESTED, ADD_PRODUCT_TAGS_REQUESTED, REMOVE_TAG_REQUESTED } from './add-tag.actions';
import { Component, OnInit } from '@angular/core';
import { InputBase, Select2 } from '@reflaunt/reactive-form';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import { listRouter } from '../product.const';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss']
})
export class AddTagComponent implements OnInit {
  public store;
  public count;
  public product_ids;
  public listRouter = listRouter();
  constructor(store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    let inputs: InputBase<any>[] = [
      new Select2({
        key: 'tags',
        label: 'Tags',
        classes: ['col-12', 'rf-form-group', 'input--select2'],
        group_classes: ['col-12'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      })
    ];
    this.store.dispatch({
      type: RENDER_ADD_PRODUCT_TAGS_FORM_REQUESTED,
      data: { inputs: inputs }
    });
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    this.product_ids = productSelected ? Object.keys(productSelected) : [];
    this.count = productSelected ? Object.keys(productSelected).length : 0;
  }

  onSubmit = form => {
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    const product_ids = Object.keys(productSelected);

    if (form.valid && (product_ids && product_ids.length > 0)) {
      this.store.dispatch({
        type: ADD_PRODUCT_TAGS_REQUESTED,
        data: { product_ids, tag_ids: form.value.tags }
      });
    } else {
      AppInjector.get(NotificationService).show('warning', 'Product cannot be empty', 3000);
    }
    // tslint:disable-next-line:semicolon
  };
  removeTag = form => {
    const productSelected = JSON.parse(localStorage.getItem('productSelected'));
    this.store.dispatch({ type: REMOVE_TAG_REQUESTED, data: { product_ids: _.map(productSelected, i => i.id), tag_ids: form.value.tags } });
  };
}
