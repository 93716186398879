import { PreloaderService } from './../../../../common/services/preloader/preloader.service';
import { ApiService } from './../../../../api/api.service';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';

import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { FETCH_PERCENTAGE_REQUESTED, FETCH_PERCENTAGE_SUCCEEDED, UPDATE_PERCENTAGE_STATUS_REQUESTED, UPDATE_PERCENTAGE_STATUS_SUCCEEDED } from './list.action';
import { Router } from '@angular/router';

function* watchFetchPercentageRequested() {
  yield takeEvery(FETCH_PERCENTAGE_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .percentage.get(action.data)
        .toPromise();
      yield put({ type: FETCH_PERCENTAGE_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdatePercentageStatusRequested() {
  yield takeEvery(UPDATE_PERCENTAGE_STATUS_REQUESTED, function*(action: any) {
    try {
      let result = yield AppInjector.get(ApiService)
        .percentage.active(action.data.id)
        .toPromise();
      yield put({ type: UPDATE_PERCENTAGE_STATUS_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdatePercentageStatusSuccessed() {
  yield takeEvery(UPDATE_PERCENTAGE_STATUS_SUCCEEDED, function*(action: any) {
    AppInjector.get(NotificationService).show('success', 'Percentage actived successfully', 3000);
    AppInjector.get(Router).navigate(['/retailer/percentage']);
  });
}

export default [watchFetchPercentageRequested, watchUpdatePercentageStatusRequested, watchUpdatePercentageStatusSuccessed];
