import * as _ from 'lodash';
import {
  FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED,
  LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_SUCCESSED,
  MARKETPLACE_CONFIGURATION_FETCH_ALL_RETAILER_SUCCESS,
  RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED
} from './list-retailer.actions';

const default_other_config = {
  name: '',
  amount: 0
};

export const ListRetailer = (
  state = { fetched: false, marketplace_retailer_configs: {}, retailers: [], zones: [], currencies: [], items_cost_default: {}, inputs_shipping: [] },
  action
) => {
  switch (action.type) {
    case FETCH_DATA_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED:
      const marketplace_retailer_configs = _.map(action.data, (i) => {
        if (_.isNil(i.others_config)) {
          i.others_config = [default_other_config];
        }
        if(_.isNil(i.shipping_cost)){
          i.shipping_cost = 0;
        }
        return _.assign(i, { is_edit_custom: false, is_edit_shipping: false, from_zone_id: 0, to_zone_id: 0 });
      });
      return _.assign(state, { fetched: true, marketplace_retailer_configs: marketplace_retailer_configs });
    case LIST_RETAILER_UPDATE_INPUT_FILTER_SHIPING_SUCCESSED:
      return _.assign({}, state, { inputs_shipping: action.data });
    case RENDER_ADMIN_MARKETPLACE_LIST_RETAILER_SUCCESSED:
      return _.assign({}, state, {
        zones: action.data.zones,
        currencies: action.data.currencies
      });
    default:
      return state;
  }
};
