import { ServiceProvider } from '../../service.provider';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { tap, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import Dataset from '../../../models/Dataset';

@Injectable()
export class DataService extends ServiceProvider {
  public url = '/api/v1/admin/dataset';
  public model = Dataset;

  getAllByRetailer(retailerId, params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/retailer/${retailerId}`), JSON.stringify(params)).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.model(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
