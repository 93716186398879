import { Edit } from './edit/edit.reducer';
import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { list } from './list/list.reducer';
import { detail } from './detail/detail.reducer';
import { Address } from './address/address.reducer';
import { CustomGeneration } from './generate-shipment-label-v2/generate-shipment-label-v2.reducer';

export const Shipment = combineReducers({Edit, 
  list,
  detail,
  Address,
  CustomGeneration
});
