import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_PERCENTAGE_RETAILER_REQUESTED, GET_PERCENTAGE_RETAILER_SUCCESSED } from './percentage.actions';

function* watchFetchPercentageRequested() {
  yield takeEvery(GET_PERCENTAGE_RETAILER_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .percentage.getPercentagesOfRetailer(action.retailerId, action.data)
        .toPromise();
      yield put({ type: GET_PERCENTAGE_RETAILER_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchPercentageRequested];
