import * as _ from 'lodash';

import { ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_SUCCESS, ADMIN_ATTRIBUTES_PRESELECT_MARKETPLACE, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from './attributes.action';
import {
  AFOUND_NL_EMAIL,
  AFOUND_SWE_EMAIL,
  ASPECT_EMAIL,
  CAMPADRE_EMAIL,
  CATAWIKI_EMAIL,
  CAVALLI_EMAIL,
  CDONAvailableAttributes,
  CDON_EMAIL,
  DTUTAvailableAttributes,
  DTUT_EMAIL,
  EBAY_FR_EMAIL,
  EBAY_UK_EMAIL,
  EBAY_US_EMAIL,
  HEWILONDON_EMAIL,
  HEWISTREET_EMAIL,
  HOODIES_EMAIL,
  HUNTSTREET_EMAIL,
  LAZADA_EMAIL,
  LAZADA_STYLETRIBUTE_EMAIL,
  LUXO_EMAIL,
  MIINTO_EAMIL,
  MODSIE_EMAIL,
  MY_PRIVATE_DRESSING_EMAIL,
  NEW_DEMO_MARKETPLACE_EMAIL,
  REBELLE_EMAIL,
  REEBONZ_EMAIL,
  RITE_EMAIL,
  SAKS_EMAIL,
  SELLBRITE_EMAIL,
  SOTT_EMAIL,
  STYLETRIBUTE_EMAIL,
  THE_VINTAGE_BAR_EMAIL,
  TRADERA_EMAIL,
  TRADESY_EMAIL,
  VESTIAIRE_COLLECTIVE_EMAIL,
  VESTIAIRE_COLLECTIVE_ST_EMAIL,
  VIDEDRESSINGSG_EMAIL,
  VIDEDRESSING_EMAIL,
  ZALORA_SINGAPORE_EMAIL,
  ZALORA_SINGAPORE_PRELOVED_EMAIL,
  ZALORA_SINGAPORE_PRELOVED_VIP_EMAIL,
  ZALORA_STYLETRIBUTE_MALAYSIA_EMAIL,
  ZALORA_STYLETRIBUTE_SINGAPORE_EMAIL,
  afoundNlAvailableAttributes,
  afoundSweAvailableAttributes,
  aspectAvailableAttributes,
  campadreAvailableAttributes,
  catawikiAvailableAttributes,
  cavalliAvailableAttributes,
  defaultAvailableAttributes,
  eBayFRAvailableAttributes,
  eBayUKAvailableAttributes,
  eBayUSAvailableAttributes,
  hewiLondonAvailableAttributes,
  hewiStreetAvailableAttributes,
  hoodiesAvailableAttributes,
  huntstreetAvailableAttributes,
  lazadaAvailableAttributes,
  lazadaStyletributeAvailableAttributes,
  luxoAvailableAttributes,
  miintoAvailableAttributes,
  modsieAvailableAttributes,
  myPrivateDressingAvailableAttributes,
  newDemoMarketplaceAvailableAttributes,
  rebelleAvailableAttributes,
  reebonzAvailableAttributes,
  riteAvailableAttributes,
  saksAvailableAttributes,
  sellbriteAvailableAttributes,
  sottAvailableAttributes,
  styletributeAvailableAttributes,
  theVintageBarAvailableAttributes,
  traderaAvailableAttributes,
  tradesyAvailableAttributes,
  vestiaireCollectiveAvailableAttributes,
  vestiaireCollectiveSTAvailableAttributes,
  videdressingAvailableAttributes,
  videdressingSgAvailableAttributes,
  zaloraAvailableAttributes
} from './attributes.const';

import { Category } from './category/category.reducer';
import { Color } from './color/color.reducer';
import { Condition } from './condition/condition.reducer';
import { Designer } from './designer/designer.reducer';
import { Material } from './material/material.reducer';
import { Size } from './size/size.reducer';
import { combineReducers } from 'redux';

const all = (
  state = {
    marketplace: { fetched: false, items: [], selected: null, direct: true, inputValue: null }
  },
  action
) => {
  switch (action.type) {
    case ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_SUCCESS:
      action.integrated_marketplace.sort((a, b) =>
        _.lowerCase(a.marketplace && a.marketplace.company ? a.marketplace.company : a.email) <
        _.lowerCase(b.marketplace && b.marketplace.company ? b.marketplace.company : b.email)
          ? -1
          : 1
      );
      return _.assign({}, state, {
        marketplace: {
          fetched: true,
          items: action.integrated_marketplace
        }
      });
    case ADMIN_ATTRIBUTES_SELECT_MARKETPLACE:
      return _.assign({}, state, {
        marketplace: _.assign({}, state.marketplace, {
          selected: _.assign({}, state.marketplace.selected, action.data, {
            availableAttributes: ((email) => {
              switch (email) {
                case STYLETRIBUTE_EMAIL:
                  return styletributeAvailableAttributes();
                case HOODIES_EMAIL:
                  return hoodiesAvailableAttributes();
                case HEWISTREET_EMAIL:
                  return hewiStreetAvailableAttributes();
                case NEW_DEMO_MARKETPLACE_EMAIL:
                  return newDemoMarketplaceAvailableAttributes();
                case HEWILONDON_EMAIL:
                  return hewiLondonAvailableAttributes();
                case LAZADA_EMAIL:
                  return lazadaAvailableAttributes();
                case LAZADA_STYLETRIBUTE_EMAIL:
                  return lazadaStyletributeAvailableAttributes();
                case HUNTSTREET_EMAIL:
                  return huntstreetAvailableAttributes();
                case MODSIE_EMAIL:
                  return modsieAvailableAttributes();
                case ZALORA_SINGAPORE_EMAIL:
                  return zaloraAvailableAttributes();
                case ZALORA_STYLETRIBUTE_SINGAPORE_EMAIL:
                  return zaloraAvailableAttributes();
                case ZALORA_SINGAPORE_PRELOVED_EMAIL:
                  return zaloraAvailableAttributes();
                case ZALORA_SINGAPORE_PRELOVED_VIP_EMAIL:
                  return zaloraAvailableAttributes();
                case ZALORA_STYLETRIBUTE_MALAYSIA_EMAIL:
                  return zaloraAvailableAttributes();
                case MY_PRIVATE_DRESSING_EMAIL:
                  return myPrivateDressingAvailableAttributes();
                case CATAWIKI_EMAIL:
                  return catawikiAvailableAttributes();
                case VIDEDRESSING_EMAIL:
                  return videdressingAvailableAttributes();
                case VIDEDRESSINGSG_EMAIL:
                  return videdressingSgAvailableAttributes();
                case AFOUND_SWE_EMAIL:
                  return afoundSweAvailableAttributes();
                case AFOUND_NL_EMAIL:
                  return afoundNlAvailableAttributes();
                case SELLBRITE_EMAIL:
                  return sellbriteAvailableAttributes();
                case TRADERA_EMAIL:
                  return traderaAvailableAttributes();
                case TRADESY_EMAIL:
                  return tradesyAvailableAttributes();
                case VESTIAIRE_COLLECTIVE_EMAIL:
                  return vestiaireCollectiveAvailableAttributes();
                case VESTIAIRE_COLLECTIVE_ST_EMAIL:
                  return vestiaireCollectiveSTAvailableAttributes();
                case REBELLE_EMAIL:
                  return rebelleAvailableAttributes();
                case MIINTO_EAMIL:
                  return miintoAvailableAttributes();
                case EBAY_US_EMAIL:
                  return eBayUSAvailableAttributes();
                case EBAY_UK_EMAIL:
                  return eBayUKAvailableAttributes();
                case EBAY_FR_EMAIL:
                  return eBayFRAvailableAttributes();
                case REEBONZ_EMAIL:
                  return reebonzAvailableAttributes();
                case CDON_EMAIL:
                  return CDONAvailableAttributes();
                case DTUT_EMAIL:
                  return DTUTAvailableAttributes();
                case ASPECT_EMAIL:
                  return aspectAvailableAttributes();
                case CAMPADRE_EMAIL:
                  return campadreAvailableAttributes();
                case LUXO_EMAIL:
                  return luxoAvailableAttributes();
                case SOTT_EMAIL:
                  return sottAvailableAttributes();
                case RITE_EMAIL:
                  return riteAvailableAttributes();
                case CAVALLI_EMAIL:
                  return cavalliAvailableAttributes();
                  case THE_VINTAGE_BAR_EMAIL:
                    return theVintageBarAvailableAttributes();
                 case SAKS_EMAIL:
                      return saksAvailableAttributes();
                default:
                  return defaultAvailableAttributes();
              }
            })(action.data.email)
          }),
          direct: action.direct
        })
      });
    case ADMIN_ATTRIBUTES_PRESELECT_MARKETPLACE:
      return _.assign({}, state, {
        marketplace: _.assign({}, state.marketplace, {
          inputValue: action.data
        })
      });
    default:
      return state;
  }
};
export const Attributes = combineReducers({
  all,
  Category,
  Designer,
  Size,
  Condition,
  Color,
  Material
});
