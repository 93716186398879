import {
  FETCH_DESIGNER_ATTRIBUTE_REQUESTED,
  FETCH_DESIGNER_ATTRIBUTE_SUCCESSED,
  CREATE_DESIGNER_REQUESTED,
  CREATE_DESIGNER_SUCCESSED,
  DELETE_DESIGNER_ATTRIBUTE_REQUESTED,
  DELETE_DESIGNER_ATTRIBUTE_SUCCESSED,
  FETCH_DESIGNER_DICTIONARY_SUCCESSED,
  FETCH_DESIGNER_DICTIONARY_REQUESTED,
  CREATE_DESIGNER_DICTIONARY_SUCCESSED,
  EDIT_DESIGNER_REQUESTED,
  EDIT_DESIGNER_SUCCESSED,
  EDIT_DESIGNER_DICTIONARY_REQUESTED,
  EDIT_DESIGNER_DICTIONARY_SUCCESSED
} from './designer.action';
import { takeEvery, put, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { DICTIONARY_DESIGNER_TYPE } from '../../../../app.const';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

export function* fetchAlldesigner() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.designer.get({ per_page: 10000 }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchDesigners(action) {
  try {
    let results = yield AppInjector.get(ApiService).designer.get({ per_page: 10000 }).toPromise();
    yield put({ type: FETCH_DESIGNER_ATTRIBUTE_SUCCESSED, data: results.items });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchDesignerAttributeRequest() {
  yield takeEvery(FETCH_DESIGNER_ATTRIBUTE_REQUESTED, fetchDesigners);
}

function* createDesigner(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.designer.create(action.data).toPromise();
    yield put({ type: CREATE_DESIGNER_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateDesignerRequested() {
  yield takeEvery(CREATE_DESIGNER_REQUESTED, createDesigner);
}

function* deleteItem(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.designer.delete(action.data.id).toPromise();
    yield put({ type: DELETE_DESIGNER_ATTRIBUTE_SUCCESSED, data: action.data.id });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchDesignerDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_DESIGNER_DICTIONARY_SUCCESSED, data: results });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteItemRequest() {
  yield takeEvery(DELETE_DESIGNER_ATTRIBUTE_REQUESTED, deleteItem);
}

function* watchFetchDesignerDictionaryRequested() {
  yield takeEvery(FETCH_DESIGNER_DICTIONARY_REQUESTED, fetchDesignerDictionary);
}

function* createDesignerDictionary(action) {
  const fetched_marketplace = yield select((state) => (state as any).Admin.Attribute.all.marketplace.fetched);
  if (fetched_marketplace) {
    const marketplaces = yield select((state) => (state as any).Admin.Attribute.all.marketplace.items);
    for (const marketplace of marketplaces) {
      try {
        const api = AppInjector.get(ApiService);

        let data = {
          type: DICTIONARY_DESIGNER_TYPE,
          attribute_id: action.data.getId(),
          marketplace_id: marketplace.getId(),
          value: action.data.getName()
        };

        let result = yield api.admin.dictionary.create(data).toPromise();
        yield put({ type: CREATE_DESIGNER_DICTIONARY_SUCCESSED, data: result });

        let marketplace_id = AppInjector.get(ActivatedRoute).snapshot.queryParams.marketplace_id;
        if (!_.isNil(marketplace_id)) {
          yield put({
            type: FETCH_DESIGNER_DICTIONARY_REQUESTED,
            data: {
              marketplace_id: marketplace_id,
              type: DICTIONARY_DESIGNER_TYPE
            }
          });
        }
      } catch (e) {
        AppInjector.get(PreloaderService).hide();
        yield put({ type: API_CALL_ERROR, error: e });
      }
    }
  }
}

function* watchCreateDesignerSucceeded() {
  yield takeEvery(CREATE_DESIGNER_SUCCESSED, createDesignerDictionary);
}

function* watchEditDesignerRequested() {
  yield takeEvery(EDIT_DESIGNER_REQUESTED, editDesigner);
}

function* editDesigner(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.designer.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_DESIGNER_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update designer success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditDesignerDictionaryRequested() {
  yield takeEvery(EDIT_DESIGNER_DICTIONARY_REQUESTED, editDesignerDictionary);
}

function* editDesignerDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_DESIGNER_DICTIONARY_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [
  watchFetchDesignerAttributeRequest,
  watchCreateDesignerRequested,
  watchDeleteItemRequest,
  watchFetchDesignerDictionaryRequested,
  watchCreateDesignerSucceeded,
  watchEditDesignerRequested,
  watchEditDesignerDictionaryRequested
];
