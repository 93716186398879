import { Injectable } from "@angular/core";
import * as _ from "lodash";
import * as Cookies from "js-cookie";
import { environment } from "../../environments/environment";
import jwt_decode from "jwt-decode";
import * as moment from 'moment';

@Injectable()
export class AuthService {
  constructor() {}
  public isAuthenticated(): boolean {
    const token = Cookies.get(environment.jwtTokenKey);
    if (!_.isNil(token)) {
      const decoded_token = jwt_decode(token);
      const is_expired = moment.unix(moment().unix()) > moment.unix(decoded_token['exp']);
      return !is_expired;
    }
    return false;
  }
}
