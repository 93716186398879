import Category from './category/category.saga';
import Designer from './designer/designer.saga';
import Size from './size/size.saga';
import Condition from './condition/condition.saga';
import Color from './color/color.saga';
import Material from './material/material.saga';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../store/action';
import {
  ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_SUCCESS,
  ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_REQUEST,
  ADMIN_ATTRIBUTES_SELECT_RETAILER,
  ADMIN_ATTRIBUTES_RETAILER_SELECT_ANY,
  REQUEST_NEW_ATTRIBUTE_REQUESTED, ADMIN_ATTRIBUTES_PRESELECT_RETAILER, ADMIN_ATTRIBUTES_PRESEARCH_RETAILER
} from './attributes-retailer.action';
import Cookies from 'js-cookie';
import * as _ from 'lodash';
import { NotificationService } from '../../../common/services/notification/notification.service';

function* watchFetchAllReatilersRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_REQUEST, fetchAllRetailers);
}

function* fetchAllRetailers(action: any) {
  const api = AppInjector.get(ApiService);
  // const fetched = yield select((state: any) => state.Admin.AttributesRetailer.all.retailer.fetched);
  // if (!fetched) {
  try {
    action.data = Cookies.get('role_slug') === 'retailer' ? _.assign(action.data, { id: Cookies.get('id') }) : action.data;
    const result = yield api.user.list(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_SUCCESS, data: result });
    if (action.auto_select_email) {
      const selectedRetailer = result.find(retailer => retailer.email === action.auto_select_email);
      if (selectedRetailer) {
        yield put({ type: ADMIN_ATTRIBUTES_PRESELECT_RETAILER, data: selectedRetailer });
        yield put({ type: ADMIN_ATTRIBUTES_PRESEARCH_RETAILER, data: action.auto_search });
        yield put({ type: ADMIN_ATTRIBUTES_SELECT_RETAILER, data: selectedRetailer, direct: true, component: action.component });
      }
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
  // }
}

function* runChangesRetailer(action: any) {
  yield put({ type: ADMIN_ATTRIBUTES_RETAILER_SELECT_ANY, data: action.data, direct: action.direct, component: action.component, constraints: action.constraints });
}

function* watchRetailerChangedRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_SELECT_RETAILER, runChangesRetailer);
}

function* watchNewAttributeRequest() {
  yield takeEvery(REQUEST_NEW_ATTRIBUTE_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.attribute.createNewAttributeRequest(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', `Successfully requested new attribute`, 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [...Category, ...Designer, ...Size, ...Condition, ...Color, ...Material, watchFetchAllReatilersRequest, watchRetailerChangedRequest, watchNewAttributeRequest];
