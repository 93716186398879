export const ADMIN_FETCH_ENDPOINT_REQUESTED = 'ADMIN_FETCH_ENDPOINT_REQUESTED';
export const ADMIN_FETCH_ENDPOINT_SUCCEEDED = 'ADMIN_FETCH_ENDPOINT_SUCCEEDED';


export const ADMIN_CREATE_ENDPOINT_REQUESTED = 'ADMIN_CREATE_ENDPOINT_REQUESTED';
export const ADMIN_CREATE_ENDPOINT_SUCCEEDED = 'ADMIN_CREATE_ENDPOINT_SUCCEEDED';

export const ADMIN_UPDATE_ENDPOINT_REQUESTED = 'ADMIN_UPDATE_ENDPOINT_REQUESTED';
export const ADMIN_UPDATE_ENDPOINT_SUCCEEDED = 'ADMIN_UPDATE_ENDPOINT_SUCCEEDED';


export const ADMIN_DELETE_ENDPOINT_REQUESTED = 'ADMIN_DELETE_ENDPOINT_REQUESTED';
export const ADMIN_DELETE_ENDPOINT_SUCCEEDED = 'ADMIN_DELETE_ENDPOINT_SUCCEEDED';
