import { put, takeEvery } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import {
  FETCH_ASYNC_PROCESSES_REQUESTED,
  FETCH_ASYNC_PROCESSES_SUCCEEDED,
  RETRY_FAILED_ASYNC_PROCESS_REQUESTED
} from './asnyc-process.action';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchFetchAsyncProcessesRequested() {
  yield takeEvery(FETCH_ASYNC_PROCESSES_REQUESTED, function*(action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.asyncProcess.get(action.data)
        .toPromise();
      yield put({ type: FETCH_ASYNC_PROCESSES_SUCCEEDED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetryFailedAsyncProcessRequested() {
  yield takeEvery(RETRY_FAILED_ASYNC_PROCESS_REQUESTED, function*(action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.asyncProcess.retry(action.data)
        .toPromise();
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(NotificationService).show('success', 'Retry success', 3000);
      router.navigate(['/admin/product/async-process']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchAsyncProcessesRequested, watchRetryFailedAsyncProcessRequested];
