import Model from './Model';

export class IssueTracking extends Model {
  status: string;
  is_closed: boolean;
  is_it: boolean;

  constructor(options) {
    super();
    this.bind(options);
  }
  isNotFixed() {
    return this.status !== 'FIXED';
  }
  isClosed() {
    return this.is_closed === true;
  }
  isIt() {
    return this.is_it === true;
  }
}
