import { FETCH_ENDPOINT_REQUESTED, FETCH_ENDPOINT_SUCCEEDED, CREATE_ENDPOINT_REQUESTED, UPDATE_ENDPOINT_REQUESTED, DELETE_ENDPOINT_REQUESTED } from './endpont.actions';
import { takeEvery, put } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { NotificationService } from '../../../common/services/notification/notification.service';
declare const $: any;

function* watchFetchListEndpointRequested() {
  yield takeEvery(FETCH_ENDPOINT_REQUESTED, function*(action) {
    try {
      const results = yield AppInjector.get(ApiService)
        .endpoint.list({})
        .toPromise();
      yield put({
        type: FETCH_ENDPOINT_SUCCEEDED,
        data: results
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateEndpointRequested() {
  yield takeEvery(CREATE_ENDPOINT_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .endpoint.create(action.data)
        .toPromise();
      yield put({
        type: FETCH_ENDPOINT_REQUESTED
      });
      $('#myEndpointModal').modal('hide');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateEndpointRequested() {
  yield takeEvery(UPDATE_ENDPOINT_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .endpoint.update(action.data.id, action.data)
        .toPromise();
      yield put({
        type: FETCH_ENDPOINT_REQUESTED
      });
      $('#myEndpointModal').modal('hide');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteEndpointRequested() {
  yield takeEvery(DELETE_ENDPOINT_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .endpoint.delete(action.data.id)
        .toPromise();
      yield put({
        type: FETCH_ENDPOINT_REQUESTED
      });
      $('#myEndpointModal').modal('hide');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchListEndpointRequested, watchCreateEndpointRequested, watchUpdateEndpointRequested, watchDeleteEndpointRequested];
