import { takeEvery, put } from 'redux-saga/effects';
import {
  ADMIN_FETCH_WEBHOOK_REQUESTED,
  ADMIN_FETCH_WEBHOOK_SUCCEEDED,
  ADMIN_CREATE_WEBHOOK_REQUESTED,
  ADMIN_UPDATE_WEBHOOK_REQUESTED,
  ADMIN_DELETE_WEBHOOK_REQUESTED
} from './webhook.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
declare const $: any;

function* watchAdminFetchListEndpointRequested() {
  yield takeEvery(ADMIN_FETCH_WEBHOOK_REQUESTED, function*(action: any) {
    try {
      const results = yield AppInjector.get(ApiService)
        .admin.user.getAllEndpointById(action.data.id, action.data)
        .toPromise();
      yield put({
        type: ADMIN_FETCH_WEBHOOK_SUCCEEDED,
        data: results
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminCreateEndpointRequested() {
  yield takeEvery(ADMIN_CREATE_WEBHOOK_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .admin.endpoint.create(action.data)
        .toPromise();
      yield put({
        type: ADMIN_FETCH_WEBHOOK_REQUESTED,
        data: { id: action.data.retailer_id }
      });
      $('#myAdminEndpointModal').modal('hide');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminUpdateEndpointRequested() {
  yield takeEvery(ADMIN_UPDATE_WEBHOOK_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .admin.endpoint.update(action.data.id, action.data)
        .toPromise();
      yield put({
        type: ADMIN_FETCH_WEBHOOK_REQUESTED,
        data: { id: action.data.retailer_id }
      });
      $('#myAdminWebhookModal').modal('hide');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminDeleteEndpointRequested() {
  yield takeEvery(ADMIN_DELETE_WEBHOOK_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .admin.endpoint.delete(action.data.id)
        .toPromise();
      yield put({
        type: ADMIN_FETCH_WEBHOOK_REQUESTED,
        data: { id: action.data.id }
      });
      $('#myAdminWebhookModal').modal('hide');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchAdminFetchListEndpointRequested, watchAdminCreateEndpointRequested, watchAdminUpdateEndpointRequested, watchAdminDeleteEndpointRequested];
