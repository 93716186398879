import { PreloaderService } from './../../common/services/preloader/preloader.service';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Integration from '../../models/Integration';
import { AppInjector } from '../../app-injector';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class IntegrationService extends ServiceProvider {
  public url = '/api/v1/integrations';
  public model = Integration;

  configuration(params = {}) {
    return AppInjector.get(HttpClient)
      .put(this.apiUrl.getApiUrl(`${this.url}/configuration`), params)
      .pipe(
        tap((result) => {}),
        map((result) => _.map((result as any).data, (item) => new Integration(item)))
      );
  }
  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: { ...params, per_page: '10000' } });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
