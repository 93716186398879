import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Language from '../../models/Language';
import * as _ from 'lodash';

@Injectable()
export class LanguageService extends ServiceProvider {
  public url = '/api/v1/admin/languages';
  public model = Language;
}
