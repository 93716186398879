export const FETCH_DATA_ADMIN_LOCATION_REQUESTED = 'FETCH_DATA_ADMIN_LOCATION_REQUESTED';
export const FETCH_DATA_ADMIN_LOCATION_SUCCESSED = 'FETCH_DATA_ADMIN_LOCATION_SUCCESSED';


export const fetchAdminLocationRequested = (payload) => {
    return {
        type: FETCH_DATA_ADMIN_LOCATION_REQUESTED,
        payload: payload,
    };
};
