import * as _ from 'lodash';
import { GET_SHIPMENT_DETAIL_ADMIN_SUCCEEDED, RENDER_UPDATE_SHIPMENT_FORM_REQUESTED } from './detail.actions';
import { SHIPMENT_STATUS_SHIPPED } from '../../../../models/Shipment';

export const detail = (state = { inputs: [] }, action) => {
  switch (action.type) {
    case RENDER_UPDATE_SHIPMENT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case GET_SHIPMENT_DETAIL_ADMIN_SUCCEEDED:
      action.data.order_product = _.assign(action.data.order_product, { return_button: action.data.status === SHIPMENT_STATUS_SHIPPED });
      state.inputs = _.map(state.inputs, i => {
        if (action.data.default_retailer_service && !action.data.default_retailer_service.is_required_manual_shipping_params) {
          if(['shipping_label_url', 'tracking_status_url'].indexOf(i.key) > -1){
            i.label = i.label.replace('*', '');
            i.validators = []
          }
        }
        return i;
      });
      return _.assign({}, state, { fetched: true, item: action.data, transaction: action.transaction, retrieves: action.retrieves, products: action.products });
    default:
      return state;
  }
};
