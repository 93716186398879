import { ServiceProvider } from '../../service.provider';
import NotificationActionMarketplaceManagement from '../../../models/NotificationActionMarketplaceManagement';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { tap, map, catchError } from 'rxjs/operators';
import LanguageNotificationActionMarketplaceManagement from '../../../models/LanguageNotificationActionMarketplaceManagement';

@Injectable()
export class CommunicationService extends ServiceProvider {
  public url = '/api/v1/admin/communications';
  public model = NotificationActionMarketplaceManagement;
  public languageIntegrationModel = LanguageNotificationActionMarketplaceManagement;

  getDefaultRetailerEmailConfig(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/email-configurations/retailer/${id}/default`)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => result.data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getRetailerMarketplaceEmailConfig(retailerId, marketplaceId): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/email-configurations/retailer/${retailerId}/marketplace/${marketplaceId}`)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => result.data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getDefaultRetailerEmailConfigByLanguage(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/email-configurations/retailer/default`), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => result.data.map((item) => new this.languageIntegrationModel(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getRetailerMarketplaceEmailConfigByLanguage(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/email-configurations/retailer/marketplace`), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => result.data.map((item) => new this.languageIntegrationModel(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getRemindersByNotificationActionMarketplaceManagement(notification_action_marketplace_management_id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/${notification_action_marketplace_management_id}/reminders`)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
