import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { MarketplaceManagement } from './marketplace-management/marketplace-management.reducer';
import { editAction } from './edit-action/edit-action.reducer';
import { DefaultConfig } from './default-config/default-config.reducer';
import { ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_SUCCESS, ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS } from './integration.actions';

const getAllAvailableTemplateSuccess = (state, action) => {
  return _.assign({}, state, {
    availableTemplate: _.assign({}, state.availableTemplate, {
      fetched: true,
      items: action.data.map((template) => _.assign(template, { label: template.name, value: template.name }))
    })
  });
};

const getAllLanguageSuccess = (state, action) => {
  return _.assign({}, state, {
    language: _.assign({}, state.language, {
      fetched: true,
      items: action.data
    })
  });
};

export const All = (state = { availableTemplate: { fetched: false, items: [] }, language: { fetched: false, items: [] } }, action) => {
  switch (action.type) {
    case ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_SUCCESS: {
      return getAllAvailableTemplateSuccess(state, action);
    }
    case ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS: {
      return getAllLanguageSuccess(state, action);
    }
    default:
      return state;
  }
};

export const Integration = combineReducers({ All, list, MarketplaceManagement, editAction, DefaultConfig });
