import { NotificationService } from './../../../../common/services/notification/notification.service';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import {
  ADMIN_UPLOAD_COMMISSION_REQUESTED,
  ADMIN_UPLOAD_COMMISSION_SUCCESSED,
  ADMIN_UPLOAD_CUSTOM_COST_REQUESTED,
  ADMIN_UPLOAD_CUSTOM_COST_SUCCESSED,
  FETCH_ADMIN_ITEMS_COST_REQUESTED,
  FETCH_ADMIN_ITEMS_COST_SUCCESSED,
  FETCH_ALL_ADMIN_CUSTOM_COST_REQUESTED,
  FETCH_ALL_ADMIN_CUSTOM_COST_SUCCESSED,
  RENDER_ADMIN_ITEMS_COST_FORM_REQUESTED,
  SAVE_ITEMS_COST_SETTING_REQUESTED,
  SAVE_ITEMS_COST_SETTING_SUCCESSED,
  SET_SHIPPING_COST_CONSTANTS
} from './edit.actions';

function* watchRenderRetailerDetailFormRequested() {
  yield takeLatest(RENDER_ADMIN_ITEMS_COST_FORM_REQUESTED, function* (action: any) {
    try {
      let zones = yield AppInjector.get(ApiService).admin.zone.list().toPromise();
      let currencies = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      currencies = currencies.items;
      yield put({
        type: SET_SHIPPING_COST_CONSTANTS,
        data: {
          zones,
          currencies
        }
      });
      yield put({
        type: FETCH_ADMIN_ITEMS_COST_REQUESTED
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSaveSettingRequested() {
  yield takeLatest(SAVE_ITEMS_COST_SETTING_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.itemsCost.updateOrCreate(action.data).toPromise();
      yield delay(500);
      yield put({ type: SAVE_ITEMS_COST_SETTING_SUCCESSED, data: result });
      yield delay(500);
      yield put({
        type: FETCH_ADMIN_ITEMS_COST_REQUESTED
      });
      AppInjector.get(NotificationService).show('success', 'Update config Success', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchFetchAdminItemCostRequested() {
  yield takeLatest(FETCH_ADMIN_ITEMS_COST_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.itemsCost.list(action.data).toPromise();
      yield put({ type: FETCH_ADMIN_ITEMS_COST_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}


function* watchFetchAdminCustomCostRequested() {
  yield takeLatest(FETCH_ALL_ADMIN_CUSTOM_COST_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.customCost.get(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_CUSTOM_COST_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchModifyByCsvRequested() {
  yield takeLatest(ADMIN_UPLOAD_CUSTOM_COST_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.customCost.uploadCsv(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_CUSTOM_COST_REQUESTED, data: { page: 1 } });
      AppInjector.get(NotificationService).show('success', 'Upload custom cost successfully', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUploadCommissionRequested() {
  yield takeLatest(ADMIN_UPLOAD_COMMISSION_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.itemsCost.uploadCsv(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', `Upload ${action.data.type} commission successfully`, 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchRenderRetailerDetailFormRequested, watchSaveSettingRequested, watchFetchAdminItemCostRequested, watchFetchAdminCustomCostRequested, watchModifyByCsvRequested, watchUploadCommissionRequested];
