import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { Observable } from 'rxjs';
import { AppInjector } from '../../app-injector';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import LengthAwarePaginator from '../../models/LengthAwarePaginator';
import Attribute from '../../models/Attribute';


@Injectable()
export class AttributeService extends ServiceProvider {
  public url = '/api/v1/attributes';
  public model = Attribute;

  createNewAttributeRequest(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url), data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }

  getAttributeByType(type, params): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${type}`, { params: queryParams }).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map(result =>
        _.assign(
          {},
          {
            items: (result as any).data.map(item => new this.model(item)),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError(error => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  getAttributeByTypeAndId(type, id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${type}/${id}`).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  updateAttribute(data, type, id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${type}/${id}`, data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }

  deleteAttribute(type, id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.delete(`${this.apiUrl.getApiUrl(this.url)}/${type}/${id}`).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }

  createNewAttribute(data, type): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${type}`, data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }
}
