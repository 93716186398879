import { RENDER_CREATE_ADMIN_MARKETPLACE_CONFIGURATION_REQUESTED, CREATE_ADMIN_MARKETPLACE_CONFIGURATION_SUCCESSED } from './configuration.actions';
import * as _ from 'lodash';

export const Configuration = (state = { inputs : []}, action) => {
  switch (action.type) {
    case CREATE_ADMIN_MARKETPLACE_CONFIGURATION_SUCCESSED:
        return _.assign({}, state, { created: true, item: action.data });
    case RENDER_CREATE_ADMIN_MARKETPLACE_CONFIGURATION_REQUESTED:
        return _.assign({}, state, { inputs: action.data.inputs });
    default:
      return state;
  }
};
