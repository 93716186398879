import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { Router } from '@angular/router';
import { put, takeEvery, all, call, takeLatest } from 'redux-saga/effects';
import { RENDER_ADD_PRODUCT_TAGS_FORM_REQUESTED, UPDATE_ADD_PRODUCT_TAGS_INPUT_OPTIONS, ADD_PRODUCT_TAGS_REQUESTED, REMOVE_TAG_REQUESTED } from './add-tag.actions';
import { fetchAlltag } from '../../tag/tag.saga';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { listRouter } from '../product.const';

function* fetchAllDataAddTags(action) {
  const [tags] = yield all([call(fetchAlltag)]);
  const optionsData = {
    tags: _.map(tags, (item, key) => _.assign(item, { value: item.id, label: item.name }))
  };
  yield put({ type: UPDATE_ADD_PRODUCT_TAGS_INPUT_OPTIONS, data: optionsData });
}

function* watchAddProductTagsFormRequested() {
  yield takeEvery(RENDER_ADD_PRODUCT_TAGS_FORM_REQUESTED, fetchAllDataAddTags);
}

function* addProductTags(action) {
  const api = AppInjector.get(ApiService);
  AppInjector.get(PreloaderService).show();
  try {
    const result = yield api.admin.tag.addTagsToProducts(action.data).toPromise();
    const async = yield AppInjector.get(ApiService)
      .asyncProcess.checkAsyncStatus(result.id, 10000)
      .toPromise();
    if (async !== 'Time out') {
      if (async.status === 'COMPLETED') {
        AppInjector.get(NotificationService).show('success', 'Add tags successfully', 3000);
        AppInjector.get(PreloaderService).hide();
        AppInjector.get(Router).navigate(listRouter());
      }
      if (async.status === 'CANCELLED') {
        AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
      }
    } else {
      AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchAddProductTagsRequested() {
  yield takeEvery(ADD_PRODUCT_TAGS_REQUESTED, addProductTags);
}

function* removeTagAsyncRequested() {
  yield takeLatest(REMOVE_TAG_REQUESTED, function*(action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      const result = yield AppInjector.get(ApiService)
      .admin.tag.removeTag(action.data)
      .toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatus(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          AppInjector.get(NotificationService).show('success', 'Remove tags successfully', 3000);
          AppInjector.get(PreloaderService).hide();
          AppInjector.get(Router).navigate(listRouter());
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchAddProductTagsFormRequested, watchAddProductTagsRequested, removeTagAsyncRequested];
