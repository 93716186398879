import { DICTIONARY_DESIGNER_PREOWNED_TYPE, DICTIONARY_DESIGNER_TYPE } from '../../../../models/Dictionary';
export const designerAttributes = () => ['/', 'admin', 'attributes', 'designer'];

export const CONDITION_INPUTS = [
  {
    value: DICTIONARY_DESIGNER_TYPE,
    label: 'Default'
  },
  {
    value: DICTIONARY_DESIGNER_PREOWNED_TYPE,
    label: 'New with tag'
  }
];
