import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import {
  FETCH_CATEGORY_ATTRIBUTE_REQUESTED,
  RESET_CATEGORY_DICTIONARY,
  FETCH_CATEGORY_DICTIONARY_REQUESTED,
  DELETE_CATEGORY_ATTRIBUTE_REQUESTED,
  EDIT_CATEGORY_DICTIONARY_REQUESTED
} from './category.action';
import { ATTRIBUTE_COMPONENT } from '../attribute.action';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { DICTIONARY_CATEGORY_TYPE } from '../../../../app.const';
import { CREATE_CATEGORY_REQUESTED } from '../../../admin/category/create/create.actions';
import { EDIT_CATEGORY_REQUESTED } from '../../category/edit/edit.actions';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public store;
  public edit_data: any = {};
  public edit_dictionary_data: any = {};

  constructor(store: Store, private router: Router, private activatedRoute: ActivatedRoute) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    // tslint:disable-next-line:radix
    const marketplace_id = parseInt(this.activatedRoute.snapshot.queryParams.marketplace_id);
    if (!_.isNil(marketplace_id)) {
      this.store.dispatch({
        type: FETCH_CATEGORY_DICTIONARY_REQUESTED,
        data: {
          marketplace_id: marketplace_id,
          type: DICTIONARY_CATEGORY_TYPE
        }
      });
    } else {
      this.store.dispatch({ type: RESET_CATEGORY_DICTIONARY });
    }
    this.store.dispatch({ type: FETCH_CATEGORY_ATTRIBUTE_REQUESTED });
  }

  openEditForm(item) {
    item.is_show_edit_form = true;
    this.edit_data = _.clone(item);
  }

  closeEditForm(item) {
    item.is_show_edit_form = false;
  }

  openEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = true;
    this.edit_dictionary_data = _.clone(item);
  }

  closeEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = false;
  }

  updateItem(form, item) {
    this.store.dispatch({
      type: EDIT_CATEGORY_REQUESTED,
      data: item,
      component: ATTRIBUTE_COMPONENT
    });
    form.resetForm();
  }

  updateDictionaryItem(form, item) {
    this.store.dispatch({
      type: EDIT_CATEGORY_DICTIONARY_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  submit(form) {
    this.store.dispatch({
      type: CREATE_CATEGORY_REQUESTED,
      data: this.store.getState().Admin.Attribute.Category.create.data,
      component: ATTRIBUTE_COMPONENT
    });
    form.resetForm();
  }

  deleteItem(item) {
    this.store.dispatch({
      type: DELETE_CATEGORY_ATTRIBUTE_REQUESTED,
      data: item
    });
  }

  selectMarketplace(marketplace) {
    let router = window.location.pathname;
    this.router.navigate([router], { queryParams: { marketplace_id: marketplace.getId() } });

    this.store.dispatch({
      type: FETCH_CATEGORY_DICTIONARY_REQUESTED,
      data: {
        marketplace_id: marketplace.getId(),
        type: DICTIONARY_CATEGORY_TYPE
      }
    });
  }
}
