import { NotificationService } from '../../../../common/services/notification/notification.service';
import { USER_ROLES } from './../../../../app.const';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  CREATE_ADMIN_MARKETPLACE_CONFIGURATION_REQUESTED,
  CREATE_ADMIN_MARKETPLACE_CONFIGURATION_SUCCESSED,
  RENDER_CREATE_ADMIN_MARKETPLACE_CONFIGURATION_REQUESTED
} from './configuration.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { Router } from '@angular/router';
import { listMarketplaceShippingRouter } from '../marketplace.const';
import * as _ from 'lodash';

function* watchCreateConfigurationRequest() {
  yield takeEvery(CREATE_ADMIN_MARKETPLACE_CONFIGURATION_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.marketplaceRetailerConfig.configuration(action.marketplace_id, action.data).toPromise();
      yield put({ type: CREATE_ADMIN_MARKETPLACE_CONFIGURATION_SUCCESSED, data: result });
      console.log('okok');
      AppInjector.get(NotificationService).show('success', 'Configuration success', 3000);
      AppInjector.get(Router).navigate([`admin/marketplace/${action.marketplace_id}/retailers`]);

    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateADminMarketplaceConfiguarationRenderFormRequest() {
  yield takeEvery(RENDER_CREATE_ADMIN_MARKETPLACE_CONFIGURATION_REQUESTED, function* (action: any) {
    try {
      let retailers = yield AppInjector.get(ApiService)
        .user.list({ constraints: JSON.stringify({ role_ids: USER_ROLES.RETAILER }) })
        .toPromise();
      let list_configuration_retailers = yield fetchMarketplaceRetailerConfiguration({ marketplace_id: action.marketplace_id });
      list_configuration_retailers = _.map(list_configuration_retailers, (i) => i.retailer);
      const retailer_input = _.find(action.data.inputs, (i) => i.key === 'retailer_id');
      retailer_input.options = _.map(_.differenceBy(retailers, list_configuration_retailers, 'retailer'), (item: any) => _.assign(item, { value: item.id, label: item.email }));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* fetchMarketplaceRetailerConfiguration(params?) {
  const fetched = yield select((state) => (state as any).Admin.AdminMarketplace.ListRetailer.fetched);
  if (!fetched) {
    try {
      let results = yield AppInjector.get(ApiService).admin.marketplaceRetailerConfig.list(params).toPromise();
      return results;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    let data = yield select((state) => (state as any).Admin.AdminMarketplace.ListRetailer.marketplace_retailer_configs);
    return data;
  }
}

export default [watchCreateConfigurationRequest, watchCreateADminMarketplaceConfiguarationRenderFormRequest];
