import { AuthGuardService } from './../../../auth/auth-guard.service';
import { ListComponent } from './list/list.component';
import { MarketplaceManagementComponent } from './marketplace-management/marketplace-management.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditActionComponent } from './edit-action/edit-action.component';
import { DefaultConfigComponent } from './default-config/default-config.component';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: ':retailerId/marketplace-management',
        component: MarketplaceManagementComponent
      },
      {
        path: ':retailerId/marketplace-management/edit/:marketplaceId',
        component: EditActionComponent
      },
      {
        path: ':retailerId/default-config',
        component: DefaultConfigComponent
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class IntegrationRoutingModule {}
