import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import {
  FETCH_SIZE_ATTRIBUTE_REQUESTED,
  CREATE_SIZE_REQUESTED,
  FETCH_SIZE_DICTIONARY_REQUESTED,
  RESET_SIZE_DICTIONARY,
  DELETE_SIZE_ATTRIBUTE_REQUESTED,
  EDIT_SIZE_REQUESTED,
  EDIT_SIZE_DICTIONARY_REQUESTED
} from './size.action';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { FETCH_DICTIONARY_BY_MARKETPLACE } from '../attribute.action';
import { DICTIONARY_SIZE_TYPE } from '../../../../app.const';

@Component({
  selector: 'app-size',
  templateUrl: './size.component.html',
  styleUrls: ['./size.component.scss']
})
export class SizeComponent implements OnInit {
  public store;
  public edit_data: any = {};
  public edit_dictionary_data: any = {};
  public name_valid = true;

  constructor(store: Store, private router: Router, private activatedRoute: ActivatedRoute) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    // tslint:disable-next-line:radix
    const marketplace_id = parseInt(this.activatedRoute.snapshot.queryParams.marketplace_id);
    if (!_.isNil(marketplace_id)) {
      this.store.dispatch({
        type: FETCH_SIZE_DICTIONARY_REQUESTED,
        data: {
          marketplace_id: marketplace_id,
          type: DICTIONARY_SIZE_TYPE
        }
      });
    } else {
      this.store.dispatch({ type: RESET_SIZE_DICTIONARY });
    }
    this.store.dispatch({ type: FETCH_SIZE_ATTRIBUTE_REQUESTED });
  }

  openEditForm(item) {
    item.is_show_edit_form = true;
    this.edit_data = _.clone(item);
  }

  closeEditForm(item) {
    item.is_show_edit_form = false;
  }

  openEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = true;
    this.edit_dictionary_data = _.clone(item);
  }

  closeEditDictionaryForm(item) {
    item.is_show_edit_dictionary_form = false;
  }

  updateItem(form, item) {
    this.store.dispatch({
      type: EDIT_SIZE_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  updateDictionaryItem(form, item) {
    this.store.dispatch({
      type: EDIT_SIZE_DICTIONARY_REQUESTED,
      data: item
    });
    form.resetForm();
  }

  submit(form) {
    this.store.dispatch({
      type: CREATE_SIZE_REQUESTED,
      data: this.store.getState().Admin.Attribute.Size.create.data
    });
    form.resetForm();
  }

  deleteItem(item) {
    this.store.dispatch({
      type: DELETE_SIZE_ATTRIBUTE_REQUESTED,
      data: item
    });
  }

  selectMarketplace(marketplace) {
    let router = window.location.pathname;
    this.router.navigate([router], { queryParams: { marketplace_id: marketplace.getId() } });

    this.store.dispatch({
      type: FETCH_SIZE_DICTIONARY_REQUESTED,
      data: {
        marketplace_id: marketplace.getId(),
        type: DICTIONARY_SIZE_TYPE
      }
    });
  }
}
