import { PreloaderService } from './../../../../common/services/preloader/preloader.service';
import { FETCH_ALL_ADMIN_COLLECTION_SUCCESSED, FETCH_ALL_ADMIN_COLLECTION_REQUESTED, GET_ALL_RETAILER_IN_COLLECTION_REQUESTED, GET_ALL_RETAILER_IN_COLLECTION_SUCCESSED, GET_COLLECTION_DETAILS_INFO_REQUESTED } from './list.actions';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

function* watchFetchCollectionRequest() {
  yield takeEvery(FETCH_ALL_ADMIN_COLLECTION_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.collection.get(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_COLLECTION_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* getAllRetailerInCollectionPage() {
  yield takeLatest(GET_ALL_RETAILER_IN_COLLECTION_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      let user = yield api.user.profile({ includes: 'roles' }).toPromise();

      let data: any = {};
      if (!_.isNil(action.data)) {
        data.constraints = JSON.stringify(action.data);
      }
      data.includes = 'retailer';

      if(user.logistic_partner){
        const parent_retailer_data = user.logistic_partner.data
        if(parent_retailer_data.length > 0){
          if(parent_retailer_data[0].parent_retailer_id){
            data.parent_retailer_id = user.logistic_partner.data[0].parent_retailer_id
          }   
        } 
      }

      let results = yield AppInjector.get(ApiService).user.list(data).toPromise();
      results = results.sort((a, b) =>
        _.lowerCase(a.retailer && a.retailer.company ? a.retailer.company : a.email) < _.lowerCase(b.retailer && b.retailer.company ? b.retailer.company : b.email) ? -1 : 1
      );
      yield put({ type: GET_ALL_RETAILER_IN_COLLECTION_SUCCESSED, data: results });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* getCollectionInfomationPage() {
  yield takeLatest(GET_COLLECTION_DETAILS_INFO_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const swal = Swal as any;
    try {
      swal
        .fire({
          icon: 'info',
          title: 'Processing...',
          showConfirmButton: false,
          onBeforeOpen: () => {
            swal.showLoading();
          }
        })
        .then((result) => {});
      const result = yield api.admin.collection.getDetailVisible().toPromise();
      const async = yield api.asyncProcess.checkAsyncStatusByCode(result.async_process_code, 3000).toPromise();
      if (async === 'Time out' || async.status === 'CANCELLED') {
        AppInjector.get(PreloaderService).hide();
        swal
          .fire({
            icon: 'error',
            title: 'Error',
            text: async.message
          })
          .then((result) => {});

        return false;
      }
      if (async.status === 'COMPLETED') {
        AppInjector.get(PreloaderService).hide();
        const { output } = async;
        swal
          .fire({
            icon: 'success',
            html: ` <h4>Collection information</h4>
            <p style="font-size: 16px; margin-top: 30px; font-weight: bold; text-align: left">Link: ${output}</p>`,
            confirmButtonText: `Download`
          })
          .then((data) => {
            if (data.value) {
              const a = document.createElement('a');
              a.href = output;
              a.click();
            }
          });
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [
  watchFetchCollectionRequest,
  getAllRetailerInCollectionPage,
  getCollectionInfomationPage
];
