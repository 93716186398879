import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import ProductModel from '../../models/ProductModel';

@Injectable()
export class ModelService extends ServiceProvider {
  public url = '/api/v1/models';
  public model = ProductModel;

}
