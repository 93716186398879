import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import { ADMIN_FETCH_ENDPOINT_REQUESTED, ADMIN_CREATE_ENDPOINT_REQUESTED, ADMIN_UPDATE_ENDPOINT_REQUESTED, ADMIN_DELETE_ENDPOINT_REQUESTED } from './endpoint.actions';
import { ActivatedRoute } from '@angular/router';
declare const $: any;
import * as _ from 'lodash';

@Component({
  selector: 'app-endpoint',
  templateUrl: './endpoint.component.html',
  styleUrls: ['./endpoint.component.scss']
})
export class EndpointComponent implements OnInit, AfterViewInit {
  public store;
  public event = 'add';
  public data: any = {
    url: '',
    type: ''
  };
  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    this.store = store.getInstance();
    this.store.dispatch({ type: ADMIN_FETCH_ENDPOINT_REQUESTED, data: { id: this.activatedRoute.snapshot.params.id } });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onSubmit() {
    if (this.event === 'add') {
      this.store.dispatch({ type: ADMIN_CREATE_ENDPOINT_REQUESTED, data: _.assign({}, this.data, { retailer_id: +this.activatedRoute.snapshot.params.id }) });
    }
    if (this.event === 'update') {
      this.store.dispatch({ type: ADMIN_UPDATE_ENDPOINT_REQUESTED, data: _.assign({}, this.data, { retailer_id: +this.activatedRoute.snapshot.params.id }) });
    }
  }

  addNew() {
    this.event = 'add';
    this.setDefaultData();
    $('#myAdminEndpointModal').modal('show');
  }

  update(item) {
    this.event = 'update';
    this.data = {
      id: item.id,
      url: item.url,
      type: item.type
    };
    $('#myAdminEndpointModal').modal('show');
  }

  delete() {
    if (confirm('Are you sure to delete this Endpoint?')) {
      this.store.dispatch({ type: ADMIN_DELETE_ENDPOINT_REQUESTED, data: this.data });
    }
  }

  setDefaultData() {
    this.data = {
      url: '',
      type: ''
    };
  }
}
