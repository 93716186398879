import * as _ from 'lodash';
import * as c3 from 'c3';
import {
  RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_SUCCESS,
  RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS,
  RETAILER_REPURCHASE_DRAW_ITEMS_PUT_ON_SALE_CHART,
  FETCH_REPURCHASE_SUCCEEDED, FETCH_REPURCHASE_REQUESTED
} from './repurchase.const';
import { getItemsPutOnSaleData, getItemsSoldData, getXAxisData } from '../retailer.const';

export const Repurchase = (state: any = { putOnSaleChart: { fetched: false } }, action) => {
  switch (action.type) {
    case RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS:
      let putOnSaleChart = {
        fetched: true,
        numberOfRepurchase: getItemsPutOnSaleData(action.data),
        valuOfRepurchase: getItemsSoldData(action.data),
        xAxisData: getXAxisData(action.data)
      };
      // let putOnSaleChart = {
      //   fetched: true,
      //   numberOfRepurchase: [45, 39, 48, 54, 52, 56],
      //   valuOfRepurchase: [26480, 23216, 22112, 24376, 32288, 35464],
      //   xAxisData: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
      // };
      return _.assign({}, state, { putOnSaleChart: putOnSaleChart });
    case RETAILER_REPURCHASE_DRAW_ITEMS_PUT_ON_SALE_CHART:
      const chart = c3.generate({
        bindto: '#chart',
        data: {
          columns: [
            ['Number of Repurchase', ...state.putOnSaleChart.numberOfRepurchase],
            ['Value of Repurchase', ...state.putOnSaleChart.valuOfRepurchase]
          ],
          types: {
            'Number of Repurchase': 'area-spline',
            'Value of Repurchase': 'area-spline'
            // 'line', 'spline', 'step', 'area', 'area-step' are also available to stack
          },
          colors: {
            'Number of Repurchase': 'rgba(1, 192, 200, 0.92)',
            'Value of Repurchase': 'rgba(26, 188, 156, 0.93)'
          },
          groups: [['Number of Repurchase', 'Value of Repurchase']],
          axes: {
            'Number of Repurchase': 'y',
            'Value of Repurchase': 'y2'
          }
        },
        axis: {
          x: {
            type: 'category',
            categories: state.putOnSaleChart.xAxisData
          },
          y: {
            label: 'Number'
          },
          y2: {
            show: true,
            label: 'Value (in $)'
          }
        }
      });
      return _.assign({}, state);
    case RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_SUCCESS:
      return _.assign({}, state, { repurchaseInfor: action.data, repurchaseInforFetched: true });
    case FETCH_REPURCHASE_REQUESTED:
      return _.assign({}, state, { quicksight: null });
    case FETCH_REPURCHASE_SUCCEEDED:
      return _.assign({}, state, { quicksight: action.data });
    default:
      return state;
  }
};
