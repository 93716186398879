import * as _ from 'lodash';
import { GET_DECLARE_PRODUCT_SUCCEEDED, DECLARE_PRODUCT_NOT_AVAILABLE_SUCCEEDED } from './item-not-available.actions';

export const DeclareProductNotAvailable = (state: any = { fetched: false, declare: false }, action) => {
  switch (action.type) {
    case GET_DECLARE_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { fetched: true, product: action.data });
    case DECLARE_PRODUCT_NOT_AVAILABLE_SUCCEEDED:
      return _.assign({}, state, { declare: true });
    default:
      return state;
  }
};
