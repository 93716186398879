import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import ProductItem from '../../../models/ProductItem';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ProductItemService extends ServiceProvider {
  public url = '/api/v1/admin/product-item';
  public model = ProductItem;

  changeStatus(id, params): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/change-status`, params).pipe(
      map((result) => {}),
      catchError((error) => {
        throw error;
      })
    );
  }

  changeLocation(id, data): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/change-location`, data).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  confirmLost(id): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/confirm-lose`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}
