import Model from './Model';
import User from './User';


class BulkOperation extends Model {
  constructor(options) {
    super();
    (this as any).user = d => {
      return new User(d.data);
    };
    this.bind(options);
  }
}

export default BulkOperation;
