export const DEMO_FETCH_DATA__ADMIN_SHIPMENT_EDIT_REQUESTED = 'DEMO_FETCH_DATA__ADMIN_SHIPMENT_EDIT_REQUESTED';
export const DEMO_FETCH_DATA__ADMIN_SHIPMENT_EDIT_SUCCESSED = 'DEMO_FETCH_DATA__ADMIN_SHIPMENT_EDIT_SUCCESSED';
export const RENDER_SHIPMENT_EDIT_FORM = 'RENDER_SHIPMENT_EDIT_FORM';
export const FILL_FORM_SHIPMENT = 'FILL_FORM_SHIPMENT';
export const EDIT_SHIPMENT_REQUESTED = 'EDIT_SHIPMENT_REQUESTED';
export const EDIT_SHIPMENT_SUCCESSED = 'EDIT_SHIPMENT_SUCCESSED';
export const SHIPMENT_COMPONENT_FETCH_ALL_COURIERS_REQUESTED = 'SHIPMENT_COMPONENT_FETCH_ALL_COURIERS_REQUESTED';
export const SHIPMENT_COMPONENT_FETCH_ALL_COURIERS_SUCCESSED = 'SHIPMENT_COMPONENT_FETCH_ALL_COURIERS_SUCCESSED';
export const UPDATE_OPTION_INPUT_SHIPMENT = 'UPDATE_OPTION_INPUT_SHIPMENT';
export const SHIPMENT_EDIT_FORM_CHANGE = 'SHIPMENT_EDIT_FORM_CHANGE';
