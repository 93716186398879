import * as _ from 'lodash';
import { FETCH_ASYNC_PROCESSES_SUCCEEDED } from './asnyc-process.action';
import * as moment from 'moment';

export const AsyncProcess = (state: any = { fetched: false }, action) => {
  switch (action.type) {
    case FETCH_ASYNC_PROCESSES_SUCCEEDED:
      const newItems = _.map(action.data.items, (item) => {
        const created = item.created_at ? moment(item.created_at) : null;
        const updated = item.updated_at ? moment(item.updated_at) : null;
        if (created && updated) {
          item.duration = updated.diff(created, 'seconds');
        }
      });
      return _.assign(state, { items: action.data.items, pagination: action.data.pagination, fetched: true} );
    default:
      return state;
  }
};
