import Category from './category/category.saga';
import Designer from './designer/designer.saga';
import Size from './size/size.saga';
import Condition from './condition/condition.saga';
import Color from './color/color.saga';
import Material from './material/material.saga';
import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import {
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_SUCCESS, ADMIN_ATTRIBUTES_PRESELECT_MARKETPLACE,
  ADMIN_ATTRIBUTES_SELECT_ANY,
  ADMIN_ATTRIBUTES_SELECT_MARKETPLACE
} from './attributes.action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import Cookies from 'js-cookie';

function* watchFetchAllMarketplacesRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_REQUEST, fetchAllMarketplaces);
}

function* fetchAllMarketplaces(action: any) {
  const api = AppInjector.get(ApiService);
  // const fetched = yield select((state: any) => state.Admin.Attributes.all.marketplace.fetched);

  // if (!fetched) {
  try {
    const result = yield api.user.list(action.data).toPromise();
    const integrated_marketplace = yield AppInjector.get(ApiService).admin.user.getMarketplaces().toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACES_SUCCESS, data: result, integrated_marketplace: integrated_marketplace });
    if (action.auto_select_email) {
      const selectedMarketplace = integrated_marketplace.find(marketplace => marketplace.email === action.auto_select_email);
      if (selectedMarketplace) {
        yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selectedMarketplace, direct: true, component: action.component });
        yield put({ type: ADMIN_ATTRIBUTES_PRESELECT_MARKETPLACE, data: selectedMarketplace, direct: true, component: action.component });
      }
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
  // }
}

function* runChanges(action: any) {
  yield put({ type: ADMIN_ATTRIBUTES_SELECT_ANY, data: action.data, direct: action.direct, component: action.component });
}

function* watchMarketplaceChangedRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, runChanges);
}

export default [...Category, ...Designer, ...Size, ...Condition, ...Color, ...Material, watchFetchAllMarketplacesRequest, watchMarketplaceChangedRequest];
