import * as _ from 'lodash';
import { FETCH_ALL_PRODUCT_IN_COLLECTION_SUCCEEDED, GET_ALL_RETAILER_IN_COLLECTION_PRODUCT_SUCCESSED } from './list.action';
export const list = (state = { fetched: false, item: {}, items: [], items_status: [] }, action) => {
  switch (action.type) {
    case FETCH_ALL_PRODUCT_IN_COLLECTION_SUCCEEDED:
      return _.assign({}, state, {
        fetched: true,
        items: action.data,
        pagination: action.pagination,
        items_status: _.map(action.data, (item) => {
          return {
            id: item.id,
            status_id: item.product_items.length ? item.product_items[0].status_id : 0,
            product_item_id: item.product_items.length ? item.product_items[0].id : 0
          };
        })
      });
    case GET_ALL_RETAILER_IN_COLLECTION_PRODUCT_SUCCESSED:
      return _.assign({}, state, {
        fetched: true,
        retailer: action.data
      });
    default:
      return state;
  }
};
