import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { GET_DETAIL_CUSTOMER_REQUESTED, GET_DETAIL_CUSTOMER_SUCCESSED } from './detail.actions';

function* getDetailCustomer(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.user.getItemById(action.data).toPromise();
    yield put({ type: GET_DETAIL_CUSTOMER_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetDetailCustomerRequest() {
  yield takeEvery(GET_DETAIL_CUSTOMER_REQUESTED, getDetailCustomer);
}

export default [watchGetDetailCustomerRequest];
