import { Component, OnInit, forwardRef, Input, ElementRef, Renderer } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'custom-switch-input',
  templateUrl: './custom-switch-input.component.html',
  styleUrls: ['./custom-switch-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSwitchInputComponent),
      multi: true
    }
  ]
})
export class CustomSwitchInputComponent implements OnInit, ControlValueAccessor {
  @Input() _value;
  private onChange: Function;
  private disabled: boolean;
  private onTouched: Function;

  constructor(private element: ElementRef, private renderer: Renderer) {
    this.onChange = (val: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  writeValue(val: any): void {
    if (val) {
      this.value = val;
    }
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(this._value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
  }

  FieldsChange(values: any) {
    this.value = values.currentTarget.checked;
  }
}
