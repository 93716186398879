import * as _ from 'lodash';
import { RETAILER_GET_DETAIL_CUSTOMER_SUCCEEDED } from './detail.actions';

export const detailUser = (
  state = {
    fetched: false
  },
  action
) => {
  switch (action.type) {
    case RETAILER_GET_DETAIL_CUSTOMER_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });
    default:
      return state;
  }
};
