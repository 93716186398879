import {
  EDIT_ADMIN_ORDER_SUCCESSED,
  FETCH_ADMIN_ORDER_SUCCESSED,
  RENDER_EDIT_ADMIN_ORDER_FORM_REQUESTED,
  FILL_ADMIN_ORDER_DETAIL_FORM,
  UPDATE_ADMIN_ORDER_DETAIL_FORM_INPUT_OPTIONS
} from './edit.actions';
import * as _ from 'lodash';
import { support } from '@reflaunt/reactive-form';
import { UpdateFormValue, UpdateInputsValue } from '@reflaunt/reactive-form';

export const edit = (state = { updated: false, fetched: false, inputs: [] }, action) => {
  switch (action.type) {
    case RENDER_EDIT_ADMIN_ORDER_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_ADMIN_ORDER_DETAIL_FORM:
      state.inputs = support.UpdateFormValue(state.inputs, action.data);
      // const currency_input = _.find(state.inputs, (e) => e.key === 'currency');
      // currency_input.value = _.find(currency_input.options, (e) => e.value === action.data.currency_id);
      const status_input = _.find(state.inputs, (e) => e.key === 'status');
      status_input.value = _.find(status_input.options, (e) => e.value === action.data.status);
      return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_ADMIN_ORDER_DETAIL_FORM_INPUT_OPTIONS:
      return _.assign({}, state, { inputs: UpdateInputsValue(state.inputs, action.data) });
    case FETCH_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, { fetched: true, item: action.data });

    case EDIT_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, { updated: true });

    default:
      return state;
  }
};
