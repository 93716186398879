import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FETCH_TRANSACTION_HISTORY_SELLER_REQUESTED } from './transaction-history.actions';
import { editRouter } from '../../product/product.const';
declare const $: any;

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent extends BaseComponent implements OnInit {
  public reducer = 'Admin.AdminCustomer.TransactionHistory';
  public supParams = ['sort', 'constraints', 'page', 'per_page', 'search'];
  public editRouter = editRouter;
  constructor(private router: Router, private route: ActivatedRoute) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let data: any = this.queryParser.parse(this.supParams);
        this.store.dispatch({
          type: FETCH_TRANSACTION_HISTORY_SELLER_REQUESTED,
          data: data,
          sellerId: this.route.snapshot.paramMap.get('id')
        });
      }
    });
  }

  subItem(id) {
    if ($('.sub-item-' + id).hasClass('active-item')) {
      $('.sub-item-' + id).removeClass('active-item');
    } else {
      $('.sub-item-' + id).addClass('active-item');
    }
  }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return { payload: state.Admin.AdminCustomer.TransactionHistory };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
