export const FETCH_RETAILER_MATERIALS_SUCCESS = 'FETCH_RETAILER_MATERIALS_SUCCESS';
export const ADMIN_ATTRIBUTES_RETAILER_MATERIAL_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_RETAILER_MATERIAL_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_RETAILER_MATERIAL_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_RETAILER_MATERIAL_UPDATE_DICTIONARY_SUCCESS';

export const ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_MATERIAL_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_MATERIAL_REQUESTED';
export const TOGGLE_MATERIAL_RETAILER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_MATERIAL_RETAILER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_MATERIAL_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_MATERIAL_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED';

export const DELETE_RETAILER_MATERIAL_MATCHING_REQUESTED = 'DELETE_RETAILER_MATERIAL_MATCHING_REQUESTED';
export const ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_MATERIAL_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_MATERIAL_REQUESTED';
