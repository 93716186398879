import { combineReducers } from 'redux';
import { Product } from './product/product.reducer';
import { RetailerUsers } from './user/user.reducer';
import { Dataset } from './dataset/dataset.reducer';
import { Percentage } from './percentage/percentage.reducer';
import { Resale } from './resale/resale.reducer';
import { SecondHandSales } from './second-hand-sales/second-hand-sales.reducer';
import { Repurchase } from './repurchase/repurchase.reducer';
import { Endpoint } from './endpoint/endpoint.reducer';
import { SmartButton } from './smart-button/smart-button.reducer';
import { Analytic } from './analytic/analytic.reducer';

export const Retailer = combineReducers({
  Product,
  RetailerUsers,
  Dataset,
  Percentage,
  Resale,
  SecondHandSales,
  Repurchase,
  Endpoint,
  SmartButton,
  Analytic
});
