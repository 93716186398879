import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import Image from '../../../models/Image';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
@Injectable()
export class ImageService extends ServiceProvider {
  public url = '/api/v1/admin/images';
  public model = Image;

  update(id, data): Observable<any> {
      return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/retouched`, {data: data}).pipe(
      map(result => {
        return _.map((result as any), item => new this.model(item));
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  updateImagesByPosition(imagesArray): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/position`, {imagesArray}).pipe(
      map(result => {
        return _.map((result as any), item => new this.model(item));
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  deleteImageById(id): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}`, {status: 0}).pipe(
      map(result => {
        return _.map((result as any), item => new this.model(item));
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  downloadListImage(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/download-list-image`), JSON.stringify(params)).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => {
        return result;
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  retouchImageAll(data): Observable<any> {
    const url = '/api/v1/upload/retouch';
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(url), data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
