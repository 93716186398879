import Model from './Model';
import * as _ from 'lodash';
class MeasurementUnit extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default MeasurementUnit;
