export const RENDER_BULK_PUSH_FORM_REQUESTED = 'RENDER_BULK_PUSH_FORM_REQUESTED';
export const UPDATE_BULK_PUSH_INPUT_OPTIONS = 'UPDATE_BULK_PUSH_INPUT_OPTIONS';

export const BULK_PUSH_PRODUCT_REQUESTED = 'BULK_PUSH_PRODUCT_REQUESTED';
export const BULK_PUSH_PRODUCT_SUCCEEDED = 'BULK_PUSH_PRODUCT_SUCCEEDED';

export const DELIST_PRODUCT_REQUESTED = 'DELIST_PRODUCT_REQUESTED';
export const DELIST_PRODUCT_SUCCEEDED = 'DELIST_PRODUCT_SUCCEEDED';

export const FETCH_BULK_OPERATION_DATA_REQUESTED = 'FETCH_BULK_OPERATION_DATA_REQUESTED';
export const FETCH_BULK_OPERATION_DATA_SUCCEEDED = 'FETCH_BULK_OPERATION_DATA_SUCCEEDED';

export const BULK_ENABLE_PRODUCT_REQUESTED = 'BULK_ENABLE_PRODUCT_REQUESTED';
export const BULK_ENABLE_PRODUCT_SUCCESSED = 'BULK_ENABLE_PRODUCT_SUCCESSED';

export const BULK_DISABLE_PRODUCT_REQUESTED = 'BULK_DISABLE_PRODUCT_REQUESTED';
export const BULK_DISABLE_PRODUCT_SUCCESSED = 'BULK_DISABLE_PRODUCT_SUCCESSED';
