export const RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST = 'RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST';
export const RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS = 'RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS';
export const RETAILER_RESALE_DRAW_ITEMS_PUT_ON_SALE_CHART = 'RETAILER_RESALE_DRAW_ITEMS_PUT_ON_SALE_CHART';
export const RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST = 'RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST';
export const RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS = 'RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS';
export const RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_REQUEST = 'RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_REQUEST';
export const RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_SUCCESS = 'RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_SUCCESS';
export const RETAILER_RESALE_GET_AVG_PRICE_DATA_REQUEST = 'RETAILER_RESALE_GET_AVG_PRICE_DATA_REQUEST';
export const RETAILER_RESALE_GET_AVG_PRICE_DATA_SUCCESS = 'RETAILER_RESALE_GET_AVG_PRICE_DATA_SUCCESS';
export const RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_REQUEST = 'RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_REQUEST';
export const RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_SUCCESS = 'RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_SUCCESS';
export const RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_REQUEST = 'RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_REQUEST';
export const RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_SUCCESS = 'RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_SUCCESS';

export const FETCH_RESALE_ACTIVITTY_REQUESTED = 'FETCH_RESALE_ACTIVITTY_REQUESTED';
export const FETCH_RESALE_ACTIVITTY_SUCCEEDED = 'FETCH_RESALE_ACTIVITTY_SUCCEEDED';
