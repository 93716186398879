import Model from './Model';

class Zone extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default Zone;
