export const ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_REQUESTED = 'ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_REQUESTED';
export const ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_SUCCESSED = 'ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_SUCCESSED';
export const DELETE_CONDITION_ATTRIBUTE_REQUESTED = 'DELETE_CONDITION_ATTRIBUTE_REQUESTED';
export const DELETE_CONDITION_ATTRIBUTE_SUCCESSED = 'DELETE_CONDITION_ATTRIBUTE_SUCCESSED';
export const FETCH_CONDITION_DICTIONARY_REQUESTED = 'FETCH_CONDITION_DICTIONARY_REQUESTED';
export const FETCH_CONDITION_DICTIONARY_SUCCESSED = 'FETCH_CONDITION_DICTIONARY_SUCCESSED';
export const CREATE_CONDITION_DICTIONARY_SUCCESSED = 'CREATE_CONDITION_DICTIONARY_SUCCESSED';
export const RESET_CONDITION_DICTIONARY = 'RESET_CONDITION_DICTIONARY';
export const EDIT_CONDITION_DICTIONARY_REQUESTED = 'EDIT_CONDITION_DICTIONARY_REQUESTED';
export const EDIT_CONDITION_DICTIONARY_SUCCEEDED = 'EDIT_CONDITION_DICTIONARY_SUCCEEDED';
export const ADMIN_ATTRIBUTES_CONDITION_FILTER_SELECTED = 'ADMIN_ATTRIBUTES_CONDITION_FILTER_SELECTED';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_SUCCESS';
export const ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_SUCCESS';
export const ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_REQUESTED = 'ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_REQUESTED';
export const ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_SUCCESS = 'ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_SUCCESS';
export const ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_REQUESTED = 'ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_REQUESTED';
export const ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_SUCCESS = 'ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_SUCCESS';
export const ADMIN_ATTRIBUTES_CONDITION_SYNCHRONISE_ATTRIBUTES_REQUEST = 'ADMIN_ATTRIBUTES_CONDITION_SYNCHRONISE_ATTRIBUTES_REQUEST';
export const TOGGLE_CONDITION_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_CONDITION_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_CONDITION_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_CONDITION_DICTIONARY_VALIDATION_SUCCEEDED';
