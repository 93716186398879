import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { FETCH_TRANSACTION_HISTORY_CUSTOMER_REQUESTED } from './transaction-history.actions';
import { editRouter } from '../product/product.const';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent extends BaseComponent implements OnInit {
  public reducer = 'Customer.TransactionsHistory';
  public supParams = ['sort', 'constraints', 'page', 'per_page', 'search'];
  public detailProductRouter = editRouter;
  constructor(private activatedRoute: ActivatedRoute, private route: Router) {
    super();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let data: any = this.queryParser.parse(this.supParams);
        this.store.dispatch({
          type: FETCH_TRANSACTION_HISTORY_CUSTOMER_REQUESTED,
          data: data
        });
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return { payload: state.Customer.TransactionsHistory };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
