import { DELETE_TAG_REQUESTED } from './../edit/edit.actions';
import { FETCH_TAGS_REQUESTED } from './list.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from './../../../../store/store.module';
import * as _ from 'lodash';
import { QueryParser } from '../../../../common/util/query-parser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  protected navigationSubscription: any;
  protected router: any;
  public store;

  constructor(store: Store, private queryParser: QueryParser, private activedRoute: ActivatedRoute, router: Router) {
    this.store = store.getInstance();
    this.activedRoute = activedRoute;
    this.router = router;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({ type: FETCH_TAGS_REQUESTED, data: this.queryParser.parse(['sort', 'page', 'per_page']) });
      }
    });
  }

  ngOnInit() {
    // this.store.dispatch({ type: FETCH_TAGS_REQUESTED });
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  delete(id) {
    this.store.dispatch({ type: DELETE_TAG_REQUESTED, data: id });
  }
}
