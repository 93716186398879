export const RENDER_FILL_CONFIRM_ADDRESS_FORM_REQUESTED = 'RENDER_FILL_CONFIRM_ADDRESS_FORM_REQUESTED';
export const RENDER_FILL_CONFIRM_ADDRESS_FORM_SUCCEEDED = 'RENDER_FILL_CONFIRM_ADDRESS_FORM_SUCCEEDED';

export const GET_SELLER_CONFIRM_ADDRESS_REQUESTED = 'GET_SELLER_CONFIRM_ADDRESS_REQUESTED';
export const GET_SELLER_CONFIRM_ADDRESS_SUCCEEDED = 'GET_SELLER_CONFIRM_ADDRESS_SUCCEEDED';

export const UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED = 'UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED';

export const SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_REQUESTED = 'SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_REQUESTED';
export const SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_SUCCEEDED = 'SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_SUCCEEDED';
export const UPDATE_INPUTS_ADDRESS_LANDING_PAGE_REQUESTED = 'UPDATE_INPUTS_ADDRESS_LANDING_PAGE_REQUESTED';
export const UPDATE_ALPHA_2_CODE_VALUE_ADDRESS_LANDINGPAGE_REQUESTED = 'UPDATE_ALPHA_2_CODE_VALUE_ADDRESS_LANDINGPAGE_REQUESTED';
