import { days } from './../dataset.const';
import {
  EDIT_DATASET_SUCCEEDED,
  GET_DATASET_SUCCEEDED,
  DATASET_EDIT_COMPONENT,
  EDIT_CONDITION_MODIFIED,
  EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED,
  RESET_STATE_EDIT_DATASET,
  BINDING_DATASET_DATA_SUCCESS
} from './edit.actions';
import * as _ from 'lodash';
import { GET_ALL_DATASET_CONDITIONS_SUCCEEDED, GET_ALL_DATASET_OBJECTS_SUCCEEDED, GET_ALL_DATASET_OPERATORS_SUCCEEDED } from '../dataset.action';
import { selectDatasetOperator } from '../dataset.const';
import * as moment from 'moment';

export const edit = (
  state = {
    updated: false,
    fetched: false,
    inputs: [],
    dataset_conditions: {
      fetched: false,
      items: []
    },
    dataset_objects: {
      fetched: false,
      items: []
    },
    dataset_operators: {
      fetched: false,
      items: []
    },
    selected_dates: [],
    old_selected_dates:[],
    state_fetched: true
  },
  action
) => {
  switch (action.type) {
    case GET_DATASET_SUCCEEDED:
      let dataCheck = action.data.selected_dates ? action.data.selected_dates.split(',') : [];
      let selected_dates = [];
      let old_selected_dates = [];
      days.map((d) => {
        if (dataCheck.includes(d.value)) {
          d.checked = true;
          old_selected_dates.push(d.value)
        }
        selected_dates.push(d);
      });
      return _.assign({}, state, {
        fetched: true,
        item: { ...action.data, start_update_at: moment(action.data.start_update_at, 'HH:mm').toISOString() },
        selected_dates: selected_dates,
        old_selected_dates: old_selected_dates
      });

    case EDIT_DATASET_SUCCEEDED:
      return _.assign({}, state, { updated: true });

    case GET_ALL_DATASET_CONDITIONS_SUCCEEDED:
      if (!_.isUndefined(action.component)) {
        switch (action.component) {
          case DATASET_EDIT_COMPONENT:
            action.data = _.map(action.data, (condition, key) => {
              return _.assign(condition, { index: key, constants: [], changed: false });
            });
            return _.assign({}, state, { dataset_conditions: { fetched: true, items: action.data } });
        }
      }
      return state;

    case GET_ALL_DATASET_OBJECTS_SUCCEEDED:
      if (!_.isUndefined(action.component)) {
        switch (action.component) {
          case DATASET_EDIT_COMPONENT:
            return _.assign({}, state, { dataset_objects: { fetched: true, items: action.data } });
        }
      }
      return state;

    case GET_ALL_DATASET_OPERATORS_SUCCEEDED:
      if (!_.isUndefined(action.component)) {
        switch (action.component) {
          case DATASET_EDIT_COMPONENT:
            return _.assign({}, state, { dataset_operators: { fetched: true, items: action.data } });
        }
      }
      return state;
    case EDIT_CONDITION_MODIFIED:
      state.dataset_conditions.items = _.map(state.dataset_conditions.items, (condition) => _.assign(condition, { changed: false }));
      const default_operator = _.head(state.dataset_operators.items);
      let current_condition: any = state.dataset_conditions.items[action.data.condition_index];
      current_condition.dataset_operator = default_operator;
      current_condition.changed = true;
      selectDatasetOperator(default_operator, current_condition);
      return _.assign({}, state);
    case EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED:
      let condition: any = _.find(state.dataset_conditions.items, (i) => i.changed);
      if (condition) {
        condition = _.assign(condition, { constants: action.constants || [], changed: !condition.changed, fetched: true });
      }
      return _.assign({}, state);
    case RESET_STATE_EDIT_DATASET:
      return _.assign({}, state, {
        dataset_conditions: {
          fetched: false,
          items: []
        },
        state_fetched: false
      });
    case BINDING_DATASET_DATA_SUCCESS:
      return _.assign({}, state, { state_fetched: true });
    default:
      return state;
  }
};
