import {
  FETCH_RETAILER_DESIGNERS_SUCCESS,
  ADMIN_ATTRIBUTES_RETAILER_DESIGNER_UPDATE_DICTIONARY_SUCCESS,
  TOGGLE_DESIGNER_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED
} from './designer.action';
import * as _ from 'lodash';

export const Designer = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    },
    rf_designers: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_RETAILER_DESIGNERS_SUCCESS:
      return _.assign({}, state, {
        items: _.map(action.data, i => {
          i.attrDefaultMap = _.find(action.rf_designers, o => o.id === i.attribute_id);
          return i;
        }),
        rf_designers: action.rf_designers,
        pagination: action.pagination
      });
    case ADMIN_ATTRIBUTES_RETAILER_DESIGNER_UPDATE_DICTIONARY_SUCCESS:
      return _.assign({}, state, {
        items: _.map(state.items, i => {
          if (i.id === action.data.id) {
            delete i.is_show_edit_dictionary_form;
          }
          return i;
        })
      });
    case TOGGLE_DESIGNER_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED:
      return _.assign({}, state, {
        items: _.map(state.items, cat => {
          if (cat.id === action.data.id) {
            return {
              ...cat,
              is_validated: action.data.is_validated
            };
          } else {
            return {
              ...cat
            }
          }
        })
      });
    default:
      return state;
  }
};
