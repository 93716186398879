import * as _ from 'lodash';
import { FETCH_VOUCHER_CUSTOMER_SUCCEEDED, ADD_ALL_VOUCHER_IN_PAGE, REMOVE_ALL_VOUCHER_IN_PAGE, ADD_OR_REMOVE_SELECTED_VOUCHER } from './list.actions';

export const listVoucher = (
  state = {
    fetched: false,
    loading: false,
    items: [],
    pagination: {},
    selected_voucher: {},
    deleted: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_VOUCHER_CUSTOMER_SUCCEEDED:
      let data_vouchers = action.data;
      data_vouchers.forEach((voucher) => {
        if (voucher.is_manual_voucher === false) {
          voucher.code = '';
        }
      });
      return _.assign({}, state, { fetched: true, items: data_vouchers, pagination: action.pagination });
    case ADD_ALL_VOUCHER_IN_PAGE:
      const voucher_to_be_added = action.data;
      const productObject = voucher_to_be_added.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      const finalProduct = {
        ...state.selected_voucher,
        ...productObject
      };
      return _.assign({}, state, {
        selected_voucher: {
          ...finalProduct
        },
        selected_voucher_count: Object.keys(finalProduct).length
      });
    case REMOVE_ALL_VOUCHER_IN_PAGE:
      const voucher_to_be_removed = action.data;
      const current_selected_voucher = state.selected_voucher;
      const voucher_after_removed = _.omit(
        current_selected_voucher,
        voucher_to_be_removed.map((x) => x.id)
      );
      return _.assign({}, state, {
        selected_voucher: {
          ...voucher_after_removed
        },
        selected_voucher_count: Object.keys(voucher_after_removed).length
      });
    case ADD_OR_REMOVE_SELECTED_VOUCHER:
      const product = action.data;
      const total = Object.keys(state.selected_voucher).length;
      if (!state.selected_voucher[product.id]) {
        return _.assign({}, state, {
          selected_voucher: {
            ...state.selected_voucher,
            [product.id]: product
          },
          selected_voucher_count: total + 1
        });
      } else {
        const current_voucher = state.selected_voucher;
        return _.assign({}, state, {
          selected_voucher: {
            ..._.omit(current_voucher, product.id)
          },
          selected_voucher_count: total - 1
        });
      }
    default:
      return state;
  }
};
