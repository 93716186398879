import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Style from '../../models/Style';

@Injectable()
export class StyleService extends ServiceProvider {

  public url = '/api/v1/styles';
  public model = Style;

}
