import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import {
  RENDER_BULK_DISCOUNT_FORM_REQUESTED,
  BULK_DISCOUNT_PRODUCT_REQUESTED,
  DELIST_PRODUCT_DISCCOUNT_COM_REQUESTED,
  FETCH_BULK_OPERATION_DATA_REQUESTED,
  UPDATE_CURRENCY_LABEL
} from './bulk-discount.actions';
import { Validators } from '@angular/forms';
import { Select2, InputBase, TextBox, Dropdown } from '@reflaunt/reactive-form';
import * as _ from 'lodash';
import { listRouter } from '../product.const';
import { BaseComponent } from '../../../base.component';
import { MARKETPLACE_DISCOUNT_TYPES, PAYOUT_SUPPLIER } from '../../../../models/Marketplace';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { AppInjector } from '../../../../app-injector';
import { SingleSelect2 } from '@reflaunt/reactive-form';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-bulk-discount',
  templateUrl: './bulk-discount.component.html',
  styleUrls: ['./bulk-discount.component.scss']
})
export class BulkDiscountComponent extends BaseComponent implements OnInit {
  public submitted = false;
  public count;
  public product_ids;
  public pushForm;
  public mandatoryField = ['discount_type'];
  public listRouter = listRouter();
  public reducer = 'Admin.Product.bulkDiscount';
  public paramsList = ['sort', 'constraints', 'page', 'per_page', 'search'];

  constructor(private router: Router, private notification: NotificationService) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let data: any = this.queryParser.parse(this.paramsList);
        this.dispatch({ type: FETCH_BULK_OPERATION_DATA_REQUESTED, data });
      }
    });
  }

  ngOnInit() {
    this.init();
    let inputs: InputBase<any>[] = [
      new Dropdown({
        key: 'discount_type',
        label: 'Discount Type',
        classes: ['col-6', 'rf-form-group', 'input--select'],
        onChange: this.discountTypeChange,
        options: MARKETPLACE_DISCOUNT_TYPES,
        group_classes: ['col-12'],
        order: 1,
        group: 1
      }),
      new Dropdown({
        key: 'marketplace_id',
        label: 'marketplace',
        classes: ['col-12', 'rf-form-group', 'input--select2'],
        group_classes: ['col-12'],
        order: 1,
        onChange: this.marketplaceChange,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TextBox({
        key: 'amount',
        label: 'EUR',
        type: 'number',
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'percent',
        label: '%',
        type: 'number',
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      })
    ];
    this.dispatch({
      type: RENDER_BULK_DISCOUNT_FORM_REQUESTED,
      data: { inputs: inputs }
    });
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    this.product_ids = productSelected ? Object.keys(productSelected) : [];
    this.count = productSelected ? Object.keys(productSelected).length : 0;
  }

  onSubmit = (form) => {
    this.submitted = true;
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    let data = _.cloneDeep(form.value);
    const product_ids = Object.keys(productSelected);
    if (form.valid && product_ids && product_ids.length > 0) {
      if ((_.isNil(data.amount) || data.amount == '') && (_.isNil(data.percent) || data.percent == '')) {
        this.notification.show('warning', 'Please input value in EUR or % field!', 3000);
        this.submitted = false;
        return false;
      }
      if (!_.isNil(data.amount) && data.amount !== '' && !_.isNil(data.percent) && data.percent !== '') {
        this.notification.show('warning', 'You cannot input value in both EUR and % field, please choose only one!', 3000);
        this.submitted = false;
        return false;
      }
      if (data.percent >= 100) {
        this.notification.show('warning', 'You cannot input percent greater than 100!', 3000);
        this.submitted = false;
        return false;
      }
      if (!_.isNil(data.discount_type) && data.discount_type.value) {
        data.discount_type = data.discount_type.value;
        if (data.discount_type === PAYOUT_SUPPLIER) {
          delete data.marketplace_id;
        }
      }
      if (!_.isNil(data.marketplace_id) && data.marketplace_id.value) {
        data.marketplace_id = data.marketplace_id.value;
      }
      data.product_ids = product_ids;
      this.dispatch({
        type: BULK_DISCOUNT_PRODUCT_REQUESTED,
        data: data
      });
      this.submitted = false;
    } else {
      AppInjector.get(NotificationService).show('warning', 'Product cannot be empty', 3000);
      this.submitted = false;
    }
  };

  discountTypeChange = (item) => {
    if (item && item.value && !this.submitted) {
      let currency_input = _.find(this.payload.inputs, (i) => i.key === 'amount');
      currency_input.label = item.value === PAYOUT_SUPPLIER ? 'USD' : 'EUR';
      _.map(this.payload.inputs, (i) => {
        if (i.key === 'marketplace_id') {
          if (item.value === PAYOUT_SUPPLIER) {
            this.pushForm.get('marketplace_id').clearValidators();
            this.pushForm.get('marketplace_id').updateValueAndValidity();
            i.classes.push('mkt_input_disabled');
          } else {
            this.pushForm.get('marketplace_id').setValidators([Validators.required]);
            this.pushForm.get('marketplace_id').updateValueAndValidity();
            _.remove(i.classes, (i) => i === 'mkt_input_disabled');
          }
        }
        return i;
      });
    }
  };

  onChangeReative = (form) => {
    this.pushForm = form;
  };

  marketplaceChange = (newVal) => {
    if (newVal && !this.submitted) {
      this.store.dispatch({ type: UPDATE_CURRENCY_LABEL, data: newVal });
    }
  };

  mapStateToProps(state) {
    return { payload: state.Admin.Product.bulkDiscount };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
