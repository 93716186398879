export const FETCH_CONDITION_ATTRIBUTE_REQUESTED = 'FETCH_CONDITION_ATTRIBUTE_REQUESTED';
export const FETCH_CONDITION_ATTRIBUTE_SUCCESSED = 'FETCH_CONDITION_ATTRIBUTE_SUCCESSED';
export const CREATE_CONDITION_REQUESTED = 'CREATE_CONDITION_REQUESTED';
export const CREATE_CONDITION_SUCCESSED = 'CREATE_CONDITION_SUCCESSED';
export const FETCH_CONDITION_DICTIONARY_REQUESTED = 'FETCH_CONDITION_DICTIONARY_REQUESTED';
export const FETCH_CONDITION_DICTIONARIES_SUCCESSED = 'FETCH_CONDITION_DICTIONARIES_SUCCESSED';
export const RESET_CONDITION_DICTIONARY = 'RESET_CONDITION_DICTIONARY';
export const CREATE_CONDITION_DICTIONARY_SUCCESSED = 'CREATE_CONDITION_DICTIONARY_SUCCESSED';
export const DELETE_CONDITION_ATTRIBUTE_REQUESTED = 'DELETE_CONDITION_ATTRIBUTE_REQUESTED';
export const DELETE_CONDITION_ATTRIBUTE_SUCCESSED = 'DELETE_CONDITION_ATTRIBUTE_SUCCESSED';
export const EDIT_CONDITION_REQUESTED = 'EDIT_CONDITION_REQUESTED';
export const EDIT_CONDITION_SUCCESSED = 'EDIT_CONDITION_SUCCESSED';
export const EDIT_CONDITION_DICTIONARY_REQUESTED = 'EDIT_CONDITION_DICTIONARY_REQUESTED';
export const EDIT_CONDITION_DICTIONARY_SUCCESSED = 'EDIT_CONDITION_DICTIONARY_SUCCESSED';
