import Model from './Model';
import * as _ from 'lodash';

export enum AttributeCondition {
  default = 'default',
  new_with_tag = 'new_with_tag',
}
class Attribute extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }

  getIsOldSizeDisplay() {
    const item = this as any;
    return item.is_old_size ? 'True' : '';
  }
}

export default Attribute;
