import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import ItemsCost from '../../../models/ItemsCost';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class ItemsCostService extends ServiceProvider {
  public url = '/api/v1/cost-settings';
  public model = ItemsCost;


  updateOrCreate(data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/updateOrCreate', data).pipe(
      map(result => {
        return new this.model((result as any).data);
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  exportCsv(data?): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/export`, data).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map((result) => {
        return result;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  uploadCsv(data): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/upload`, data).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map((result) => {
        return result;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
}
