import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  ADMIN_CONFIGURATION_FETCH_MARKETPLACE_REQUESTED,
  ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED,
  ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED,
  ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE,
  ADMIN_SAVE_INTEGRATION_CONFIGURATION_REQUESTED,
  ADMIN_SAVE_INTEGRATION_CONFIGURATION_SUCCESSED
} from './edit-action.actions';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchAdminFetchDatasetRequested() {
  yield takeLatest(ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.dataset.getAllByRetailer(action.data.retailer.id).toPromise();
      yield put({ type: ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminFetchIntegrationRequested() {
  yield takeLatest(ADMIN_CONFIGURATION_FETCH_MARKETPLACE_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.integration.getAllByRetailer(action.data.retailer_id, { constraints: action.data }).toPromise();
      yield put({ type: ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED, data: _.head(result) });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminFetchDatasetSuccess() {
  yield takeLatest(ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED, function* (action: any) {
    const admin_integration_meta = yield select((state: any) => state.Admin.Integration.editAction.item.meta);
    yield put({ type: ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE, data: admin_integration_meta });
  });
}

function* watchAdminSaveIntegrationConfigurationRequested() {
  yield takeLatest(ADMIN_SAVE_INTEGRATION_CONFIGURATION_REQUESTED, function* (action: any) {
    try {
      const data = _.assign({}, action.data, {
        marketplace_id: action.marketplace_id,
        markup: action.markup,
        dataset_markups: action.dataset_markups
      });
      const result = yield AppInjector.get(ApiService).admin.integration.configuration(action.retailer_id, data).toPromise();
      yield put({ type: ADMIN_SAVE_INTEGRATION_CONFIGURATION_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminSaveIntegrationConfigurationSuccessed() {
  yield takeLatest(ADMIN_SAVE_INTEGRATION_CONFIGURATION_SUCCESSED, function* () {
    AppInjector.get(NotificationService).show('success', 'Setting Saved', 3000);
  });
}

export default [
  watchAdminFetchIntegrationRequested,
  watchAdminFetchDatasetRequested,
  watchAdminFetchDatasetSuccess,
  watchAdminSaveIntegrationConfigurationRequested,
  watchAdminSaveIntegrationConfigurationSuccessed
];
