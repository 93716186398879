import { CustomerGroupService } from './customer-group/customer-group.service';
import { PermissionService } from './permission/permission.service';
import { RoleService } from './role/role.service';
import { StyleService } from './style/style.service';
import { SizeService } from './size/size.service';
import { CustomerService } from './customer/customer.service';
import { ProductService } from './product/product.service';
import { ApiUrl } from './api-url.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { OrderService } from './order/order.service';
import { UserService } from './user/user.service';
import { CategoryService } from './category/category.service';
import { StatusService } from './status/status.service';
import { CustomerAddressService } from './customer-address/customer-address.service';
import { ConstantService } from './constant/constant.service';
import { ProviderService } from './provider/provider.service';
import { CurrencyService } from './currency/currency.service';
import { AnalyticService } from './analytic.service';
import { BroadcastService } from './broadcast/broadcast.service';
import { MediaService } from './media/media.service';
import { RetailerService } from './retailer/retailer.service';
import { DatasetService } from './dataset/dataset.service';
import { DatasetOperatorService } from './dataset-operator/dataset-operator.service';
import { DatasetObjectService } from './dataset-object/dataset-object.service';
import { DatasetConditionService } from './dataset-condition/dataset-condition.service';
import { DesignerService } from './designer/designer.service';
import { ConditionService } from './condition/condition.service';
import { ColorService } from './color/color.service';
import { AdminApiModule } from './admin/admin.module';
import { IntegrationService } from './integration/integration.service';
import { FileService } from './file/file.service';
import { TagService } from './tag/tag.service';
import { WalletService } from './wallet/wallet.service';
import { VoucherService } from './voucher/voucher.service';
import { TransactionHistoryService } from './transaction-history/transaction-history.service';
import { PercentageService } from './percentage/percentage.service';
import { BlockchainService } from './blockchain/blockchain.service';
import { MarketplaceService } from './marketplace/marketplace.service';
import { EndpointService } from './endpoint/endpoint.service';
import { OAuthClientService } from './oauth-client/oauth-client';
import { SmartButtonService } from './smart-button/smart-button.service';
import { ZoneService } from './admin/zone/zone.service';
import { ImageService } from './admin/image/image.service';
import { ExcelService } from './excel/excel.service';
import { AsyncProcessService } from './async-process/async_processes.service';
import { MaterialService } from './material/material.service';
import { AttributeService } from './attribute/attribute.service';
import { LanguageService } from './language/language.service';
import { CountryService } from './country/country.service';
import { ReflauntService } from './reflaunt/reflaunt.service';
import { BulkOperationService } from './admin/bulk-operation/bulk-operation.service';
import { MeasurementUnitService } from './measurement-unit/measurement-unit.service';
import { ModelService } from './model/model.service';

@NgModule({
  imports: [CommonModule, AdminApiModule],
  declarations: [],
  providers: [
    TagService,
    DatasetConditionService,
    DatasetObjectService,
    DatasetOperatorService,
    DatasetService,
    RetailerService,
    AsyncProcessService,
    ApiUrl,
    ApiService,
    AuthService,
    ProductService,
    ImageService,
    OrderService,
    UserService,
    CustomerService,
    CategoryService,
    SizeService,
    StyleService,
    StatusService,
    CustomerAddressService,
    RoleService,
    PermissionService,
    ConstantService,
    ProviderService,
    CustomerGroupService,
    AnalyticService,
    CurrencyService,
    BroadcastService,
    MediaService,
    DesignerService,
    ConditionService,
    ColorService,
    MeasurementUnitService,
    IntegrationService,
    FileService,
    WalletService,
    VoucherService,
    TransactionHistoryService,
    PercentageService,
    BlockchainService,
    MarketplaceService,
    EndpointService,
    OAuthClientService,
    SmartButtonService,
    ZoneService,
    ExcelService,
    MaterialService,
    AttributeService,
    LanguageService,
    CountryService,
    ReflauntService,
    BulkOperationService,
    ModelService
  ]
})
export class ApiModule {}
