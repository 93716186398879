import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from './../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';
import { CREATE_STATUS_REQUESTED } from './create.actions';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public store;
  public status: any = {};

  constructor(public activatedRoute: ActivatedRoute, store: Store) {
    this.store = AppInjector.get(Store).getInstance();
  }

  ngOnInit() {}

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      store.dispatch({ type: CREATE_STATUS_REQUESTED, data: this.status });
    }
  }
}
