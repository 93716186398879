import * as _ from 'lodash';
import {
  EDIT_GROUP_DATASET_ADD_DATASET_TO_GROUP,
  EDIT_GROUP_DATASET_DISABLE_ADD_DATASET_INPUT,
  EDIT_GROUP_DATASET_ENABLE_ADD_DATASET_INPUT,
  EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_SUCCESS,
  EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS,
  EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_SUCCESS,
  EDIT_GROUP_DATASET_INIT_FORM,
  EDIT_GROUP_DATASET_REMOVE_DATASET_TO_GROUP
} from './edit-group.actions';
import { Validators } from '@angular/forms';
import { uniqBy } from 'lodash';

export const editGroup = (
  state = {
    isEnableAddDatasetInput: false,
    dataset: { item: null, fetched: false },
    normalListDataset: { fetched: false, items: [] },
    children: { items: [], fetched: false },
    editGroupForm: null,
    addDatasetForm: null
  },
  action
) => {
  switch (action.type) {
    case EDIT_GROUP_DATASET_ENABLE_ADD_DATASET_INPUT: {
      return Object.assign({}, state, { isEnableAddDatasetInput: true });
    }
    case EDIT_GROUP_DATASET_DISABLE_ADD_DATASET_INPUT: {
      return Object.assign({}, state, { isEnableAddDatasetInput: false });
    }
    case EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_SUCCESS: {
      return Object.assign({}, state, { normalListDataset: { items: action.data, fetched: true } });
    }
    case EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS: {
      return Object.assign({}, state, { dataset: { item: action.data, fetched: true } });
    }
    case EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_SUCCESS: {
      return Object.assign({}, state, { children: { items: action.data, fetched: true } });
    }
    case EDIT_GROUP_DATASET_INIT_FORM: {
      const { result, formGroup } = action.data;
      const editGroupForm = formGroup.group({
        title: [result.title, Validators.required],
        is_active: [result.is_active]
      });
      const addDatasetForm = formGroup.group({
        datasets: ['']
      });
      return Object.assign({}, state, { editGroupForm, addDatasetForm });
    }
    case EDIT_GROUP_DATASET_ADD_DATASET_TO_GROUP: {
      const { datasets } = action.data;
      const childrenItems = datasets.concat(Object.assign([], state.children.items));
      return Object.assign({}, state, {
        children: Object.assign({}, state.children, { items: uniqBy(childrenItems, (item: any) => item.id) }),
        isEnableAddDatasetInput: false
      });
    }
    case EDIT_GROUP_DATASET_REMOVE_DATASET_TO_GROUP: {
      const datasetId = action.data;
      const childrenItems = Object.assign([], state.children.items).filter((child) => child.id !== datasetId);
      return Object.assign({}, state, {
        children: Object.assign({}, state.children, { items: childrenItems })
      });
    }
    default:
      return Object.assign(state, {
        isEnableAddDatasetInput: false
      });
  }
};
