import Model from './Model';
import DatasetObject from './DatasetObject';
import DatasetOperator from './DatasetOperator';

class DatasetCondition extends Model {
  constructor(options) {
    super();

    (this as any).dataset_object = d => {
      return new DatasetObject(d.data);
    };

    (this as any).dataset_operator = d => {
      return new DatasetOperator(d.data);
    };

    this.bind(options);
  }
}

export default DatasetCondition;
