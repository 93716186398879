import { ConsignmentAgreementComponent } from './consignment-agreement/consignment-agreement.component';
import { PipesModule } from '../../common/pipes/pipes.module';
import { DirectivesModule } from '../../common/directives/directives.module';
import { ProxyPageRoutingModule } from './proxy-page-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ProxyPageRoutingModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
  ],
  declarations: [ConsignmentAgreementComponent]
})
export class ProxyPageModule { }
