import { PreloaderService } from './../../../../common/services/preloader/preloader.service';
import { ApiService } from './../../../../api/api.service';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import {
  FETCH_RETAILER_PRODUCTS_REQUESTED,
  FETCH_RETAILER_PRODUCTS_SUCCEEDED,
  MAP_CATEGORIES_TO_RETAILER_PRODUCTS,
  BROADCAST_RETAILER_PRODUCT_REQUESTED,
  BROADCAST_RETAILER_PRODUCT_SUCCESSED,
  UPDATE_RETAILER_PRODUCT_STATUS_REQUESTED,
  UPDATE_RETAILER_PRODUCT_STATUS_SUCCESSED,
  FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED,
  FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_REQUESTED,
  FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_SUCCEEDED,
  RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED,
  RETAILER_FETCH_PRODUCT_MARKETPLACE_REQUESTED,
  RETAILER_FETCH_ALL_MARKETPLACE_REQUESTED,
  RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED,
  RETAILER_PUBLISH_PRODUCT_REQUESTED,
  RETAILER_PUBLISH_PRODUCT_SUCCESSED,
  RETAILER_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED,
  RETAILER_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED,
  RETAILER_EXPORT_PRODUCT_REQUESTED,
  RETAILER_UPDATE_PRODUCT_QUANTITY_REQUESTED
} from './list.actions';
import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { GET_PRODUCT_DETAIL_REQUESTED } from '../../../admin/product/detail/detail.actions';

function* watchFetchProductRequested() {
  yield takeEvery(FETCH_RETAILER_PRODUCTS_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).product.get(action.data).toPromise();
      const { retailer_services } = yield select((state) => (state as any).Auth.login.profile);
      if (action.com) {
        yield put({ type: FETCH_RETAILER_PRODUCTS_SUCCEEDED, retailer_services, data: results.items, pagination: results.pagination, com: action.com });
      } else {
        yield put({ type: FETCH_RETAILER_PRODUCTS_SUCCEEDED, retailer_services, data: results.items, pagination: results.pagination });
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* mapCategoriesToProduct(action) {
  try {
    let categories = yield select((state) => (state as any).Admin.Product.list.categories);
    yield put({ type: MAP_CATEGORIES_TO_RETAILER_PRODUCTS, data: categories });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchProductImages(action) {
  try {
    let request = yield AppInjector.get(ApiService)
      .media.get({ object_type: 'Product', object_ids: action.data.map((item) => item.getId()).join(','), per_page: action.data.length })
      .toPromise();
    yield put({ type: FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED, data: request.items, pagination: request.pagination });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchCustomerInformationRequested() {
  yield takeEvery(FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_REQUESTED, function* (action: any) {
    try {
      let data = {
        ids: action.data
      };
      const result = yield AppInjector.get(ApiService).user.list(data).toPromise();
      yield put({ type: FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}


function* fetchProductMarketplaces(action) {
  try {
    let product_ids = _.map(action.data, (item) => item.id).join(',');
    const result = yield AppInjector.get(ApiService).admin.product.getMarketplace({ product_ids }).toPromise();
    yield put({ type: RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchProductsSuccessed() {
  yield takeLatest(FETCH_RETAILER_PRODUCTS_SUCCEEDED, mapCategoriesToProduct);
  yield takeLatest(FETCH_RETAILER_PRODUCTS_SUCCEEDED, fetchProductMarketplaces);
}

function* watchRetailerFetchProductMarketplaceRequested() {
  yield takeLatest(RETAILER_FETCH_PRODUCT_MARKETPLACE_REQUESTED, fetchProductMarketplaces);
}

function* watchRetailerFetchProductMarketplaceSuccessed() {
  yield takeEvery(RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED, function* (action: any) {
    yield put({ type: RETAILER_FETCH_ALL_MARKETPLACE_REQUESTED });
  });
}

function* watchRetailerFetchMarketplaceRequested() {
  yield takeLatest(RETAILER_FETCH_ALL_MARKETPLACE_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.user.getMarketplaces().toPromise();
      yield put({ type: RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchBroadcastItemRequested() {
  yield takeEvery(BROADCAST_RETAILER_PRODUCT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let product_id = action.data;
      let result = yield AppInjector.get(ApiService).broadcast.update(product_id, { id: product_id }).toPromise();
      yield put({ type: BROADCAST_RETAILER_PRODUCT_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchBroadcastItemSuccessed() {
  yield takeEvery(BROADCAST_RETAILER_PRODUCT_SUCCESSED, function* (action) {
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(NotificationService).show('success', 'Product is shared to marketplace', 3000);
  });
}

function* watchUpdateProductStatusRequested() {
  yield takeEvery(UPDATE_RETAILER_PRODUCT_STATUS_REQUESTED, function* (action: any) {
    try {
      let params = { status: action.data.status };
      let result = yield AppInjector.get(ApiService).product.changeStatus(action.data.id, params).toPromise();
      yield put({ type: UPDATE_RETAILER_PRODUCT_STATUS_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchUpdateProductStatusSuccessed() {
  yield takeEvery(UPDATE_RETAILER_PRODUCT_STATUS_SUCCESSED, function* (action: any) {
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(NotificationService).show('success', 'Product status updated successfully', 3000);
  });
}

function* watchRetailerPublishProductRequested() {
  yield takeEvery(RETAILER_PUBLISH_PRODUCT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = { marketplaces: action.marketplaces.map((item) => item.id) };
      let result = yield AppInjector.get(ApiService).admin.product.publish(action.data.id, params).toPromise();
      yield put({ type: RETAILER_PUBLISH_PRODUCT_SUCCESSED, data: result, product_id: action.data.id });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerPublishProductSuccessed() {
  AppInjector.get(PreloaderService).hide();
  yield takeEvery(RETAILER_PUBLISH_PRODUCT_SUCCESSED, function* (action: any) {
    AppInjector.get(NotificationService).show('success', 'Publish product successfully', 3000);
    const products = yield select((state) => (state as any).Retailer.Product.list.items);
    yield put({ type: RETAILER_FETCH_PRODUCT_MARKETPLACE_REQUESTED, data: products });
    yield put({ type: GET_PRODUCT_DETAIL_REQUESTED, data: action.product_id });
  });
}

function* watchUpdateProductItemStatusRequested() {
  yield takeEvery(RETAILER_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = { status_id: action.data.status_id };
      let result = yield AppInjector.get(ApiService).admin.productItem.changeStatus(action.data.product_item_id, params).toPromise();
      yield put({ type: RETAILER_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateProductItemStatusSuccessed() {
  yield takeEvery(RETAILER_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    AppInjector.get(NotificationService).show('success', 'Product status updated successfully', 3000);
  });
}

function* watchUpdateQuantityRequested() {
  yield takeEvery(RETAILER_UPDATE_PRODUCT_QUANTITY_REQUESTED, function* (action: any) {
    try {
      let data = {
        quantity: action.data.quantity
      };
      const result = yield AppInjector.get(ApiService).admin.product.updateQuantity(action.data.id, data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Product quantity updated successfully', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerExportProductRequested() {
  yield takeEvery(RETAILER_EXPORT_PRODUCT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      const del_fields = [
        'authentication_id',
        'categories',
        'condition_description_items',
        'product_items',
        'retailer',
        'color_id',
        'currency_id',
        'id',
        'retailer_id',
        'seller_id',
        'size_id',
        'source_order_id',
        'source_product_id',
        'designer',
        'seller',
        'size',
        'price',
        'source_logo',
        'designer_id',
        'created_at',
        'updated_at',
        'item_includes',
        'seller_comment',
        'color',
        'original_price'
      ];
      const products = yield AppInjector.get(ApiService).product.retailerExportProduct(action.data).toPromise();
      _.map(products, (i) => {
        if (i.categories && i.categories.data.length > 0) {
          i.Category = i.categories.data[0].name;
        }
        if (i.designer) {
          i.Designer = i.designer.name;
        }
        if (i.price) {
          i.Price = i.price.price;
          i.Currency = i.price.cur;
        }
        if (i.color) {
          i.Color = i.color.name;
        }
        i['Original Price'] = i.original_price;
        if (i.retailer) {
          i['Retailer Email'] = i.retailer.email;
        } else {
          i['Retailer Email'] = '';
        }
        let data_img = '';
        _.forEach(i.images, function (value, index) {
          if (Number(index) === 0) {
            data_img += `${value.url}`;
          } else if (Number(index) > 0) {
            data_img += `,${value.url}`;
          }
        });
        i.images = data_img;
        if (i.size) {
          i.Size = i.size.name;
        }
        _.forEach(del_fields, function (value) {
          delete i[value];
        });
        if (!i.dimensions) {
          i.dimensions = '';
        }
        if (!i.material) {
          i.material = '';
        } else {
          i.material = i.material.name.replace(';', '-');
        }
        if (!i.heel_height) {
          i['Heel Height'] = '';
        } else {
          i['Heel Height'] = i.heel_height;
        }
        if (!i.website) {
          i['website'] = '';
        } else {
          i['website'] = i.website;
        }
        return i;
      });
      const email = yield select((state) => (state as any).Auth.login.profile.email);
      AppInjector.get(ApiService).excel.exportAsExcelFile(products, `retailer-${email}-products`);
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchFetchProductRequested,
  watchFetchProductsSuccessed,
  watchBroadcastItemRequested,
  watchBroadcastItemSuccessed,
  watchUpdateProductStatusRequested,
  watchUpdateProductStatusSuccessed,
  watchFetchCustomerInformationRequested,
  watchRetailerFetchProductMarketplaceRequested,
  watchRetailerFetchProductMarketplaceSuccessed,
  watchRetailerFetchMarketplaceRequested,
  watchRetailerPublishProductRequested,
  watchRetailerPublishProductSuccessed,
  watchUpdateProductItemStatusRequested,
  watchUpdateProductItemStatusSuccessed,
  watchRetailerExportProductRequested,
  watchUpdateQuantityRequested
];
