export const FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED = 'FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED';
export const FETCH_PRODUCT_ACTIVITY_LOG_SUCCESSED = 'FETCH_PRODUCT_ACTIVITY_LOG_SUCCESSED';

export const EXPORT_PRODUCT_ACTIVITY_LOG_REQUESTED = 'EXPORT_PRODUCT_ACTIVITY_LOG_REQUESTED';

export const REPROCESS_ISSUE_REQUESTED = 'REPROCESS_ISSUE_REQUESTED';
export const REPROCESS_ISSUE_SUCCEEDED = 'REPROCESS_ISSUE_SUCCEEDED';

export const SKIP_ISSUE_REQUESTED = 'SKIP_ISSUE_REQUESTED';
export const SKIP_ISSUE_SUCCEEDED = 'SKIP_ISSUE_SUCCEEDED';

export const MARK_ISSUE_AT_IT_REQUESTED = 'MARK_ISSUE_AT_IT_REQUESTED';
export const MARK_ISSUE_AT_IT_SUCCEEDED = 'MARK_ISSUE_AT_IT_SUCCEEDED';

export const TOGGLE_ISSUE = 'TOGGLE_ISSUE';
export const TOGGLE_ALL_ISSUES = 'TOGGLE_ALL_ISSUES';

export const BULK_UPDATE_ISSUE_REQUESTED = 'BULK_UPDATE_ISSUE_REQUESTED';
export const BULK_UPDATE_ISSUE_SUCCEEDED = 'BULK_UPDATE_ISSUE_SUCCEEDED';
