import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import SmartButtonConfig from '../../models/SmartButtonConfig';

@Injectable()
export class SmartButtonService extends ServiceProvider {
  public url = '/api/v1/admin/smart-button';
  public model = SmartButtonConfig;

  getRenderConfig(user_id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/' + user_id + '/render').pipe(
      tap(result => {
      }),
      map(result => (result as any).data),
      catchError(error => {
        throw error;
      })
    );
  }

  updateRenderConfig(user_id, config): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/' + user_id + '/render', {config}).pipe(
      tap(result => {
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  getMyRenderConfig(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/me/render').pipe(
      tap(result => {
      }),
      map(result => (result as any).data),
      catchError(error => {
        throw error;
      })
    );
  }

  updateMyRenderConfig(config): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/me/render', {config}).pipe(
      tap(result => {
      }),
      catchError(error => {
        throw error;
      })
    );
  }
}
