import {
  ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED,
  ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_SUCCESSED,
  DELETE_CATEGORY_ATTRIBUTE_REQUESTED,
  DELETE_CATEGORY_ATTRIBUTE_SUCCESSED,
  FETCH_CATEGORY_DICTIONARY_SUCCESSED,
  FETCH_CATEGORY_DICTIONARY_REQUESTED,
  ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_SUCCESS,
  ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_CATEGORY_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_CATEGORY_FILTER_SELECTED,
  TOGGLE_CATEGORY_DICTIONARY_VALIDATION_REQUESTED, TOGGLE_CATEGORY_DICTIONARY_VALIDATION_SUCCEEDED, DELETE_MARKETPLACE_CATAGORY_MATCHING_SUCCESSED, DELETE_MARKETPLACE_CATAGORY_MATCHING_REQUESTED
} from './category.action';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_ATTRIBUTES_SELECT_ANY, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { Router } from '@angular/router';
import { categoryAttributes } from './category.const';

function* fetchCategories(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.category.get(action.data).toPromise();
    let selected_marketplace = yield select(state => state.Admin.Attributes.all.marketplace.selected);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_SUCCESSED, data: results.items, pagination: results.pagination });
    if (selected_marketplace) {
      yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selected_marketplace, direct: false, component: 'category' });
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* deleteCategory(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.admin.category.delete(action.data.id).toPromise();
    yield put({ type: DELETE_CATEGORY_ATTRIBUTE_SUCCESSED, data: action.data.id });
    notification.show('success', 'Delete category success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* deleteCategoryMatching(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    console.log('deleteCategoryMatching', action.data);
    let result = yield api.admin.dictionary.put(action.data).toPromise();
    // yield put({ type: DELETE_MARKETPLACE_CATAGORY_MATCHING_SUCCESSED, data: result});
    notification.show('success', 'Successfully un-matched dictionary', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e});
  }
}

function* fetchCategoryDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_CATEGORY_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchCategoryAttributeRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED, fetchCategories);
}

function* watchDeleteCategoryMatchingRequest() {
  yield takeEvery(DELETE_MARKETPLACE_CATAGORY_MATCHING_REQUESTED, deleteCategoryMatching);
}

function* watchDeleteCategoryRequest() {
  yield takeEvery(DELETE_CATEGORY_ATTRIBUTE_REQUESTED, deleteCategory);
}

function* watchFetchCategoryDictionaryRequested() {
  yield takeEvery(FETCH_CATEGORY_DICTIONARY_REQUESTED, fetchCategoryDictionary);
}

function* watchUpdateCategoryDictionaryRequested() {
  yield takeEvery(ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_REQUEST, updateCategoryDictionary);
}

function* updateCategoryDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.updateOrCreate(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_SUCCESS, data: result });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED, data: action.requestData });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* toggleCategoryDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_CATEGORY_DICTIONARY_VALIDATION_REQUESTED, toggleCategoryDictionaryValidation);
}

function* toggleCategoryDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_CATEGORY_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchSelectAnyFilter() {
  yield takeEvery(ADMIN_ATTRIBUTES_SELECT_ANY, function*(action: any) {
    const attribute = yield select((state: any) => state.Admin.Attributes.all);
    if (action.component && action.component === 'category') {
      yield put({ type: ADMIN_ATTRIBUTES_CATEGORY_FILTER_SELECTED, data: attribute });
      yield put({
        type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_REQUEST,
        data: {
          marketplace_id: attribute.marketplace.selected.id
        }
      });
    }
  });
}

function* watchFetchAllMarketplaceCategoriesRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let results = yield api.marketplace.getAllCategories(action.data).toPromise();
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_SUCCESS, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSynchroniseCategoriesRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_CATEGORY_SYNCHRONISE_ATTRIBUTES_REQUEST, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.marketplace.syncAttributes(action.data).toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatus(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          router.navigate(categoryAttributes());
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteCategoryRequest,
  watchFetchCategoryAttributeRequest,
  watchFetchCategoryDictionaryRequested,
  watchUpdateCategoryDictionaryRequested,
  watchSelectAnyFilter,
  watchFetchAllMarketplaceCategoriesRequest,
  watchSynchroniseCategoriesRequest,
  toggleCategoryDictionaryValidationRequested,
  watchDeleteCategoryMatchingRequest
];
