import * as _ from 'lodash';
import {
  ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED,
  ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED,
  ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE,
  ADMIN_INTEGRATION_EDIT_CHANGE_SETTING,
  ADMIN_INTEGRATION_EDIT_UPDATE_ALL_SETTING,
  ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED
} from './edit-action.actions';

const Marketplace = (state: any = {}, action) => {
  switch (action.type) {
    case ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED:
      if (_.isNil(state.custom_integration)) {
        state.is_all_configuration = false;
        state.is_custom_configuration = false;
      } else if (state.custom_integration) {
        state.is_all_configuration = false;
        state.is_custom_configuration = true;
      } else {
        state.is_all_configuration = true;
        state.is_custom_configuration = false;
      }

      if (state.is_all_configuration) {
        if (_.isNil(state.automatically)) {
          state.is_manually = false;
          state.is_automatically = false;
        } else if (state.automatically) {
          state.is_manually = false;
          state.is_automatically = true;
        } else {
          state.is_manually = true;
          state.is_automatically = false;
        }
      }
      return state;
    case ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED:
      if (state.marketplace_id === action.data.marketplace_id) {
        if (action.field === 'is_all_configuration') {
          if (action.value === true) {
            state.is_all_configuration = true;
            state.is_custom_configuration = false;
          } else {
            state.is_all_configuration = false;
            state.is_custom_configuration = true;
          }
        }
        if (action.field === 'is_custom_configuration') {
          if (action.value === true) {
            state.is_all_configuration = false;
            state.is_custom_configuration = true;
          } else {
            state.is_all_configuration = true;
            state.is_custom_configuration = false;
          }
        }

        if (action.field === 'is_manually') {
          if (action.value === true) {
            state.is_manually = true;
            state.is_automatically = false;
          } else {
            state.is_manually = false;
            state.is_automatically = true;
          }
        }

        if (action.field === 'is_automatically') {
          if (action.value === true) {
            state.is_manually = false;
            state.is_automatically = true;
          } else {
            state.is_manually = true;
            state.is_automatically = false;
          }
        }
      }
      return state;
    default:
      return state;
  }
};

export const editAction = (
  state: any = {
    fetched: false,
    integration: {
      is_custom_configuration: false,
      is_automatically: false
    },
    datasets: []
  },
  action
) => {
  let setting;
  switch (action.type) {
    case ADMIN_CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, {
        fetched: true,
        item: action.data,
        integration: _.assign({}, state.integration, {
          is_custom_configuration: action.data.custom_integration,
          is_automatically: action.data.automatically
        }),
        allMarkup: _.assign({}, state.allMarkup, {
          fix_amount: action.data.markup && action.data.markup.fix_amount,
          percentage_amount: action.data.markup && action.data.markup.percentage_amount,
          max_amount: action.data.markup && action.data.markup.max_amount,
          min_amount: action.data.markup && action.data.markup.min_amount
        })
      });
    case ADMIN_CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED:
      return _.assign(state, {
        datasets: action.data,
        datasetMarkups: action.data.map(datum => {
          const datasetMarkup = state.item.datasets.getContent().find(dataset => datum.id === dataset.id);
          return {
            dataset_id: datum.id,
            title: datum.title,
            fix_amount: datasetMarkup && datasetMarkup.markup ? datasetMarkup.markup.fix_amount : null,
            percentage_amount: datasetMarkup && datasetMarkup.markup ? datasetMarkup.markup.percentage_amount : null,
            min_amount: datasetMarkup && datasetMarkup.markup ? datasetMarkup.markup.min_amount : null,
            max_amount: datasetMarkup && datasetMarkup.markup ? datasetMarkup.markup.max_amount : null
          };
        })
      });
    case ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE:
      return _.assign({}, state, {
        datasets: _.assign(
          [],
          state.datasets,
          _.map(state.datasets, dataset => {
            if (action.data) {
              const config = action.data.find(item => item.id === dataset.id);
              if (!config) {
                return dataset;
              }
              dataset.is_automatically = config.is_automatically;
            }
            return dataset;
          })
        ),
        integration: _.assign({}, state.integration, {
          datasets: action.data
        })
      });
    case ADMIN_INTEGRATION_EDIT_CHANGE_SETTING:
      setting = (data => {
        switch (data) {
          case 'all':
            return { is_custom_configuration: false };
          case 'custom':
            return { is_custom_configuration: true, is_automatically: false };
        }
      })(action.data);
      return _.assign({}, state, { integration: _.assign({}, state.integration, setting) });
    case ADMIN_INTEGRATION_EDIT_UPDATE_ALL_SETTING:
      setting = (data => {
        switch (data) {
          case 'manually':
            return { is_automatically: false };
          case 'automatically':
            return { is_automatically: true };
        }
      })(action.data);
      return _.assign({}, state, { integration: _.assign({}, state.integration, setting) });
    case ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED:
      return _.assign({}, state, { fetched: true, integrations: _.map(state.integrations, item => Marketplace(item, action)) });
    default:
      return state;
  }
};
