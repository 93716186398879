import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import Product from '../../models/Product';
import { AppInjector, setAppInjector } from '../../app-injector';
import { PreloaderService } from '../../common/services/preloader/preloader.service';

@Injectable()
export class ProductService extends ServiceProvider {
  public url = '/api/v1/products';
  public model = Product;

  getItemBySku(sku): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/sku/' + sku).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  changeStatus(id, params): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/status`, params).pipe(
      map((result) => {
        return new Product((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  previewCsv(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/preview`, params).pipe(
      map((result) => {
        // console.log(result);
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }


  modifyQuantityCsv(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/modify-quantity-csv`, params).pipe(
      map((result) => {
        // console.log(result);
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  printLabel(params): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/generate-label`, params).pipe(
      map((result) => {
        this.preloader.hide();
        return result;
      }),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  importFromCsv(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/import`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  declareNotAvailable(params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${params.product_id}/declare-not-available`, params).pipe(
      map((result) => {
        // console.log(result);
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/list-export`), JSON.stringify(params)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  retailerExportProduct(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/retailer-export-product`), JSON.stringify(params)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  updateProductStatusToWaiting(id, params): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/waiting-product`, params).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
