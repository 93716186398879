import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED,
  CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_SUCCESSED,
  EDIT_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED,
  EDIT_ADMIN_ORDER_EDIT_ORDER_ITEM_SUCCESSED,
  RENDER_CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED,
  FILL_ORDER_ITEM_EDIT_FORM
} from './edit-order-item.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchEditOrderItemRequest() {
  yield takeEvery(EDIT_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED, function* (action: any) {
    //   const router = AppInjector.get(Router);
    try {
      const result = yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
      yield put({ type: EDIT_ADMIN_ORDER_EDIT_ORDER_ITEM_SUCCESSED, data: result });
      // router.navigate(listAdmin/orderEditOrderItem());
      AppInjector.get(NotificationService).show('success', 'Update order item success', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* fillFormEditOrderItemRequested() {
  yield takeLatest(RENDER_CREATE_ADMIN_ORDER_EDIT_ORDER_ITEM_REQUESTED, function* (action: any) {
    try {
      let currency = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      currency = currency.items;
      const product_price_currency = _.find(action.data.inputs, (e) => e.key === 'product_price_currency');
      const paid_to_supplier_currency = _.find(action.data.inputs, (e) => e.key === 'paid_to_supplier_currency_id');
      const paid_to_reflaunt_currency = _.find(action.data.inputs, (e) => e.key === 'paid_to_reflaunt_currency_id');
      paid_to_reflaunt_currency.options = product_price_currency.options = paid_to_supplier_currency.options = _.map(currency, (e) => _.assign({ value: e.id, label: e.code }));
      const order_item = yield AppInjector.get(ApiService).admin.fulfilment.show(action.id).toPromise();
      if (order_item.cancelled_at) {
        order_item.cancelled_at = new Date(order_item.cancelled_at);
      }
      if (order_item.sold_confirm_date) {
        order_item.sold_confirm_date = new Date(order_item.sold_confirm_date);
      }
      if (order_item.returned_date) {
        order_item.returned_date = new Date(order_item.returned_date);
      }
      if (order_item.payment_notification_date) {
        order_item.payment_notification_date = new Date(order_item.payment_notification_date);
      }
      if (order_item.supplier_invoice_paid_date) {
        order_item.supplier_invoice_paid_date = new Date(order_item.supplier_invoice_paid_date);
      }
      if (order_item.supplier_invoice_date) {
        order_item.supplier_invoice_date = new Date(order_item.supplier_invoice_date);
      }
      if (order_item.return_confirm_date) {
        order_item.return_confirm_date = new Date(order_item.return_confirm_date);
      }
      if (order_item.return_in_qc_date) {
        order_item.return_in_qc_date = new Date(order_item.return_in_qc_date);
      }
      yield put({ type: FILL_ORDER_ITEM_EDIT_FORM, data: order_item });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchEditOrderItemRequest, fillFormEditOrderItemRequested];
