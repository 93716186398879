import { CREATE_RETAILER_REQUESTED, CREATE_RETAILER_SUCCEEDED, RENDER_CREATE_RETAILER_FORM_REQUESTED, UPDATE_INPUTS_DATA } from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { detailRouter, voucherTypes } from '../retailer.const';
import * as _ from 'lodash';

function* createRetailer(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.user.create(action.data).toPromise();
    yield put({ type: CREATE_RETAILER_SUCCEEDED, data: result });
    AppInjector.get(NotificationService).show('success', 'Retailer created', 3000);
    router.navigate(detailRouter(result.id));
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateRetailerRequest() {
  yield takeEvery(CREATE_RETAILER_REQUESTED, createRetailer);
}

function* fillInputData(action) {
  const api = AppInjector.get(ApiService);
  try {
    let shippingMethods = yield api.admin.shippingMethod.list().toPromise();
    // const data = {
    //   shipping_method_id: _.map(shippingMethods, (item, key) => _.assign(item, { value: item.id, label: item.name }))
    // };
    // yield put({ type: UPDATE_INPUTS_DATA, data: data });
    yield put({
      type: UPDATE_INPUTS_DATA,
      input: 'shipping_method_id',
      data: {
        value: null,
        options: _.map(shippingMethods, (item, key) => _.assign(item, { value: item.id, label: item.name }))
      }
    });
    yield put({
      type: UPDATE_INPUTS_DATA,
      input: 'voucher_type',
      data: {
        value: null,
        options: voucherTypes
      }
    });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFillInputDataRequest() {
  yield takeEvery(RENDER_CREATE_RETAILER_FORM_REQUESTED, fillInputData);
}

export default [watchCreateRetailerRequest, watchFillInputDataRequest];
