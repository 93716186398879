export const ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_REQUESTED = 'ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_REQUESTED';
export const ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_SUCCESSED = 'ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_SUCCESSED';
export const DELETE_COLOR_ATTRIBUTE_REQUESTED = 'DELETE_COLOR_ATTRIBUTE_REQUESTED';
export const DELETE_COLOR_ATTRIBUTE_SUCCESSED = 'DELETE_COLOR_ATTRIBUTE_SUCCESSED';
export const FETCH_COLOR_DICTIONARY_REQUESTED = 'FETCH_COLOR_DICTIONARY_REQUESTED';
export const FETCH_COLOR_DICTIONARY_SUCCESSED = 'FETCH_COLOR_DICTIONARY_SUCCESSED';
export const CREATE_COLOR_DICTIONARY_SUCCESSED = 'CREATE_COLOR_DICTIONARY_SUCCESSED';
export const RESET_COLOR_DICTIONARY = 'RESET_COLOR_DICTIONARY';
export const EDIT_COLOR_DICTIONARY_REQUESTED = 'EDIT_COLOR_DICTIONARY_REQUESTED';
export const EDIT_COLOR_DICTIONARY_SUCCEEDED = 'EDIT_COLOR_DICTIONARY_SUCCEEDED';
export const ADMIN_ATTRIBUTES_COLOR_FILTER_SELECTED = 'ADMIN_ATTRIBUTES_COLOR_FILTER_SELECTED';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_SUCCESS';
export const ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_SUCCESS';
export const ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_REQUESTED = 'ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_REQUESTED';
export const ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_SUCCESS = 'ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_SUCCESS';
export const ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_REQUESTED = 'ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_REQUESTED';
export const ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_SUCCESS = 'ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_SUCCESS';
export const ADMIN_ATTRIBUTES_COLOR_SYNCHRONISE_ATTRIBUTES_REQUEST = 'ADMIN_ATTRIBUTES_COLOR_SYNCHRONISE_ATTRIBUTES_REQUEST';
export const TOGGLE_COLOR_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_COLOR_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_COLOR_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_COLOR_DICTIONARY_VALIDATION_SUCCEEDED';
