import { FETCH_ALL_ADMIN_FULFILMENT_SUCCESSED, FETCH_ALL_ADMIN_FULFILMENT_REQUESTED, RESET_DATE_FILTER_FORM, UPDATE_INPUT_FILTER_DATE_FULFILMENT } from './list.actions';
import * as _ from 'lodash';
import { put, select, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchFetchFulfilmentsRequest() {
  yield takeEvery(FETCH_ALL_ADMIN_FULFILMENT_REQUESTED, function* (action: any) {
    try {
      const user = yield AppInjector.get(ApiService).user.profile({}).toPromise();
      let results = yield AppInjector.get(ApiService)
        .admin.shipment.getAllFulfilmentShipment({ ...action.data, user_id: user.id })
        .toPromise();

      yield put({ type: FETCH_ALL_ADMIN_FULFILMENT_SUCCESSED, data: results.items, pagination: results.pagination, queryParams: { ...action.data, user_id: user.id } });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchFulfilmentsRequest];
