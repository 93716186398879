import { CREATE_TAG_REQUESTED, CREATE_TAG_SUCCEEDED } from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { listTagRouter } from '../tag.const';

function* createTag(action) {
  const router = AppInjector.get(Router);
  try {
    let result = yield AppInjector.get(ApiService)
      .admin.tag.create(action.data)
      .toPromise();
    yield put({ type: CREATE_TAG_SUCCEEDED, data: result });
    router.navigate(listTagRouter());
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateTagRequest() {
  yield takeEvery(CREATE_TAG_REQUESTED, createTag);
}

export default [watchCreateTagRequest];
