export const GET_INTEGRATION_REQUESTED = 'GET_INTEGRATION_REQUESTED';
export const GET_INTEGRATION_SUCCEEDED = 'GET_INTEGRATION_SUCCEEDED';
export const EDIT_INTEGRATION_REQUESTED = 'EDIT_INTEGRATION_REQUESTED';
export const EDIT_INTEGRATION_SUCCEEDED = 'EDIT_INTEGRATION_SUCCEEDED';
export const DELETE_INTEGRATION_REQUESTED = 'DELETE_INTEGRATION_REQUESTED';
export const DELETE_INTEGRATION_SUCCEEDED = 'DELETE_INTEGRATION_SUCCEEDED';
export const CONFIGURATION_FETCH_MARKETPLACE_REQUESTED = 'CONFIGURATION_FETCH_MARKETPLACE_REQUESTED';
export const CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED = 'CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED';
export const UPDATE_INTEGRATION_TYPE_REQUESTED = 'UPDATE_INTEGRATION_TYPE_REQUESTED';
export const UPDATE_INTEGRATION_TYPE_SUCCESSED = 'UPDATE_INTEGRATION_TYPE_SUCCESSED';
export const SAVE_INTEGRATION_CONFIGURATION_REQUESTED = 'SAVE_INTEGRATION_CONFIGURATION_REQUESTED';
export const SAVE_INTEGRATION_CONFIGURATION_SUCCESSED = 'SAVE_INTEGRATION_CONFIGURATION_SUCCESSED';
export const CONFIGURATION_FETCH_ALL_DATASETS_REQUESTED = 'CONFIGURATION_FETCH_ALL_DATASETS_REQUESTED';
export const CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED = 'CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED';
export const CONFIGURATION_UPDATE_CUSTOM_INTEGRATION = 'CONFIGURATION_UPDATE_CUSTOM_INTEGRATION';
export const INTEGRATION_EDIT_CHANGE_SETTING = 'INTEGRATION_EDIT_CHANGE_SETTING';
export const INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE = 'INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE';
export const INTEGRATION_EDIT_UPDATE_ALL_SETTING = 'INTEGRATION_EDIT_UPDATE_ALL_SETTING';

export const fetchMarketplaceRequested = payload => {
  return {
    type: CONFIGURATION_FETCH_MARKETPLACE_REQUESTED,
    data: payload
  };
};
