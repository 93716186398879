import * as _ from 'lodash';
import { CREATE_NEW_MEASUREMENT_UNIT_SUCCEEDED } from './create.action';

export const Create = (
  state = {
    created: false
  },
  action
) => {
  switch (action.type) {
    case CREATE_NEW_MEASUREMENT_UNIT_SUCCEEDED:
      return true;
    default:
      return state;
  }
};
