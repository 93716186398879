import * as _ from 'lodash';
import { support } from '@reflaunt/reactive-form';
import { REMOVE_UPDATE_CURRENCY_LABEL, RENDER_REMOVE_BULK_DISCOUNT_FORM_REQUESTED, UPDATE_REMOVE_BULK_DISCOUNT_INPUT_OPTIONS } from './remove-bulk-discount.actions';

export const removeBulkDiscount = (state: any = { created: false, fetched: false }, action) => {
  switch (action.type) {
    case RENDER_REMOVE_BULK_DISCOUNT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs , fetched: true });
    case UPDATE_REMOVE_BULK_DISCOUNT_INPUT_OPTIONS:
      state.inputs = support.UpdateInputsValue(state.inputs, action.data);
      return _.assign({}, state, { inputs: state.inputs });
    case REMOVE_UPDATE_CURRENCY_LABEL:
        return _.assign({}, state);
    default:
      return state;
  }
};
