export const ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED = 'ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_REQUESTED';
export const ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_SUCCESSED = 'ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_SUCCESSED';
export const DELETE_CATEGORY_ATTRIBUTE_REQUESTED = 'DELETE_CATEGORY_ATTRIBUTE_REQUESTED';
export const DELETE_CATEGORY_ATTRIBUTE_SUCCESSED = 'DELETE_CATEGORY_ATTRIBUTE_SUCCESSED';
export const FETCH_CATEGORY_DICTIONARY_REQUESTED = 'FETCH_CATEGORY_DICTIONARY_REQUESTED';
export const FETCH_CATEGORY_DICTIONARY_SUCCESSED = 'FETCH_CATEGORY_DICTIONARY_SUCCESSED';
export const CREATE_CATEGORY_DICTIONARY_SUCCESSED = 'CREATE_CATEGORY_DICTIONARY_SUCCESSED';
export const RESET_CATEGORY_DICTIONARY = 'RESET_CATEGORY_DICTIONARY';
export const EDIT_CATEGORY_DICTIONARY_REQUESTED = 'EDIT_CATEGORY_DICTIONARY_REQUESTED';
export const EDIT_CATEGORY_DICTIONARY_SUCCEEDED = 'EDIT_CATEGORY_DICTIONARY_SUCCEEDED';
export const ADMIN_ATTRIBUTES_CATEGORY_FILTER_SELECTED = 'ADMIN_ATTRIBUTES_CATEGORY_FILTER_SELECTED';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_SUCCESS';
export const ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_SUCCESS';
export const ADMIN_ATTRIBUTES_CATEGORY_SYNCHRONISE_ATTRIBUTES_REQUEST = 'ADMIN_ATTRIBUTES_CATEGORY_SYNCHRONISE_ATTRIBUTES_REQUEST';
export const TOGGLE_CATEGORY_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_CATEGORY_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_CATEGORY_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_CATEGORY_DICTIONARY_VALIDATION_SUCCEEDED';
export const DELETE_MARKETPLACE_CATAGORY_MATCHING_REQUESTED = 'DELETE_MARKETPLACE_CATAGORY_MATCHING_REQUESTED';
export const DELETE_MARKETPLACE_CATAGORY_MATCHING_SUCCESSED = 'DELETE_MARKETPLACE_CATAGORY_MATCHING_SUCCESSED';
