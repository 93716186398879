import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Courier from '../../../models/Courier';

@Injectable()
export class CourierService extends ServiceProvider {
  public url = '/api/v1/admin/couriers';
  public model = Courier;
}
