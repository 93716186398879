import { LOGIN_SUCCEEDED, FETCH_LOGIN_DETAIL_SUCCEEDED } from './login.actions';
import * as _ from 'lodash';
import * as $ from 'jquery';

export const login = (state = { loggedin: false }, action) => {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return _.assign({}, state, { loggedin: true });

    case FETCH_LOGIN_DETAIL_SUCCEEDED:
      let permissions = action.data.roles[0].permissions;
      if (action.data.roles[0].slug === 'retailer') {
        permissions.push({ slug: 'retailer.view.marketplace' });
      }
      return _.assign({}, state, { loggedin: true, fetched: true, profile: action.data });

    default:
      return state;
  }
};
