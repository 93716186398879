export const RENDER_PROFILE_DETAIL_FORM = 'RENDER_PROFILE_DETAIL_FORM';
export const FETCH_PROFILE_DETAIL_REQUESTED = 'FETCH_PROFILE_DETAIL_REQUESTED';
export const FETCH_PROFILE_DETAIL_SUCCESSED = 'FETCH_PROFILE_DETAIL_SUCCESSED';
export const FILL_PROFILE_DETAIL_FORM = 'FILL_PROFILE_DETAIL_FORM';

export const UPDATE_AVATAR_PROFILE = 'UPDATE_AVATAR_PROFILE';

export const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED';
export const UPDATE_PROFILE_SUCCESSED = 'UPDATE_PROFILE_SUCCESSED';

export const GET_USER_WALLET_SUCCEEDED = 'GET_USER_WALLET_SUCCEEDED';

