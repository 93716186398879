export const FETCH_ENDPOINT_REQUESTED = 'FETCH_ENDPOINT_REQUESTED';
export const FETCH_ENDPOINT_SUCCEEDED = 'FETCH_ENDPOINT_SUCCEEDED';


export const CREATE_ENDPOINT_REQUESTED = 'CREATE_ENDPOINT_REQUESTED';
export const CREATE_ENDPOINT_SUCCEEDED = 'CREATE_ENDPOINT_SUCCEEDED';

export const UPDATE_ENDPOINT_REQUESTED = 'UPDATE_ENDPOINT_REQUESTED';
export const UPDATE_ENDPOINT_SUCCEEDED = 'UPDATE_ENDPOINT_SUCCEEDED';


export const DELETE_ENDPOINT_REQUESTED = 'DELETE_ENDPOINT_REQUESTED';
export const DELETE_ENDPOINT_SUCCEEDED = 'DELETE_ENDPOINT_SUCCEEDED';
