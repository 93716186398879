import Model from './Model';
import Dictionary from './Dictionary';
import * as _ from 'lodash';


export enum SizeTypes {
  SHOES = 'SHOES',
  CLOTHING = 'CLOTHING',
}

export const GenderMapping =  new Map([
  ['FEMALE', 'Women'],
  ['MALE', 'Men',],
]);

class Size extends Model {
  full_name: string;
  constructor(options) {
    super();
    (this as any).dictionaries = d => {
      return _.map(d.data, item => new Dictionary(item));
    };
    this.bind(options);
    this.full_name = `${(this as any).age_group || 'ANY AGE'} | ${(this as any).gender || 'UNISEX'} | ${(this as any).type || 'ANY'} | ${(this as any).name}`;
  }
}

export default Size;
