import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { list } from './list/list.reducer';
import { detail } from './detail/detail.reducer';
import { edit } from './edit/edit.reducer';

export const OrderItem = combineReducers({
  list,
  detail,
  edit
});
