export const GET_ALL_USERS_SUCCEEDED = 'GET_ALL_USERS_SUCCEEDED';
export const GET_ALL_USERS_REQUESTED = 'GET_ALL_USERS_REQUESTED';

export const CHANGE_USER_IMAGE_REQUESTED = 'CHANGE_USER_IMAGE_REQUESTED';
export const CHANGE_USER_IMAGE_SUCCESSED = 'CHANGE_USER_IMAGE_SUCCESSED';

export const GET_USER_PROFILE_IMAGE_REQUESTED = 'GET_USER_PROFILE_IMAGE_REQUESTED';
export const GET_USER_PROFILE_IMAGE_SUCCESSED = 'GET_USER_PROFILE_IMAGE_SUCCESSED';

/**
 * Update user avatar
 *
 * @param user_id
 * @param image_url
 *
 * @return void
 */
export const changeUserAvatarRequeted = (user_id, url) => {
  return {
    type: CHANGE_USER_IMAGE_REQUESTED,
    data: { user_id, url }
  };
};

/**
 * Get user avatar
 *
 * @param user_id
 * @param image_url
 *
 * @return void
 */
export const getUserProfileImageRequeted = user_id => {
  return {
    type: GET_USER_PROFILE_IMAGE_REQUESTED,
    data: { user_id }
  };
};
