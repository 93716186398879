import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailerRoutingModule } from './retailer-routing.module';
import { AnalyticComponent } from './analytic/analytic.component';
import { SharedModule } from '../../template/shared/shared.module';
import { ChartModule } from 'angular2-chartjs';
import { AgmCoreModule } from '@agm/core';
import { DatasetModule } from './dataset/dataset.module';
import { PercentageComponent } from './percentage/percentage.component';
import { ResaleComponent } from './resale/resale.component';
import { SecondHandSalesComponent } from './second-hand-sales/second-hand-sales.component';
import { RepurchaseComponent } from './repurchase/repurchase.component';
import { AcquisitionComponent } from './acquisition/acquisition.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { EndpointComponent } from './endpoint/endpoint.component';
import { DirectivesModule } from '../../common/directives/directives.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../common/pipes/pipes.module';
import { SmartButtonComponent } from './smart-button/smart-button.component';
import { CredentialModule } from '../credential/credential.module';

@NgModule({
  imports: [
    CommonModule,
    RetailerRoutingModule,
    SharedModule,
    ChartModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyCE0nvTeHBsiQIrbpMVTe489_O5mwyqofk' }),
    DatasetModule,
    SweetAlert2Module,
    DirectivesModule,
    FormsModule,
    PipesModule,
    CredentialModule
  ],
  declarations: [
    AnalyticComponent,
    PercentageComponent,
    ResaleComponent,
    SecondHandSalesComponent,
    RepurchaseComponent,
    AcquisitionComponent,
    EndpointComponent,
    SmartButtonComponent
  ]
})
export class RetailerModule {}
