import * as _ from 'lodash';
import { FETCH_PRODUCT_DETAIL_SUCCEEDED, MAP_CATEGORIES_TO_PRODUCT, FETCH_PRODUCT_DETAIL_REQUESTED, GET_DETAIL_PRODUCT_SUCCEEDED } from './detail.actions';

export const detail = (state = { fetched: false, data: {}, item: {} }, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DETAIL_SUCCEEDED:
      if (!_.isNil(action.data.seller)) {
        action.data.customer_email = action.data.seller.email;
      }
      if (!_.isNil(action.data.retailer)) {
        action.data.retailer_email = action.data.retailer.email;
      }
      if (!_.isNil(action.data.categories) && action.data.categories.data.length) {
        action.data.category_id = (_.head(action.data.categories.data) as any).id;
      }
      return _.assign({}, state, { fetched: true, data: action.data });

    case FETCH_PRODUCT_DETAIL_REQUESTED:
      return _.assign({}, state, { fetched: false, data: {} });

    case MAP_CATEGORIES_TO_PRODUCT:
      return _.assign({}, { data: _.assign(state.data, { categories: action.data }) });
    case GET_DETAIL_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });

    default:
      return state;
  }
};
