import { DEFAULT_MKT_COMMISION } from './../../../../models/Marketplace';
import {
  EDIT_ADMIN_MARKETPLACE_SUCCEEDED,
  GET_MARKETPLACE_SUCCEEDED,
  RENDER_EDIT_MARKETPLACE_FORM_REQUESTED,
  FILL_MARKETPLACE_DETAIL_FORM,
  UPDATE_MARKETPLACE_INPUT_OPTIONS,
  ADD_INPUT_INCREASE_RULE
} from './edit.actions';
import * as _ from 'lodash';
import { object_types } from '../../../../models/SmartObject';
import { support, TextBox } from '@reflaunt/reactive-form';
import { Validators } from '@angular/forms';
declare var $: any;

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case FILL_MARKETPLACE_DETAIL_FORM:
      if (!_.isUndefined(_.get(action.data.marketplace, state.key))) {
        state.value = _.get(action.data.marketplace, state.key);
        if (state.key === 'is_using_partner_selling_price') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.is_using_partner_selling_price);
        }
        if (state.key === 'is_auto_generate_shippinglabel') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.is_auto_generate_shippinglabel);
        }
        if (state.key === 'position_require_retouched') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.position_require_retouched);
        }
        if (state.key === 'is_integrated') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.is_integrated);
        }
        if (state.key === 'shipping_type') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.shipping_type);
        }
        if (state.key === 'is_disable_shipping_cost') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.is_disable_shipping_cost);
        }
        if (state.key === 'forward_shipment_location_id') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.forward_shipment_location_id);
        }
        if (state.key === 'is_old_pricing_logic') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.is_old_pricing_logic);
        }
        if (state.key === 'event_resource') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.event_resource);
        }
        if (state.key === 'has_original_price_rule') {
          state.value = state.options.find((item) => item.value === action.data.marketplace.has_original_price_rule);
        }
        if (state.key === 'currency_id') {
          state.value = action.data.currency_id;
        }
        if (state.key === 'return_location_id') {
          state.value = action.data.return_location_id;
        }
        if (state.key === 'preferred_sizing_prefix') {
          state.value = action.data.preferred_sizing_prefix.value;
        }
        if (state.key === 'language_code') {
          state.value = action.data.language_code.value;
        }
      } else {
        if (state.key === 'language_code') {
          state.value = '';
        }
      }
      if (state.key === 'image_type') {
        state.value = state.options.find((item) => item.value === action.data.marketplace.image_type);
      }
      if (state.key === 'is_manual_login') {
        state.value = state.options.find((item) => item.value === action.data.marketplace.is_manual_login);
      }
      if (!_.isNil(action.default_address) && !_.isUndefined(_.get(action.default_address, state.key))) {
        state.value = _.get(action.default_address, state.key);
      }
      if (state.key === 'min_discount_percent_from_original_price') {
        console.log(action);
        state.value = !_.isNil(action.data.marketplace.min_discount_percent_from_original_price)
          ? action.data.marketplace.min_discount_percent_from_original_price.toString()
          : null;
      }
      // if (!_.isUndefined(_.get(action.data, state.key))) {
      //   state.value = _.get(action.data, state.key);
      // }
      return state;
    default:
      return state;
  }
};

export const edit = (
  state = {
    updated: false,
    fetched: false,
    inputs: [],
    marketplace_commissions: [],
    constants: {},
    default_address: {},
    default_commission: { is_default: true },
    countries: [],
    item: {}
  },
  action
) => {
  switch (action.type) {
    case RENDER_EDIT_MARKETPLACE_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_MARKETPLACE_DETAIL_FORM:
      let default_address: any;
      let mkt_commission = [DEFAULT_MKT_COMMISION];
      const { marketplace_commissions, address, marketplace } = action.data;
      if (!_.isNil(address) && !_.isEmpty(address)) {
        default_address = _.find(address, (i) => i.is_default);
        action.default_address = !_.isNil(default_address) ? default_address : _.head(address);
        if (action.default_address.country_id) {
          action.default_address.country_id = _.find(state.countries, (e) => e.id === action.default_address.country_id);
        }
      }
      if (!_.isNil(marketplace_commissions) && marketplace_commissions.length > 0) {
        mkt_commission = _.map(marketplace_commissions, (e, key) => _.assign({}, e, { key: key + 1 })).sort((a, b) => b.is_default - a.is_default);
      }
      if (!_.isNil(marketplace)) {
        if (marketplace.has_original_price_rule) {
          state.inputs.push(
            new TextBox({
              key: 'increase_original_price_percent',
              label: 'Original price percent increase',
              classes: ['col-6 original_price_rule'],
              group_classes: ['col-12'],
              order: 1,
              type: 'number',
              min: 0,
              max: 99.99,
              validators: [
                {
                  validator: Validators.max(99.99),
                  message: 'Value must be < 100'
                },
                {
                  validator: Validators.min(0),
                  message: 'Value must be > 0'
                }
              ]
            })
          );
          setTimeout(() => {
            $('.original_price_rule .float-label span').hover(
              function () {
                $(this).attr('title', 'Increase the original price by this percent in case the original price rule prevents from publishing');
              },
              function () {
                $(this).removeAttr('title');
              }
            );
          }, 1000);
        }
      }
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (item) => Input(item, action)),
        constants: action.constants,
        default_address: default_address,
        marketplace_commissions: mkt_commission
      });
    case GET_MARKETPLACE_SUCCEEDED:
      const smartObjects = _.cloneDeep(object_types);
      _.forEach(smartObjects, function (value: any) {
        const check = _.find(action.data.smart_objects, (i) => i.type === value.type);
        if (check) {
          value.url = check.url;
        }
      });
      return _.assign({}, state, { fetched: true, item: action.data, smart_objects: smartObjects });
    case UPDATE_MARKETPLACE_INPUT_OPTIONS:
      return _.assign({}, state, {
        countries: action.countries,
        currencies: action.currencies,
        inputs: _.map(state.inputs, (input) => {
          _.map(action.data, (value, key) => {
            if (input.key === key) {
              input.options = value;
            }
            return input;
          });
          return input;
        })
      });
    case EDIT_ADMIN_MARKETPLACE_SUCCEEDED:
      return _.assign({}, state, { updated: true });
    case ADD_INPUT_INCREASE_RULE:
      console.log(state.inputs, 'current inputs');
      const increasePercentInput = _.find(state.inputs, (e) => e.key === 'increase_original_price_percent');
      const originalPriceRuleInput = _.find(state.inputs, (e) => e.key === 'has_original_price_rule');
      if (increasePercentInput && !action.data.has_original_price_rule) {
        state.inputs = _.filter(state.inputs, (i) => (i as any).key !== 'increase_original_price_percent');
      }
      if (!increasePercentInput && action.data.has_original_price_rule) {
        state.inputs.push(
          new TextBox({
            key: 'increase_original_price_percent',
            label: 'Original price percent increase',
            classes: ['col-6 original_price_rule'],
            group_classes: ['col-12'],
            order: 1,
            type: 'number',
            min: 0,
            max: 99.99,
            validators: [
              {
                validator: Validators.max(99.99),
                message: 'Value must be < 100'
              },
              {
                validator: Validators.min(0),
                message: 'Value must be > 0'
              }
            ]
          })
        );
      }
      const has_original_price_rule_value = _.find(originalPriceRuleInput.options, (e) => e.value === action.data.has_original_price_rule);
      setTimeout(() => {
        $('.original_price_rule .float-label span').hover(
          function () {
            $(this).attr('title', 'Increase the original price by this percent in case the original price rule prevents from publishing');
          },
          function () {
            $(this).removeAttr('title');
          }
        );
      }, 1000);

      return _.assign({}, state, {
        inputs: support.UpdateFormValue(
          state.inputs,
          _.assign({}, state.item, {
            has_original_price_rule: has_original_price_rule_value
          })
        )
      });

    default:
      return state;
  }
};
