import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as $ from 'jquery';

@Component({
  selector: 'app-channel-dropdown',
  templateUrl: './channel-dropdown.component.html',
  styleUrls: ['./channel-dropdown.component.scss']
})
export class ChannelDropdownComponent implements OnInit {
  @Input() disablePointerEvent = true;
  @Input() marketplaces;
  @Input() item;
  @Input() index;

  @Output() checked = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  channelsDropdownToggle(event: any, id) {
    let dropdownMenu = event.target.nextSibling;
    dropdownMenu.classList.toggle('d-none');
    if ($('.overflow-table').length !== 0) {
      const left = $(`.td-${id}`)[0].offsetLeft - $(`.specific-marketplace-${id}`)[0].offsetWidth + $(`.specific-dropdown-${id}`)[0].offsetLeft - 20;
      const top = $(`.tr-${id}`)[0].offsetTop + $(`.specific-dropdown-${id}`)[0].offsetTop;
      $(`.specific-marketplace-${id}`).css('left', left);
      $(`.specific-marketplace-${id}`).css('top', top);
    } else {
      $(`.specific-dropdown-${id}`).css('position', 'relative');
      $(`.specific-marketplace-${id}`).css('left', -235);
      $(`.specific-marketplace-${id}`).css('top', 0);
    }
  }

  onChannelClickedOutside(event) {
    let dropdowns = document.getElementsByClassName('publish-channel__dropdown-menu');
    let activeDropdowns = _.filter(Array.from(dropdowns), item => {
      return !_.includes(Array.from(item.classList), 'd-none');
    });
    activeDropdowns.forEach(item => {
      item.classList.add('d-none');
    });
  }

  marketplaceClick(event: any, index, marketplace_index) {
    let marketPlaceItem = document.getElementById(`marketplace__item__${index}-${marketplace_index}`);
    if (!_.isNil(marketPlaceItem)) {
      (marketPlaceItem as any).classList.toggle('marketplace__item--active');
      (marketPlaceItem as any).childNodes[1].childNodes[1].classList.toggle('d-none');
      (marketPlaceItem as any).childNodes[1].childNodes[0].addEventListener('click', ev => {
        ev.stopPropagation();
      });
      (marketPlaceItem as any).childNodes[1].childNodes[0].click();
    }
  }

  isActiveMarketplace(item, marketplace) {
    let itemActiveMarketplaceIds = _.map(item.marketplaces, value => value.marketplace.id);
    return _.includes(itemActiveMarketplaceIds, marketplace.marketplace.id) ? true : false;
  }

  setThumbnailImage(marketplace) {
    if (marketplace.images.length > 0) {
      const imageLocation = marketplace.images.length - 1;
      return marketplace.images[imageLocation].url;
    } else {
      return '../../../../assets/images/thumbnail/hoodies.png';
    }
  }

  changeCheckbox(e, item, marketplace) {
    item.availableMarketplaces = item.availableMarketplaces.map(i => {
      if (e.target.checked && i.id === marketplace.id) {
        i.selected = true;
      }
      if (!e.target.checked && i.id === marketplace.id) {
        delete i.selected;
      }
      return i;
    });
    return item;
  }

  publish(item) {
    this.checked.emit(item);
  }
}
