import Model from './Model';
import Language from './Language';
import NotificationActionMarketplaceManagement from './NotificationActionMarketplaceManagement';

export default class LanguageNotificationActionMarketplaceManagement extends Model {
  constructor(options) {
    super();
    (this as any).language = (d) => {
      return new Language(d.data);
    };
    (this as any).integration = (d) => {
      return new NotificationActionMarketplaceManagement(d.data);
    };
    this.bind(options);
  }
}
