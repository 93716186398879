import { FETCH_DATASETS_SUCCEEDED, SORT_DATASETS_SUCCEEDED, DATASET_LIST_COMPONENT } from './list.actions';
import * as _ from 'lodash';
import { GET_ALL_DATASET_CONDITIONS_SUCCEEDED } from '../dataset.action';

export const list = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case FETCH_DATASETS_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });

    case SORT_DATASETS_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.data, total: action.total });

    case GET_ALL_DATASET_CONDITIONS_SUCCEEDED:
      if (!_.isUndefined(action.component)) {
        switch (action.component) {
          case DATASET_LIST_COMPONENT:
            let items = _.cloneDeep(state.items);
            _.map(items, item => {
              item.conditions = _.filter(action.data, { dataset_id: item.getId() });
              return item;
            });
            return _.assign({}, state, { items: items });
        }
      }
      return state;

    default:
      return state;
  }
};
