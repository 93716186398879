import * as _ from 'lodash';
import { CUSTOM_FORM_LABEL_RENDER,REQUEST_CUSTOM_GENERATION_REQUESTED, REQUEST_CUSTOM_GENERATION_SUCEEDED } from './generate-shipment-label-v2.actions';


export const CustomGeneration = (state = { fetched: false, inputs: [], item: {} }, action) => {
  switch (action.type) {
    case CUSTOM_FORM_LABEL_RENDER:
      return _.assign({}, state, { fetched: true, inputs: action.data.inputs });  
  default:
    return state;
  }
}; 
