import {
  COLLECTION_EDIT_PRODUCT_REQUESTED,
  COLLECTION_GET_PRODUCT_DETAIL_SUCCEEDED,
  RENDER_COLLECTION_EDIT_PRODUCT_FORM_REQUESTED,
  SAVE_COLLECTION_EDIT_PRODUCT_ALL_SIZES_OPTIONS_SUCCEEDED,
  UPDATE_COLLECTION_EDIT_PRODUCT_INPUT_OPTIONS
} from './edit.actions';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../../app-injector';
import { ApiService } from '../../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../../store/action';
import { fetchAllcurrency } from '../../../currency/currency.saga';
import { fetchAllsize, fetchAllsizeUnit } from '../../../attribute/size/size.saga';
import { fetchAlldesigner } from '../../../attribute/designer/designer.saga';
import { fetchAllcolor } from '../../../attribute/color/color.saga';
import { fetchChildCategory, fetchItemType, fetchMainCategory, fetchSubCategory } from '../../../category/category.saga';
import { fetchAllmaterial } from '../../../attribute/material/material.saga';
import { fetchAllConditions } from '../../../attribute/condition/condition.saga';
import { fetchProductDetail } from '../../../product/edit/edit.saga';
import { UPDATE_PRODUCT_SUCCEEDED } from '../../../product/edit/edit.actions';
import { NotificationService } from '../../../../../common/services/notification/notification.service';
import { convertNameSize } from '../../../product/edit/edit.const';

export function* fetchAllLocations(params?) {
  const result = yield AppInjector.get(ApiService).admin.location.getLogisticPartnerLocation().toPromise();
  return result;
}

function* fetchAllProductData(action) {
  const [currencies, sizes, designers, colors, categories, materials, conditions, locations, size_units] = yield all([
    call(fetchAllcurrency),
    call(fetchAllsize, {
      constraints: JSON.stringify({
        is_old_size: false
      })
    }),
    call(fetchAlldesigner),
    call(fetchAllcolor),
    call(fetchMainCategory),
    call(fetchAllmaterial),
    call(fetchAllConditions),
    call(fetchAllLocations),
    call(fetchAllsizeUnit)
  ]);
  const [product] = yield all([call(fetchProductDetail, action.data.id, { includes: 'categories,seller,retailer,currency' })]);

  const productCategory = product.categories.data.length > 0 ? product.categories.data[0] : undefined;
  const productCategoryName = productCategory ? productCategory.name : undefined;
  const productNameArray = productCategoryName ? productCategoryName.split(' | ') : [];
  let subCategories, itemTypes, childCategories;
  if (productCategory) {
    [subCategories] = yield all([call(fetchSubCategory, productNameArray[0])]);
    [itemTypes] = yield all([call(fetchItemType, productNameArray[0], productNameArray[1])]);
    [childCategories] = yield all([call(fetchChildCategory, productNameArray[0], productNameArray[1], productNameArray[2])]);
  }

  const sizeConvert = sizes.map((item, key) => {
    let label = convertNameSize(item);
    return Object.assign(item, { value: item.id, label: label });
  });

  const co = designers.find((i) => i.name === 'co');
  const abco = designers.find((i) => i.name === 'a browns co');
  const swapElements = (arr, pos1, pos2) => {
    const temp = arr[pos1];
    arr[pos1] = arr[pos2];
    arr[pos2] = temp;
    return arr;
  };
  const optionsData = {
    designer_id: _.map(co && abco ? swapElements(designers, designers.indexOf(abco), designers.indexOf(co)) : designers, (item, key) =>
      _.assign(item, { value: item.id, label: item.name })
    ),
    currency_id: _.map(currencies, (item, key) => _.assign(item, { value: item.id, label: item.code })),
    size_id: sizeConvert,
    color_id: _.map(colors, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    main_category: _.map(categories.data.main_categories, (item, key) => _.assign({}, { value: item, label: item })),
    material_id: _.map(materials, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    condition_id: _.map(conditions, (item, key) => _.assign(item, { value: item.id, label: item.name })),
    warehouse: _.map(locations, (item, key) => _.assign(item, { value: item.slug, label: item.name })),
    size_type: _.map(size_units, (item, key) => _.assign(item, { value: item.unit, label: item.unit })),
    sub_category: subCategories ? _.map(subCategories.data.sub_categories, (item, key) => _.assign({}, { value: item, label: item })) : [],
    item_type: _.map(itemTypes, (item) =>
      _.assign(
        {},
        {
          value: item.item_type,
          label: item.item_type,
          id: item.id,
          full_name: item.name
        }
      )
    ),
    child_category: _.map(childCategories, (item) =>
      _.assign(
        {},
        {
          value: item.id,
          label: item.last_name,
          full_name: item.name
        }
      )
    )
  };
  yield put({ type: SAVE_COLLECTION_EDIT_PRODUCT_ALL_SIZES_OPTIONS_SUCCEEDED, data: optionsData.size_id });
  yield put({ type: UPDATE_COLLECTION_EDIT_PRODUCT_INPUT_OPTIONS, data: optionsData });
  yield put({ type: COLLECTION_GET_PRODUCT_DETAIL_SUCCEEDED, data: { product } });
}

function* watchCreateProductFormRequested() {
  yield takeEvery(RENDER_COLLECTION_EDIT_PRODUCT_FORM_REQUESTED, fetchAllProductData);
}

function* updateProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.product.update(action.data.id, action.data).toPromise();
    yield put({ type: UPDATE_PRODUCT_SUCCEEDED, data: result });
    AppInjector.get(NotificationService).show('success', 'Successfully edit product', 5000);
    const url = `/admin/product/edit/${action.data.id}`;
    const param = action.params;
    router.navigate([url], { queryParams: param });
    // router.routeReuseStrategy.shouldReuseRoute = () => false;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateProductRequested() {
  yield takeEvery(COLLECTION_EDIT_PRODUCT_REQUESTED, updateProduct);
}

export default [watchCreateProductRequested, watchCreateProductFormRequested];
