import Model from './Model';

export const NONE_TYPE_SHIPPING_METHOD = 'none';
export const MANUAL_TYPE_SHIPPING_METHOD = 'manual';
export const EASYSHIP_TYPE_SHIPPING_METHOD = 'easyship';
export const DPD_TYPE_SHIPPING_METHOD = 'dpd';
export const SHIPSTATION_TYPE_SHIPPING_METHOD = 'shipstation';
export const MONDIALRELAY_TYPE_SHIPPING_METHOD = 'mondialrelay';
export const DROPOFF_TYPE_SHIPPING_METHOD = 'dropoff';
export const DHL_TYPE_SHIPPING_METHOD = 'dhl';

export const LIST_SHIPING_METHODS = [
  { label: 'None', value: NONE_TYPE_SHIPPING_METHOD },
  { label: 'Manual', value: MANUAL_TYPE_SHIPPING_METHOD },
  { label: 'Easyship', value: EASYSHIP_TYPE_SHIPPING_METHOD },
  { label: 'Dpd', value: DPD_TYPE_SHIPPING_METHOD },
  { label: 'Shipstation', value: SHIPSTATION_TYPE_SHIPPING_METHOD },
  { label: 'Mondialrelay', value: MONDIALRELAY_TYPE_SHIPPING_METHOD },
  { label: 'Dropoff', value: DROPOFF_TYPE_SHIPPING_METHOD },
  { label: 'Dhl', value: DHL_TYPE_SHIPPING_METHOD }
];

class ShippingMethod extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

class ShippingMethodV2 extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default ShippingMethod;
