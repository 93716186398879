import Model from './Model';
import * as _ from 'lodash';

export class ShipmentAddress extends Model {
  constructor(options) {
    super();

    this.bind(options);
  }
}
