import {
  ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_SUCCESSED,
  FETCH_CATEGORY_DICTIONARY_SUCCESSED,
  DELETE_CATEGORY_ATTRIBUTE_SUCCESSED,
  EDIT_CATEGORY_DICTIONARY_SUCCEEDED,
  ADMIN_ATTRIBUTES_CATEGORY_FILTER_SELECTED,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_SUCCESS,
  ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_CATEGORY_SYNCHRONISE_ATTRIBUTES_REQUEST, TOGGLE_CATEGORY_DICTIONARY_VALIDATION_SUCCEEDED
} from './category.action';
import * as _ from 'lodash';
import {
  ATTRIBUTE_COMPONENT,
  ADMIN_ATTRIBUTES_SELECT_MARKETPLACE
} from '../attributes.action';
import { CREATE_CATEGORY_SUCCEEDED } from '../../../admin/category/create/create.actions';
import { EDIT_CATEGORY_SUCCEEDED } from '../../category/edit/edit.actions';

export const Category = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    },
    marketplace_categories: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case ADMIN_ATTRIBUTES_FETCH_CATEGORY_ATTRIBUTE_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_form = false;
        return item;
      });
      return { ...state, ...{ fetched: true, items: action.data, pagination: action.pagination } };

    case CREATE_CATEGORY_SUCCEEDED:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            state.items.push(action.data);
            return _.assign({}, state, { create: { created: true, data: {} } });
        }
      }
      return state;

    case EDIT_CATEGORY_SUCCEEDED:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            // tslint:disable-next-line:no-shadowed-variable
            const item = _.find(state.items, { id: action.data.id });
            if (!_.isNil(item)) {
              item.is_show_edit_form = false;
              item.name = action.data.name;
            }
            return { ...state, ...{ items: state.items } };
        }
      }
      return state;

    case DELETE_CATEGORY_ATTRIBUTE_SUCCESSED:
      return {
        ...state,
        ...{
          items: _.filter(state.items, i => i.id !== action.data),
          dictionary: { ...state.dictionary, ...{ items: _.filter(state.dictionary.items, i => i.attribute_id !== action.data) } }
        }
      };

    case FETCH_CATEGORY_DICTIONARY_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_dictionary_form = false;
        return item;
      });
      return _.assign({}, state, { dictionary: { fetched: true, items: action.data } });

    case EDIT_CATEGORY_DICTIONARY_SUCCEEDED:
      const item = _.find(state.dictionary.items, { id: action.data.id });
      if (!_.isNil(item)) {
        item.is_show_edit_dictionary_form = false;
        item.value = action.data.value;
      }
      return _.assign({}, state, { dictionary: { items: state.dictionary.items } });

    case ADMIN_ATTRIBUTES_CATEGORY_FILTER_SELECTED:
      return _.assign({}, state, {
        items: _.map(state.items, cat => {
          cat.selectedDictionary = cat.dictionaries ? cat.dictionaries
            .find(d => d.marketplace_id === action.data.marketplace.selected.id) : null;
          return cat;
        })
      });
    case TOGGLE_CATEGORY_DICTIONARY_VALIDATION_SUCCEEDED:
      return _.assign({}, state, {
        items: _.map(state.items, cat => {
          if (cat.selectedDictionary && cat.selectedDictionary.id === action.data.id) {
            return {
              ...cat,
              selectedDictionary: action.data
            };
          } else {
            return {
              ...cat
            }
          }
        })
      });


    case ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CATEGORIES_SUCCESS:
      return _.assign({}, state, {
        marketplace_categories: {
          fetched: true,
          items: action.data
        },
        items: _.map(state.items, cat => {
          let mapped;
          if (cat.selectedDictionary) {
            mapped = action.data.find(d => d.id === cat.selectedDictionary.source_id && d.name === cat.selectedDictionary.value);
          }
          cat.mappedAttribute = mapped ? mapped : null;
          return cat;
        })
      });

    case ADMIN_ATTRIBUTES_CATEGORY_UPDATE_DICTIONARY_SUCCESS:
      return _.assign({}, state, {
        items: _.map(state.items, cat => {
          if (cat.id === action.data.attribute_id) {
            cat.is_show_edit_dictionary_form = false;
            cat.dictionaries = [...cat.dictionaries, action.data];
            cat.selectedDictionary = cat.dictionaries.find(s => s.id === action.data.id);
          }
          return cat;
        })
      });

    case ADMIN_ATTRIBUTES_SELECT_MARKETPLACE:
      if (action.direct) {
        return _.assign({}, state, { marketplace_categories: _.assign({}, state.marketplace_categories, { fetched: false }) });
      } else {
        return state;
      }

    case ADMIN_ATTRIBUTES_CATEGORY_SYNCHRONISE_ATTRIBUTES_REQUEST:
      return _.assign({}, state, { marketplace_categories: _.assign({}, state.marketplace_categories, { fetched: false }) });

    default:
      return state;
  }
};
