import { NullTemplateVisitor } from '@angular/compiler';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';


@Component({
  selector: 'shipping-method-rules',
  templateUrl: './shipping-method-rules.component.html',
  styleUrls: ['./shipping-method-rules.component.scss']
})
export class ShippingMethodRulesComponent implements OnChanges, OnInit {

  @Input() constants = {
    zones: [],
    shipping_methods: [],
    rates: []
  };

  @Input() rules = [];
  @Output() saved = new EventEmitter<object>();
  @Output() changeRule = new EventEmitter<object>();
  @Output() clickToEditRate = new EventEmitter<object>();

  actualRules: Array<{
    key: number,
    shipping_method_id: number,
    from_zone_id: number,
    to_zone_id: number,
    rate: string,
    manual_value: string,
    is_edit_rate: boolean
  }> = [];

  constructor() {}

  fillShippingMethodRules() {
    if (Array.isArray(this.rules)) {
      this.actualRules = this.rules.map((config, k) => ({
        key: k,
        shipping_method_id: config.shipping_method_id,
        from_zone_id: config.from_zone_id,
        to_zone_id: config.to_zone_id,
        rate: config.rate,
        manual_value: config.manual_value,
        is_edit_rate: false
      }));
    }
  }

  ngOnInit(): void {
    this.fillShippingMethodRules();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.fillShippingMethodRules();
    }
  }

  addItem() {
    this.actualRules.push({
      key: this.actualRules.length,
      shipping_method_id: null,
      from_zone_id: null,
      to_zone_id: null,
      rate: 'default',
      manual_value: null,
      is_edit_rate: false
    });
  }

  deleteItem(key) {
    this.actualRules = this.actualRules.filter(s => s.key !== key);
  }

  saveShippingMethodRules() {
    this.saved.emit(this.actualRules);
  }

  ruleChange(rule) {
    if (rule.is_edit_rate) {
      this.changeRule.emit(rule);
    } else {
      _.map(this.actualRules, i => {
        if (i.key === rule.key) {
          i.is_edit_rate = false;
          i.rate = 'default';
        }
      });
    }
  }

  editRate(rule) {
    _.map(this.actualRules, i => {
      if (i.key === rule.key) {
        i.is_edit_rate = true;
      } else {
        i.is_edit_rate = false;
      }
    });
    this.clickToEditRate.emit(rule);
  }

  refreshRate(rule) {
    _.map(this.actualRules, i => {
      if (i.key === rule.key) {
        i.rate = 'default';
        i.is_edit_rate = false;
      }
    });
  }
}
