import { Router } from '@angular/router';
import { API_CALL_ERROR } from './../../../../store/action';
import { PreloaderService } from './../../../../common/services/preloader/preloader.service';
import { RETAILER_USER_COMP } from './../user.const';
import { RETAILER_PRODUCT_COMP } from '../../product/product.const';
import { AppInjector } from './../../../../app-injector';
import {
  FETCH_CUSTOMER_OF_RETAILER_REQUESTED,
  FETCH_CUSTOMER_OF_RETAILER_SUCCEEDED,
  FILTER_RETAILER_USERS_REQUESTED,
  FILTER_RETAILER_USERS_SUCCEEDED,
  FILTER_RETAILER_USERS_PROCESSING,
  FETCH_LIST_RETAILER_USER_REQUESTED,
  FETCH_LIST_RETAILER_USER_SUCCEEDED
} from './list.actions';
import { takeEvery, put } from 'redux-saga/effects';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';

function* fetchCustomerOfRetailerRequested(action) {
  try {
    AppInjector.get(PreloaderService).show();
    const results = yield AppInjector.get(ApiService)
      .user.getCustomersOfRetailer(action.id, action.data)
      .toPromise();
    yield put({
      type: FETCH_CUSTOMER_OF_RETAILER_SUCCEEDED,
      data: results
    });

    AppInjector.get(PreloaderService).hide();
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchCustomerOfRetailerRequested() {
  yield takeEvery(FETCH_CUSTOMER_OF_RETAILER_REQUESTED, fetchCustomerOfRetailerRequested);
}

function* reloadUsers(action) {
  try {
    AppInjector.get(PreloaderService).show();
    if (!_.isUndefined(action.com)) {
      switch (action.com) {
        case RETAILER_USER_COMP:
          AppInjector.get(Router).navigate(['/user']);
          break;

        default:
          break;
      }
    }
  } catch (e) {
    AppInjector.get(PreloaderService).show();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* filterUsers(action) {
  try {
    AppInjector.get(PreloaderService).show();
    yield put({ type: FILTER_RETAILER_USERS_PROCESSING });

    const params = {
      pagination: action.data.pagination,
      sort: action.data.sort,
      filter: action.data.filter,
      search: action.data.search
    };
    const results = yield AppInjector.get(ApiService)
      .user.get(params)
      .toPromise();
    // const results = yield AppInjector.get(ApiService)
    //   .user.get(action.data.pagination, action.data.sort, action.data.filter, action.data.search)
    //   .toPromise();

    if (!_.isUndefined(action.com)) {
      switch (action.com) {
        case RETAILER_USER_COMP:
          yield put({
            type: FILTER_RETAILER_USERS_SUCCEEDED,
            data: results,
            com: RETAILER_USER_COMP
          });
          break;
        case RETAILER_PRODUCT_COMP:
          yield put({
            type: FILTER_RETAILER_USERS_SUCCEEDED,
            data: results,
            com: RETAILER_PRODUCT_COMP
          });
          break;
        default:
          break;
      }
    }
    AppInjector.get(PreloaderService).hide();
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFilterUsersRequest() {
  yield takeEvery(FILTER_RETAILER_USERS_REQUESTED, filterUsers);
}

function* fetchListUserRequested(action) {
  try {
    const results = yield AppInjector.get(ApiService)
      .user.list(action.data)
      .toPromise();

    yield put({
      type: FETCH_LIST_RETAILER_USER_SUCCEEDED,
      data: results
    });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchListUserRequested() {
  yield takeEvery(FETCH_LIST_RETAILER_USER_REQUESTED, fetchListUserRequested);
}

export default [watchFetchCustomerOfRetailerRequested, watchFilterUsersRequest, watchFetchListUserRequested];
