import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Category from '../../models/Category';
import * as _ from 'lodash';
import { PreloaderService } from '../../common/services/preloader/preloader.service';

@Injectable()
export class CategoryService extends ServiceProvider {
  public url = '/api/v1/categories';
  public model = Category;

  getNestedCategory(categoryId = 0): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${categoryId}/path`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  all(params = {}): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/all').pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new Category(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  allWithExtras(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url)).pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new Category(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getMainCategory(params = {}): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/main_category').pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  getSubCategory(mainCategory): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/sub_category/${mainCategory}`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  getItemType(mainCategory, subCategory): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/item_type/${mainCategory}/${subCategory}`).pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new Category(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getChildCategory(mainCategory, subCategory, itemType): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/child_category/${mainCategory}/${subCategory}/${itemType}`).pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new Category(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getConditionTags(params = {}): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/condition-tags', { params: queryParams }).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
