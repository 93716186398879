import { GET_ALL_USER_SERVICE_CONFIGURATION_SUCCESS, SYNC_USER_SERVICE_CONFIGURATION_SUCCESS, GET_USER_DETAIL_SUCCESS } from './service-configuration.actions';
import * as _ from 'lodash';

export const ServiceConfiguration = (state = { fetched: false, items: [], user: {} }, action) => {
  switch (action.type) {
    case GET_USER_DETAIL_SUCCESS: {
      return _.assign({}, state, { user: _.assign({}, state.user, { fetched: true, item: action.data }) });
    }
    case GET_ALL_USER_SERVICE_CONFIGURATION_SUCCESS: {
      return _.assign({}, state, { fetched: true, items: action.data });
    }
    case SYNC_USER_SERVICE_CONFIGURATION_SUCCESS: {
      return _.assign({}, state, { fetched: false, user: _.assign({}, state.user, { fetched: false }) });
    }
    default:
      return state;
  }
};
