import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from './../../../../store/store.module';
import {
  FETCH_PRODUCTS_REQUESTED,
  SELECT_ALL_PRODUCT,
  ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED,
  PUBLISH_PRODUCT_REQUESTED,
  ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED,
  EXPORT_PRODUCT_REQUESTED,
  FETCH_ALL_CONDITION_REQUESTED,
  FETCH_ALL_DESIGNER_REQUESTED,
  FETCH_ALL_MARKETPLACE_REQUESTED,
  FETCH_ALL_CATEGORY_REQUESTED,
  GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED,
  UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED,
  FETCH_ALL_STATUS_REQUESTED,
  RESYNC_PRODUCT_FROM_RETAILER_REQUESTED,
  ADD_OR_REMOVE_SELECTED_PRODUCT,
  REMOVE_ALL_SELECTED_PRODUCT,
  ADD_ALL_PRODUCTS_IN_PAGE,
  REMOVE_ALL_PRODUCTS_IN_PAGE,
  REMOVE_ALL_DISCOUNT_REQUESTED,
  MODIFY_BY_CSV_REQUESTED
} from './list.actions';
import * as _ from 'lodash';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductStatuses, isBindingResponsiveTableClass, isBindingFilterSearchNoPaddingClass, USER_ROLES } from '../../../../app.const';
import { QueryParser } from '../../../../common/util/query-parser';
import { createRouter, importRouter } from '../product.const';
import { FETCH_ALL_DATASETS_REQUESTED, GET_ALL_DATASET_CONDITIONS_REQUESTED } from '../../../retailer/dataset/dataset.action';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { BaseComponent } from '../../../base.component';
import { GET_ALL_STATUSS_REQUESTED } from '../../status/status.action';
import Cookies from 'js-cookie';
import { ProductService } from '../../../../api/admin/product/product.service';
import { UserService } from '../../../../api/admin/user/user.service';
import { GET_ALL_TAGS_REQUESTED } from '../../tag/tag.action';
import { HostListener } from '@angular/core';
import { FETCH_RETAILERS_REQUESTED } from '../../retailer/list/list.actions';
import { ApiService } from '../../../../api/api.service';
import { PRODUCT_MANAGEMENT_PUBLISH_STATUS } from '../../../../models/ProductManagement';
import { DateTimePicker, VALIDATOR_REQUIRED, TypeAheadWithoutApi } from '@reflaunt/reactive-form';
import { InputBase } from '@reflaunt/reactive-form';
import { Validators } from '@angular/forms';
import Swal from 'sweetalert2';
declare const $: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseComponent implements OnInit, OnDestroy {
  public reducer = 'Admin.Product.list';
  public isBindingResponsiveTableClass = isBindingResponsiveTableClass;
  public isBindingFilterSearchNoPaddingClass = isBindingFilterSearchNoPaddingClass;
  public dataset;
  public state;
  public selection = false;
  public selectToggle = false;
  public skus;
  public condition;
  public designer;
  public category;
  public status_id;
  public selling_status;
  public marketplace;
  public min_price;
  public max_price;
  public tag;
  public scrollOver;
  public retailer;
  public with_description;
  public material_available;
  public size_available;
  public is_test;
  public list_with_trash = 'no';
  public has_retouched_image;
  is_published;
  publish_status;
  search_type = 'multiple_fields';
  search_value;
  public filters_retailer = {
    role_ids: USER_ROLES.RETAILER
  };
  private filters = {
    dataset_id: undefined,
    deleted: undefined
  };
  public filters_marketplace = {
    role_ids: USER_ROLES.MARKETPLACE
  };
  public states = [
    {
      label: 'Available',
      value: 'available'
    },
    {
      label: 'Deleted',
      value: 'deleted'
    }
  ];

  public product_statuses = [
    {
      label: 'Selling',
      value: 1
    },
    {
      label: 'Not Selling',
      value: 0
    }
  ];

  public description_state = [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ];
  public material_state = [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ];
  public size_state = [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ];
  public is_test_state = [
    {
      label: 'Yes',
      value: 1
    },
    {
      label: 'No',
      value: 0
    }
  ];
  public trash_state = [
    {
      label: 'hide',
      value: 'no'
    },
    {
      label: 'show',
      value: 'yes'
    }
  ];
  public retouched_state = [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ];
  searchTypes = [
    { label: 'Multiple fields', value: 'multiple_fields', description: 'Multi-fields search does not support multi-products search.' },
    { label: 'Product ID', value: 'product_id', description: 'Insert several product_ids separated by comma(s) to search for several product_ids at the same time.' },
    { label: 'SKU', value: 'sku', description: 'Insert several SKUs separated by comma(s) to search for several SKUs at the same time.' },
    { label: 'Supplier SKU', value: 'supplier_sku', description: 'Insert several Supplier SKUs separated by comma(s) to search for several Supplier SKUs at the same time.' },
    { label: 'Warehouse SKU', value: 'warehouse_sku', description: 'Insert several Warehouse SKUs separated by comma(s) to search for several Warehouse SKUs at the same time.' }
  ];
  public currentUrl = window.location.pathname;
  public store;
  public queryParser;
  public status = ProductStatuses;
  public navigationSubscription: Subscription;
  public supParams = [
    'sort',
    'min_price',
    'max_price',
    'constraints',
    'page',
    'per_page',
    'search',
    'skus',
    'dataset_id',
    'deleted',
    'skus',
    'condition_id',
    'designer_id',
    'marketplace_id',
    'category_id',
    'tag_id',
    'retailer_id',
    'start_date',
    'end_date',
    'status_id',
    'selling_status',
    'with_description',
    'material_available',
    'size_available',
    'search_type',
    'search_value',
    'is_published',
    'publish_status',
    'location_id',
    'is_test',
    'with_trash',
    'has_retouched_image'
  ];
  public login;
  public routerCreate = createRouter();
  public importRouter = importRouter();
  public PRODUCT_MANAGEMENT_PUBLISH_STATUS = PRODUCT_MANAGEMENT_PUBLISH_STATUS;
  public filter_created_at: InputBase<any>[] = [
    new DateTimePicker({
      key: 'start_date',
      label: 'From',
      classes: ['col-lg-6'],
      group_classes: ['col-lg-12'],
      monthNavigator: true,
      yearNavigator: true,
      showIcon: true
    }),
    new DateTimePicker({
      key: 'end_date',
      label: 'To',
      classes: ['col-lg-6'],
      group_classes: ['col-lg-12'],
      monthNavigator: true,
      yearNavigator: true,
      showIcon: true
    })
  ];
  datasets;
  public locations;
  public location_id;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    store: Store,
    queryParser: QueryParser,
    private product_service: ProductService,
    private api: ApiService
  ) {
    super();
    this.store = store.getInstance();
    this.queryParser = queryParser;
    this.store.dispatch({
      type: GET_ALL_STATUSS_REQUESTED
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.skus = this.activatedRoute.snapshot.queryParams.skus;
        let data: any = _.assign(this.queryParser.parse(this.supParams), { search_value: encodeURIComponent(this.activatedRoute.snapshot.queryParams.search_value) });
        data.includes = 'images_list_product';

        this.store.dispatch({
          type: FETCH_PRODUCTS_REQUESTED,
          data: data
        });
        this.selection = false;
        this.marketplace = this.activatedRoute.snapshot.queryParams.marketplace_id;
        this.category = this.activatedRoute.snapshot.queryParams.category_id;
        this.designer = this.activatedRoute.snapshot.queryParams.designer_id;
        this.condition = this.activatedRoute.snapshot.queryParams.condition_id;
        this.tag = this.activatedRoute.snapshot.queryParams.tag_id;
        this.retailer = this.activatedRoute.snapshot.queryParams.retailer_id;
        this.dataset = this.activatedRoute.snapshot.queryParams.dataset_id;
        this.status_id = this.activatedRoute.snapshot.queryParams.status_id;
        this.selling_status = this.activatedRoute.snapshot.queryParams.selling_status;
        this.with_description = this.activatedRoute.snapshot.queryParams.with_description;
        this.material_available = this.activatedRoute.snapshot.queryParams.material_available;
        this.size_available = this.activatedRoute.snapshot.queryParams.size_available;
        this.search_value = this.activatedRoute.snapshot.queryParams.search_value;
        this.search_type = this.activatedRoute.snapshot.queryParams.search_type || 'multiple_fields';
        this.is_published = this.activatedRoute.snapshot.queryParams.is_published;
        this.location_id = this.activatedRoute.snapshot.queryParams.location_id;
        this.is_test = this.activatedRoute.snapshot.queryParams.is_test;
        this.has_retouched_image = this.activatedRoute.snapshot.queryParams.has_retouched_image;
        AppInjector.get(ApiService)
          .dataset.list({ is_active: true })
          .subscribe((res) => {
            this.datasets = res;
          });
      }
    });
  }
  @HostListener('window:scroll', ['$event'])
  scrollHandler(e) {
    const action_height = $('.action-shortcut').offset().top;
    if ($(window).scrollTop() > action_height) {
      return (this.scrollOver = true);
    } else {
      return (this.scrollOver = false);
    }
  }

  ngOnInit() {
    this.init();
    this.store.dispatch({ type: FETCH_ALL_DATASETS_REQUESTED });
    this.store.dispatch({ type: GET_ALL_TAGS_REQUESTED });
    this.store.dispatch({ type: FETCH_ALL_CONDITION_REQUESTED });
    this.store.dispatch({ type: FETCH_ALL_CATEGORY_REQUESTED });
    this.store.dispatch({ type: FETCH_ALL_STATUS_REQUESTED });
    this.store.dispatch({ type: GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED, data: this.filters_retailer });
    this.store.dispatch({ type: FETCH_ALL_DESIGNER_REQUESTED, data: { includes: 'designer' } });
    this.store.dispatch({
      type: FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED,
      data: { constraints: JSON.stringify(this.filters_marketplace), includes: 'marketplace', is_integrated: 1 }
    });
    let inputs_designer: InputBase<any>[] = [
      new TypeAheadWithoutApi({
        key: 'designer_id',
        label: 'Designer',
        classes: ['col-12', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12'],
        order: 1
      })
    ];
    this.store.dispatch({ type: UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED, inputs: inputs_designer });
    this.getAllLocation();
  }

  onChangeFilterDate = (form) => {
    form.value.start_date = form.value.start_date === '' ? null : form.value.start_date;
    form.value.end_date = form.value.end_date === '' ? null : form.value.end_date;
    this.router.navigate([], { queryParams: form.value, queryParamsHandling: 'merge' });
  };
  onChangeDesigner = (form) => {
    this.router.navigate([], { queryParams: { designer_id: form.value.designer_id.value }, queryParamsHandling: 'merge' });
  };
  onSubmit = (form) => {
    console.log(form);
  };

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  changeProductStatus(item) {
    this.store.dispatch({ type: ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED, data: this.getProductStatus(item) });
  }

  togglePublishSection(item) {
    if (!_.isBoolean(item.showPublishSection)) {
      item.showPublishSection = true;
    } else {
      item.showPublishSection = !item.showPublishSection;
    }
  }

  publish(item) {
    if (_.isEmpty(item.categories.data)) {
      AppInjector.get(NotificationService).show('warning', 'Category not found', 3000);
      return false;
    }
    const marketplaces = _.filter(item.availableMarketplaces, (i) => i.selected === true);
    const diffA = _.differenceBy(item.marketplaces, marketplaces, 'id');
    const diffB = _.differenceBy(marketplaces, item.marketplaces, 'id');
    if (diffA.length === 0 && diffB.length === 0) {
      AppInjector.get(NotificationService).show('warning', 'No update on the marketplace', 3000);
    } else {
      this.store.dispatch({ type: PUBLISH_PRODUCT_REQUESTED, data: item, marketplaces });
    }
  }

  filterProductsByDataset(dataset) {
    this.router.navigate([], { queryParams: { dataset_id: dataset !== 'undefined' ? dataset : undefined, tag_id: undefined }, queryParamsHandling: 'merge' });
  }

  filterProductsByState(state) {
    this.filters.deleted = state.value;
    let queryParams = this.activatedRoute.snapshot.queryParams;
    queryParams = _.assign({}, queryParams, { deleted: this.filters.deleted });
    let params = _.assign({}, this.activatedRoute.snapshot.params, { queryParams });
    this.router.navigate([this.currentUrl], params);
  }
  filterProductsByCondition(condition) {
    this.router.navigate([], { queryParams: { condition_id: condition !== 'undefined' ? condition : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByDesigner(designer) {
    this.router.navigate([], { queryParams: { designer_id: designer !== 'undefined' ? designer : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByDescription(with_description) {
    this.router.navigate([], { queryParams: { with_description: with_description !== 'undefined' ? with_description : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByMaterialAvailable(material_available) {
    this.router.navigate([], { queryParams: { material_available: material_available !== 'undefined' ? material_available : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsBySizeAvailable(size_available) {
    this.router.navigate([], { queryParams: { size_available: size_available !== 'undefined' ? size_available : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByCategory(category) {
    this.router.navigate([], { queryParams: { category_id: category !== 'undefined' ? category : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByStatus(status) {
    this.router.navigate([], { queryParams: { status_id: status !== 'undefined' ? status : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsBySellingStatus(status) {
    this.router.navigate([], { queryParams: { selling_status: status !== 'undefined' ? status : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByMarketplace(marketplace) {
    this.router.navigate([], { queryParams: { marketplace_id: marketplace !== 'undefined' ? marketplace : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByTag(tag) {
    this.router.navigate([], { queryParams: { tag_id: tag !== 'undefined' ? tag : undefined, dataset_id: undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByRetailer(retailer) {
    this.router.navigate([], { queryParams: { retailer_id: retailer !== 'undefined' ? retailer : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByIsPublished(is_published) {
    this.router.navigate([], { queryParams: { is_published: is_published !== 'undefined' ? is_published : undefined }, queryParamsHandling: 'merge' });
  }
  filterProductsByPublishStatus(publish_status) {
    this.router.navigate([], { queryParams: { publish_status: publish_status !== 'undefined' ? publish_status : undefined }, queryParamsHandling: 'merge' });
  }
  filterByPrice(form) {
    this.router.navigate([], { queryParams: { min_price: form.value.min_price, max_price: form.value.max_price }, queryParamsHandling: 'merge' });
  }
  filterByLocation(location_id) {
    this.router.navigate([], { queryParams: { location_id: location_id !== 'undefined' ? location_id : undefined }, queryParamsHandling: 'merge' });
  }
  filterByIsTest(is_test) {
    this.router.navigate([], { queryParams: { is_test: is_test !== 'undefined' ? is_test : undefined }, queryParamsHandling: 'merge' });
  }
  filterWithTrash(with_trash) {
    this.router.navigate([], { queryParams: { with_trash: with_trash !== 'undefined' ? with_trash : undefined }, queryParamsHandling: 'merge' });
  }
  filterWithRetouchedImage(has_retouched_image) {
    this.router.navigate([], { queryParams: { has_retouched_image: has_retouched_image !== 'undefined' ? has_retouched_image : undefined }, queryParamsHandling: 'merge' });
  }
  changeCustomType(search_type) {
    this.search_type = search_type;
  }
  searchByType(form) {
    console.log(form.value);

    console.log(this.search_type);
    this.router.navigate([], {
      queryParams: {
        search_type: this.search_type !== 'undefined' ? this.search_type : undefined,
        search_value: form.value.search_value !== 'undefined' ? form.value.search_value : undefined
      },
      queryParamsHandling: 'merge'
    });
  }

  mouseEnter(index) {
    $('.alert-index-' + index).show();
  }

  mouseLeave(index) {
    $('.alert-index-' + index).hide();
  }

  moreInfoDropdownToggle(event: any) {
    let dropdownMenu = event.target.nextSibling;
    dropdownMenu.classList.toggle('d-none');
  }

  getIntegratedMarketplace(marketplaces) {
    return _.filter(marketplaces, (marketplace) => marketplace.marketplace.is_integrated);
  }

  resyncFromRetailer(product) {
    this.store.dispatch({ type: RESYNC_PRODUCT_FROM_RETAILER_REQUESTED, data: product.id });
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.Product.list,
      login: state.Auth.login
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch
    };
  }

  getProductStatus(product) {
    return this.payload.items_status.find((item) => item.id === product.id);
  }

  onMoreInfoClickedOutside(event) {
    let dropdowns = document.getElementsByClassName('more-info__content');
    let activeDropdowns = _.filter(Array.from(dropdowns), (item) => {
      return !_.includes(Array.from(item.classList), 'd-none');
    });
    activeDropdowns.forEach((item) => {
      item.classList.add('d-none');
    });
  }

  getStatusLabel(val, statuses) {
    const status = _.find(statuses, (i) => i.id === val);
    return status.name;
  }

  shouldShowExportCsvButton() {
    if (this.login.loggedin) {
      if (this.login.profile.isAdmin()) {
        let data: any = this.queryParser.parse(this.supParams);
        if (data.constraints) {
          if (data.constraints.indexOf('marketplace_id') > -1) {
            return true;
          }
        }
      } else if (this.login.profile.isMarketplace()) {
        return true;
      }
    }
    return false;
  }

  exportAsCsv() {
    let data: any = this.queryParser.parse(this.supParams);
    let marketplace_id = null;
    if (data.constraints) {
      const json = JSON.parse(data.constraints);
      if (json) {
        marketplace_id = json.marketplace_id;
      }
    }
    this.store.dispatch({ type: EXPORT_PRODUCT_REQUESTED, data: marketplace_id });
  }

  searchBySku(form) {
    this.router.navigate([], { queryParams: { skus: form.value.skus } });
  }

  onSelect(item) {
    this.store.dispatch({ type: ADD_OR_REMOVE_SELECTED_PRODUCT, data: item });
  }

  resetSelected() {
    this.selection = false;
    this.store.dispatch({ type: REMOVE_ALL_SELECTED_PRODUCT });
  }
  selectAllProductOnPage() {
    const allProductInThePage = this.store.getState().Admin.Product.list.items;
    this.selectToggle = false;
    this.selection = true;
    this.store.dispatch({ type: ADD_OR_REMOVE_SELECTED_PRODUCT, data: allProductInThePage });
  }

  addTags() {
    const productsCount = this.payload.selected_products_count;
    if (productsCount < 1) {
      AppInjector.get(NotificationService).show('warning', 'Please choose atleast one product', 3000);
      return false;
    }
    AppInjector.get(Router).navigate(['/', 'admin', 'product', 'add-tags']);
  }

  bulkPush() {
    const productsCount = this.payload.selected_products_count;
    Cookies.set('queryParams', JSON.stringify(this.activatedRoute.snapshot.queryParams));
    AppInjector.get(Router).navigate(['/', 'admin', 'product', 'bulk-push']);
  }

  bulkDiscount() {
    const productsCount = this.payload.selected_products_count;
    Cookies.set('queryParams', JSON.stringify(this.activatedRoute.snapshot.queryParams));
    AppInjector.get(Router).navigate(['/', 'admin', 'product', 'bulk-discount']);
  }

  removeBulkDiscount() {
    AppInjector.get(Router).navigate(['/', 'admin', 'product', 'remove-bulk-discount']);
  }

  setThumbnailImage(marketplace) {
    if (marketplace.images.length > 0) {
      return marketplace.images[0].url;
    } else {
      return 'assets/images/thumbnail/hoodies.png';
    }
  }
  getQueryEditProduct() {
    Cookies.set('queryParams', JSON.stringify(this.activatedRoute.snapshot.queryParams));
  }
  toggleSelectProduct() {
    this.selection = !this.selection;
    const allProductInThePage = this.store.getState().Admin.Product.list.items;
    if (this.selection) {
      this.store.dispatch({ type: ADD_ALL_PRODUCTS_IN_PAGE, data: allProductInThePage });
    } else {
      this.store.dispatch({ type: REMOVE_ALL_PRODUCTS_IN_PAGE, data: allProductInThePage });
    }
  }
  getMarketplaceStatusClass(marketplace, statuses) {
    if (marketplace && statuses) {
      const statusId = marketplace.product_management.data.status_id;
      const lastOperation = marketplace.product_management.data.last_operation;
      const lastOperationStatus = marketplace.product_management.data.last_operation_status;
      if (statusId) {
        const status = statuses.find((each) => each.id === statusId);
        if (status) {
          if (lastOperation === 'UPDATE' || lastOperation === 'DELETE') {
            if (lastOperationStatus === 'FAIL' || lastOperationStatus === 'PENDING') {
              return 'warning';
            }
          }
          return status.slug;
        }
      }
    }
    return '';
  }
  hasMarketplaceError(marketplaces) {
    if (marketplaces) {
      return !!marketplaces.find((marketplace) => {
        const lastOperationStatus = marketplace.product_management.data.last_operation_status;
        return lastOperationStatus === 'FAIL' || lastOperationStatus === 'PENDING';
      });
    }
    return false;
  }

  getAllLocation() {
    this.api.location.listAll().subscribe((res: any) => {
      this.locations = res;
    });
  }
  importCsvOpenModal() {
    (Swal as any)
      .fire({
        title: 'Type of import',
        text: 'Please select the action you want to perform',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Upload products',
        cancelButtonText: 'Modify marketplace description',
        customClass: {
          confirmButton: 'import-csv-confirm'
        }
      })
      .then((result) => {
        if (result.value) {
          this.router.navigate(importRouter());
        }
        if (result.dismiss === 'cancel') {
          this.router.navigate(['/admin/product/modify-product-description']);
        }
      });
  }

  modifyByCsvOpenModal() {
    (Swal as any)
      .fire({
        title: 'Please Select CSV File',
        input: 'file',
        inputAttributes: {
          accept: '.csv',
          'aria-label': 'Upload your CSV file'
        },
        showLoaderOnConfirm: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Upload',
        cancelButtonText: 'Cancel',
        preConfirm: (result) => {
          let params = {
            files: result
          };
          const uploaded = this.api.file.upload(params);
          return uploaded;
        }
      })
      .then((result) => {
        if (result.value && result.value.data[0].full_path) {
          this.store.dispatch({ type: MODIFY_BY_CSV_REQUESTED, data: { file_url: result.value.data[0].full_path } });
        }
        if (result.dismiss === 'cancel') {
          console.log('ok');
        }
      });
  }

  isValid(marketplace) {
    return _.isNil(marketplace);
  }
}
