export const RENDER_EDIT_ADMIN_LOCATION_FORM_REQUESTED = 'RENDER_EDIT_ADMIN_LOCATION_FORM_REQUESTED';
export const FILL_ADMIN_LOCATION_DETAIL_FORM = 'FILL_ADMIN_LOCATION_DETAIL_FORM';
export const FETCH_ADMIN_LOCATION_REQUESTED = 'FETCH_ADMIN_LOCATION_REQUESTED';
export const FETCH_ADMIN_LOCATION_SUCCESSED = 'FETCH_ADMIN_LOCATION_SUCCESSED';
export const EDIT_ADMIN_LOCATION_REQUESTED = 'EDIT_ADMIN_LOCATION_REQUESTED';
export const EDIT_ADMIN_LOCATION_SUCCESSED = 'EDIT_ADMIN_LOCATION_SUCCESSED';
export const DELETE_ADMIN_LOCATION_REQUESTED = 'DELETE_ADMIN_LOCATION_REQUESTED';
export const DELETE_ADMIN_LOCATION_SUCCESSED = 'DELETE_ADMIN_LOCATION_SUCCESSED';
export const UPDATE_ADMIN_LOCATION_DETAIL_FORM_INPUT_OPTIONS = 'UPDATE_ADMIN_LOCATION_DETAIL_FORM_INPUT_OPTIONS';


export const fetchAdminLocationRequested = (payload) => {
    return {
        type: FETCH_ADMIN_LOCATION_REQUESTED,
        data: payload,
    };
};

export const fetchAdminLocationSuccessed = (payload) => {
    return {
        type: FETCH_ADMIN_LOCATION_SUCCESSED,
        data: payload,
    };
};

