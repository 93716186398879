export const FETCH_CATEGORY_ATTRIBUTE_REQUESTED = 'FETCH_CATEGORY_ATTRIBUTE_REQUESTED';
export const FETCH_CATEGORY_ATTRIBUTE_SUCCESSED = 'FETCH_CATEGORY_ATTRIBUTE_SUCCESSED';
export const DELETE_CATEGORY_ATTRIBUTE_REQUESTED = 'DELETE_CATEGORY_ATTRIBUTE_REQUESTED';
export const DELETE_CATEGORY_ATTRIBUTE_SUCCESSED = 'DELETE_CATEGORY_ATTRIBUTE_SUCCESSED';
export const FETCH_CATEGORY_DICTIONARY_REQUESTED = 'FETCH_CATEGORY_DICTIONARY_REQUESTED';
export const FETCH_CATEGORY_DICTIONARY_SUCCESSED = 'FETCH_CATEGORY_DICTIONARY_SUCCESSED';
export const CREATE_CATEGORY_DICTIONARY_SUCCESSED = 'CREATE_CATEGORY_DICTIONARY_SUCCESSED';
export const RESET_CATEGORY_DICTIONARY = 'RESET_CATEGORY_DICTIONARY';
export const EDIT_CATEGORY_DICTIONARY_REQUESTED = 'EDIT_CATEGORY_DICTIONARY_REQUESTED';
export const EDIT_CATEGORY_DICTIONARY_SUCCEEDED = 'EDIT_CATEGORY_DICTIONARY_SUCCEEDED';
