import Model from './Model';
import Dictionary from './Dictionary';
import * as _ from 'lodash';

class Color extends Model {
  constructor(options) {
    super();
    (this as any).dictionaries = d => {
      return _.map(d.data, item => new Dictionary(item));
    };
    this.bind(options);
  }
}

export default Color;
