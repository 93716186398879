import { EDIT_LANGUAGE_SUCCEEDED, GET_LANGUAGE_SUCCEEDED } from './edit.actions';
import * as _ from 'lodash';

export const edit = (state = { updated: false, fetched: false }, action) => {
  switch (action.type) {
    case GET_LANGUAGE_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data, language_name: action.data.name });

    case EDIT_LANGUAGE_SUCCEEDED:
      return _.assign({}, state, { updated: true });

    default:
      return state;
  }
}
