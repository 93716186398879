import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import edit from './edit/edit.saga';
import { GET_ALL_ADMIN_ITEMS_COST_REQUESTED, GET_ALL_ADMIN_ITEMS_COST_SUCCESSED } from './items-cost.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAllItemsCosts(params?) {
  const fetched = yield select((state) => (state as any).Admin.ItemsCost.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.itemsCost.list(params).toPromise();
      return result;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select((state) => (state as any).Admin.ItemsCost.all.items);
    return data;
  }
}

function* watchFetchAllItemsCostsRequest() {
  yield takeLatest(GET_ALL_ADMIN_ITEMS_COST_REQUESTED, function* (action: any) {
    const data = yield call(fetchAllItemsCosts);
    yield put({ type: GET_ALL_ADMIN_ITEMS_COST_SUCCESSED, data: data });
  });
}

export default [...edit, watchFetchAllItemsCostsRequest];
