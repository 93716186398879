import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ShipmentAddress } from '../../../models/ShipmentAddress';

@Injectable()
export class ShipmentAddressService extends ServiceProvider {
  public url = '/api/v1/shipment-address';
  public model = ShipmentAddress;
}
