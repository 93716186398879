import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FETCH_RETAILER_COMMISSION_REQUESTED, GET_COMMISSION_CONFIG_REQUESTED, RENDER_COUNTRY_INPUT_FORM, UPDATE_COMMISSION_CONFIG_REQUESTED } from './commission-config.action';
import { setAppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { InputBase, SingleSelect2 } from '@reflaunt/reactive-form';
import * as _ from 'lodash';
import { NgForm, Validators } from '@angular/forms';
import { NotificationService } from './../../../../common/services/notification/notification.service';

@Component({
  selector: 'app-commission-config',
  templateUrl: './commission-config.component.html',
  styleUrls: ['./commission-config.component.scss']
})
export class CommissionConfigComponent extends BaseComponent implements OnInit, OnDestroy {
  public reducer = 'Admin.AdminRetailer.CommissionConfig';
  @ViewChild('form_config', { static: false }) form_config: NgForm;
  public keysChange = ['country'];
  public render_config: object = {};
  public countries;
  public country_id;
  public inputs: InputBase<any>[];
  public negativePattern = '^([-]d+(.d+))?$';
  public negativeRequired = false;
  public retailer_percentage_pattern = '^([1-9][0-9]+|[1-9])(\\.[0-9]+)?$';

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService, private notificationService: NotificationService) {
    super();
    this.route = route;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: GET_COMMISSION_CONFIG_REQUESTED,
          id: this.route.snapshot.params.id
        });
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  getCurrentService(item) {
    const { fetchRetailerCommissionByCountry } = this as any;
    fetchRetailerCommissionByCountry(item);
  }

  addItem() {
    this.payload.current_service.rs_shipping_costs.push({
      key: this.payload.current_service.rs_shipping_costs.length,
      from_zone_id: null,
      to_zone_id: null,
      currency_id: null,
      cost: null,
      custom_rate: null,
      custom_average_cost: null,
      custom_cost_percentage: null
    });
  }

  deleteItem(key) {
    this.payload.current_service.rs_shipping_costs = this.payload.current_service.rs_shipping_costs.filter((s) => s.key !== key);
  }

  onSaveCommissionSetting = (form) => {
    let valid = true;
    if (this.payload.current_service.is_partner_selling_price_logic) {
      if (!this.payload.current_service.retailer_percentage.toString().includes('-')) {
        this.negativeRequired = true;
        return false;
      }
    }
    console.log(form, 'value');

    for (let rs of this.payload.retailer_services) {
      if (!rs.is_reduce_payout_of_first_item_in_collection) {
        rs = _.assign(rs, { currency_id: null, amount_to_reduce: null });
      }
      if (!rs.is_hybrid_payment) {
        rs = _.assign(rs, { hybrid_payment_voucher_percentage: null });
      }
    }
    console.log(this.payload.retailer_services, 'this.payload.retailer_services');

    this.payload.retailer_services.map((e) => {
      if (e.rs_commissions.data.length > 0) {
        e.rs_commissions.data.map((i) => {
          Object.assign(i, { retailer_service_id: e.id });
        });
        e.rs_commissions.data.map((i, index) => {
          let previous_tranche;
          if (i.from_amount > i.to_amount) {
            this.notificationService.show('warning', `to amount must great than from amount -- tranche_${index + 1}`, 2000);
            valid = false;
          }
          if (index > 0) previous_tranche = e.rs_commissions.data[index - 1];
          if (!_.isNil(previous_tranche)) {
            if (previous_tranche.from_amount > i.from_amount || previous_tranche.to_amount > i.to_amount) {
              this.notificationService.show('warning', 'Next tranche from/to amount must great than previous tranche', 2000);
              valid = false;
            }
            if (previous_tranche.to_amount > i.from_amount || previous_tranche.to_amount > i.to_amount) {
              this.notificationService.show('warning', 'The next From amount should have minimum value = previous To amount', 2000);
              valid = false;
            }
            if (previous_tranche.from_amount > i.from_amount) {
              this.notificationService.show('warning', 'Next tranche from amount must great than previous tranche', 2000);
              valid = false;
            }
            if (previous_tranche.from_amount === i.from_amount) {
              this.notificationService.show('warning', 'Next tranche from amount must diffent previous tranche', 2000);
              valid = false;
            }
            if (previous_tranche.to_amount === i.to_amount) {
              this.notificationService.show('warning', 'Next tranche from amount must diffent previous tranche', 2000);
              valid = false;
            }
            if (_.isEqual(previous_tranche, i)) {
              this.notificationService.show('warning', 'Next tranche must diffent previous tranche', 2000);
              valid = false;
            }
            const checkDuplicate = _.filter(e.rs_commissions.data, (e) => e.from_amount === i.from_amount && e.to_amount === i.to_amount);
            if (checkDuplicate.length > 1) {
              this.notificationService.show('warning', 'Duplicate tranche', 2000);
              valid = false;
            }
          }
        });
      }
    });
    if (!valid) return;
    if (form.valid) {
      console.log(this.negativeRequired);
      this.store.dispatch({
        type: UPDATE_COMMISSION_CONFIG_REQUESTED,
        id: this.payload.user.id,
        data: {
          retailer_services: this.payload.retailer_services
        }
      });
      this.negativeRequired = false;
    }
  };

  rtChangeValue(value) {
    if (this.payload.current_service.is_partner_selling_price_logic) {
      this.negativeRequired = !value.toString().includes('-');
    }
  }

  dynamicPartnerSellingPriceChange(value, current_service) {
    if (value) {
      this.negativeRequired = !value.toString().includes('-');
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.AdminRetailer.CommissionConfig
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch,
      fetchRetailerCommissionByCountry: (retailer_service) => {
        dispatch({
          type: FETCH_RETAILER_COMMISSION_REQUESTED,
          data: retailer_service
        });
      }
    };
  }

  onRenderConfigSaved(config) {
    this.render_config = config;
    this.store.dispatch({
      type: UPDATE_COMMISSION_CONFIG_REQUESTED,
      user_id: this.route.snapshot.params.id,
      data: config
    });
  }

  addItemRsCommissions = () => {
    this.payload.current_service.rs_commissions.data.push({
      key: this.payload.current_service.rs_commissions.data.length + 1,
      from_amount: null,
      to_amount: null,
      amount: null,
      percentage: null
    });
  };

  deleteRsCommissions(key) {
    this.payload.current_service.rs_commissions.data = this.payload.current_service.rs_commissions.data.filter((s) => s.key !== key);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
