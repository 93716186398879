import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import * as _ from 'lodash';
import Product from '../../models/Product';

@Injectable()
export class BroadcastService extends ServiceProvider {
  public url = '/api/v1/reflaunt';
  public model = Product;
}
