import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @Input() data: any = [];
  @Input() destinations: any = [];
  @Input() locations: any = [];
  public selectAll = true;

  constructor() {}

  ngOnInit() {}

  public updateDestination = item => {};
  public updateInventory = item => {};
}
