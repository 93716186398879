import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import * as _ from 'lodash';
import Product from '../../models/Product';
import { Observable } from 'rxjs';
import { AppInjector } from '../../app-injector';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()
export class ReflauntService extends ServiceProvider {
  public url = '/api/v1/reflaunt';
  public smartPricerCommentUrl = '/api/v1/reflaunt/smart-pricer-comment';

  storeSmartPricerComment(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.smartPricerCommentUrl), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => new this.model((result as any).data)),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
