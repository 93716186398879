import * as _ from 'lodash';
import {
  GET_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED
} from './smart-button.actions';

const render_config_initial_state = { fetched: false, config: {}};

const RenderConfig = (state = render_config_initial_state, action) => {
  switch (action.type) {
    case GET_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED:
      return _.assign({}, state, { fetched: true, config: action.data });
    default:
      return state;
  }
};

export const SmartButton = (state = { render: render_config_initial_state }, action) => {
  switch (action.type) {
    case GET_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED:
      return _.assign({}, state, { render: RenderConfig(state.render, action) });
    default:
      return state;
  }
};
