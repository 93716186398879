import Model from "./Model";

class ProcessImage extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default ProcessImage;
