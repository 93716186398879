import { API_CALL_ERROR } from '../../../../store/action';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery } from 'redux-saga/effects';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { DELIST_PRODUCT_REMOVE_DISCCOUNT_COM_REQUESTED, REMOVE_BULK_DISCOUNT_PRODUCT_REQUESTED, RENDER_REMOVE_BULK_DISCOUNT_FORM_REQUESTED, UPDATE_REMOVE_BULK_DISCOUNT_INPUT_OPTIONS } from './remove-bulk-discount.actions';

function* fetchAllDataRemoveBulkDiscount(action) {
  let result = yield AppInjector.get(ApiService).admin.user.get({ roles: 'marketplace', includes: 'marketplace', per_page: 10000 }).toPromise();
  result = result.items;
  const optionsData = {
    marketplace_id: _.map(result, (item, key) => _.assign(item, { value: item.id, label: item.marketplace.company }))
  };
  yield put({ type: UPDATE_REMOVE_BULK_DISCOUNT_INPUT_OPTIONS, data: optionsData });
}

function* watchBulkDiscountFormRequested() {
  yield takeEvery(RENDER_REMOVE_BULK_DISCOUNT_FORM_REQUESTED, fetchAllDataRemoveBulkDiscount);
}

function* removeBulkDiscount(action) {
  AppInjector.get(PreloaderService).show();
  try {
    let result = yield AppInjector.get(ApiService).admin.product.removeBulkDiscount(action.data).toPromise();
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(Router).navigate(['admin', 'product']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchBulkDiscountProductTagsRequested() {
  yield takeEvery(REMOVE_BULK_DISCOUNT_PRODUCT_REQUESTED, removeBulkDiscount);
}

export default [
  watchBulkDiscountFormRequested,
  watchBulkDiscountProductTagsRequested
];
