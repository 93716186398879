import { ApiService } from '../../../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';

@Component({
  selector: 'app-add-payment-account',
  templateUrl: './add-payment-account.component.html',
  styleUrls: ['./add-payment-account.component.scss']
})
export class AddPaymentAccountComponent implements OnInit {
  payment_methods;
  payment_method_active;
  payment_method_id = 1;
  fetched;
  list_currencies;
  allow_currency_list;
  selected_payment_method;
  list_countries;
  submitted;
  id;

  constructor(private router: Router, private api: ApiService, private route: ActivatedRoute) {
    this.id = this.route.snapshot.params.id;
    this.api.admin.payment.getAllPaymentMethod().subscribe((res) => {
      this.payment_methods = res;
      this.payment_methods.forEach((e) => {
        if (e.payment_method_input_fields.data.length > 0) {
          e = _.map(e.payment_method_input_fields.data, (o) =>
            _.assign(o, { name: o.detail ? o.detail.data.name : undefined, display_name: o.display ? o.display.data.display_name : undefined, value: '' })
          );
        }
      });
      console.log(res);
      this.fetched = true;
      this.payment_method_active = res[0].payment_method_input_fields.data.map((e) =>
        _.assign(e, { name: e.detail ? e.detail.data.name : undefined, display_name: e.display ? e.display.data.display_name : undefined, value: '' })
      );
      console.log(this.payment_method_active);
      this.api.currency.get({ per_page: 10000 }).subscribe((currencies) => {
        this.list_currencies = currencies.items;
        const payment_method_name = res[0].name;
        this.getAllowCurrencyList(payment_method_name);
      });
      this.api.country.list().subscribe((countries) => {
        this.list_countries = countries;
      });
    });
  }

  ngOnInit() {}
  validateEmail(email) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true;
    }
    return false;
  }
  getAllowCurrencyList(paymentMethodName) {
    if (paymentMethodName === 'TRANSFER_IBAN') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'EUR' || e.code === 'GBP');
    } else if (paymentMethodName === 'TRANSFER_UK_LOCAL') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'GBP');
    } else if (paymentMethodName === 'TRANSFER_SG_LOCAL') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'SGD');
    } else if (paymentMethodName === 'TRANSFER_USA_LOCAL') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'USD');
    }
  }
  changePaymentMethod(e) {
    this.selected_payment_method = _.find(this.payment_methods, (o) => o.id === Number(e));
    this.payment_method_active = this.selected_payment_method.payment_method_input_fields.data;
    console.log(this.payment_method_active);

    const paymentMethodName = this.selected_payment_method.name;
    this.getAllowCurrencyList(paymentMethodName);
  }
  validateCurrencyCode(currency_code) {
    if (currency_code) {
      return true;
    }
    return false;
  }
  validateCountryCode(country_code) {
    if (country_code) {
      return true;
    }
    return false;
  }
  onSubmit(form) {
    this.submitted = true;
    if (form.valid) {
      const data = _.cloneDeep(form.value);
      delete data['amount'];
      delete data['payment_method'];
      this.api.admin.payment
        .updatePaymentAccount({
          user_id: this.id,
          payment_method_id: this.selected_payment_method ? this.selected_payment_method.id : 1,
          data: data
        })
        .subscribe(() => {
          AppInjector.get(NotificationService).show('success', 'Update payment account success', 2000);
          this.router.navigate([]);
        });
    }
  }
}
