import { combineReducers } from 'redux';
import { Category } from './category/category.reducer';
import { Designer } from './designer/designer.reducer';
import { Size } from './size/size.reducer';
import { Condition } from './condition/condition.reducer';
import { Color } from './color/color.reducer';
import { Material } from './material/material.reducer';
import { GET_ALL_ATTRIBUTES_SUCCEEDED, ATTRIBUTE_COMPONENT } from './attribute.action';
import { FETCH_ALL_MARKETPLACE_SUCCESSED } from '../../integration/create/create.actions';
import * as _ from 'lodash';

const all = (state = { fetched: false, items: [], marketplace: { fetched: false, items: [] }, selected_marketplace: null }, action) => {
  switch (action.type) {
    case GET_ALL_ATTRIBUTES_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };

    case FETCH_ALL_MARKETPLACE_SUCCESSED:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            return _.assign({}, state, { marketplace: { fetched: true, items: action.data }, selected_marketplace: _.find(action.data, { id: action.marketplace_id }) });
        }
      }
      return state;

    default:
      return state;
  }
};
export const Attribute = combineReducers({
  Category,
  Designer,
  Size,
  Condition,
  Color,
  Material,
  all
});
