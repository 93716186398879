import { takeEvery, put } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { GET_DETAIL_TEMPLATE_REQUESTED, GET_DETAIL_TEMPLATE_SUCCEEDED } from './detail.actions';

function* getDetailTemplate(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.email.getDetailTemplate(action.data.name).toPromise();
    yield put({ type: GET_DETAIL_TEMPLATE_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetDetailTemplateRequest() {
  yield takeEvery(GET_DETAIL_TEMPLATE_REQUESTED, getDetailTemplate);
}


export default [watchGetDetailTemplateRequest];
