import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
// import { Action } from '../../../models/Action';
import NotificationAction from '../../../models/NotificationAction';

@Injectable()
export class NotificationActionService extends ServiceProvider {
  public url = '/api/v1/admin/notification-actions';
  // public model = Action;
  public model = NotificationAction;
}
