import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import Wallet from '../../models/Wallet';
import TransactionHistory from '../../models/TransactionHistory';

@Injectable()
export class WalletService extends ServiceProvider {
  public url = '/api/v1/wallets';
  public model = Wallet;
  choosePayment(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/choose-payment', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getWalletByUserLogin(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/user-wallet').pipe(
      tap((result) => {}),
      map((result) => {
        if (result === null) {
          return null;
        }
        return new this.model((result as any).data);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  withdraw(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/withdraw`, data).pipe(
      map((result) => new TransactionHistory((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }
}
