export const PRODUCT_COMP = 'PRODUCT_COMP';

export const listRouter = () => {
  return ['/', 'admin', 'product'];
};
export const createRouter = () => {
  return ['/', 'admin', 'product', 'create'];
};
export const detailRouter = (id) => {
  return ['/', 'admin', 'product', id, 'detail'];
};
export const editRouter = (id) => {
  return ['/', 'admin', 'product', 'edit', id];
};
export const importRouter = () => {
  return ['/', 'admin', 'product', 'import'];
};
export const asyncProcessRouter = () => {
  return ['/', 'admin', 'product', 'async-process'];
};
export const historyRouter = (id) => {
  return ['/', 'admin', 'product', 'history', id];
};
export const stepsCreateCollection = {
  step_1: 1,
  step_2: 2,
  step_3: 3
};
export const getSellingPrice = (price) => {
  let result;
  const x = Number(price);
  switch (true) {
    // S$45
    case 50 <= x && x <= 100:
      result = Number(price) + 45;
      break;
    // 35%
    case 101 <= x && x <= 500:
      result = Number(price) / 0.65;
      break;
    // 30%
    case 501 <= x && x <= 2000:
      result = Number(price) / 0.7;
      break;
    // 25%
    case 2001 <= x && x <= 3000:
      result = Number(price) / 0.75;
      break;
    // 20%
    case 3001 <= x && x <= 7500:
      result = Number(price) / 0.8;
      break;
    // S$1,500
    case 7500 <= x:
      result = Number(price) + 1500;
      break;
    default:
      result = Number(price);
      break;
  }
  return Number.isInteger(result) ? result : result.toFixed(2);
};

export const getSellingPriceVip = (price) => {
  const x = Number(price);
  const result = x * 1.3;
  return Number.isInteger(result) ? result : result.toFixed(2);
};
