import { Router } from '@angular/router';
import { FETCH_TAGS_REQUESTED } from './../list/list.actions';
import { DELETE_TAG_REQUESTED, GET_TAG_REQUESTED, GET_TAG_SUCCEEDED, EDIT_TAG_REQUESTED, RENDER_EDIT_TAG_FORM_REQUESTED, FILL_TAG_DETAIL_FORM } from './edit.actions';
import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { listTagRouter } from '../tag.const';

function* edit(action) {
  const router = AppInjector.get(Router);
  try {
    let result = yield AppInjector.get(ApiService)
      .admin.tag.update(action.data.id, action.data)
      .toPromise();
    router.navigate(listTagRouter());
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditTagRequest() {
  yield takeEvery(EDIT_TAG_REQUESTED, edit);
}

function* getTag(action) {
  try {
    let result = yield AppInjector.get(ApiService)
      .admin.tag.getItemById(action.data)
      .toPromise();
    yield put({ type: GET_TAG_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetTagRequest() {
  yield takeEvery(GET_TAG_REQUESTED, getTag);
}

function* deleteTag(action) {
  try {
    yield AppInjector.get(ApiService)
      .admin.tag.delete(action.data)
      .toPromise();
    yield put({ type: FETCH_TAGS_REQUESTED });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteTagRequest() {
  yield takeEvery(DELETE_TAG_REQUESTED, deleteTag);
}

function* watchRenderTagDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_TAG_FORM_REQUESTED, function*(action: any) {
    yield put({ type: GET_TAG_REQUESTED, data: action.data.id });
  });
}

function* fillTagDetailForm(action) {
  yield put({ type: FILL_TAG_DETAIL_FORM, data: action.data });
}

function* watchFetchTagDetailSuccessed() {
  yield takeLatest(GET_TAG_SUCCEEDED, fillTagDetailForm);
}

export default [watchEditTagRequest, watchGetTagRequest, watchDeleteTagRequest, watchRenderTagDetailFormRequested, watchFetchTagDetailSuccessed];
