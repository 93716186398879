import { combineReducers } from 'redux';
import { Certificate } from './certificate/certificate.reducer';
import { DeclareProductNotAvailable } from './item-not-available/item-not-available.reducer';
import { ShippingLabel } from './buy-shipping-label/buy-shipping-lable.reducer';
import { checkoutPayment } from './checkout-payment/checkout-payment.reducer';
import { ConfirmAddress } from './confirm-address/confirm-address.reducer';
import { ConsignmentAgreement } from './consignment-agreement/consignment-agreement.reducer';
import { ConsignmentConfirmation } from './consignment-agreement/confirmation/confirmation.reducer';

export const LandingPages = combineReducers({
  Certificate,
  DeclareProductNotAvailable,
  ShippingLabel,
  checkoutPayment,
  ConfirmAddress,
  ConsignmentAgreement,
  ConsignmentConfirmation
});
