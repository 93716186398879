import * as _ from 'lodash';
import {
  FETCH_BULK_OPERATION_DATA_SUCCEEDED,
  RENDER_BULK_DISCOUNT_FORM_REQUESTED,
  UPDATE_BULK_DISCOUNT_INPUT_OPTIONS,
  UPDATE_CURRENCY_LABEL
} from './bulk-discount.actions';
import { MARKETPLACE_PARTNER_PRICE } from '../../../../models/Marketplace';
import { support } from '@reflaunt/reactive-form';

export const bulkDiscount = (state: any = { created: false, fetched: false }, action) => {
  switch (action.type) {
    case RENDER_BULK_DISCOUNT_FORM_REQUESTED:
      let discount_type_input = _.find(action.data.inputs, i => i.key === 'discount_type');
      discount_type_input.value = _.find(discount_type_input.options, i => i.value === MARKETPLACE_PARTNER_PRICE);
      return _.assign({}, state, { inputs: action.data.inputs });
    case FETCH_BULK_OPERATION_DATA_SUCCEEDED:
      return _.assign({}, state, { items: action.data.items, pagination: action.data.pagination, fetched: true });
    case UPDATE_BULK_DISCOUNT_INPUT_OPTIONS:
      state.inputs = support.UpdateInputsValue(state.inputs, action.data);
      return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_CURRENCY_LABEL:
      let currency_input = _.find(state.inputs, i => i.key === 'amount');
      currency_input.label = `${action.data.marketplace.currency.symbol}`;
      return _.assign({}, state);
    default:
      return state;
  }
};
