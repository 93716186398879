import { PermissionGuardService } from './../../../auth/permission-guard.service';
import { AuthGuardService } from './../../../auth/auth-guard.service';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListGroupComponent } from './list-group/list-group.component';
import { AddToGroupComponent } from './add-to-group/add-to-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: 'edit/:id',
        component: EditComponent
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: ':id/groups/create',
        component: CreateGroupComponent
      },
      {
        path: ':id/groups',
        component: ListGroupComponent
      },
      {
        path: ':id/groups/attach',
        component: AddToGroupComponent
      },
      {
        path: 'edit/:id/groups',
        component: EditGroupComponent
      }
    ],
    canActivate: [AuthGuardService, PermissionGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DatasetRoutingModule {}
