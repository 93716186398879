import { AppInjector } from '../../../app-injector';
import { ApiUrl } from '../../api-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import Magic from '../../../models/Magic';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import * as _ from 'lodash';
@Injectable()
export class MagicService extends ServiceProvider {
  public url = '/api/v1/admin';
  public model = Magic;

  getListBaseOnReference(reference, params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/${reference}/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
