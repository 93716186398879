import { put, takeEvery } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { Router } from '@angular/router';
import { NotificationService } from '../../../common/services/notification/notification.service';
import {
    FETCH_MEASUREMENT_UNIT_DETAIL_SUCCESSED,
    FETCH_MEASUREMENT_UNIT_DETAIL_REQUEST,
    UPDATE_MEASUREMENT_UNIT_REQUESTED
} from './edit.action';


function* watchDetailMeasurementRequested() {
  yield takeEvery(FETCH_MEASUREMENT_UNIT_DETAIL_REQUEST, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .measurementUnit.show(action.data.id)
        .toPromise();
      yield put({ type: FETCH_MEASUREMENT_UNIT_DETAIL_SUCCESSED, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateMeasurementRequested() {
  yield takeEvery(UPDATE_MEASUREMENT_UNIT_REQUESTED, function* (action: any) {
    try {
      const id = action.id;
      const data = action.data;
      let result = yield AppInjector.get(ApiService).measurementUnit
        .update(id, data).toPromise();
      const router = AppInjector.get(Router);
      AppInjector.get(NotificationService).show('success', `Successfully updated measurement unit`, 3000);
      router.navigate(['/', 'measurement-unit']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchDetailMeasurementRequested, watchUpdateMeasurementRequested];
