import { fork } from 'redux-saga/effects';
import * as _ from 'lodash';
import categorySaga from './category/category.saga';
import designerSaga from './designer/designer.saga';
import sizeSaga from './size/size.saga';
import conditionSaga from './condition/condition.saga';
import colorSaga from './color/color.saga';
import materialSaga from './material/material.saga';

export default [...categorySaga, ...designerSaga, ...sizeSaga, ...conditionSaga, ...colorSaga, ...materialSaga];
