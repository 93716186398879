import Model from './Model';
import * as _ from 'lodash';
import UserBase from './Base/UserBase';
import OrderProduct from './OrderProduct';
class User extends UserBase {}

class TransactionHistory extends Model {
  seller: any;
  retailer: any;
  constructor(options) {
    super();
    (this as any).seller = d => {
      return new User(d.data);
    };
    (this as any).retailer = d => {
      return new User(d.data);
    };
    (this as any).marketplace = d => {
      return new User(d.data);
    };
    (this as any).order_product = d => {
      return new OrderProduct(d.data);
    };
    (this as any).transactions = d => {
      return _.map(d.data, item => new TransactionHistory(item));
    };
    this.bind(options);
  }
}

export default TransactionHistory;
