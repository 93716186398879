import { PreloaderService } from './../../../common/services/preloader/preloader.service';
import { AppInjector } from './../../../app-injector';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Category from '../../../models/Category';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class CategoryService extends ServiceProvider {
  public url = '/api/v1/admin/categories';
  public model = Category;

  getNestedCategory(categoryId = 0): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${categoryId}/path`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  all(params = {}): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/all').pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new Category(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  allWithExtras(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url)).pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new Category(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: { ...params, per_page: '10000' } });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
