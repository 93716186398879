import Model from './Model';
import User from './User';
import Product from './Product';
import * as _ from 'lodash';
import { Address } from './Address';
import Order from './Order';
import Currency from './Currency';

export const SHIPMENT_STATUS_STARTED = 'started';
export const SHIPMENT_STATUS_SHIPPED = 'shipped';
export const SHIPMENT_STATUS_READY_TO_SHIP = 'ready-to-ship';
export const SHIPMENT_STATUS_DELIVERED = 'delivered';
export const PENDING_STATUS_SHIPMENT = 'pending';
export const CREATED_STATUS_SHIPMENT = 'created';
export const CANCELED_STATUS_SHIPMENT = 'canceled';
export const PICKED_STATUS_SHIPMENT = 'completed';
export const empty_text = null;
export const SHIPMENT_TYPE_RETURN = 'return';
export const SHIPMENT_TYPE_ORDER = 'order';
export const SHIPMENT_TYPE_COLLECTION_RETURN = 'collection_return';
export const SHIPMENT_LIST_TYPES = [
  { label: 'Order', value: SHIPMENT_TYPE_ORDER },
  { label: 'Return', value: SHIPMENT_TYPE_RETURN }
];

export const SHIPMENT_LIST_STATUS = [
  {
    label: 'Pending',
    value: PENDING_STATUS_SHIPMENT
  },
  {
    label: 'Canceled',
    value: CANCELED_STATUS_SHIPMENT
  },
  {
    label: 'Shipped',
    value: SHIPMENT_STATUS_SHIPPED
  },
  {
    label: 'Delivered',
    value: SHIPMENT_STATUS_DELIVERED
  }
];

class OrderProduct extends Model {
  constructor(options) {
    super();
    (this as any).product = (d) => {
      return new Product(d.data);
    };
    (this as any).order = (d) => {
      return new Order(d.data);
    };
    this.bind(options);
  }
}

class Shipment extends Model {
  seller: any;
  marketplace: any;
  constructor(options) {
    super();
    (this as any).seller = (d) => {
      return new User(d.data);
    };
    (this as any).marketplace = (d) => {
      return new User(d.data);
    };
    (this as any).product = (d) => {
      return new Product(d.data);
    };
    (this as any).order_product = (d) => {
      return new OrderProduct(d.data);
    };
    (this as any).source_address = (d) => {
      return new Address(d.data);
    };
    (this as any).destination_address = (d) => {
      return new Address(d.data);
    };
    (this as any).order_product_many = (d) => {
      return d.data.map((item) => new OrderProduct(item));
    };
    (this as any).shipping_fee_currency = (d) => {
      return new Currency(d.data);
    };
    this.bind(options);
  }
  getCourier() {
    if ((this as any).courier_name && !_.isUndefined((this as any).courier_name) && !_.isNil((this as any).courier_name) && !_.isEqual((this as any).courier_name, '')) {
      return (this as any).courier_name;
    }
    return empty_text;
  }

  getTotal() {
    if (
      (this as any).shipment_charge_total &&
      !_.isUndefined((this as any).shipment_charge_total) &&
      !_.isNil((this as any).shipment_charge_total) &&
      !_.isEqual((this as any).shipment_charge_total, '')
    ) {
      return (this as any).shipment_charge_total;
    }
    return empty_text;
  }

  getCurrency() {
    if ((this as any).currency && !_.isUndefined((this as any).currency) && !_.isNil((this as any).currency) && !_.isEqual((this as any).currency, '')) {
      return (this as any).currency;
    }
    return empty_text;
  }

  getType() {
    if ((this as any).type && !_.isUndefined((this as any).type) && !_.isNil((this as any).type) && !_.isEqual((this as any).type, '')) {
      return (this as any).type;
    }
    return empty_text;
  }

  getShipmentId() {
    if ((this as any).shipment_id && !_.isUndefined((this as any).shipment_id) && !_.isNil((this as any).shipment_id) && !_.isEqual((this as any).shipment_id, '')) {
      return (this as any).shipment_id;
    }
    return empty_text;
  }

  getShipmentLabelUrl() {
    if (
      (this as any).shipping_label_url &&
      !_.isUndefined((this as any).shipping_label_url) &&
      !_.isNil((this as any).shipping_label_url) &&
      !_.isEqual((this as any).shipping_label_url, '')
    ) {
      return (this as any).shipping_label_url;
    }
    return empty_text;
  }

  getQrUrl() {
    if (
      (this as any).pickup_qr_code_url &&
      !_.isUndefined((this as any).pickup_qr_code_url) &&
      !_.isNil((this as any).pickup_qr_code_url) &&
      !_.isEqual((this as any).pickup_qr_code_url, '')
    ) {
      return (this as any).pickup_qr_code_url;
    }
    return empty_text;
  }

  getDateFromCurrentDateType(current_date_type) {
    let result = this['created_at'];
    if (!_.isNil(current_date_type) && current_date_type !== '') {
      result = this[current_date_type];
      if (current_date_type === 'sold_date') {
        result = (this as any).order_product.order.data.sold_date;
      }
    }
    return result;
  }

  getTrackingStatusUrl() {
    if (
      (this as any).tracking_status_url &&
      !_.isUndefined((this as any).tracking_status_url) &&
      !_.isNil((this as any).tracking_status_url) &&
      !_.isEqual((this as any).tracking_status_url, '')
    ) {
      return (this as any).tracking_status_url;
    }
    return empty_text;
  }
  
  getCollectionId() {
    if ((this as any).collection_id && !_.isUndefined((this as any).collection_id) && !_.isNil((this as any).collection_id) && !_.isEqual((this as any).collection_id, '')) {
      return (this as any).collection_id;
    }
    return empty_text;
  }
}
export default Shipment;
