import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Dataset from '../../models/Dataset';
import { Observable } from 'rxjs';
import { AppInjector } from '../../app-injector';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class DatasetService extends ServiceProvider {
  public url = '/api/v1/datasets';
  public model = Dataset;

  refresh(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/${id}/refresh`)).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => result),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  createGroup(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/groups`), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result: any) => new this.model(result.data)),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getGroup(id): Observable<any> {
    this.preloader.show();
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${id}/groups`).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result: any) => result.data.map((item) => new this.model(item))),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  getChildren(id): Observable<any> {
    this.preloader.show();
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${id}/children`).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result: any) => result.data.map((item) => new this.model(item))),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  detachFromGroup(id, data): Observable<any> {
    this.preloader.show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/groups/${id}/detach`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result: any) => result),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  attachToGroup(id, data): Observable<any> {
    this.preloader.show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/groups/${id}/attach`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result: any) => result),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  updateGroup(id, data): Observable<any> {
    this.preloader.show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/groups/${id}`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result: any) => new this.model(result.data)),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: { ...params, per_page: '10000' } });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
