import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppInjector } from '../../app-injector';
import { ApiUrl } from '../api-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import * as _ from 'lodash';
import { MarketplaceCategory } from '../../models/MarketplaceCategory';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { MarketplaceDesigner } from '../../models/MarketplaceDesigner';
import { MarketplaceSize } from '../../models/MarketplaceSize';
import { MarketplaceCondition } from '../../models/MarketplaceCondition';
import { MarketplaceColor } from '../../models/MarketplaceColor';
import { MarketplaceMaterial } from '../../models/MarketplaceMaterial';

@Injectable()
export class MarketplaceService extends ServiceProvider {
  public url = '/api/v1/marketplaces';
  public categoryModel = MarketplaceCategory;
  public designerModel = MarketplaceDesigner;
  public sizeModel = MarketplaceSize;
  public conditionModel = MarketplaceCondition;
  public colorModel = MarketplaceColor;
  public materialModel = MarketplaceMaterial;

  getAllCategories(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/categories`), { params: queryParams }).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.categoryModel(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllDesigners(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/designers`), { params: queryParams }).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.designerModel(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllSizes(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/sizes`), { params: queryParams }).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.sizeModel(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllConditions(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/conditions`), { params: queryParams }).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.conditionModel(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllColors(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/colors`), { params: queryParams }).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.colorModel(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getAllMaterials(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/materials`), { params: queryParams }).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.materialModel(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  syncAttributes(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.put(this.apiUrl.getApiUrl(`${this.url}/attributes/synchronise`), data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  exportAsCsv(retailer_id = null): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/${retailer_id ? retailer_id + '/' : ''}export`), null).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }


  triggerMiintoOrders(): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(`/api/v1/admin/miinto/triggger-orders`)).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => result),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getMiintoProductManagement(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(`/api/v1/admin/miinto/miinto-product-managemnet`)).pipe(
      tap(result => {
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  generateJSON(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(`/api/v1/admin/miinto/generate-feedfile-json`), data).pipe(
      tap(result => {
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  uploadMiintoFeedFile(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(`/api/v1/admin/miinto/upload-feed-file`), data).pipe(
      tap(result => {
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  getExportData(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(`/api/v1/admin/miinto/get-export-data`)).pipe(
      tap(result => {
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  resetMarketplaceServiceConfiguration(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/reset`), data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
