import { BarcodeComponent } from './../../../../common/print-template/product-barcode/barcode.component';
import { Router } from '@angular/router';
import { takeEvery, put, takeLatest, select, delay } from 'redux-saga/effects';
import { listAdminCollection } from '../collection.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import {
  GET_DETAIL_INFO_COLLECTION_REQUESTED,
  GET_DETAIL_INFO_COLLECTION_SUCCESSED,
  GET_PRODUCT_OF_CURRENT_CUSTOMER_REQUESTED,
  GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED,
  APPROVE_CONSIGNMENT_AGREEMENT_REQUESTED,
  SEND_CONSIGNMENT_AGREEMENT_REQUESTED,
  SEND_NOTIFICATION_TO_CUSTOMER_PICKUP_REQUESTED,
  GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_REQUESTED,
  GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_SUCCESSED,
  RESEND_PICKUP_CONFIRMATION_EMAIL_REQUESTED,
  RESEND_PICKUP_CONFIRMATION_EMAIL_SUCCESSED,
  SEND_EMAIL_RESCHEDULE_PICKUP_REQUESTED,
  SEND_EMAIL_RESCHEDULE_PICKUP_SUCCESSED,
  SEND_APPROVED_CONSIGNMENT_AGREEMENT_EMAIL_REQUESTED,
  DOWNLOAD_CONSIGNMENT_AGREEMENT_REQUESTED,
  UPDATE_STATUS_PRODUCT_ITEM_REQUEST,
  UPDATE_STATUS_PRODUCT_ITEM_SUCCESS,
  DELETE_ITEM_IN_PICKUP_REQUEST,
  DELETE_ITEM_IN_PICKUP_SUCCESS,
  PRINT_REQUEST,
  PRINT_PRODUCT_REQUEST,
  PRINT_PRODUCT_SUCCESSED,
  COLLECTION_RELOAD_REQUESTED,
  MODIFY_COLLECTION_PICKUP_DATE_REQUESTED,
  MODIFY_COLLECTION_PICKUP_DATE_SUCCESSED,
  MODIFY_COLLECTION_PICKUP_TIMESLOT_REQUESTED,
  CREATE_GHOST_PRODUCT_REQUESTED,
  GET_LOGICTIS_PARTNER_LOCATION_SUCCESSED
} from './detail.actions';
import { MAP_CATEGORIES_TO_PRODUCTS } from '../../product/list/list.actions';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { PRINT_COMPLETED } from '../../../print/print.actions';
import { QueryParser } from '../../../../common/util/query-parser';
import { COLLECTION_TYPES } from '../../../../models/Collection';
import { DELETE_ADMIN_COLLECTION_REQUESTED } from '../edit/edit.actions';

function* getDetailInfoCollection() {
  yield takeLatest(GET_DETAIL_INFO_COLLECTION_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.collection.show(action.id).toPromise();
      let retailer_slug;
      if (result && result.retailer) {
        const retailer = yield AppInjector.get(ApiService).admin.user.show(result.retailer.id, { from: 'collection-detail' }).toPromise();
        if (retailer && retailer.retailer) {
          retailer_slug = retailer.retailer.service_slug;
        }
      }
      yield put({ type: GET_DETAIL_INFO_COLLECTION_SUCCESSED, data: result, retailer_slug: retailer_slug });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getLogictisPartnerLocation() {
  yield takeLatest(GET_DETAIL_INFO_COLLECTION_SUCCESSED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.location.getLogisticPartnerLocation().toPromise();
      yield put({ type: GET_LOGICTIS_PARTNER_LOCATION_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getProductOfCustomer() {
  yield takeLatest(GET_PRODUCT_OF_CURRENT_CUSTOMER_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.product.get(action.data).toPromise();
      yield put({ type: GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* mapCategoriesToProduct(action) {
  const api = AppInjector.get(ApiService);
  try {
    let categories = yield select((state) => (state as any).Admin.Category.list.items);
    yield put({ type: MAP_CATEGORIES_TO_PRODUCTS, data: categories });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* watchFetchProductsSuccessed() {
  yield takeLatest(GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED, mapCategoriesToProduct);
}

function* watchApproveConsignmentAgreement() {
  yield takeLatest(APPROVE_CONSIGNMENT_AGREEMENT_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.collection.forceUpdateConsignmentAgreementStatus(action.id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Consignment agreement sent', 3000);
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSendApprovedConsignmentAgreementEmail() {
  yield takeLatest(SEND_APPROVED_CONSIGNMENT_AGREEMENT_EMAIL_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.collection.sendApprovedConsignmentAgreementEmail(action.id).toPromise();
      AppInjector.get(NotificationService).show('success', 'Approved consignment agreement email sent', 3000);
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDownloadConsignmentAgreement() {
  yield takeLatest(DOWNLOAD_CONSIGNMENT_AGREEMENT_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.collection.downloadConsignmentAgreement(action.id).toPromise();
      if (result && result.data && result.data.url) {
        window.open(result.data.url);
      }
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSendConsignmentAgreement() {
  yield takeLatest(SEND_CONSIGNMENT_AGREEMENT_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.collection.sendConsignmentAgreement(action.id).toPromise();
      AppInjector.get(NotificationService).show('success', 'Consignment agreement sent', 3000);
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* sendNotificationToCustomerRequested() {
  yield takeLatest(SEND_NOTIFICATION_TO_CUSTOMER_PICKUP_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.sendNotificationToCustomer(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Send notification success', 2000);
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.data.collection_id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* getPickupStepValueByCollectionIdRequested() {
  yield takeLatest(GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_REQUESTED, function* (action: any) {
    try {
      const res = yield AppInjector.get(ApiService).admin.collection.getPickupStepValueByCollectionId(action.id).toPromise();
      yield put({ type: GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_SUCCESSED, data: res });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* resendEmailPickupConfirmationRequested() {
  yield takeLatest(RESEND_PICKUP_CONFIRMATION_EMAIL_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.resendEmailPickupConfirmation(action.data).toPromise();
      yield put({ type: RESEND_PICKUP_CONFIRMATION_EMAIL_SUCCESSED });
      AppInjector.get(NotificationService).show('success', 'Send pickup confirmation email success', 2000);
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.data.collection_id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* sendEmailReschedulePickupRequested() {
  yield takeLatest(SEND_EMAIL_RESCHEDULE_PICKUP_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.sendReschedulePickupEmail(action.data).toPromise();
      yield put({ type: SEND_EMAIL_RESCHEDULE_PICKUP_SUCCESSED });
      AppInjector.get(NotificationService).show('success', 'Send re-schedule pickup email success', 2000);
      yield put({ type: GET_DETAIL_INFO_COLLECTION_REQUESTED, id: action.data.collection_id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* updateStatusOfProductItem() {
  yield takeLatest(UPDATE_STATUS_PRODUCT_ITEM_REQUEST, function* (action: any) {
    try {
      const { product_item_id, status_name } = action.data;
      const status = yield AppInjector.get(ApiService).status.getByName(status_name).toPromise();
      if (status) {
        yield AppInjector.get(ApiService).admin.productItem.update(product_item_id, { status_id: status.id }).toPromise();
        AppInjector.get(Router).navigate([]);
      }
      yield put({ type: UPDATE_STATUS_PRODUCT_ITEM_SUCCESS });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* modifyPickupDateCollectionRequested() {
  yield takeLatest(MODIFY_COLLECTION_PICKUP_DATE_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.modifyPickupDate(action.collection_id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Modify pickup date collection success', 2000);
      yield delay(1000);
      AppInjector.get(Router).navigate([]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* modifyPickupTimeSlotCollectionRequested() {
  yield takeLatest(MODIFY_COLLECTION_PICKUP_TIMESLOT_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.modifyPickupTimeSlot(action.collection_id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Modify pickup time slot collection success', 2000);
      yield delay(1000);
      AppInjector.get(Router).navigate([]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* deleteItemInCollection() {
  yield takeLatest(DELETE_ITEM_IN_PICKUP_REQUEST, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.deleteItemInCollection(action.data).toPromise();
      AppInjector.get(Router).navigate([]);
      yield put({ type: DELETE_ITEM_IN_PICKUP_SUCCESS });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* printGhostProducts() {
  yield takeLatest(PRINT_PRODUCT_REQUEST, function* (action: any) {
    try {
      let data = action.data;
      let result;
      if (action.data.collection_id) {
        if (action.data.type === COLLECTION_TYPES.BUYBACK) {
          result = yield AppInjector.get(ApiService).product.updateProductStatusToWaiting(action.data.id, action.data.params).toPromise();
        } else {
          result = yield AppInjector.get(ApiService).product.printLabel(action.data).toPromise();
        }
        yield put({
          type: PRINT_PRODUCT_SUCCESSED,
          item_index: action.data.item_index
        });
        data.product_id = result.product_id;
        yield delay(1000);
      }
      yield put({
        type: PRINT_REQUEST,
        data: {
          values: [data],
          collection_id: action.data.collection_id
        },
        component: BarcodeComponent
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* createGhostProducts() {
  yield takeLatest(CREATE_GHOST_PRODUCT_REQUESTED, function* (action: any) {
    try {
      let data = action.data;
      let result;
      if (action.data.collection_id) {
        if (action.data.type === COLLECTION_TYPES.BUYBACK) {
          result = yield AppInjector.get(ApiService).product.updateProductStatusToWaiting(action.data.id, action.data.params).toPromise();
        } else {
          result = yield AppInjector.get(ApiService).product.printLabel(action.data).toPromise();
        }
        data.product_id = result.product_id;
        AppInjector.get(NotificationService).show('success', 'Create product success', 2000);
        yield delay(500);
      }
      AppInjector.get(Router).navigate([]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchPrintCompletedRequested() {
  yield takeLatest(PRINT_COMPLETED, function* (action: any) {
    try {
      if (action.data.collection_reload) {
        AppInjector.get(Router).navigate([]);
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteCollectionRequest() {
  yield takeEvery(DELETE_ADMIN_COLLECTION_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.collection.delete(action.data).toPromise();
      AppInjector.get(Router).navigate(['admin', 'collection', 'detail', action.data]);
      const notification = AppInjector.get(NotificationService);
      notification.show('success', 'Delete collection success', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchPrintCompletedRequested,
  printGhostProducts,
  deleteItemInCollection,
  getDetailInfoCollection,
  getLogictisPartnerLocation,
  getProductOfCustomer,
  watchFetchProductsSuccessed,
  watchSendConsignmentAgreement,
  watchApproveConsignmentAgreement,
  sendNotificationToCustomerRequested,
  getPickupStepValueByCollectionIdRequested,
  resendEmailPickupConfirmationRequested,
  sendEmailReschedulePickupRequested,
  watchSendApprovedConsignmentAgreementEmail,
  watchDownloadConsignmentAgreement,
  updateStatusOfProductItem,
  createGhostProducts,
  modifyPickupDateCollectionRequested,
  modifyPickupTimeSlotCollectionRequested,
  watchDeleteCollectionRequest
];
