// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://api.dev.reflaunt.com',
  mediaUrl: 'https://media-dev.reflaunt.com',
  jwtTokenKey: 'jwt_token_key',
  broadcastUrl: 'https://api-dev.styletribute.com/api/v1/reflaunt',
  emailHistoryBucket: 'https://email-dev.reflaunt.com',
  receiptImageFolderBucket: 'https://receiptreader-dev-receiptreaderreceiptreaderdev6c-1suxc71pan0bd.s3.amazonaws.com',
  emailScrappingSdkUrl: 'https://h21c3hdpyk.execute-api.eu-west-1.amazonaws.com/prod',
  inspectletId: 1218060564,
  appUrl: 'https://admin-dev.reflaunt.com',
};
