export const FETCH_RETAILER_COLORS_SUCCESS = 'FETCH_RETAILER_COLORS_SUCCESS';
export const ADMIN_ATTRIBUTES_RETAILER_COLOR_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_RETAILER_COLOR_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_RETAILER_COLOR_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_RETAILER_COLOR_UPDATE_DICTIONARY_SUCCESS';

export const ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_COLOR_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_COLOR_REQUESTED';
export const TOGGLE_COLOR_RETAILER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_COLOR_RETAILER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_COLOR_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_COLOR_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED';

export const DELETE_RETAILER_COLOR_MATCHING_REQUESTED = 'DELETE_RETAILER_COLOR_MATCHING_REQUESTED';
export const ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_COLOR_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_COLOR_REQUESTED';
