export const FETCH_RETAILER_PRODUCTS_REQUESTED = 'FETCH_RETAILER_PRODUCTS_REQUESTED';
export const FETCH_RETAILER_PRODUCTS_SUCCEEDED = 'FETCH_RETAILER_PRODUCTS_SUCCEEDED';
export const SELECT_ALL_RETAILER_PRODUCT = 'SELECT_ALL_RETAILER_PRODUCT';
export const FETCH_CATEGORIES_OF_RETAILER_PRODUCT_REQUESTED = 'FETCH_CATEGORIES_OF_RETAILER_PRODUCT_REQUESTED';
export const FETCH_CATEGORIES_OF_RETAILER_PRODUCT_SUCCEEDED = 'FETCH_CATEGORIES_OF_RETAILER_PRODUCT_SUCCEEDED';
export const MAP_CATEGORIES_TO_RETAILER_PRODUCTS = 'MAP_CATEGORIES_TO_RETAILER_PRODUCTS';
export const BROADCAST_RETAILER_PRODUCT_REQUESTED = 'BROADCAST_RETAILER_PRODUCT_REQUESTED';
export const BROADCAST_RETAILER_PRODUCT_SUCCESSED = 'BROADCAST_RETAILER_PRODUCT_SUCCESSED';
export const UPDATE_RETAILER_PRODUCT_STATUS_REQUESTED = 'UPDATE_RETAILER_PRODUCT_STATUS_REQUESTED';
export const UPDATE_RETAILER_PRODUCT_STATUS_SUCCESSED = 'UPDATE_RETAILER_PRODUCT_STATUS_SUCCESSED';
export const FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED = 'FETCH_RETAILER_PRODUCT_IMAGE_SUCCESSED';
export const FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_REQUESTED = 'FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_REQUESTED';
export const FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_SUCCEEDED = 'FETCH_RETAILER_PRODUCT_CUSTOMER_INFORMATION_SUCCEEDED';

export const RETAILER_FETCH_PRODUCT_MARKETPLACE_REQUESTED = 'RETAILER_FETCH_PRODUCT_MARKETPLACE_REQUESTED';
export const RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED = 'RETAILER_FETCH_PRODUCT_MARKETPLACE_SUCCESSED';
export const RETAILER_FETCH_ALL_MARKETPLACE_REQUESTED = 'RETAILER_FETCH_ALL_MARKETPLACE_REQUESTED';
export const RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED = 'RETAILER_FETCH_ALL_MARKETPLACE_SUCCESSED';
export const RETAILER_PUBLISH_PRODUCT_REQUESTED = 'RETAILER_PUBLISH_PRODUCT_REQUESTED';
export const RETAILER_PUBLISH_PRODUCT_SUCCESSED = 'RETAILER_PUBLISH_PRODUCT_SUCCESSED';
export const RETAILER_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED = 'RETAILER_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED';
export const RETAILER_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED = 'RETAILER_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED';
export const RETAILER_EXPORT_PRODUCT_REQUESTED = 'RETAILER_EXPORT_PRODUCT_REQUESTED';

export const RETAILER_UPDATE_PRODUCT_QUANTITY_REQUESTED = 'RETAILER_UPDATE_PRODUCT_QUANTITY_REQUESTED';
