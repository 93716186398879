import Model from './Model';
import Category from './Category';

export default class Dictionary extends Model {
  gender: string;
  age_group: string;
  constructor(options) {
    super();
    this.bind(options);
    this.gender = this.gender || null;
    this.age_group = this.age_group || null;
  }
}

export const DICTIONARY_CATEGORY_TYPE = 'categories';
export const DICTIONARY_DESIGNER_TYPE = 'designers';
export const DICTIONARY_DESIGNER_PREOWNED_TYPE = 'designers_preowned';
export const DICTIONARY_SIZE_TYPE = 'sizes';
export const DICTIONARY_CONDITION_TYPE = 'conditions';
export const DICTIONARY_COLOR_TYPE = 'colors';
