import { FETCH_ALL_ADMIN_NOTIFICATION_SUCCESSED } from './list.actions';
import * as _ from 'lodash';

export const list = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case FETCH_ALL_ADMIN_NOTIFICATION_SUCCESSED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    default:
      return state;
  }
};
