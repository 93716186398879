import Model from './Model';

export enum DataTypes {
  varchar = 'varchar',
  timestamp = 'timestamp',
  double = 'double'
}

export enum DataReferences {
  categories = 'categories',
  conditions = 'conditions',
  designers = 'designers',
  materials = 'materials',
  sizes = 'sizes',
  status = 'status',
  colors = ' colors'
}

export enum DataObjectMarketplaces {
  is_published = 'Is published on marketplace',
  is_not_published = 'Is not published on marketplace',
  product_item_location = 'Product item location'
}

export enum otherReferences {
  gender = 'genders',
  agegroup = 'agegroups',
  retailer = 'retailers'
}

export const AGEGROUPS = [
  {
    label: 'ADULT',
    value: 'ADULT'
  },
  {
    label: 'KID',
    value: 'KID'
  }
];

export const GENDERS = [
  {
    label: 'FEMALE',
    value: 'FEMALE'
  },
  {
    label: 'MALE',
    value: 'MALE'
  }
];
class DatasetObject extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default DatasetObject;
