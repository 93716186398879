import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { edit } from './edit/edit.reducer';
import { detail } from './detail/detail.reducer';
import { create } from './create/create.reducer';
import { Product } from './product/product.reducer';
import { GET_ALL_ADMIN_COLLECTION_SUCCESSED } from './collection.action';
import { EDIT_ADMIN_COLLECTION_SUCCEEDED, DELETE_ADMIN_COLLECTION_SUCCESSED } from './edit/edit.actions';
import { CREATE_ADMIN_COLLECTION_SUCCESSED } from './create/create.actions';
const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case CREATE_ADMIN_COLLECTION_SUCCESSED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case EDIT_ADMIN_COLLECTION_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case DELETE_ADMIN_COLLECTION_SUCCESSED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case GET_ALL_ADMIN_COLLECTION_SUCCESSED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};
export const collection = combineReducers({
  list,
  edit,
  create,
  detail,
  Product,
  all
});
