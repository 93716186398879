import { MediaService } from './media/media.service';
import { CustomerGroupService } from './customer-group/customer-group.service';
import { PermissionService } from './permission/permission.service';
import { StyleService } from './style/style.service';
import { SizeService } from './size/size.service';
import { CategoryService } from './category/category.service';
import { CustomerService } from './customer/customer.service';
import { OrderService } from './order/order.service';
import { ProductService } from './product/product.service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { UserService } from './user/user.service';
import { StatusService } from './status/status.service';
import { CustomerAddressService } from './customer-address/customer-address.service';
import { RoleService } from './role/role.service';
import { ConstantService } from './constant/constant.service';
import { ProviderService } from './provider/provider.service';
import { AnalyticService } from './analytic.service';
import { CurrencyService } from './currency/currency.service';
import { BroadcastService } from './broadcast/broadcast.service';
import { RetailerService } from './retailer/retailer.service';
import { DatasetService } from './dataset/dataset.service';
import { DatasetOperatorService } from './dataset-operator/dataset-operator.service';
import { DatasetObjectService } from './dataset-object/dataset-object.service';
import { DatasetConditionService } from './dataset-condition/dataset-condition.service';
import { DesignerService } from './designer/designer.service';
import { ConditionService } from './condition/condition.service';
import { ColorService } from './color/color.service';
import { AdminApiService } from './admin/admin.service';
import { IntegrationService } from './integration/integration.service';
import { FileService } from './file/file.service';
import { TagService } from './tag/tag.service';
import { WalletService } from './wallet/wallet.service';
import { VoucherService } from './voucher/voucher.service';
import { TransactionHistoryService } from './transaction-history/transaction-history.service';
import { PercentageService } from './percentage/percentage.service';
import { BlockchainService } from './blockchain/blockchain.service';
import { MarketplaceService } from './marketplace/marketplace.service';
import { EndpointService } from './endpoint/endpoint.service';
import { OAuthClientService } from './oauth-client/oauth-client';
import { SmartButtonService } from './smart-button/smart-button.service';
import { ImageService } from './admin/image/image.service';
import { ExcelService } from './excel/excel.service';
import { AsyncProcessService } from './async-process/async_processes.service';
import { MaterialService } from './material/material.service';
import { AttributeService } from './attribute/attribute.service';
import { LanguageService } from './language/language.service';
import { CountryService } from './country/country.service';
import { ReflauntService } from './reflaunt/reflaunt.service';
import { BulkOperationService } from './admin/bulk-operation/bulk-operation.service';
import { LocationService } from './admin/location/location.service';
import { MeasurementUnitService } from './measurement-unit/measurement-unit.service';
import { ModelService } from './model/model.service';


@Injectable()
export class ApiService {
  constructor(
    public tag: TagService,
    public admin: AdminApiService,
    public color: ColorService,
    public measurementUnit: MeasurementUnitService,
    public condition: ConditionService,
    public datasetCondition: DatasetConditionService,
    public datasetObject: DatasetObjectService,
    public datasetOperator: DatasetOperatorService,
    public dataset: DatasetService,
    public retailer: RetailerService,
    public auth: AuthService,
    public product: ProductService,
    public image: ImageService,
    public order: OrderService,
    public user: UserService,
    public customer: CustomerService,
    public category: CategoryService,
    public size: SizeService,
    public style: StyleService,
    public status: StatusService,
    public customerAddress: CustomerAddressService,
    public role: RoleService,
    public asyncProcess: AsyncProcessService,
    public permission: PermissionService,
    public constant: ConstantService,
    public provider: ProviderService,
    public customerGroup: CustomerGroupService,
    public analytic: AnalyticService,
    public currency: CurrencyService,
    public broadcast: BroadcastService,
    public media: MediaService,
    public designer: DesignerService,
    public integration: IntegrationService,
    public file: FileService,
    public wallet: WalletService,
    public voucher: VoucherService,
    public transactionHistory: TransactionHistoryService,
    public percentage: PercentageService,
    public blockchain: BlockchainService,
    public marketplace: MarketplaceService,
    public endpoint: EndpointService,
    public oauthClient: OAuthClientService,
    public smartButton: SmartButtonService,
    public excel: ExcelService,
    public material: MaterialService,
    public attribute: AttributeService,
    public language: LanguageService,
    public country: CountryService,
    public reflaunt: ReflauntService,
    public bulkOperation: BulkOperationService,
    public location: LocationService,
    public model: ModelService
  ) {}
}
