import Model from './Model';
import * as _ from 'lodash';
import ItemsCost from './ItemsCost';
import User from './User';
import { MarketplaceConfigurationOverride } from './MarketplaceConfigurationOverride';

export class MarketplaceRetailerConfiguration extends Model {
  constructor(options) {
    super();
    (this as any).retailer = d => new User(d.data);
    (this as any).marketplace = d => new User(d.data);
    (this as any).item_cost_config = d => new ItemsCost(d.data);
    (this as any).config_override = d => new MarketplaceConfigurationOverride(d.data);
    this.bind(options);
  }
}
