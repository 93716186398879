import { MainComponent } from './components/main.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const appRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'product', pathMatch: 'full' },
      {
        path: 'admin',
        loadChildren: () => import('./components/admin/admin.module').then((m) => m.AdminModule)
      },
      {
        path: 'retailer',
        loadChildren: () => import('./components/retailer/retailer.module').then((m) => m.RetailerModule)
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./components/marketplace/marketplace.module').then((m) => m.MarketplaceModule)
      },
      {
        path: 'customer',
        loadChildren: () => import('./components/customer/customer.module').then((m) => m.CustomerModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./components/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./components/settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'integration',
        loadChildren: () => import('./components/integration/integration.module').then((m) => m.IntegrationModule)
      },
      {
        path: 'blockchain',
        loadChildren: () => import('./components/blockchain/blockchain.module').then((m) => m.BlockchainModule)
      },
      { path: 'login', redirectTo: '/auth/login', pathMatch: 'full' },
      { path: 'register', redirectTo: '/auth/register', pathMatch: 'full' },
      {
        path: 'attribute',
        loadChildren: () => import('./components/attribute/attribute.module').then((m) => m.AttributeModule)
      },
      {
        path: 'measurement-unit',
        loadChildren: () => import('./components/measurement-unit/measurement-unit.module').then((m) => m.MeasurementUnitModule)
      },
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () => import('./components/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./components/landing-page/landing-page.module').then((m) => m.LandingPageModule)
  },
  {
    path: 'proxy-page',
    loadChildren: () => import('./components/proxy-page/proxy-page.module').then((m) => m.ProxyPageModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      useHash: false,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
