import Configuration from './configuration/configuration.saga';
import ListRetailer from './list-retailer/list-retailer.saga';
import Detail from './detail/detail.saga';
import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import list from './list/list.saga';
import edit from './edit/edit.saga';
import create from './create/create.saga';
import webhook from './webhook/webhook.saga';
import { GET_ALL_ADMIN_MARKETPLACE_REQUESTED, GET_ALL_ADMIN_MARKETPLACE_SUCCESSED } from './marketplace.action';
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';

export function* fetchAllMarketplaces() {
  const fetched = yield select((state) => (state as any).Admin.Marketplace.all.fetched);
  if (!fetched) {
    try {
      const api = AppInjector.get(ApiService);
      let result = yield api.admin.user.get({ per_page: 10000 }).toPromise();
      return result.items;
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  } else {
    const data = yield select((state) => (state as any).Admin.Marketplace.all.items);
    return data;
  }
}

function* allSaga(action) {
  const data = yield call(fetchAllMarketplaces);
  yield put({ type: GET_ALL_ADMIN_MARKETPLACE_SUCCESSED, component: action.component, data: data });
}

function* watchFetchAllMarketplacesRequest() {
  yield takeLatest(GET_ALL_ADMIN_MARKETPLACE_REQUESTED, allSaga);
}
export default [...Configuration, ...ListRetailer, ...Detail, ...list, ...edit, ...create, ...webhook, watchFetchAllMarketplacesRequest];
