import { AuthGuardService } from './../../../auth/auth-guard.service';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailComponent } from './detail/detail.component';
import { PercentageComponent } from './percentage/percentage.component';
import { EndpointComponent } from './endpoint/endpoint.component';
import { CredentialComponent } from '../../credential/credential.component';
import { SmartButtonComponent } from './smart-button/smart-button.component';
import { ADMIN_RETAILER_COMP } from './retailer.const';
import { CommissionConfigComponent } from './commission-config/commisison-config.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: ':id/edit',
        component: EditComponent
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: ':id/detail',
        component: DetailComponent
      },
      {
        path: ':id/percentage',
        component: PercentageComponent
      },
      {
        path: ':id/endpoint',
        component: EndpointComponent
      },
      {
        path: ':id/credential',
        component: CredentialComponent,
        data: {
          comp: ADMIN_RETAILER_COMP
        }
      },
      {
        path: ':id/smart-button',
        component: SmartButtonComponent
      },
      {
        path: ':id/commission-config',
        component: CommissionConfigComponent
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetailerRoutingModule {}
