import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from './../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';

import { RENDER_CREATE_RETAILER_FORM_REQUESTED, CREATE_RETAILER_REQUESTED } from './create.actions';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import { TextBox, TextArea } from '@reflaunt/reactive-form';
import { InputBase } from '@reflaunt/reactive-form/lib/models/InputBase';
import { Dropdown } from '@reflaunt/reactive-form';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public store;
  constructor(public activatedRoute: ActivatedRoute, store: Store) {
    this.store = AppInjector.get(Store).getInstance();
  }

  ngOnInit() {
    let inputs: InputBase<any>[] = [
      new TextBox({
        key: 'email',
        label: 'Email *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          },
          {
            validator: Validators.email,
            message: 'This field must be an email address'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'password',
        label: 'Password *',
        type: 'password',
        minLength: 6,
        maxLength: 20,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          },
          {
            validator: Validators.minLength(6),
            message: 'Minimmum alowed charactes are 6'
          },
          {
            validator: Validators.maxLength(20),
            message: 'Maximmum alowed charactes are 20'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'first_name',
        label: 'First name *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'last_name',
        label: 'Last name *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'company',
        label: 'Company *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'shipping_company',
        label: 'Shipping Company *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'address',
        label: 'Address *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'zipcode',
        label: 'Zipcode *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'website',
        label: 'Website *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
          // {
          //   validator: Validators.pattern('^(https?://)?(www.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*.)+[w]{2,}(/S*)?$'),
          //   message: 'This field must be a website address'
          // }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1,
        placeholder: 'https://www.example.com'
      }),
      new Dropdown({
        key: 'shipping_method_id',
        label: 'Courier *',
        classes: ['col-6'],
        group_classes: ['col-12'],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new Dropdown({
        key: 'voucher_type',
        label: 'Voucher Type *',
        classes: ['col-6'],
        group_classes: ['col-12'],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TextBox({
        key: 'voucher_integration_type',
        label: 'Voucher Integration Type',
        validators: [
          {
            validator: Validators.maxLength(10),
            message: 'The maximum allowed characters are 10'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12']
      }),
      new TextArea({
        key: 'note',
        label: 'Note',
        classes: ['col-12'],
        group_classes: ['col-12'],
        order: 1
      })
    ];
    this.store.dispatch({ type: RENDER_CREATE_RETAILER_FORM_REQUESTED, data: { inputs: inputs } });
  }

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      let data = form.value;
      data = _.assign(form.value, { description: '', shipping_method_id: form.value.shipping_method_id.value, voucher_type: form.value.voucher_type.value });
      store.dispatch({
        type: CREATE_RETAILER_REQUESTED,
        data: _.assign(data, { role_id: 4 })
      });
    }
  }
}
