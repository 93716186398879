import ProxyPage from '../components/proxy-page/proxy-page.saga';
import { PreloaderService } from './../common/services/preloader/preloader.service';
import { AppInjector } from './../app-injector';
import { takeEvery, fork, all } from 'redux-saga/effects';
import { API_CALL_ERROR } from './action';
import { NotificationService } from '../common/services/notification/notification.service';
import * as _ from 'lodash';
import main from '../components/main.saga';
import auth from '../components/auth/auth.saga';
import profileSaga from '../components/profile/profile.saga';
import integrationSaga from './../components/integration/integration.saga';
import retailer from './../components/retailer/retailer.saga';
import admin from './../components/admin/admin.saga';
import attribute from './../components/attribute/attribute.saga';
import measurementUnitSaga from '../components/measurement-unit/measurement-unit.saga';
import customer from './../components/customer/customer.saga';
import blockchain from './../components/blockchain/blockchain.saga';
import landingPage from '../components/landing-page/landing-page.saga';
import credential from './../components/credential/credential.saga';
import printSaga from './../components/print/print.saga';

function* watchApiCallError() {
  yield takeEvery(API_CALL_ERROR, function* (action) {
    AppInjector.get(PreloaderService).hide();
    if ((action as any).error !== undefined) {
      if ((action as any).error.error !== undefined && (action as any).error.error.message !== undefined) {
        AppInjector.get(NotificationService).show('warning', (action as any).error.error.message, 5000);
      }
    }
  });
}

export default function* sagas() {
  yield all(
    _.map([...ProxyPage, watchApiCallError, ...admin, ...attribute, ...measurementUnitSaga, ...retailer, ...integrationSaga, ...main, ...auth, ...profileSaga, ...customer, ...blockchain, ...landingPage, ...credential, ...printSaga], (item) =>
      fork(item)
    )
  );
}
