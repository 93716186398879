import * as _ from 'lodash';
import { GET_DETAIL_RETAILER_SUCCESSED } from './detail.actions';


export const detail = (state = { updated: false, fetched: false, inputs: [] }, action) => {
  switch (action.type) {
    case GET_DETAIL_RETAILER_SUCCESSED:
    return _.assign({}, state, { fetched: true, item: action.data });

    default:
      return state;
  }
};
