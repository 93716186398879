import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMask'
})
export class EmailMaskPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return this.mask(value);
  }

  mask(email) {
    // const maskedEmail = email.replace(/([^@\.])/g, '*').split('');
    // let previous = '';
    // for (let i = 0; i < maskedEmail.length; i++) {
    //   if (i < 1 || previous === '.' || previous === '@') {
    //     maskedEmail[i] = email[i];
    //   }
    //   previous = email[i];
    // }
    // return maskedEmail.join('');

    return email
      .split(/[.,@]/)
      .map((part) => {
        const separatorIndex = email.indexOf(part) + part.length;
        const separator = email[separatorIndex];
        let content;
        if (part.length > 4) {
          const len = part.length;
          content = part[0] + new Array(part.length - 2).fill('*').join('') + part[len - 1];
        } else {
          content = new Array(part.length).fill('*').join('');
        }
        return content + (separator || '');
      })
      .join('');
  }
}
