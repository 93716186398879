import { DELETE_RETAILER_REQUESTED } from './../edit/edit.actions';
import { FETCH_RETAILERS_REQUESTED, CHANGE_USER_STATUS_REQUESTED, RETAILER_SYNC_PRODUCTS_REQUEST } from './list.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from './../../../../store/store.module';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { USER_ROLES } from '../../../../app.const';
import {
  detailRouter,
  editRouter,
  createRouter,
  percentageRetailerRouter,
  listRetailerCredentialRouter,
  configSmartButtonRouter,
  configCommissionRouter,
  SHOPIFY_SHOP_CONFIG_KEY
} from '../retailer.const';
import { listUserRouter, listProductRouter } from '../../../retailer/retailer.const';
import { listRouter } from '../../product/product.const';
import { QueryParser } from '../../../../common/util/query-parser';
import { editServiceConfigurationRouter } from '../../user/user.const';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  protected navigationSubscription: any;
  protected router: any;
  public store;
  public detailRouter = detailRouter;
  public editRouter = editRouter;
  public createRouter = createRouter;
  public listUserRouter = listUserRouter;
  public listProductRouter = listProductRouter;
  public percentageRetailerRouter = percentageRetailerRouter;
  public listRetailerCredentialRouter = listRetailerCredentialRouter;
  public configSmartButtonRouter = configSmartButtonRouter;
  public editServiceConfigurationRouter = editServiceConfigurationRouter;
  public configCommissionRouter = configCommissionRouter;

  public filters = {
    role_ids: USER_ROLES.RETAILER
  };
  public queryParser;
  public listRouter = listRouter;
  public status = [
    {
      label: 'Pending',
      value: 2
    },
    {
      label: 'Active',
      value: 1
    }
  ];
  public constraintsFunction = (id) => JSON.stringify({ retailer_id: id });
  constructor(store: Store, private notification: NotificationService, private activedRoute: ActivatedRoute, router: Router, queryParser: QueryParser) {
    this.store = store.getInstance();
    this.queryParser = queryParser;
    this.activedRoute = activedRoute;
    this.router = router;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const sortQueryParams = this.activedRoute.snapshot.queryParams.sort;
        this.store.dispatch({
          type: FETCH_RETAILERS_REQUESTED,
          data: {
            pagination: this.queryParser.parse(['sort', 'constraints', 'page', 'search', 'email']),
            filter: this.filters,
            sortQueryParams: sortQueryParams
          }
        });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  private parseQueryParams(): object {
    let params = {
      page: 1,
      per_page: 20
    };
    if (!_.isUndefined(this.activedRoute.snapshot.queryParams.page)) {
      params = _.assign(params, { page: this.activedRoute.snapshot.queryParams.page });
    }
    if (!_.isUndefined(this.activedRoute.snapshot.queryParams.search)) {
      params = _.assign(params, { search: this.activedRoute.snapshot.queryParams.search });
    }
    if (!_.isUndefined(this.activedRoute.snapshot.queryParams.order_by)) {
      params = _.assign(params, { orderBy: this.activedRoute.snapshot.queryParams.order_by });
    }
    return params;
  }

  delete(id) {
    if (!_.isUndefined(id)) {
      this.store.dispatch({
        type: DELETE_RETAILER_REQUESTED,
        data: id
      });
    }
  }

  changeUserStatus(item) {
    this.store.dispatch({
      type: CHANGE_USER_STATUS_REQUESTED,
      data: item
    });
  }

  isDisplaySyncButton(item) {
    if (item.service_configurations && item.service_configurations.length) {
      const isShopifyRetailer = item.service_configurations.find((f) => f.key === SHOPIFY_SHOP_CONFIG_KEY);
      if (isShopifyRetailer) {
        return true;
      }
    }
    return false;
  }

  syncProducts(item) {
    (Swal as any)
      .fire({
        title: 'Do you want to synchronize products for this retailer?',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        html: ` <div class="import-info__wrapper">
                    <p>Please select type of synchronize </p>
                    <select id="sync-select-type" class="swal2-select" style="display: flex;">
                        <option value="default">Shopify ==> Reflaunt </option>
                        <option value="reflaunt">Reflaunt ==> Shopify </option>
                    </select>
                </div>`,
        preConfirm: () => {
          const syncType = (document.getElementById('sync-select-type') as any).value;
          return {
            syncType
          };
        }
      })
      .then((result) => {
        let data = { retailer_id: item.id };
        if (!result.value) {
          return false;
        }
        if (result.value.syncType === 'reflaunt') {
          data = _.assign(data, { useToken: true });
        }
        this.store.dispatch({
          type: RETAILER_SYNC_PRODUCTS_REQUEST,
          data
        });
      });
  }
}
