import * as _ from 'lodash';
import { FETCH_TRANSACTION_HISTORY_SELLER_SUCCEEDED } from './transaction-history.actions';

export const TransactionHistory = (
  state = {
    fetched: false,
    loading: false,
    items: [],
    deleted: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_TRANSACTION_HISTORY_SELLER_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.data });
    default:
      return state;
  }
};
