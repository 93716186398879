import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { detail } from './detail/detail.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { Import } from './import/import.reducer';
import { Event } from './event/event.reducer';
import { AsyncProcess } from './async-process/async-process.reducer';
import { addTags } from './add-tag/add-tag.reducer';
import { bulkPush } from './bulk-push/bulk-push.reducer';
import { bulkDiscount } from './bulk-discount/bulk-discount.reducer';
import { History } from './history/history.reducer';
import { removeBulkDiscount } from './remove-bulk-discount/remove-bulk-discount.reducer';
export const Product = combineReducers({
  list,
  detail,
  edit,
  create,
  Import,
  Event,
  addTags,
  bulkPush,
  AsyncProcess,
  bulkDiscount,
  History,
  removeBulkDiscount
});
