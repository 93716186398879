import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { AppInjector } from '../../../../app-injector';
import {
  NEGOTIATION_FETCH_PRODUCTS_REQUESTED,
  NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED,
  NEGOTIATION_MAP_CATEGORIES_TO_PRODUCTS,
  NEGOTIATION_BROADCAST_ITEM_REQUESTED,
  NEGOTIATION_BROADCAST_ITEM_SUCCESSED,
  NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED,
  NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED,
  NEGOTIATION_FETCH_PRODUCT_IMAGE_SUCCESSED,
  NEGOTIATION_FETCH_CUSTOMER_INFORMATION_REQUESTED,
  NEGOTIATION_FETCH_CUSTOMER_INFORMATION_SUCCEEDED,
  NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_REQUESTED,
  NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_SUCCESSED,
  NEGOTIATION_PUBLISH_PRODUCT_REQUESTED,
  NEGOTIATION_PUBLISH_PRODUCT_SUCCESSED,
  NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED,
  NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED,
  NEGOTIATION_EXPORT_PRODUCT_REQUESTED,
  NEGOTIATION_EXPORT_PRODUCT_SUCCEEDED,
  NEGOTIATION_EXPORT_PRODUCT_FAILED,
  NEGOTIATION_FETCH_ALL_CONDITION_REQUESTED,
  NEGOTIATION_FETCH_ALL_CONDITION_SUCCESSED,
  NEGOTIATION_FETCH_ALL_DESIGNER_REQUESTED,
  NEGOTIATION_FETCH_ALL_DESIGNER_SUCCESSED,
  NEGOTIATION_FETCH_ALL_MARKETPLACE_REQUESTED,
  NEGOTIATION_FETCH_ALL_MARKETPLACE_SUCCESSED,
  NEGOTIATION_FETCH_ALL_CATEGORY_REQUESTED,
  NEGOTIATION_FETCH_ALL_CATEGORY_SUCCESSED,
  NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED,
  NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED,
  NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED,
  NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED,
  NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED,
  NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED,
  NEGOTIATION_FETCH_ALL_STATUS_REQUESTED,
  NEGOTIATION_FETCH_ALL_STATUS_SUCCESSED,
  NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_SUCCEEDED,
  NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_REQUESTED
} from './list-product.actions';
import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import Cookies from 'js-cookie';
import { ActivatedRoute } from '@angular/router';

function* watchFetchProductRequested() {
  yield takeEvery(NEGOTIATION_FETCH_PRODUCTS_REQUESTED, function* (action: any) {
    try {
      console.log('------------in here------------');
      let results = yield AppInjector.get(ApiService).admin.product.get(action.data).toPromise();
      yield put({ type: NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchFetchMarketplaceRequested() {
  yield takeLatest(NEGOTIATION_FETCH_ALL_MARKETPLACE_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.user.get({ roles: 'marketplace', includes: 'marketplace', per_page: 10000 }).toPromise();
      yield put({ type: NEGOTIATION_FETCH_ALL_MARKETPLACE_SUCCESSED, data: result.items });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* mapCategoriesToProduct(action) {
  const api = AppInjector.get(ApiService);
  try {
    let categories = yield select((state) => (state as any).Admin.Category.list.items);
    yield put({ type: NEGOTIATION_MAP_CATEGORIES_TO_PRODUCTS, data: categories });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchProductImages(action) {
  try {
    let request = yield AppInjector.get(ApiService)
      .media.list({ imageable_type: 'product', imageable_ids: action.data.map((item) => item.getId()) })
      .toPromise();
    yield put({ type: NEGOTIATION_FETCH_PRODUCT_IMAGE_SUCCESSED, data: request });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchCustomerInformationRequested() {
  yield takeEvery(NEGOTIATION_FETCH_CUSTOMER_INFORMATION_REQUESTED, function* (action: any) {
    try {
      let data = {
        ids: action.data
      };
      const result = yield AppInjector.get(ApiService).user.list(data).toPromise();
      yield put({ type: NEGOTIATION_FETCH_CUSTOMER_INFORMATION_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchFetchAllConditionRequested() {
  yield takeLatest(NEGOTIATION_FETCH_ALL_CONDITION_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).condition.get({ per_page: 10000 }).toPromise();
      yield put({ type: NEGOTIATION_FETCH_ALL_CONDITION_SUCCESSED, data: result.items });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchFetchAllDesignerRequested() {
  yield takeLatest(NEGOTIATION_FETCH_ALL_DESIGNER_REQUESTED, function* (action: any) {
    try {
      let designers = yield AppInjector.get(ApiService).admin.designer.get({ per_page: 10000 }).toPromise();
      designers = designers.items;
      designers.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      const inputs = yield select((store) => (store as any).Admin.Product.list.inputs_designer);
      const inputs_designer = _.find(inputs, (e) => e.key === 'designer_id');
      inputs_designer.options = _.map(designers, (e) => _.assign({ value: e.id, label: e.name }));

      yield put({ type: NEGOTIATION_FETCH_ALL_DESIGNER_SUCCESSED, data: designers });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* updateFilterDesignerRequested() {
  yield takeLatest(NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED, function* (action: any) {
    try {
      let designers = yield AppInjector.get(ApiService).admin.designer.get({ per_page: 10000 }).toPromise();
      designers = designers.items;
      designers.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      const inputs_designer = _.find(action.inputs, (e) => e.key === 'designer_id');
      inputs_designer.options = _.map(designers, (e) => _.assign({ value: e.id, label: e.name }));
      yield put({ type: NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED, data: action.inputs });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchFetchAllCategoriesRequested() {
  yield takeLatest(NEGOTIATION_FETCH_ALL_CATEGORY_REQUESTED, function* (action: any) {
    try {
      const categories = yield AppInjector.get(ApiService).admin.category.list().toPromise();
      categories.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      yield put({ type: NEGOTIATION_FETCH_ALL_CATEGORY_SUCCESSED, data: categories });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchFetchAllStatusRequested() {
  yield takeLatest(NEGOTIATION_FETCH_ALL_STATUS_REQUESTED, function* (action: any) {
    try {
      const statuses = yield AppInjector.get(ApiService).status.list().toPromise();
      yield put({ type: NEGOTIATION_FETCH_ALL_STATUS_SUCCESSED, data: statuses });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchFetchAllMarketplaceRequested() {
  yield takeEvery(NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .admin.user.get({ ...action.data, per_page: 10000 })
        .toPromise();
      result.items.sort((a, b) =>
        _.lowerCase(a.marketplace && a.marketplace.company ? a.marketplace.company : a.email) <
        _.lowerCase(b.marketplace && b.marketplace.company ? b.marketplace.company : b.email)
          ? -1
          : 1
      );
      yield put({ type: NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED, data: result.items });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* mapUsersToProduct(action) {
  try {
    let seller_ids = _.uniq(_.compact(_.map(action.data, 'seller_id')));
    yield put({ type: NEGOTIATION_FETCH_CUSTOMER_INFORMATION_REQUESTED, data: seller_ids });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchProductMarketplaces(action) {
  try {
    let product_ids = _.map(action.data, (item) => item.id).join(',');
    const result = yield AppInjector.get(ApiService).admin.product.getMarketplace({ product_ids }).toPromise();
    yield put({ type: NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* resyncFromRetailerRequested(action) {
  yield takeEvery(NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      const result = yield AppInjector.get(ApiService).admin.product.syncRequest(action.data).toPromise();
      AppInjector.get(PreloaderService).hide();
      yield put({ type: NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchFetchProductsSuccessed() {
  yield takeLatest(NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED, mapCategoriesToProduct);
  // yield takeLatest(NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED, fetchProductImages);
  yield takeLatest(NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED, mapUsersToProduct);
  yield takeLatest(NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED, fetchProductMarketplaces);
}
function* watchFetchProductMarketplaceRequested() {
  yield takeLatest(NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_REQUESTED, fetchProductMarketplaces);
}

function* watchBroadcastItemRequested() {
  yield takeEvery(NEGOTIATION_BROADCAST_ITEM_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = action.data;
      let result = yield AppInjector.get(ApiService).broadcast.update(params.id, params).toPromise();
      yield put({ type: NEGOTIATION_BROADCAST_ITEM_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* broadcast_product_successed(action) {
  AppInjector.get(PreloaderService).hide();
  AppInjector.get(NotificationService).show('success', 'new product is added', 3000);
}

function* watchBroadcastItemSuccessed() {
  yield takeEvery(NEGOTIATION_BROADCAST_ITEM_SUCCESSED, broadcast_product_successed);
}

function* watchUpdateProductStatusRequested() {
  yield takeEvery(NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = { status: action.data.status };
      let result = yield AppInjector.get(ApiService).admin.product.changeStatus(action.data.id, params).toPromise();
      yield put({ type: NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateProductStatusSuccessed() {
  yield takeEvery(NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    AppInjector.get(NotificationService).show('success', 'Product status updated successfully', 3000);
  });
}

function* watchFetchProductMarketplaceSuccessed() {
  yield takeEvery(NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_SUCCESSED, function* (action: any) {
    yield put({ type: NEGOTIATION_FETCH_ALL_MARKETPLACE_REQUESTED });
  });
}

function* watchPublishProductRequested() {
  yield takeEvery(NEGOTIATION_PUBLISH_PRODUCT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = { marketplaces: action.marketplaces.map((item) => item.id) };
      let result = yield AppInjector.get(ApiService).admin.product.syncMarketplaces(action.data.id, params).toPromise();
      yield put({ type: NEGOTIATION_PUBLISH_PRODUCT_SUCCESSED, data: result, product_id: action.data.id });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      if (e.error.error_code === 1234) {
        const queryParams = AppInjector.get(ActivatedRoute).snapshot.queryParams;
        Cookies.set('queryParamsWhenFailPublish', JSON.stringify(queryParams));
      }
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchPublishProductSuccessed() {
  AppInjector.get(PreloaderService).hide();
  yield takeEvery(NEGOTIATION_PUBLISH_PRODUCT_SUCCESSED, function* (action: any) {
    AppInjector.get(NotificationService).show('success', 'Publish product successfully', 3000);
    const products = yield select((state) => (state as any).Admin.Product.list.items);
    yield put({ type: NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_REQUESTED, data: products });
    yield put({ type: NEGOTIATION_FETCH_PRODUCTS_REQUESTED, data: action.product_id });
  });
}

function* watchUpdateProductItemStatusRequested() {
  yield takeEvery(NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = { status_id: action.data.status_id };
      let result = yield AppInjector.get(ApiService).admin.productItem.changeStatus(action.data.product_item_id, params).toPromise();
      yield put({ type: NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateProductItemStatusSuccessed() {
  yield takeEvery(NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    AppInjector.get(NotificationService).show('success', 'Product status updated successfully', 3000);
  });
}

function* watchExportProductRequested() {
  yield takeEvery(NEGOTIATION_EXPORT_PRODUCT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService).marketplace.exportAsCsv(action.data).toPromise();
      const async = yield AppInjector.get(ApiService).asyncProcess.checkAsyncStatus(result.id, 10000).toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          AppInjector.get(NotificationService).show('success', `File ready <a href="${async.message}" target="_blank">Click here to download</a>`);
          yield put({ type: NEGOTIATION_EXPORT_PRODUCT_SUCCEEDED });
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
          yield put({ type: NEGOTIATION_EXPORT_PRODUCT_FAILED });
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `file will take longer time to process`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      yield put({ type: NEGOTIATION_EXPORT_PRODUCT_FAILED });
    }
  });
}

function* watchExportProductSucceeded() {
  yield takeEvery(NEGOTIATION_EXPORT_PRODUCT_SUCCEEDED, function* (action: any) {
    window.open(action.data);
  });
}

function* getAllRetailerInProductPage() {
  yield takeLatest(NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED, function* (action: any) {
    try {
      let data: any = {};
      if (!_.isNil(action.data)) {
        data.constraints = JSON.stringify(action.data);
      }
      data.includes = 'retailer';
      let results = yield AppInjector.get(ApiService).user.list(data).toPromise();
      results = results.sort((a, b) =>
        _.lowerCase(a.retailer && a.retailer.company ? a.retailer.company : a.email) < _.lowerCase(b.retailer && b.retailer.company ? b.retailer.company : b.email) ? -1 : 1
      );
      yield put({ type: NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED, data: results });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

export default [
  watchFetchMarketplaceRequested,
  watchFetchAllMarketplaceRequested,
  // watchFetchAllDesignerRequested,
  watchFetchAllConditionRequested,
  updateFilterDesignerRequested,
  watchFetchProductMarketplaceRequested,
  watchFetchProductMarketplaceSuccessed,
  watchFetchProductRequested,
  watchFetchProductsSuccessed,
  watchBroadcastItemRequested,
  watchBroadcastItemSuccessed,
  watchUpdateProductStatusRequested,
  watchUpdateProductStatusSuccessed,
  watchFetchCustomerInformationRequested,
  watchPublishProductRequested,
  watchPublishProductSuccessed,
  watchUpdateProductItemStatusRequested,
  watchUpdateProductItemStatusSuccessed,
  watchExportProductRequested,
  watchExportProductSucceeded,
  getAllRetailerInProductPage,
  watchFetchAllCategoriesRequested,
  watchFetchAllStatusRequested,
  resyncFromRetailerRequested
];
