import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Endpoint from '../../models/Endpoint';
import * as _ from 'lodash';

@Injectable()
export class EndpointService extends ServiceProvider {
  public url = '/api/v1/endpoint';
  public model = Endpoint;
}
