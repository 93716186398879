import {
  FETCH_ALL_ADMIN_ORDER_SUCCESSED,
  GET_ALL_RETAILER_IN_ORDER_PAGE_SUCCESSED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_SUCCESSED,
  ORDER_UPDATE_INPUT_FILTER_DATE
} from './list.actions';
import * as _ from 'lodash';

export const list = (
  state = {
    fetched: false,
    items: [],
    retailers: [],
    filter_date: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    case GET_ALL_RETAILER_IN_ORDER_PAGE_SUCCESSED:
      return _.assign({}, state, { fetched: true, retailers: action.data });
    case FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_SUCCESSED:
      return _.assign({}, state, { fetched: true, marketplaces: action.data });
    case ORDER_UPDATE_INPUT_FILTER_DATE:
      action.inputs = _.map(action.inputs, (i) => {
        if (i.key === 'date_filter_type') {
          i.value = _.find(i.options, o => o.value === 'sold_date')
        }
        return i;
      });
      return _.assign({}, state, { filter_date: action.inputs });
    default:
      return state;
  }
};
