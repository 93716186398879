import { Router } from '@angular/router';
import { FETCH_ALL_ADMIN_LOCATION_REQUESTED } from './../list/list.actions';
import {
  DELETE_ADMIN_LOCATION_REQUESTED,
  FETCH_ADMIN_LOCATION_REQUESTED,
  fetchAdminLocationRequested,
  FETCH_ADMIN_LOCATION_SUCCESSED,
  fetchAdminLocationSuccessed,
  EDIT_ADMIN_LOCATION_REQUESTED,
  EDIT_ADMIN_LOCATION_SUCCESSED,
  RENDER_EDIT_ADMIN_LOCATION_FORM_REQUESTED,
  FILL_ADMIN_LOCATION_DETAIL_FORM
} from './edit.actions';
import { put, takeLatest } from 'redux-saga/effects';
import { listAdminLocation } from '../location.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchEditLocationRequest() {
  yield takeLatest(EDIT_ADMIN_LOCATION_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      const result = yield AppInjector.get(ApiService).admin.location.update(action.id, action.data).toPromise();
      yield put({ type: EDIT_ADMIN_LOCATION_SUCCESSED, data: result });
      router.navigate(listAdminLocation());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetLocationRequest() {
  yield takeLatest(FETCH_ADMIN_LOCATION_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.location.show(action.data).toPromise();
      yield put(fetchAdminLocationSuccessed(result));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteLocationRequest() {
  yield takeLatest(DELETE_ADMIN_LOCATION_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.location.delete(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Delete Item Success', 5000);
      yield put({ type: FETCH_ALL_ADMIN_LOCATION_REQUESTED });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRenderLocationDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_ADMIN_LOCATION_FORM_REQUESTED, function*(action: any) {
    yield put(fetchAdminLocationRequested(action.data.id));
  });
}

function* watchFetchLocationDetailSuccessed() {
  yield takeLatest(FETCH_ADMIN_LOCATION_SUCCESSED, function* (action: any) {
    yield put({ type: FILL_ADMIN_LOCATION_DETAIL_FORM, data: action.data });
  });
}

export default [watchEditLocationRequest, watchGetLocationRequest, watchDeleteLocationRequest, watchRenderLocationDetailFormRequested, watchFetchLocationDetailSuccessed];
