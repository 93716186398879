export const FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED = 'FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED';
export const FETCH_ALL_ADMIN_ORDER_ITEM_SUCCESSED = 'FETCH_ALL_ADMIN_ORDER_ITEM_SUCCESSED';
export const GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_REQUESTED = 'GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_REQUESTED';
export const GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_SUCCESSED = 'GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_SUCCESSED';
export const FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_REQUESTED = 'FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_REQUESTED';
export const FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_SUCCESSED = 'FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_SUCCESSED';
export const ORDER_ITEM_UPDATE_INPUT_FILTER_DATE = 'ORDER_ITEM_UPDATE_INPUT_FILTER_DATE';

export const FETCH_ALL_DATASETS_ORDER_ITEM_PAGE_REQUESTED = 'FETCH_ALL_DATASETS_ORDER_ITEM_PAGE_REQUESTED';
export const FETCH_ALL_DATASETS_ORDER_ITEM_PAGE_SUCCESSED = 'FETCH_ALL_DATASETS_ORDER_ITEM_PAGE_SUCCESSED';

export const FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_REQUESTED = 'FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_REQUESTED';
export const FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_SUCCESSED = 'FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_SUCCESSED';

export const FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_REQUESTED = 'FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_REQUESTED';
export const FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_SUCCESSED = 'FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_SUCCESSED';

export const FETCH_ALL_DESIGNER_ORDER_ITEM_PAGE_REQUESTED = 'FETCH_ALL_DESIGNER_ORDER_ITEM_PAGE_REQUESTED';
export const FETCH_ALL_DESIGNER_ORDER_ITEM_PAGE_SUCCESSED = 'FETCH_ALL_DESIGNER_ORDER_ITEM_PAGE_SUCCESSED';

export const UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_REQUESTED = 'UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_REQUESTED';
export const UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_SUCCESSED = 'UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_SUCCESSED';

export const ORDER_ITEM_PAGE_CANCEL_ORDER_PRODUCT_REQUESTED = 'ORDER_ITEM_PAGE_CANCEL_ORDER_PRODUCT_REQUESTED';
export const ORDER_ITEM_PAGE_CANCEL_ORDER_PRODUCT_SUCCESSED = 'ORDER_ITEM_PAGE_CANCEL_ORDER_PRODUCT_SUCCESSED';

export const ORDER_ITEM_PAGE_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED = 'ORDER_ITEM_PAGE_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED';
export const ORDER_ITEM_PAGE_UPDATE_STATUS_ORDER_PRODUCT_SUCCESSED = 'ORDER_ITEM_PAGE_UPDATE_STATUS_ORDER_PRODUCT_SUCCESSED';

export const ORDER_ITEM_PAGE_UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED = 'ORDER_ITEM_PAGE_UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED';
export const ORDER_ITEM_PAGE_UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_SUCCESSED = 'ORDER_ITEM_PAGE_UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_SUCCESSED';

export const LIST_ORDER_ITEM_SUPPORT_PARAMS = [
  'sort',
  'constraints',
  'page',
  'per_page',
  'search',
  'start_date',
  'end_date',
  'status',
  'order_status',
  'marketplace_id',
  'retailer_id',
  'date_filter_type',
  'shipment_status'
];
