import { PipesModule } from './../../../common/pipes/pipes.module';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { DatasetRoutingModule } from './dataset-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CreateComponent } from './create/create.component';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from '../../../template/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ListGroupComponent } from './list-group/list-group.component';
import { AddToGroupComponent } from './add-to-group/add-to-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [CommonModule, DatasetRoutingModule, FormsModule, PipesModule, DirectivesModule, SweetAlert2Module, SharedModule, NgSelectModule, OwlDateTimeModule, OwlNativeDateTimeModule, ReactiveFormsModule],
  declarations: [ListComponent, EditComponent, CreateComponent, CreateGroupComponent, ListGroupComponent, AddToGroupComponent, EditGroupComponent]
})
export class DatasetModule {}
