import * as _ from 'lodash';
import { GET_NOTIFICATION_ACTION_SUCCEEDED, RENDER_UPDATE_NOTIFICATION_FORM_REQUESTED } from './edit.actions';
import { support } from '@reflaunt/reactive-form';

export const edit = (state = { fetched: false, item: null, inputs: [] }, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_ACTION_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });
    case RENDER_UPDATE_NOTIFICATION_FORM_REQUESTED:
      const inputs = _.map(action.data, (i) => {
        if (i.key === 'default_template') {
          i.value = state.item.default_template;
        }
        return i;
      });
      return _.assign({}, state, { inputs: inputs });
    default:
      return state;
  }
};
