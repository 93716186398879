import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasItemPipe } from './has-item/has-item.pipe';
import { CustomDatePipe } from './custom-date/custom-date.pipe';
import { LengthPipe } from './length/length.pipe';
import { FilterByPipe } from './filter-by/filter-by.pipe';
import { FindByPipe } from './find-by/find-by.pipe';
import { IsArrayPipe } from './is-array/is-array.pipe';
import { OrderByPipe } from './order-by/order-by.pipe';
import { SumByPipe } from './sum-by/sum-by.pipe';
import { FlatMapPipe } from './flat-map/flat-map.pipe';
import { ArrayKeyValue } from './array-key-value/array-key-value.pipe';
import { ConvertToTree } from './convert-to-tree/convert-to-tree.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { EmailMaskPipe } from './email-mask/email-mask.pipe';
import { FormartDateOwlPipe } from './formart-date-owl/formart-date-owl.pipe';
import { FilterByPropertyPipe } from './has-property/has-property.pipe';
@NgModule({
  imports: [CommonModule],
  exports: [HasItemPipe, FilterByPropertyPipe, CustomDatePipe, LengthPipe, FilterByPipe, IsArrayPipe, OrderByPipe, SumByPipe, FlatMapPipe, ArrayKeyValue, ConvertToTree, FindByPipe, SafeHtmlPipe, EmailMaskPipe, FormartDateOwlPipe],
  declarations: [HasItemPipe, CustomDatePipe, FilterByPropertyPipe, LengthPipe, FilterByPipe, IsArrayPipe, OrderByPipe, SumByPipe, FlatMapPipe, ArrayKeyValue, ConvertToTree, FindByPipe, SafeHtmlPipe, EmailMaskPipe, FormartDateOwlPipe]
})
export class PipesModule {}
