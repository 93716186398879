import { FETCH_STATUSES_SUCCEEDED, FETCH_STATUSES_REQUESTED, SORT_STATUSES_REQUESTED, SORT_STATUSES_SUCCEEDED } from './list.actions';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery } from 'redux-saga/effects';

function* getStatuses(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.status.get(action.data).toPromise();
    yield put({ type: FETCH_STATUSES_SUCCEEDED, data: results.items, pagination: results.pagination });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchStatussRequest() {
  yield takeEvery(FETCH_STATUSES_REQUESTED, getStatuses);
}

export default [
  watchFetchStatussRequest
];
