import { AttributeCondition } from './../../../../models/Attribute';
import { DICTIONARY_DESIGNER_PREOWNED_TYPE } from './../../../../models/Dictionary';
import {
  ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_REQUESTED,
  ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_SUCCESSED,
  DELETE_DESIGNER_ATTRIBUTE_REQUESTED,
  DELETE_DESIGNER_ATTRIBUTE_SUCCESSED,
  FETCH_DESIGNER_DICTIONARY_SUCCESSED,
  FETCH_DESIGNER_DICTIONARY_REQUESTED,
  ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_REQUESTED,
  ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_SUCCESS,
  ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_REQUESTED,
  ADMIN_ATTRIBUTES_DESIGNER_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_DESIGNER_FILTER_SELECTED,
  TOGGLE_DESIGNER_DICTIONARY_VALIDATION_SUCCEEDED,
  TOGGLE_DESIGNER_DICTIONARY_VALIDATION_REQUESTED
} from './designer.action';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_ATTRIBUTES_SELECT_ANY, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { Router } from '@angular/router';
import { designerAttributes } from './designer.const';

function* fetchDesigners(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.designer.get(action.data).toPromise();
    let selected_marketplace = yield select((state) => state.Admin.Attributes.all.marketplace.selected);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_SUCCESSED, data: results.items, pagination: results.pagination });
    if (selected_marketplace) {
      yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selected_marketplace, direct: false, component: 'designer' });
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchDesignerAttributeRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_REQUESTED, fetchDesigners);
}

function* deleteDesigner(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.admin.designer.delete(action.data.id).toPromise();
    yield put({ type: DELETE_DESIGNER_ATTRIBUTE_SUCCESSED, data: action.data.id });
    notification.show('success', 'Delete designer success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* fetchDesignerDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_DESIGNER_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteDesignerRequest() {
  yield takeEvery(DELETE_DESIGNER_ATTRIBUTE_REQUESTED, deleteDesigner);
}

function* watchFetchDesignerDictionaryRequested() {
  yield takeEvery(FETCH_DESIGNER_DICTIONARY_REQUESTED, fetchDesignerDictionary);
}

function* watchUpdateDesignerDictionaryRequested() {
  yield takeEvery(ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_REQUEST, updateDesignerDictionary);
}

function* updateDesignerDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.updateOrCreate(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_SUCCESS, data: result });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* toggleDesignerDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_DESIGNER_DICTIONARY_VALIDATION_REQUESTED, toggleDesignerDictionaryValidation);
}

function* toggleDesignerDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_DESIGNER_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchSelectAnyFilter() {
  yield takeEvery(ADMIN_ATTRIBUTES_SELECT_ANY, function* (action: any) {
    const attribute = yield select((state: any) => state.Admin.Attributes.all);
    const conditionInputValue = yield select((state: any) => state.Admin.Attributes.Designer.conditionInputValue);
    if (action.component && action.component === 'designer') {
      const designer_condition = ((conditionInputValue) => {
        if (conditionInputValue === DICTIONARY_DESIGNER_PREOWNED_TYPE) {
          return AttributeCondition.new_with_tag;
        }
        return AttributeCondition.default;

      })(conditionInputValue.value);
      yield put({ type: ADMIN_ATTRIBUTES_DESIGNER_FILTER_SELECTED, data: attribute, dictionary_type: conditionInputValue.value });
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_REQUEST, data: { marketplace_id: attribute.marketplace.selected.id }, designer_condition });
    }
  });
}

function* watchFetchAllMarketplaceDesignersRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let results = yield api.marketplace.getAllDesigners(action.data).toPromise();
      if (action.designer_condition) {
        results = results.filter((e) => e.condition === action.designer_condition)
      }
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_SUCCESS, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* createDesigner(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.designer.create(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_SUCCESS, data: result, component: action.component });

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'designer']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateDesignerRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_REQUESTED, createDesigner);
}

function* updateDesigner(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.designer.update(action.data.id, action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_SUCCESS, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update designer success', 5000);

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'designer']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateDesignerRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_REQUESTED, updateDesigner);
}

function* watchSynchroniseDesignersRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_DESIGNER_SYNCHRONISE_ATTRIBUTES_REQUEST, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.marketplace.syncAttributes(action.data).toPromise();
      const async = yield AppInjector.get(ApiService).asyncProcess.checkAsyncStatus(result.id, 10000).toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          router.navigate(designerAttributes());
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteDesignerRequest,
  watchFetchDesignerAttributeRequest,
  watchFetchDesignerDictionaryRequested,
  watchUpdateDesignerDictionaryRequested,
  watchSelectAnyFilter,
  watchFetchAllMarketplaceDesignersRequest,
  watchCreateDesignerRequest,
  watchUpdateDesignerRequest,
  watchSynchroniseDesignersRequest,
  toggleDesignerDictionaryValidationRequested
];
