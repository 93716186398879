import { put, takeEvery } from 'redux-saga/effects';
import { GET_ALL_ATTRIBUTES_BY_TYPE_REQUESTED, GET_ALL_ATTRIBUTES_BY_TYPE_SUCCEEDED } from '../attribute.action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { DELETE_ATTRIBUTE_REQUESTED } from './list.action';
import { NotificationService } from '../../../common/services/notification/notification.service';

function* watchGetAttributesByTypeRequested() {
  yield takeEvery(GET_ALL_ATTRIBUTES_BY_TYPE_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .attribute.getAttributeByType(action.data.type, action.data.params)
        .toPromise();
      yield put({ type: GET_ALL_ATTRIBUTES_BY_TYPE_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteAttributeRequested() {
  yield takeEvery(DELETE_ATTRIBUTE_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .attribute.deleteAttribute(action.data.type, action.data.id)
        .toPromise();
      AppInjector.get(NotificationService).show('success', `Successfully deleted attribute`, 3000);
      yield put({ type: GET_ALL_ATTRIBUTES_BY_TYPE_REQUESTED, data: { type: action.data.type }});
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [watchGetAttributesByTypeRequested, watchDeleteAttributeRequested];
