import Model from './Model';
import NotificationActionMarketplaceManagement from './NotificationActionMarketplaceManagement';

export default class NotificationAction extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }

  getConfigTemplateName() {
    const { config }: { config: NotificationActionMarketplaceManagement } = this as any;
    return config ? (config as any).getTemplateName() : '';
  }

  getConfigSubject() {
    const { config }: { config: NotificationActionMarketplaceManagement } = this as any;
    return config ? (config as any).getSubject() : '';
  }

  getConfigIntegration() {
    const { config }: { config: NotificationActionMarketplaceManagement } = this as any;
    return config ? (config as any).getIntegration() : '';
  }
}
