import { CREATE_CURRENCY_REQUESTED } from './create.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from './../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public store;
  public currency = {
    base: false,
    code: null,
    country: null,
    symbol: null,
    // rate: null
  };

  constructor(store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {}

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      store.dispatch({ type: CREATE_CURRENCY_REQUESTED, data: this.currency });
    }
  }
}
