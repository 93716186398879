import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from './../../../store/store.module';
import * as _ from 'lodash';
import { CREATE_INTEGRATION_REQUESTED, FETCH_ALL_MARKETPLACE_REQUESTED, UPDATE_INTEGRATION_FIELD, DELETE_INTEGRATION_REQUESTED } from './create.actions';
import { IntegrationDetailRouter } from '../integration.const';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public store;
  public submitted: Boolean = false;
  public IntegrationDetailRouter = IntegrationDetailRouter;

  constructor(public activatedRoute: ActivatedRoute, store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.store.dispatch({ type: FETCH_ALL_MARKETPLACE_REQUESTED });
  }

  delete(marketplace) {
    this.store.dispatch({
      type: DELETE_INTEGRATION_REQUESTED,
      data: marketplace
    });
  }

  add(marketplace) {
    this.store.dispatch({ type: CREATE_INTEGRATION_REQUESTED, data: [marketplace] });
  }
}
