import * as _ from 'lodash';
import { PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED, IMPORT_PRODUCT_FROM_CSV_SUCCESSED } from './import.actions';

export const Import = (state: any = { fetched: false, imported: false }, action) => {
  switch (action.type) {
    case PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED:
      let numSelected = 0;
      _.forEach(action.data, function(value) {
        if (value.selected) {
          numSelected += 1;
        }
      });
      return _.assign({}, state, { fetched: true, items: action.data, numSelected: numSelected, allSelected: action.data.length === numSelected });
    case IMPORT_PRODUCT_FROM_CSV_SUCCESSED:
      return _.assign({}, state, { imported: true, items: [], fetched: false });
    default:
      return state;
  }
};
