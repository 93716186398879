import * as _ from 'lodash';
import {
  ADMIN_INTEGRATION_GET_RETAILER_SUCCESS,
  INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  INDIVIDUAL_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION,
  INDIVIDUAL_CONFIG_SAVE_RETAILER_AND_MARKETPLACE_IDS,
  INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE
} from './marketplace-management.actions';

const resolveSelectedNotifyAction = (state, action) => {
  const { config } = state;
  return _.assign(
    {},
    state,
    {
      options: _.map(state.options, (item) => {
        return _.assign(item, { isEdit: false, checked: false, config: null });
      })
    },
    {
      options: _.map(state.options, (item) => {
        const matchedConfig = _.find(config.items, (configItem) => configItem.integration.notification_action.id === item.id);
        if (matchedConfig) {
          return _.assign(item, { checked: true, config: matchedConfig });
        }
        return item;
      })
    }
  );
};

const setActiveLanguage = (state, action) => {
  return _.assign({}, state, { activeLanguage: action.data });
};

export const MarketplaceManagement = (
  state = {
    itemSelected: null,
    marketplaceSelected: null,
    options: null,
    config: { fetched: false, items: [] },
    routingState: {
      retailerId: null,
      marketplaceId: null
    },
    activeLanguage: {}
  },
  action
) => {
  switch (action.type) {
    case ADMIN_INTEGRATION_GET_RETAILER_SUCCESS:
      const available_actions = action.available_actions;
      const de_marketplaces = action.data.marketplaces;
      const marketplaces = _.map(de_marketplaces, (value) => {
        const marketplace_selected = _.find(value.marketplace_managements.data, function (i) {
          return i.retailer_id === Number(action.retailerId);
        });
        value.marketplace_management = marketplace_selected;
        value.marketplace_management.actions = available_actions;
        return value;
      });
      action.data.marketplaces = marketplaces;
      return _.assign({}, state, { itemSelected: action.data });
    case 'GET_DETAIL_MARKETPLACE_EDIT':
      const data = _.find(state.itemSelected.marketplaces, (i) => i.id === Number(action.data));
      let options = data.marketplace_management.actions;
      const options_st1 = _.map(options, (opt) => {
        opt.isEdit = false;
        const dt1 = _.find(data.marketplace_management.notification_actions.data, (i) => i.id === opt.id);
        const dt2 = _.find(data.marketplace_management.notification_action_marketplace_managements.data, (i) => i.notification_action_id === opt.id);
        if (dt1) {
          opt.checked = true;
        } else {
          opt.checked = false;
        }
        if (dt2) {
          opt.template_name = dt2.template_name;
        } else {
          opt.template_name = '';
        }
        return opt;
      });
      options = options_st1;
      return _.assign({}, state, { marketplaceSelected: data, options: options });
    case INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS: {
      return _.assign({}, state, { config: _.assign({}, state.config, { fetched: true, items: action.data }) });
    }
    case INDIVIDUAL_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION: {
      return resolveSelectedNotifyAction(state, action);
    }
    case INDIVIDUAL_CONFIG_SAVE_RETAILER_AND_MARKETPLACE_IDS: {
      const { retailerId, marketplaceId } = action.data;
      return _.assign({}, state, { routingState: { retailerId, marketplaceId } });
    }
    case INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE: {
      return setActiveLanguage(state, action);
    }
    default:
      return state;
  }
};
