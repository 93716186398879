import { TextBox, InputBase, DateTimePicker, CheckBox, PhoneCode, Radio } from '@reflaunt/reactive-form';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '../../../../store/store.module';
import { RENDER_EDIT_CUSTOMER_FORM_REQUESTED, ADMIN_UPDATE_CUSTOMER_REQUESTED } from './update.actions';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import { Validators } from '@angular/forms';
import { listRouter } from '../customer.const';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  public store;
  public listRouter = listRouter;
  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    let inputs: InputBase<any>[] = [
      new TextBox({
        key: 'first_name',
        label: 'First Name *',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TextBox({
        key: 'last_name',
        label: 'Last Name *',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new DateTimePicker({
        key: 'birth',
        label: 'Birth *',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1,
        monthNavigator: true,
        yearNavigator: true,
        yearRange: '1950:2050',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new Radio({
        key: 'gender',
        label: 'Gender *',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1,
        options: [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }, { label: 'None', value: 'NULL' }],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new PhoneCode({
        key: 'phone',
        label: 'Phone number *',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TextBox({
        key: 'top_size',
        label: 'Top size',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'bottom_size',
        label: 'Bottom size',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'shoe_size',
        label: 'Shoe size',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'tax_number',
        label: 'Tax number',
        classes: ['col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new CheckBox({
        key: 'can_do_pricing',
        label: 'Can Do Pricing',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new CheckBox({
        key: 'enable_offer',
        label: 'Enable Offer',
        classes: ['col-md-6 col-12'],
        group_classes: ['col-12'],
        order: 1
      })
    ];
    this.store.dispatch({ type: RENDER_EDIT_CUSTOMER_FORM_REQUESTED, data: { id: this.activatedRoute.snapshot.params.id, inputs: inputs } });
  }

  onSubmit(form) {
    const store = AppInjector.get(Store).getInstance();
    if (form.valid) {
      const data = {
        first_name: form.value.first_name,
        last_name: form.value.last_name,
        birth: form.value.birth,
        gender: form.value.gender,
        phone_area_code: form.value.phone.code,
        phone_number: form.value.phone.value,
        top_size: form.value.top_size,
        bottom_size: form.value.bottom_size,
        shoe_size: form.value.shoe_size,
        tax_number: form.value.tax_number,
        enable_offer: form.value.enable_offer,
        can_do_pricing: form.value.can_do_pricing
      };
      store.dispatch({
        type: ADMIN_UPDATE_CUSTOMER_REQUESTED,
        data: _.assign(data, { id: store.getState().Admin.AdminCustomer.update.item.id })
      });
    }
  }
}
