import * as _ from 'lodash';
import {
  GET_DETAIL_INFO_COLLECTION_SUCCESSED,
  GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED,
  GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_SUCCESSED,
  COLLECTION_RELOAD_REQUESTED,
  GET_LOGICTIS_PARTNER_LOCATION_SUCCESSED
} from './detail.actions';
import { format } from 'date-fns';
import { COLLECTION_TYPES } from '../../../../models/Collection';
export const detail = (
  state = {
    fetched: false,
    is_enable_consignment_aggrement_update: false,
    item: { type: '', collection_expected_list_items: [] },
    items: [],
    locations: [],
    pagination: {},
    items_status: [],
    pickup_step_value: {},
    print: { status: false, data: {}, component: null }
  },
  action
) => {
  switch (action.type) {
    case GET_DETAIL_INFO_COLLECTION_SUCCESSED:
      if (action.data.pickup_date) {
        const dt = new Date(action.data.pickup_date);
        const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
        action.data.pickup_date = format(dtDateOnly, 'dd/MM/yyyy');
        action.data.raw_pickup_date = dtDateOnly;
      }
      let is_enable_consignment_aggrement_update = false;
      if (action.data.rejected_products && action.data.rejected_products.length > 0) {
        is_enable_consignment_aggrement_update = true;
      } else {
        if (action.data.rejected_products && action.data.approved_products.length > 0) {
          for (const approved_product of action.data.approved_products) {
            if (approved_product.price) {
              is_enable_consignment_aggrement_update = true;
              break;
            }
          }
        }
      }
      if (action.data.collection_expected_list_items && action.data.type === COLLECTION_TYPES.BUYBACK) {
        const product_buyback = state.items[0];
        action.data.collection_expected_list_items = action.data.collection_expected_list_items.map((e, index) => {
          if (product_buyback) {
            e = Object.assign(e, { images: product_buyback.images, sku: product_buyback.sku, id: product_buyback.id });
          }
          return e;
        });
      }
      if (action.data.collection_expected_list_items && action.data.type === COLLECTION_TYPES.TAKEBACK) {
        action.data.collection_expected_list_items = action.data.collection_expected_list_items.filter((e => !e.product_id));
      }
      if (action.data.shipments && action.data.shipments.length > 0 && action.data.shipment.data) {
        action.data.shipments = action.data.shipments.filter((i) => i.id !== action.data.shipment.data.id);
      }
      return _.assign({}, state, {
        fetched: true,
        item: action.data,
        is_enable_consignment_aggrement_update,
        create_product_params: JSON.stringify({
          collection_id: action.data.id,
          customer_email: action.data.seller ? action.data.seller.email : '',
          retailer_email: action.data.retailer ? action.data.retailer.email : '',
          retailer_slug: action.retailer_slug
        })
      });
    case GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED:
      return _.assign({}, state, {
        fetched: true,
        items: _.map(action.data, (i: any) => _.assign(i, { edit_product_params: JSON.stringify({ retailer_email: i.retailer ? i.retailer.email : '' }) })),
        pagination: action.pagination,
        items_status: _.map(action.data, (item) => {
          return {
            id: item.id,
            status_id: item.product_items.length ? item.product_items[0].status_id : 0,
            status_slug: item.product_items.length ? item.product_items[0].status.slug : null,
            product_item_id: item.product_items.length ? item.product_items[0].id : 0
          };
        })
      });
    case GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_SUCCESSED:
      return _.assign({}, state, { fetched: true, pickup_step_value: action.data });
    case COLLECTION_RELOAD_REQUESTED:
      return _.assign({}, state, { fetched: false, item: {} });
    case GET_LOGICTIS_PARTNER_LOCATION_SUCCESSED:
      return _.assign({}, state, { locations: action.data });
    default:
      return state;
  }
};
