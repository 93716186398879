import { ApiUrl } from './api-url.service';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import LengthAwarePaginator from '../models/LengthAwarePaginator';
import { AppInjector } from '../app-injector';
import { PreloaderService } from '../common/services/preloader/preloader.service';
import * as Cookies from 'js-cookie';

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export class ServiceProvider {
  public url = '';
  public model;
  public http;
  public apiUrl;
  public preloader;
  public token = Cookies.get('jwt_token_key')

  constructor() {
    this.http = AppInjector.get(HttpClient);
    this.apiUrl = AppInjector.get(ApiUrl);
    this.preloader = AppInjector.get(PreloaderService);
  }
  /**
   * Get list of all resource with pagination
   *
   * @param param Optinal
   * @param sort Optinal
   * @param filter Optinal
   * @param search Optinal
   *
   * @return Observable
   */

  get(params = {}): Observable<any> {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params, encoder: new CustomEncoder() });
    return this.http.get(this.apiUrl.getApiUrl(this.url), { params: queryParams },{ headers: { Authorization: `Bearer ${this.token}` } }).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map(result =>
        _.assign(
          {},
          {
            items: (result as any).data.map(item => new this.model(item)),
            pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError(error => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  /**
   * Get list of all resource
   *
   * @param params Optional
   *
   * @return Observable
   */
  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(`${this.url}/list`), JSON.stringify(params)).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new this.model(item))),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  /**
   * Update resource by given id
   *
   * @param Object
   *
   * @return Observable
   */
  update(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/' + id, data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => {
        return new this.model((result as any).data);
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  // Created a new endpoint for patching instead of put
  // updatePatch(id, data): Observable<any> {
  //   AppInjector.get(PreloaderService).show();
  //   return this.http.patch(this.apiUrl.getApiUrl(this.url) + '/' + id, data).pipe(
  //     tap(result => {
  //       AppInjector.get(PreloaderService).hide();
  //     }),
  //     map(result => new this.model((result as any).data)),
  //     catchError(error => {
  //       AppInjector.get(PreloaderService).hide();
  //       throw error;
  //     })
  //   );
  // }

  /**
   * Delete resource by given id
   *
   * @param id
   *
   * @return Observable
   */
  delete(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.delete(this.apiUrl.getApiUrl(this.url) + '/' + id).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  create(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url), data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  /**
   * Get Resource By Given ID
   *
   * @param ID
   *
   * @return Object
   */
  getItemById(id, param?): Observable<any> {
    param = param || {};
    let query = [];
    // tslint:disable-next-line:forin
    for (const prop in param) {
      query.push(prop + '=' + param[prop]);
    }
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/' + id + '?' + _.join(query, '&')).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => new this.model((result as any).data)),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  /**
   * Get Resource By Given ID
   *
   * @param ID
   *
   * @return Object
   */
  show(id, param?): Observable<any> {
    return this.getItemById(id, param);
  }

  sort(data): Observable<any> {
    // tslint:disable-next-line:forin
    AppInjector.get(PreloaderService).show();
    let query = [];
    for (const prop in data) {
      if (data[prop] !== null) {
        query.push(data[prop] + prop);
      }
    }

    return this.http.get(this.apiUrl.getApiUrl(this.url) + '?sort=' + _.join(query, ',')).pipe(
      tap(results => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(results =>
        _.assign(
          {},
          {
            items: (results as any).data.map(item => new this.model(item)),
            total: (results as any).meta.pagination.total
          }
        )
      ),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  filter(query): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '?constraints=' + JSON.stringify(query)).pipe(
      tap(results => {
        AppInjector.get(PreloaderService).hide();
      }),
      // map(results => _.assign({}, { items: (results as any).data.items.map(item => new new classes[this.modelName](item)), total: (results as any).data.total })),
      map(results => {
        return _.assign(
          {},
          {
            items: (results as any).data.map(item => new this.model(item)),
            total: (results as any).meta.pagination.total
          }
        );
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  search(query): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '?search=' + query).pipe(
      tap(results => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(results =>
        _.assign(
          {},
          {
            items: (results as any).data.map(item => new this.model(item)),
            total: (results as any).meta.pagination.total
          }
        )
      ),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
