import { Component, OnDestroy, OnInit } from '@angular/core';
import { Dropdown, TextBox, UploadFile } from '@reflaunt/reactive-form';
import { Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import * as _ from 'lodash';
import { CREATE_NEW_ATTRIBUTE_REQUESTED, RENDER_ATTRIBUTE_FORM_SUCCEEDED } from './create.action';
import Swal from "sweetalert2";
import { AppInjector } from '../../../app-injector';
import { Store } from '../../../store/store.module';
import { CREATE_PRODUCT_REQUESTED } from '../../admin/product/create/create.actions';
import { SIZE_TYPES } from '../attribute.consts';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent extends BaseComponent implements OnInit, OnDestroy {

  public availableInputs = {
    categories: {
      sizing_type : {
        class: Dropdown,
        default_data: {
          key: 'sizing_type',
          label: 'Sizing Type',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: [
            { label: 'Any', value: '' },
            { label: 'Clothing', value: 'CLOTHING' },
            { label: 'Shoes', value: 'SHOES' },
            { label: 'Accessories', value: 'ACCESSORIES' }
          ]
        }
      }
    },
    colors: {},
    conditions: {},
    designers: {},
    materials: {},
    sizes: {
      age_group : {
        class: Dropdown,
        default_data: {
          key: 'age_group',
          label: 'Age Group',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: [
            { label: 'Any', value: '' },
            { label: 'Adult', value: 'ADULT' },
            { label: 'Kid', value: 'KID' }
          ]
        }
      },
      type: {
        class: Dropdown,
        default_data: {
          key: 'type',
          label: 'Type',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: SIZE_TYPES
        }
      },
      gender: {
        class: Dropdown,
        default_data: {
          key: 'gender',
          label: 'Gender',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1,
          options: [
            { label: 'Any', value: '' },
            { label: 'Male', value: 'MALE' },
            { label: 'Female', value: 'FEMALE' }
          ]
        }
      },
      unit: {
        class: TextBox,
        default_data: {
          key: 'unit',
          label: 'Unit',
          classes: ['col-6'],
          group_classes: ['col-12'],
          order: 1
        }
      }
    }
  };

  public commonInputs = {
    name: {
      class: TextBox,
      default_data: {
        key: 'name',
        label: 'Name *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-12'],
        group_classes: ['col-12'],
        order: 1
      }
    },
    /*status : {
      class: Dropdown,
      default_data: {
        key: 'status',
        label: 'Status *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 99,
        options: [
          { label: 'Active', value: 1 },
          { label: 'Inactive', value: 0 },
        ]
      }
    }*/
  };
  public type;
  public combinedInputs;

  constructor(private activatedRoute: ActivatedRoute, private route: Router) {
    super();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.generateInput();
      }
    });
  }

  generateInput() {
    let test;
    const renderer = this.activatedRoute.parent.params.subscribe(params => {
      this.type = params.type;
      if (this.type !== 'models') {
        this.combinedInputs = [
          ...Object.keys(this.commonInputs).map(s => {
            const input = this.commonInputs[s];
            if (input) {
              return new input.class({
                ...input.default_data
              });
            }
            return null;
          }).filter(s => !!s),
          ...Object.keys(this.availableInputs[this.type]).map(s => {
            const input = this.availableInputs[this.type][s];
            if (input) {
              return new input.class({
                ...input.default_data
              });
            }
            return null;
          }).filter(s => !!s)
        ];
      } else {
        this.combinedInputs = [
          ...Object.keys(this.commonInputs).map(s => {
            const input = this.commonInputs[s];
            if (input) {
              return new input.class({
                ...input.default_data
              });
            }
            return null;
          })
        ];
      }
      this.store.dispatch({ type: RENDER_ATTRIBUTE_FORM_SUCCEEDED, data: { inputs: this.combinedInputs } });
    });
    renderer.unsubscribe();
  }

  ngOnInit() {
  }

  mapStateToProps(state) {
    return {
      payload: state.Attribute.Create
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onSubmit = form => {
    const formCopy = {...form.value};
    const type = this.type;
    if (!form.valid) {
      return;
    }
    if (confirm(`Do you want to create this ${type} ?`)) {
      let data = {
        name: formCopy.name,
        // status: formCopy.status.value,
        sizing_type: formCopy.sizing_type ? formCopy.sizing_type.value : '',
        age_group: formCopy.age_group ? formCopy.age_group.value : '',
        type: formCopy.type ? formCopy.type.value : '',
        gender: formCopy.gender ? formCopy.gender.value : '',
        unit: formCopy.unit
      };
      const store = AppInjector.get(Store).getInstance();
      store.dispatch({ type: CREATE_NEW_ATTRIBUTE_REQUESTED, data: { attributes: data, type: type } });
    }
  }

}
