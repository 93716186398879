import Model from './Model';
import * as _ from 'lodash';
import Location from './Location';
import ProductItemLocation from './ProductItemLocation';
import Status from './Status';

class ProductItem extends Model {
  constructor(options) {
    super();
    (this as any).location = (d) => {
      return _.map(d.data, (i) => new Location(i));
    };
    (this as any).product_item_location = (d) => {
      return _.map(d.data, (i) => new ProductItemLocation(i));
    };
    (this as any).status = (d) => new Status(d.data);
    this.bind(options);
  }
}

export default ProductItem;
