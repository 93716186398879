import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import Transaction from '../../../models/Transaction';

@Injectable()
export class TransactionService extends ServiceProvider {
  public url = '/api/v1/admin/transaction-history';
  public model = Transaction;
}


