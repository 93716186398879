import Model from './Model';

class LengthAwarePaginator extends Model {
  getTotalPages(): any {
    return 0;
  }
  getTotal(): any {
    return 0;
  }
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default LengthAwarePaginator;
