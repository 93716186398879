import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../../app-injector';
import { ApiService } from '../../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../../store/action';
import {
  FETCH_ALL_PRODUCT_IN_COLLECTION_REQUESTED,
  FETCH_ALL_PRODUCT_IN_COLLECTION_SUCCEEDED,
  GET_ALL_RETAILER_IN_COLLECTION_PRODUCT_REQUESTED,
  GET_ALL_RETAILER_IN_COLLECTION_PRODUCT_SUCCESSED
} from './list.action';
import * as _ from 'lodash';

function* fetchAllProductInCollection() {
  yield takeLatest(FETCH_ALL_PRODUCT_IN_COLLECTION_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService)
      const user = yield api.admin.user.profile({}).toPromise()

      if (user && user.logistic_partner) {
        const partner_data = user.logistic_partner.data

        if (partner_data && partner_data.length) {
          action.data.partner_retailer_id = partner_data[0].parent_retailer_id
        }
  
      } 

      let results = yield api.admin.product.get(action.data).toPromise();
      yield put({ type: FETCH_ALL_PRODUCT_IN_COLLECTION_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getAllRetailerCollectionPage() {
  yield takeLatest(GET_ALL_RETAILER_IN_COLLECTION_PRODUCT_REQUESTED, function* (action: any) {
    try {
      let data: any = {};

      if (!_.isNil(action.data)) {
        data.constraints = JSON.stringify(action.data);
      }
      
      const user = yield AppInjector.get(ApiService).admin.user.profile({}).toPromise()

      if(user.logistic_partner){
        const parent_retailer_data = user.logistic_partner.data
        if(parent_retailer_data.length > 0){
          if(parent_retailer_data[0].parent_retailer_id){
            data.parent_retailer_id = user.logistic_partner.data[0].parent_retailer_id
          }   
        } 
      } 
      
      data.includes = 'retailer';
      let results = yield AppInjector.get(ApiService).user.list(data).toPromise();
      results = results.sort((a, b) =>
        _.lowerCase(a.retailer && a.retailer.company ? a.retailer.company : a.email) < _.lowerCase(b.retailer && b.retailer.company ? b.retailer.company : b.email) ? -1 : 1
      );
      yield put({ type: GET_ALL_RETAILER_IN_COLLECTION_PRODUCT_SUCCESSED, data: results });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

export default [fetchAllProductInCollection, getAllRetailerCollectionPage];
