import { ConfigMarketplaceComponent } from './config-marketplace/config-marketplace.component';
import { PipesModule } from './../../common/pipes/pipes.module';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { IntegrationRoutingModule } from './integration-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CreateComponent } from './create/create.component';
import { AddDatasetComponent } from './add-dataset/add-dataset.component';
import { DirectivesModule } from '../../common/directives/directives.module';

@NgModule({
  imports: [CommonModule, IntegrationRoutingModule, FormsModule, PipesModule, DirectivesModule],
  declarations: [ConfigMarketplaceComponent, ListComponent, EditComponent, CreateComponent, AddDatasetComponent]
})
export class IntegrationModule {}
