import { put, takeLatest, select, takeEvery, call, delay } from 'redux-saga/effects';
import {
  FETCH_DATA_ADMIN_ORDER_REQUESTED,
  FETCH_DATA_ADMIN_ORDER_SUCCESSED,
  CANCEL_ORDER_PRODUCT_REQUESTED,
  UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED,
  UPDATE_STATUS_ORDER_PRODUCT_REQUESTED,
  UPDATE_ORDER_SHIPMENT_ADMIN_REQUESTED,
  UPDATE_ORDER_SHIPMENT_ADMIN_SUCCESSED,
  CANCEL_ORDER_PRODUCT_SUCCESSED
} from './detail.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ORDER_PRODUCT_STATUS_SHIPPED_CONFIRMED, ORDER_PRODUCT_STATUS_SOLD_CONFIRMED, ORDER_ITEM_STATUS_SOLD_CONFIRMED } from '../../../../models/OrderProduct';
import { Router } from '@angular/router';
import { fetchAllLocations } from '../../location/location.saga';
import Shipment from '../../../../models/Shipment';
import { flatMapDeep, uniq } from 'lodash';

const reduceArray = (array) => {
  const new_array = [];
  array.reduce(function (res, value) {
    if (!res[value.id]) {
      new_array.push(value);
      res[value.id] = true;
    }
    return res;
  }, {});
  return new_array;
};

function* watchCreateOrderRequest() {
  yield takeLatest(FETCH_DATA_ADMIN_ORDER_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).order.show(action.payload.id).toPromise();
      let transaction = [];
      let shipment = [];
      let order_product_shipment = [];
      for (const e of result.order_products) {
        console.log(e)
        const transaction_item = yield AppInjector.get(ApiService).admin.transaction.list({ order_product_id: e.id }).toPromise();
        const shipment_item = yield AppInjector.get(ApiService).admin.shipment.list({ product_id: e.product.id }).toPromise();
        if (transaction_item.length > 0) {
          transaction = transaction.concat(transaction_item);
        }
        if (shipment_item.length > 0) {
          shipment.concat(shipment_item);
        }
        order_product_shipment = order_product_shipment.concat(e.shipment_manys.data);
      }
      const status = yield AppInjector.get(ApiService).status.get().toPromise();
      const locations = yield call(fetchAllLocations);

      const locationIds = flatMapDeep(
        result.order_products.map((orderProduct) => {
          if (orderProduct.product_item) {
            return orderProduct.product_item.product_item_location.map((itemLocation) => itemLocation.location_id);
          }
        }),
        uniq
      );

      let itemLocations;
      let productServiceTypes;
      if (locationIds.length > 0) {
        itemLocations = yield call(fetchAllItemLocations, { in_ids: locationIds.join(',') });
      }
      const serviceTypeIds = result.order_products.map((orderProduct) => orderProduct.product && orderProduct.product.service_type_id);
      if (serviceTypeIds.filter((i) => _.isNil(i)).length === 0) {
        productServiceTypes = yield call(fetchProductServiceTypes, { in_ids: serviceTypeIds.join(',') });
      }
      yield put({
        type: FETCH_DATA_ADMIN_ORDER_SUCCESSED,
        data: result,
        transaction: transaction,
        shipment: shipment,
        status: status.items,
        locations,
        itemLocations,
        productServiceTypes,
        order_product_shipment: reduceArray(_.map(order_product_shipment, (item) => new Shipment(item)))
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* cancelOrderProductRequested() {
  yield takeLatest(CANCEL_ORDER_PRODUCT_REQUESTED, function* (action: any) {
    try {
      action.data.cancelled_at = new Date();
      const result = yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
      // AppInjector.get(Router).navigate([]);
      yield put({ type: CANCEL_ORDER_PRODUCT_SUCCESSED, data: { order_id: result.order_id, order_product_id: action.id } });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* cancelShipment() {
  yield takeLatest(CANCEL_ORDER_PRODUCT_SUCCESSED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.shipment.cancelShipment(action.data).toPromise();
      AppInjector.get(Router).navigate([]);
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* updateStatusShippingByOrderProductIdRequested() {
  yield takeLatest(UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.shipment.updateStatusShippingByOrderProductId(action.order_product_id, action.data).toPromise();
      yield AppInjector.get(ApiService).admin.fulfilment.update(action.order_product_id, { status: ORDER_PRODUCT_STATUS_SHIPPED_CONFIRMED }).toPromise();
      AppInjector.get(Router).navigate([]);
      AppInjector.get(NotificationService).show('success', 'Update status shipment success', 2000);
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* updateOrderProductRequested() {
  yield takeLatest(UPDATE_STATUS_ORDER_PRODUCT_REQUESTED, function* (action: any) {
    try {
      if (action.data.status === ORDER_ITEM_STATUS_SOLD_CONFIRMED) {
        const transactions = yield select((state) => (state as any).Admin.Order.detail.transaction);
        const item = _.find(transactions, (e) => e.order_product_id === action.id);
        if (!item) {
          yield AppInjector.get(NotificationService).show('warning', 'Transaction is undefined', 2000);
        } else {
          const marketplace_id = item.marketplace_id;
          action.data.marketplace_id = marketplace_id;
          yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
          yield AppInjector.get(ApiService).admin.product.setProductToSoldConfirmProductItem(action.data.product_id, action.data).toPromise();
        }
      } else {
        yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
      }
      AppInjector.get(Router).navigate([]);
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* watchUpdateShipmentStatusRequested() {
  yield takeEvery(UPDATE_ORDER_SHIPMENT_ADMIN_REQUESTED, function* (action: any) {
    try {
      const resuilt = yield AppInjector.get(ApiService).admin.shipment.update(action.data.id, action.data).toPromise();
      AppInjector.get(Router).navigate([]);
      AppInjector.get(NotificationService).show('success', 'The shipment was delivered', 3000);
      yield put({ type: UPDATE_ORDER_SHIPMENT_ADMIN_SUCCESSED, data: resuilt });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* fetchAllItemLocations(params?) {
  try {
    const api = AppInjector.get(ApiService);
    const result = yield api.admin.location.get({ ...params, per_page: 10000 }).toPromise();
    return result.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchProductServiceTypes(params?) {
  try {
    const api = AppInjector.get(ApiService);
    const result = yield api.admin.serviceType.get({ ...params, per_page: 10000 }).toPromise();
    return result.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [
  watchCreateOrderRequest,
  watchUpdateShipmentStatusRequested,
  cancelOrderProductRequested,
  updateStatusShippingByOrderProductIdRequested,
  updateOrderProductRequested,
  cancelShipment
];
