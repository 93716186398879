export const FETCH_RETAILER_DESIGNERS_SUCCESS = 'FETCH_RETAILER_DESIGNERS_SUCCESS';
export const ADMIN_ATTRIBUTES_RETAILER_DESIGNER_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_RETAILER_DESIGNER_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_RETAILER_DESIGNER_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_RETAILER_DESIGNER_UPDATE_DICTIONARY_SUCCESS';

export const ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_DESIGNER_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_DESIGNER_REQUESTED';
export const TOGGLE_DESIGNER_RETAILER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_DESIGNER_RETAILER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_DESIGNER_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_DESIGNER_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED';

export const DELETE_RETAILER_DESIGNER_MATCHING_REQUESTED = 'DELETE_RETAILER_DESIGNER_MATCHING_REQUESTED';

export const ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_DESIGNER_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_DESIGNER_REQUESTED';
