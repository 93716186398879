import Model from './Model';
import Markup from './Markup';

export const GROUP_GROUP_TYPE = 'group';
export const NORMAL_GROUP_TYPE = 'normal';

class Dataset extends Model {
  markup: any;
  children_datasets: any;
  constructor(options) {
    super();
    this.markup = (d) => new Markup(d.data);
    this.children_datasets = (d) => d.data.map((item) => new Dataset(item));
    this.bind(options);
  }

  isGroup() {
    const item = this as any;
    return item.group_type === GROUP_GROUP_TYPE;
  }
}

export default Dataset;
