import { ProxyPage } from '../components/proxy-page/proxy-page.reducer';
import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { Auth } from '../components/auth/auth.reducer';
import { environment } from '../../environments/environment';
import { Profile } from '../components/profile/profile.reducer';
import { Integration } from './../components/integration/integration.reducer';
import { Retailer } from '../components/retailer/retailer.reducer';
import { Admin } from '../components/admin/admin.reducer';
import { Attribute } from '../components/attribute/attribute.reducer';
import { MeasurementUnit } from '../components/measurement-unit/measurement-unit.reducer';
import { Customer } from '../components/customer/customer.reducer';
import { Blockchain } from '../components/blockchain/blockchain.reducer';
import { LandingPages } from '../components/landing-page/landing-page.reducer';
import { Credential } from '../components/credential/credential.reducer';
import { FETCH_CONSTANTS_SUCCEEDED } from '../components/main.action';
import { Print } from '../components/print/print.reducer';


const retailer_menus_able = ['Dashboard', 'Resale activity', '2nd Hand Sales'];

const RootReducer = (state = { config: environment, isShowBtnSettings: false, constants: { fetched: false, items: {} } }, action) => {
  switch (action.type) {
    case FETCH_CONSTANTS_SUCCEEDED:
      return _.assign({}, state, { constants: { fetched: true, items: action.data } });
    case 'INIT_APP_MENU':
      const levelShow = action.levelMenu ? action.levelMenu : 1;
      const target = _.cloneDeep(action.data);
      const MenuItems = _.map(target, (item) => {
        item.main = _.map(item.main, (menu) => {
          if (menu.level === levelShow) {
            if (_.isArray(menu.permissions) && menu.permissions.length > 0) {
              menu.show = action.user.hasOneOf(menu.permissions);
            } else {
              menu.show = true;
            }
            if (menu.roles && _.isArray(menu.roles) && menu.roles.length > 0) {
              menu.show = false;
              menu.roles.forEach((role) => {
                if (action.user.isRole(role)) {
                  menu.show = true;
                }
              });
            }
            if (menu.roles && _.isArray(menu.roles) && menu.roles.length > 0) {
              menu.show = action.user.roles.some((role) => {
                if (_.includes(menu.roles, role.slug)) {
                  return true;
                }
                return false;
              });
            }
          }
          if (action.levelMenu && menu.level !== levelShow) {
            menu.show = false;
          }
          if (_.isArray(menu.states)) {
            menu.states = menu.states.map((i) => {
              if (i === 'CURRENT_USER_ID') {
                return action.user.id;
              }
              if (i === 'CURRENT_USER_EMAIL') {
                return action.user.email;
              }
              return i;
            });
          }
          if (retailer_menus_able.includes(menu.name) && action.user.retailer) {
            if (menu.name === 'Dashboard' && !action.user.retailer.quicksight_dashboard_id) {
              menu.show = false;
            }
            if (menu.name === 'Resale activity' && !action.user.retailer.quicksight_resale_activity_id) {
              menu.show = false;
            }
            if (menu.name === '2nd Hand Sales' && !action.user.retailer.quicksight_2nd_hand_sales_id) {
              menu.show = false;
            }
          }
          if (action.user.email === 'netaporter@reflaunt.com') {
            if (menu.name === 'Product list' || menu.name === 'Attribute') {
              menu.show = false;
            }
          }
          return menu;
        });
        item.show = !_.isUndefined(_.find(item.main, (i) => i.show));
        if (action.user.email === 'netaporter@reflaunt.com') {
          if (item.label === 'Products') {
            item.show = false;
          }
        }
        return item;
      });
      return _.assign({}, state, { MenuItems: MenuItems, isShowBtnSettings: action.isShowBtnSettings });
    default:
      return state;
  }
};

export default combineReducers({ProxyPage,
  Admin,
  Attribute,
  MeasurementUnit,
  Integration,
  RootReducer,
  Auth,
  Profile,
  Print,
  Retailer,
  Customer,
  Blockchain,
  LandingPages,
  Credential
});
