export const ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_REQUESTED = 'ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_REQUESTED';
export const ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_SUCCESSED = 'ADMIN_ATTRIBUTES_FETCH_DESIGNER_ATTRIBUTE_SUCCESSED';
export const DELETE_DESIGNER_ATTRIBUTE_REQUESTED = 'DELETE_DESIGNER_ATTRIBUTE_REQUESTED';
export const DELETE_DESIGNER_ATTRIBUTE_SUCCESSED = 'DELETE_DESIGNER_ATTRIBUTE_SUCCESSED';
export const FETCH_DESIGNER_DICTIONARY_REQUESTED = 'FETCH_DESIGNER_DICTIONARY_REQUESTED';
export const FETCH_DESIGNER_DICTIONARY_SUCCESSED = 'FETCH_DESIGNER_DICTIONARY_SUCCESSED';
export const CREATE_DESIGNER_DICTIONARY_SUCCESSED = 'CREATE_DESIGNER_DICTIONARY_SUCCESSED';
export const RESET_DESIGNER_DICTIONARY = 'RESET_DESIGNER_DICTIONARY';
export const EDIT_DESIGNER_DICTIONARY_REQUESTED = 'EDIT_DESIGNER_DICTIONARY_REQUESTED';
export const EDIT_DESIGNER_DICTIONARY_SUCCEEDED = 'EDIT_DESIGNER_DICTIONARY_SUCCEEDED';
export const ADMIN_ATTRIBUTES_DESIGNER_FILTER_SELECTED = 'ADMIN_ATTRIBUTES_DESIGNER_FILTER_SELECTED';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_REQUEST = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_REQUEST';
export const ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_SUCCESS = 'ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_DESIGNERS_SUCCESS';
export const ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_DESIGNER_UPDATE_DICTIONARY_SUCCESS';
export const ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_REQUESTED = 'ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_REQUESTED';
export const ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_SUCCESS = 'ADMIN_ATTRIBUTES_DESIGNER_CREATE_DESIGNER_SUCCESS';
export const ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_REQUESTED = 'ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_REQUESTED';
export const ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_SUCCESS = 'ADMIN_ATTRIBUTES_DESIGNER_EDIT_DESIGNER_SUCCESS';
export const ADMIN_ATTRIBUTES_DESIGNER_SYNCHRONISE_ATTRIBUTES_REQUEST = 'ADMIN_ATTRIBUTES_DESIGNER_SYNCHRONISE_ATTRIBUTES_REQUEST';
export const TOGGLE_DESIGNER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_DESIGNER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_DESIGNER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_DESIGNER_DICTIONARY_VALIDATION_SUCCEEDED';
