import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { ServiceProvider } from '../../service.provider';
import HistoryPush from '../../../models/HistoryPush';

@Injectable()
export class HistoryPushService extends ServiceProvider {
  public url = '/api/v1/admin/history_pushes';
  public model = HistoryPush;
}
