import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';
import { GET_DETAIL_RETAILER_REQUESTED } from './detail.actions';
import { editRouter, listRouter } from '../retailer.const';
import Swal from 'sweetalert2';
import { EDIT_RETAILER_REQUESTED } from '../edit/edit.actions';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  public store;
  public editRouter = editRouter;
  public listRouter = listRouter;
  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    this.activatedRoute = activatedRoute;
    this.store = store.getInstance();
  }

  ngOnInit() {
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({
      type: GET_DETAIL_RETAILER_REQUESTED,
      data: this.activatedRoute.snapshot.params.id
    });
  }

  changeDefaultPercentage(id, defaultPercentage) {
    (Swal as any)
      .fire({
        input: 'text',
        title: '<strong>Enter your percentage:</strong>',
        type: 'info',
        inputValue: defaultPercentage,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        inputValidator: function(value) {
          if (value === '' || value === null) {
            return 'You need to enter something!';
          } else {
            const format = /[+-]?([0-9]*[.])?[0-9]+/;

            if (!format.test(value)) {
              return 'You need to enter number!';
            }
          }
        }
      })
      .then(function(result) {
        if (result.value && +result.value !== defaultPercentage) {
          const store = AppInjector.get(Store).getInstance();
          store.dispatch({
            type: EDIT_RETAILER_REQUESTED,
            data: _.assign({ default_percentage: +result.value }, { id: id })
          });
          store.getState().Admin.AdminRetailer.detail.item.retailer.default_percentage = +result.value;
        }
      });
  }

  onSubmit(form) {}
}
