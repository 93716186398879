import { put, takeEvery } from 'redux-saga/effects';
import { RENDER_SHIPMENT_EDIT_FORM, FILL_FORM_SHIPMENT, EDIT_SHIPMENT_REQUESTED, EDIT_SHIPMENT_SUCCESSED } from './edit.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import * as _ from 'lodash';
function* watchFetchDataRequest() {
  yield takeEvery(RENDER_SHIPMENT_EDIT_FORM, function* (action: any) {
    try {
      let couriers = yield AppInjector.get(ApiService).admin.shippingMethod.list().toPromise();
      let locations = yield AppInjector.get(ApiService).admin.location.get({ per_page: 10000 }).toPromise();
      locations = locations.items;
      let currency = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      currency = currency.items;
      const shipping_fee_currency = _.find(action.data.inputs, (e) => e.key === 'currency_id');
      const customs_fee_currency = _.find(action.data.inputs, (e) => e.key === 'customs_fee_currency_id');
      const other_taxes_currency = _.find(action.data.inputs, (e) => e.key === 'other_taxes_currency_id');
      shipping_fee_currency.options = customs_fee_currency.options = other_taxes_currency.options = _.map(currency, (e) => _.assign({ value: e.id, label: e.code }));
      couriers = _.map(couriers, (i) => {
        i.label = i.name;
        i.value = i.code;
        return i;
      });
      const courier_input = _.find(action.data.inputs, (e) => e.key === 'courier_name');
      courier_input.options = couriers;

      locations = _.map(locations, (i) => {
        i.label = i.name;
        i.value = i.id;
        return i;
      });
      const forward_location = _.find(action.data.inputs, (e) => e.key === 'forward_location_id');
      forward_location.options = locations;

      const result = yield AppInjector.get(ApiService).admin.shipment.show(action.data.id).toPromise();

      if (result.shipped_date) {
        result.shipped_date = new Date(result.shipped_date);
      }
      if (result.reached_customer_date) {
        result.reached_customer_date = new Date(result.reached_customer_date);
      }
      if (result.delivery_date) {
        result.delivery_date = new Date(result.delivery_date);
      }
      if (result.customer_paid_date) {
        result.customer_paid_date = new Date(result.customer_paid_date);
      }
      if (result.ready_to_ship_date) {
        result.ready_to_ship_date = new Date(result.ready_to_ship_date);
      }
      yield put({ type: FILL_FORM_SHIPMENT, data: result });
    } catch (e) {
      yield put({ type: 'API_CALL_ERROR', error: e });
    }
  });
}

function* editShipmentRequested() {
  yield takeEvery(EDIT_SHIPMENT_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.shipment.update(action.data.id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Update shipment success', 3000);
      yield put({ type: EDIT_SHIPMENT_SUCCESSED });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchDataRequest, editShipmentRequested];
