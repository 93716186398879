import * as _ from 'lodash';
import {
  RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
  RETAILER_FILL_PRODUCT_DETAIL_FORM,
  RETAILER_UPDATE_PRODUCT_SUCCEEDED,
  RETAILER_GET_PRODUCT_SUCCEEDED,
  RETAILER_DELETE_PRODUCT_SUCCEEDED,
  RETAILER_RENDER_EDIT_PRODUCT_FORM_REQUESTED
} from './edit.actions';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case RETAILER_FILL_PRODUCT_DETAIL_FORM:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
      }
      if (state.key === 'images') {
        state.value = _.map(state.value, i => {
          i = i.split(' ').join('%20');
          return i;
        });
      }
      return state;
    default:
      return state;
  }
};

export const edit = (state: any = { updated: false }, action) => {
  switch (action.type) {
    case RETAILER_UPDATE_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { updated: true });
    case RETAILER_FILL_PRODUCT_DETAIL_FORM:
      return _.assign({}, state, { inputs: _.map(state.inputs, item => Input(item, action)) });
    case RETAILER_GET_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });
    case RETAILER_DELETE_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { deleted: true });
    case RETAILER_RENDER_EDIT_PRODUCT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, input => {
          if (input.key === action.input) {
            input.options = action.data;
            // const selected = _.find(input.options, item => item.selected);
            // if (!_.isUndefined(selected)) {
            //   input.value = selected;
            // }
          }
          return input;
        })
      });
    default:
      return state;
  }
};
