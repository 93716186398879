import { put, takeEvery, select } from 'redux-saga/effects';
import { FETCH_ALL_USER_ADDRESS_REQUESTED, FETCH_ALL_USER_ADDRESS_SUCCESSED, DELETE_USER_ADDRESS_REQUESTED } from './list.actions';
import { AppInjector } from '../../../../../app-injector';
import { ApiService } from '../../../../../api/api.service';
import { FETCH_USER_DETAIL_REQUESTED } from '../../edit/edit.actions';

function* watchFetchUserAddressesRequest() {
  yield takeEvery(FETCH_ALL_USER_ADDRESS_REQUESTED, function*(action: any) {
    try {
      yield put({
        type: FETCH_USER_DETAIL_REQUESTED,
        data: action.data.id
      });
      const result = yield AppInjector.get(ApiService)
        .admin.user.getAddressesOfUser(action.data.id, { includes: 'country'})
        .toPromise();
      yield put({ type: FETCH_ALL_USER_ADDRESS_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: 'API_CALL_ERROR', error: e });
    }
  });
}

function* watchDeleteUserAddressesRequest() {
  yield takeEvery(DELETE_USER_ADDRESS_REQUESTED, function*(action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .admin.address.delete(action.data.id)
        .toPromise();

      const idUser = yield select(state => (state as any).Admin.Users.editUser.item.id);
      yield put({
        type: FETCH_ALL_USER_ADDRESS_REQUESTED,
        data: { id: idUser }
      });
    } catch (e) {
      yield put({ type: 'API_CALL_ERROR', error: e });
    }
  });
}

export default [watchFetchUserAddressesRequest, watchDeleteUserAddressesRequest];
