import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from './pagination/pagination.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { DataTableComponent } from './data-table/data-table.component';
import { RangeComponent } from './range/range.component';
import { NotifyComponent } from './notify/notify.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { LengthAwarePaginatorComponent } from './length-aware-paginator/length-aware-paginator.component';
import { SortByFieldComponent } from './sort-by-field/sort-by-field.component';
import { PerPageComponent } from './per-page/per-page.component';
import { MultiPurposeSearchComponent } from './multi-purpose-search/multi-purpose-search.component';
import { OptionsTreeComponent } from './options-tree/options-tree.component';
import { InputMaskDirective } from './input-mask/input-mask.directive';
import { CountryFieldComponent } from './country-field/country-field.component';
import { InputSwitchDirective } from './input-switch/input-switch.directive';
import { NgxBarcodeModule } from 'ngx-barcode';
import { InputCheckboxDirective } from './input-checkbox/input-checkbox.directive';
import { BarcodeScannerDetectorDirective } from './barcode-scanner-detector/barcode-scanner-detector.directive';
import { BlockDirective } from './block/block.directive';
import { AutoresizeDirective } from './autoresize/autoresize.directive';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { CustomSelectionComponent } from './custom-selection/custom-selection.component';
import { MutipleCheckboxComponent } from './mutiple-checkbox/mutiple-checkbox.component';
import { MaskInputComponent } from './mask-input/mask-input.component';
import { TextMaskModule } from 'angular2-text-mask';
import { UploadFileDirective } from './upload-file/upload-file.directive';
import { CustomSwitchInputComponent } from './custom-switch-input/custom-switch-input.component';
import { ChannelDropdownComponent } from './channel-dropdown/channel-dropdown.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SmartButtonRenderConfigComponent } from './smart-button-render-config/smart-button-render-config.component';
import { ShippingMethodRulesComponent } from './shipping-method-rules/shipping-method-rules.component';
import { NgSelectOnOpenDirective } from './custom-ng-select/NgSelectOnOpenDirective';
import { CustomRadioComponent } from './custom-radio/custom-radio.component';
import { QuicksightDashboardComponent } from './quicksight-dashboard/quicksight-dashboard.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, PipesModule, NgxBarcodeModule, TextMaskModule, ClickOutsideModule, SweetAlert2Module],
  exports: [
    PaginationComponent,
    LoaderComponent,
    SearchInputComponent,
    DataTableComponent,
    CustomRadioComponent,
    RangeComponent,
    NotifyComponent,
    SearchFormComponent,
    LengthAwarePaginatorComponent,
    SortByFieldComponent,
    PerPageComponent,
    MultiPurposeSearchComponent,
    OptionsTreeComponent,
    InputMaskDirective,
    CountryFieldComponent,
    InputSwitchDirective,
    InputCheckboxDirective,
    BarcodeScannerDetectorDirective,
    BlockDirective,
    AutoresizeDirective,
    PhoneInputComponent,
    CustomSelectionComponent,
    MutipleCheckboxComponent,
    MaskInputComponent,
    UploadFileDirective,
    CustomSwitchInputComponent,
    ChannelDropdownComponent,
    SmartButtonRenderConfigComponent,
    ShippingMethodRulesComponent,
    NgSelectOnOpenDirective,
    QuicksightDashboardComponent
  ],
  declarations: [
    PaginationComponent,
    LoaderComponent,
    SearchInputComponent,
    DataTableComponent,
    RangeComponent,
    NotifyComponent,
    SearchFormComponent,
    LengthAwarePaginatorComponent,
    SortByFieldComponent,
    PerPageComponent,
    MultiPurposeSearchComponent,
    OptionsTreeComponent,
    InputMaskDirective,
    CountryFieldComponent,
    InputSwitchDirective,
    CustomRadioComponent,
    InputCheckboxDirective,
    BarcodeScannerDetectorDirective,
    BlockDirective,
    AutoresizeDirective,
    PhoneInputComponent,
    CustomSelectionComponent,
    MutipleCheckboxComponent,
    MaskInputComponent,
    UploadFileDirective,
    CustomSwitchInputComponent,
    ChannelDropdownComponent,
    SmartButtonRenderConfigComponent,
    ShippingMethodRulesComponent,
    NgSelectOnOpenDirective,
    QuicksightDashboardComponent
  ]
})
export class DirectivesModule {}

// All components and directives/modules are imported here;
