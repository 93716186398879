import {
  ADMIN_INTEGRATION_GET_ALL_RETAILERS_REQUEST,
  ADMIN_INTEGRATION_GET_ALL_RETAILERS_SUCCESS,
  ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_REQUEST,
  ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_SUCCESS,
  ADMIN_INTEGRATION_RETAILER_SYNC_MARKETPLACE_REQUEST
} from './list.actions';
import { takeLatest, put } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchAdminIntegrationGetAllRetailersRequest() {
  yield takeLatest(ADMIN_INTEGRATION_GET_ALL_RETAILERS_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.user.get(action.data).toPromise();
      yield put({ type: ADMIN_INTEGRATION_GET_ALL_RETAILERS_SUCCESS, items: result.items, pagination: result.pagination });
      yield put({ type: ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_REQUEST, data: action.marketplace_params });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminIntegrationGetAllMarketplacesRequest() {
  yield takeLatest(ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.user.get({ ...action.data, per_page: 10000 }).toPromise();
      yield put({ type: ADMIN_INTEGRATION_GET_ALL_MARKETPLACES_SUCCESS, items: result.items });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAdminIntegrationSyncMarketplaceRequest() {
  yield takeLatest(ADMIN_INTEGRATION_RETAILER_SYNC_MARKETPLACE_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notify = AppInjector.get(NotificationService);
    try {
      const result = yield api.admin.integration.sync(action.id, action.data).toPromise();
      notify.show('success', 'Integrate Successful', 2000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchAdminIntegrationGetAllRetailersRequest, watchAdminIntegrationGetAllMarketplacesRequest, watchAdminIntegrationSyncMarketplaceRequest];
