import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Currency from '../../models/Currency';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CurrencyService extends ServiceProvider {
  public url = '/api/v1/currencies';
  public model = Currency;

  getConvertedAmount(curr1, curr2, amount): Observable<any> {
    const params = {
      amount,
      from_currency: curr1,
      to_currency: curr2
    };
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/convert`, { params: queryParams }).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getConvertedReverseAmount(curr1, curr2, amount): Observable<any> {
    const params = {
      amount,
      from_currency: curr1,
      to_currency: curr2
    };
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/convert-reverse`, { params: queryParams }).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
