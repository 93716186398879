import { BaseComponent } from '../../../base.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { FormBuilder, Validators } from '@angular/forms';
import { uniq } from 'lodash';
import { editRouter, listRouter } from '../dataset.const';
import { filter } from 'rxjs/operators';
import {
  EDIT_GROUP_DATASET_DISABLE_ADD_DATASET_INPUT,
  EDIT_GROUP_DATASET_ENABLE_ADD_DATASET_INPUT,
  EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_REQUEST,
  EDIT_GROUP_DATASET_GET_DATASET_DETAIL_REQUEST,
  EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_REQUEST,
  EDIT_GROUP_DATASET_ADD_DATASET_TO_GROUP,
  EDIT_GROUP_DATASET_REMOVE_DATASET_TO_GROUP
} from './edit-group.actions';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.Dataset.editGroup';
  public datasetId;
  public listRouter = listRouter;
  public editRouter = editRouter;

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    super();
    const { dispatchGetListNormalDatasetRequest, dispatchGetDatasetDetailRequest } = this as any;
    this.datasetId = Number(this.activatedRoute.snapshot.params.id);
    this.navigationSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      dispatchGetDatasetDetailRequest(this.datasetId, this.fb);
      dispatchGetListNormalDatasetRequest();
    });
  }

  get title() {
    return this.payload.editGroupForm.get('title');
  }

  get user() {
    return this.store.getState().Auth.login.profile;
  }

  ngOnInit() {
    this.init();
  }

  onSubmit() {
    const { dispatchUpdateDatasetGroupRequest } = this as any;
    const form = this.payload.editGroupForm;
    form.markAllAsTouched();
    if (form.valid) {
      const payload = {
        title: form.value.title,
        user_id: this.user.id,
        is_active: form.value.is_active,
        children_ids: this.payload.children.items.map((child) => child.id)
      };
      dispatchUpdateDatasetGroupRequest(this.datasetId, payload);
    }
  }

  delete(id) {
    const { dispatchRemoveDatasetToGroupRequest } = this as any;
    dispatchRemoveDatasetToGroupRequest(id);
  }

  onEnableAddDatasetInput() {
    const { dispatchEnableAddDatasetInput } = this as any;
    dispatchEnableAddDatasetInput();
  }

  onDisableAddDatasetInput() {
    const { dispatchDisableAddDatasetInput } = this as any;
    dispatchDisableAddDatasetInput();
  }

  onAddDataset() {
    const form = Object.assign({}, this.payload.addDatasetForm);
    this.payload.addDatasetForm.reset();
    const { dispatchAddDatasetToGroupRequest } = this as any;
    dispatchAddDatasetToGroupRequest(form.value);
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Dataset.editGroup
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch,
      dispatchEnableAddDatasetInput: () => {
        dispatch({ type: EDIT_GROUP_DATASET_ENABLE_ADD_DATASET_INPUT });
      },
      dispatchDisableAddDatasetInput: () => {
        dispatch({ type: EDIT_GROUP_DATASET_DISABLE_ADD_DATASET_INPUT });
      },
      dispatchGetListNormalDatasetRequest: () => {
        dispatch({ type: EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_REQUEST });
      },
      dispatchGetDatasetDetailRequest: (datasetId, formGroup) => {
        dispatch({ type: EDIT_GROUP_DATASET_GET_DATASET_DETAIL_REQUEST, data: { datasetId, formGroup } });
      },
      dispatchUpdateDatasetGroupRequest: (datasetId, payload) => {
        dispatch({ type: EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_REQUEST, data: { datasetId, payload } });
      },
      dispatchAddDatasetToGroupRequest: (data) => {
        dispatch({ type: EDIT_GROUP_DATASET_ADD_DATASET_TO_GROUP, data });
      },
      dispatchRemoveDatasetToGroupRequest: (datasetId) => {
        dispatch({ type: EDIT_GROUP_DATASET_REMOVE_DATASET_TO_GROUP, data: datasetId });
      }
    };
  }
}
