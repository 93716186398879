import { put, takeEvery } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { Router } from '@angular/router';
import { NotificationService } from '../../../common/services/notification/notification.service';
import {
  RENDER_EDIT_ATTRIBUTE_FORM_DATA_REQUESTED,
  RENDER_EDIT_ATTRIBUTE_FORM_DATA_SUCCEEDED,
  UPDATE_ATTRIBUTE_REQUESTED, UPDATE_ATTRIBUTE_SUCCEEDED
} from './edit.action';
import { CREATE_NEW_ATTRIBUTE_SUCCEEDED } from '../create/create.action';

function* watchRenderEditAttributeFormRequested() {
  yield takeEvery(RENDER_EDIT_ATTRIBUTE_FORM_DATA_REQUESTED, function*(action: any) {
    try {
      const type = action.data.type;
      const id = action.data.id;

      let result = yield AppInjector.get(ApiService).attribute.getAttributeByTypeAndId(type, id).toPromise();
      yield put({ type: RENDER_EDIT_ATTRIBUTE_FORM_DATA_SUCCEEDED, data: {attribute: result, type: type, inputs: action.data.inputs} });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchUpdateAttributeRequested() {
  yield takeEvery(UPDATE_ATTRIBUTE_REQUESTED, function*(action: any) {
    try {
      const type = action.data.type;
      const id = action.data.id;
      const data = action.data.attribute;

      let result = yield AppInjector.get(ApiService).attribute
        .updateAttribute(data, type, id).toPromise();
      const router = AppInjector.get(Router);
      AppInjector.get(NotificationService).show('success', `Successfully updated attribute`, 3000);
      router.navigate(['/', 'attribute', type]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchRenderEditAttributeFormRequested, watchUpdateAttributeRequested];
