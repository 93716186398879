import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServiceProvider } from './service.provider';
import { ApiUrl } from './api-url.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class AnalyticService {
  protected url = '/api/v1/analytic';

  constructor(private http: HttpClient, private apiUrl: ApiUrl) {}

  /**
   * Get number of product per destination
   *
   * @return Observable
   */
  getNumberOfProductPerDestination(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/destination/product`)).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
  /**
   * Get number of product per location
   *
   * @param Object {destination_id: Number}
   *
   * @return Observable
   */
  getNumberOfProductPerLocation(params: any = {}): Observable<any> {
    if (!_.isUndefined(params.location_ids)) {
      params = _.assign(params, { location_ids: params.location_ids.join(',') });
    }
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/location/product`), { params: params }).pipe(
      map(result => (result as any).data),
      catchError(error => {
        throw error;
      })
    );
  }

  getNumberSoldSaleProductsByDate(data): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/resale-analytics/${data}`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getNumberSoldProductsByCategorySplit(data): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/item-sold-by-categories/${data}`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getAnalyticDataDashboard(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getQuicksightEmbeddedUrl(): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/quicksight/dashboard/embedded-url`, {}).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getResaleActivityEmbeddedUrl(): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/quicksight/resale-activity/embedded-url`, {}).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  get2ndHandSalesEmbeddedUrl(): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/quicksight/2nd-hand-sales/embedded-url`, {}).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getRepurchaseEmbeddedUrl(): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/quicksight/repurchase/embedded-url`, {}).pipe(
      catchError(error => {
        throw error;
      })
    );
  }


  listQuicksightDashboards(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/quicksight/dashboards`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getAvgDisscount(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-average-discount`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getAvgPrice(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-average-price`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getTotalCustomers(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-total-customer`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getSaleInformation(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-sale-information`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getRePurchaseInformation(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-re-purchase`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getAvgItemPerCus(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-avg-items-per-customer`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  getAvgSoldItemPerCus(): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/get-avg-sold-items-per-customer`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
}
