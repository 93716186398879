export const CUSTOMER_COMP = 'CUSTOMER_COMP';

export const listRouter = () => {
  return ['/', 'admin', 'customer'];
};

export const detailRouter = id => {
  return ['/', 'admin', 'customer', id, 'detail'];
};
export const editRouter = id => {
  return ['/', 'admin', 'customer', 'edit', id];
};

export const transactionHistoryRouter = id => {
  return ['/', 'admin', 'customer', id, 'transaction-history'];
};
