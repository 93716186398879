import { Router } from '@angular/router';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { PREVIEW_PRODUCTS_FROM_CSV_REQUESTED, PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED, IMPORT_PRODUCT_FROM_CSV_REQUESTED, IMPORT_PRODUCT_FROM_CSV_SUCCESSED } from './import.actions';
import { listRouter } from '../product.const';

function* watchPreviewProductRequested() {
  yield takeEvery(PREVIEW_PRODUCTS_FROM_CSV_REQUESTED, function*(action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.product.previewCsv(action.data)
        .toPromise();
      yield put({ type: PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* callAdminImportApi(chunk) {
  try {
    const api = AppInjector.get(ApiService);
    const request = {
      records: chunk
    };
    let result = yield api.admin.product.importFromCsv(request).toPromise();
    return result;
  } catch (e) {
    throw e;
  }
}

function* watchImportProductRequested() {
  yield takeEvery(IMPORT_PRODUCT_FROM_CSV_REQUESTED, function*(action: any) {
    let start=0;
    let end=10;
    AppInjector.get(PreloaderService).show();
    try {
      const data = _.chunk(action.data.records, 1);
      const calling_actions = _.map(data, chunk => {
        return call(callAdminImportApi, chunk);
      });
      
      if(action.data.records[0].customer_email === 'garageluxe@reflaunt.com'){
        while ( end < action.data.records.length){
          const sliced_actions = calling_actions.slice(start,end)
          yield all(sliced_actions)
          start=start+10
          end=end+10
        } 
      } else {
        yield all(calling_actions)
      }
      yield put({ type: IMPORT_PRODUCT_FROM_CSV_SUCCESSED });
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(Router).navigate(listRouter());
      AppInjector.get(NotificationService).show('success', 'Product update successfully', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [watchPreviewProductRequested, watchImportProductRequested];
