import * as _ from 'lodash';
import { SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_SUCCESSED } from './consignment-agreement.actions';

export const consignmentAgreement = (state = { fetched: false }, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_SUCCESSED:
      return _.assign({}, state, { fetched: true, access_token: action.data });
    default:
      return state;
  }
};
