import * as _ from 'lodash';
import { FETCH_ALL_USER_SUCCEEDED, DELETE_USER_SUCCEEDED, FILTER_USERS_SUCCEEDED, FILTER_USERS_PROCESSING } from './list.actions';
import { FETCH_ALL_ROLE_SUCCEEDED } from '../../../admin/acl/roles/roles.actions';

export const listUser = (
  state = {
    fetched: false,
    loading: false,
    items: [],
    pagination: {},
    deleted: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_USER_SUCCEEDED:
      return _.assign({}, state, {
        fetched: true,
        items: action.data.items,
        pagination: action.data.pagination,
        loading: false
      });

    case DELETE_USER_SUCCEEDED:
      return _.assign({}, state, {
        deleted: true,
        loading: false
      });

    case FILTER_USERS_SUCCEEDED:
      return _.assign({}, state, {
        fetched: true,
        items: action.data.items,
        pagination: action.data.pagination,
        loading: false
      });

    case FILTER_USERS_PROCESSING:
      return _.assign({}, state, {
        loading: true
      });

    case FETCH_ALL_ROLE_SUCCEEDED:
      let roles = _.cloneDeep(action.data);
      if (!_.isNil(roles) && !_.isNil(action.except)) {
        _.forEach(action.except, item => {
          let role = _.find(roles, { slug: item });
          if (!_.isNil(role)) {
            _.remove(roles, role);
          }
        });
      }
      return _.assign({}, state, {
        roles: roles
      });

    default:
      return state;
  }
};
