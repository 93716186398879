import Model from './Model';
import { map } from 'lodash';
import RetailerServiceShippingCost from './RetailerServiceShippingCost';

class RetailerService extends Model {
  constructor(options) {
    super();
    (this as any).service_type = (d) => {
      return new ServiceType(d.data);
    };
    (this as any).retailer_service_shipping_costs = (d) => {
      return map(d.data, i => new RetailerServiceShippingCost(i));
    };
    (this as any).rs_shipping_costs = (d) => {
      return map(d.data, i => new RetailerServiceShippingCost(i));
    };
    this.bind(options);
  }
}

class ServiceType extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default RetailerService;
