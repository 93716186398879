import { PRODUCT_TRANSLATE_ABLE_TYPE } from './../../../../models/ProductTransaction';
import { FETCH_PRODUCT_DETAIL_SUCCEEDED } from '../detail/detail.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { API_CALL_ERROR } from '../../../../store/action';
import { AppInjector } from '../../../../app-injector';
import {
  UPDATE_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_SUCCEEDED,
  DELETE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_SUCCEEDED,
  RENDER_EDIT_PRODUCT_FORM_REQUESTED,
  GET_PRODUCT_REQUESTED,
  GET_PRODUCT_SUCCEEDED,
  UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
  FILL_PRODUCT_DETAIL_FORM,
  UPDATE_MARKETPLACE_INFORMATION_REQUESTED,
  EDIT_PUBLISH_PRODUCT_REQUESTED,
  EDIT_PUBLISH_PRODUCT_SUCCESSED,
  EDIT_FETCH_MARKETPLACE_REQUESTED,
  EDIT_FETCH_MARKETPLACE_SUCCEEDED,
  UPDATE_STATUS_PRODUCT_REQUESTED,
  GET_PRODUCT_IMAGES_SUCCEEDED,
  GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED,
  GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED,
  GET_HISTORY_PUSH_BY_PRODUCT_ID_REQUESTED,
  GET_HISTORY_PUSH_BY_PRODUCT_ID_SUCCESSED,
  GET_MEDIA_SUPPORT_REQUESTED,
  GET_MEDIA_SUPPORT_SUCCEEDED,
  UPDATE_EDIT_PRODUCT_OUTLINES,
  CANCEL_SALE_ON_PRODUCT_REQUESTED,
  GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED,
  GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_SUCCESSED,
  CANCEL_SALE_ON_PRODUCT_SUCCESSED,
  PRODUCT_DATA_MODIFIED,
  DELIST_PRODUCT_FROM_MARKETPLACE_REQUESTED,
  DELIST_PRODUCT_FROM_MARKETPLACE_SUCCESSED,
  ASSIGN_IMAGE_OUTLINES,
  RESOLVE_PRODUCT_FROM_MARKETPLACE_REQUESTED,
  RESOLVE_PRODUCT_FROM_MARKETPLACE_SUCCESSED,
  GET_PRODUCT_TRANSLATION_REQUEST,
  GET_PRODUCT_TRANSLATION_SUCCESS,
  MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_SUCCESSED,
  MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_REQUESTED,
  SAVE_SMART_PRICER_COMMENT_REQUEST,
  CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_REQUESTED,
  GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED,
  GET_MORE_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED,
  UPDATE_INPUTS_AFTER_CHECK_PRODUCT_ITEMS,
  FETCH_ALL_STATUS_SUCCESSED,
  FETCH_ALL_STATUS_REQUESTED,
  CLONE_PRODUCT_REQUESTED,
  UPDATE_INPUTS_RENDER_AFTER_CHECK_COLLECTION,
  PRODUCT_DELETED_ACTION,
  GET_PRODUCT_CLONE_HISTORY_REQUESTED,
  GET_PRODUCT_CLONE_HISTORY_SUCCESSED,
  CLONE_PRODUCT_SUCCESSED
} from './edit.actions';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { takeEvery, put, takeLatest, all, call, select, delay } from 'redux-saga/effects';
import { GET_ALL_CATEGORIES_REQUESTED } from '../../../admin/category/category.actions';
import { fetchAllcurrency } from '../../currency/currency.saga';
import { fetchAllsize, fetchSizeById } from '../../attribute/size/size.saga';
import { fetchAlldesigner } from '../../attribute/designer/designer.saga';
import { fetchAllcolor } from '../../attribute/color/color.saga';
import { fetchAllConditions } from '../../attribute/condition/condition.saga';
import { fetchAllcategory } from '../../category/category.saga';
import { listRouter } from '../product.const';
import { fetchAlltag } from '../../tag/tag.saga';
import { fetchAllConstants } from '../../../main.saga';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import Cookies from 'js-cookie';
import { resizeImages, USER_ROLES } from '../../../../app.const';
import { fetchAllmaterial } from '../../attribute/material/material.saga';
import * as $ from 'jquery';
import { FR_LANG } from './edit.const';
import { TranslationUtil } from '../../../../common/util/translation-util';
import Swal from 'sweetalert2';
import { fetchAllLocations } from '../../location/location.saga';
import { GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED } from '../list/list.actions';
import { fetchAllmodel } from '../../attribute/model/model.saga';
import { convertNameSize } from './edit.const';
import { SERVICE_TYPES } from './../../../../models/ServiceType';

function* updateProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  const originalImages = yield select((state) => (state as any).Admin.Product.edit.images);
  const user = yield select((state) => (state as any).Auth.login.profile);
  const newImages = [];
  if (action.data.images) {
    if (originalImages && action.data.images) {
      action.data.images.forEach((s, i) => {
        const found = originalImages.find((img) => img.original_url === s.url);
        const additionalValue = {
          label: s.outline,
          position: i
        };
        if (found) {
          newImages.push(_.assign({}, found, s, additionalValue));
        } else {
          if (s.url) {
            newImages.push(_.assign({}, s, additionalValue));
          }
        }
      });
    } else {
      action.data.images.forEach((s, i) => {
        const additionalValue = {
          label: s.outline,
          position: i
        };
        if (s.url) {
          newImages.push(_.assign({}, s, additionalValue));
        }
      });
    }
  }
  action.data.images = newImages;
  try {
    let result = yield api.admin.product.update(action.data.id, action.data).toPromise();
    yield put({ type: UPDATE_PRODUCT_SUCCEEDED, data: result });
    AppInjector.get(NotificationService).show('success', 'Successfully edit product', 5000);
    if (action.data.editType === 'CONTINUE_EDITING') {
      const inputs = yield select((state) => (state as any).Admin.Product.edit.inputs);
      yield put({ type: RENDER_EDIT_PRODUCT_FORM_REQUESTED, data: { id: action.data.id, inputs: inputs }, param: { includes: 'categories,seller,retailer,currency' } });
      router.navigate([]);
    } else {
      if (action.referer) {
        if (action.highlight === 'image') {
          let url = `/admin/product/edit/${action.data.id}`;
          if (user.isRole('logistic')) {
            url = `/admin/collection/detail/${action.params.collection_id}`;
          }
          const param = action.params;
          router.navigate([url], { queryParams: param });
          router.routeReuseStrategy.shouldReuseRoute = () => false;
        } else if (action.highlight === 'price') {
          const url = `/admin/collection/detail/${action.collectionId}`;
          router.navigate([url]);
        }
        // to get url only without query string
        // const url = action.referer.split('?')[0];
        // router.navigate(url.split('/'));
      } else {
        router.navigate(listRouter(), { queryParams: JSON.parse(action.data.queryParams) });
      }
      Cookies.remove('queryParams');
    }
  } catch (e) {
    if (e.error.error_code === 1001 && e.error.message === 'Resource not found') {
      AppInjector.get(NotificationService).show('warning', 'This product was be deleted. Can not update.', 5000);
    } else {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  }
}

function* watchUpdateProductRequested() {
  yield takeEvery(UPDATE_PRODUCT_REQUESTED, updateProduct);
}

function* deleteProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.product.delete(action.data).toPromise();
    yield put({ type: DELETE_PRODUCT_SUCCEEDED });
    router.navigate(listRouter(), { queryParams: JSON.parse(action.queryParams) });
    Cookies.remove('queryParams');
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* updateProductStatusRequested() {
  yield takeLatest(UPDATE_STATUS_PRODUCT_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.product.changeStatusProductToSold(action.id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Product sold', 1000);
      yield delay(1000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.id, params: param });

      yield delay(2000);
      const shipments = yield AppInjector.get(ApiService)
        .admin.shipment.get({ constraints: JSON.stringify({ marketplace_id: action.data.marketplace_id, product_id: result.id, seller_id: result.seller_id }) })
        .toPromise();
      const shipment = _.orderBy(shipments.items, ['id'], ['desc'])[0];
      const destination_address = yield AppInjector.get(ApiService).admin.shipmentAddress.show(shipment.destination_address_id).toPromise();
      let html = `
      <h4>Order will ship to:</h4>
      <p style="font-size: 16px; margin-top: 30px; font-weight: bold;">${destination_address.address_1}, ${destination_address.address_2}, ${destination_address.city}, ${destination_address.zipcode}, ${destination_address.country}</p>
    `;
      if (
        destination_address.address_1 === 'empty' &&
        destination_address.city === 'empty' &&
        destination_address.zipcode === 'empty' &&
        destination_address.phone === 'empty'
        // destination_address.country_alpha2_code === 'empty'
      ) {
        html = '<h4>Order will ship to:</h4><p style="font-size: 16px; margin-top: 30px; font-weight: bold;">unknown address! Please click edit below</p>';
      }
      (Swal as any)
        .fire({
          html: html,
          showCancelButton: true,
          confirmButtonText: `Edit`,
          cancelButtonText: `Accept`,
          allowOutsideClick: false
        })
        .then((result) => {
          if (result.value && result.value === true) {
            // AppInjector.get(Router).navigate(['/', 'admin', 'shipment', `${shipment.id}`, 'detail']);
            AppInjector.get(Router).navigate(['/', 'admin', 'user', action.data.marketplace_id, 'address', 'edit', 'null'], { queryParams: { shipment: shipment.id } });
            // window.location.href = `/admin/user/${action.data.user_id}/address/edit/${action.data.id}?shipment=${action.data.shipment_id}`;
          }
        });

      if (result.quantity <= 0) {
        yield AppInjector.get(ApiService).admin.product.delistFromAllMarket(result.id).toPromise();
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
// function* createShipmentItemRequested() {
//   yield takeLatest(CREATE_SHIPMENT_ITEM_REQUESTED, function*(action: any) {
//     try {
//       const result = yield AppInjector.get(ApiService)
//         .admin.shipment.create(action.data)
//         .toPromise();
//       yield put({ type: CREATE_SHIPMENT_ITEM_SUCCESSED, data: result });
//     } catch (e) {
//       yield put({ type: API_CALL_ERROR, error: e });
//     }
//   });
// }

function* watchDeleteProductRequested() {
  yield takeEvery(DELETE_PRODUCT_REQUESTED, deleteProduct);
}

function* watchFetchProductSuccessed() {
  yield takeEvery(FETCH_PRODUCT_DETAIL_SUCCEEDED, function* () {
    yield put({
      type: GET_ALL_CATEGORIES_REQUESTED
    });
  });
}
function* watchGetMoreProductItemRequest() {
  yield takeLatest(GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.productItem.get(action.data).toPromise();
      yield put({ type: GET_MORE_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* fetchProductDetail(id, params) {
  try {
    if (!_.isNil(params)) {
      return yield AppInjector.get(ApiService).admin.product.getItemById(id, params).toPromise();
    } else {
      return yield AppInjector.get(ApiService).product.getItemById(id).toPromise();
    }
  } catch (e) {
    AppInjector.get(NotificationService).show('warning', e.error ? e.error.message : e, 5000);
  }
}

export function* fetchProductImages(id) {
  return yield AppInjector.get(ApiService)
    .admin.image.list({ constraints: { imageable_id: id, imageable_type: 'product' } })
    .toPromise();
}

export function* fetchInitialMediaSupport(id) {
  const result = yield AppInjector.get(ApiService).admin.product.getMediaSupport(id, {}).toPromise();
  return result;
}

export function* fetchProductCatalogue(product_catalogue_id) {
  if (!product_catalogue_id) {
    return;
  }
  const result = yield AppInjector.get(ApiService).admin.productCatalogue.getItemById(product_catalogue_id).toPromise();
  return result;
}

export function* fetchProductTags(id) {
  return yield AppInjector.get(ApiService).admin.tag.getProductTags(id).toPromise();
}

function* getPost(action) {
  const retail_tags = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];
  const sign_of_wears = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];
  const clothing_size = [
    { value: 'Above Knee', label: 'Above Knee' },
    { value: 'High-Low', label: 'High-Low' },
    { value: 'Knee Length', label: 'Knee Length' },
    { value: 'Long', label: 'Long' },
    { value: 'Mid-Length', label: 'Mid-Length' },
    { value: 'Mini', label: 'Mini' },
    { value: 'Extra Long', label: 'Extra Long' },
    { value: 'Petite', label: 'Petite' },
    { value: 'Regular', label: 'Regular' },
    { value: 'Cropped', label: 'Cropped' },
    { value: 'Waist', label: 'Waist' },
    { value: 'Short', label: 'Short' },
    { value: 'Maxi', label: 'Maxi' }
  ];
  const product = yield call(fetchProductDetail, action.data, action.params);
  if (product && !product.deleted_at) {
    const [images, productTags, mediaSupports, productCatalogue] = yield all([
      call(fetchProductImages, action.data),
      call(fetchProductTags, action.data),
      call(fetchInitialMediaSupport, action.data),
      call(fetchProductCatalogue, product.product_catalogue_id)
    ]);
    const [currencies, sizes, designers, colors, categories, conditions, tags, constants, materials, service_types, locations, models] = yield all([
      call(fetchAllcurrency),
      call(fetchSizeById, {
        ids: product.size_id,
        constraints: JSON.stringify({
          is_old_size: false
        })
      }),
      call(fetchAlldesigner),
      call(fetchAllcolor),
      call(fetchAllcategory),
      call(fetchAllConditions),
      call(fetchAlltag),
      call(fetchAllConstants),
      call(fetchAllmaterial),
      call(fetchAllServiceTypes),
      call(fetchAllLocations),
      call(fetchAllmodel)
    ]);
    resizeImages(images);
    const sizeConvert = _.map(sizes, (item, key) => {
      item.name = convertNameSize(item);
      return _.assign(item, { value: item.id, label: item.name });
    });
    const optionsData = {
      designer_id: _.map(designers, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      currency_id: _.map(currencies, (item, key) => _.assign(item, { value: item.id, label: item.code })),
      size_id: sizeConvert,
      color_id: _.map(colors, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      material_id: _.map(materials, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      model_id: _.map(models, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      category_id: _.map(categories, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      condition_id: _.map(conditions, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      tags: _.map(tags, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      retail_tag: retail_tags,
      sign_of_wear: sign_of_wears,
      clothing_size: clothing_size,
      gender: _.map(constants.gender, (item, key) => _.assign(item, { value: item.value, label: item.key })),
      age_group: _.map(constants.age_group, (item, key) => _.assign(item, { value: item.value, label: item.key })),
      service_type_id: _.map(service_types, (item) => _.assign(item, { value: item.id, label: _.capitalize(item.name).replace(/-/g, ' ') }))
    };

    const outlinesData = {
      images: _.map(mediaSupports, (mediaSupport) =>
        _.assign(
          {},
          {
            outline: mediaSupport.picture_style,
            comment: mediaSupport.comment
          }
        )
      )
    };

    const product_items = yield AppInjector.get(ApiService).admin.productItem.get({ product_id: product.id }).toPromise();
    const currency = _.find(currencies, (item) => product.currency.id === item.id);

    let base_price, base_original_price;
    if (currency && currency.code) {
      if (product.price.price) {
        base_price = yield AppInjector.get(ApiService).currency.getConvertedReverseAmount(currency.code, 'EUR', product.price.price).toPromise();
        product.base_price = base_price ? base_price.amount : null;
      }
      if (product.original_price) {
        base_original_price = yield AppInjector.get(ApiService).currency.getConvertedReverseAmount(currency.code, 'EUR', product.original_price).toPromise();
        product.base_original_price = base_original_price ? base_original_price.amount : null;
      }
    }

    const retailerService = yield AppInjector.get(ApiService).admin.product.getRetailerServiceByProductId(action.data).toPromise();

    yield put({ type: UPDATE_EDIT_PRODUCT_INPUT_OPTIONS, data: optionsData });
    yield put({ type: GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED, data: { role_ids: USER_ROLES.RETAILER } });
    yield put({ type: UPDATE_EDIT_PRODUCT_OUTLINES, data: outlinesData });
    yield put({ type: ASSIGN_IMAGE_OUTLINES, data: _.sortBy(images, 'position') });
    yield put({ type: GET_PRODUCT_SUCCEEDED, data: product, product_items: product_items.items, locations, currencies, retailerService, productCatalogue });
    yield put({ type: PRODUCT_DATA_MODIFIED, data: product });
    yield put({ type: GET_PRODUCT_IMAGES_SUCCEEDED, data: _.sortBy(images, 'position'), highlight: action.highlight });
    yield put({ type: GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED, data: { product_id: action.data } });
    const getIsEligibleForInstantRewardValue = () => {
      if (product.is_eligible_for_instant_reward === true) {
        return 'yes';
      }
      if (product.is_eligible_for_instant_reward === false) {
        return 'no';
      }
      return null;
    };
    const data = {
      sku: product.sku,
      supplier_sku: product.supplier_sku ? product.supplier_sku : product.sku,
      group_sku: product.group_sku ? product.group_sku : null,
      name: product.name,
      location: product.warehouse_sku,
      price: product.price.price,
      base_price: base_price ? base_price.amount : null,
      partner_selling_price: product.partner_selling_price,
      original_price: product.original_price,
      base_original_price: base_original_price ? base_original_price.amount : null,
      designer_id: product.designer_id ? _.find(designers, (item) => item.id === product.designer_id) : null,
      size_id: product.size_id ? _.find(sizes, (item) => item.id === product.size_id) : null,
      heel_height: product.heel_height,
      platform_height: product.platform_height,
      width: product.width,
      length: product.length,
      height: product.height,
      handle_drop: product.handle_drop,
      strap_length: product.strap_length,
      upc: product.upc,
      color_id: product.color_id ? _.find(colors, (item) => item.id === product.color_id) : null,
      material_string: product.material_string,
      material_id: product.material_id ? _.find(materials, (item) => item.id === product.material_id) : null,
      model_id: product.model_id ? _.find(models, (item) => item.id === product.model_id) : null,
      customer_email: product.seller ? product.seller.email : '',
      category_id: product.categories.data.length > 0 ? _.find(categories, (item) => item.id === product.categories.data[0].id) : null,
      condition_id: product.condition_id ? _.find(conditions, (item) => item.id === product.condition_id) : null,
      website: product.website,
      description: product.description,
      private_note: product.private_note,
      condition_description: product.condition_description,
      retailer_email: product.retailer ? product.retailer.email : '',
      quantity: !_.isUndefined(product.quantity) ? product.quantity.toString() : 1,
      currency_id: currency,
      service_type_id: product.service_type_id ? _.find(service_types, (item) => product.service_type_id === item.id) : null,
      retail_tag: _.find(retail_tags, (item) => product.retail_tag === item.value),
      sign_of_wear: _.find(sign_of_wears, (item) => product.sign_of_wear === item.value),
      collection_id: product.collection_id,
      color_changed_at: product.color_changed_at ? 'yes' : 'no',
      clothing_size: _.find(clothing_size, (item) => product.custom_attributes.clothing_size === item.value),
      tags: _.map(tags, (item) =>
        _.includes(
          _.map(productTags, (tag) => tag.id),
          item.value
        )
          ? _.assign(item, { selected: true })
          : item
      ),
      gender: constants.gender.find((s) => product.gender === s.value),
      age_group: constants.age_group.find((s) => product.age_group === s.value),
      is_eligible_for_instant_reward: getIsEligibleForInstantRewardValue(),
      instant_reward_price: product.instant_reward_price,
      instant_reward_payout_range: product.instant_reward_payout_range
    };
    // let userLogin = yield AppInjector.get(ApiService).user.profile({ includes: 'roles' }).toPromise();
    const userRoles = yield select((state) => (state as any).Auth.login.profile.roles);
    yield put({ type: FILL_PRODUCT_DETAIL_FORM, data: data, is_pricing_collection_page: AppInjector.get(ActivatedRoute).snapshot.queryParams.highlight === 'price', userRoles });
  } else {
    yield put({ type: PRODUCT_DELETED_ACTION });
  }
}

function* watchGetProductRequest() {
  yield takeEvery(GET_PRODUCT_REQUESTED, getPost);
}

function* watchRenderProductDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_PRODUCT_FORM_REQUESTED, function* (action: any) {
    yield put({ type: GET_PRODUCT_REQUESTED, data: action.data.id, params: action.param, highlight: action.data.highlight });
    yield put({ type: GET_PRODUCT_TRANSLATION_REQUEST, productId: action.data.id });
  });
}
function* updateMarketplaceOfProductRequested() {
  yield takeLatest(UPDATE_MARKETPLACE_INFORMATION_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.productManagement.update(action.id, action.data).toPromise();
      if (!action.is_fixed_price) {
        AppInjector.get(ApiService)
          .admin.productManagement.recalculatePrice(action.id)
          .subscribe(() => {});
      }
      const param = { includes: 'categories,seller,retailer,currency,shipments,ServiceType' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.product_id, params: param });
      AppInjector.get(NotificationService).show('success', 'Update success', 2000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* callPublish2Api(product_id, params) {
  try {
    const api = AppInjector.get(ApiService);
    let result = yield api.admin.product.publish2(product_id, params).toPromise();
    return result;
  } catch (e) {
    throw e;
  }
}

function* watchPublishProductRequested() {
  yield takeLatest(EDIT_PUBLISH_PRODUCT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let params = { marketplaces: action.marketplaces.map((item) => item.id) };
      let result = yield AppInjector.get(ApiService).admin.product.publish(action.data.id, params).toPromise();
      yield put({ type: EDIT_PUBLISH_PRODUCT_SUCCESSED, data: result, product_id: action.data.id });
      AppInjector.get(NotificationService).show('success', 'Publish product success', 2000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.data.id, params: param });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchGetMarketplaceRequested() {
  yield takeLatest(GET_PRODUCT_SUCCEEDED, function* (action: any) {
    try {
      const params = { filter: { role_ids: '5' }, includes: 'marketplace' };
      if (action.data.product_items && action.data.product_items.length > 0) {
        const check = checkIfAllProductItemsHavingSamePrice(action.data.product_items, action.data);
        yield put({ type: UPDATE_INPUTS_AFTER_CHECK_PRODUCT_ITEMS, data: check });
      }
      yield put({ type: EDIT_FETCH_MARKETPLACE_REQUESTED, data: params, product: action.data });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* getAllMarketplaceRequested() {
  yield takeLatest(EDIT_FETCH_MARKETPLACE_REQUESTED, getMarketplaces);
}
function* getMarketplaces(action) {
  const api = AppInjector.get(ApiService);
  try {
    let data: any = {};
    let sorts = [];
    // tslint:disable-next-line:forin
    for (const key in action.data.pagination) {
      data[key] = action.data.pagination[key];
    }
    if (_.isNil(data.page)) {
      data.page = 1;
    }
    if (_.isNil(data.per_page)) {
      data.per_page = 100;
    }
    if (!_.isNil(action.data.filter)) {
      data.constraints = JSON.stringify(action.data.filter);
    }
    if (!_.isNil(action.data.includes)) {
      data.includes = action.data.includes;
    }
    if (!_.isNil(action.data.sort)) {
      for (const key in action.data.sort) {
        if (!_.isNil(action.data.sort[key])) {
          sorts.push(action.data.sort[key] + key);
        }
      }
    }
    if (sorts.length !== 0) {
      data.sort = _.join(sorts, ',');
    }
    const results = yield AppInjector.get(ApiService).user.get(data).toPromise();
    results.items.sort((a, b) =>
      _.lowerCase(a.marketplace && a.marketplace.company ? a.marketplace.company : a.email) < _.lowerCase(b.marketplace && b.marketplace.company ? b.marketplace.company : b.email)
        ? -1
        : 1
    );
    yield put({ type: EDIT_FETCH_MARKETPLACE_SUCCEEDED, data: results.items, pagination: results.pagination });
    yield put({ type: GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED, data: { product_id: action.product.id } });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* getTransactionByProductIdRequested() {
  yield takeLatest(GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.transactionHistory.get(action.data).toPromise();
      yield put({ type: GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED, data: result.items });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* getProductItemByProductIdRequested() {
  yield takeLatest(GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.transactionHistory.get(action.data).toPromise();
      yield put({ type: GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED, data: result.items });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* getHistoryPushByProductIdRequested() {
  yield takeLatest(GET_HISTORY_PUSH_BY_PRODUCT_ID_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.history_push.get(action.data).toPromise();
      yield put({ type: GET_HISTORY_PUSH_BY_PRODUCT_ID_SUCCESSED, data: result.items });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* getAllProductManagementRequested() {
  yield takeLatest(GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED, function* (action: any) {
    try {
      const publishableProductManagements = yield AppInjector.get(ApiService)
        .admin.productManagement.get({
          ...action.data,
          only_publishable: true
        })
        .toPromise();
      const allProductManagements = yield AppInjector.get(ApiService)
        .admin.productManagement.getHistoryPush({
          ...action.data,
          ...(action.data.nextPage ? { page: action.data.nextPage } : {})
        })
        .toPromise();
      yield put({
        type: GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_SUCCESSED,
        publishable_product_item_managements: publishableProductManagements.items,
        all_product_item_managements: allProductManagements.items,
        product_item_managements_pagination: allProductManagements.pagination
      });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* getAllProductCloneHistoryRequested() {
  yield takeLatest(GET_PRODUCT_CLONE_HISTORY_REQUESTED, function* (action: any) {
    try {
      const productCloneHistories = yield AppInjector.get(ApiService)
        .admin.productCloneHistory.get({
          ...action.data
        })
        .toPromise();
      yield put({
        type: GET_PRODUCT_CLONE_HISTORY_SUCCESSED,
        product_clone_histories: productCloneHistories.data,
        product_id: action.data.id
      });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* getMediaSupportRequested() {
  yield takeEvery(GET_MEDIA_SUPPORT_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.product.getMediaSupport(action.id, action.data).toPromise();
      yield put({ type: GET_MEDIA_SUPPORT_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getCloneProductRequested() {
  yield takeEvery(CLONE_PRODUCT_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.product.cloneProduct(action.data.product_id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Clone product successed!', 2000);
      yield delay(1000);
      AppInjector.get(Router).navigate([`/admin/collection/detail/${result.collection_id}`]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* cancelSaleRequested() {
  yield takeEvery(CANCEL_SALE_ON_PRODUCT_REQUESTED, function* (action: any) {
    try {
      let product_managements = yield AppInjector.get(ApiService).admin.productManagement.get({ product_id: action.product_id, user_id: action.marketplace_id }).toPromise();
      if (product_managements.items.length === 0) {
        throw new Error('no product management override found');
      }
      const product_management = product_managements.items[0];
      yield AppInjector.get(ApiService).admin.productManagement.cancelSale(product_management.id, action.product_item_id).toPromise();
      yield put({ type: CANCEL_SALE_ON_PRODUCT_SUCCESSED });
      AppInjector.get(NotificationService).show('success', 'Sale canceled', 2000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.product_id, params: param });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* delistProductFromMarketplace() {
  yield takeLatest(DELIST_PRODUCT_FROM_MARKETPLACE_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.product.delistProductsFromSpecificMarketplace(action.data).toPromise();
      yield put({ type: DELIST_PRODUCT_FROM_MARKETPLACE_SUCCESSED });
      AppInjector.get(NotificationService).show('success', 'Delist success', 2000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.data.product_id, params: param });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.data.product_id, params: param });
    }
  });
}

function* watchCreateOrderWithoutStockQuantityRequest() {
  yield takeLatest(CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.product.createOrderWithNonStockQuantity(action.data.product_id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Create order with non stock quantity success', 2000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.data.product_id, params: param });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.data.product_id, params: param });
    }
  });
}
function* watchMarkProductAsFailFromMarketplace() {
  yield takeLatest(MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.productManagement.markAsFail(action.id).toPromise();
      yield put({ type: MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_SUCCESSED });
      AppInjector.get(NotificationService).show('success', 'Resolve success', 2000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.product_id, params: param });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.product_id, params: param });
    }
  });
}
function* resolveProductManagementRequested() {
  yield takeLatest(RESOLVE_PRODUCT_FROM_MARKETPLACE_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.productManagement.resolveOperation(action.id, action.data).toPromise();
      yield put({ type: RESOLVE_PRODUCT_FROM_MARKETPLACE_SUCCESSED });
      AppInjector.get(NotificationService).show('success', 'Resolve success', 2000);
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.product_id, params: param });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      const param = { includes: 'categories,seller,retailer,currency' };
      yield put({ type: GET_PRODUCT_REQUESTED, data: action.product_id, params: param });
    }
  });
}

function* watchGetProductTranslationRequest() {
  yield takeLatest(GET_PRODUCT_TRANSLATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const constraints = JSON.stringify({ translatable_id: action.productId, translatable_type: PRODUCT_TRANSLATE_ABLE_TYPE });
    try {
      const result = yield api.admin.productTranslation.list({ lang: FR_LANG, constraints }).toPromise();
      const data = TranslationUtil.transform(result);
      yield put({ type: GET_PRODUCT_TRANSLATION_SUCCESS, data });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllRequest() {
  yield takeLatest(SAVE_SMART_PRICER_COMMENT_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.reflaunt.storeSmartPricerComment(action.data).toPromise();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* fetchAllServiceTypes() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.serviceType.get({ per_page: 1000 }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function checkIfAllProductItemsHavingSamePrice(product_items, product) {
  const activeProductItems = _.filter(product_items, (e) => e.status && ['pending', 'selling'].includes(e.status.slug));
  const prices = _.map(activeProductItems, (e) => e.price);
  const uniqPrices = _.uniq(prices);
  let checkProductPriceDifferentWithProductItemsPrice;
  if (product.price && product.price.price) {
    checkProductPriceDifferentWithProductItemsPrice = _.find(uniqPrices, (p) => Number(p) !== Number(product.price.price));
  }
  if (checkProductPriceDifferentWithProductItemsPrice) {
    return false;
  }
  return true;
}

function* watchFetchAllStatusRequested() {
  yield takeLatest(FETCH_ALL_STATUS_REQUESTED, function* (action: any) {
    try {
      const statuses = yield AppInjector.get(ApiService).status.list().toPromise();
      yield put({ type: FETCH_ALL_STATUS_SUCCESSED, data: statuses });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [
  watchGetMarketplaceRequested,
  updateProductStatusRequested,
  getAllMarketplaceRequested,
  watchPublishProductRequested,
  watchUpdateProductRequested,
  updateMarketplaceOfProductRequested,
  watchDeleteProductRequested,
  watchFetchProductSuccessed,
  watchRenderProductDetailFormRequested,
  watchGetProductRequest,
  getTransactionByProductIdRequested,
  getHistoryPushByProductIdRequested,
  getMediaSupportRequested,
  cancelSaleRequested,
  getAllProductManagementRequested,
  delistProductFromMarketplace,
  resolveProductManagementRequested,
  watchGetProductTranslationRequest,
  watchMarkProductAsFailFromMarketplace,
  watchGetAllRequest,
  watchCreateOrderWithoutStockQuantityRequest,
  watchGetMoreProductItemRequest,
  watchFetchAllStatusRequested,
  getCloneProductRequested,
  getAllProductCloneHistoryRequested
];
