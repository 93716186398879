import { ItemsCostService } from './items-cost/items-cost.service';
import { LocationService } from './location/location.service';
import { OrderProductService } from './order-product/order-product.service';
import { TransactionService } from './transaction/transaction.service';
import { NotificationService } from './notification/notification.service';
import { CollectionService } from './collection/collection.service';
import { ApplicationService } from './application/application.service';
import { Injectable } from '@angular/core';
import { UserService } from './user/user.service';
import { DesignerService } from './designer/designer.service';
import { DictionaryService } from './dictionary/dictionary.service';
import { ProductService } from './product/product.service';
import { TagService } from './tag/tag.service';
import { EmailService } from './email/email.service';
import { AddressService } from './address/address.service';
import { ShipmentService } from './shipment/shipment.service';
import { TransactionHistoryService } from './transaction-history/transaction-history.service';
import { ActivityLogService } from './activity-log/activity-log.service';
import { ProductItemService } from './product-item/product-item.service';
import { CategoryService } from './category/category.service';
import { ConditionService } from './condition/condition.service';
import { ColorService } from './color/color.service';
import { SizeService } from './size/size.service';
import { ShippingMethodService } from './shipping-method/shipping-method.service';
import { IntegrationService } from './integration/integration.service';
import { EndpointService } from './endpoint/endpoint.service';
import { OAuthClientService } from './oauth-client/oauth-client';
import { IssueTrackingService } from './issue-tracking/issue-tracking.service';
import { NotificationActionService } from './notification-action/notification-action.service';
import { IntegrationNotification } from './Integration-notification/Integration-notification.service';
import { ZoneService } from './zone/zone.service';
import { ImageService } from './image/image.service';
import { ProductManagementService } from './product-management/product-management.service';
import { DataService } from './dataset/dataset.service';
import { MaterialService } from './material/material.service';
import { HistoryPushService } from './history-push/history_push.service';
import { SmartObjectService } from './smart-object/smart-object.service';
import { PaymentService } from './payment/payment.service';
import { TransactionLogService } from './transaction-log/transaction-log.service';
import { OrderService } from './order/order.service';
import { CommunicationService } from './communication/communication.service';
import { CourierService } from './courier/courier.service';
import { LanguageService } from './language/language.service';
import { marketplaceRetailerConfigService } from './marketplace-retailer-config/marketplace-retailer-config.service';
import { TranslationService } from './translation/translation.service';
import { ProductTranslationService } from './product-translation/product-translation.service';
import { ShipmentAddressService } from './shipment-address/shipment-address.service';
import { AsyncProcessService } from '../async-process/async_processes.service';
import { ServiceTypeService } from './service-type/service-type.service';
import { MagicService } from './magic/magic.service';
import { NegotiationService } from './negotiation/negotiation.service';
import { GroupedPaymentService } from './grouped-payment/grouped-payment.service';
import { ProductHistoryService } from './product-history/product-history.service';
import { CountryService } from '../country/country.service';
import { ProductCloneHistoryService } from './product-clone-history/product-clone-history.service';
import { ProductCatalogueService } from './product-catalogue/product-catalogue.service';
import { CustomsCostService } from './items-cost/customs-cost.service';

@Injectable()
export class AdminApiService {
  constructor(
    public country: CountryService,
    public itemsCost: ItemsCostService,
    public customCost: CustomsCostService,
    public location: LocationService,
    public fulfilment: OrderProductService,
    public transaction: TransactionService,
    public notification: NotificationService,
    public collection: CollectionService,
    public transactionLog: TransactionLogService,
    public payment: PaymentService,
    public productManagement: ProductManagementService,
    public marketplaceRetailerConfig: marketplaceRetailerConfigService,
    public application: ApplicationService,
    public designer: DesignerService,
    public courier: CourierService,
    public dictionary: DictionaryService,
    public product: ProductService,
    public image: ImageService,
    public tag: TagService,
    public email: EmailService,
    public address: AddressService,
    public shipment: ShipmentService,
    public user: UserService,
    public transactionHistory: TransactionHistoryService,
    public activityLog: ActivityLogService,
    public asyncProcess: AsyncProcessService,
    public productItem: ProductItemService,
    public category: CategoryService,
    public condition: ConditionService,
    public color: ColorService,
    public size: SizeService,
    public shippingMethod: ShippingMethodService,
    public integration: IntegrationService,
    public endpoint: EndpointService,
    public oauthClient: OAuthClientService,
    public issueTracking: IssueTrackingService,
    public notificationAction: NotificationActionService,
    public integrationNotification: IntegrationNotification,
    public zone: ZoneService,
    public history_push: HistoryPushService,
    public dataset: DataService,
    public material: MaterialService,
    public smartObject: SmartObjectService,
    public order: OrderService,
    public communication: CommunicationService,
    public language: LanguageService,
    public translation: TranslationService,
    public productTranslation: ProductTranslationService,
    public shipmentAddress: ShipmentAddressService,
    public serviceType: ServiceTypeService,
    public magic: MagicService,
    public negotiation: NegotiationService,
    public groupedPayment: GroupedPaymentService,
    public productHistory: ProductHistoryService,
    public productCloneHistory: ProductCloneHistoryService,
    public productCatalogue: ProductCatalogueService,
  ) {}
}
