import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Store } from '../../../../store/store.module';
import initSubscriber from 'redux-subscriber';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  public store: any;
  public props: Object = {};
  public states: Object = {};
  public unsubscribe: Function;
  constructor(store: Store) {
    this.store = store.getInstance();
    const subscribe = initSubscriber(this.store);
    const unsubscribe = subscribe('Tag.list', state => {
      const data = this.mapStateToProps(state);
      const keys = Object.keys(data);
      _.forEach(keys, key => (this[key] = data[key]));
    });
  }
  ngOnInit() {
    this.store.dispatch({ type: 'TEST_TEST' });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.Tag.list
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }
}
