import * as _ from 'lodash';
import { FETCH_PERCENTAGE_SUCCEEDED } from './list.action';

export const list = (
  state = {
    fetched: false,
    loading: false,
    items: [],
    pagination: {},
    deleted: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_PERCENTAGE_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    default:
      return state;
  }
};
