import * as _ from 'lodash';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../store/action';
import {
  RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST,
  RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS,
  RETAILER_RESALE_DRAW_ITEMS_PUT_ON_SALE_CHART,
  RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST,
  RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS,
  RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_REQUEST,
  RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_SUCCESS,
  RETAILER_RESALE_GET_AVG_PRICE_DATA_SUCCESS,
  RETAILER_RESALE_GET_AVG_PRICE_DATA_REQUEST,
  RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_REQUEST,
  RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_SUCCESS,
  RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_REQUEST,
  RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_SUCCESS, FETCH_RESALE_ACTIVITTY_REQUESTED, FETCH_RESALE_ACTIVITTY_SUCCEEDED
} from './resale.const';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

function* watchRetailerGerItemsPutOnSaleChartDataRequest() {
  yield takeEvery(RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getNumberSoldSaleProductsByDate(action.data).toPromise();
      yield put({ type: RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerGerItemsPutOnSaleChartDataSuccess() {
  yield takeEvery(RETAILER_RESALE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS, function*(action: any) {
    yield put({ type: RETAILER_RESALE_DRAW_ITEMS_PUT_ON_SALE_CHART });
  });
}

function* watchRetailerGerItemsSoldCategorySplitChartDataRequest() {
  yield takeEvery(RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getNumberSoldProductsByCategorySplit(action.data).toPromise();
      yield put({ type: RETAILER_RESALE_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerGeAvgDisscountDataRequest() {
  yield takeEvery(RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getAvgDisscount().toPromise();
      yield put({ type: RETAILER_RESALE_GET_AVG_DISCCOUNT_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}


function* watchRetailerGeAvgPriceDataRequest() {
  yield takeEvery(RETAILER_RESALE_GET_AVG_PRICE_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getAvgPrice().toPromise();
      yield put({ type: RETAILER_RESALE_GET_AVG_PRICE_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerGetTotalCustomerDataRequest() {
  yield takeEvery(RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getTotalCustomers().toPromise();
      yield put({ type: RETAILER_RESALE_GET_TOTAL_CUSTOMER_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerGetAvgProductperCusDataRequest() {
  yield takeEvery(RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getAvgItemPerCus().toPromise();
      yield put({ type: RETAILER_RESALE_GET_AVG_NO_PRODUCT_PER_CUS_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* fetchResaleActivity(action) {
  yield takeEvery(FETCH_RESALE_ACTIVITTY_REQUESTED, function*(action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const api = AppInjector.get(ApiService);
      let result = yield api.analytic.getResaleActivityEmbeddedUrl().toPromise();
      AppInjector.get(PreloaderService).hide();
      yield put({ type: FETCH_RESALE_ACTIVITTY_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchRetailerGeAvgDisscountDataRequest,
  watchRetailerGerItemsPutOnSaleChartDataRequest,
  watchRetailerGerItemsPutOnSaleChartDataSuccess,
  watchRetailerGerItemsSoldCategorySplitChartDataRequest,
  watchRetailerGeAvgPriceDataRequest,
  watchRetailerGetTotalCustomerDataRequest,
  watchRetailerGetAvgProductperCusDataRequest,
  fetchResaleActivity
];
