import * as _ from 'lodash';
import { FETCH_ALL_CREDENTIALS_SUCCEEDED, CREATE_CREDENTIAL_SUCCEEDED } from './credential.actions';

export const Credential = (state = { fetched: false, items: [], pagination: {}, generateResult: {} }, action) => {
  switch (action.type) {
    case FETCH_ALL_CREDENTIALS_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.items, pagination: action.pagination });
    case CREATE_CREDENTIAL_SUCCEEDED:
      return _.assign({}, state, { generateResult: action.data });
    default:
      return state;
  }
};
