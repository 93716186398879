import { takeEvery, put } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { GET_DETAIL_EMAIL_REQUESTED, GET_DETAIL_EMAIL_SUCCEEDED } from './detail-email.actions';

function* getDetailEmail(action) {
  const api = AppInjector.get(ApiService);
  try {
    let data = yield api.admin.email.getTextFile(action.data.id).toPromise();
    let result = yield api.admin.email.getByMandrillId(action.data.id).toPromise();
    yield put({ type: GET_DETAIL_EMAIL_SUCCEEDED, data: data, result: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetDetailEmailRequest() {
  yield takeEvery(GET_DETAIL_EMAIL_REQUESTED, getDetailEmail);
}

export default [watchGetDetailEmailRequest];
