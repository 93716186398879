import { Router } from '@angular/router';
import {
  DELETE_ADMIN_MARKETPLACE_REQUESTED,
  GET_MARKETPLACE_REQUESTED,
  GET_MARKETPLACE_SUCCEEDED,
  EDIT_ADMIN_MARKETPLACE_REQUESTED,
  RENDER_EDIT_MARKETPLACE_FORM_REQUESTED,
  FILL_MARKETPLACE_DETAIL_FORM,
  UPDATE_MARKETPLACE_INPUT_OPTIONS,
  RESET_MARKETPLACE_SERVICE_CONFIGURATION_REQUESTED
} from './edit.actions';
import { takeEvery, put, takeLatest, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { fetchUserProfilePicture } from '../../user/user.saga';
import { fetchAllcurrency } from '../../currency/currency.saga';
import * as _ from 'lodash';
import { fetchAllConstants } from '../../../main.saga';
import { Countries } from '../../user/address/countries';
import { listAdminMarketplace } from '../marketplace.const';
import Cookies from 'js-cookie';
import { MarketplaceService } from '../../../../api/marketplace/marketplace.service';
import { fetchAllLocations } from '../../location/location.saga';

function* watchEditMarketplaceRequest() {
  yield takeEvery(EDIT_ADMIN_MARKETPLACE_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.user.update(action.data.id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Marketplace updated', 3000);
      const router = AppInjector.get(Router);
      router.navigate(listAdminMarketplace(), { queryParams: JSON.parse(Cookies.get('currentQueryParamsMarketplace')) });
      Cookies.remove('currentQueryParamsMarketplace');
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getMarketplace(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.user.getItemById(action.data).toPromise();
    yield put({ type: GET_MARKETPLACE_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetMarketplaceRequest() {
  yield takeEvery(GET_MARKETPLACE_REQUESTED, getMarketplace);
}

function* deleteMarketplace(action) {
  const api = AppInjector.get(ApiService);
  try {
    yield api.user.delete(action.data).toPromise();
    AppInjector.get(NotificationService).show('success', 'Marketplace deleted', 3000);
    AppInjector.get(Router).navigate(['/', 'admin', 'marketplace']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* resetMarketplaceServiceConfiguration(action) {
  const api = AppInjector.get(MarketplaceService);
  try {
    yield api.resetMarketplaceServiceConfiguration(action.data).toPromise();
    AppInjector.get(NotificationService).show('success', 'Marketplace service configuration successfully reset', 3000);
    AppInjector.get(Router).navigate(['/', 'admin', 'marketplace']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteMarketplaceRequest() {
  yield takeEvery(DELETE_ADMIN_MARKETPLACE_REQUESTED, deleteMarketplace);
}

function* watchResetMarketplaceServiceConfigurationRequest() {
  yield takeEvery(RESET_MARKETPLACE_SERVICE_CONFIGURATION_REQUESTED, resetMarketplaceServiceConfiguration);
}

function* watchRenderMarketplaceDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_MARKETPLACE_FORM_REQUESTED, function* (action: any) {
    yield put({ type: GET_MARKETPLACE_REQUESTED, data: action.data.id });
  });
}

function* fillMarketplaceDetailForm(action) {
  const image = yield call(fetchUserProfilePicture, action.data.id);
  const currencies = yield call(fetchAllcurrency);
  const locations = yield call(fetchAllLocations);
  const forwardShipmentLocations = _.cloneDeep(locations)
  forwardShipmentLocations.unshift({id: null, name: 'None'})
  const constants = yield call(fetchAllConstants);
  const COUNTRIES = Countries;
  let languages = [];
  languages.push({ value: '', label: 'Default' });
  let countries = yield AppInjector.get(ApiService).country.list().toPromise();
  _.map(COUNTRIES, (item) => _.map(item.languages, (lang) => languages.push({ value: lang.iso639_1, label: lang.name })));
  yield put({
    type: UPDATE_MARKETPLACE_INPUT_OPTIONS,
    countries,
    currencies,
    data: {
      currency_id: _.map(currencies, (item, key) => _.assign(item, { value: item.id, label: item.code })),
      return_location_id: _.map(locations, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      forward_shipment_location_id: _.map(forwardShipmentLocations, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      _location_id: _.map(locations, (item, key) => _.assign(item, { value: item.id, label: item.name })),
      preferred_sizing_prefix: _.map(constants.size_prefixes, (item, key) => _.assign(item, { value: item.key, label: item.value })),
      language_code: _.orderBy(_.uniqBy(languages, 'label'), 'value')
    }
  });
  yield put({
    type: FILL_MARKETPLACE_DETAIL_FORM,
    data: {
      ...action.data,
      avatar: image.url,
      currency_id: currencies.find((s) => s.id === action.data.marketplace.currency_id),
      return_location_id: locations.find((s) => s.id === action.data.marketplace.return_location_id),
      forward_shipment_location_id: forwardShipmentLocations.find((s) => s.id === action.data.marketplace.forward_shipment_location_id),
      country_id: countries.find((s) => s.id === action.data.marketplace.country_id),
      preferred_sizing_prefix: constants.size_prefixes.find((s) => s.key === action.data.marketplace.preferred_sizing_prefix),
      language_code: languages.find((l) => l.value === action.data.marketplace.language_code)
    },
    constants: {
      currencies: currencies.filter((s) => s.id === action.data.marketplace.currency_id)
    }
  });
}

function* watchFetchMarketplaceDetailSuccessed() {
  yield takeLatest(GET_MARKETPLACE_SUCCEEDED, fillMarketplaceDetailForm);
}

export default [
  watchEditMarketplaceRequest,
  watchGetMarketplaceRequest,
  watchDeleteMarketplaceRequest,
  watchRenderMarketplaceDetailFormRequested,
  watchFetchMarketplaceDetailSuccessed,
  watchResetMarketplaceServiceConfigurationRequest
];
