import * as _ from 'lodash';
import {
  UPDATE_INTEGRATION_TYPE_REQUESTED,
  SAVE_INTEGRATION_CONFIGURATION_REQUESTED,
  CONFIGURATION_UPDATE_CUSTOM_INTEGRATION,
  fetchMarketplaceRequested,
  INTEGRATION_EDIT_CHANGE_SETTING,
  INTEGRATION_EDIT_UPDATE_ALL_SETTING,
  INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE
} from './edit.actions';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '../../../store/store.module';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseComponent implements OnInit, OnDestroy {
  public datasetIntegrationMarkups;
  public reducer = 'Integration.edit';
  public setting = 'all';
  public allConfiguration = 'is_automatically';

  constructor(public router: Router, public activatedRoute: ActivatedRoute, store: Store) {
    super();
    this.activatedRoute = activatedRoute;
    this.store = store.getInstance();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.dispatch(fetchMarketplaceRequested({ retailer_id: this.activatedRoute.snapshot.params.id, marketplace_id: this.activatedRoute.snapshot.params.marketplace_id }));
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  changeSetting(setting) {
    this.dispatch({ type: INTEGRATION_EDIT_CHANGE_SETTING, data: setting });
    this.setting = setting;
  }

  updateAllIntegration(setting) {
    this.dispatch({ type: INTEGRATION_EDIT_UPDATE_ALL_SETTING, data: setting });
    this.allConfiguration = setting;
  }

  updateIntegrationType(marketplace, field) {
    this.store.dispatch({
      type: UPDATE_INTEGRATION_TYPE_REQUESTED,
      data: marketplace,
      marketplace_id: marketplace.id,
      field: field,
      value: !marketplace[field]
    });
  }

  updateCustomIntegration(dataset, value) {
    const is_automatically = (data => {
      switch (data) {
        case 'manually':
          return false;
        case 'automatically':
          return true;
      }
    })(value);
    const datasets = _.map(this.payload.datasets, item => {
      let auto_value = item.is_automatically;
      if (item.id === dataset.id) {
        auto_value = is_automatically;
      }
      return {
        id: item.id,
        is_automatically: auto_value
      };
    });
    this.dispatch({ type: INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE, data: datasets });
  }

  save() {
    this.store.dispatch({
      type: SAVE_INTEGRATION_CONFIGURATION_REQUESTED,
      data: this.payload.integration,
      markup: this.payload.allMarkup,
      marketplace_id: this.activatedRoute.snapshot.params.marketplace_id,
      dataset_markups: this.payload.datasetMarkups
    });
  }

  mapStateToProps(state) {
    return { payload: state.Integration.edit };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
