import { AuthGuardService } from './../../../auth/auth-guard.service';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { DetailComponent } from './detail/detail.component';
import { ListComponent } from './list/list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportComponent } from './import/import.component';
import { EventComponent } from './event/event.component';
import { AddTagComponent } from './add-tag/add-tag.component';
import { BulkPushComponent } from './bulk-push/bulk-push.component';
import { BulkDiscountComponent } from './bulk-discount/bulk-discount.component';
import { AsyncProcessComponent } from './async-process/async-process.component';
import { ModifyDescriptionComponent } from './modify-description/modify-description.component';
import { HistoryComponent } from './history/history.component';
import { RemoveBulkDiscountComponent } from './remove-bulk-discount/remove-bulk-discount.component';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: 'detail/:id',
        component: DetailComponent
      },
      {
        path: 'edit/:id',
        component: EditComponent
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: 'import',
        component: ImportComponent
      },
      {
        path: 'event',
        component: EventComponent
      },
      {
        path: 'add-tags',
        component: AddTagComponent
      },
      {
        path: 'bulk-push',
        component: BulkPushComponent
      },
      {
        path: 'bulk-discount',
        component: BulkDiscountComponent
      },
      {
        path: 'remove-bulk-discount',
        component: RemoveBulkDiscountComponent
      },
      {
        path: 'async-process',
        component: AsyncProcessComponent
      },
      {
        path: 'modify-product-description',
        component: ModifyDescriptionComponent
      },
      {
        path: 'history/:id',
        component: HistoryComponent
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {}
