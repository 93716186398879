import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';
import { GET_DETAIL_CUSTOMER_REQUESTED } from './detail.actions';
import { listRouter, editRouter } from '../customer.const';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  public store;
  public listRouter = listRouter;
  public editRouter = editRouter;
  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    this.activatedRoute = activatedRoute;
    this.store = store.getInstance();
  }

  ngOnInit() {
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({
      type: GET_DETAIL_CUSTOMER_REQUESTED,
      data: this.activatedRoute.snapshot.params.id
    });
  }

}
