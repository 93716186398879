import * as _ from 'lodash';
import {
  FETCH_BULK_OPERATION_DATA_SUCCEEDED,
  RENDER_BULK_PUSH_FORM_REQUESTED,
  UPDATE_BULK_PUSH_INPUT_OPTIONS
} from './bulk-push.actions';

export const bulkPush = (state: any = { created: false, fetched: false }, action) => {
  switch (action.type) {
    case RENDER_BULK_PUSH_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FETCH_BULK_OPERATION_DATA_SUCCEEDED:
      return _.assign({}, state, { items: action.data.items, pagination: action.data.pagination, fetched: true });
    case UPDATE_BULK_PUSH_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, input => {
          _.map(action.data, (value, key) => {
            if (input.key === key) {
              input.options = value;
            }
            return input;
          });
          return input;
        })
      });
    default:
      return state;
  }
};
