export const GET_ALL_CATEGORIES_REQUESTED = 'GET_ALL_CATEGORIES_REQUESTED';
export const GET_ALL_CATEGORIES_SUCCEEDED = 'GET_ALL_CATEGORIES_SUCCEEDED';

export const FETCH_ITEM_TYPE_REQUESTED = 'FETCH_ITEM_TYPE_REQUESTED';
export const FETCH_ITEM_TYPE_SUCCEEDED = 'FETCH_ITEM_TYPE_SUCCEEDED';

export const FETCH_SUB_CATEGORY_REQUESTED = 'FETCH_SUB_CATEGORY_REQUESTED';
export const FETCH_SUB_CATEGORY_SUCCEEDED = 'FETCH_SUB_CATEGORY_SUCCEEDED';

export const FETCH_CHILD_CATEGORY_REQUESTED = 'FETCH_CHILD_CATEGORY_REQUESTED';
export const FETCH_CHILD_CATEGORY_SUCCEEDED = 'FETCH_CHILD_CATEGORY_SUCCEEDED';
