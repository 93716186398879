export const RENDER_EDIT_USER_ADDRESS_FORM_REQUESTED = 'RENDER_EDIT_USER_ADDRESS_FORM_REQUESTED';

export const ADMIN_GET_USER_ADDRESS_REQUESTED = 'ADMIN_GET_USER_ADDRESS_REQUESTED';
export const ADMIN_GET_USER_ADDRESS_SUCCEEDED = 'ADMIN_GET_USER_ADDRESS_SUCCEEDED';

export const FILL_USER_ADDRESS_DETAIL_FORM = 'FILL_USER_ADDRESS_DETAIL_FORM';

export const UPDATE_USER_ADDRESS_REQUESTED = 'UPDATE_USER_ADDRESS_REQUESTED';
export const UPDATE_USER_ADDRESS_SUCCEEDED = 'UPDATE_USER_ADDRESS_SUCCEEDED';

export const FILL_DATA_INPUTS_OPTIONS_EDIT_ADDRESS_FORM_REQUESTED = 'FILL_DATA_INPUTS_OPTIONS_EDIT_ADDRESS_FORM_REQUESTED';
export const UPDATE_ALPHA_2_CODE_VALUE_EDIT_ADDRRESS_REQUESTED = 'UPDATE_ALPHA_2_CODE_VALUE_EDIT_ADDRRESS_REQUESTED';
