import { Component, OnInit } from '@angular/core';
import { listRouter, editRouter } from '../product.const';
import { BaseComponent } from '../../../base.component';
import { PREVIEW_PRODUCTS_FROM_CSV_REQUESTED, IMPORT_PRODUCT_FROM_CSV_REQUESTED } from './import.actions';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
declare const $: any;

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent extends BaseComponent implements OnInit {
  public reducer = 'Admin.Product.Import';
  public listRouter = listRouter();
  public detailProductRouter = editRouter;
  public uploading = {
    process: false,
    error: false
  };
  constructor(private notify: NotificationService) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  completeUpload($event) {
    this.store.dispatch({ type: PREVIEW_PRODUCTS_FROM_CSV_REQUESTED, data: { file_url: $event.response.data[0].full_path } });
  }

  errorUpload($event) {
    console.log('error', $event);
  }

  import = () => {
    if (_.isEmpty(_.filter(this.payload.items, (i) => i.selected))) {
      this.notify.show('warning', 'You must choose at least one item', 3000);
      return false;
    }
    this.store.dispatch({ type: IMPORT_PRODUCT_FROM_CSV_REQUESTED, data: { records: this.payload.items } });
    // tslint:disable-next-line:semicolon
  };

  getPreviewImage(item) {
    if (!item.images || (item.images && !item.images.length)) {
      if (!item.images_retouched) {
        return '';
      }

      if (item.images_retouched && !item.images_retouched.length) {
        return '';
      }

      return `url("${item.images_retouched[0]}")`;
    }

    return `url("${item.images[0]}")`;
  }

  mouseEnter(index) {
    $('.conflict-product-' + index).show();
  }

  mouseLeave(index) {
    $('.conflict-product-' + index).hide();
  }

  changeCheckbox($event) {
    this.updateNumSelected();
  }

  changeAllCheckbox() {
    if (this.payload.allSelected) {
      _.forEach(this.payload.items, function (item) {
        item.selected = true;
      });
      this.payload.numSelected = this.payload.items.length;
    } else {
      _.forEach(this.payload.items, function (item) {
        item.selected = false;
      });
      this.payload.numSelected = 0;
    }
  }

  updateNumSelected() {
    let numSelected = 0;
    const $this = this;
    _.forEach(this.payload.items, function (value) {
      if (value.selected) {
        numSelected += 1;
      }
    });
    this.payload.numSelected = numSelected;
  }

  mapStateToProps(state) {
    return { payload: state.Admin.Product.Import };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }

  importBtnClicked() {
    let importSection = document.getElementById('import-section');
    importSection.click();
  }
}
