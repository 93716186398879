import * as _ from 'lodash';
import {
  RENDER_EDIT_CUSTOMER_FORM_REQUESTED,
  FILL_CUSTOMER_DETAIL_FORM,
  UPDATE_EDIT_CUSTOMER_INPUT_OPTIONS,
  ADMIN_GET_CUSTOMER_SUCCEEDED,
  ADMIN_UPDATE_CUSTOMER_SUCCEEDED
} from './update.actions';
// import { UpdateFormValue } from '@reflaunt/reactive-form';
// import { UpdateInputsValue } from '@reflaunt/reactive-form';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case FILL_CUSTOMER_DETAIL_FORM:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
      }
      return state;
    default:
      return state;
  }
};

export const update = (state: any = { updated: false, fetched: false }, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_CUSTOMER_SUCCEEDED:
      return _.assign({}, state, { updated: true });
    case RENDER_EDIT_CUSTOMER_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case ADMIN_GET_CUSTOMER_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });
    case FILL_CUSTOMER_DETAIL_FORM:
      return _.assign({}, state, { inputs: _.map(state.inputs, item => Input(item, action)) });
    default:
      return state;
  }
};
