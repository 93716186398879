import { SERVICE_TYPES } from './../../../../models/ServiceType';
import {
  UPDATE_PRODUCT_SUCCEEDED,
  DELETE_PRODUCT_SUCCEEDED,
  RENDER_EDIT_PRODUCT_FORM_REQUESTED,
  GET_PRODUCT_SUCCEEDED,
  UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
  FILL_PRODUCT_DETAIL_FORM,
  EDIT_FETCH_MARKETPLACE_SUCCEEDED,
  GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED,
  GET_HISTORY_PUSH_BY_PRODUCT_ID_SUCCESSED,
  GET_PRODUCT_IMAGES_SUCCEEDED,
  GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_SUCCESSED,
  UPDATE_EDIT_PRODUCT_OUTLINES,
  PRODUCT_DATA_MODIFIED,
  ASSIGN_IMAGE_OUTLINES,
  GET_PRODUCT_TRANSLATION_SUCCESS,
  GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED,
  GET_MORE_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED,
  UPDATE_INPUTS_AFTER_CHECK_PRODUCT_ITEMS,
  FETCH_ALL_STATUS_SUCCESSED,
  UPDATE_INPUTS_RENDER_AFTER_CHECK_COLLECTION,
  PAGINATE_PRODUCT_ITEM_SUCCESSED,
  GET_PRODUCT_CLONE_HISTORY_SUCCESSED,
  UPDATE_FORM_INPUT
} from './edit.actions';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { indexOf } from 'lodash';
import { Dropdown, TextBox, support } from '@reflaunt/reactive-form';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInjector } from '../../../../app-injector';
import * as moment from 'moment';
import { COLLECTION_TYPE } from '../../../../models/Collection';
import { convertNameSize } from './edit.const';
import { Validators } from '@angular/forms';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case FILL_PRODUCT_DETAIL_FORM:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
        if (state.key === 'tags') {
          state.options = _.get(action.data, state.key);
        }
        if (state.key === 'private_images') {
          state.value = _.map(state.value, (i) => {
            i.url = i.url.split(' ').join('%20');
            return i.url;
          });
        }
        if (state.key === 'public_images') {
          state.value = _.map(state.value, (i) => {
            i.url = i.url.split(' ').join('%20');
            return i.url;
          });
        }
        if (state.key === 'images') {
          state.value = _.map(state.value, (i) => {
            i.url = i.url.split(' ').join('%20');
            return i.url;
          });
        }
        if (state.key === 'currency_id') {
          state.disabled = state.value ? true : false;
        }
        if (state.key === 'quantity' && action.userRoles && action.userRoles.find((i) => i.slug === 'logistic')) {
          state.disabled = state.value ? true : false;
        }
      }
      return state;
    default:
      return state;
  }
};

export const edit = (
  state: any = {
    updated: false,
    history_push_marketplace_id: [],
    translation: { fetched: false, item: {} },
    smart_pricer_comment: {},
    product_items: [],
    product_clone_histories: {
      items: [],
      is_original: false
    }
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { updated: true });
    case PRODUCT_DATA_MODIFIED:
      setTimeout(() => {
        if (
          action.data &&
          action.data.base_original_price &&
          action.data.categories.data.length &&
          action.data.designer &&
          action.data.condition &&
          action.data.service_type &&
          action.data.service_type.name !== 'buyback'
        ) {
          let url = window.location.origin;
          $('.reflaunt-smart-pricer').html(
            "<reflaunt-smart-pricer style='height: 80px'" +
              `rf-reflaunt-client-key="ck_b567656440e28326b3c52bd3c41bab"` +
              `rf-category="${action.data.categories.data.length > 0 ? action.data.categories.data[0].name : ''}"` +
              `rf-brand="${action.data.designer ? action.data.designer.name : ''}"` +
              `rf-condition="${action.data.condition ? action.data.condition.name.split(' ').join('_') : ''}"` +
              `rf-original-price="${action.data.base_original_price}"` +
              `rf-marketplace="videdressing"` +
              `rf-marketplace-group="all"` +
              `rf-pricer-threshold="5"` +
              `rf-pricer-mode="detailed"` +
              `rf-test-iframe-src="${url}/test/styletribute/smart-pricer/index.html"` +
              `product-id="${action.data.id}"` +
              `name-of-partner-integrated="Reflaunt"` +
              `marketplace-category="${action.data.categories.data.length > 0 ? action.data.categories.data[0].name : ''}"` +
              `price-chosen-by-user="${action.data.price.price}"` +
              `product-name="${action.data.name}"` +
              `user-email="${action.data.seller.email}"` +
              `user-name="${action.data.seller.email}"` +
              '></reflaunt-smart-pricer>'
          );
          (window as any).runReflauntSmartPricer();
        }
        if (action.data.service_type && action.data.service_type.name === 'buyback') {
          if (!action.data.upperBound && !action.data.lowerBound && action.data.price.price) {
            const minMaxRange = getMinMaxRange(action.data.price.price, state.retailerService);
            action.data.upperBound = minMaxRange.upperBound;
            action.data.lowerBound = minMaxRange.lowerBound;
          }
          $('.buyback').html(
            "<div class='row'>" +
              `<div class='col-12 min-range' style='font-weight: bold;'>Min range offered: ${action.data.lowerBound || ''}</div>` +
              `<div class='col-12 max-range' style='font-weight: bold;margin-bottom: 20px;'>Max range offered: ${action.data.upperBound || ''}</div>` +
              "<div class='col-md-12'>" +
              `<div id='${action.data.quote_accepted_at ? 'my-button-send-final-quote' : 'my-button-send-quote'}' (click)='openAlert()' class='${
                action.data.final_quote_accepted_at ? 'd-none' : ''
              }' style='cursor: pointer; padding: 9px 50px;background-color: #B0DC80;display: inline-block;color: white;margin-right: 15px'>${
                action.data.quote_accepted_at ? 'Send final quote' : 'Send first quote'
              } </div>` +
              `<div id='${action.data.quote_accepted_at ? 'my-button-reject-final-buyback' : 'my-button-reject-buyback'}' (click)='openAlert()' class='${
                action.data.final_quote_accepted_at ? 'd-none' : ''
              }' style='cursor: pointer;padding: 9px 50px;background-color: red;display: inline-block;color: white'> Reject buyback </div>` +
              '</div>' +
              '</div>'
          );
          $('.reject-button').addClass('temp-disable');
          $('.finish-later').addClass('temp-disable');
          $('button:contains("Accept")').addClass('temp-disable');
        }
      });
      return _.assign({}, state);
    case GET_PRODUCT_SUCCEEDED:
      const interval = setInterval(() => {
        if (action.data && $('.pricing-product-name') && $('.pricing-product-name')[0]) {
          $('.pricing-product-name')[0].textContent = action.data.name;
          $('.pricing-product-description')[0].textContent = action.data.description;
        }
        if (action.data && $('.sku') && $('.sku')[0]) {
          let nameSize = '';
          if (action.data.size) {
            nameSize = convertNameSize(action.data.size);
          }
          $('.rf-price').html("<div (click)='openAlert()' id='rf-price-conversion' class='rf-price-conversion'>Conversion</div>");
          $('.sku')[0].textContent = action.data.sku;
          $('.supplier_sku')[0].textContent = action.data.supplier_sku ? action.data.supplier_sku : action.data.sku;
          $('.group_sku')[0].textContent = action.data.group_sku ? action.data.group_sku : '';
          $('.name')[0].textContent = action.data.name;
          $('.price')[0].textContent = action.data.price ? action.data.price.price : '';
          // $('.payout_to_supplier')[0].textContent = action.data.price ? action.data.price.price : '';
          $('.partner_selling_price')[0].textContent = action.data.partner_selling_price ? action.data.partner_selling_price : '';
          $('.original_price')[0].textContent = action.data.original_price;
          $('.initial_price')[0].textContent = action.data.initial_price;
          $('.designer')[0].textContent = action.data.designer ? action.data.designer.name : '';
          $('.size')[0].textContent = nameSize;
          $('.heel_height')[0].textContent = action.data.heel_height;
          $('.platform_height')[0].textContent = action.data.platform_height;
          // $('.dimensions')[0].textContent = action.data.dimensions;
          $('.width')[0].textContent = action.data.width;
          $('.length')[0].textContent = action.data.length;
          $('.height')[0].textContent = action.data.height;
          $('.handle_drop')[0].textContent = action.data.handle_drop;
          $('.strap_length')[0].textContent = action.data.strap_length;
          $('.color')[0].textContent = action.data.color ? action.data.color.name : '';
          $('.upc')[0].textContent = action.data.upc ? action.data.upc : '';
          $('.material')[0].textContent = action.data.material ? action.data.material.name : '';
          $('.model')[0].textContent = action.data.model ? action.data.model.name : '';
          $('.customer_email')[0].textContent = action.data.customer_email;
          $('.category')[0].textContent = action.data.categories.data.length > 0 ? action.data.categories.data[0].name : '';
          $('.condition')[0].textContent = action.data.condition ? action.data.condition.name : '';
          $('.website')[0].textContent = action.data.website;
          $('.retailer_email')[0].textContent = action.data.retailer ? action.data.retailer.email : '';
          $('.customer_email')[0].textContent = action.data.seller ? action.data.seller.email : '';
          $('.description')[0].textContent = action.data.description ? action.data.description.replace(/<[^>]*>?/gm, '').replace('&amp;', '&') : '';
          $('.private_note')[0].textContent = action.data.private_note;
          $('.location')[0].textContent = action.data.warehouse_sku;
          $('.currency')[0].textContent = action.data.currency ? action.data.currency.code : '';
          $('.service_type_id')[0].textContent = action.data.service_type ? action.data.service_type.name : '';
          $('.collection_id')[0].textContent = action.data.collection_id ? action.data.collection_id : '';
          $('.gender')[0].textContent = action.data.gender;
          $('.quantity')[0].textContent = action.data.quantity;
          $('.age_group')[0].textContent = action.data.age_group;
          $('.supplier_name')[0].textContent = action.data.retailer && action.data.retailer.retailer ? action.data.retailer.retailer.company : '';
          $('.sign_of_wear')[0].textContent = action.data.sign_of_wear ? 'Yes' : 'No';
          $('.product_id')[0].textContent = action.data.id;
          $('.note_condition_description')[0].textContent = action.data.custom_attributes.damage_notes ? action.data.custom_attributes.damage_notes : '';
          $('.packaging')[0].textContent = action.data.custom_attributes.inclusions ? action.data.custom_attributes.inclusions : '';
          $('.heel_style')[0].textContent = action.data.custom_attributes.heel_style ? action.data.custom_attributes.heel_style : '';
          $('.waist')[0].textContent = action.data.custom_attributes.waist ? action.data.custom_attributes.waist : '';
          $('.chest')[0].textContent = action.data.custom_attributes.chest ? action.data.custom_attributes.chest : '';
          $('.arm_length')[0].textContent = action.data.custom_attributes.waist ? action.data.custom_attributes.waist : '';
          $('.authentication_id')[0].textContent = action.data.custom_attributes.authenticity_card ? action.data.custom_attributes.authenticity_card : '';
          $('.condition_description')[0].textContent = action.data.condition_description ? action.data.condition_description : '';
          $('.smart_pricing')[0].textContent =
            action.data.service_type && action.data.service_type.code === SERVICE_TYPES.SMART_BUTTON && action.data.is_auto_reduce_price ? 'Yes' : 'No';
          $('.retailer_category')[0].textContent =
            action.data.product_request && action.data.product_request.data && action.data.product_request.data.category
              ? _.startCase(action.data.product_request.data.category.toLowerCase())
              : '';
          $('.retailer_size')[0].textContent =
            action.data.product_request && action.data.product_request.data && action.data.product_request.data.category
              ? action.data.categories &&
                _.startCase(_.compact(_.concat(action.data.categories.gender, action.data.categories.sizing_type, action.data.product_request.data.size)).toString().toLowerCase())
              : action.data.product_request && action.data.product_request.data.size
              ? _.startCase(action.data.product_request.data.size.toLowerCase())
              : '';
          $('.retailer_condition')[0].textContent =
            action.data.product_request && action.data.product_request.data && action.data.product_request.data.condition
              ? _.startCase(action.data.product_request.data.condition.toLowerCase())
              : '';
          $('.retaler_material')[0].textContent =
            action.data.product_request && action.data.product_request.data && action.data.product_request.data.material
              ? _.startCase(action.data.product_request.data.material.toLowerCase())
              : '';
          $('.retailer_color')[0].textContent =
            action.data.product_request && action.data.product_request.data && action.data.product_request.data.color
              ? _.startCase(action.data.product_request.data.color.toLowerCase())
              : '';
          $('.retailer_designer')[0].textContent =
            action.data.product_request && action.data.product_request.data && action.data.product_request.data.designer
              ? _.startCase(action.data.product_request.data.designer.toLowerCase())
              : '';
          $('.warehouse_reject_reason')[0].textContent = action.data.warehouse_rejection_reason || '';

          clearInterval(interval);
        }
      });

      const product_item_managements = action.data.product_item_managements;
      const status_sold = _.find(product_item_managements, (e) => e.status === 1);
      const available_quantity = _.filter(action.product_items, (e) => e.status_id !== 5).length;
      return _.assign({}, state, {
        fetched: true,
        product_items: action.product_items,
        locations: action.locations,
        currencies: action.currencies,
        item: _.assign(action.data, { status_sold: status_sold ? true : false, product_sold: status_sold, available_quantity: available_quantity }),
        retailerService: action.retailerService
      });
    case GET_PRODUCT_IMAGES_SUCCEEDED:
      const highlight = action.highlight;
      const privateImages = action.data.filter((e) => e.is_private);
      const publicImages = action.data.filter((e) => !e.is_private);
      const product = state.item;
      if ((product && product.service_type && product.service_type.name !== 'buyback') || !product.service_type) {
        _.remove(state.inputs, (input: any) => input.key === 'private-images__title' || input.key === 'image_private_zoom');
      }

      if (highlight === 'price') {
        const imageCarousel = _.find(state.inputs, (input) => input.key === 'images_carousel');
        const ImagePrivateZoom = _.find(state.inputs, (input) => input.key === 'image_private_zoom');
        const ImagePublicZoom = _.find(state.inputs, (input) => input.key === 'image_public_zoom');
        if (ImagePrivateZoom) {
          imageCarousel.imageObjects = [];
          ImagePrivateZoom.imageObjects = [];
          if (privateImages && privateImages.length > 0) {
            _.forEach(privateImages, (image) => {
              imageCarousel.imageObjects.push({
                small: image.thumbnail300x300,
                medium: image.original_url,
                big: image.original_url
              });
              ImagePrivateZoom.imageObjects.push({
                small: image.thumbnail300x300,
                medium: image.original_url,
                big: image.original_url
              });
            });
          }
        }
        if (ImagePublicZoom) {
          imageCarousel.imageObjects = [];
          ImagePublicZoom.imageObjects = [];
          if (publicImages && publicImages.length > 0) {
            _.forEach(publicImages, (image) => {
              imageCarousel.imageObjects.push({
                small: image.thumbnail300x300,
                medium: image.original_url,
                big: image.original_url
              });
              ImagePublicZoom.imageObjects.push({
                small: image.thumbnail300x300,
                medium: image.original_url,
                big: image.original_url
              });
            });
          }
        }
      }
      return _.assign({}, state, { images: action.data, privateImages: privateImages });
    case DELETE_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { deleted: true });
    case RENDER_EDIT_PRODUCT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case UPDATE_INPUTS_AFTER_CHECK_PRODUCT_ITEMS:
      const index = _.indexOf(
        state.inputs,
        _.find(state.inputs, (e) => e.label === 'Payout to supplier')
      );
      const indexAdded = _.indexOf(
        state.inputs,
        _.find(state.inputs, (e) => e.key === 'change_all_product_item_price')
      );
      if (indexAdded < 0 && !action.data) {
        const options = [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ];
        state.inputs.splice(
          index + 1,
          0,
          new Dropdown({
            key: 'change_all_product_item_price',
            label: 'change the prices of all items?',
            classes: ['col-6', 'rf-form-group', 'custom-css'],
            group_classes: ['col-12', 'group'],
            group: 33,
            options: options,
            value: _.find(options, (e) => !e.value)
          })
        );
      }
      if (indexAdded > 0 && action.data) {
        state.inputs.splice(indexAdded, 1);
      }
      return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_EDIT_PRODUCT_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (input) => {
          _.map(action.data, (value, key) => {
            if (input && input.key === key) {
              input.options = value;
            }
            return input;
          });
          return input;
        })
      });
    case UPDATE_EDIT_PRODUCT_OUTLINES:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (input) => {
          _.map(action.data, (value, key) => {
            if (input.key === key) {
              input.outlines = value;
            }
            return input;
          });
          return input;
        })
      });
    case ASSIGN_IMAGE_OUTLINES:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (input) => {
          if (input.key === 'private_images') {
            let privateImages = action.data.filter((e) => e.is_private);
            if (input.outlines && input.outlines.length > 0) {
              input.outlines = input.outlines
                .map((outline) => {
                  const imagesFiltered = privateImages.filter((image) => outline.outline === image.label && outline.outline);
                  if (imagesFiltered.length > 0) {
                    let selectedImage = imagesFiltered.find((image) => image.retouched === true);
                    if (!selectedImage) {
                      selectedImage = imagesFiltered[0];
                    }
                    outline.outline = selectedImage.label;
                    outline.url = selectedImage.original_url;
                    outline.thumbnailUrl = selectedImage.thumbnail;
                    outline.id = selectedImage.id;
                    outline.is_retouch_allowed = selectedImage.status === 'NONPROCESS';
                    outline.retouched = selectedImage.retouched;
                    outline.is_stock_image = selectedImage.is_stock_image;

                    privateImages = privateImages.filter((image) => image.id !== selectedImage.id);
                  }
                  return outline;
                })
                .concat(
                  privateImages.map((image) => ({
                    outline: image.label,
                    url: image.original_url,
                    thumbnailUrl: image.thumbnail,
                    id: image.id,
                    is_retouch_allowed: image.status === 'NONPROCESS',
                    retouched: image.retouched,
                    is_stock_image: image.is_stock_image
                  }))
                );
            } else {
              _.map(privateImages, (image) => {
                if (!input.outlines) {
                  input.outlines = [
                    {
                      outline: image.label,
                      url: image.original_url,
                      thumbnailUrl: image.thumbnail,
                      is_retouch_allowed: image.status === 'NONPROCESS',
                      retouched: image.retouched,
                      is_stock_image: image.is_stock_image,
                      id: image.id
                    }
                  ];
                } else {
                  input.outlines.push({
                    outline: image.label,
                    url: image.original_url,
                    thumbnailUrl: image.thumbnail,
                    is_retouch_allowed: image.status === 'NONPROCESS',
                    retouched: image.retouched,
                    is_stock_image: image.is_stock_image,
                    id: image.id
                  });
                }
              });
            }
          }
          if (input.key === 'public_images') {
            let publicImages = action.data.filter((e) => !e.is_private);
            if (input.outlines && input.outlines.length > 0) {
              input.outlines = input.outlines
                .map((outline) => {
                  const imagesFiltered = publicImages.filter((image) => outline.outline === image.label && outline.outline);
                  if (imagesFiltered.length > 0) {
                    let selectedImage = imagesFiltered.find((image) => image.retouched === true);
                    if (!selectedImage) {
                      selectedImage = imagesFiltered[0];
                    }
                    outline.outline = selectedImage.label;
                    outline.url = selectedImage.original_url;
                    outline.thumbnailUrl = selectedImage.thumbnail;
                    outline.id = selectedImage.id;
                    outline.is_retouch_allowed = selectedImage.status === 'NONPROCESS';
                    outline.retouched = selectedImage.retouched;
                    outline.is_stock_image = selectedImage.is_stock_image;

                    publicImages = publicImages.filter((image) => image.id !== selectedImage.id);
                  }
                  return outline;
                })
                .concat(
                  publicImages.map((image) => ({
                    outline: image.label,
                    url: image.original_url,
                    thumbnailUrl: image.thumbnail,
                    id: image.id,
                    is_retouch_allowed: image.status === 'NONPROCESS',
                    retouched: image.retouched,
                    is_stock_image: image.is_stock_image
                  }))
                );
            } else {
              _.map(publicImages, (image) => {
                if (!input.outlines) {
                  input.outlines = [
                    {
                      outline: image.label,
                      url: image.original_url,
                      thumbnailUrl: image.thumbnail,
                      is_retouch_allowed: image.status === 'NONPROCESS',
                      retouched: image.retouched,
                      is_stock_image: image.is_stock_image,
                      id: image.id
                    }
                  ];
                } else {
                  input.outlines.push({
                    outline: image.label,
                    url: image.original_url,
                    thumbnailUrl: image.thumbnail,
                    is_retouch_allowed: image.status === 'NONPROCESS',
                    retouched: image.retouched,
                    is_stock_image: image.is_stock_image,
                    id: image.id
                  });
                }
              });
            }
          }
          if (input.key === 'images') {
            let images = action.data.filter((e) => !e.is_private);
            console.log('ASSIGN_IMAGE_OUTLINES', images);
            if (input.outlines && input.outlines.length > 0) {
              input.outlines = input.outlines
                .map((outline) => {
                  const imagesFiltered = images.filter((image) => outline.outline === image.label && outline.outline);
                  if (imagesFiltered.length > 0) {
                    let selectedImage = imagesFiltered.find((image) => image.retouched === true);
                    if (!selectedImage) {
                      selectedImage = imagesFiltered[0];
                    }
                    outline.outline = selectedImage.label;
                    outline.url = selectedImage.original_url;
                    outline.thumbnailUrl = selectedImage.thumbnail;
                    outline.id = selectedImage.id;
                    outline.is_retouch_allowed = selectedImage.status === 'NONPROCESS';
                    outline.retouched = selectedImage.retouched;
                    outline.is_stock_image = selectedImage.is_stock_image;

                    images = images.filter((image) => image.id !== selectedImage.id);
                  }
                  return outline;
                })
                .concat(
                  images.map((image) => ({
                    outline: image.label,
                    url: image.original_url,
                    thumbnailUrl: image.thumbnail,
                    id: image.id,
                    is_retouch_allowed: image.status === 'NONPROCESS',
                    retouched: image.retouched,
                    is_stock_image: image.is_stock_image
                  }))
                );
            } else {
              _.map(images, (image) => {
                if (!input.outlines) {
                  input.outlines = [
                    {
                      outline: image.label,
                      url: image.original_url,
                      thumbnailUrl: image.thumbnail,
                      is_retouch_allowed: image.status === 'NONPROCESS',
                      retouched: image.retouched,
                      is_stock_image: image.is_stock_image,
                      id: image.id
                    }
                  ];
                } else {
                  input.outlines.push({
                    outline: image.label,
                    url: image.original_url,
                    thumbnailUrl: image.thumbnail,
                    is_retouch_allowed: image.status === 'NONPROCESS',
                    retouched: image.retouched,
                    is_stock_image: image.is_stock_image,
                    id: image.id
                  });
                }
              });
            }
            console.log('ASSIGN_IMAGE_OUTLINES_INPUT', input);
          }
          return input;
        })
      });
    case FILL_PRODUCT_DETAIL_FORM:
      const i = setInterval(() => {
        if ($('.tags').length > 0) {
          if (action.data.tags.length > 0) {
            let tags = '';
            for (const e of action.data.tags) {
              if (e === action.data.tags[action.data.tags.length - 1]) {
                if (e.selected) {
                  tags = tags + e.name + '.';
                }
              } else {
                if (e.selected) {
                  tags = tags + e.name + ', ';
                }
              }
            }
            $('.tags')[0].textContent = tags;
          }
          clearInterval(i);
        }
      });

      const color_changed_at_index_input = _.indexOf(
        state.inputs,
        _.find(state.inputs, (e) => e.key === 'color_changed_at')
      );

      if (color_changed_at_index_input > -1 && action.data.service_type_id && action.data.service_type_id.code !== 'takeback') {
        state.inputs.splice(color_changed_at_index_input, 1);
      }
      if (action.is_pricing_collection_page) {
        updateInputInstantRewardPrice(state.inputs, action.data.is_eligible_for_instant_reward);
      }
      return _.assign({}, state, {
        inputs: _.map(state.inputs, (item) => Input(item, action))
      });
    case EDIT_FETCH_MARKETPLACE_SUCCEEDED:
      return _.assign({}, state, { fetched: true, all_marketplaces: action.data, pagination: action.pagination });
    case GET_TRANSACTION_BY_PRODUCT_ID_SUCCESSED:
      const transaction = action.data.length > 0 ? _.filter(action.data, (e) => e.parent_id === 0) : undefined;
      if (transaction && transaction.length > 0) {
        for (const item of transaction) {
          if ((item as any).order_product) {
            if ((item as any).order_product.shipments && (item as any).order_product.shipments.length > 0) {
              const parent_shipment = _.find((item as any).order_product.shipments, (e) => !e.parent_id);
              if (parent_shipment) {
                _.assign(item, { parent_shipment_id: parent_shipment.id });
              }
            }
            if ((item as any).order_product.shipment_manys && (item as any).order_product.shipment_manys.data.length > 0) {
              const parent_shipment = _.find((item as any).order_product.shipment_manys.data, (e) => !e.parent_id);
              if (parent_shipment) {
                _.assign(item, { parent_shipment_id: parent_shipment.id });
              }
            }
          }
        }
      }

      return _.assign({}, state, { transaction: transaction });
    case GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_SUCCESSED:
      let marketplaces = state.all_marketplaces && state.all_marketplaces.filter(
        (marketplace) =>
          !action.publishable_product_item_managements.find((product_item_management) => product_item_management.user_id === marketplace.id) &&
          marketplace &&
          marketplace.marketplace.is_integrated === 1
      );
      action.publishable_product_item_managements.forEach((e) => {
        const payout_to_rf = e.partner_price * 0.95;
        if (e.partner_price) {
          _.assign(e, { payout_to_rf: payout_to_rf });
        }
        if (e.currency_pair_rate) {
          const payout_to_supplier = state.item.price.price;
          const est_profit = Math.round((payout_to_rf - e.currency_pair_rate * payout_to_supplier) * 100) / 100;
          _.assign(e, { est_profit: !_.isNaN(est_profit) ? est_profit : 0 });
        }
        _.assign(e, { is_listing_price_fixed: e.user_inputted_price ? true : false });
      });
      if (action.publishable_product_item_managements && action.publishable_product_item_managements.length > 0) {
        action.publishable_product_item_managements.sort((a, b) =>
          _.lowerCase(a.user.marketplace && a.user.marketplace.company ? a.user.marketplace.company : a.user.email) <
          _.lowerCase(b.user.marketplace && b.user.marketplace.company ? b.user.marketplace.company : b.user.email)
            ? -1
            : 1
        );
      }
      return _.assign({}, state, {
        product_item_managements_history: action.all_product_item_managements,
        product_item_managements: action.publishable_product_item_managements,
        product_item_managements_pagination: action.product_item_managements_pagination,
        marketplaces
      });

    case GET_PRODUCT_CLONE_HISTORY_SUCCESSED:
      const is_original = action.product_clone_histories.filter((e) => e.cloned_from === Number(action.product_id)).length > 0;
      return _.assign({}, state, { product_clone_histories: { items: action.product_clone_histories, is_original } });

    case GET_HISTORY_PUSH_BY_PRODUCT_ID_SUCCESSED:
      const history_push_marketplace_id = [];
      action.data.forEach((element) => {
        history_push_marketplace_id.push(element.user_id);
      });
      return _.assign({}, state, { historyPush: action.data, history_push_marketplace_id: history_push_marketplace_id });
    case GET_PRODUCT_TRANSLATION_SUCCESS:
      return _.assign({}, state, { translation: { fetched: true, item: action.data } });
    case GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED:
      return _.assign({}, state, { product_items: action.data.items });
    case FETCH_ALL_STATUS_SUCCESSED:
      return _.assign({}, state, { statuses: action.data });
    case GET_MORE_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED:
      let disable_load_more = true;
      const total = action.data.pagination.total;
      const current_page = action.data.pagination.current_page;
      const quantity = 20;
      if (total >= quantity) {
        disable_load_more = false;
      }
      const product_items = _.uniqBy(_.concat(state.product_items, action.data.items), 'id');
      if (product_items.length === total) {
        disable_load_more = true;
      }
      const per_page = action.data.pagination.per_page;

      return _.assign({}, state, { product_items: product_items, total: total, current_page: current_page, disable_load_more: disable_load_more, per_page: per_page });

    case PAGINATE_PRODUCT_ITEM_SUCCESSED:
      return _.assign({}, state, { product_item_managements_history: action.data.items, product_item_managements_pagination: action.data.pagination });

    case 'SAVE_COMMENT_FROM_SMART_PRICER':
      return _.assign({}, state, { smart_pricer_comment: action.data });
    case UPDATE_INPUTS_RENDER_AFTER_CHECK_COLLECTION:
      let inputs = state.inputs;
      const { collection } = action;
      if (collection.type === COLLECTION_TYPE.BUYBACK) {
        inputs = inputs.filter((i) => !['base_original_price', 'original_price'].includes(i.key));
        setTimeout(() => {
          if ($('.dynamic-submit-btn').length > 0) {
            $('.dynamic-submit-btn').css('display', 'none');
          }
        }, 2000);
      }
      return _.assign({}, state, { inputs: inputs });
    case 'PRODUCT_DELETED_ACTION':
      return _.assign({}, state, { is_deleted: true });
    case UPDATE_FORM_INPUT:
      updateInputInstantRewardPrice(state.inputs, action.value);
      return _.assign({}, state, {
        inputs: support.UpdateFormValue(state.inputs, {
          instant_reward_price: action.value === 'yes' ? state.item.instant_reward_price || Math.floor(state.item.price.price * 0.8) : null,
          is_eligible_for_instant_reward: action.value
        }),
        updatedForm: true
      });
    default:
      return state;
  }
};

function getMinMaxRange(value, retailerService) {
  const productPrice = Number(value);
  let lowerBound;
  let upperBound;
  if (retailerService && productPrice) {
    lowerBound = _.max([
      Number(productPrice - retailerService.quote_max_range_difference),
      Number((productPrice * (1 - retailerService.quote_lower_range_percentage / 100)).toFixed(2))
    ]);
    upperBound = _.min([
      Number(productPrice + retailerService.quote_max_range_difference),
      Number((productPrice * (1 + retailerService.quote_upper_range_percentage / 100)).toFixed(2))
    ]);
  }
  return { lowerBound, upperBound };
}

function updateInputInstantRewardPrice(inputs, value) {
  const indexOfPayoutToOriginalSeller = _.indexOf(
    inputs,
    _.find(inputs, (e) => e.label === 'PAYOUT TO original seller *')
  );
  const indexOfInstandRewardToAdded = _.indexOf(
    inputs,
    _.find(inputs, (e) => e.label === 'Instant Reward Payout *')
  );
  if (indexOfInstandRewardToAdded < 0 && value === 'yes') {
    inputs.splice(
      indexOfPayoutToOriginalSeller + 1,
      0,
      new TextBox({
        key: 'instant_reward_price',
        label: 'Instant Reward Payout *',
        classes: ['col-6', 'rf-form-group'],
        group_classes: ['col-12'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      })
    );
  }
  if (indexOfInstandRewardToAdded > -1 && value === 'no') {
    inputs.splice(indexOfInstandRewardToAdded, 1);
  }
}
