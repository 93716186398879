import { Router } from '@angular/router';
import { FETCH_LANGUAGE_REQUESTED } from './../list/list.actions';
import { DELETE_LANGUAGE_REQUESTED, GET_LANGUAGE_REQUESTED, GET_LANGUAGE_SUCCEEDED, EDIT_LANGUAGE_REQUESTED } from './edit.actions';
import { takeEvery, put } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';

function* editLanguage(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.language.update(action.data.id, action.data).toPromise();
    router.navigate(['/admin/language']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditLanguageRequest() {
  yield takeEvery(EDIT_LANGUAGE_REQUESTED, editLanguage);
}

function* getLanguage(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.language.getItemById(action.data).toPromise();
    yield put({ type: GET_LANGUAGE_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetLanguageRequest() {
  yield takeEvery(GET_LANGUAGE_REQUESTED, getLanguage);
}

function* deleteLanguage(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.language.delete(action.data).toPromise();
    yield put({ type: FETCH_LANGUAGE_REQUESTED });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteLanguageRequest() {
  yield takeEvery(DELETE_LANGUAGE_REQUESTED, deleteLanguage);
}

export default [watchEditLanguageRequest, watchGetLanguageRequest, watchDeleteLanguageRequest];
