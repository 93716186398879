import { FETCH_RETAILERS_SUCCEEDED, SORT_RETAILERS_SUCCEEDED } from './list.actions';
import * as _ from 'lodash';

export const list = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case FETCH_RETAILERS_SUCCEEDED:
      if (action.sortQueryParams) {
        if (action.sortQueryParams === '-retailer_company') {
          action.data.sort((a, b) =>
            _.lowerCase(a.retailer && a.retailer.company ? a.retailer.company : a.email) < _.lowerCase(b.retailer && b.retailer.company ? b.retailer.company : b.email) ? 1 : -1
          );
        } else if (action.sortQueryParams === 'retailer_company') {
          action.data.sort((a, b) =>
            _.lowerCase(a.retailer && a.retailer.company ? a.retailer.company : a.email) < _.lowerCase(b.retailer && b.retailer.company ? b.retailer.company : b.email) ? -1 : 1
          );
        }
      }
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });

    case SORT_RETAILERS_SUCCEEDED:
      return _.assign({}, state, { fetched: true, items: action.data, total: action.total });

    default:
      return state;
  }
};
