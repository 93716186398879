export const RETAILER_PRODUCT_COMP = 'RETAILER_PRODUCT_COMP';

export const listRouter = id => {
  return ['/', 'retailer', id, 'product'];
};
export const createRouter = id => {
  return ['/', 'retailer', id, 'product', 'create'];
};

export const editRouter = (idRetailer, idProduct) => {
  return ['/', 'retailer', idRetailer, 'product', 'edit', idProduct];
};

export const importRouterRetailer = (idRetailer) => {
  return ['/', 'retailer', idRetailer, 'product', 'import'];
};

