import Model from './Model';
import * as moment from 'moment';

class Notification extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }

  getId() {
    return (this as any).messageId.S;
  }

  getUrl() {
    return (this as any).s3Url.S ? (this as any).s3Url.S : '';
  }

  getCreatedAt() {
    const timestamp = (this as any).createdAt.S;
    return moment(timestamp).format('DD-MM-YYYY HH:mm:ss');
  }

  getRecipientValue() {
    return (this as any).recipient.S;
  }
}

export default Notification;
