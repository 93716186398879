import { AppInjector } from './../../app-injector';
import { ApiUrl } from './../api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Provider from '../../models/Provider';

@Injectable()
export class ProviderService extends ServiceProvider {

  public url = '/api/v1/provider';
  public model = Provider;

}
