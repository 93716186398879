import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-config-marketplace',
  templateUrl: './config-marketplace.component.html',
  styleUrls: ['./config-marketplace.component.scss']
})
export class ConfigMarketplaceComponent extends BaseComponent implements OnInit {
  public reducer: String = 'Integration.ConfigMarketplace';

  constructor(private router: Router) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        // dispatch an action
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return {
      payload: state.Integration.ConfigMarketplace
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }
}
