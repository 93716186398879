import { FETCH_ALL_ADMIN_LOCATION_SUCCESSED, FETCH_ALL_ADMIN_LOCATION_REQUESTED } from './list.actions';
import * as _ from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchFetchLocationsRequest() {
  yield takeEvery(FETCH_ALL_ADMIN_LOCATION_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.location.get(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_LOCATION_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchFetchLocationsRequest
];
