import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

import { ADMIN_ATTRIBUTES_RETAILER_SELECT_ANY, ADMIN_ATTRIBUTES_RETAILER_FILTER_NONE } from '../attributes-retailer.action';
import {
  FETCH_RETAILER_CONDITIONS_SUCCESS,
  ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_CONDITION_REQUESTED,
  TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_REQUESTED,
  TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED,
  DELETE_RETAILER_CONDITION_MATCHING_REQUESTED,
  ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_CONDITION_REQUESTED
} from './condition.action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { Router } from '@angular/router';

function* watchSelectRetailerFilterNone() {
  yield takeEvery(ADMIN_ATTRIBUTES_RETAILER_FILTER_NONE, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.dictionary.get(action.data).toPromise();
      yield put({ type: FETCH_RETAILER_CONDITIONS_SUCCESS, data: result.items, pagination: result.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* toggleConditionDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_REQUESTED, toggleConditionDictionaryValidation);
}

function* toggleConditionDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_CONDITION_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchSelectAnyFilterRetailer() {
  yield takeEvery(ADMIN_ATTRIBUTES_RETAILER_SELECT_ANY, function* (action: any) {
    const attribute = yield select((state: any) => state.Admin.AttributesRetailer.all);
    if (action.component && action.component === 'condition') {
      try {
        const api = AppInjector.get(ApiService);
        let params = {
          constraints: JSON.stringify({
            marketplace_id: attribute.retailer.inputValue ? attribute.retailer.inputValue.id : null,
            type: 'conditions',
            ...(action.constraints ? action.constraints : {})
          }),
          search: attribute.retailer.searchValue || '',
          page: action.data ? action.data.page || 1 : 1
        };
        if (action.requestData) {
          params = _.assign({}, params, action.requestData);
        }
        if (action.filter) {
          params = _.assign({}, params, { filter: action.filter });
        }
        const result = yield api.admin.dictionary.get(params).toPromise();
        const rf_condtions = yield api.admin.condition.get({ per_page: 10000 }).toPromise();
        yield put({ type: FETCH_RETAILER_CONDITIONS_SUCCESS, data: result.items, rf_condtions: rf_condtions.items, pagination: result.pagination });
      } catch (e) {
        yield put({ type: API_CALL_ERROR, error: e });
      }
    }
  });
}

function* watchAdminUpdateAttrRetailer() {
  yield takeEvery(ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_REQUEST, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      yield api.admin.dictionary.update(action.data.dictionary.id, _.assign({}, action.data.dictionary, { attribute_id: action.data.dictionary.attrDefaultMap.id })).toPromise();
      yield put({ type: ADMIN_ATTRIBUTES_RETAILER_CONDITION_UPDATE_DICTIONARY_SUCCESS, data: action.data.dictionary });
      AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchActualizeConditionsRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_CONDITION_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.admin.dictionary.actualize(action.data).toPromise();
      const async = yield AppInjector.get(ApiService).asyncProcess.checkAsyncStatus(result.id, 10000).toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteConditionRequest() {
  yield takeLatest(DELETE_RETAILER_CONDITION_MATCHING_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notification = AppInjector.get(NotificationService);
    try {
      let result = yield api.admin.dictionary.put(action.data).toPromise();
      notification.show('success', 'Successfully un-matched dictionary', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSynchroniseConditionsRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_RETAILER_SYNCHRONISE_CONDITION_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.admin.dictionary.synchronise(action.data).toPromise();
      const async = yield AppInjector.get(ApiService).asyncProcess.checkAsyncStatus(result.id, 10000).toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(Router).navigate([]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteConditionRequest,
  watchSelectAnyFilterRetailer,
  watchAdminUpdateAttrRetailer,
  watchActualizeConditionsRequest,
  toggleConditionDictionaryValidationRequested,
  watchSelectRetailerFilterNone,
  watchSynchroniseConditionsRequest
];
