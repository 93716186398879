import { FETCH_ALL_ADMIN_FULFILMENT_SUCCESSED, UPDATE_INPUT_FILTER_DATE_FULFILMENT } from './list.actions';
import * as _ from 'lodash';

export const list = (state = { fetched: false, items: [], filter_date: [], queryParams: {} }, action) => {
  switch (action.type) {
    case FETCH_ALL_ADMIN_FULFILMENT_SUCCESSED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination, queryParams: action.queryParams });
    case UPDATE_INPUT_FILTER_DATE_FULFILMENT:
      action.inputs = _.map(action.inputs, (i) => {
        if (i.key === 'date_filter_type') {
          if (action.selected) {
            const default_value = _.find(i.options, (e) => e.value === action.selected);
            i.value = default_value;
          } else {
            i.value = _.head(i.options);
          }
        }
        return i;
      });

      return _.assign({}, state, { filter_date: action.inputs });
    default:
      return state;
  }
};
