import { PipesModule } from '../../common/pipes/pipes.module';
import { AttributeRoutingModule } from './attribute-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../common/directives/directives.module';
import { AttributeComponent } from './attribute.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../../template/shared/shared.module';
import { AngularReactiveFormModule } from '@reflaunt/reactive-form';

@NgModule({
  imports: [CommonModule, AttributeRoutingModule, FormsModule, PipesModule, DirectivesModule, SweetAlert2Module, SharedModule, AngularReactiveFormModule],
  declarations: [AttributeComponent, CreateComponent, EditComponent, ListComponent]
})
export class AttributeModule {}
