import { DELETE_DATASET_REQUESTED } from './../edit/edit.actions';
import { FETCH_DATASETS_REQUESTED, SORT_DATASETS_REQUESTED } from './list.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from './../../../../store/store.module';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { listProductRouter } from '../../retailer.const';
import { QueryParser } from '../../../../common/util/query-parser';
import { addToGroupRouter, createGroupRouter, editGroupRouter, listGroupRouter, listRouter } from '../dataset.const';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  protected navigationSubscription: any;
  protected router: any;
  public store;
  public listProductRouter = listProductRouter;
  public createGroupRouter = createGroupRouter;
  public listGroupRouter = listGroupRouter;
  public addToGroupRouter = addToGroupRouter;
  public editGroupRouter = editGroupRouter;
  public listGroupTypes = [
    {
      label: 'All'
    },
    {
      label: 'Group',
      value: 'group'
    },
    {
      label: 'Normal',
      value: 'normal'
    }
  ];
  public groupType = 'undefined';
  private is_skip_cache = true;
  private includes = 'children_datasets';

  constructor(private queryParser: QueryParser, store: Store, private activedRoute: ActivatedRoute, router: Router) {
    this.store = store.getInstance();
    this.activedRoute = activedRoute;
    this.router = router;
    const { is_skip_cache, includes } = this;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.groupType = 'undefined';
        const data: any = Object.assign(this.queryParser.parse(['sort', 'page', 'per_page', 'constraints', 'search']), {
          is_skip_cache,
          includes
        });
        if (data.constraints) {
          const constraints = JSON.parse(data.constraints);
          this.groupType = constraints.group_type;
        }

        this.store.dispatch({ type: FETCH_DATASETS_REQUESTED, data });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  delete(id) {
    const { is_skip_cache, includes } = this as any;
    this.store.dispatch({ type: DELETE_DATASET_REQUESTED, data: id, queryParams: { is_skip_cache, includes } });
  }

  filterGroupType() {
    let queryParams = Object.assign({}, this.activedRoute.snapshot.queryParams);
    const payload = {
      group_type: this.groupType
    };
    queryParams = Object.assign({}, queryParams, { constraints: JSON.stringify(payload) });
    if (this.groupType === 'undefined') {
      delete queryParams.constraints;
    }
    this.router.navigate(listRouter(), { queryParams });
  }
}
