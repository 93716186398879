import { EditOrderItem } from './edit-order-item/edit-order-item.reducer';
import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { list } from './list/list.reducer';
import { detail } from './detail/detail.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { GET_ALL_ADMIN_ORDER_SUCCESSED } from './order.action';
import { EDIT_ADMIN_ORDER_SUCCESSED, DELETE_ADMIN_ORDER_SUCCESSED } from './edit/edit.actions';
import { CREATE_ADMIN_ORDER_SUCCESSED} from './create/create.actions';
const all = (state = { fetched: false, items: []}, action) => {
  switch (action.type) {
    case CREATE_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, { fetched: false });
    case EDIT_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, { fetched: false });
    case DELETE_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, { fetched: false });
    case GET_ALL_ADMIN_ORDER_SUCCESSED:
      return _.assign({}, state, {
        fetched: true,
        items: [...action.data]
      });
    default:
      return state;
  }
};
export const Order = combineReducers({EditOrderItem, 
  list, detail, edit, create, all
});
