import { ItemsCostService } from './items-cost/items-cost.service';
import { LocationService } from './location/location.service';
import { OrderProductService } from './order-product/order-product.service';
import { TransactionService } from './transaction/transaction.service';
import { NotificationService } from './notification/notification.service';
import { CollectionService } from './collection/collection.service';
import { ApplicationService } from './application/application.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './user/user.service';
import { AdminApiService } from './admin.service';
import { DesignerService } from './designer/designer.service';
import { DictionaryService } from './dictionary/dictionary.service';
import { ProductService } from './product/product.service';
import { TagService } from './tag/tag.service';
import { EmailService } from './email/email.service';
import { AddressService } from './address/address.service';
import { ShipmentService } from './shipment/shipment.service';
import { TransactionHistoryService } from './transaction-history/transaction-history.service';
import { ActivityLogService } from './activity-log/activity-log.service';
import { ProductItemService } from './product-item/product-item.service';
import { CategoryService } from './category/category.service';
import { SizeService } from './size/size.service';
import { ColorService } from './color/color.service';
import { ConditionService } from './condition/condition.service';
import { ShippingMethodService } from './shipping-method/shipping-method.service';
import { IntegrationService } from './integration/integration.service';
import { EndpointService } from './endpoint/endpoint.service';
import { OAuthClientService } from './oauth-client/oauth-client';
import { IssueTrackingService } from './issue-tracking/issue-tracking.service';
import { NotificationActionService } from './notification-action/notification-action.service';
import { IntegrationNotification } from './Integration-notification/Integration-notification.service';
import { ImageService } from './image/image.service';
import { ProductManagementService } from './product-management/product-management.service';
import { DataService } from './dataset/dataset.service';
import { MaterialService } from './material/material.service';
import { HistoryPushService } from './history-push/history_push.service';
import { SmartObjectService } from './smart-object/smart-object.service';
import { PaymentService } from './payment/payment.service';
import { TransactionLogService } from './transaction-log/transaction-log.service';
import { OrderService } from './order/order.service';
import { CommunicationService } from './communication/communication.service';
import { CourierService } from './courier/courier.service';
import { LanguageService } from './language/language.service';
import { marketplaceRetailerConfigService } from './marketplace-retailer-config/marketplace-retailer-config.service';
import { TranslationService } from './translation/translation.service';
import { ShipmentAddressService } from './shipment-address/shipment-address.service';
import { ProductTranslationService } from './product-translation/product-translation.service';
import { ServiceTypeService } from './service-type/service-type.service';
import { MagicService } from './magic/magic.service';
import { NegotiationService } from './negotiation/negotiation.service';
import { GroupedPaymentService } from './grouped-payment/grouped-payment.service';
import { ProductHistoryService } from './product-history/product-history.service';
import { ProductCloneHistoryService } from './product-clone-history/product-clone-history.service';
import { ProductCatalogueService } from './product-catalogue/product-catalogue.service';
import { CustomsCostService } from './items-cost/customs-cost.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    ItemsCostService,
    LocationService,
    OrderProductService,
    TransactionService,
    NotificationService,
    TransactionLogService,
    PaymentService,
    CollectionService,
    ProductManagementService,
    ApplicationService,
    AdminApiService,
    UserService,
    DesignerService,
    DictionaryService,
    ProductService,
    ImageService,
    marketplaceRetailerConfigService,
    TagService,
    EmailService,
    MagicService,
    AddressService,
    ShipmentService,
    TransactionHistoryService,
    ActivityLogService,
    ProductItemService,
    ConditionService,
    ColorService,
    CategoryService,
    SizeService,
    ShippingMethodService,
    IntegrationService,
    EndpointService,
    OAuthClientService,
    IssueTrackingService,
    NotificationActionService,
    IntegrationNotification,
    DataService,
    HistoryPushService,
    MaterialService,
    SmartObjectService,
    OrderService,
    CommunicationService,
    CourierService,
    LanguageService,
    TranslationService,
    ProductTranslationService,
    ShipmentAddressService,
    ServiceTypeService,
    NegotiationService,
    GroupedPaymentService,
    ProductHistoryService,
    ProductCloneHistoryService,
    ProductCatalogueService,
    CustomsCostService
  ]
})
export class AdminApiModule {}
