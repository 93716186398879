export const RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST = 'RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST';
export const RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS = 'RETAILER_SECOND_HAND_SALES_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS';
export const RETAILER_SECOND_HAND_SALES_DRAW_ITEMS_PUT_ON_SALE_CHART = 'RETAILER_SECOND_HAND_SALES_DRAW_ITEMS_PUT_ON_SALE_CHART';
export const RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST = 'RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_REQUEST';
export const RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS = 'RETAILER_SECOND_HAND_SALES_GET_ITEMS_SOLD_CATEGORY_SPLIT_CHART_DATA_SUCCESS';
export const RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_REQUEST = 'RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_REQUEST';
export const RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_SUCCESS = 'RETAILER_SECOND_HAND_SALES_GET_AVG_DISCCOUNT_DATA_SUCCESS';
export const RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_REQUEST = 'RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_REQUEST';
export const RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_SUCCESS = 'RETAILER_SECOND_HAND_SALES_GET_SALE_INFOMATION_DATA_SUCCESS';
export const RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_REQUEST = 'RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_REQUEST';
export const RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_SUCCESS = 'RETAILER_SECOND_HAND_SALES_GET_AVG_SOLD_ITEM_PER_CUS_DATA_SUCCESS';

export const FETCH_2ND_HAND_SALES_REQUESTED = 'FETCH_2ND_HAND_SALES_REQUESTED';
export const FETCH_2ND_HAND_SALES_SUCCEEDED = 'FETCH_2ND_HAND_SALES_SUCCEEDED';
