import {
  COLLECTION_EDIT_PRODUCT_SUCCEEDED,
  COLLECTION_GET_PRODUCT_DETAIL_SUCCEEDED,
  RENDER_COLLECTION_EDIT_PRODUCT_FORM_REQUESTED,
  SAVE_COLLECTION_EDIT_PRODUCT_ALL_SIZES_OPTIONS_SUCCEEDED,
  UPDATE_COLLECTION_EDIT_PRODUCT_INPUT_OPTIONS
} from './edit.actions';
import * as _ from 'lodash';

export const edit = (state: any = { updated: false, inputRendered: false, locations: [] }, action) => {
  switch (action.type) {
    case RENDER_COLLECTION_EDIT_PRODUCT_FORM_REQUESTED:
      return _.assign({}, state, { product: undefined, inputs: action.data.inputs, inputRendered: false });
    case COLLECTION_EDIT_PRODUCT_SUCCEEDED:
      return _.assign({}, state, { created: true, last_product: action.data });
    case UPDATE_COLLECTION_EDIT_PRODUCT_INPUT_OPTIONS:
      return _.assign({}, state, {
        inputRendered: true,
        locations: action.data.warehouse,
        inputs: _.map(state.inputs, (input) => {
          _.map(action.data, (value, key) => {
            if (input.key === key) {
              input.options = value;
            }
            return input;
          });
          return input;
        })
      });
    case SAVE_COLLECTION_EDIT_PRODUCT_ALL_SIZES_OPTIONS_SUCCEEDED:
      return _.assign({}, state, { size_options: action.data });
    case COLLECTION_GET_PRODUCT_DETAIL_SUCCEEDED:
      return _.assign({}, state, { product: action.data });
    default:
      return state;
  }
};
