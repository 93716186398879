import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AttributeModule } from './attribute/attribute.module';
import { CategoryModule } from './category/category.module';
import { CurrencyModule } from './currency/currency.module';
import { ProductModule } from './product/product.module';
import { ProviderModule } from './provider/provider.module';
import { StatusModule } from './status/status.module';
import { RetailerModule } from './retailer/retailer.module';
import { UserModule } from './user/user.module';
import { AclComponent } from './acl/acl.component';
import { CategoryComponent } from './category/category.component';
import { CurrencyComponent } from './currency/currency.component';
import { ProductComponent } from './product/product.component';
import { ProviderComponent } from './provider/provider.component';
import { StatusComponent } from './status/status.component';
import { UserComponent } from './user/user.component';
import { CustomerModule } from './customer/customer.module';
import { CustomerComponent } from './customer/customer.component';
import { EmailComponent } from './email/email.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { TagModule } from './tag/tag.module';
import { TagComponent } from './tag/tag.component';
import { IntegrationModule } from './integration/integration.module';
import { ConfigurationComponent } from './configuration/configuration.component';
import { DirectivesModule } from '../../common/directives/directives.module';
import { SharedModule } from '../../template/shared/shared.module';
import { PipesModule } from '../../common/pipes/pipes.module';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    CategoryModule,
    AttributeModule,
    CurrencyModule,
    ProductModule,
    ProviderModule,
    RetailerModule,
    StatusModule,
    UserModule,
    CustomerModule,
    TagModule,
    IntegrationModule,
    SharedModule,
    DirectivesModule,
    PipesModule
  ],
  declarations: [
    AclComponent,
    CategoryComponent,
    CurrencyComponent,
    ProductComponent,
    ProviderComponent,
    StatusComponent,
    UserComponent,
    CustomerComponent,
    EmailComponent,
    ShipmentComponent,
    TagComponent,
    ConfigurationComponent,
    PaymentComponent
  ]
})
export class AdminModule {}
