import { Configuration } from './configuration/configuration.reducer';
import { ListRetailer } from './list-retailer/list-retailer.reducer';
import { Detail } from './detail/detail.reducer';
import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { Webhook } from './webhook/webhook.reducer';
import { GET_ALL_ADMIN_MARKETPLACE_SUCCESSED } from './marketplace.action';
import { EDIT_ADMIN_MARKETPLACE_SUCCEEDED, DELETE_ADMIN_MARKETPLACE_SUCCESSED } from './edit/edit.actions';
import { CREATE_ADMIN_MARKETPLACE_SUCCEEDED } from './create/create.actions';
const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case CREATE_ADMIN_MARKETPLACE_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case EDIT_ADMIN_MARKETPLACE_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case DELETE_ADMIN_MARKETPLACE_SUCCESSED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case GET_ALL_ADMIN_MARKETPLACE_SUCCESSED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};
export const AdminMarketplace = combineReducers({Configuration,  ListRetailer, Detail, list, edit, create, all, Webhook });
