import { ServiceProvider } from '../service.provider';
import AsyncProcess from '../../models/AsyncProcess';
import { Injectable } from '@angular/core';
import { Observable, interval, of } from 'rxjs';
import { mergeMap, tap, catchError, filter, take, map, timeout } from 'rxjs/operators';
import { AppInjector } from '../../app-injector';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { HttpParams } from '@angular/common/http';
@Injectable()
export class AsyncProcessService extends ServiceProvider {
  public url = '/api/v1/async_processes';
  public model = AsyncProcess;

  checkAsyncStatus(id, speed): Observable<any> {
    return this.basicAsync(this.http.get(this.apiUrl.getApiUrl(this.url) + '/' + id), speed);
  }

  checkAsyncStatusByCode(code, speed, params = {}): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params });
    return this.basicAsync(this.http.get(this.apiUrl.getApiUrl(this.url) + '/code/' + code, { params: queryParams }), speed);
  }

  basicAsync(req, speed): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const availableStatuses = ['COMPLETED', 'RECTIFIED', 'CANCELLED'];
    return interval(speed).pipe(
      mergeMap(() => req),
      // tap(result => {
      //   AppInjector.get(PreloaderService).hide();
      // }),
      filter((s) => availableStatuses.includes(((s as any).data as any).status)),
      take(1),
      map((s) => (s as any).data),
      timeout(900000),
      catchError((error) => of('Time out'))
    );
  }

  retry(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/retry', data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }
}
