import Model from './Model';
import * as _ from 'lodash';
import UserBase from './Base/UserBase';
import PaymentBase from './Base/PaymentBase';
import Currency from './Currency';
import * as moment from 'moment';
import TransactionHistoryBase from './Base/TransactionHistoryBase';
class User extends UserBase {}
class Payment extends PaymentBase {}

class Voucher extends Model {
  seller: any;
  retailer: any;
  payment: any;
  constructor(options) {
    super();
    (this as any).seller = (d) => {
      return new User(d.data);
    };
    (this as any).retailer = (d) => {
      return new User(d.data);
    };
    (this as any).currency = (d) => {
      return new Currency(d.data);
    };
    (this as any).payment = (d) => {
      return new Payment(d.data);
    };
    (this as any).transactionHistory = (d) => {
      return new TransactionHistoryBase(d.data);
    };
    this.bind(options);
  }

  getIssuedDate() {
    if ((this as any).is_issued_at) {
      return moment((this as any).is_issued_at)
        .utc()
        .format('MMMM D YYYY, h:mm:ss a');
    }
    return null;
  }

  getCreatedAtDate() {
    if ((this as any).created_at) {
      return moment((this as any).created_at)
        .utc()
        .format('MMMM D YYYY, h:mm:ss a');
    }
    return null;
  }
}

export const voucher_process_status = [
  {
    label: 'Issued',
    value: true
  },
  {
    label: 'Not issued',
    value: false
  }
];
export const wrong_email_status = [
  {
    label: 'Notification sent',
    value: true
  },
  {
    label: 'Notification not sent',
    value: false
  }
];
export const exported_status = [
  {
    label: 'Exported is null',
    value: true
  },
  {
    label: 'Exported not null',
    value: false
  }
];
export const has_email = [
  {
    label: 'Email is null',
    value: true
  },
  {
    label: 'Email not null',
    value: false
  }
];

export default Voucher;
