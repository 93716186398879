import * as _ from 'lodash';
import product from './product/product.saga';
import dataset from './dataset/dataset.saga';
import user from './user/user.saga';
import percentage from './percentage/percentage.saga';
import resale from './resale/resale.saga';
import secondHandSales from './second-hand-sales/second-hand-sales.saga';
import repurchase from './repurchase/repurchase.saga';
import endpoint from './endpoint/endpoint.saga';
import smartButtonSaga from './smart-button/smart-button.saga';
import analytic from './analytic/analytic.saga';

export default [...product, ...dataset, ...user, ...percentage, ...resale, ...secondHandSales, ...repurchase, ...endpoint, ...smartButtonSaga, ...analytic];
