import { FETCH_PRODUCT_HISTORY_SUCCEEDED } from './history.action';

export const History = (state: any = { fetched: false }, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_HISTORY_SUCCEEDED: {
      return Object.assign(state, { items: action.data.items, pagination: action.data.pagination, fetched: true });
    }
    default:
      return state;
  }
};
