import Model from './Model';
import * as _ from 'lodash';
class AsyncProcess extends Model {
  id: number;
  name: string;
  code: string;
  message: string;
  status: string;
  is_from_cron: boolean;

  constructor(options) {
    super();
    this.bind(options);
  }
}

export default AsyncProcess;
