import { Injectable } from '@angular/core';
import Collection from '../../../models/Collection';
import { ServiceProvider } from '../../service.provider';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Address } from '../../../models/Address';

@Injectable()
export class CollectionService extends ServiceProvider {
  public url = '/api/v1/admin/collections';
  public model = Collection;

  sendConsignmentAgreement(id): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${id}/send-consignment-agreement`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  forceUpdateConsignmentAgreementStatus(id, data): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/consignment-agreement`, data).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  sendApprovedConsignmentAgreementEmail(id): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${id}/consignment-agreement/send-approved-email`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  downloadConsignmentAgreement(id): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/consignment-agreement/download`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  getCollectionData(id, token): Observable<any> {
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/${id}/consignment-agreement/product?token=${token}`).pipe(
      map((result) => new this.model((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }

  acceptConsignmentAgreement(id, params): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/consignment-agreement`, params).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  sendNotificationToCustomer(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/send-notification-to-customer-pickup`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  getPickupStepValueByCollectionId(id): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/get-pickup-step-value-by-collection-id`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
  resendEmailPickupConfirmation(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/resend-pickup-confirmation-email`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  sendReschedulePickupEmail(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/send-reschedule-pickup-email`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  sendValidateReturnEmail(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/send-validate-return-email`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  deleteItemInCollection(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/delete-item`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  addItemInCollection(data): Observable<any> {
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/add-item`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }

  modifyPickupDate(id, data): Observable<any> {
    this.preloader.show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/modify-pickup`, data).pipe(
      tap(() => {
        this.preloader.hide();
      }),
      map((result) => (result as any).data),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  modifyPickupTimeSlot(id, data): Observable<any> {
    this.preloader.show();
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/modify-pickup-timeslot`, data).pipe(
      tap(() => {
        this.preloader.hide();
      }),
      map((result) => (result as any).data),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  updateCollectionNegotiationStatus(id, data): Observable<any> {
    return this.http.put(`${this.apiUrl.getApiUrl(this.url)}/${id}/negotiation-status`, data).pipe(
      map((result) => (result as any).data),
      catchError((error) => {
        throw error;
      })
    );
  }
  getDetailVisible(): Observable<any> {
    this.preloader.show();
    return this.http.get(`${this.apiUrl.getApiUrl(this.url)}/collection-details`).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result) => result),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  createReturnShipment(id, data): Observable<any> {
    this.preloader.show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/return-shipment`, data).pipe(
      tap((result) => {
        this.preloader.hide();
      }),
      map((result) => (result as any).data),
      catchError((error) => {
        this.preloader.hide();
        throw error;
      })
    );
  }
}
