import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../base.component';

import { GET_ALL_ATTRIBUTES_BY_TYPE_REQUESTED } from '../attribute.action';
import * as _ from 'lodash';
import { DELETE_ATTRIBUTE_REQUESTED } from './list.action';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseComponent implements OnInit, OnDestroy {
  public reducer = 'Attribute.List';
  public paramsList = ['sort', 'constraints', 'page', 'per_page', 'search'];
  public type;

  constructor(private activatedRoute: ActivatedRoute, private route: Router) {
    super();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.type = this.activatedRoute.snapshot.params.type;
        let params: any = this.queryParser.parse(this.paramsList);
        this.store.dispatch({
          type: GET_ALL_ATTRIBUTES_BY_TYPE_REQUESTED,
          data: {
            type: this.type, params
          }
        });
      }
    });
  }

  deleteItem(id) {
    this.store.dispatch({ type: DELETE_ATTRIBUTE_REQUESTED, data: {
      id, type: this.type
    }});
  }

  ngOnInit() {
  }

  mapStateToProps(state) {
    return {
      payload: state.Attribute.List
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
