import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ServiceProvider } from '../../service.provider';
import Endpoint from '../../../models/Endpoint';

@Injectable()
export class EndpointService extends ServiceProvider {
  public url = '/api/v1/admin/endpoint';
  public model = Endpoint;
}
