import { Router } from '@angular/router';
import {
  DELETE_RETAILER_REQUESTED,
  GET_RETAILER_REQUESTED,
  GET_RETAILER_SUCCEEDED,
  EDIT_RETAILER_REQUESTED,
  RENDER_EDIT_RETAILER_FORM_REQUESTED,
  FILL_RETAILER_DETAIL_FORM,
  UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
  SET_SHIPPING_METHOD_RULE_CONSTANTS,
  GET_COURIER_RATES_REQUESTED,
  GET_COURIER_RATES_SUCCEEDED,
  UPDATE_COUNTRIES_INPUT_OPTIONS
} from './edit.actions';
import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { listRouter, voucherTypes } from '../retailer.const';
import * as _ from 'lodash';

function* edit(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.user.update(action.data.id, action.data).toPromise();
    AppInjector.get(NotificationService).show('success', 'Retailer updated', 3000);
    router.navigate(listRouter());
    yield put({ type: RENDER_EDIT_RETAILER_FORM_REQUESTED, data: action.completed });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditRetailerRequest() {
  yield takeEvery(EDIT_RETAILER_REQUESTED, edit);
}

function* getRetailer(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.user.getItemById(action.data).toPromise();
    yield put({ type: GET_RETAILER_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchGetRetailerRequest() {
  yield takeEvery(GET_RETAILER_REQUESTED, getRetailer);
}

function* deleteRetailer(action) {
  const api = AppInjector.get(ApiService);
  try {
    yield api.user.delete(action.data).toPromise();
    AppInjector.get(NotificationService).show('success', 'Retailer deleted', 3000);
    AppInjector.get(Router).navigate(['/', 'admin', 'retailer']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteRetailerRequest() {
  yield takeEvery(DELETE_RETAILER_REQUESTED, deleteRetailer);
}

function* watchRenderRetailerDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_RETAILER_FORM_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let shippingMethods = yield api.admin.shippingMethod.list().toPromise();
      let blockchainproviders = yield api.blockchain.listProviders().toPromise();
      let zones = yield api.admin.zone.list().toPromise();
      let dashboards = yield api.analytic.listQuicksightDashboards().toPromise();
      let countries = yield AppInjector.get(ApiService).country.list().toPromise();
      const currencies = yield AppInjector.get(ApiService).currency.list().toPromise();
      const retailers = yield AppInjector.get(ApiService).user.list({ roles: 'retailer', includes : 'retailer' }).toPromise();
      dashboards.unshift({ id: '', name: 'Classic' });
      const dropoffStatus = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
      const defaultIsStockImage = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
      const is_require_retouch_image = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
      const number_of_retouch_image_required = [
        { value: '1', label: '1' },
        { value: 'ALL', label: 'ALL' }
      ];
      yield put({
        type: UPDATE_COUNTRIES_INPUT_OPTIONS,
        data: {
          countries
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'shipping_method_id',
        data: {
          options: _.map(shippingMethods, (item, key) => _.assign(item, { value: item.id, label: item.name }))
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'blockchain_provider_id',
        data: {
          options: _.map(blockchainproviders, (item, key) => _.assign(item, { value: item.id, label: item.code })).concat([{ value: null, label: '' }])
        }
      });
      yield put({
        type: SET_SHIPPING_METHOD_RULE_CONSTANTS,
        data: {
          zones,
          shipping_methods: shippingMethods,
          currencies,
          retailers: retailers.map((i) => _.assign(i, { label: i.email }))
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'voucher_type',
        data: {
          value: null,
          options: voucherTypes
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'dropoff_status',
        data: {
          value: null,
          options: dropoffStatus
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'default_is_stock_image',
        data: {
          value: null,
          options: defaultIsStockImage
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'is_require_retouch_image',
        data: {
          value: null,
          options: is_require_retouch_image
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'number_of_retouch_image_required',
        data: {
          value: null,
          options: number_of_retouch_image_required
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'quicksight_dashboard_id',
        data: {
          options: _.map(dashboards, (item, key) => _.assign(item, { value: item.id, label: item.name }))
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'quicksight_resale_activity_id',
        data: {
          options: _.map(dashboards, (item, key) => _.assign(item, { value: item.id, label: item.name }))
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'quicksight_2nd_hand_sales_id',
        data: {
          options: _.map(dashboards, (item, key) => _.assign(item, { value: item.id, label: item.name }))
        }
      });
      yield put({
        type: UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM,
        input: 'quicksight_repurchase_id',
        data: {
          options: _.map(dashboards, (item, key) => _.assign(item, { value: item.id, label: item.name }))
        }
      });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
    yield put({ type: GET_RETAILER_REQUESTED, data: action.data.id });
  });
}

function* fillRetailerDetailForm(action) {
  yield put({ type: FILL_RETAILER_DETAIL_FORM, data: action.data });
}

function* watchFetchRetailerDetailSuccessed() {
  yield takeLatest(GET_RETAILER_SUCCEEDED, fillRetailerDetailForm);
}

function* watchGetCourierRatesRequested() {
  yield takeLatest(GET_COURIER_RATES_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.shipment.getCourierRates(action.data).toPromise();
      yield put({ type: GET_COURIER_RATES_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchEditRetailerRequest,
  watchGetRetailerRequest,
  watchDeleteRetailerRequest,
  watchRenderRetailerDetailFormRequested,
  watchFetchRetailerDetailSuccessed,
  watchGetCourierRatesRequested
];
