export const GET_DETAIL_INFO_COLLECTION_REQUESTED = 'GET_DETAIL_INFO_COLLECTION_REQUESTED';
export const GET_DETAIL_INFO_COLLECTION_SUCCESSED = 'GET_DETAIL_INFO_COLLECTION_SUCCESSED';
export const GET_PRODUCT_OF_CURRENT_CUSTOMER_REQUESTED = 'GET_PRODUCT_OF_CURRENT_CUSTOMER_REQUESTED';
export const GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED = 'GET_PRODUCT_OF_CURRENT_CUSTOMER_SUCCESSED';
export const SEND_CONSIGNMENT_AGREEMENT_REQUESTED = 'SEND_CONSIGNMENT_AGREEMENT_REQUESTED';
export const APPROVE_CONSIGNMENT_AGREEMENT_REQUESTED = 'APPROVE_CONSIGNMENT_AGREEMENT_REQUESTED';
export const SEND_NOTIFICATION_TO_CUSTOMER_PICKUP_REQUESTED = 'SEND_NOTIFICATION_TO_CUSTOMER_PICKUP_REQUESTED';
export const GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_REQUESTED = 'GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_REQUESTED';
export const GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_SUCCESSED = 'GET_PICKUP_STEP_VALUE_BY_COLLECTION_ID_SUCCESSED';
export const RESEND_PICKUP_CONFIRMATION_EMAIL_REQUESTED = 'RESEND_PICKUP_CONFIRMATION_EMAIL_REQUESTED';
export const RESEND_PICKUP_CONFIRMATION_EMAIL_SUCCESSED = 'RESEND_PICKUP_CONFIRMATION_EMAIL_SUCCESSED';
export const SEND_EMAIL_RESCHEDULE_PICKUP_REQUESTED = 'SEND_EMAIL_RESCHEDULE_PICKUP_REQUESTED';
export const SEND_EMAIL_RESCHEDULE_PICKUP_SUCCESSED = 'SEND_EMAIL_RESCHEDULE_PICKUP_SUCCESSED';
export const SEND_APPROVED_CONSIGNMENT_AGREEMENT_EMAIL_REQUESTED = 'SEND_APPROVED_CONSIGNMENT_AGREEMENT_EMAIL_REQUESTED';
export const DOWNLOAD_CONSIGNMENT_AGREEMENT_REQUESTED = 'DOWNLOAD_CONSIGNMENT_AGREEMENT_REQUESTED';
export const UPDATE_STATUS_PRODUCT_ITEM_REQUEST = 'UPDATE_STATUS_PRODUCT_ITEM_REQUEST';
export const UPDATE_STATUS_PRODUCT_ITEM_SUCCESS = 'UPDATE_STATUS_PRODUCT_ITEM_SUCCESS';
export const DELETE_ITEM_IN_PICKUP_REQUEST = 'DELETE_ITEM_IN_PICKUP_REQUEST';
export const DELETE_ITEM_IN_PICKUP_SUCCESS = 'DELETE_ITEM_IN_PICKUP_SUCCESS';

export const MODIFY_COLLECTION_PICKUP_DATE_REQUESTED = 'MODIFY_COLLECTION_PICKUP_DATE_REQUESTED';
export const MODIFY_COLLECTION_PICKUP_DATE_SUCCESSED = 'MODIFY_COLLECTION_PICKUP_DATE_SUCCESSED';

export const MODIFY_COLLECTION_PICKUP_TIMESLOT_REQUESTED = 'MODIFY_COLLECTION_PICKUP_TIMESLOT_REQUESTED';
export const MODIFY_COLLECTION_PICKUP_TIMESLOT_SUCCESSED = 'MODIFY_COLLECTION_PICKUP_TIMESLOT_SUCCESSED';

export const PRINT_PRODUCT_REQUEST = 'PRINT_PRODUCT_REQUEST';
export const PRINT_PRODUCT_SUCCESSED = 'PRINT_PRODUCT_SUCCESSED';
export const PRINT_REQUEST = 'PRINT_REQUEST';
export const PRINT_COMPLETE = 'PRINT_COMPLETE';

export const COLLECTION_RELOAD_REQUESTED = 'COLLECTION_RELOAD_REQUESTED';

export const CREATE_GHOST_PRODUCT_REQUESTED = 'CREATE_GHOST_PRODUCT_REQUESTED';
export const CREATE_GHOST_PRODUCT_SUCCESSED = 'CREATE_GHOST_PRODUCT_SUCCESSED';

export const GET_LOGICTIS_PARTNER_LOCATION_REQUESTED = 'GET_LOGICTIS_PARTNER_LOCATION_REQUESTED';
export const GET_LOGICTIS_PARTNER_LOCATION_SUCCESSED = 'GET_LOGICTIS_PARTNER_LOCATION_SUCCESSED';
