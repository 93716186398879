import { Router } from '@angular/router';
import { takeEvery, put, takeLatest, all, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import {
  RENDER_EDIT_CUSTOMER_FORM_REQUESTED,
  ADMIN_GET_CUSTOMER_REQUESTED,
  FILL_CUSTOMER_DETAIL_FORM,
  UPDATE_EDIT_CUSTOMER_INPUT_OPTIONS,
  ADMIN_GET_CUSTOMER_SUCCEEDED,
  ADMIN_UPDATE_CUSTOMER_REQUESTED,
  ADMIN_UPDATE_CUSTOMER_SUCCEEDED
} from './update.actions';
import * as _ from 'lodash';
import { detailRouter } from '../customer.const';

function* update(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.user.update(action.data.id, action.data).toPromise();
    yield put({ type: ADMIN_UPDATE_CUSTOMER_SUCCEEDED, data: result });
    AppInjector.get(NotificationService).show('success', 'Customer updated', 3000);
    router.navigate(detailRouter(action.data.id));
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateCustomerRequest() {
  yield takeEvery(ADMIN_UPDATE_CUSTOMER_REQUESTED, update);
}

export function* fetchCustomerDetail(id) {
  return yield AppInjector.get(ApiService)
    .admin.user.getItemById(id)
    .toPromise();
}

function* getCustomer(action) {
  // const dataOptions = {
  //   gender: [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }, { label: 'None', value: 'NULL' }]
  // };
  // yield put({ type: UPDATE_EDIT_CUSTOMER_INPUT_OPTIONS, data: dataOptions, input: 'gender' });
  const [customer] = yield all([call(fetchCustomerDetail, action.data)]);
  yield put({ type: ADMIN_GET_CUSTOMER_SUCCEEDED, data: customer });
  const data = {
    first_name: customer.customer ? customer.customer.first_name : '',
    last_name: customer.customer ? customer.customer.last_name : '',
    gender: customer.customer ? customer.customer.gender : '',
    top_size: customer.customer ? customer.customer.top_size : '',
    bottom_size: customer.customer ? customer.customer.bottom_size : '',
    shoe_size: customer.customer ? customer.customer.shoe_size : '',
    tax_number: customer.customer ? customer.customer.tax_number : '',
    enable_offer: customer.customer ? customer.customer.enable_offer : false,
    can_do_pricing: customer.customer ? customer.customer.can_do_pricing : false,
    phone: {
      code: customer.customer ? customer.customer.phone_area_code : null,
      value: customer.customer ? customer.customer.phone_number : null
    },
    birth: customer.customer ? new Date(customer.customer.birth) : null
  };
  yield put({ type: FILL_CUSTOMER_DETAIL_FORM, data: data });
}

function* watchGetCustomerRequest() {
  yield takeEvery(ADMIN_GET_CUSTOMER_REQUESTED, getCustomer);
}

function* watchRenderCustomerDetailFormRequested() {
  yield takeLatest(RENDER_EDIT_CUSTOMER_FORM_REQUESTED, function*(action: any) {
    yield put({ type: ADMIN_GET_CUSTOMER_REQUESTED, data: action.data.id });
  });
}

export default [watchRenderCustomerDetailFormRequested, watchGetCustomerRequest, watchUpdateCustomerRequest];
