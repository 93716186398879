import { PipesModule } from './../../../common/pipes/pipes.module';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { TagRoutingModule } from './tag-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CreateComponent } from './create/create.component';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from '../../../template/shared/shared.module';
import { AngularReactiveFormModule } from '@reflaunt/reactive-form';
import { TestComponent } from './test/test.component';

@NgModule({
  imports: [CommonModule, TagRoutingModule, FormsModule, PipesModule, DirectivesModule, SweetAlert2Module, SharedModule, AngularReactiveFormModule],
  declarations: [ListComponent, EditComponent, CreateComponent, TestComponent]
})
export class TagModule {}
