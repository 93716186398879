import { Router } from '@angular/router';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { takeEvery, put, select, call, all } from 'redux-saga/effects';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import {
  RETAILER_PREVIEW_PRODUCTS_FROM_CSV_REQUESTED,
  RETAILER_PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED,
  RETAILER_IMPORT_PRODUCT_FROM_CSV_REQUESTED,
  RETAILER_IMPORT_PRODUCT_FROM_CSV_SUCCESSED
} from './import.actions';
import { listRouter } from '../product.const';
import Swal from 'sweetalert2';

function* watchRetailerPreviewProductRequested() {
  yield takeEvery(RETAILER_PREVIEW_PRODUCTS_FROM_CSV_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService).product.previewCsv(action.data).toPromise();
      yield put({ type: RETAILER_PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* callImportApi(chunk) {
  try {
    const api = AppInjector.get(ApiService);
    const request = {
      records: chunk
    };
    let result = yield api.product.importFromCsv(request).toPromise();
    return result;
  } catch (e) {
    throw e;
  }
}

function* watchRetailerImportProductRequested() {
  yield takeEvery(RETAILER_IMPORT_PRODUCT_FROM_CSV_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      const data = _.chunk(action.data.records, 1);
      const calling_actions = _.map(data, (chunk) => {
        return call(callImportApi, chunk);
      });
      const result = yield all(calling_actions);
      let errorMessage = '';
      for (const res of result) {
        if (res.length > 0) {
          for (const r of res) {
            errorMessage += `-${r.item} - ${r.message} \n </br>`;
          }
        }
      }
      yield put({ type: RETAILER_IMPORT_PRODUCT_FROM_CSV_SUCCESSED });
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(Router).navigate(listRouter(yield select((state) => (state as any).Auth.login.profile.id)));
      
      if (errorMessage) {
        (Swal as any)
          .fire({
            icon: 'error',
            title: 'Import Product Error',
            html: errorMessage,
          })
      } else {
        AppInjector.get(NotificationService).show('success', 'Import product complete');
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [watchRetailerPreviewProductRequested, watchRetailerImportProductRequested];
