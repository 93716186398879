import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import GroupedPayment from '../../../models/GroupedPayment';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

@Injectable()
export class GroupedPaymentService extends ServiceProvider {
  public url = '/api/v1/admin/grouped-payments';
  public model = GroupedPayment;

  sendToXero(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(`${this.apiUrl.getApiUrl(this.url)}/${id}/send-to-xero`, {data: data}).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
    catchError(error => {
      AppInjector.get(PreloaderService).hide();
      throw error.error;
    })
  );
}
}
