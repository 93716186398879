import {
  ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_REQUESTED,
  ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_SUCCESSED,
  DELETE_CONDITION_ATTRIBUTE_REQUESTED,
  DELETE_CONDITION_ATTRIBUTE_SUCCESSED,
  FETCH_CONDITION_DICTIONARY_SUCCESSED,
  FETCH_CONDITION_DICTIONARY_REQUESTED,
  ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_SUCCESS,
  ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_REQUESTED,
  ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_SUCCESS,
  ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_SUCCESS,
  ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_REQUESTED,
  ADMIN_ATTRIBUTES_CONDITION_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_CONDITION_FILTER_SELECTED,
  TOGGLE_CONDITION_DICTIONARY_VALIDATION_REQUESTED,
  TOGGLE_CONDITION_DICTIONARY_VALIDATION_SUCCEEDED
} from './condition.action';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_ATTRIBUTES_SELECT_ANY, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { Router } from '@angular/router';
import { conditionAttributes } from './condition.const';

function* fetchConditions(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.condition.get(action.data).toPromise();
    let selected_marketplace = yield select(state => state.Admin.Attributes.all.marketplace.selected);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_SUCCESSED, data: results.items, pagination: results.pagination });
    if (selected_marketplace) {
      yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selected_marketplace, direct: false, component: 'condition' });
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchConditionAttributeRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_CONDITION_ATTRIBUTE_REQUESTED, fetchConditions);
}

function* deleteCondition(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.admin.condition.delete(action.data.id).toPromise();
    yield put({ type: DELETE_CONDITION_ATTRIBUTE_SUCCESSED, data: action.data.id });
    notification.show('success', 'Delete condition success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* fetchConditionDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_CONDITION_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteConditionRequest() {
  yield takeEvery(DELETE_CONDITION_ATTRIBUTE_REQUESTED, deleteCondition);
}

function* watchFetchConditionDictionaryRequested() {
  yield takeEvery(FETCH_CONDITION_DICTIONARY_REQUESTED, fetchConditionDictionary);
}

function* watchUpdateConditionDictionaryRequested() {
  yield takeEvery(ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_REQUEST, updateConditionDictionary);
}

function* updateConditionDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.updateOrCreate(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_CONDITION_UPDATE_DICTIONARY_SUCCESS, data: result });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* toggleConditionDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_CONDITION_DICTIONARY_VALIDATION_REQUESTED, toggleConditionDictionaryValidation);
}

function* toggleConditionDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_CONDITION_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchSelectAnyFilter() {
  yield takeEvery(ADMIN_ATTRIBUTES_SELECT_ANY, function*(action: any) {
    const attribute = yield select((state: any) => state.Admin.Attributes.all);
    if (action.component && action.component === 'condition') {
      yield put({ type: ADMIN_ATTRIBUTES_CONDITION_FILTER_SELECTED, data: attribute });
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_REQUEST, data: { marketplace_id: attribute.marketplace.selected.id } });
    }
  });
}

function* watchFetchAllMarketplaceConditionsRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const results = yield api.marketplace.getAllConditions(action.data).toPromise();
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_CONDITIONS_SUCCESS, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* createCondition(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.condition.create(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_SUCCESS, data: result, component: action.component });

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'condition']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateConditionRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_CONDITION_CREATE_CONDITION_REQUESTED, createCondition);
}

function* updateCondition(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.condition.update(action.data.id, action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_SUCCESS, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update condition success', 5000);

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'condition']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateConditionRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_CONDITION_EDIT_CONDITION_REQUESTED, updateCondition);
}

function* watchSynchroniseConditionsRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_CONDITION_SYNCHRONISE_ATTRIBUTES_REQUEST, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.marketplace.syncAttributes(action.data).toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatus(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          router.navigate(conditionAttributes());
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteConditionRequest,
  watchFetchConditionAttributeRequest,
  watchFetchConditionDictionaryRequested,
  watchUpdateConditionDictionaryRequested,
  watchSelectAnyFilter,
  watchFetchAllMarketplaceConditionsRequest,
  watchCreateConditionRequest,
  watchUpdateConditionRequest,
  watchSynchroniseConditionsRequest,
  toggleConditionDictionaryValidationRequested
];
