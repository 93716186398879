import { FETCH_ALL_ADMIN_NOTIFICATION_SUCCESSED, FETCH_ALL_ADMIN_NOTIFICATION_REQUESTED, DELETE_ADMIN_NOTIFICATION_REQUESTED } from './list.actions';
import * as _ from 'lodash';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchFetchNotificationsRequest() {
  yield takeEvery(FETCH_ALL_ADMIN_NOTIFICATION_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .admin.notification.get()
        .toPromise();
      yield put({ type: FETCH_ALL_ADMIN_NOTIFICATION_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteNotificationRequest() {
  yield takeLatest(DELETE_ADMIN_NOTIFICATION_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .admin.notification.delete(action.data)
        .toPromise();
      yield put({ type: FETCH_ALL_ADMIN_NOTIFICATION_REQUESTED });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchNotificationsRequest, watchDeleteNotificationRequest];
