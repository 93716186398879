import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import OrderProduct from '../../../models/OrderProduct';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class OrderProductService extends ServiceProvider {
  public url = '/api/v1/admin/order-products';
  public model = OrderProduct;

  simpleUpdate(id, data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/' + id + '/simple-update', data).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => {
        return new this.model((result as any).data);
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
