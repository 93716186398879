import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { USER_COMP } from './../user.const';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CREATE_USER_REQUESTED } from './create.actions';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { CHANGE_ROLE_SUCCEEDED, FETCH_ALL_ROLE_REQUESTED } from '../../acl/roles/roles.actions';
import { Store } from '../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';
import { FETCH_RETAILERS_REQUESTED } from '../../retailer/list/list.actions';
import { USER_ROLES } from '../../../../app.const';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {
  public store;
  public filters = {
    role_ids: USER_ROLES.RETAILER
  };
  protected navigationSupscription: Subscription;
  public user = {
    email: '',
    password: '',
    repassword: '',
    first_name: '',
    last_name: '',
    status: 0,
    role_id: '',
    role_slug: '',
    parent_retailer_id: ''
  };
  constructor(private notification: NotificationService, private activatedRoute: ActivatedRoute, private router: Router, store: Store) {
    this.store = store.getInstance();
    this.navigationSupscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: FETCH_ALL_ROLE_REQUESTED,
          com: USER_COMP
        });
        this.store.dispatch({
          type: FETCH_RETAILERS_REQUESTED,
          data: {
            filter: this.filters,
          }
        });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSupscription)) {
      this.navigationSupscription.unsubscribe();
    }
  }

  ngOnChange(value) {
    this.store.dispatch({
      type: CHANGE_ROLE_SUCCEEDED,
      data: value
    })
    const role_data = this.store.getState().Admin.Acl.Roles.commonRoles.items.find((item) => item.id === Number(this.user.role_id))
    if(role_data){
      this.user.role_slug = role_data.slug
    }
  }

  onSubmit(form) {
    if (form.valid) {
      const store = AppInjector.get(Store).getInstance();
      store.dispatch({
        type: CREATE_USER_REQUESTED,
        data: this.user,
        com: USER_COMP
      });
    }
  }
}
