export const NEGOTIATION_NEGOTIATION_FETCH_PRODUCTS_REQUESTED = 'NEGOTIATION_NEGOTIATION_FETCH_PRODUCTS_REQUESTED';
export const NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED = 'NEGOTIATION_FETCH_PRODUCTS_SUCCEEDED';
export const NEGOTIATION_SELECT_ALL_PRODUCT = 'NEGOTIATION_SELECT_ALL_PRODUCT';
export const NEGOTIATION_FETCH_CATEGORIES_OF_PRODUCT_REQUESTED = 'NEGOTIATION_FETCH_CATEGORIES_OF_PRODUCT_REQUESTED';
export const NEGOTIATION_FETCH_CATEGORIES_OF_PRODUCT_SUCCEEDED = 'NEGOTIATION_FETCH_CATEGORIES_OF_PRODUCT_SUCCEEDED';
export const NEGOTIATION_MAP_CATEGORIES_TO_PRODUCTS = 'NEGOTIATION_MAP_CATEGORIES_TO_PRODUCTS';
export const NEGOTIATION_BROADCAST_ITEM_REQUESTED = 'NEGOTIATION_BROADCAST_ITEM_REQUESTED';
export const NEGOTIATION_BROADCAST_ITEM_SUCCESSED = 'NEGOTIATION_BROADCAST_ITEM_SUCCESSED';
export const NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED = 'NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_REQUESTED';
export const NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED = 'NEGOTIATION_ADMIN_UPDATE_PRODUCT_STATUS_SUCCESSED';
export const NEGOTIATION_FETCH_PRODUCT_IMAGE_SUCCESSED = 'NEGOTIATION_FETCH_PRODUCT_IMAGE_SUCCESSED';
export const NEGOTIATION_FETCH_CUSTOMER_INFORMATION_REQUESTED = 'NEGOTIATION_FETCH_CUSTOMER_INFORMATION_REQUESTED';
export const NEGOTIATION_FETCH_CUSTOMER_INFORMATION_SUCCEEDED = 'NEGOTIATION_FETCH_CUSTOMER_INFORMATION_SUCCEEDED';
export const NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_REQUESTED = 'NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_REQUESTED';
export const NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_SUCCESSED = 'NEGOTIATION_FETCH_PRODUCT_MARKETPLACE_SUCCESSED';
export const NEGOTIATION_FETCH_ALL_MARKETPLACE_REQUESTED = 'NEGOTIATION_FETCH_ALL_MARKETPLACE_REQUESTED';
export const NEGOTIATION_FETCH_ALL_MARKETPLACE_SUCCESSED = 'NEGOTIATION_FETCH_ALL_MARKETPLACE_SUCCESSED';
export const NEGOTIATION_PUBLISH_PRODUCT_REQUESTED = 'NEGOTIATION_PUBLISH_PRODUCT_REQUESTED';
export const NEGOTIATION_PUBLISH_PRODUCT_SUCCESSED = 'NEGOTIATION_PUBLISH_PRODUCT_SUCCESSED';
export const NEGOTIATION_EXPORT_PRODUCT_REQUESTED = 'NEGOTIATION_EXPORT_PRODUCT_REQUESTED';
export const NEGOTIATION_EXPORT_PRODUCT_SUCCEEDED = 'NEGOTIATION_EXPORT_PRODUCT_SUCCEEDED';
export const NEGOTIATION_EXPORT_PRODUCT_FAILED = 'NEGOTIATION_EXPORT_PRODUCT_FAILED';

export const NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED = 'NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_REQUESTED';
export const NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED = 'NEGOTIATION_ADMIN_UPDATE_PRODUCT_ITEM_STATUS_SUCCESSED';
export const NEGOTIATION_FETCH_ALL_CONDITION_REQUESTED = 'NEGOTIATION_FETCH_ALL_CONDITION_REQUESTED';
export const NEGOTIATION_FETCH_ALL_CONDITION_SUCCESSED = 'NEGOTIATION_FETCH_ALL_CONDITION_SUCCESSED';
export const NEGOTIATION_FETCH_ALL_DESIGNER_REQUESTED = 'NEGOTIATION_FETCH_ALL_DESIGNER_REQUESTED';
export const NEGOTIATION_FETCH_ALL_DESIGNER_SUCCESSED = 'NEGOTIATION_FETCH_ALL_DESIGNER_SUCCESSED';
export const NEGOTIATION_FETCH_ALL_CATEGORY_REQUESTED = 'NEGOTIATION_FETCH_ALL_CATEGORY_REQUESTED';
export const NEGOTIATION_FETCH_ALL_CATEGORY_SUCCESSED = 'NEGOTIATION_FETCH_ALL_CATEGORY_SUCCESSED';
export const NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED = 'NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_REQUESTED';
export const NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED = 'NEGOTIATION_GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED';
export const NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED = 'NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_REQUESTED';
export const NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED = 'NEGOTIATION_FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED';
export const NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED = 'NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED';
export const NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED = 'NEGOTIATION_UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED';
export const NEGOTIATION_FETCH_ALL_STATUS_REQUESTED = 'NEGOTIATION_FETCH_ALL_STATUS_REQUESTED';
export const NEGOTIATION_FETCH_ALL_STATUS_SUCCESSED = 'NEGOTIATION_FETCH_ALL_STATUS_SUCCESSED';
export const NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_REQUESTED = 'NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_REQUESTED';
export const NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_SUCCEEDED = 'NEGOTIATION_RESYNC_PRODUCT_FROM_RETAILER_SUCCEEDED';

export const NEGOTIATION_ADD_OR_REMOVE_SELECTED_PRODUCT = 'NEGOTIATION_ADD_OR_REMOVE_SELECTED_PRODUCT';
export const NEGOTIATION_ADD_ALL_PRODUCTS_IN_PAGE = 'NEGOTIATION_ADD_ALL_PRODUCTS_IN_PAGE';
export const NEGOTIATION_REMOVE_ALL_PRODUCTS_IN_PAGE = 'NEGOTIATION_REMOVE_ALL_PRODUCTS_IN_PAGE';
export const NEGOTIATION_REMOVE_ALL_SELECTED_PRODUCT = 'NEGOTIATION_REMOVE_ALL_SELECTED_PRODUCT';

export const NEGOTIATION_GET_PRODUCT_DETAIL_SUCCEEDED = 'NEGOTIATION_GET_PRODUCT_DETAIL_SUCCEEDED';
export const NEGOTIATION_FETCH_PRODUCTS_REQUESTED = 'NEGOTIATION_FETCH_PRODUCTS_REQUESTED';
