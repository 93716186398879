import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { takeEvery, put } from 'redux-saga/effects';
import * as _ from 'lodash';
import { FETCH_NOTIFICATION_ACTIONS_REQUESTED, FETCH_NOTIFICATION_ACTIONS_SUCCEEDED } from './list.actions';

function* watchFetchNotificationActionRequested() {
  yield takeEvery(FETCH_NOTIFICATION_ACTIONS_REQUESTED, function*(action: any) {
    try {
      let results = yield AppInjector.get(ApiService)
        .admin.notificationAction.get(action.data)
        .toPromise();
      yield put({ type: FETCH_NOTIFICATION_ACTIONS_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchNotificationActionRequested];
