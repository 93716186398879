import * as _ from 'lodash';
import { FETCH_USER_DETAIL_SUCCEEDED, CHANGE_PASSWORD_BY_ADMIN_SUCCESSED } from './edit.actions';
import { FETCH_ALL_ROLE_SUCCEEDED } from '../../../admin/acl/roles/roles.actions';
import { GET_ALL_CURRENCYS_SUCCEEDED } from '../../currency/currency.actions';
import { FETCH_LANGUAGE_SUCCEEDED } from '../../language/list/list.actions';

export const editUser = (
  state = {
    fetched: false,
    item: {}
  },
  action
) => {
  switch (action.type) {
    case FETCH_USER_DETAIL_SUCCEEDED:
      return _.assign({}, state, {
        fetched: true,
        item: action.data
      });

    case FETCH_ALL_ROLE_SUCCEEDED:
      return _.assign({}, state, {
        roles: _.map(action.data, (item) => {
          const role = _.find((state as any).item.roles, (i) => item.getId() === i.getId());
          if (role) {
            return _.assign({}, role, { checked: true });
          } else {
            return item;
          }
        }),
        item: _.assign(_.clone(state.item), {
          roles: _.map((state as any).item.roles, (i) => {
            return _.find(action.data, (v) => i.getId() === v.getId());
          })
        })
      });
    case CHANGE_PASSWORD_BY_ADMIN_SUCCESSED:
      return _.assign({}, state, {
        changePassword: true
      });

    case GET_ALL_CURRENCYS_SUCCEEDED:
      return _.assign({}, state, { currencies: action.data });

    case FETCH_LANGUAGE_SUCCEEDED:
      return _.assign({}, state, { languages: action.data });
    default:
      return state;
  }
};
