export const FETCH_RETAILER_CATEGORIES_SUCCESS = 'FETCH_RETAILER_CATEGORIES_SUCCESS';
export const ADMIN_ATTRIBUTES_RETAILER_CATEGORY_UPDATE_DICTIONARY_REQUEST = 'ADMIN_ATTRIBUTES_RETAILER_CATEGORY_UPDATE_DICTIONARY_REQUEST';
export const ADMIN_ATTRIBUTES_RETAILER_CATEGORY_UPDATE_DICTIONARY_SUCCESS = 'ADMIN_ATTRIBUTES_RETAILER_CATEGORY_UPDATE_DICTIONARY_SUCCESS';

export const ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_CATEGORY_REQUESTED = 'ADMIN_ATTRIBUTES_RETAILER_ACTUALIZE_CATEGORY_REQUESTED';
export const TOGGLE_CATEGORY_RETAILER_DICTIONARY_VALIDATION_REQUESTED = 'TOGGLE_CATEGORY_RETAILER_DICTIONARY_VALIDATION_REQUESTED';
export const TOGGLE_CATEGORY_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED = 'TOGGLE_CATEGORY_RETAILER_DICTIONARY_VALIDATION_SUCCEEDED';

export const DELETE_RETAILER_CATEGORY_MATCHING_REQUESTED = 'DELETE_RETAILER_CATEGORY_MATCHING_REQUESTED';

export const ADMIN_SYNCHRONISE_RETAILER_ACTUALIZE_CATEGORY_REQUESTED = 'ADMIN_SYNCHRONISE_RETAILER_ACTUALIZE_CATEGORY_REQUESTED';
