import { CREATE_LANGUAGE_REQUESTED, CREATE_LANGUAGE_SUCCEEDED } from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* createLanguage(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.language.create(action.data).toPromise();
    yield put({ type: CREATE_LANGUAGE_SUCCEEDED, data: result });
    router.navigate(['/admin/language']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateLanguageRequest() {
  yield takeEvery(CREATE_LANGUAGE_REQUESTED, createLanguage);
}

export default [watchCreateLanguageRequest];
