export const FETCH_DATA_ADMIN_ORDER_REQUESTED = 'FETCH_DATA_ADMIN_ORDER_REQUESTED';
export const FETCH_DATA_ADMIN_ORDER_SUCCESSED = 'FETCH_DATA_ADMIN_ORDER_SUCCESSED';
export const CANCEL_ORDER_PRODUCT_REQUESTED = 'CANCEL_ORDER_PRODUCT_REQUESTED';
export const CANCEL_ORDER_PRODUCT_SUCCESSED = 'CANCEL_ORDER_PRODUCT_SUCCESSED';
export const UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED = 'UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED';
export const UPDATE_STATUS_ORDER_PRODUCT_REQUESTED = 'UPDATE_STATUS_ORDER_PRODUCT_REQUESTED';
export const UPDATE_ORDER_SHIPMENT_ADMIN_REQUESTED = 'UPDATE_ORDER_SHIPMENT_ADMIN_REQUESTED';
export const UPDATE_ORDER_SHIPMENT_ADMIN_SUCCESSED = 'UPDATE_ORDER_SHIPMENT_ADMIN_SUCCESSED';

export const fetchAdminOrderRequested = (payload) => {
  return {
    type: FETCH_DATA_ADMIN_ORDER_REQUESTED,
    payload: payload
  };
};
