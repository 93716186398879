import { days } from './../dataset.const';
import { DataOperators } from './../../../../models/DatasetOperator';
import { NgForm } from '@angular/forms';
import { BaseComponent } from './../../../base.component';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from './../../../../store/store.module';
import { CONDITION_MODIFIED, CREATE_DATASET_REQUESTED, DATASET_CREATE_COMPONENT, FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED } from './create.actions';
import { GET_ALL_DATASET_OBJECTS_REQUESTED, GET_ALL_DATASET_OPERATORS_REQUESTED } from '../dataset.action';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { DataObjectMarketplaces, DataTypes } from '../../../../models/DatasetObject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { INPUT_TYPES, selectDatasetOperator, frequencies } from '../dataset.const';
import { get, isEmpty } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('datasetConditionForm', null) datasetConditionForm: NgForm;
  public formChangesSubscription: any;
  public subscriptionCondidtions: any;
  public reducer = 'Retailer.Dataset.create';
  public isPushAllProduct = true;
  public retailerId = null;
  public marketplaceId = null;
  public default_operator_list = [DataOperators.in_list, DataOperators.not_in_list];
  public default_operator_containt = [DataOperators.contains, DataOperators.does_not_contain];
  public numberPattern = '^([1-9][0-9]+|[1-9])(\\.[0-9]+)?$';
  public INPUT_TYPES = INPUT_TYPES;
  public time_operators = [
    { value: '+', label: '+ (Plus)' },
    { value: '-', label: '- (Minus)' }
  ];
  public time_interval_types = [
    { value: 'year', label: 'Years' },
    { value: 'month', label: 'Months' },
    { value: 'day', label: 'Days' },
    { value: 'hour', label: 'Hours' },
    { value: 'minute', label: 'Minutes' },
    { value: 'second', label: 'Seconds' }
  ];
  public frequencies = frequencies;
  public isDailyOrWeekly=null;
  public days = days;
  public selectedDatesRequired = false;
  public selectedDates = [];
  constructor(public activatedRoute: ActivatedRoute, store: Store, private notification: NotificationService) {
    super();
    this.activatedRoute.queryParams.subscribe((params) => {
      const retailerId = params['retailer_id'];
      const marketplaceId = params['marketplace_id'];
      this.retailerId = retailerId;
      this.marketplaceId = marketplaceId;
    });
  }

  ngOnInit() {
    this.init();
    this.store.dispatch({
      type: GET_ALL_DATASET_OBJECTS_REQUESTED,
      component: DATASET_CREATE_COMPONENT
    });

    this.store.dispatch({
      type: GET_ALL_DATASET_OPERATORS_REQUESTED,
      component: DATASET_CREATE_COMPONENT
    });
  }

  ngAfterViewInit() {
    if (!_.isNil(this.datasetConditionForm)) {
      this.watchFormValueChange();
    }
  }

  selectDatasetObject = (dataset_object, condition_index) => {
    this.store.dispatch({
      type: CONDITION_MODIFIED,
      data: {
        dataset_object,
        condition_index
      }
    });
  };

  datasetOperatorChange = (dataset_operator, condition_index) => {
    let current_condition = this.payload.item.conditions[condition_index];
    current_condition.value = null;
    selectDatasetOperator(dataset_operator, current_condition);
  };

  isDisabledBaseOnCondition(operator, condition_index) {
    let disabled = false;
    let current_condition = this.payload.item.conditions[condition_index];
    if (_.isNil(current_condition) || _.isNil(current_condition.dataset_object)) {
      return disabled;
    }
    const { data_type, is_contains_enabled, is_multiple_choice, label } = current_condition.dataset_object;
    if (_.includes([DataObjectMarketplaces.is_not_published, DataObjectMarketplaces.is_published, DataObjectMarketplaces.product_item_location], label)) {
      return operator.label !== DataOperators.is_equal_to;
    }
    switch (true) {
      case data_type === DataTypes.varchar:
        let default_disable = [DataOperators.is_greater_than, DataOperators.is_less_than];
        if (!is_multiple_choice) {
          default_disable = [...default_disable, ...this.default_operator_list];
        }
        if (!is_contains_enabled) {
          default_disable = [...default_disable, ...this.default_operator_containt];
        }
        disabled = default_disable.indexOf(operator.label) > -1;
        break;
      case [DataTypes.double, DataTypes.timestamp].indexOf(data_type) > -1:
        disabled = [...this.default_operator_containt, ...this.default_operator_list].indexOf(operator.label) > -1;
        break;
      case is_contains_enabled && is_multiple_choice:
        return false;
      case !is_contains_enabled && is_multiple_choice:
        disabled = [...this.default_operator_containt].indexOf(operator.label) > -1;
        break;
      case is_contains_enabled && !is_multiple_choice:
        disabled = this.default_operator_list.indexOf(operator.label) > -1;
        break;
      case !is_contains_enabled && !is_multiple_choice:
        disabled = [...this.default_operator_containt, ...this.default_operator_list].indexOf(operator.label) > -1;
        break;
    }
    return disabled;
  }

  watchFormValueChange = () => {
    this.formChangesSubscription = this.datasetConditionForm.valueChanges.pipe(debounceTime(300)).subscribe((selectedValue) => {
      const conditions = this.payload.item.conditions;
      const current_condition_change = _.find(conditions, (i) => i.changed);
      console.log('___current_condition_change', current_condition_change);

      if (conditions.length > 0 && !_.isNil(current_condition_change)) {
        if ([this.INPUT_TYPES.search, this.INPUT_TYPES.mutiple_search].indexOf(current_condition_change.input_type) > -1) {
          this.store.dispatch({
            type: FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED,
            condition: current_condition_change
          });
        }
      }
    });
  };

  onSubmit = (form) => {
    if (form.valid) {
      let data = _.cloneDeep(this.payload.item);
      if (!this.isDisplayAutoPublishSection()) {
        data = Object.assign(data, {
          is_auto_publish: false,
          publish_frequency: null,
          first_run_at: null,
          auto_publish_chunk_limit: null,
          daily_or_weekly: null,
          selected_dates:null,
          time_slot:null,
          limit_push_item_per_day:null,
          limit_push_item_per_week:null
        });
      }
      data.conditions = _.chain(data.conditions)
        .map((condition) => {
          condition.dataset_operator_id = condition.dataset_operator.getId();
          condition.dataset_object_id = condition.dataset_object.getId();
          if (Array.isArray(condition.value)) {
            condition.value = _.map(condition.value, (i) => i.id);
          }
          if (condition.dataset_object.data_type === DataTypes.timestamp && condition.is_dynamic_date) {
            condition.value = {
              value: Number(condition.value),
              time_interval_type: condition.time_interval_type,
              time_interval_operator: condition.time_interval_operator
            };
          }
          return _.pick(condition, ['dataset_object_id', 'dataset_operator_id', 'value']);
        })
        .value();
      data.user_id = this.retailerId ? Number(this.retailerId) : this.store.getState().Auth.login.profile.getId();
      data.daily_or_weekly = form.value.daily_or_weekly;
      data.time_slot = form.value.time_slot;
      if (this.selectedDates.length) {
        data.selected_dates = _.sortBy(this.selectedDates).toString();
      }
      if (form.value.is_auto_publish && form.value.daily_or_weekly === 'weekly' && this.selectedDates.length < 1) {
        this.selectedDatesRequired = true;
        return false;
      }
      if (data.conditions.length === 0) {
        this.notification.show('warning', 'Please select at least 1 condition', 5000);
        return false;
      } else {
        if (this.isPushAllProduct && this.isPushAllProduct === true) {
          data.no_product_to_push = 0;
        }
        this.store.dispatch({ type: CREATE_DATASET_REQUESTED, data: data, meta: { retailerId: this.retailerId, marketplaceId: this.marketplaceId } });
      }
    }
  };

  addCondition() {
    const last_index = _.findLastIndex(this.payload.item.conditions);
    let new_condition = {
      dataset_object: _.head(this.payload.dataset_objects.items),
      dataset_operator: _.head(this.payload.dataset_operators.items),
      constants: [],
      index: last_index + 1
    };
    this.payload.item.conditions.push(new_condition);
    this.store.dispatch({
      type: CONDITION_MODIFIED,
      data: {
        dataset_object: new_condition.dataset_object,
        condition_index: last_index + 1
      }
    });
  }

  dynamicType(condition) {
    let input_type = 'text';
    if (_.isNil(condition.dataset_object)) {
      return input_type;
    }
    switch (true) {
      case condition.dataset_object.data_type === DataTypes.double:
        input_type = 'number';
        break;
    }
    return input_type;
  }

  removeCondition(index) {
    this.payload.item.conditions.splice(index, 1);
  }

  isDisplayAutoPublishSection() {
    let result = false;
    if (!this.payload.item.conditions || !this.payload.item.conditions.length) {
      return result;
    }

    const selectedDatasetObjects = this.payload.item.conditions.map((condition) => {
      let objectLabel = null;
      if (condition.dataset_object) {
        objectLabel = get(condition.dataset_object, 'label');
      }
      return objectLabel;
    });
    if (selectedDatasetObjects.includes('Is not published on marketplace')) {
      result = true;
    }
    return result;
  }

  onChangeCk(event) {
    if (event.checked) {
      this.selectedDates.push(event.value);
    } else {
      this.selectedDates = this.selectedDates.filter((s) => {
        return s !== event.value;
      });
    }
    this.selectedDates = this.selectedDates.filter((s) => {
      return !isEmpty(s);
    });
    if (this.selectedDates.length) {
      this.selectedDatesRequired = false;
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Dataset.create
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch
    };
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (!_.isNil(this.formChangesSubscription)) {
      this.formChangesSubscription.unsubscribe();
    }
    if (!_.isNil(this.subscriptionCondidtions)) {
      this.subscriptionCondidtions.unsubscribe();
    }
  }
}
