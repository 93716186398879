import { takeEvery, put } from 'redux-saga/effects';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import {
  RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST,
  RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS,
  RETAILER_REPURCHASE_DRAW_ITEMS_PUT_ON_SALE_CHART,
  RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_REQUEST,
  RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_SUCCESS, FETCH_REPURCHASE_REQUESTED, FETCH_REPURCHASE_SUCCEEDED
} from './repurchase.const';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { FETCH_RESALE_ACTIVITTY_SUCCEEDED } from '../resale/resale.const';

function* watchRetailerGerItemsPutOnSaleChartDataRequest() {
  yield takeEvery(RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getNumberSoldSaleProductsByDate(action.data).toPromise();
      yield put({ type: RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerGerItemsPutOnSaleChartDataSuccess() {
  yield takeEvery(RETAILER_REPURCHASE_GET_ITEMS_PUT_ON_SALE_CHART_DATA_SUCCESS, function* (action: any) {
    yield put({ type: RETAILER_REPURCHASE_DRAW_ITEMS_PUT_ON_SALE_CHART });
  });
}

function* watchRetailerPurChaseGetInfoDataRequest() {
  yield takeEvery(RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.analytic.getRePurchaseInformation().toPromise();
      yield put({ type: RETAILER_REPURCHASE_GET_SALE_INFORMATION_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* fetchRepurchase(action) {
  yield takeEvery(FETCH_REPURCHASE_REQUESTED, function* (action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const api = AppInjector.get(ApiService);
      let result = yield api.analytic.getRepurchaseEmbeddedUrl().toPromise();
      AppInjector.get(PreloaderService).hide();
      yield put({ type: FETCH_REPURCHASE_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchRetailerPurChaseGetInfoDataRequest, watchRetailerGerItemsPutOnSaleChartDataRequest, watchRetailerGerItemsPutOnSaleChartDataSuccess, fetchRepurchase];
