import * as _ from 'lodash';
import {
  GET_TRANSACTION_BY_ID_SUCCEEDED,
  RENDER_FILL_BANK_ACCOUNT_FORM_REQUESTED,
  ADD_PAYMENT_SUCCEEDED,
  UPDATE_BANK_ACCOUNT_FROM_REQUESTED,
  RENDER_FILL_ADDRESS_FORM_REQUESTED,
  UPDATE_SELLER_ADDRESS_FROM_REQUESTED,
  UPDATE_INPUTS_ACCOUNT_PAYMENT_FORM_REQUESTED,
  SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED,
  UPDATE_INPUTS_VALUES_PAYMENT_FORM_REQUESTED
} from './checkout-payment.actions';
import { support } from '@reflaunt/reactive-form';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case UPDATE_BANK_ACCOUNT_FROM_REQUESTED:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
      }
      if (state.key === 'country') {
        state.value = _.find(state.options, i => i.alpha2Code === state.value);
      }
      return state;
    default:
      return state;
  }
};

export const checkoutPayment = (state: any = { added: false, fetched: false, transaction: null, result: null, isShowFirstStep: true }, action) => {
  switch (action.type) {
    case ADD_PAYMENT_SUCCEEDED:
      return _.assign({}, state, { added: true, result: action.data.data });
    case GET_TRANSACTION_BY_ID_SUCCEEDED:
      return _.assign({}, state, { fetched: true, transaction: action.data });
    case RENDER_FILL_BANK_ACCOUNT_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case UPDATE_INPUTS_ACCOUNT_PAYMENT_FORM_REQUESTED:
      state.inputs = support.UpdateInputsValue(state.inputs, action.data);
      state.inputs = support.UpdateFormValue(state.inputs, {
        country: _.find(action.data.country, item => item.value === 'United Kingdom'),
        currency: 'GBP'
      });
      return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_INPUTS_VALUES_PAYMENT_FORM_REQUESTED:
      return _.assign({}, state, {
        inputs: support.UpdateFormValue(
          state.inputs,
          _.assign({}, action.data, {
            currency: action.data.country.currencies[0].code
          })
        )
      });
    case UPDATE_BANK_ACCOUNT_FROM_REQUESTED:
      let inputs = _.map(state.inputs, item => Input(item, action));

      const fieldsAble = ['country', 'currency', 'account_number'];
      inputs = _.map(inputs, i => {
        if (_.includes(fieldsAble, i.key)) {
          i.disabled = true;
        }
        return i;
      });
      return _.assign({}, state, { inputs: inputs });
    case SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED:
      return _.assign({}, state, { isShowFirstStep: false });
    default:
      return state;
  }
};
