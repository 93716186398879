import {
  FETCH_CONDITION_ATTRIBUTE_REQUESTED,
  FETCH_CONDITION_ATTRIBUTE_SUCCESSED,
  CREATE_CONDITION_REQUESTED,
  CREATE_CONDITION_SUCCESSED,
  FETCH_CONDITION_DICTIONARIES_SUCCESSED,
  FETCH_CONDITION_DICTIONARY_REQUESTED,
  CREATE_CONDITION_DICTIONARY_SUCCESSED,
  DELETE_CONDITION_ATTRIBUTE_REQUESTED,
  DELETE_CONDITION_ATTRIBUTE_SUCCESSED,
  EDIT_CONDITION_REQUESTED,
  EDIT_CONDITION_SUCCESSED,
  EDIT_CONDITION_DICTIONARY_REQUESTED,
  EDIT_CONDITION_DICTIONARY_SUCCESSED
} from './condition.action';
import { takeEvery, put, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { DICTIONARY_CONDITION_TYPE } from '../../../../app.const';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

export function* fetchAllConditions() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.condition.get({ per_page: 10000 }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchConditions(action) {
  try {
    let results = yield AppInjector.get(ApiService).condition.get({ per_page: 10000 }).toPromise();
    yield put({ type: FETCH_CONDITION_ATTRIBUTE_SUCCESSED, data: results.items });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchConditionAttributeRequested() {
  yield takeEvery(FETCH_CONDITION_ATTRIBUTE_REQUESTED, fetchConditions);
}

function* createCondition(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.condition.create(action.data).toPromise();
    yield put({ type: CREATE_CONDITION_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateConditionRequested() {
  yield takeEvery(CREATE_CONDITION_REQUESTED, createCondition);
}

function* fetchConditionDictionaries(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_CONDITION_DICTIONARIES_SUCCESSED, data: results });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchConditionDictionaryRequested() {
  yield takeEvery(FETCH_CONDITION_DICTIONARY_REQUESTED, fetchConditionDictionaries);
}

function* createConditionDictionary(action) {
  const fetched_marketplace = yield select((state) => (state as any).Admin.Attribute.all.marketplace.fetched);
  if (fetched_marketplace) {
    const marketplaces = yield select((state) => (state as any).Admin.Attribute.all.marketplace.items);
    for (const marketplace of marketplaces) {
      try {
        const api = AppInjector.get(ApiService);

        let data = {
          type: DICTIONARY_CONDITION_TYPE,
          attribute_id: action.data.getId(),
          marketplace_id: marketplace.getId(),
          value: action.data.getName()
        };

        let result = yield api.admin.dictionary.create(data).toPromise();
        yield put({ type: CREATE_CONDITION_DICTIONARY_SUCCESSED, data: result });

        let marketplace_id = AppInjector.get(ActivatedRoute).snapshot.queryParams.marketplace_id;
        if (!_.isNil(marketplace_id)) {
          yield put({
            type: FETCH_CONDITION_DICTIONARY_REQUESTED,
            data: {
              marketplace_id: marketplace_id,
              type: DICTIONARY_CONDITION_TYPE
            }
          });
        }
      } catch (e) {
        AppInjector.get(PreloaderService).hide();
        yield put({ type: API_CALL_ERROR, error: e });
      }
    }
  }
}

function* watchCreateConditionSucceeded() {
  yield takeEvery(CREATE_CONDITION_SUCCESSED, createConditionDictionary);
}

function* watchDeleteItemRequest() {
  yield takeEvery(DELETE_CONDITION_ATTRIBUTE_REQUESTED, deleteItem);
}

function* deleteItem(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.condition.delete(action.data.id).toPromise();
    yield put({ type: DELETE_CONDITION_ATTRIBUTE_SUCCESSED, data: action.data.id });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditConditionRequested() {
  yield takeEvery(EDIT_CONDITION_REQUESTED, editCondition);
}

function* editCondition(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.condition.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_CONDITION_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update condition success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditConditionDictionaryRequested() {
  yield takeEvery(EDIT_CONDITION_DICTIONARY_REQUESTED, editConditionDictionary);
}

function* editConditionDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_CONDITION_DICTIONARY_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [
  watchFetchConditionAttributeRequested,
  watchCreateConditionRequested,
  watchFetchConditionDictionaryRequested,
  watchCreateConditionSucceeded,
  watchDeleteItemRequest,
  watchEditConditionRequested,
  watchEditConditionDictionaryRequested
];
