import { RENDER_SHIPMENT_EDIT_FORM, FILL_FORM_SHIPMENT, UPDATE_OPTION_INPUT_SHIPMENT, SHIPMENT_EDIT_FORM_CHANGE } from './edit.actions';
import * as _ from 'lodash';
import { support } from '@reflaunt/reactive-form';

export const Edit = (state = { fetched: false, inputs: [], item: {} }, action) => {
  switch (action.type) {
    case RENDER_SHIPMENT_EDIT_FORM:
      return _.assign({}, state, { fetched: true, inputs: action.data.inputs });
    case FILL_FORM_SHIPMENT:
      state.inputs = support.UpdateFormValue(state.inputs, action.data);
      if (action.data.shipment_type) {
        const type_input = _.find(state.inputs, (e) => e.key === 'shipment_type');
        type_input.value = _.find(type_input.options, (e) => e.value === action.data.shipment_type);
      }
      if (action.data.courier_name) {
        const courier_input = _.find(state.inputs, (e) => e.key === 'courier_name');
        courier_input.value = _.find(courier_input.options, (e) => e.value === action.data.courier_name);
      }
      if (action.data.customs_fee_paid_by) {
        const customs_fee_paid_by_input = _.find(state.inputs, (e) => e.key === 'customs_fee_paid_by');
        customs_fee_paid_by_input.value = _.find(customs_fee_paid_by_input.options, (e) => e.value === action.data.customs_fee_paid_by);
      }
      if (action.data.currency_id) {
        const currency_input = _.find(state.inputs, (e) => e.key === 'currency_id');
        currency_input.value = _.find(currency_input.options, (e) => e.value === action.data.currency_id);
      }
      if (action.data.customs_fee_currency_id) {
        const customs_fee_currency_input = _.find(state.inputs, (e) => e.key === 'customs_fee_currency_id');
        customs_fee_currency_input.value = _.find(customs_fee_currency_input.options, (e) => e.value === action.data.customs_fee_currency_id);
      }
      if (action.data.other_taxes_currency_id) {
        const other_taxes_currency_input = _.find(state.inputs, (e) => e.key === 'other_taxes_currency_id');
        other_taxes_currency_input.value = _.find(other_taxes_currency_input.options, (e) => e.value === action.data.other_taxes_currency_id);
      }
      if (action.data.forward_location_id) {
        const forward_location_input = _.find(state.inputs, (e) => e.key === 'forward_location_id');
        forward_location_input.value = _.find(forward_location_input.options, (e) => e.value === action.data.forward_location_id);
      }
      let list_date_inputs = _.filter(state.inputs, { controlType: 'datetimepicker' });
      _.forEach(list_date_inputs, (i, index) => {
        if (action.data.hasOwnProperty(i.key) && !_.isNil(list_date_inputs[index].value)) {
          if (!_.isNil(list_date_inputs[index + 1])) list_date_inputs[index + 1].minDate = list_date_inputs[index].value;
        }
      });
      return _.assign({}, state, { inputs: state.inputs, item: action.data });
    case SHIPMENT_EDIT_FORM_CHANGE:
      state.inputs = support.UpdateFormValue(state.inputs, action.data);
      let list_date_inputs_change = _.filter(state.inputs, { controlType: 'datetimepicker' });
      _.forEach(list_date_inputs_change, (i, index) => {
        if (action.data.hasOwnProperty(i.key) && !_.isNil(action.data[i.key]) && action.data[i.key] !== '') {
          if (!_.isNil(list_date_inputs_change[index + 1])) list_date_inputs_change[index + 1].minDate = action.data[i.key];
        }
      });
      return _.assign({}, state, { inputs: state.inputs });
    default:
      return state;
  }
};
