import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import Condition from '../../../models/Condition';

@Injectable()
export class ConditionService extends ServiceProvider {
  public url = '/api/v1/admin/conditions';
  public model = Condition;
}
