import * as _ from 'lodash';
import {
  FETCH_ALL_MARKETPLACE_SUCCESSED,
  FETCH_ALL_MARKETPLACE_REQUESTED,
  CREATE_INTEGRATION_REQUESTED,
  CREATE_INTEGRATION_SUCCEEDED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_SUCCESSED,
  DELETE_INTEGRATION_REQUESTED,
  DELETE_INTEGRATION_SUCCESSED
} from './create.actions';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../store/action';
import { AppInjector } from '../../../app-injector';
import { ApiService } from '../../../api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { fetchUserProfilePictureOfManyUser } from '../../admin/user/user.saga';

function* watchFetchAllMarketplaceRequested() {
  yield takeLatest(FETCH_ALL_MARKETPLACE_REQUESTED, function*(action: any) {
    try {
      // let result = yield AppInjector.get(ApiService)
      //   .admin.user.list({ roles: 'marketplace', includes: 'marketplace' })
      //   .toPromise();
      let result = yield AppInjector.get(ApiService)
        .admin.user.getMarketplaces()
        .toPromise();
      // result = _.filter(result, item => item.email !== 'hoodies@reflaunt.com');
      const queryParams = AppInjector.get(ActivatedRoute).snapshot.queryParams;
      const marketplace_ids = result.map(item => item.id).join(',');
      const pictures = yield call(fetchUserProfilePictureOfManyUser, marketplace_ids);
      result = _.map(result, marketplace => _.assign(marketplace, { image: _.find(pictures, pic => pic.imageable_id === marketplace.id) }));
      // tslint:disable-next-line:radix
      yield put({ type: FETCH_ALL_MARKETPLACE_SUCCESSED, data: result, component: action.component, marketplace_id: parseInt(queryParams.marketplace_id) });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchFetchAlIntegratedMarketplaces() {
  yield takeLatest(FETCH_ALL_MARKETPLACE_SUCCESSED, function*(action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .integration.list()
        .toPromise();
      yield put({ type: FETCH_ALL_INTEGRATED_MARKETPLACE_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateIntegrationRequested() {
  yield takeLatest(CREATE_INTEGRATION_REQUESTED, function*(action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .integration.create({ marketplace_id: _.head(action.data.map(item => item.id)) })
        .toPromise();
      yield put({ type: CREATE_INTEGRATION_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteIntegrationRequested() {
  yield takeLatest(DELETE_INTEGRATION_REQUESTED, function*(action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .integration.delete(action.data.id)
        .toPromise();
      yield put({ type: DELETE_INTEGRATION_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteIntegrationSuccessed() {
  yield takeLatest(DELETE_INTEGRATION_SUCCESSED, function*(action: any) {
    yield put({ type: FETCH_ALL_MARKETPLACE_REQUESTED });
  });
}

function* watchFetchAllMarketplaceSuccessed() {
  yield takeLatest(CREATE_INTEGRATION_SUCCEEDED, function*(action: any) {
    AppInjector.get(Router).navigate(['integration', action.data.retailer_id, 'edit', action.data.marketplace_id]);
  });
}

export default [
  watchFetchAllMarketplaceRequested,
  watchFetchAlIntegratedMarketplaces,
  watchCreateIntegrationRequested,
  watchDeleteIntegrationRequested,
  watchDeleteIntegrationSuccessed,
  watchFetchAllMarketplaceSuccessed
];
