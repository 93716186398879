import {
  ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED,
  ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_SUCCESSED,
  DELETE_SIZE_ATTRIBUTE_REQUESTED,
  DELETE_SIZE_ATTRIBUTE_SUCCESSED,
  FETCH_SIZE_DICTIONARY_SUCCESSED,
  FETCH_SIZE_DICTIONARY_REQUESTED,
  ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_REQUESTED,
  ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_REQUESTED,
  ADMIN_ATTRIBUTES_SIZE_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_SIZE_FILTER_SELECTED,
  TOGGLE_SIZE_DICTIONARY_VALIDATION_REQUESTED,
  TOGGLE_SIZE_DICTIONARY_VALIDATION_SUCCEEDED
} from './size.action';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_ATTRIBUTES_SELECT_ANY, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { Router } from '@angular/router';
import { sizeAttributes } from './size.const';

function* fetchSizes(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.size.get(action.data).toPromise();
    let selected_marketplace = yield select(state => state.Admin.Attributes.all.marketplace.selected);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_SUCCESSED, data: results.items, pagination: results.pagination });
    if (selected_marketplace) {
      yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selected_marketplace, direct: false, component: 'size' });
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchSizeAttributeRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED, fetchSizes);
}

function* deleteSize(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.admin.size.delete(action.data.id).toPromise();
    yield put({ type: DELETE_SIZE_ATTRIBUTE_SUCCESSED, data: action.data.id });
    notification.show('success', 'Delete size success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* fetchSizeDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_SIZE_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteSizeRequest() {
  yield takeEvery(DELETE_SIZE_ATTRIBUTE_REQUESTED, deleteSize);
}

function* watchFetchSizeDictionaryRequested() {
  yield takeEvery(FETCH_SIZE_DICTIONARY_REQUESTED, fetchSizeDictionary);
}

function* watchUpdateSizeDictionaryRequested() {
  yield takeEvery(ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_REQUEST, updateSizeDictionary);
}

function* updateSizeDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.updateOrCreate(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_SUCCESS, data: result });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_REQUESTED, data: action.requestData });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* toggleSizeDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_SIZE_DICTIONARY_VALIDATION_REQUESTED, toggleSizeDictionaryValidation);
}

function* toggleSizeDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_SIZE_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchSelectAnyFilter() {
  yield takeEvery(ADMIN_ATTRIBUTES_SELECT_ANY, function*(action: any) {
    const attribute = yield select((state: any) => state.Admin.Attributes.all);
    if (action.component && action.component === 'size') {
      yield put({ type: ADMIN_ATTRIBUTES_SIZE_FILTER_SELECTED, data: attribute });
      yield put({
        type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_REQUEST,
        data: {
          marketplace_id: attribute.marketplace.selected.id
        }
      });
    }
  });
}

function* watchFetchAllMarketplaceSizesRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const results = yield api.marketplace.getAllSizes(action.data).toPromise();
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_SUCCESS, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* createSize(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.size.create(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_SUCCESS, data: result, component: action.component });

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'size']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateSizeRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_REQUESTED, createSize);
}

function* updateSize(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.size.update(action.data.id, action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_SUCCESS, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update size success', 5000);

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'size']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateSizeRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_REQUESTED, updateSize);
}

function* watchSynchroniseSizesRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_SIZE_SYNCHRONISE_ATTRIBUTES_REQUEST, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.marketplace.syncAttributes(action.data).toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatus(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          router.navigate(sizeAttributes());
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteSizeRequest,
  watchFetchSizeAttributeRequest,
  watchFetchSizeDictionaryRequested,
  watchUpdateSizeDictionaryRequested,
  watchSelectAnyFilter,
  watchFetchAllMarketplaceSizesRequest,
  watchCreateSizeRequest,
  watchUpdateSizeRequest,
  watchSynchroniseSizesRequest,
  toggleSizeDictionaryValidationRequested
];
