import AsyncProcess from './AsyncProcess';
import Model from './Model';
import User from './User';

export class ProductHistory extends Model {
  user: User;
  async_process: AsyncProcess;

  constructor(options) {
    super();
    (this as any).user = (d) => {
      return new User(d.data);
    };
    (this as any).async_process = (d) => {
      return new AsyncProcess(d.data);
    };
    this.bind(options);
  }

  getUserEmail() {
    return this.user ? this.user.email : '';
  }

  getAsyncProcessName() {
    return this.async_process ? this.async_process.name : '';
  }

  getAsyncProcessId() {
    return this.async_process ? this.async_process.id : '';
  }
}
