import { CREATE_RETAILER_SUCCEEDED, RENDER_CREATE_RETAILER_FORM_REQUESTED, UPDATE_INPUTS_DATA } from './create.actions';
import * as _ from 'lodash';
import { support } from '@reflaunt/reactive-form';

export const create = (state: any = { created: false, item: {} }, action) => {
  switch (action.type) {
    case CREATE_RETAILER_SUCCEEDED:
      return _.assign({}, state, { created: true, item: action.data });
    case RENDER_CREATE_RETAILER_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    // case UPDATE_INPUTS_DATA:
    //   state.inputs = support.UpdateInputsValue(state.inputs, action.data);
    //   return _.assign({}, state, { inputs: state.inputs });
    case UPDATE_INPUTS_DATA:
      return _.assign({}, state, {
        inputs: _.map(state.inputs, input => {
          if (input.key === action.input) {
            input.options = action.data.options;
          }
          return input;
        })
      });
    default:
      return state;
  }
};
