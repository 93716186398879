import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ServiceProvider } from '../service.provider';
import Country from '../../models/Country';

@Injectable()
export class CountryService extends ServiceProvider {
  public url = '/api/v1/countries';
  public model = Country;
}
