import * as _ from 'lodash';
import { GET_LIST_NORMAL_DATASET_SUCCESS } from './create-group.actions';

export const createGroup = (state = { normalDataset: { items: [], fetched: false } }, action) => {
  switch (action.type) {
    case GET_LIST_NORMAL_DATASET_SUCCESS: {
      return Object.assign({}, state, { normalDataset: { items: action.data, fetched: true } });
    }
    default:
      return state;
  }
};
