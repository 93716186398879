import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import ArrayList from '../../models/ArrayList';
import { BlockchainEvent } from '../../models/Blockchain/BlockchainEvent';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { AppInjector } from '../../app-injector';
import * as _ from 'lodash';
import BlockchainProvider from '../../models/BlockchainProvider';

@Injectable()
export class BlockchainService extends ServiceProvider {
  public url = '/api/v1/blockchain';
  public model = BlockchainEvent;

  getFullTransferHistories(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/transfers').pipe(
      map(result => {
        if (result === null) {
          return null;
        }
        return new ArrayList<BlockchainEvent>(BlockchainEvent, (result as any).data);
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  claimCertificate(data): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/transfer', data).pipe(
      tap(result => {
        // console.log(result);
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }

  listProviders(): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/providers').pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      map(result => _.map((result as any).data, item => new BlockchainProvider(item))),
      catchError(error => {
        throw error;
        AppInjector.get(PreloaderService).hide();
      })
    );
  }
}
