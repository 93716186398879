import * as _ from 'lodash';
import {
  RENDER_EDIT_SHIPMENT_DESTINATION_ADDRESS_FORM_REQUESTED,
  ADMIN_GET_USER_ADDRESS_SUCCEEDED,
  FILL_USER_ADDRESS_DETAIL_FORM,
  FILL_DATA_INPUTS_OPTIONS_EDIT_ADDRESS_FORM_REQUESTED,
  UPDATE_ALPHA_2_CODE_VALUE_EDIT_ADDRRESS_REQUESTED
} from './edit.action';
import { support } from '@reflaunt/reactive-form';

const Input = (state: any = {}, action) => {
  switch (action.type) {
    case FILL_USER_ADDRESS_DETAIL_FORM:
      if (!_.isUndefined(_.get(action.data, state.key))) {
        state.value = _.get(action.data, state.key);
      }
      return state;
    default:
      return state;
  }
};

export const edit = (state = { fetched: false, updated: false, item: {}, inputs: [] }, action) => {
  switch (action.type) {
    case RENDER_EDIT_SHIPMENT_DESTINATION_ADDRESS_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case FILL_DATA_INPUTS_OPTIONS_EDIT_ADDRESS_FORM_REQUESTED:
      state.inputs = support.UpdateInputsValue(state.inputs, action.data);
      return _.assign({}, state, { inputs: state.inputs });
    case ADMIN_GET_USER_ADDRESS_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });
    case FILL_USER_ADDRESS_DETAIL_FORM:
      return _.assign({}, state, { id: action.data.id, inputs: _.map(state.inputs, (item) => Input(item, action)) });
    case UPDATE_ALPHA_2_CODE_VALUE_EDIT_ADDRRESS_REQUESTED:
      console.log('___state', state.inputs);
      const phone_code_input = _.find(state.inputs, (i) => i.key === 'phonecode');
      return _.assign({}, state, {
        inputs: support.UpdateFormValue(
          state.inputs,
          _.assign({}, action.data, {
            country_alpha2_code: action.data.country.alpha2Code,
            phonecode: {
              code: action.data.country.callingCodes[0],
              value: !_.isNil(phone_code_input.value) && phone_code_input.value.value ? phone_code_input.value.value : '',
              alpha2Code: action.data.country.alpha2Code
            }
          })
        )
      });
    default:
      return state;
  }
};
