import {
  FETCH_MARKETPLACE_SUCCEEDED,
  FETCH_MARKETPLACE_REQUESTED,
  SORT_MARKETPLACE_REQUESTED,
  SORT_MARKETPLACE_SUCCEEDED,
  CHANGE_USER_STATUS_REQUESTED,
  GET_MANUAL_LOGIN_URL_REQUESTED
} from './list.actions';
import { API_CALL_ERROR } from '../../../../store/action';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery, takeLatest, select, call, all } from 'redux-saga/effects';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import * as $ from 'jquery';
import Cookies from 'js-cookie';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

function* getMarketplaces(action) {
  const api = AppInjector.get(ApiService);
  try {
    let data: any = {};
    let sorts = [];
    // tslint:disable-next-line:forin
    for (const key in action.data.pagination) {
      data[key] = action.data.pagination[key];
    }
    if (_.isNil(data.page)) {
      data.page = 1;
    }
    if (_.isNil(data.per_page)) {
      data.per_page = 100;
    }
    if (!_.isNil(action.data.filter)) {
      data.constraints = JSON.stringify(action.data.filter);
    }
    if (!_.isNil(action.data.includes)) {
      data.includes = action.data.includes;
    }
    if (!_.isNil(action.data.sort)) {
      for (const key in action.data.sort) {
        if (!_.isNil(action.data.sort[key])) {
          sorts.push(action.data.sort[key] + key);
        }
      }
    }
    if (sorts.length !== 0) {
      data.sort = _.join(sorts, ',');
    }
    const results_retailer = yield AppInjector.get(ApiService).admin.user.getMarketplaces().toPromise();
    const results = yield AppInjector.get(ApiService).user.get(data).toPromise();
    const sortQueryParams = AppInjector.get(ActivatedRoute).snapshot.queryParams.sort;
    yield put({
      type: FETCH_MARKETPLACE_SUCCEEDED,
      data: Cookies.get('role_slug') === 'retailer' ? results_retailer : results.items,
      pagination: results.pagination,
      sortQueryParams: sortQueryParams
    });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchMarketplacesRequest() {
  yield takeEvery(FETCH_MARKETPLACE_REQUESTED, getMarketplaces);
}

function* watchChangeUserStatusRequested() {
  yield takeLatest(CHANGE_USER_STATUS_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.user.changeStatus(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Status updated', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetManualLoginUrlRequested() {
  yield takeLatest(GET_MANUAL_LOGIN_URL_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.user.getAllServiceConfigurations(action.data.id, { key: action.data.key }).toPromise();
      if (result && result.length > 0) {
        window.open(result[0].value);
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetMiintoPendingOrdersRequested() {
  yield takeLatest('GET_MIINTO_PENDING_ORDERS_REQUESTED', function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notification = AppInjector.get(NotificationService);
    try {
      const result = yield api.marketplace.triggerMiintoOrders().toPromise();
      const { async_process_name } = result;
      const timestamp = async_process_name.split('---')[1];

      AppInjector.get(PreloaderService).hide();
      notification.show(
        'success',
        `It may take few minutes to process the orders. Please check the result in these <a href="/admin/product/async-process?search=order---${timestamp}" target="_blank">async processes</a>`,
        20000
      );
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
function* watchGetReebonzPendingOrdersRequested() {
  yield takeLatest('GET_MARKETPLACE_PENDING_ORDERS_REQUESTED', function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.order.triggerOrdersMarketplace({ email: action.email }).toPromise();
      let totalOrders = '';
      let acceptOrders = '';
      let notExistInReflaunt = '';
      let notListedInReflaunt = '';
      if (result.total.length > 0) {
        for (const i of result.total) {
          totalOrders =
            totalOrders +
            `<p style="font-size: 14px; text-align: left">Marketplace order Id: ${i.market_order_id} (${i.country}) - Reflaunt product id: ${i.reflaunt_product_id}</p>`;
        }
      }
      if (result.accept.length > 0) {
        for (const i of result.accept) {
          acceptOrders =
            acceptOrders +
            `<p style="font-size: 14px; text-align: left">Marketplace order Id: ${i.market_order_id} (${i.country}) - Reflaunt product id: ${i.reflaunt_product_id}</p>`;
        }
      }
      if (result.not_exist_in_reflaunt && result.not_exist_in_reflaunt.length > 0) {
        const uniqId = _.uniq(result.not_exist_in_reflaunt);
        notExistInReflaunt = `<p style="font-size: 14px; text-align: left"><span style="font-size: 16px; font-weight: bold; margin-top: 30px;">Products don't exist in Reflaunt system:</span> ${uniqId.join(
          ', '
        )}</p>`;
      }
      if (result.not_listed_in_reflaunt && result.not_listed_in_reflaunt.length > 0) {
        const uniqId = _.uniq(result.not_listed_in_reflaunt);
        notListedInReflaunt = `<p style="font-size: 14px; text-align: left"><span style="font-size: 16px; font-weight: bold; margin-top: 30px;">Products aren't listed on Reebonz in Reflaunt system:</span> ${uniqId.join(
          ','
        )}</p>`;
      }
      (Swal as any)
        .fire({
          html: ` <h4>Pending Order</h4>
          <p style="font-size: 16px; margin-top: 30px; font-weight: bold; text-align: left">Total pending orders: ${result.total.length}</p>
          ${totalOrders}
          <p style="font-size: 16px; margin-top: 30px; font-weight: bold; text-align: left">Total accepted orders: ${result.accept.length}</p>
          ${acceptOrders}
          ${notExistInReflaunt}
          ${notListedInReflaunt}
          `,
          confirmButtonText: `OK`
        })
        .then((result) => {});
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchExportMiintoFeedFileRequested() {
  yield takeLatest('EXPORT_FEED_FILE_REQUESTED', function* (action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const export_data = yield AppInjector.get(ApiService).marketplace.getExportData().toPromise();
      const result = yield AppInjector.get(ApiService).marketplace.uploadMiintoFeedFile({ data: export_data.data }).toPromise();
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(NotificationService).show('success', `${result.no} was be added, <a href="${result.url}">Click here to Download</a>`);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      AppInjector.get(PreloaderService).hide();
    }
  });
}

export default [
  watchFetchMarketplacesRequest,
  watchChangeUserStatusRequested,
  watchGetMiintoPendingOrdersRequested,
  watchExportMiintoFeedFileRequested,
  watchGetManualLoginUrlRequested,
  watchGetReebonzPendingOrdersRequested
];
