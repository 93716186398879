export const FETCH_DATA_ADMIN_ORDER_ITEM_REQUESTED = 'FETCH_DATA_ADMIN_ORDER_ITEM_REQUESTED';
export const FETCH_DATA_ADMIN_ORDER_ITEM_SUCCESSED = 'FETCH_DATA_ADMIN_ORDER_ITEM_SUCCESSED';


export const fetchAdminOrderItemRequested = (payload) => {
    return {
        type: FETCH_DATA_ADMIN_ORDER_ITEM_REQUESTED,
        payload: payload,
    };
};
