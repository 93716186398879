import { put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { NORMAL_GROUP_TYPE } from '../../../../models/Dataset';
import {
  EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_REQUEST,
  EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_SUCCESS,
  EDIT_GROUP_DATASET_GET_DATASET_DETAIL_REQUEST,
  EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS,
  EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_REQUEST,
  EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_SUCCESS,
  EDIT_GROUP_DATASET_INIT_FORM,
  EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_REQUEST,
  EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_SUCCESS
} from './edit-group.actions';
import { API_CALL_ERROR } from '../../../../store/action';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { Router } from '@angular/router';
import { listRouter } from '../dataset.const';

function* watchGetListNormalDatasetRequest() {
  yield takeLatest(EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.dataset.list({ constraints: { group_type: NORMAL_GROUP_TYPE } }).toPromise();
      yield put({ type: EDIT_GROUP_DATASET_GET_LIST_NORMAL_DATASET_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetDatasetDetailRequest() {
  yield takeLatest(EDIT_GROUP_DATASET_GET_DATASET_DETAIL_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const { datasetId, formGroup } = action.data;
    try {
      const result = yield api.dataset.getItemById(datasetId).toPromise();
      yield put({ type: EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS, data: { result, formGroup } });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetDatasetDetailSuccess() {
  yield takeLatest(EDIT_GROUP_DATASET_GET_DATASET_DETAIL_SUCCESS, function* (action: any) {
    const { result, formGroup } = action.data;
    yield put({ type: EDIT_GROUP_DATASET_INIT_FORM, data: { result, formGroup } });
    yield put({ type: EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_REQUEST, data: result });
  });
}

function* watchGetDatasetChildrenRequest() {
  yield takeLatest(EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const dataset = action.data;
    try {
      const result = yield api.dataset.getChildren(dataset.id).toPromise();
      yield put({ type: EDIT_GROUP_DATASET_GET_DATASET_CHILDREN_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateDatasetGroupRequest() {
  yield takeLatest(EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);
    const notification = AppInjector.get(NotificationService);
    const { datasetId, payload } = action.data;
    try {
      const result = yield api.dataset.updateGroup(datasetId, payload).toPromise();
      yield put({ type: EDIT_GROUP_DATASET_UPDATE_DATASET_GROUP_SUCCESS, data: result });
      notification.show('success', 'Update dataset group success', 3000);
      router.navigate(listRouter());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetListNormalDatasetRequest, watchGetDatasetDetailRequest, watchGetDatasetDetailSuccess, watchGetDatasetChildrenRequest, watchUpdateDatasetGroupRequest];
