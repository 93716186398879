import * as _ from 'lodash';
import {
  GET_SHIPMENT_LANDING_PAGE_SUCCEEDED,
  BUY_SHIPPING_LABEL_LANDING_PAGE_SUCCEEDED, BUY_SHIPPING_LABEL_LANDING_PAGE_FAILED
} from './buy-shipping-label.actions';

export const ShippingLabel = (state: any = { fetched: false, bought: false, isShowFirstStep: true }, action) => {
  switch (action.type) {
    case GET_SHIPMENT_LANDING_PAGE_SUCCEEDED:
      return _.assign({}, state, { fetched: true, shipment: action.data.shipment, product: action.data.product, retrieve: action.data.retrieve });
    case BUY_SHIPPING_LABEL_LANDING_PAGE_SUCCEEDED:
      return _.assign({}, state, { bought: true, shipment: action.data });
    case BUY_SHIPPING_LABEL_LANDING_PAGE_FAILED:
      return _.assign({}, state, { shipment: action.data });
    default:
      return state;
  }
};
