import * as _ from 'lodash';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AuthModule } from './auth/auth.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainComponent } from './main.component';
import { ProfileModule } from './profile/profile.module';
import { ProfileComponent } from './profile/profile.component';
import { CollectionBarcodeComponent } from '../common/print-template/collection-barcode/collection-barcode.component';
import { BarcodeComponent } from '../common/print-template/product-barcode/barcode.component';
import { PrintTemplateModule } from '../common/print-template/print-template.module';
import { SharedModule } from '../template/shared/shared.module';
import { SettingsModule } from './settings/settings.module';
import { IntegrationComponent } from './integration/integration.component';
import { IntegrationModule } from './integration/integration.module';
import { AdminModule } from './admin/admin.module';
import { RetailerModule } from './retailer/retailer.module';
import { CustomerComponent } from './customer/customer.component';
import { CustomerModule } from './customer/customer.module';
import { BaseComponent } from './base.component';
import { BlockchainModule } from './blockchain/blockchain.module';
import { BlockchainComponent } from './blockchain/blockchain.component';
import { PipesModule } from '../common/pipes/pipes.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { DirectivesModule } from '../common/directives/directives.module';
import { CredentialModule } from './credential/credential.module';
import { AttributeModule } from './attribute/attribute.module';
import { ProxyPageModule } from './proxy-page/proxy-page.module';
import { MeasurementUnitModule } from './measurement-unit/measurement-unit.module';

@NgModule({
  declarations: [BaseComponent, IntegrationComponent, PageNotFoundComponent, MainComponent, ProfileComponent, CustomerComponent, BlockchainComponent, LandingPageComponent],
  imports: [
    PipesModule,
    IntegrationModule,
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    AuthModule,
    ProfileModule,
    SweetAlert2Module,
    PrintTemplateModule,
    BrowserAnimationsModule,
    SettingsModule,
    AdminModule,
    RetailerModule,
    CustomerModule,
    BlockchainModule,
    MarketplaceModule,
    DirectivesModule,
    CredentialModule,
    AttributeModule,
    MeasurementUnitModule,
    ProxyPageModule
  ],
  entryComponents: [BarcodeComponent, CollectionBarcodeComponent],
  providers: [],
  exports: []
})
export class ComponentsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ComponentsModule
    };
  }
}
