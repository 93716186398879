import certificateSaga from './certificate/certificate.saga';
import itemNotAvailable from './item-not-available/item-not-available.saga';
import shippingLabel from './buy-shipping-label/buy-shipping-label.saga';
import checkoutPaymentSaga from './checkout-payment/checkout-payment.saga';
import consignmentAgreementSaga from './consignment-agreement/consignment-agreement.saga';
import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_SELLER_CONFIRM_ADDRESS_REQUESTED,
  UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED,
  SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_REQUESTED,
  SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_SUCCEEDED,
  RENDER_FILL_CONFIRM_ADDRESS_FORM_REQUESTED,
  UPDATE_INPUTS_ADDRESS_LANDING_PAGE_REQUESTED
} from './landing-page.actions';
import { AppInjector } from '../../app-injector';
import { ApiService } from '../../api/api.service';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { API_CALL_ERROR } from '../../store/action';
import { CONFIRM_ADDRESS_COM } from './confirm-address/confirm-address.consts';
import { BUY_SHIPPING_LABEL_COM } from './buy-shipping-label/buy-shipping-label.consts';
import { SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED } from './checkout-payment/checkout-payment.actions';
import { CHECKOUT_PAYMENT_COM } from './checkout-payment/checkout-payment.consts';
import { Countries } from '../admin/user/address/countries';
import * as _ from 'lodash';
import { BUY_SHIPPING_LABEL_LANDING_PAGE_REQUESTED } from './buy-shipping-label/buy-shipping-label.actions';

function* renderForm(action) {
  const COUNTRIES = Countries;
  const optionsData = {
    country: _.map(COUNTRIES, (item, key) => _.assign(item, { value: item.name, label: item.name }))
  };
  yield put({
    type: UPDATE_INPUTS_ADDRESS_LANDING_PAGE_REQUESTED,
    data: optionsData
  });
}

function* watchRenderAddressLandingPageFormRequest() {
  yield takeEvery(RENDER_FILL_CONFIRM_ADDRESS_FORM_REQUESTED, renderForm);
}

function* watchGetSellerConfirmAddressDetailRequest() {
  yield takeEvery(GET_SELLER_CONFIRM_ADDRESS_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      let result = yield api.admin.address.getSellerAddressByToken(action.data.token, action.data).toPromise();
      if (Object.keys(result).length > 0) {
        yield put({ type: UPDATE_SELLER_CONFIRM_ADDRESS_FROM_REQUESTED, data: result });
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSaveSellerConfirmAddressDetailRequest() {
  yield takeEvery(SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_REQUESTED, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      yield api.admin.address.saveAddressByToken(action.data).toPromise();
      switch (action.com) {
        case CONFIRM_ADDRESS_COM:
          yield put({ type: SAVE_SELLER_CONFIRM_ADDRESS_DETAIL_SUCCEEDED });
          break;
        case BUY_SHIPPING_LABEL_COM:
          yield put({ type: SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED });
          if (action.data.auto_buy_shipping_label) {
            yield put({ type: BUY_SHIPPING_LABEL_LANDING_PAGE_REQUESTED, data: {
              token: action.data.token,
              transaction_id: action.data.transaction_id,
              shipmentId: action.data.shipment_id
            } });
          }
          break;
        case CHECKOUT_PAYMENT_COM:
          yield put({ type: SAVE_SELLER_ADDRESS_DETAIL_SUCCEEDED });
          break;
      }
      window.scrollTo(0, 0);
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  ...certificateSaga,
  ...itemNotAvailable,
  ...shippingLabel,
  ...checkoutPaymentSaga,
  ...consignmentAgreementSaga,
  watchGetSellerConfirmAddressDetailRequest,
  watchSaveSellerConfirmAddressDetailRequest,
  watchRenderAddressLandingPageFormRequest
];
