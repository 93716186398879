import { Store } from '../../../store/store.module';
import { FETCH_ENDPOINT_REQUESTED, CREATE_ENDPOINT_REQUESTED, UPDATE_ENDPOINT_REQUESTED, DELETE_ENDPOINT_REQUESTED } from './endpont.actions';
import { Component, OnInit, AfterViewInit } from '@angular/core';
declare const $: any;

@Component({
  selector: 'app-endpoint',
  templateUrl: './endpoint.component.html',
  styleUrls: ['./endpoint.component.scss']
})
export class EndpointComponent implements OnInit, AfterViewInit {
  public store;
  public event = 'add';
  public data: any = {
    url: '',
    type: ''
  };
  constructor(store: Store) {
    this.store = store.getInstance();
    this.store.dispatch({ type: FETCH_ENDPOINT_REQUESTED });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onSubmit() {
    if (this.event === 'add') {
      this.store.dispatch({ type: CREATE_ENDPOINT_REQUESTED, data: this.data });
    }
    if (this.event === 'update') {
      this.store.dispatch({ type: UPDATE_ENDPOINT_REQUESTED, data: this.data });
    }
  }

  addNew() {
    this.event = 'add';
    this.setDefaultData();
    $('#myEndpointModal').modal('show');
  }

  update(item) {
    this.event = 'update';
    this.data = {
      id: item.id,
      url: item.url,
      type: item.type
    };
    $('#myEndpointModal').modal('show');
  }

  delete() {
    if (confirm('Are you sure to delete this Endpoint?')) {
      this.store.dispatch({ type: DELETE_ENDPOINT_REQUESTED, data: this.data });
    }
  }

  setDefaultData() {
    this.data = {
      url: '',
      type: ''
    };
  }
}
