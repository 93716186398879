export const FETCH_ALL_ADMIN_ORDER_REQUESTED = 'FETCH_ALL_ADMIN_ORDER_REQUESTED';
export const FETCH_ALL_ADMIN_ORDER_SUCCESSED = 'FETCH_ALL_ADMIN_ORDER_SUCCESSED';
export const GET_ALL_RETAILER_IN_ORDER_PAGE_REQUESTED = 'GET_ALL_RETAILER_IN_ORDER_PAGE_REQUESTED';
export const GET_ALL_RETAILER_IN_ORDER_PAGE_SUCCESSED = 'GET_ALL_RETAILER_IN_ORDER_PAGE_SUCCESSED';
export const FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_REQUESTED = 'FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_REQUESTED';
export const FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_SUCCESSED = 'FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_SUCCESSED';
export const ORDER_UPDATE_INPUT_FILTER_DATE = 'ORDER_UPDATE_INPUT_FILTER_DATE';


export const LIST_ORDER_SUPPORT_PARAMS = [
  'sort',
  'constraints',
  'page',
  'per_page',
  'search',
  'sold_date',
  'start_date',
  'end_date',
  'date_filter_type',
  'order_sale_amount',
  'order_item_sale_amount',
  'order_status',
  'order_item_status',
  'marketplace_id',
  'shipment_status',
  'retailer_id',
  'reached_customer_date'
];
