import { ApiService } from '../../../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AppInjector } from '../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-account',
  templateUrl: './payment-account.component.html',
  styleUrls: ['./payment-account.component.scss']
})
export class PaymentAccountComponent implements OnInit {
  id;
  payment_account;
  payment_methods;
  payment_method_id;
  amount;
  payment;
  fetched;
  payment_method_active;
  selected_payment_method;
  customer_id;
  list_currencies;
  list_countries;
  submitted;
  allow_currency_list;
  validationEmail;
  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) {
    this.id = this.route.snapshot.params.id;
    this.api.admin.payment.getDefaultPaymentAccountByUserID({ user_id: this.id }).subscribe(
      (res) => {
        this.api.currency.get({ per_page: 10000 }).subscribe((currencies) => {
          this.list_currencies = currencies.items;
          const payment_method_name = res.payment_method.data.name;
          this.getAllowCurrencyList(payment_method_name);
        });
        this.api.country.list().subscribe((countries) => {
          this.list_countries = countries;
        });
        this.api.admin.user.show(this.id).subscribe((customer) => {
          this.payment_account = customer.payment_accounts.data;
          this.api.admin.payment.getAllPaymentMethod().subscribe((paymentMethods) => {
            this.payment_methods = paymentMethods;
            this.payment_methods.forEach((e) => {
              if (e.payment_method_input_fields.data.length > 0) {
                e = _.map(e.payment_method_input_fields.data, (o) =>
                  _.assign(o, { name: o.detail ? o.detail.data.name : undefined, display_name: o.display ? o.display.data.display_name : undefined })
                );
              }
            });
            const keep_item = _.find(this.payment_account, (e) => e.payment_method_id === res.payment_method_id && e.id === res.id);
            _.remove(this.payment_account, (e) => e !== keep_item && (e as any).payment_method_id === keep_item.payment_method_id);
            for (const e of this.payment_methods) {
              for (const i of this.payment_account) {
                if (e.id === i.payment_method_id) {
                  if (i.data) {
                    for (const key in i.data) {
                      if (Object.prototype.hasOwnProperty.call(i.data, key)) {
                        const element = i.data[key];
                        for (const k of e.payment_method_input_fields.data) {
                          if (key === k.name) {
                            _.assign(k, { value: element });
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            this.api.admin.payment.getPaymentAccountDefault(res.id).subscribe((res) => {
              this.fetched = true;
              this.payment_method_id = res.payment_method_id;
              this.selected_payment_method = res.payment_method.data;
              this.payment_method_active = res.payment_method.data.payment_method_input_fields.data.map((e) =>
                _.assign(e, { name: e.detail ? e.detail.data.name : undefined, display_name: e.display ? e.display.data.display_name : undefined })
              );
              for (const key in res.data) {
                if (Object.prototype.hasOwnProperty.call(res.data, key)) {
                  const element = res.data[key];
                  for (const i of this.payment_method_active) {
                    if (key === i.name) {
                      _.assign(i, { value: element });
                    }
                  }
                }
              }
            });
          });
        });
      },
      (err) => {
        Swal.fire({
          title: `You don't have any payment accounts!`,
          text: 'Add your payment account!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes!',
          cancelButtonText: 'Back'
        }).then((result) => {
          if (result.value) {
            this.router.navigate([`/admin/customer/${this.id}/add-payment-account`]);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.router.navigate(['/admin/customer']);
          }
        });
      }
    );
  }

  ngOnInit() {}
  changePaymentMethod(e) {
    this.selected_payment_method = _.find(this.payment_methods, (o) => o.id === Number(e));
    this.payment_method_active = this.selected_payment_method.payment_method_input_fields.data;
    const paymentMethodName = this.selected_payment_method.name;
    this.getAllowCurrencyList(paymentMethodName);
  }
  onSubmit(form) {
    this.submitted = true;
    if (form.valid) {
      const data = _.cloneDeep(form.value);
      delete data['amount'];
      delete data['payment_method'];
      this.api.admin.payment
        .updatePaymentAccount({
          user_id: this.id,
          payment_method_id: this.selected_payment_method.id,
          data: data
        })
        .subscribe(() => {
          AppInjector.get(NotificationService).show('success', 'Update payment account success', 2000);
          this.router.navigate([]);
        });
    }
  }
  validateEmail(email) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true;
    }
    return false;
  }
  getAllowCurrencyList(paymentMethodName) {
    if (paymentMethodName === 'TRANSFER_IBAN') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'EUR' || e.code === 'GBP');
    } else if (paymentMethodName === 'TRANSFER_UK_LOCAL') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'GBP');
    } else if (paymentMethodName === 'TRANSFER_SG_LOCAL') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'SGD');
    } else if (paymentMethodName === 'TRANSFER_USA_LOCAL') {
      this.allow_currency_list = _.filter(this.list_currencies, (e) => e.code === 'USD');
    }
  }
  validateCurrencyCode(currency_code) {
    if (currency_code) {
      return true;
    }
    return false;
  }
  validateCountryCode(country_code) {
    if (country_code) {
      return true;
    }
    return false;
  }
}
