import Model from './Model';

export const STATUS_RETURN_TO_SELLER_IN_PROGRESS = 'Return to seller in progress';
export const STATUS_RETURN_TO_SELLER_COMPLETE = 'Return to seller complete';
class Status extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default Status;

export const SELLING_STATUS = 'selling';
export const UNQUOTED_STATUS = 'unquoted';
export const PENDING_STATUS = 'pending';

export const PRODUCT_ITEM_STATUS = {
  PENDING: "pending",
  DRAFT: "draft",
  WAITING: "waiting",
  SELLING: "selling",
  SOLD: "sold",
  SOLD_CONFIRMED: "sold-confirmed",
  IN_DELIVERY: "in-delivery",
  PENDING_PAYMENT: "pending-payment",
  PAYMENT_SENT: "payment-sent", // when we send the payment
  PAYMENT_COMPLETED: "payment-completed", // when  we verify the payment is on the bank account and the accountant verify
  RETURNING: "returning",
  RETURNED: "returned",
  CANCELLED: "canceled", // product item is not sold, still accountable as product overall quantity but not to be published into marketplace
  REJECTED: "rejected",
  INCOMPLETE: "incomplete",
  RETURN_TO_SELLER_INPROGRESS: "return-to-seller-in-progress",
  IMAGE_PROCESSING: "image-processing",
  QUOTED: "quoted",
  QUOTE_FINALISED: "quote-finalised",
  REJECTE_BY_WAREHOUSE: "rejected-by-warehouse",
  REJECTED_BY_SELLER: "rejected-by-seller",
  UNQUOTED: "unquoted",
  LOST: "lost",
  DELETED: "deleted",
  RETURN_TO_SELLER_COMPLETE: "return-to-seller-complete",
};
