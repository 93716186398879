import { takeEvery, put, takeLatest, all, call, select } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import {
  GET_SHIPMENT_DETAIL_ADMIN_REQUESTED,
  GET_SHIPMENT_DETAIL_ADMIN_SUCCEEDED,
  UPDATE_SHIPMENT_ADMIN_REQUESTED,
  BUY_SHIPPING_LABEL_REQUESTED,
  BUY_SHIPPING_LABEL_SUCCEEDED,
  SEND_SHIPMENT_TO_SELLER_REQUESTED,
  SEND_CONFIRM_ADDRESS_REQUESTED,
  UPDATE_SHIPMENT_STATUS_ADMIN_REQUESTED,
  UPDATE_SHIPMENT_STATUS_ADMIN_SUCCESSED,
  SHIPMENT_DETAIL_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED,
  RENDER_UPDATE_SHIPMENT_FORM_REQUESTED,
  RE_GENERATE_SHIPPING_LABEL_REQUESTED,
  GENERATE_INVOICE_REQUESTED
} from './detail.actions';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { CREATED_STATUS_SHIPMENT } from '../list/list.actions';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import Shipment, { SHIPMENT_STATUS_SHIPPED } from '../../../../models/Shipment';
import { ORDER_ITEM_STATUS_SOLD_CONFIRMED } from '../../../../models/OrderProduct';
import { Router } from '@angular/router';

function* watchGetShipmentDetailRequested() {
  yield takeEvery(GET_SHIPMENT_DETAIL_ADMIN_REQUESTED, function* (action: any) {
    try {
      let retrieves = null;
      let result = yield AppInjector.get(ApiService).admin.shipment.getItemById(action.data).toPromise();
      if (result.product) {
        const { service_type_id, retailer_id } = result.product;
        if (service_type_id) {
          const retailer_services = yield AppInjector.get(ApiService).admin.user.getRetailerService(retailer_id, { service_type_id: service_type_id }).toPromise();
          if (retailer_services && retailer_services.length > 0) {
            result.default_retailer_service = _.head(retailer_services);
          }
        }
      }
      let transactions = [];
      if (result.order_product) {
        transactions = yield AppInjector.get(ApiService).admin.transaction.list({ order_product_id: result.order_product.id }).toPromise();
      }
      const products = yield AppInjector.get(ApiService).admin.shipment.getAllProduct(action.data).toPromise();
      // if (result.type === 'easyship' && result.status === CREATED_STATUS_SHIPMENT) {
      //   retrieves = yield AppInjector.get(ApiService)
      //     .admin.shipment.getRetrieveAvailable(result.id)
      //     .toPromise();
      // }
      yield put({ type: GET_SHIPMENT_DETAIL_ADMIN_SUCCEEDED, data: result, transaction: transactions, retrieves: retrieves, products: products });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateShipmentDetailRequested() {
  yield takeEvery(UPDATE_SHIPMENT_ADMIN_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.shipment.update(action.data.id, action.data).toPromise();
      yield AppInjector.get(ApiService).admin.shipment.sendNotificationWhenShippingLabelUpload(action.data.id).toPromise();
      AppInjector.get(NotificationService).show('success', 'The shipment was completed', 3000);
      yield put({ type: GET_SHIPMENT_DETAIL_ADMIN_REQUESTED, data: action.data.id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchBuyShippingLabelRequest() {
  yield takeEvery(BUY_SHIPPING_LABEL_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      yield api.admin.shipment.buyShippingLabel(action.data).toPromise();
      //AppInjector.get(NotificationService).show('success', 'The shipment was completed', 3000);
      yield put({ type: GET_SHIPMENT_DETAIL_ADMIN_REQUESTED, data: action.data.shipmentId });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSendShipmentToSellerRequest() {
  yield takeEvery(SEND_SHIPMENT_TO_SELLER_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      AppInjector.get(PreloaderService).show();
      yield AppInjector.get(ApiService).admin.shipment.sendShipmentToSeller({ shipmentId: action.data.shipmentId }).toPromise();
      // yield AppInjector.get(ApiService).admin.shipment.update(action.data.shipmentId, action.data).toPromise();
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(Router).navigate([]);
      AppInjector.get(NotificationService).show('success', 'The shipment was send.', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSendConfirmAddressSellerRequest() {
  yield takeEvery(SEND_CONFIRM_ADDRESS_REQUESTED, function* (action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      yield AppInjector.get(ApiService).admin.shipment.sendConfirmAddressToSeller(action.data).toPromise();
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(NotificationService).show('success', 'An Email was send to seller.', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* updateShipmentStatusRequest() {
  yield takeEvery(UPDATE_SHIPMENT_STATUS_ADMIN_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService).admin.shipment.update(action.data.id, action.data).toPromise();
      if (action.data.shipment_note) {
        yield AppInjector.get(ApiService).admin.shipment.sendNotificationWhenShippingNoteEdited(action.data.id).toPromise();
      }
      if (action.data.shipping_label_url) {
        yield AppInjector.get(ApiService).admin.shipment.sendNotificationWhenShippingLabelUpload(action.data.id).toPromise();
      }
      AppInjector.get(NotificationService).show('success', 'The shipment was delivered.', 3000);
      AppInjector.get(Router).navigate([], { queryParamsHandling: 'merge' });
      yield put({ type: UPDATE_SHIPMENT_STATUS_ADMIN_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* ShipmentDetailUpdateOrderProductRequested() {
  yield takeLatest(SHIPMENT_DETAIL_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED, function* (action: any) {
    try {
      if (action.data.status === ORDER_ITEM_STATUS_SOLD_CONFIRMED) {
        const transactions = yield select((state) => (state as any).Admin.Shipment.detail.transaction);
        const item = _.find(transactions, (e) => e.order_product_id === action.id);
        if (!item) {
          yield AppInjector.get(NotificationService).show('warning', 'Transaction is undefined', 2000);
        } else {
          const marketplace_id = item.marketplace_id;
          action.data.marketplace_id = marketplace_id;
          yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
          yield AppInjector.get(ApiService).admin.product.setProductToSoldConfirm(action.data.product_id, action.data).toPromise();
        }
      } else {
        yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
      }
      AppInjector.get(Router).navigate([]);
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* updateFormManualShipment() {
  yield takeLatest(RENDER_UPDATE_SHIPMENT_FORM_REQUESTED, function* (action: any) {
    try {
      let currency = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      currency = currency.items;
      const currency_inputs = _.find(action.data.inputs, (e) => e.key === 'currency');
      currency_inputs.options = _.map(currency, (e) => _.assign({}, { value: e.id, label: e.code }));

      let couriers = yield AppInjector.get(ApiService).admin.shippingMethod.list().toPromise();
      const courier_input = _.find(action.data.inputs, (e) => e.key === 'courier_name');
      courier_input.options = _.map(couriers, (i) => ({ ...i, ...{ label: i.name, value: i.code } }));

      let aftership_code = yield AppInjector.get(ApiService).admin.courier.list().toPromise();
      const shipment_courier_input = _.find(action.data.inputs, (e) => e.key === 'aftership_code');
      shipment_courier_input.options = _.map(aftership_code, (i) => ({ ...i, ...{ label: i.name, value: i.code } }));

      let countries = yield AppInjector.get(ApiService).admin.country.list().toPromise();
      const countries_list = _.find(action.data.inputs, (e) => e.key === 'country');
      countries_list.options = _.map(countries, (i) => ({ ...i, ...{ label: i.name, value: i.code } }) )

    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchReGenerateShippingLabelRequest() {
  yield takeEvery(RE_GENERATE_SHIPPING_LABEL_REQUESTED, function* (action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const result = yield AppInjector.get(ApiService).admin.shipment.reGenerateShippingLabel({ shipment_id: action.data.id }).toPromise();
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(Router).navigate([]);
    } catch (e) {
      AppInjector.get(PreloaderService).hide();
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGenerateShippingInvoiceRequest() {
  yield takeEvery(GENERATE_INVOICE_REQUESTED, function* (action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const result = yield AppInjector.get(ApiService).admin.shipment.generateShippingInvoice({ shipment_id: action.data.id }).toPromise();
      AppInjector.get(PreloaderService).hide();
      AppInjector.get(Router).navigate([]);
    } catch (e) {
      AppInjector.get(PreloaderService).hide();
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}


export default [
  watchGetShipmentDetailRequested,
  updateShipmentStatusRequest,
  watchUpdateShipmentDetailRequested,
  watchBuyShippingLabelRequest,
  watchSendShipmentToSellerRequest,
  watchSendConfirmAddressSellerRequest,
  updateFormManualShipment,
  ShipmentDetailUpdateOrderProductRequested,
  watchReGenerateShippingLabelRequest,
  watchGenerateShippingInvoiceRequest
];
