import * as _ from 'lodash';
import { FETCH_PROFILE_DETAIL_SUCCESSED, UPDATE_PROFILE_SUCCESSED, GET_USER_WALLET_SUCCEEDED } from './detail.action';
import { InputBase, TextBox, DateTimePicker, PhoneCode } from '@reflaunt/reactive-form';

const getLabel = key => {
  let label = key.replace('_', ' ');
  label = label.charAt(0).toUpperCase() + label.slice(1);
  return label;
};

export const Detail = (state = { updated: false, inputs: [] }, action) => {
  switch (action.type) {
    case FETCH_PROFILE_DETAIL_SUCCESSED:
      let retailerFieldable = [
        { key: 'first_name', type: 'TextBox' },
        { key: 'last_name', type: 'TextBox' },
        { key: 'company', type: 'TextBox' },
        { key: 'address', type: 'TextBox' },
        { key: 'zipcode', type: 'TextBox' },
        { key: 'description', type: 'TextBox' },
        { key: 'note', type: 'TextBox' },
        { key: 'website', type: 'TextBox' }
      ];
      let customerFieldable = [
        { key: 'first_name', type: 'TextBox' },
        { key: 'last_name', type: 'TextBox' },
        { key: 'birth', type: 'DateTimePicker' },
        { key: 'website', type: 'TextBox' },
        { key: 'bottom_size', type: 'TextBox' },
        { key: 'top_size', type: 'TextBox' },
        { key: 'shoe_size', type: 'TextBox' },
        { key: 'tax_number', type: 'TextBox' },
        { key: 'bank_name', type: 'TextBox' },
        { key: 'bank_code', type: 'TextBox' },
        { key: 'bank_benefeciary', type: 'TextBox' },
        { key: 'bank_account', type: 'TextBox' },
        { key: 'bank_branch_code', type: 'TextBox' },
        { key: 'offer_email', type: 'TextBox' }
      ];
      let inputs: InputBase<any>[] = [];
      if (action.data.hasOwnProperty('retailer')) {
        _.forEach(action.data.retailer, function(value, key) {
          _.forEach(retailerFieldable, function(rfa) {
            if (key === rfa.key && rfa.type === 'TextBox') {
              inputs.push(
                new TextBox({
                  key: key,
                  label: getLabel(key),
                  classes: ['col-12'],
                  group_classes: ['col-12'],
                  order: 1,
                  value: value
                })
              );
            }
          });
        });
      }
      if (action.data.hasOwnProperty('customer')) {
        let phoneData: any = {};
        _.forEach(action.data.customer, function(value, key) {
          _.forEach(customerFieldable, function(cfa) {
            if (key === cfa.key && cfa.type === 'TextBox') {
              inputs.push(
                new TextBox({
                  key: key,
                  label: getLabel(key),
                  classes: ['col-12'],
                  group_classes: ['col-12'],
                  order: 1,
                  value: value
                })
              );
            }
            if (key === cfa.key && cfa.type === 'DateTimePicker') {
              inputs.push(
                new DateTimePicker({
                  key: key,
                  label: getLabel(key),
                  classes: ['col-12'],
                  group_classes: ['col-12'],
                  order: 1,
                  value: new Date(value),
                  monthNavigator: true,
                  yearNavigator: true,
                  yearRange: '1990:2030'
                })
              );
            }
          });
          if (key === 'phone_number') {
            phoneData.value = value;
          }
          if (key === 'phone_area_code') {
            phoneData.code = value;
          }
        });
        inputs.push(
          new PhoneCode({
            key: 'phone_data',
            label: 'Phone number',
            classes: ['col-12'],
            group_classes: ['col-12'],
            order: 1,
            value: phoneData
          })
        );
      }
      return Object.assign({}, state, { inputs: inputs });
    case UPDATE_PROFILE_SUCCESSED:
      return Object.assign({}, state, { updated: true });
    case GET_USER_WALLET_SUCCEEDED:
      return Object.assign({}, state, { wallet: action.data });
    default:
      return state;
  }
};
