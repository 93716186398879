import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import Location from '../../../models/Location';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
@Injectable()
export class LocationService extends ServiceProvider {
  public url = '/api/v1/locations';
  public model = Location;
  listAll(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: { ...params, per_page: '10000' } });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  getLogisticPartnerLocation(params = {}): Observable<any> {
    // AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/logistic-partner`), { params: queryParams }).pipe(
      tap((result) => {
        // AppInjector.get(PreloaderService).hide();
      }),
      map((result) => {
        const data = _.map((result as any).data, (item) => new this.model(item));
        return _.orderBy(data, [dt => (dt as any).name.toLowerCase()], ['asc'])
      }),
      catchError((error) => {
        // AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
