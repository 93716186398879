export const DATASET_EDIT_COMPONENT = 'DATASET_EDIT_COMPONENT';
export const GET_DATASET_REQUESTED = 'GET_DATASET_REQUESTED';
export const GET_DATASET_SUCCEEDED = 'GET_DATASET_SUCCEEDED';
export const EDIT_DATASET_REQUESTED = 'EDIT_DATASET_REQUESTED';
export const EDIT_DATASET_SUCCEEDED = 'EDIT_DATASET_SUCCEEDED';
export const DELETE_DATASET_REQUESTED = 'DELETE_DATASET_REQUESTED';
export const DELETE_DATASET_SUCCEEDED = 'DELETE_DATASET_SUCCEEDED';
export const BINDING_DATASET_DATA = 'BINDING_DATASET_DATA';
export const BINDING_DATASET_DATA_SUCCESS = 'BINDING_DATASET_DATA_SUCCESS';

export const EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED = 'EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_REQUESTED';
export const EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED = 'EDIT_FILL_CONTANTS_DROPDOWN_CONDITION_SUCCESSED';

export const EDIT_CONDITION_MODIFIED = 'EDIT_CONDITION_MODIFIED';
export const RESET_STATE_EDIT_DATASET = 'RESET_STATE_EDIT_DATASET';

export const REFRESH_DATASET_REQUEST = 'REFRESH_DATASET_REQUEST';
export const REFRESH_DATASET_SUCCESS = 'REFRESH_DATASET_SUCCESS';
