import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  GET_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED, UPDATE_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED
} from './smart-button.actions';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-smart-button',
  templateUrl: './smart-button.component.html',
  styleUrls: ['./smart-button.component.scss']
})
export class SmartButtonComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.SmartButton';
  public render_config: object = {};

  constructor(private router: Router, private route: ActivatedRoute) {
    super();
    this.route = route;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: GET_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED
        });
      }
    });
   }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return {
      render_config: state.Retailer.SmartButton.render.config
    };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }

  onRenderConfigSaved(config) {
    this.render_config = config;
    this.store.dispatch({
      type: UPDATE_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
      data: config
    });
  }
}
