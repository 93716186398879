import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import {
  RETAILER_CREATE_PRODUCT_REQUESTED,
  RETAILER_CREATE_PRODUCT_SUCCEEDED,
  RETAILER_RENDER_CREATE_PRODUCT_FORM_REQUESTED,
  RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS
} from './create.actions';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { Router } from '@angular/router';
import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { fetchAllcurrency } from '../../../admin/currency/currency.saga';
import { fetchAllsize } from '../../../admin/attribute/size/size.saga';
import { fetchAlldesigner } from '../../../admin/attribute/designer/designer.saga';
import { fetchAllcolor } from '../../../admin/attribute/color/color.saga';
import { fetchAllcategory } from '../../../admin/category/category.saga';
import { listRouter } from '../product.const';

function* fetchAllDataProduct(action) {
  const [currencies, sizes, designers, colors, categories] = yield all([
    call(fetchAllcurrency),
    call(fetchAllsize),
    call(fetchAlldesigner),
    call(fetchAllcolor),
    call(fetchAllcategory)
  ]);
  yield put({
    type: RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS,
    input: 'currency_id',
    data: _.map(currencies, (item, key) => _.assign(item, { value: item.id, label: item.code, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS,
    input: 'size_id',
    data: _.map(sizes, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS,
    input: 'designer_id',
    data: _.map(designers, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS,
    input: 'color_id',
    data: _.map(colors, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_CREATE_PRODUCT_INPUT_OPTIONS,
    input: 'category_id',
    data: _.map(categories, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
}

function* watchCreateProductFormRequested() {
  yield takeEvery(RETAILER_RENDER_CREATE_PRODUCT_FORM_REQUESTED, fetchAllDataProduct);
}

function* createProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.product.create(action.data).toPromise();
    yield put({ type: RETAILER_CREATE_PRODUCT_SUCCEEDED, data: result });
    let redirect = listRouter(yield select(state => (state as any).Auth.login.profile.id));
    if (action.hasOwnProperty('redirect')) {
      redirect = action.redirect;
    }
    router.navigate(redirect);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateProductRequested() {
  yield takeEvery(RETAILER_CREATE_PRODUCT_REQUESTED, createProduct);
}

export default [watchCreateProductRequested, watchCreateProductFormRequested];
