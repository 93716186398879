import { EDIT_STATUS_SUCCEEDED, GET_STATUS_SUCCEEDED } from './edit.actions';
import * as _ from 'lodash';

export const edit = (state = { updated: false, fetched: false, inputs: [] }, action) => {
  switch (action.type) {
    case GET_STATUS_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });

    case EDIT_STATUS_SUCCEEDED:
      return _.assign({}, state, { updated: true });

    default:
      return state;
  }
};
