import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class IntegrationNotification extends ServiceProvider {
  public url = '/api/v1/admin/marketplace-management';

  createIntegrationNotification(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url), data).pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }
  deleteIntegrationNotification(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/delete', data).pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }
  updateIntegrationNotification(data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/update', data).pipe(
      tap(result => {}),
      catchError(error => {
        throw error;
      })
    );
  }
}
