import Model from './Model';

export enum DataOperators {
  is_equal_to = 'is equal to',
  is_not_equal_to = 'is not equal to',
  is_greater_than = 'is greater than',
  is_less_than = 'is less than',
  start_with = 'starts with',
  ends_with = 'ends with',
  contains = 'contains',
  does_not_contain = 'does not contain',
  in_list = 'in list',
  not_in_list = 'not in list'
}
class DatasetOperator extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default DatasetOperator;
