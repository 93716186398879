import { put, takeEvery, takeLatest, all, call, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../../app-injector';
import { ApiService } from '../../../../../api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { API_CALL_ERROR } from '../../../../../store/action';
import {
  RENDER_EDIT_USER_ADDRESS_FORM_REQUESTED,
  ADMIN_GET_USER_ADDRESS_REQUESTED,
  ADMIN_GET_USER_ADDRESS_SUCCEEDED,
  FILL_USER_ADDRESS_DETAIL_FORM,
  UPDATE_USER_ADDRESS_REQUESTED,
  UPDATE_USER_ADDRESS_SUCCEEDED,
  FILL_DATA_INPUTS_OPTIONS_EDIT_ADDRESS_FORM_REQUESTED
} from './edit.actions';
import { NotificationService } from '../../../../../common/services/notification/notification.service';
import { listUserAddressRoute } from '../address.consts';
import { Countries } from '../countries';
import * as _ from 'lodash';
import { GET_SHIPMENT_DETAIL_ADMIN_REQUESTED } from '../../../shipment/detail/detail.actions';

function* updateUserAddress(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    if (action.data.shipment_id) {
      let result = yield api.admin.address.updateShipmentAddress(action.data.shipment_id, _.assign(action.data, { type_address: action.data.type_address })).toPromise();
      yield put({ type: UPDATE_USER_ADDRESS_SUCCEEDED, data: result });
      setTimeout(() => {
        window.location.href = `/admin/shipment/${Number(action.data.shipment_id)}/detail`;
      }, 1000);
    } else {
      let result = yield api.admin.address.update(action.data.id, action.data).toPromise();
      yield put({ type: UPDATE_USER_ADDRESS_SUCCEEDED, data: result });
      AppInjector.get(NotificationService).show('success', 'Address updated', 3000);
      const userId = yield select((state) => (state as any).Admin.Users.editUser.item.id);
      router.navigate(listUserAddressRoute(userId));
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateUserAddressRequest() {
  yield takeEvery(UPDATE_USER_ADDRESS_REQUESTED, updateUserAddress);
}

export function* fetchAddressDetail(id) {
  return yield AppInjector.get(ApiService).admin.address.getItemById(id).toPromise();
}

export function* fetchShipmentAddress(shipmentId, type) {
  const shipment = yield AppInjector.get(ApiService).admin.shipment.getItemById(shipmentId).toPromise();
  if (type === 'destination_address') {
    return shipment.destination_address;
  } else if (type === 'source_address') {
    return shipment.source_address;
  }
}

function* getUserAddress(action) {
  const COUNTRIES = Countries;
  let countries = yield AppInjector.get(ApiService).country.list().toPromise();

  const optionsData = {
    country: _.map(COUNTRIES, (item, key) => _.assign(item, { value: item.name, label: item.name }))
  };
  yield put({
    type: FILL_DATA_INPUTS_OPTIONS_EDIT_ADDRESS_FORM_REQUESTED,
    data: optionsData
  });
  let address;
  if (action.shipment_id && action.type_address) {
    address = yield call(fetchShipmentAddress, action.shipment_id, action.type_address);
  } else {
    address = yield call(fetchAddressDetail, action.data);
  }
  if (address) {
    yield put({ type: ADMIN_GET_USER_ADDRESS_SUCCEEDED, data: address });
    let phone;
    let code;
    let value;
    let country_data;
    if (address.country_id) {
      country_data = _.find(countries, (e) => e.id === address.country_id);
    }
    if (address.phone) {
      phone = address.phone ? address.phone.split('-') : '';
      code = phone[0].replace('+', '');
      value = phone[1];
    }
    const data = {
      id: address.id,
      company: address.company ? address.company : null,
      first_name: address.first_name ? address.first_name : null,
      last_name: address.last_name ? address.last_name : null,
      address_1: address.address_1 ? address.address_1 : null,
      address_2: address.address_2 ? address.address_2 : null,
      city: address.city ? address.city : null,
      state: address.state ? address.state : null,
      country: country_data ? _.find(optionsData.country, (i) => i.alpha2Code === country_data.iso_2_code) : null,
      zipcode: address.zipcode ? address.zipcode : null,
      phonecode: {
        code: code,
        value: value,
        alpha2Code: country_data ? country_data.iso_2_code : null
      },
      postal_code: address.postal_code ? address.postal_code : null,
      country_alpha2_code: country_data ? country_data.iso_2_code : null,
      street: address.street ? address.street : null,
      locality: address.locality ? address.locality : null,
      town: address.town ? address.town : null
    };
    yield put({ type: FILL_USER_ADDRESS_DETAIL_FORM, data: data });
  }
}

function* watchGetUserAddressRequest() {
  yield takeEvery(ADMIN_GET_USER_ADDRESS_REQUESTED, getUserAddress);
}

function* watchRenderEditUserAddressFormRequested() {
  yield takeLatest(RENDER_EDIT_USER_ADDRESS_FORM_REQUESTED, function* (action: any) {
    yield put({ type: ADMIN_GET_USER_ADDRESS_REQUESTED, data: action.data.id, shipment_id: action.data.shipment_id, type_address: action.data.type_address });
  });
}

export default [watchRenderEditUserAddressFormRequested, watchGetUserAddressRequest, watchUpdateUserAddressRequest];
