import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { create } from './create/create.reducer';
import { edit } from './edit/edit.reducer';

export const Address = combineReducers({
  list,
  create,
  edit
});
