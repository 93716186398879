import * as _ from 'lodash';
import { GET_PRODUCT_CLAIM_CERTIFICATE_SUCCEEDED, CLAIM_CERTIFICATE_SUCCEEDED } from './certificate.actions';

export const Certificate = (state = { claim: false, product: {} }, action) => {
  switch (action.type) {
    case GET_PRODUCT_CLAIM_CERTIFICATE_SUCCEEDED:
      return _.assign({}, state, { product: action.data });
    case CLAIM_CERTIFICATE_SUCCEEDED:
      return _.assign({}, state, { claim: true });

    default:
      return state;
  }
};
