export const RENDER_BULK_DISCOUNT_FORM_REQUESTED = 'RENDER_BULK_DISCOUNT_FORM_REQUESTED';
export const UPDATE_BULK_DISCOUNT_INPUT_OPTIONS = 'UPDATE_BULK_DISCOUNT_INPUT_OPTIONS';

export const BULK_DISCOUNT_PRODUCT_REQUESTED = 'BULK_DISCOUNT_PRODUCT_REQUESTED';
export const BULK_DISCOUNT_PRODUCT_SUCCEEDED = 'BULK_DISCOUNT_PRODUCT_SUCCEEDED';

export const DELIST_PRODUCT_DISCCOUNT_COM_REQUESTED = 'DELIST_PRODUCT_DISCCOUNT_COM_REQUESTED';
export const DELIST_PRODUCT_DISCCOUNT_COM_SUCCEEDED = 'DELIST_PRODUCT_DISCCOUNT_COM_SUCCEEDED';

export const FETCH_BULK_OPERATION_DATA_REQUESTED = 'FETCH_BULK_OPERATION_DATA_REQUESTED';
export const FETCH_BULK_OPERATION_DATA_SUCCEEDED = 'FETCH_BULK_OPERATION_DATA_SUCCEEDED';
export const UPDATE_CURRENCY_LABEL = 'UPDATE_CURRENCY_LABEL';
