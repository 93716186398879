import * as _ from 'lodash';
import { EDIT_RETAILER_REQUESTED, RENDER_EDIT_RETAILER_FORM_REQUESTED, GET_COURIER_RATES_REQUESTED, RETAILER_UPDATE_INPUTS, RETAILER_REMOVE_INPUTS } from './edit.actions';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '../../../../store/store.module';
import { AppInjector } from '../../../../app-injector';
import { UPDATE_OR_CREATE_SMART_OBJECT_REQUESTED } from '../../user/user.const';
import { Validators } from '@angular/forms';
import { InputBase } from '@reflaunt/reactive-form/lib/models/InputBase';
import { TextBox, Dropdown, TextArea, TypeAhead } from '@reflaunt/reactive-form';
import { BaseComponent } from '../../../base.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseComponent implements OnInit {
  public store;
  public submitted = false;
  public inputs: InputBase<any>[];
  public keysChange = ['is_require_retouch_image'];
  public reducer = 'Admin.AdminRetailer.edit';
  public retailerForm;
  @ViewChild('retailer-commission', { static: false }) public rtCommission;
  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    super();
    this.activatedRoute = activatedRoute;
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.init();
    this.inputs = [
      new TextBox({
        key: 'first_name',
        label: 'First name *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'last_name',
        label: 'Last name *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'company',
        label: 'Company *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'shipping_company',
        label: 'Shipping Company *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'address_1',
        label: 'Address 1 *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'address_2',
        label: 'Address 2 *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'city',
        label: 'City',
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'state',
        label: 'State',
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'zipcode',
        label: 'Zipcode *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'website',
        label: 'Website *',
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
          // {
          //   validator: Validators.pattern('^(https?://)?(www.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*.)+[w]{2,}(/S*)?$'),
          //   message: 'This field must be a website address'
          // }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1,
        placeholder: 'https://www.example.com'
      }),
      new TypeAhead({
        key: 'country_id',
        label: 'Country *',
        classes: ['col-12 col-md-6'],
        group_classes: ['col-12'],
        group: 1,
        apiUpload: '/api/v1/countries?page=1&search=',
        resultTransformer: (result) => result.data,
        fieldName: (result) => result.name,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        multiple: false,
        keyName: ['name'],
        searchBy: 'name'
      }),
      new Dropdown({
        key: 'shipping_method_id',
        label: 'Default shipping method *',
        classes: ['col-6'],
        group_classes: ['col-12'],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new Dropdown({
        key: 'voucher_type',
        label: 'Voucher Type *',
        classes: ['col-6'],
        group_classes: ['col-12'],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TextBox({
        key: 'voucher_integration_type',
        label: 'Voucher Integration Type',
        validators: [
          {
            validator: Validators.maxLength(10),
            message: 'The maximum allowed characters are 10'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextArea({
        key: 'note',
        label: 'Note',
        classes: ['col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextArea({
        key: 'introduction_description',
        label: 'Introduction Description',
        classes: ['col-12'],
        group_classes: ['col-12'],
        order: 1
      }),
      new Dropdown({
        key: 'blockchain_provider_id',
        label: 'Blockchain Provider',
        classes: ['col-6'],
        group_classes: ['col-12']
      }),
      new TextBox({
        key: 'service_slug',
        label: 'Service slug',
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new TextBox({
        key: 'short_code',
        label: 'Short code',
        classes: ['col-6'],
        group_classes: ['col-12'],
        order: 1
      }),
      new Dropdown({
        key: 'dropoff_status',
        label: 'Dropoff status',
        classes: ['col-6'],
        group_classes: ['col-12']
      }),
      new Dropdown({
        key: 'quicksight_dashboard_id',
        label: 'Quicksight Dashboard',
        classes: ['col-3'],
        group_classes: ['col-12']
      }),
      new Dropdown({
        key: 'quicksight_resale_activity_id',
        label: 'Quicksight Resale Activity',
        classes: ['col-3'],
        group_classes: ['col-12']
      }),
      new Dropdown({
        key: 'quicksight_2nd_hand_sales_id',
        label: 'Quicksight 2nd Hand Sales',
        classes: ['col-3'],
        group_classes: ['col-12']
      }),
      new Dropdown({
        key: 'quicksight_repurchase_id',
        label: 'Quicksight Repurchase',
        classes: ['col-3'],
        group_classes: ['col-12']
      }),
      new Dropdown({
        key: 'default_is_stock_image',
        label: 'Default Is Stock Image',
        classes: ['col-3'],
        group_classes: ['col-12']
      }),
      new Dropdown({
        key: 'is_require_retouch_image',
        label: 'Is retouch image required',
        classes: ['col-3'],
        group_classes: ['col-12'],
        onChange: this.isRetouchImageChange
      }),
      new Dropdown({
        key: 'number_of_retouch_image_required',
        label: 'Number of retouch image required',
        classes: ['col-3'],
        group_classes: ['col-12'],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new Dropdown({
        key: 'are_images_aldready_retouched',
        label: 'Are images aldready retouched?',
        classes: ['col-3'],
        group_classes: ['col-12'],
        options: [
          { value: false, label: 'No' },
          { value: true, label: 'Yes' }
        ]
      }),
      new Dropdown({
        key: 'is_notify_when_order_placed',
        label: 'Notify when order placed?',
        classes: ['col-3'],
        group_classes: ['col-12'],
        options: [
          { value: false, label: 'No' },
          { value: true, label: 'Yes' }
        ]
      })
    ];
    this.store.dispatch({ type: RENDER_EDIT_RETAILER_FORM_REQUESTED, data: { id: this.activatedRoute.snapshot.params.id, inputs: this.inputs } });
  }

  onChange = (form) => {
    this.retailerForm = form;
  };

  isRetouchImageChange = (value) => {
    if (value) {
      if (value.value) {
        return this.store.dispatch({ type: RETAILER_REMOVE_INPUTS, data: value.value });
      }
      return this.store.dispatch({
        type: RETAILER_UPDATE_INPUTS,
        data: [
          new Dropdown({
            key: 'are_images_aldready_retouched',
            label: 'Are images aldready retouched?',
            classes: ['col-3'],
            group_classes: ['col-12'],
            options: [
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]
          })
        ],
        value: value.value,
        input_key: 'are_images_aldready_retouched'
      });
    }
  };

  onBeforeSubmit = (form) => {
    const is_require_retouch_image = form.get('is_require_retouch_image').value.value;
    if (!is_require_retouch_image) {
      form.get('number_of_retouch_image_required').clearValidators();
      form.controls['number_of_retouch_image_required'].setErrors(null);
      form.get('number_of_retouch_image_required').updateValueAndValidity();
      form.get('number_of_retouch_image_required').markAsTouched();
    }
  };

  mapStateToProps(state) {
    return {
      payload: state.Admin.AdminRetailer.edit
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }

  onSubmit = (form) => {
    const store = AppInjector.get(Store).getInstance();
    if (form.valid) {
      const address_value = _.pick(form.value, ['address_1', 'address_2', 'city', 'company', 'zipcode', 'state', 'shipping_company']);
      const country_id = form.value.country_id ? form.value.country_id.id : null;
      store.dispatch({
        type: EDIT_RETAILER_REQUESTED,
        data: _.assign(form.value, {
          id: store.getState().Admin.AdminRetailer.edit.item.id,
          shipping_method_id: form.value.shipping_method_id.value,
          blockchain_provider_id: form.value.blockchain_provider_id.value,
          quicksight_dashboard_id: form.value.quicksight_dashboard_id.value,
          quicksight_resale_activity_id: form.value.quicksight_resale_activity_id.value,
          quicksight_2nd_hand_sales_id: form.value.quicksight_2nd_hand_sales_id.value,
          quicksight_repurchase_id: form.value.quicksight_repurchase_id.value,
          voucher_type: form.value.voucher_type.value,
          country_id: country_id,
          dropoff_status: form.value.dropoff_status.value,
          default_is_stock_image: form.value.default_is_stock_image.value,
          is_require_retouch_image: form.value.is_require_retouch_image.value,
          are_images_aldready_retouched: form.value.are_images_aldready_retouched.value,
          is_notify_when_order_placed: form.value.is_notify_when_order_placed.value,
          number_of_retouch_image_required: form.value.is_require_retouch_image.value ? form.value.number_of_retouch_image_required.value : null,
          default_address: !_.isNil(this.payload.default_address)
            ? _.assign({ ...this.payload.default_address, ...address_value, country_id: country_id })
            : { ...address_value, country_id: country_id }
        }),
        completed: { id: this.activatedRoute.snapshot.params.id, inputs: this.inputs }
      });
    }
  };

  onSaveRetailerStoreConfigs = (form) => {
    this.submitted = true;
    if (form.valid) {
      const data = form.value;
      this.store.dispatch({
        type: EDIT_RETAILER_REQUESTED,
        data: {
          id: this.payload.item.id,
          store_config: {
            retailer_parent_ids: data.retailer_parent.map((e) => e.id),
            currency_id: data.retailer_currency,
            store_app_config: JSON.parse(data.store_app_config)
          }
        }
      });
      this.submitted = !this.submitted;
    }
  };

  onSaveShippingMethodRules(configs) {
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({
      type: EDIT_RETAILER_REQUESTED,
      data: {
        id: store.getState().Admin.AdminRetailer.edit.item.id,
        shipping_method_rules: configs
      }
    });
  }
  smartOjectSave(val) {
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({
      type: UPDATE_OR_CREATE_SMART_OBJECT_REQUESTED,
      data: _.assign(val, { user_id: Number(this.getItemId()) })
    });
  }

  getItemId() {
    return this.activatedRoute.snapshot.params.id;
  }

  onChangeShippingMethodRule(config) {
    const store = AppInjector.get(Store).getInstance();
    store.getState().Admin.AdminRetailer.edit.show_shipping_mask = true;
    store.dispatch({
      type: GET_COURIER_RATES_REQUESTED,
      data: config
    });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onClickToEditRate(config) {
    const store = AppInjector.get(Store).getInstance();
    store.getState().Admin.AdminRetailer.edit.show_shipping_mask = true;
    store.dispatch({
      type: GET_COURIER_RATES_REQUESTED,
      data: config
    });
  }
}
