export const DEFAULT_CONFIG_GET_RETAILER_INFO_REQUEST = 'DEFAULT_CONFIG_GET_RETAILER_INFO_REQUEST';
export const DEFAULT_CONFIG_GET_RETAILER_INFO_SUCCESS = 'DEFAULT_CONFIG_GET_RETAILER_INFO_SUCCESS';

export const DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_REQUEST = 'DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_REQUEST';
export const DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_SUCCESS = 'DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_SUCCESS';

export const DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST = 'DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST';
export const DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS = 'DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS';

export const DEFAULT_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION = 'DEFAULT_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION';

export const DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_REQUEST = 'DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_REQUEST';
export const DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS = 'DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS';

export const DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_REQUEST = 'DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_REQUEST';
export const DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_SUCCESS = 'DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_SUCCESS';

export const DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_REQUEST = 'DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_REQUEST';
export const DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS = 'DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_SUCCESS';

export const DEFAULT_CONFIG_EDIT_NOTIFICATION_ACTION_ITEM = 'DEFAULT_CONFIG_EDIT_NOTIFICATION_ACTION_ITEM';

export const DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE = 'DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE';
