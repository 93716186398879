import * as _ from 'lodash';
import { FETCH_ALL_USER_ADDRESS_SUCCESSED } from './list.actions';

export const list = (state = { fetched: false }, action) => {
  switch (action.type) {
    case FETCH_ALL_USER_ADDRESS_SUCCESSED:
      return _.assign({}, state, {
        items: action.data,
        fetched: true
      });
    default:
      return state;
  }
};
