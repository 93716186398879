import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { takeEvery, put, select } from 'redux-saga/effects';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import Swal from 'sweetalert2';
import {
  BULK_UPDATE_ISSUE_REQUESTED,
  EXPORT_PRODUCT_ACTIVITY_LOG_REQUESTED,
  FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED,
  FETCH_PRODUCT_ACTIVITY_LOG_SUCCESSED, MARK_ISSUE_AT_IT_REQUESTED, MARK_ISSUE_AT_IT_SUCCEEDED,
  REPROCESS_ISSUE_REQUESTED,
  REPROCESS_ISSUE_SUCCEEDED, SKIP_ISSUE_REQUESTED, SKIP_ISSUE_SUCCEEDED
} from './event.actions';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchFetchProductActivityLogRequested() {
  yield takeEvery(FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.activityLog.get(action.data)
        .toPromise();
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_SUCCESSED, data: result });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchExportProductActivityLogRequested() {
  yield takeEvery(EXPORT_PRODUCT_ACTIVITY_LOG_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const swal = Swal as any;
    try {
      swal
        .fire({
          icon: 'info',
          title: 'Processing...',
          showConfirmButton: false,
          onBeforeOpen: () => {
            swal.showLoading();
          }
        })
        .then((result) => { });
      const result = yield api.admin.activityLog.exportCsv(action.filters).toPromise();
      const async = yield api.asyncProcess.checkAsyncStatusByCode(result.async_process_code, 3000).toPromise();
      if (async === 'Time out' || async.status === 'CANCELLED') {
        AppInjector.get(PreloaderService).hide();
        swal
          .fire({
            icon: 'error',
            title: 'Error',
            text: async.message
          })
          .then((result) => { });

        return false;
      }
      if (async.status === 'COMPLETED') {
        AppInjector.get(PreloaderService).hide();
        const { output } = async;
        swal
          .fire({
            icon: 'success',
            html: ` <h4>Activity Log</h4>
            <p style="font-size: 16px; margin-top: 30px; font-weight: bold; text-align: left">Link: ${output}</p>`,
            confirmButtonText: `Download`
          })
          .then((data) => {
            if (data.value) {
              const a = document.createElement('a');
              a.href = output;
              a.click();
            }
          });
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchReprocessIssueRequested() {
  yield takeEvery(REPROCESS_ISSUE_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.issueTracking.reprocess(action.data)
        .toPromise();
      yield put({ type: REPROCESS_ISSUE_SUCCEEDED, data: result });
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: action.params });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: action.params });
    }
  });
}

function* watchSkipIssueRequested() {
  yield takeEvery(SKIP_ISSUE_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.issueTracking.skip(action.data)
        .toPromise();
      yield put({ type: SKIP_ISSUE_SUCCEEDED, data: result });
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: action.params });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: action.params });
    }
  });
}

function* markIssueAsItRequested() {
  yield takeEvery(MARK_ISSUE_AT_IT_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.issueTracking.markAsIt(action.data)
        .toPromise();
      yield put({ type: MARK_ISSUE_AT_IT_SUCCEEDED, data: result });
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: action.params });
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: action.params });
    }
  });
}

function* bulkUpdateIssueRequested() {
  yield takeEvery(BULK_UPDATE_ISSUE_REQUESTED, function* (action: any) {
    AppInjector.get(PreloaderService).show();
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.issueTracking.bulkUpdate(action.data)
        .toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatusByCode(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          AppInjector.get(NotificationService).show('success', 'Bulk Push successfully', 3000);
          AppInjector.get(PreloaderService).hide();
          const query = yield select(state => (state as any).Admin.Product.Event.query);
          yield put({ type: FETCH_PRODUCT_ACTIVITY_LOG_REQUESTED, data: query, reset_selection: true });
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
      AppInjector.get(NotificationService).show('warning', `Error when doing bulk update`, 3000);
    }
  });
}


export default [watchFetchProductActivityLogRequested, watchExportProductActivityLogRequested, watchReprocessIssueRequested, watchSkipIssueRequested, markIssueAsItRequested, bulkUpdateIssueRequested];
