import Model from '../Model';
import Transfer from './EventData/Transfer';
import * as moment from 'moment';

export class BlockchainEvent extends Model {
  constructor(options) {
    super();
    if (options.event_name === 'Transfer') {
      options.data = options.transfer_data;
    } else {
      options.data = null;
    }
    (this as any).data = d => {
      if (options.event_name === 'Transfer') {
        return new Transfer(d.data);
      } else {
        return null;
      }
    };
    this.bind(options);
  }
  getBlockDateAsMoment(): moment.Moment {
    let m = moment();
    if (this['block_date']) {
      m = moment(this['block_date']);
    }
    return m;
  }
}
