import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { detail } from './detail/detail.reducer';
import { create } from './create/create.reducer';
import { edit } from './edit/edit.reducer';
import { Import } from './import/import.reducer';

export const Product = combineReducers({
  list,
  detail,
  create,
  edit,
  Import
});
