import {
  CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED,
  CONFIGURATION_FETCH_MARKETPLACE_REQUESTED,
  SAVE_INTEGRATION_CONFIGURATION_REQUESTED,
  SAVE_INTEGRATION_CONFIGURATION_SUCCESSED,
  CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED,
  INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE
} from './edit.actions';
import { API_CALL_ERROR } from './../../../store/action';
import { ApiService } from './../../../api/api.service';
import { AppInjector } from './../../../app-injector';
import * as _ from 'lodash';
import { NotificationService } from '../../../common/services/notification/notification.service';
import { fetchAlldataset } from '../../retailer/dataset/dataset.saga';
import { takeLatest, call, put, select } from 'redux-saga/effects';

function* watchFetchDatasetRequested() {
  yield takeLatest(CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED, function*(action) {
    try {
      const result = yield call(fetchAlldataset);
      yield put({ type: CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchFetchIntegrationRequested() {
  yield takeLatest(CONFIGURATION_FETCH_MARKETPLACE_REQUESTED, function*(action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .integration.list({ constraints: action.data })
        .toPromise();
      yield put({ type: CONFIGURATION_FETCH_MARKETPLACE_SUCCESSED, data: _.head(result) });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSaveIntegrationConfigurationRequested() {
  yield takeLatest(SAVE_INTEGRATION_CONFIGURATION_REQUESTED, function*(action: any) {
    try {
      const data = _.assign({}, action.data, {
        marketplace_id: action.marketplace_id,
        markup: action.markup,
        dataset_markups: action.dataset_markups
      });
      const result = yield AppInjector.get(ApiService)
        .integration.configuration(data)
        .toPromise();
      yield put({ type: SAVE_INTEGRATION_CONFIGURATION_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSaveIntegrationConfigurationSuccessed() {
  yield takeLatest(SAVE_INTEGRATION_CONFIGURATION_SUCCESSED, function*() {
    AppInjector.get(NotificationService).show('success', 'Setting Saved', 3000);
  });
}

function* watchFetchDatasetSuccess() {
  yield takeLatest(CONFIGURATION_FETCH_ALL_DATASETS_SUCCESSED, function*(action: any) {
    const integration_meta = yield select((state: any) => state.Integration.edit.item.meta);
    yield put({ type: INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE, data: integration_meta });
  });
}

export default [
  watchFetchDatasetRequested,
  watchFetchIntegrationRequested,
  watchSaveIntegrationConfigurationRequested,
  watchSaveIntegrationConfigurationSuccessed,
  watchFetchDatasetSuccess
];
