import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Store } from '../../../../store/store.module';
import {
  ADMIN_INTEGRATION_GET_RETAILER_REQUEST,
  DELETE_NOTIFICATION_INTEGRATION_REQUEST,
  CREATE_NOTIFICATION_INTEGRATION_REQUEST,
  UPDATE_NOTIFICATION_INTEGRATION_REQUEST,
  INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE,
  INDIVIDUAL_CONFIG_SAVE_RETAILER_AND_MARKETPLACE_IDS
} from '../marketplace-management/marketplace-management.actions';
import { BaseComponent } from '../../../base.component';
import {
  adminFetchMarketplaceRequested,
  ADMIN_INTEGRATION_EDIT_CHANGE_SETTING,
  ADMIN_INTEGRATION_EDIT_UPDATE_ALL_SETTING,
  ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED,
  ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE,
  ADMIN_SAVE_INTEGRATION_CONFIGURATION_REQUESTED
} from './edit-action.actions';
import { ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_REQUEST, ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST } from '../integration.actions';

@Component({
  selector: 'app-edit-action',
  templateUrl: './edit-action.component.html',
  styleUrls: ['./edit-action.component.scss']
})
export class EditActionComponent extends BaseComponent implements OnInit, AfterViewChecked {
  public options;
  public store;
  public retailerId;
  public marketplaceId;
  public ngSelectOnCompare;

  public datasetIntegrationMarkups;
  public reducer = 'Admin.Integration.editAction';
  public setting = 'all';
  public allConfiguration = 'is_automatically';

  constructor(private activatedRoute: ActivatedRoute, store: Store) {
    super();
    this.retailerId = this.activatedRoute.snapshot.params.retailerId;
    this.marketplaceId = this.activatedRoute.snapshot.params.marketplaceId;
    this.store = store.getInstance();
    const { dispatchGetAllAvailableTemplate, dispatchSaveRetailerAndMarketplaceIds } = this as any;

    dispatchSaveRetailerAndMarketplaceIds({ retailerId: this.retailerId, marketplaceId: this.marketplaceId });

    this.store.dispatch({
      type: ADMIN_INTEGRATION_GET_RETAILER_REQUEST,
      data: this.retailerId,
      com: 'EDIT_ACTION_COM',
      marketplaceId: this.activatedRoute.snapshot.params.marketplaceId
    });

    this.dispatch(
      adminFetchMarketplaceRequested({ retailer_id: this.activatedRoute.snapshot.params.retailerId, marketplace_id: this.activatedRoute.snapshot.params.marketplaceId })
    );

    dispatchGetAllAvailableTemplate();
  }

  get availableTemplate() {
    return this.store.getState().Admin.Integration.All.availableTemplate;
  }

  get availableLanguage() {
    return this.store.getState().Admin.Integration.All.language;
  }

  get activeLanguage() {
    return this.store.getState().Admin.Integration.MarketplaceManagement.activeLanguage;
  }

  ngOnInit() {
    this.init();
  }

  isCheckedAction(action) {
    return action.checked;
  }

  isDisplayActionList() {
    return this.availableTemplate.fetched;
  }

  ngAfterViewChecked() {}

  onChange(event, val) {
    event.preventDefault();
    const { retailerId, marketplaceId } = this.activatedRoute.snapshot.params;
    if (!val.checked) {
      this.store.dispatch({
        type: CREATE_NOTIFICATION_INTEGRATION_REQUEST,
        data: {
          language_id: this.activeLanguage.id,
          notification_action_id: val.id,
          marketplace_management_id: this.store.getState().Admin.Integration.MarketplaceManagement.marketplaceSelected.marketplace_management.id,
          template_name: val.template_name ? val.template_name : val.default_template
        },
        retailerId,
        marketplaceId
      });
    } else {
      this.store.dispatch({
        type: DELETE_NOTIFICATION_INTEGRATION_REQUEST,
        data: {
          language_id: this.activeLanguage.id,
          notification_action_id: val.id,
          marketplace_management_id: this.store.getState().Admin.Integration.MarketplaceManagement.marketplaceSelected.marketplace_management.id
        },
        retailerId,
        marketplaceId
      });
    }
  }

  eidt(val) {
    _.map(this.store.getState().Admin.Integration.MarketplaceManagement.options, (opt) => {
      if (opt.id === val.id) {
        opt.isEdit = !opt.isEdit;
      }
      return opt;
    });
  }

  Update(val) {
    this.store.dispatch({
      type: UPDATE_NOTIFICATION_INTEGRATION_REQUEST,
      data: {
        language_id: this.activeLanguage.id,
        notification_action_id: val.id,
        marketplace_management_id: this.store.getState().Admin.Integration.MarketplaceManagement.marketplaceSelected.marketplace_management.id,
        template_name: val.template_name ? val.template_name : val.default_template,
        subject: val.config.subject
      }
    });
  }

  changeSetting(setting) {
    this.dispatch({ type: ADMIN_INTEGRATION_EDIT_CHANGE_SETTING, data: setting });
    this.setting = setting;
  }

  updateAllIntegration(setting) {
    this.dispatch({ type: ADMIN_INTEGRATION_EDIT_UPDATE_ALL_SETTING, data: setting });
    this.allConfiguration = setting;
  }

  updateIntegrationType(marketplace, field) {
    this.store.dispatch({
      type: ADMIN_UPDATE_INTEGRATION_TYPE_REQUESTED,
      data: marketplace,
      marketplace_id: marketplace.id,
      field: field,
      value: !marketplace[field]
    });
  }

  updateCustomIntegration(dataset, value) {
    const is_automatically = ((data) => {
      switch (data) {
        case 'manually':
          return false;
        case 'automatically':
          return true;
      }
    })(value);

    // _.map(this.store.getState().Admin.Integration.MarketplaceManagement.options, (opt) => {
    //   if (opt.id === val.id) {
    //     opt.checked = !opt.checked;
    //     const dt2 = _.find(
    //       this.store.getState().Admin.Integration.MarketplaceManagement.marketplaceSelected.marketplace_management.notification_action_marketplace_managements.data,
    //       (i) => i.notification_action_id === val.id
    //     );
    //     if (dt2 && opt.checked) {
    //       opt.template_name = dt2.template_name;
    //     } else if (!dt2 && opt.checked) {
    //       opt.template_name = opt.default_template;
    //     } else {
    //       opt.template_name = '';
    //     }
    //     if (opt.isEdit) {
    //       opt.isEdit = !opt.isEdit;
    //     }
    //   }
    //   return opt;
    // });
    const datasets = _.map(this.payload.datasets, (item) => {
      let auto_value = item.is_automatically;
      if (item.id === dataset.id) {
        auto_value = is_automatically;
      }
      return {
        id: item.id,
        is_automatically: auto_value
      };
    });
    this.dispatch({ type: ADMIN_INTEGRATION_EDIT_UPDATE_DATASET_SETTING_STATE, data: datasets });
  }

  save() {
    this.store.dispatch({
      type: ADMIN_SAVE_INTEGRATION_CONFIGURATION_REQUESTED,
      data: this.payload.integration,
      markup: this.payload.allMarkup,
      marketplace_id: this.activatedRoute.snapshot.params.marketplaceId,
      dataset_markups: this.payload.datasetMarkups,
      retailer_id: this.activatedRoute.snapshot.params.retailerId
    });
  }

  isActiveLanguageOption(language) {
    return language.id === this.payload.activeLanguage;
  }

  setActiveLanguage(id) {
    const { dispatchSetActiveLanguage } = this as any;
    const activeLanguage = this.availableLanguage.items.find((item) => item.id === Number(id));
    dispatchSetActiveLanguage(activeLanguage);
  }

  mapStateToProps(state) {
    return { payload: state.Admin.Integration.editAction };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch,
      dispatchGetAllAvailableTemplate: () => {
        dispatch({ type: ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_REQUEST });
      },
      dispatchSetActiveLanguage: (data) => {
        dispatch({ type: INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE, data });
      },
      dispatchSaveRetailerAndMarketplaceIds: (data) => {
        dispatch({ type: INDIVIDUAL_CONFIG_SAVE_RETAILER_AND_MARKETPLACE_IDS, data });
      }
    };
  }
}
