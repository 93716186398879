import * as _ from 'lodash';
import { FETCH_DATA_ADMIN_ORDER_ITEM_SUCCESSED } from './detail.actions';

export const detail = (state = {fetched: false, item: {}}, action) => {
  switch (action.type) {
    case FETCH_DATA_ADMIN_ORDER_ITEM_SUCCESSED:
        return _.assign(state, {fetched: true, item: action.data});
    default:
      return state;
  }
};
