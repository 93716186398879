import * as _ from 'lodash';
import { FETCH_MEASUREMENT_UNIT_SUCCESSED, DELETE_MEASUREMENT_UNIT_SUCCESSED } from './list.action';

export const List = (
  state = {
    items: [],
    fetched: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_MEASUREMENT_UNIT_SUCCESSED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    case DELETE_MEASUREMENT_UNIT_SUCCESSED:
      const items = _.filter(state.items, (item) => item.id !== action.data.id);
      return { ...state, ...{ items } };
    default:
      return state;
  }
};
