import {
  ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_REQUESTED,
  ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_SUCCESSED,
  DELETE_COLOR_ATTRIBUTE_REQUESTED,
  DELETE_COLOR_ATTRIBUTE_SUCCESSED,
  FETCH_COLOR_DICTIONARY_SUCCESSED,
  FETCH_COLOR_DICTIONARY_REQUESTED,
  ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_REQUESTED,
  ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_REQUESTED,
  ADMIN_ATTRIBUTES_COLOR_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_COLOR_FILTER_SELECTED,
  TOGGLE_COLOR_DICTIONARY_VALIDATION_REQUESTED,
  TOGGLE_COLOR_DICTIONARY_VALIDATION_SUCCEEDED
} from './color.action';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_ATTRIBUTES_SELECT_ANY, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { Router } from '@angular/router';
import { colorAttributes } from './color.const';

function* fetchColors(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.color.get(action.data).toPromise();
    let selected_marketplace = yield select(state => state.Admin.Attributes.all.marketplace.selected);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_SUCCESSED, data: results.items, pagination: results.pagination });
    if (selected_marketplace) {
      yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selected_marketplace, direct: false, component: 'color' });
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchColorAttributeRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_REQUESTED, fetchColors);
}

function* deleteColor(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.admin.color.delete(action.data.id).toPromise();
    yield put({ type: DELETE_COLOR_ATTRIBUTE_SUCCESSED, data: action.data.id });
    notification.show('success', 'Delete color success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* fetchColorDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_COLOR_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteColorRequest() {
  yield takeEvery(DELETE_COLOR_ATTRIBUTE_REQUESTED, deleteColor);
}

function* watchFetchColorDictionaryRequested() {
  yield takeEvery(FETCH_COLOR_DICTIONARY_REQUESTED, fetchColorDictionary);
}

function* watchUpdateColorDictionaryRequested() {
  yield takeEvery(ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_REQUEST, updateColorDictionary);
}

function* updateColorDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.updateOrCreate(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_SUCCESS, data: result });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* toggleColorDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_COLOR_DICTIONARY_VALIDATION_REQUESTED, toggleColorDictionaryValidation);
}

function* toggleColorDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_COLOR_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}


function* watchSelectAnyFilter() {
  yield takeEvery(ADMIN_ATTRIBUTES_SELECT_ANY, function*(action: any) {
    const attribute = yield select((state: any) => state.Admin.Attributes.all);
    if (action.component && action.component === 'color') {
      yield put({ type: ADMIN_ATTRIBUTES_COLOR_FILTER_SELECTED, data: attribute });
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_REQUEST, data: { marketplace_id: attribute.marketplace.selected.id } });
    }
  });
}

function* watchFetchAllMarketplaceColorsRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const results = yield api.marketplace.getAllColors(action.data).toPromise();
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_SUCCESS, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* createColor(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.color.create(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_SUCCESS, data: result, component: action.component });

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'color']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateColorRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_REQUESTED, createColor);
}

function* updateColor(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.color.update(action.data.id, action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_SUCCESS, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update color success', 5000);

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'color']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateColorRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_REQUESTED, updateColor);
}

function* watchSynchroniseColorsRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_COLOR_SYNCHRONISE_ATTRIBUTES_REQUEST, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.marketplace.syncAttributes(action.data).toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatus(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          router.navigate(colorAttributes());
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteColorRequest,
  watchFetchColorAttributeRequest,
  watchFetchColorDictionaryRequested,
  watchUpdateColorDictionaryRequested,
  watchSelectAnyFilter,
  watchFetchAllMarketplaceColorsRequest,
  watchCreateColorRequest,
  watchUpdateColorRequest,
  watchSynchroniseColorsRequest,
  toggleColorDictionaryValidationRequested
];
