import {
  ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_SUCCESSED,
  FETCH_SIZE_DICTIONARY_SUCCESSED,
  DELETE_SIZE_ATTRIBUTE_SUCCESSED,
  EDIT_SIZE_DICTIONARY_SUCCEEDED,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_SIZE_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_SIZE_FILTER_SELECTED, TOGGLE_SIZE_DICTIONARY_VALIDATION_SUCCEEDED
} from './size.action';
import * as _ from 'lodash';
import {
  ATTRIBUTE_COMPONENT,
  ADMIN_ATTRIBUTES_SELECT_MARKETPLACE
} from '../attributes.action';

export const Size = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    },
    marketplace_sizes: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case ADMIN_ATTRIBUTES_FETCH_SIZE_ATTRIBUTE_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_form = false;
        return item;
      });
      return { ...state, ...{ fetched: true, items: action.data, pagination: action.pagination } };

    case ADMIN_ATTRIBUTES_SIZE_CREATE_SIZE_SUCCESS:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            state.items.push(action.data);
            return _.assign({}, state, { create: { created: true, data: {} } });
        }
      }
      return state;

    case ADMIN_ATTRIBUTES_SIZE_EDIT_SIZE_SUCCESS:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            // tslint:disable-next-line:no-shadowed-variable
            const item = _.find(state.items, { id: action.data.id });
            if (!_.isNil(item)) {
              item.is_show_edit_form = false;
              item.name = action.data.name;
            }
            return { ...state, ...{ items: state.items } };
        }
      }
      return state;

    case DELETE_SIZE_ATTRIBUTE_SUCCESSED:
      return {
        ...state,
        ...{
          items: _.filter(state.items, i => i.id !== action.data),
          dictionary: { ...state.dictionary, ...{ items: _.filter(state.dictionary.items, i => i.attribute_id !== action.data) } }
        }
      };

    case FETCH_SIZE_DICTIONARY_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_dictionary_form = false;
        return item;
      });
      return _.assign({}, state, { dictionary: { fetched: true, items: action.data } });

    case EDIT_SIZE_DICTIONARY_SUCCEEDED:
      const item = _.find(state.dictionary.items, { id: action.data.id });
      if (!_.isNil(item)) {
        item.is_show_edit_dictionary_form = false;
        item.value = action.data.value;
      }
      return _.assign({}, state, { dictionary: { items: state.dictionary.items } });

    case ADMIN_ATTRIBUTES_SIZE_FILTER_SELECTED:
      return _.assign({}, state, {
        items: _.map(state.items, size => {
          size.selectedDictionary = size.dictionaries ? size.dictionaries
            .find(d => d.marketplace_id === action.data.marketplace.selected.id ) : null;
          return size;
        })
      });
    case TOGGLE_SIZE_DICTIONARY_VALIDATION_SUCCEEDED:
      return _.assign({}, state, {
        items: _.map(state.items, cat => {
          if (cat.selectedDictionary && cat.selectedDictionary.id === action.data.id) {
            return {
              ...cat,
              selectedDictionary: action.data
            };
          } else {
            return {
              ...cat
            }
          }
        })
      });

    case ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_SIZES_SUCCESS:
      return _.assign({}, state, {
        marketplace_sizes: {
          fetched: true,
          items: action.data
        },
        items: _.map(state.items, size => {
          let mapped;
          if (size.selectedDictionary) {
            mapped = action.data.find(d => d.id === size.selectedDictionary.source_id && d.name === size.selectedDictionary.value);
          }
          size.mappedAttribute = mapped ? mapped : null;
          return size;
        })
      });

    case ADMIN_ATTRIBUTES_SIZE_UPDATE_DICTIONARY_SUCCESS:
      return _.assign({}, state, {
        items: _.map(state.items, size => {
          if (size.id === action.data.attribute_id) {
            size.is_show_edit_dictionary_form = false;
            size.dictionaries = [...size.dictionaries, action.data];
            size.selectedDictionary = size.dictionaries.find(s => s.id === action.data.id);
          }
          return size;
        })
      });

    case ADMIN_ATTRIBUTES_SELECT_MARKETPLACE:
      if (action.direct) {
        return _.assign({}, state, { marketplace_sizes: _.assign({}, state.marketplace_sizes, { fetched: false }) });
      } else {
        return state;
      }

    case ADMIN_ATTRIBUTES_SIZE_SYNCHRONISE_ATTRIBUTES_REQUEST:
      return _.assign({}, state, { marketplace_sizes: _.assign({}, state.marketplace_sizes, { fetched: false }) });

    default:
      return state;
  }
};
