import { put, select, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as _ from "lodash";
import { API_CALL_ERROR } from '../../../store/action';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';
import {
  FETCH_DASHBOARD_REQUESTED,
  FETCH_DASHBOARD_SUCCEEDED,
  GET_ANALYTIC_DATA_REQUEST,
  GET_ANALYTIC_DATA_SUCCESS
} from './analytic.actions';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';

function* watchfetchAnalyticDataRetailer() {
  yield takeEvery(GET_ANALYTIC_DATA_REQUEST, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const result = yield api.analytic.getAnalyticDataDashboard().toPromise();
      yield put({ type: GET_ANALYTIC_DATA_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* fetchDashboard(action) {
  yield takeEvery(FETCH_DASHBOARD_REQUESTED, function*(action: any) {
    try {
      AppInjector.get(PreloaderService).show();
      const api = AppInjector.get(ApiService);
      let result = yield api.analytic.getQuicksightEmbeddedUrl().toPromise();
      AppInjector.get(PreloaderService).hide();
      yield put({ type: FETCH_DASHBOARD_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchfetchAnalyticDataRetailer, fetchDashboard];
