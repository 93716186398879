import { Router } from '@angular/router';
import { takeEvery, put, delay, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import * as _ from 'lodash';
import {
  FETCH_RETAILER_COMMISSION_REQUESTED,
  FETCH_RETAILER_COMMISSION_SUCCESSED,
  GET_COMMISSION_CONFIG_REQUESTED,
  GET_COMMISSION_CONFIG_SUCCESSED,
  SET_RETAILER_COMMISSIOS_CONSTANTS,
  UPDATE_COMMISSION_CONFIG_REQUESTED
} from './commission-config.action';

function* watchGetCommissionConfigRequest() {
  yield takeEvery(GET_COMMISSION_CONFIG_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let default_retailer_service;
      let result = yield api.user.getItemById(action.id, { from: 'commission-config' }).toPromise();
      const locations = yield api.admin.location.get({ per_page: 10000 }).toPromise();
      yield delay(1000);
      let zones = yield AppInjector.get(ApiService).admin.zone.list().toPromise();
      let currencies = yield AppInjector.get(ApiService).currency.get({ per_page: 10000 }).toPromise();
      currencies = currencies.items;
      const list_currency = _.cloneDeep(currencies);
      currencies = currencies.filter((e) => e.code === 'USD');
      yield put({
        type: SET_RETAILER_COMMISSIOS_CONSTANTS,
        data: {
          zones,
          currencies
        }
      });
      yield put({ type: GET_COMMISSION_CONFIG_SUCCESSED, data: result, default_retailer_service, locations: locations.items, list_currency });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchEditRetailerRequest() {
  yield takeEvery(UPDATE_COMMISSION_CONFIG_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);
    try {
      let result = yield api.user.update(action.id, action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Retailer Commission updated', 3000);
      // yield put({ type: GET_COMMISSION_CONFIG_REQUESTED, id: action.id });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchFetchDetailRetailerCommissionRequest() {
  yield takeEvery(FETCH_RETAILER_COMMISSION_REQUESTED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);
    try {
      // const default_retailer_service = yield call(fetchAllRetailerServiceShippingCost, action.data.id);
      yield put({ type: FETCH_RETAILER_COMMISSION_SUCCESSED, data: action.data });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export function* fetchAllRetailerServiceShippingCost(retailer_service_id) {
  try {
    const api = AppInjector.get(ApiService);
    let result = yield api.admin.user.getAllRetailerServiceShippingCost(retailer_service_id).toPromise();
    console.log('result', result);
    return result;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [watchEditRetailerRequest, watchGetCommissionConfigRequest, watchFetchDetailRetailerCommissionRequest];
