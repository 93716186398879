import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Store } from '../../../../store/store.module';
import { Block, DateTimePicker, Dropdown, InputBase, TextBox } from '@reflaunt/reactive-form';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators } from '@angular/forms';
import { ORDER_TYPES, ORDER_CANCEL_REASONS, ORDER_RETURN_REASONS } from '../../../../models/Order';

@Component({
  selector: 'sold-product-modal',
  templateUrl: './sold-product-modal.component.html',
  styleUrls: ['./sold-product-modal.component.scss']
})
export class ShowUserComponent implements OnInit, OnDestroy {
  @Input() public marketplace;
  @Input() public currencies;
  @Input() public product_item_management;
  @Input() public product_management;
  @Input() public product;
  @Input() public order_type;
  public inputs: InputBase<any>[] = [];
  store;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private activeModal: NgbActiveModal, store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.inputs = [
      new TextBox({
        key: 'marketplace_order_id',
        label: 'Marketplace Order ID *',
        classes: ['col-6'],
        group_classes: ['col-12'],
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        multiple: true,
        group: 1
      }),
      new DateTimePicker({
        key: 'sold_date',
        label: 'Sold Date *',
        classes: ['col-6'],
        group_classes: ['col-12'],
        group: 1,
        showIcon: true,
        monthNavigator: true,
        yearNavigator: true,
        yearRange: '1930:2050',
        placeholder: '01/01/2019'
      }),
      new Block({
        classes: ['col-12', 'text-center'],
        group_classes: ['col-12'],
        group: 2,
        content: `<h3>Sale Amount</h3>`
      }),
      new TextBox({
        key: 'initial_product_price',
        label: 'Initial Order Item Sale Amount *',
        type: 'number',
        validators: [
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          },
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        group: 3
      }),
      new Dropdown({
        key: 'product_price_currency_id',
        label: 'Order Item Sale Amount Currency *',
        required: true,
        classes: ['offset-6', 'currency-block'],
        group_classes: ['col-12'],
        group: 4,
        dropdownParent: '.custom-modal'
      }),
      new TextBox({
        key: 'product_price',
        label: 'Order Item Sale Amount *',
        type: 'number',
        validators: [
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          },
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        group: 5
      }),
      new Block({
        classes: ['col-12', 'text-center'],
        group_classes: ['col-12'],
        group: 6,
        content: `<h3>Payout</h3>`
      }),
      new TextBox({
        key: 'payout_to_supplier',
        label: 'Payout to supplier *',
        type: 'number',
        validators: [
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          },
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        group: 7
      }),
      new Dropdown({
        key: 'payout_to_supplier_currency_id',
        label: 'Payout to supplier currency *',
        required: true,
        classes: ['offset-6', 'currency-block'],
        group_classes: ['col-12'],
        group: 8,
        dropdownParent: '.custom-modal',
        ...(this.order_type === ORDER_TYPES.SALE && ((this.product_item_management && this.product_item_management.payout_to_supplier_currency_id) || this.product.currency_id)
          ? { disabled: true }
          : {})
      }),
      new TextBox({
        key: 'payout_to_original_seller',
        label: 'Payout to original seller *',
        type: 'number',
        validators: [
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          },
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        classes: ['col-6'],
        group_classes: ['col-12'],
        group: 9
      })
    ];
    if (!_.isNil(this.order_type) && this.order_type !== ORDER_TYPES.SALE) {
      this.inputs.push(
        new Dropdown({
          key: this.order_type === ORDER_TYPES.CANCEL ? 'cancel_reason' : 'return_reason',
          label: this.order_type === ORDER_TYPES.CANCEL ? 'Cancel reason *' : 'Return reason *',
          required: true,
          classes: ['col-12'],
          validators: [
            {
              validator: Validators.required,
              message: 'This field is required'
            }
          ],
          group_classes: ['col-12'],
          options: _.map(this.order_type === ORDER_TYPES.CANCEL ? ORDER_CANCEL_REASONS : ORDER_RETURN_REASONS, (i) => _.assign({}, i, { label: i.value })),
          group: 2,
          dropdownParent: '.custom-modal'
        })
      );
    }
    this.mapValue(this.inputs);
  }

  onSubmit = (form) => {
    if (form.valid) {
      if (form.value.product_price_currency_id) {
        form.value.product_price_currency_id = form.value.product_price_currency_id.value;
      }
      if (form.value.payout_to_supplier_currency_id) {
        form.value.payout_to_supplier_currency_id = form.value.payout_to_supplier_currency_id.value;
      }
      if (form.value.return_reason) {
        form.value.return_reason = form.value.return_reason.value;
      }
      if (form.value.cancel_reason) {
        form.value.cancel_reason = form.value.cancel_reason.value;
      }
      this.activeModal.close(form.value);
    }
  };

  ngOnDestroy() {
    this.clearData();
  }

  cancel = () => {
    this.activeModal.dismiss();
    this.clearData();
  };

  clearData = () => {
    this.marketplace = null;
    this.product_item_management = null;
    this.product_management = null;
    this.product = null;
    this.order_type = null;
  };

  mapValue = (inputs) => {
    return _.map(inputs, (i) => {
      if (['product_price_currency_id', 'payout_to_supplier_currency_id'].indexOf(i.key) > -1) {
        i.options = _.map(this.currencies, (currency) => _.assign(currency, { label: currency.code, value: currency.id }));
      }
      if (i.key === 'product_price' || i.key === 'initial_product_price') {
        i.value = this.product_management ? this.product_management.partner_price : 0;
      }
      if (i.key === 'product_price_currency_id' && this.marketplace) {
        const currency_id = this.marketplace.marketplace.is_using_partner_selling_price
          ? this.product_management.partner_price_currency_id
          : this.marketplace.marketplace.currency_id;
        i.value = _.find(i.options, (o: any) => o.id === currency_id);
      }
      if (i.key === 'sold_date') {
        i.value = new Date();
      }
      if (i.key === 'payout_to_original_seller') {
        i.value = this.product.price.price;
      }
      if (i.key === 'payout_to_supplier') {
        i.value = this.product_item_management && this.product_item_management.payout_to_supplier ? this.product_item_management.payout_to_supplier : this.product.price.price;
      }

      if (i.key === 'payout_to_supplier_currency_id') {
        i.value = _.find(
          i.options,
          (o: any) => (this.product_item_management && o.id === this.product_item_management.payout_to_supplier_currency_id) || o.id === this.product.currency_id
        );
      }
      return i;
    });
  };
}
