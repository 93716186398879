import { CUSTOMER_COMP, detailRouter, editRouter, transactionHistoryRouter } from './../customer.const';
import { FETCH_ALL_CUSTOMER_GROUP_REQUESTED } from './../customer.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { FETCH_CUSTOMERS_REQUESTED, SORT_CUSTOMERS_REQUESTED, DELETE_CUSTOMERS_REQUESTED } from './list.actions';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { MultiSearchAction } from '../../../../models/MultiSearchAction';
import { Store } from '../../../../store/store.module';
import { QueryParser } from '../../../../common/util/query-parser';
import { listRouter } from '../../product/product.const';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  protected navigationSubscription: any;
  public store;
  public filter;
  public queryParser;
  public detailRouter = detailRouter;
  public editRouter = editRouter;
  public transactionRouter = transactionHistoryRouter;

  public search_actions = _.map(
    [
      {
        label: 'Email',
        placeholder: 'Search by customer email'
      }
    ],
    action => new MultiSearchAction(action)
  );
  public listProductRouter = listRouter;
  public constraintsFunction = (id) => JSON.stringify({ seller_id: id });

  constructor(private notification: NotificationService, private activeRouter: ActivatedRoute, private router: Router, store: Store, queryParser: QueryParser) {
    this.store = store.getInstance();
    this.queryParser = queryParser;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: FETCH_CUSTOMERS_REQUESTED,
          data: _.assign({}, this.queryParser.parse(['sort', 'constraints', 'page', 'search', 'email']), {
            roles: 'customer'
          })
        });
        // this.store.dispatch({ type: FETCH_ALL_CUSTOMER_GROUP_REQUESTED, com: CUSTOMER_COMP });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  delete(id) {
    this.store.dispatch({
      type: DELETE_CUSTOMERS_REQUESTED,
      data: id
    });
  }
}
