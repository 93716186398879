import * as _ from 'lodash';
import { GET_DETAIL_TEMPLATE_SUCCEEDED } from './detail.actions';

export const detailTemplate = (state = { fetched: false }, action) => {
  switch (action.type) {
    case GET_DETAIL_TEMPLATE_SUCCEEDED:
      return _.assign({}, state, { fetched: true, item: action.data });
    default:
      return state;
  }
};
