import {
  FETCH_SIZE_ATTRIBUTE_REQUESTED,
  FETCH_SIZE_ATTRIBUTE_SUCCESSED,
  CREATE_SIZE_REQUESTED,
  CREATE_SIZE_SUCCESSED,
  FETCH_SIZE_DICTIONARY_SUCCESSED,
  FETCH_SIZE_DICTIONARY_REQUESTED,
  CREATE_SIZE_DICTIONARY_SUCCESSED,
  DELETE_SIZE_ATTRIBUTE_REQUESTED,
  DELETE_SIZE_ATTRIBUTE_SUCCESSED,
  EDIT_SIZE_REQUESTED,
  EDIT_SIZE_SUCCESSED,
  EDIT_SIZE_DICTIONARY_REQUESTED,
  EDIT_SIZE_DICTIONARY_SUCCESSED
} from './size.action';
import { takeEvery, put, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { DICTIONARY_SIZE_TYPE } from '../../../../app.const';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';

export function* fetchAllsize(params = {}) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.size.get({ ...{ per_page: 10000 }, ...params }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchSizeById(params = {}) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.size.get({ ...{ per_page: 20 }, ...params }).toPromise();
    return results.items;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export function* fetchAllsizeUnit() {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.size.getSizesUnit('SHOES').toPromise();
    return results;
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* fetchSizes(action) {
  try {
    let results = yield AppInjector.get(ApiService).size.get({ per_page: 10000 }).toPromise();
    yield put({ type: FETCH_SIZE_ATTRIBUTE_SUCCESSED, data: results.items });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchSizeAttributeRequested() {
  yield takeEvery(FETCH_SIZE_ATTRIBUTE_REQUESTED, fetchSizes);
}

function* createSize(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.size.create(action.data).toPromise();
    yield put({ type: CREATE_SIZE_SUCCESSED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateSizeRequested() {
  yield takeEvery(CREATE_SIZE_REQUESTED, createSize);
}

function* fetchSizeDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_SIZE_DICTIONARY_SUCCESSED, data: results });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchSizeDictionaryRequested() {
  yield takeEvery(FETCH_SIZE_DICTIONARY_REQUESTED, fetchSizeDictionary);
}

function* createSizeDictionary(action) {
  const fetched_marketplace = yield select((state) => (state as any).Admin.Attribute.all.marketplace.fetched);
  if (fetched_marketplace) {
    const marketplaces = yield select((state) => (state as any).Admin.Attribute.all.marketplace.items);
    for (const marketplace of marketplaces) {
      try {
        const api = AppInjector.get(ApiService);

        let data = {
          type: DICTIONARY_SIZE_TYPE,
          attribute_id: action.data.getId(),
          marketplace_id: marketplace.getId(),
          value: action.data.getName()
        };

        let result = yield api.admin.dictionary.create(data).toPromise();
        yield put({ type: CREATE_SIZE_DICTIONARY_SUCCESSED, data: result });

        let marketplace_id = AppInjector.get(ActivatedRoute).snapshot.queryParams.marketplace_id;
        if (!_.isNil(marketplace_id)) {
          yield put({
            type: FETCH_SIZE_DICTIONARY_REQUESTED,
            data: {
              marketplace_id: marketplace_id,
              type: DICTIONARY_SIZE_TYPE
            }
          });
        }
      } catch (e) {
        AppInjector.get(PreloaderService).hide();
        yield put({ type: API_CALL_ERROR, error: e });
      }
    }
  }
}

function* watchCreateSizeSucceeded() {
  yield takeEvery(CREATE_SIZE_SUCCESSED, createSizeDictionary);
}

function* watchDeleteItemRequest() {
  yield takeEvery(DELETE_SIZE_ATTRIBUTE_REQUESTED, deleteItem);
}

function* deleteItem(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.size.delete(action.data.id).toPromise();
    yield put({ type: DELETE_SIZE_ATTRIBUTE_SUCCESSED, data: action.data.id });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditSizeRequested() {
  yield takeEvery(EDIT_SIZE_REQUESTED, editSize);
}

function* editSize(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.size.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_SIZE_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update size success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchEditSizeDictionaryRequested() {
  yield takeEvery(EDIT_SIZE_DICTIONARY_REQUESTED, editSizeDictionary);
}

function* editSizeDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.update(action.data.id, action.data).toPromise();
    yield put({ type: EDIT_SIZE_DICTIONARY_SUCCESSED, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

export default [
  watchFetchSizeAttributeRequested,
  watchCreateSizeRequested,
  watchFetchSizeDictionaryRequested,
  watchCreateSizeSucceeded,
  watchDeleteItemRequest,
  watchEditSizeRequested,
  watchEditSizeDictionaryRequested
];
