import { ServiceProvider } from '../../service.provider';
import TransactionLog from '../../../models/TransactionLog';
import { Injectable } from '@angular/core';

@Injectable()
export class TransactionLogService extends ServiceProvider {
  public url = '/api/v1/admin/transaction_logs';
  public model = TransactionLog;
}


