import { takeLatest, put } from 'redux-saga/effects';
import {
  FETCH_ALL_CREDENTIALS_REQUESTED,
  FETCH_ALL_CREDENTIALS_SUCCEEDED,
  CREATE_CREDENTIAL_REQUESTED,
  CREATE_CREDENTIAL_SUCCEEDED,
  DELETE_CREDENTIAL_REQUESTED
} from './credential.actions';
import { NotificationService } from '../../common/services/notification/notification.service';
import { AppInjector } from '../../app-injector';
import { ApiService } from '../../api/api.service';
import { Router } from '@angular/router';
import { API_CALL_ERROR } from '../../store/action';
import { MARKETPLACE_COMP } from '../marketplace/marketplace.const';
import { ADMIN_RETAILER_COMP } from '../admin/retailer/retailer.const';
import { RETAILER_COMP } from '../retailer/retailer.const';
import { ADMIN_MARKETPLACE_COMP } from '../admin/marketplace/marketplace.const';

const getRedirectUrl = (comp, extra = {}) => {
  switch (comp) {
    case MARKETPLACE_COMP:
      return '/marketplace/credential';
    case ADMIN_RETAILER_COMP:
      return '/admin/retailer/' + extra['id'] + '/credential';
    case ADMIN_MARKETPLACE_COMP:
      return '/admin/marketplace/' + extra['id'] + '/credential';
    case RETAILER_COMP:
      return '/retailer/credential';
  }
};

function* watchGetAllCredentailsRequest() {
  yield takeLatest(FETCH_ALL_CREDENTIALS_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      let result;
      switch (action.comp) {
        case MARKETPLACE_COMP:
          result = yield api.oauthClient
            .get({
              ...action.data,
              includes: 'marketplace'
            })
            .toPromise();
          yield put({ type: FETCH_ALL_CREDENTIALS_SUCCEEDED, items: result.items, pagination: result.pagination });
          break;
        case ADMIN_RETAILER_COMP:
          result = yield api.admin.oauthClient
            .get({
              ...action.data,
              constraints: JSON.stringify({
                user_id: action.params.id
              }),
              includes: 'retailer'
            })
            .toPromise();
          yield put({ type: FETCH_ALL_CREDENTIALS_SUCCEEDED, items: result.items, pagination: result.pagination });
          break;
        case ADMIN_MARKETPLACE_COMP:
          result = yield api.admin.oauthClient
            .get({
              ...action.data,
              constraints: JSON.stringify({
                user_id: action.params.id
              }),
              includes: 'marketplace'
            })
            .toPromise();
          yield put({ type: FETCH_ALL_CREDENTIALS_SUCCEEDED, items: result.items, pagination: result.pagination });
          break;
        case RETAILER_COMP:
          result = yield api.oauthClient
            .get({
              ...action.data,
              includes: 'retailer'
            })
            .toPromise();
          yield put({ type: FETCH_ALL_CREDENTIALS_SUCCEEDED, items: result.items, pagination: result.pagination });
          break;
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateCredentialRequest() {
  yield takeLatest(CREATE_CREDENTIAL_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);

      let result;
      switch (action.comp) {
        case MARKETPLACE_COMP:
          result = yield api.oauthClient.create(action.data).toPromise();
          yield put({ type: CREATE_CREDENTIAL_SUCCEEDED, data: result, params: action.params, comp: action.comp });
          break;
        case ADMIN_RETAILER_COMP:
        case ADMIN_MARKETPLACE_COMP:
          result = yield api.admin.oauthClient
            .create({
              ...action.data,
              user_id: action.params.id
            })
            .toPromise();
          yield put({ type: CREATE_CREDENTIAL_SUCCEEDED, data: result, params: action.params, comp: action.comp });
          break;
        case RETAILER_COMP:
          result = yield api.oauthClient.create(action.data).toPromise();
          yield put({ type: CREATE_CREDENTIAL_SUCCEEDED, data: result, params: action.params, comp: action.comp });
          break;
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateCredentialSuccess() {
  yield takeLatest(CREATE_CREDENTIAL_SUCCEEDED, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);

    const result = yield api.oauthClient.showCredentialAfterCreateSuccess(action.data);
    if (result.value) {
      router.navigate([getRedirectUrl(action.comp, action.params)]);
    }
  });
}

function* watchDeleteCredentialRequest() {
  yield takeLatest(DELETE_CREDENTIAL_REQUESTED, function* (action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);

      switch (action.comp) {
        case MARKETPLACE_COMP:
          yield api.oauthClient.delete(action.id).toPromise();
          break;
        case ADMIN_RETAILER_COMP:
        case ADMIN_MARKETPLACE_COMP:
          yield api.admin.oauthClient.delete(action.id).toPromise();
          break;
        case RETAILER_COMP:
          yield api.oauthClient.delete(action.id).toPromise();
          break;
      }

      notify.show('success', 'Delete credential success', 3000);
      router.navigate([getRedirectUrl(action.comp, action.params)]);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetAllCredentailsRequest, watchCreateCredentialRequest, watchCreateCredentialSuccess, watchDeleteCredentialRequest];
