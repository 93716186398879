import * as _ from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';
import {
  GET_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
  GET_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED,
  UPDATE_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED,
  UPDATE_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED
} from './smart-button.actions';
import { NotificationService } from '../../../common/services/notification/notification.service';
import { ApiService } from '../../../api/api.service';
import { AppInjector } from '../../../app-injector';
import { API_CALL_ERROR } from '../../../store/action';

function* watchGetRenderConfigRequested() {
  yield takeEvery(GET_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED, function*(action: any) {
    try {
      let result = yield AppInjector.get(ApiService).smartButton.getMyRenderConfig().toPromise();
      yield put({ type: GET_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateRenderConfigRequested() {
  yield takeEvery(UPDATE_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED, function*(action: any) {
    try {
      const notification = AppInjector.get(NotificationService);
      let result = yield AppInjector.get(ApiService).smartButton.updateMyRenderConfig(action.data).toPromise();
      yield put({ type: UPDATE_MY_SMART_BUTTON_RENDER_CONFIG_SUCCEEDED, data: result });
      yield put({ type: GET_MY_SMART_BUTTON_RENDER_CONFIG_REQUESTED });
      notification.show('success', 'Configuration saved', 5000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetRenderConfigRequested, watchUpdateRenderConfigRequested];
