import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Directive({
  selector: '[appNgSelectOnOpen]'
})
export class NgSelectOnOpenDirective implements OnInit {

  @Input('items')
  items: any;
  public longest: number;

  constructor(public el: ElementRef) {
  }
  @HostListener('open') onOpen() {
    setTimeout(() => {
      const dropdown = $(this.el.nativeElement).find('.ng-dropdown-panel');
      const inputVal = $(this.el.nativeElement).find('.ng-value');
      if (inputVal) {
        inputVal.css({
          color: 'grey'
        });
      }
      const newWidth = (this.longest * 7) + (5 / 100 * (this.longest * 7));
      const curWidth = dropdown.width();
      if (curWidth < newWidth) {
        dropdown.css({
          width: newWidth + 'px',
          left: (curWidth - newWidth) + 'px'
        });
      }
    });
  }

  ngOnInit(): void {
    this.longest = this.items.reduce((acc, cur) => {
      if (cur.name.length > acc) {
        acc = cur.name.length;
      }
      return acc;
    }, 0);
  }

}
