import {
  FETCH_ALL_ADMIN_ORDER_ITEM_SUCCESSED,
  GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_SUCCESSED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_SUCCESSED,
  ORDER_ITEM_UPDATE_INPUT_FILTER_DATE,
  FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_SUCCESSED,
  FETCH_ALL_DESIGNER_ORDER_ITEM_PAGE_SUCCESSED,
  FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_SUCCESSED,
  UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_SUCCESSED
} from './list.actions';
import * as _ from 'lodash';

export const list = (
  state = {
    fetched: false,
    items: [],
    retailers: [],
    filter_date: [],
    conditions: { fetched: false, items: [] },
    categories: { fetched: false, items: [] },
    inputs_designer: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_ADMIN_ORDER_ITEM_SUCCESSED:
      return _.assign({}, state, { fetched: true, items: action.data, pagination: action.pagination });
    case GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_SUCCESSED:
      return _.assign({}, state, { fetched: true, retailers: action.data });
    case FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_SUCCESSED:
      return _.assign({}, state, { fetched: true, marketplaces: action.data });
    case ORDER_ITEM_UPDATE_INPUT_FILTER_DATE:
      action.inputs = _.map(action.inputs, (i) => {
        if (i.key === 'date_filter_type') {
          i.value = _.head(i.options);
        }
        return i;
      });
      return _.assign({}, state, { filter_date: action.inputs });
    default:
      return state;
  }
};
