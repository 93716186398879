import { takeEvery, put, takeLatest, all, call, select } from 'redux-saga/effects';
import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { FETCH_SHIPMENT_REQUESTED, FETCH_SHIPMENT_SUCCEEDED, SHIPMENT_FETCH_ALL_COURIERS_REQUESTED, SHIPMENT_FETCH_ALL_COURIERS_SUCCESSED } from './list.actions';

function* watchFetchShipmentRequested() {
  yield takeEvery(FETCH_SHIPMENT_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.shipment.get(action.data).toPromise();
      yield put({ type: FETCH_SHIPMENT_SUCCEEDED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}
export default [watchFetchShipmentRequested];
