export const FETCH_CUSTOMER_DETAIL_REQUESTED = 'FETCH_CUSTOMER_DETAIL_REQUESTED';
export const FETCH_CUSTOMER_DETAIL_SUCCEEDED = 'FETCH_CUSTOMER_DETAIL_SUCCEEDED';
export const CREATE_CUSTOMER_ADDRESS_REQUESTED = 'CREATE_CUSTOMER_ADDRESS_REQUESTED';
export const CREATE_CUSTOMER_ADDRESS_SUCCEEDED = 'CREATE_CUSTOMER_ADDRESS_SUCCEEDED';
export const UPDATE_CUSTOMER_REQUESTED = 'UPDATE_CUSTOMER_REQUESTED';
export const UPDATE_CUSTOMER_SUCCEEDED = 'UPDATE_CUSTOMER_SUCCEEDED';
export const FETCH_CUSTOMER_ADDRESSES_REQUESTED = 'FETCH_CUSTOMER_ADDRESSES_REQUESTED';
export const FETCH_CUSTOMER_ADDRESSES_SUCCEEDED = 'FETCH_CUSTOMER_ADDRESSES_SUCCEEDED';
export const DELETE_CUSTOMER_ADDRESS_REQUESTED = 'DELETE_CUSTOMER_ADDRESS_REQUESTED';
export const DELETE_CUSTOMER_ADDRESS_SUCCEEDED = 'DELETE_CUSTOMER_ADDRESS_SUCCEEDED';
export const UPDATE_CUSTOMER_ADDRESS_REQUESTED = 'UPDATE_CUSTOMER_ADDRESS_REQUESTED';
export const UPDATE_CUSTOMER_ADDRESS_SUCCEEDED = 'UPDATE_CUSTOMER_ADDRESS_SUCCEEDED';
export const SET_DEFAULT_ADDRESS_REQUESTED = 'SET_DEFAULT_ADDRESS_REQUESTED';
export const SET_DEFAULT_ADDRESS_SUCCEEDED = 'SET_DEFAULT_ADDRESS_SUCCEEDED';
export const DELETE_CUSTOMER_REQUESTED = 'DELETE_CUSTOMER_REQUESTED';
export const DELETE_CUSTOMER_SUCCEEDED = 'DELETE_CUSTOMER_SUCCEEDED';
export const FETCH_ALL_CUSTOMER_ORDER_SUCCEEDED = 'FETCH_ALL_CUSTOMER_ORDER_SUCCEEDED';



