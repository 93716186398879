import { Router } from '@angular/router';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { takeEvery, put, takeLatest, all, call, select } from 'redux-saga/effects';
import { GET_ALL_CATEGORIES_REQUESTED } from '../../../admin/category/category.actions';
import {
  RETAILER_UPDATE_PRODUCT_SUCCEEDED,
  RETAILER_UPDATE_PRODUCT_REQUESTED,
  RETAILER_DELETE_PRODUCT_SUCCEEDED,
  RETAILER_DELETE_PRODUCT_REQUESTED,
  RETAILER_GET_PRODUCT_SUCCEEDED,
  RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
  RETAILER_FILL_PRODUCT_DETAIL_FORM,
  RETAILER_GET_PRODUCT_REQUESTED,
  RETAILER_RENDER_EDIT_PRODUCT_FORM_REQUESTED
} from './edit.actions';
import { FETCH_PRODUCT_DETAIL_SUCCEEDED } from '../../../admin/product/detail/detail.actions';
import { fetchAllcurrency } from '../../../admin/currency/currency.saga';
import { fetchAllsize } from '../../../admin/attribute/size/size.saga';
import { fetchAlldesigner } from '../../../admin/attribute/designer/designer.saga';
import { fetchAllcolor } from '../../../admin/attribute/color/color.saga';
import { fetchAllcategory } from '../../../admin/category/category.saga';
import { listRouter } from '../product.const';
import { fetchAllConstants } from '../../../main.saga';
import { resizeImages } from '../../../../app.const';

export function* retailerId() {
  return yield select(state => (state as any).Auth.login.profile.id);
}

function* retailerUpdateProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.product.update(action.data.id, action.data).toPromise();
    yield put({ type: RETAILER_UPDATE_PRODUCT_SUCCEEDED, data: result });
    AppInjector.get(NotificationService).show('success', 'Successfully edit product', 5000);
    let redirect = listRouter(yield select(state => (state as any).Auth.login.profile.id));
    if (action.hasOwnProperty('redirect')) {
      redirect = action.redirect;
    }
    router.navigate(redirect);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchRetailerUpdateProductRequested() {
  yield takeEvery(RETAILER_UPDATE_PRODUCT_REQUESTED, retailerUpdateProduct);
}

function* retailerDeleteProduct(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.product.delete(action.data).toPromise();
    yield put({ type: RETAILER_DELETE_PRODUCT_SUCCEEDED });
    AppInjector.get(NotificationService).show('success', 'Successfully delete product', 5000);
    let redirect = listRouter(yield select(state => (state as any).Auth.login.profile.id));
    if (action.hasOwnProperty('redirect')) {
      redirect = action.redirect;
    }
    router.navigate(redirect);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchRetailerDeleteProductRequested() {
  yield takeEvery(RETAILER_DELETE_PRODUCT_REQUESTED, retailerDeleteProduct);
}

function* watchFetchProductSuccessed() {
  yield takeEvery(FETCH_PRODUCT_DETAIL_SUCCEEDED, function*() {
    yield put({
      type: GET_ALL_CATEGORIES_REQUESTED
    });
  });
}

export function* fetchProductDetail(id, params) {
  if (!_.isNil(params)) {
    return yield AppInjector.get(ApiService)
      .product.getItemById(id, params)
      .toPromise();
  } else {
    return yield AppInjector.get(ApiService)
      .product.getItemById(id)
      .toPromise();
  }
}

export function* fetchProductImages(id) {
  return yield AppInjector.get(ApiService)
    .admin.image.list({ constraints: { imageable_id: id, imageable_type: 'product' } })
    .toPromise();
}

function* getPost(action) {
  const [product, currencies, sizes, designers, colors, categories, constants, images] = yield all([
    call(fetchProductDetail, action.data, action.params),
    call(fetchAllcurrency),
    call(fetchAllsize),
    call(fetchAlldesigner),
    call(fetchAllcolor),
    call(fetchAllcategory),
    call(fetchAllConstants),
    call(fetchProductImages, action.data)
  ]);
  resizeImages(images);

  yield put({ type: RETAILER_GET_PRODUCT_SUCCEEDED, data: product });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'currency_id',
    data: _.map(currencies, (item, key) => _.assign(item, { value: item.id, label: item.code, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'size_id',
    data: _.map(sizes, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'designer_id',
    data: _.map(designers, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'color_id',
    data: _.map(colors, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'category_id',
    data: _.map(categories, (item, key) => _.assign(item, { value: item.id, label: item.name, selected: Number(key) === 0 }))
  });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'gender',
    data: _.map(constants.gender, (item, key) => _.assign(item, { value: item.value, label: item.key }))
  });
  yield put({
    type: RETAILER_UPDATE_EDIT_PRODUCT_INPUT_OPTIONS,
    input: 'age_group',
    data: _.map(constants.age_group, (item, key) => _.assign(item, { value: item.value, label: item.key }))
  });
  const data = {
    sku: product.sku,
    name: product.name,
    price: product.price.price,
    original_price: product.original_price,
    designer_id: product.designer_id ? _.find(designers, item => item.id === product.designer_id) : null,
    size_id: product.size_id ? _.find(sizes, item => item.id === product.size_id) : null,
    heel_height: product.heel_height,
    dimensions: product.dimensions,
    color_id: product.color_id ? _.find(colors, item => item.id === product.color_id) : null,
    material: product.material,
    // images: _.map(images, 'url'),
    images: _.sortBy(images, 'position').map(p => p.url),
    customer_email: product.seller ? product.seller.email : '',
    category_id: product.categories.data.length > 0 ? _.find(categories, item => item.id === product.categories.data[0].id) : null,
    website: product.website,
    description: product.description,
    retailer_email: product.retailer ? product.retailer.email : '',
    quantity: product.quantity ? product.quantity : 1,
    currency_id: _.find(currencies, item => product.currency.id === item.id),
    gender: constants.gender.find(s => product.gender === s.value),
    age_group: constants.age_group.find(s => product.age_group === s.value)
  };
  yield put({ type: RETAILER_FILL_PRODUCT_DETAIL_FORM, data: data });
}

function* watchGetProductRequest() {
  yield takeEvery(RETAILER_GET_PRODUCT_REQUESTED, getPost);
}

function* watchRenderProductDetailFormRequested() {
  yield takeLatest(RETAILER_RENDER_EDIT_PRODUCT_FORM_REQUESTED, function*(action: any) {
    yield put({ type: RETAILER_GET_PRODUCT_REQUESTED, data: action.data.id, params: action.param });
  });
}

export default [
  watchRetailerUpdateProductRequested,
  watchRetailerDeleteProductRequested,
  watchFetchProductSuccessed,
  watchRenderProductDetailFormRequested,
  watchGetProductRequest
];
