import { retailerCustomerCareDashboardRouter, retailerDashboardRouter } from './components/retailer/retailer.const';
import * as Cookies from 'js-cookie';

export const DICTIONARY_CATEGORY_TYPE = 'categories';
export const DICTIONARY_DESIGNER_TYPE = 'designers';
export const DICTIONARY_SIZE_TYPE = 'sizes';
export const DICTIONARY_CONDITION_TYPE = 'conditions';
export const DICTIONARY_COLOR_TYPE = 'colors';
export const DICTIONARY_MATERIAL_TYPE = 'materials';

export const ProductStatuses = [
  {
    label: 'Pending',
    value: 1
  },
  {
    label: 'Flaunt',
    value: 2
  },
  {
    label: 'Ready for sale',
    value: 3
  },
  {
    label: 'Selling',
    value: 4
  },
  {
    label: 'Sold',
    value: 5
  },
  {
    label: 'Canceled',
    value: 6
  },
  {
    label: 'Wanted',
    value: 7
  },
  {
    label: 'Sold Confirmed',
    value: 8
  }
];

export const USER_ROLES = {
  SUPER_ADMIN: '1',
  ADMIN: '2',
  CUSTOMER: '3',
  RETAILER: '4',
  MARKETPLACE: '5',
  USER: '6'
};

export const USER_ROLES_SLUG = {
  SUPER_ADMIN: 'superadmin',
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  RETAILER: 'retailer',
  MARKETPLACE: 'marketplace',
  USER: 'user'
};

export const AvailablePermissions = {
  ADMIN_VIEW: 'admin.view',
  ADMIN_CREATE: 'admin.create',
  ADMIN_UPDATE: 'admin.update',
  ADMIN_DELETE: 'admin.delete',

  USER_VIEW: 'user.view',
  USER_CREATE: 'user.create',
  USER_UPDATE: 'user.update',
  USER_DELETE: 'user.delete',

  ROLE_VIEW: 'role.view',
  ROLE_CREATE: 'role.create',
  ROLE_UPDATE: 'role.update',
  ROLE_DELETE: 'role.delete',

  PRODUCT_VIEW: 'product.view',
  PRODUCT_CREATE: 'product.create',
  PRODUCT_UPDATE: 'product.update',
  PRODUCT_DELETE: 'product.delete',

  CUSTOMER_VIEW: 'customer.view',
  CUSTOMER_CREATE: 'customer.create',
  CUSTOMER_UPDATE: 'customer.update',
  CUSTOMER_DELETE: 'customer.delete',

  CATEGORY_VIEW: 'category.view',
  CATEGORY_UPDATE: 'category.update',
  CATEGORY_CREATE: 'category.create',
  CATEGORY_DELETE: 'category.delete',

  CONDITION_VIEW: 'condition.view',
  CONDITION_CREATE: 'condition.create',
  CONDITION_UPDATE: 'condition.update',
  CONDITION_DELETE: 'condition.delete',

  DESIGNER_VIEW: 'designer.view',
  DESIGNER_CREATE: 'designer.create',
  DESIGNER_UPDATE: 'designer.update',
  DESIGNER_DELETE: 'designer.delete',

  ORDER_VIEW: 'order.view',
  ORDER_CREATE: 'order.create',
  ORDER_UPDATE: 'order.update',
  ORDER_DELETE: 'order.delete',

  SIZE_VIEW: 'size.view',
  SIZE_CREATE: 'size.create',
  SIZE_UPDATE: 'size.update',
  SIZE_DELETE: 'size.delete',

  STYLE_VIEW: 'styles.view',
  STYLE_CREATE: 'styles.create',
  STYLE_UPDATE: 'styles.update',
  STYLE_DELETE: 'styles.delete',

  STATUS_VIEW: 'status.view',
  STATUS_CREATE: 'status.create',
  STATUS_UPDATE: 'status.update',
  STATUS_DELETE: 'status.delete',

  FLAUNT_VIEW_EMAILS: 'flaunt.view.emails',

  INVENTORY_VIEW: 'inventory.view',
  INVENTORY_CREATE: 'inventory.create',
  INVENTORY_UPDATE: 'inventory.update',
  INVENTORY_DELETE: 'inventory.delete'
};

export const redirectToHome = (user, router) => {
  const homeUri = Cookies.get('home_route_uri');
  if (homeUri) {
    router.navigateByUrl(homeUri);
  } else {
    if (user.isRole('admin')) {
      router.navigate(['/', 'admin', 'product']);
    } else if (user.isRole('customer')) {
      router.navigate(['/']);
    } else if (user.isRole('retailer')) {
      router.navigate(retailerDashboardRouter());
    } else if (user.isRole('retailer_customer_care')) {
      router.navigate(retailerCustomerCareDashboardRouter());
    } else {
      router.navigate(['/', 'admin', 'product']);
    }
  }
};

export const isBindingResponsiveTableClass = () => {
  if (window.innerWidth < 1024) {
    return true;
  }
  return false;
};

export const isBindingFilterSearchNoPaddingClass = () => {
  if (window.innerWidth >= 768) {
    return true;
  }
  return false;
};

export function resizeImages(images, dimensions: string = '139x139') {
  images.forEach((image) => {
    let arr = image.url.slice(8).split('/');
    arr.splice(3, 0, dimensions);
    image.urlZoom = image.url;
    image.url = `https://${arr.join('/')}`;
  });
}
