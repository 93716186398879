import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import * as _ from 'lodash';
import { AppInjector } from '../../../app-injector';
import { Store, } from '../../../store/store.module';
import {
    FETCH_MEASUREMENT_UNIT_DETAIL_REQUEST,
    UPDATE_MEASUREMENT_UNIT_REQUESTED
} from './edit.action';
import { ApiService } from '../../../api/api.service';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseComponent implements OnInit, OnDestroy {

  public measurementUnit;
  public idMeasurement;
  public checkform = false;
  public showValid = false;

  constructor(private activatedRoute: ActivatedRoute, private route: Router, private api: ApiService) {
    super();
    this.navigationSubscription = this.route.events.subscribe((e: any) => {
      this.idMeasurement = this.activatedRoute.snapshot.params.id;
      if (e instanceof NavigationEnd) {
        this.store.dispatch({
          type: FETCH_MEASUREMENT_UNIT_DETAIL_REQUEST,
          data: { id: this.activatedRoute.snapshot.params.id }
        });
      }
    });
  }



  ngOnInit() {
    this.api.measurementUnit.show(this.idMeasurement).subscribe(
      response => {
        this.measurementUnit = new FormGroup({
          name: new FormControl(response.name, [Validators.required]),
        });
        this.checkform = true;
      },
      error => {

      }
    );
  }

  mapStateToProps(state) {
    return {
      payload: state.MeasurementUnit.Edit
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }

  ngOnDestroy() {
    if (!_.isUndefined(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onSubmit(form) {
    if (!form.valid) {
      this.showValid = true;
      return;
    }
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({ type: UPDATE_MEASUREMENT_UNIT_REQUESTED, data: form.value, id: this.idMeasurement });
  }


}

