import { put, takeLatest, select } from 'redux-saga/effects';
import {
  GET_ALL_USER_SERVICE_CONFIGURATION_REQUEST,
  GET_ALL_USER_SERVICE_CONFIGURATION_SUCCESS,
  SYNC_USER_SERVICE_CONFIGURATION_REQUEST,
  SYNC_USER_SERVICE_CONFIGURATION_SUCCESS,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS
} from './service-configuration.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { Router } from '@angular/router';
import { editServiceConfigurationRouter } from '../user.const';

function* watchGetUserDetailRequest() {
  yield takeLatest(GET_USER_DETAIL_REQUEST, function*(action: any) {
    try {
      const { id } = action.data;
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.user.getItemById(id).toPromise();
      yield put({ type: GET_USER_DETAIL_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllUserServiceConfigurationRequest() {
  yield takeLatest(GET_ALL_USER_SERVICE_CONFIGURATION_REQUEST, function*(action: any) {
    try {
      const { id } = action.data;
      const api = AppInjector.get(ApiService);
      const result = yield api.admin.user.getAllServiceConfigurations(id).toPromise();
      yield put({ type: GET_ALL_USER_SERVICE_CONFIGURATION_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchSyncUserServiceConfigurationRequest() {
  yield takeLatest(SYNC_USER_SERVICE_CONFIGURATION_REQUEST, function*(action: any) {
    try {
      const { id, data } = action.data;
      const api = AppInjector.get(ApiService);
      const notify = AppInjector.get(NotificationService);
      const router = AppInjector.get(Router);

      const result = yield api.admin.user.syncServiceConfigurations(id, data).toPromise();

      yield put({ type: SYNC_USER_SERVICE_CONFIGURATION_SUCCESS, data: result });
      notify.show('success', 'Service configuration success', 3000);
      router.navigate(editServiceConfigurationRouter(id));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetAllUserServiceConfigurationRequest, watchSyncUserServiceConfigurationRequest, watchGetUserDetailRequest];
