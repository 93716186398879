import { AppInjector } from './../../app-injector';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import LengthAwarePaginator from '../../models/LengthAwarePaginator';
import User from '../../models/User';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { BankAccount } from '../../models/BankAccount';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class UserService extends ServiceProvider {
  public url = '/api/v1/admin/users';
  public model = User;

  profile(params: {}): Observable<any> {
    const meUrl = '/api/v1/me';
    return this.http.get(this.apiUrl.getApiUrl(meUrl) + '/profile', { params }).pipe(
      map((result) => new User((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }

  addCustomer(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data.user_id + '/customer', data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  attachRoleToUser(userId, roleId): Observable<any> {
    return this.http
      .put(this.apiUrl.getApiUrl(this.url) + `/${userId}/role`, {
        role_id: roleId
      })
      .pipe(
        tap((result) => {
          console.log(result);
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  detachRoleFromUser(data): Observable<any> {
    return this.http.delete(this.apiUrl.getApiUrl(this.url) + `/${data.userId}/role/${data.roleId}`).pipe(
      tap((result) => {
        console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  saveRoleUser(userId, roles): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + `/${userId}/roles`, roles).pipe(
      tap((result) => {
        console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getCustomersOfRetailer(id, param: object = { page: 1, per_page: 100 }, sort: any = null, filter: any = null, search: any = null): Observable<any> {
    let query = [];
    let sorts = [];
    // tslint:disable-next-line:forin
    for (const prop in param) {
      query.push(prop + '=' + param[prop]);
    }
    if (sort) {
      for (const prop in sort) {
        if (sort[prop] !== null) {
          sorts.push(sort[prop] + prop);
        }
      }
    }
    AppInjector.get(PreloaderService).show();
    return this.http
      .get(
        this.apiUrl.getApiUrl(this.url) +
          '/' +
          id +
          '/customers' +
          '?' +
          _.join(query, '&') +
          (sort ? '&sort=' + _.join(sorts, ',') : '') +
          (filter ? '&constraints=' + JSON.stringify(filter) : '') +
          (search ? '&search=' + search : '')
      )
      .pipe(
        tap((result) => {
          AppInjector.get(PreloaderService).hide();
        }),
        map((result) =>
          _.assign(
            {},
            {
              items: (result as any).data.map((item) => new User(item)),
              total: (result as any).meta.pagination.total,
              pagination: new LengthAwarePaginator((result as any).meta.pagination)
            }
          )
        ),
        catchError((error) => {
          AppInjector.get(PreloaderService).hide();
          throw error;
        })
      );
  }

  getBankAccountByToken(token, data): Observable<any> {
    const meUrl = '/api/v1/users/payment-account';
    return this.http.post(this.apiUrl.getApiUrl(meUrl) + '/' + token, data).pipe(
      map((result) => new BankAccount((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }

  savePaymentAccountByToken(token, data): Observable<any> {
    const meUrl = `/api/v1/users/${token}/save-payment-account`;
    return this.http.post(this.apiUrl.getApiUrl(meUrl), data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      map((result) => new BankAccount((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }
  addLocationToLogisticPartner(id, data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/' + id + '/add-location-to-logistic-partner-location'), data).pipe(
      tap((result) => {
        // console.log(result);
      }),
      map((result) => new BankAccount((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }
  getUserOnLogisticPartner(id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url + '/' + id + '/get-logistic-partner')).pipe(
      tap((result) => {
        // console.log(result);
      }),
      map((result) => result),
      catchError((error) => {
        throw error;
      })
    );
  }
  getAllLocationsById(id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url + '/' + id + '/get-all-locations-by-id')).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  removeLocationToLogisticPartner(id): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/' + id + '/remove-location-to-logistic-partner-location')).pipe(
      tap((result) => {
        // console.log(result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  saveCustomerHistoryPurchaseToken(retailerEmail, data): Observable<any> {
    const meUrl = `/api/v1/users/retailer/${retailerEmail}/save-customer-purchase`;
    AppInjector.get(PreloaderService).show();
    return this.http.post(this.apiUrl.getApiUrl(meUrl), data).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }

  confirmReceiveEmail(data, token): Observable<any> {
    const url = `/api/v1/users/accept-receive-emails`;
    const handler = AppInjector.get(HttpBackend);
    const http = new HttpClient(handler);
    AppInjector.get(PreloaderService).show();
    return http.post(this.apiUrl.getApiUrl(url), data, { headers: { Authorization: `Bearer ${token}` } }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => new User((result as any).data)),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: { ...params, per_page: '10000' } });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => _.map((result as any).data, (item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
