import Model from './Model';
import User from './User';
import Status from './Status';
import Currency from './Currency';

export enum ProductManagementOperation {
  PUBLISH = 'PUBLISH',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export enum ProductManagementOperationStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

export const PRODUCT_MANAGEMENT_PUBLISH_STATUS = [
  {
    label : 'Publish Pending',
    value : `${ProductManagementOperation.PUBLISH}_${ProductManagementOperationStatus.PENDING}`
  },
  {
    label : 'Publish Fail',
    value : `${ProductManagementOperation.PUBLISH}_${ProductManagementOperationStatus.FAIL}`
  },
  {
    label : 'Delete Pending',
    value : `${ProductManagementOperation.DELETE}_${ProductManagementOperationStatus.PENDING}`
  },
  {
    label : 'Delete Fail',
    value : `${ProductManagementOperation.DELETE}_${ProductManagementOperationStatus.FAIL}`
  },
  {
    label : 'Update Pending',
    value : `${ProductManagementOperation.UPDATE}_${ProductManagementOperationStatus.PENDING}`
  },
  {
    label : 'Update Fail',
    value : `${ProductManagementOperation.UPDATE}_${ProductManagementOperationStatus.FAIL}`
  }
]


class ProductManagement extends Model {
  constructor(options) {
    super();
    (this as any).user = (d) => {
      return new User(d.data);
    };
    (this as any).status = (d) => {
      return new Status(d.data);
    };
    (this as any).partner_price_currency = (d) => {
      return new Currency(d.data);
    };
    this.bind(options);
  }
}

export default ProductManagement;
