import { takeLatest, put, select } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import {
  DELETE_NOTIFICATION_INTEGRATION_REQUEST,
  CREATE_NOTIFICATION_INTEGRATION_REQUEST,
  ADMIN_INTEGRATION_GET_RETAILER_REQUEST,
  ADMIN_INTEGRATION_GET_RETAILER_SUCCESS,
  UPDATE_NOTIFICATION_INTEGRATION_REQUEST,
  CREATE_RETAILER_DEFAULT_INTEGRATION_SUCCESS,
  CREATE_RETAILER_DEFAULT_INTEGRATION_REQUEST,
  INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  INDIVIDUAL_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION,
  INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE
} from './marketplace-management.actions';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { Router } from '@angular/router';
import { defaultConfigRouter } from '../integration.const';
import { ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST, ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS } from '../integration.actions';

function* watchAdminIntegrationGetRetailerRequest() {
  yield takeLatest(ADMIN_INTEGRATION_GET_RETAILER_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.admin.user.getItemById(action.data).toPromise();
      let notification_actions_available = yield api.admin.notificationAction.list().toPromise();
      yield put({ type: ADMIN_INTEGRATION_GET_RETAILER_SUCCESS, retailerId: action.data, data: result, available_actions: notification_actions_available });
      if (action.com && action.com === 'EDIT_ACTION_COM') {
        yield put({ type: 'GET_DETAIL_MARKETPLACE_EDIT', data: action.marketplaceId });
        yield put({ type: ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST, component: 'INDIVIDUAL_CONFIG' });
      }
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateNotificationIntegrationRequest() {
  yield takeLatest(CREATE_NOTIFICATION_INTEGRATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.admin.integrationNotification.createIntegrationNotification(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Integration created!', 2000);
      yield put({ type: INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST, data: { retailerId: action.retailerId, marketplaceId: action.marketplaceId } });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDeleteNotificationIntegrationRequest() {
  yield takeLatest(DELETE_NOTIFICATION_INTEGRATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.admin.integrationNotification.deleteIntegrationNotification(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Integration deleted!', 2000);
      yield put({ type: INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST, data: { retailerId: action.retailerId, marketplaceId: action.marketplaceId } });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchUpdateNotificationIntegrationRequest() {
  yield takeLatest(UPDATE_NOTIFICATION_INTEGRATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      let result = yield api.admin.integrationNotification.updateIntegrationNotification(action.data).toPromise();
      _.map(yield select((state) => (state as any).Admin.Integration.MarketplaceManagement.options), (opt) => {
        if (opt.id === action.data.notification_action_id) {
          opt.isEdit = !opt.isEdit;
          opt.template_name = action.data.template_name;
        }
        return opt;
      });
      AppInjector.get(NotificationService).show('success', 'Integration updated!', 2000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateRetailerDefaultIntegration() {
  yield takeLatest(CREATE_RETAILER_DEFAULT_INTEGRATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);
    try {
      const result = yield api.admin.integration.createDefault(action.data).toPromise();
      yield put({ type: CREATE_RETAILER_DEFAULT_INTEGRATION_SUCCESS, data: result });
      router.navigate(defaultConfigRouter(action.data));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllRetailerEmailConfigRequest() {
  yield takeLatest(INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const currentState = yield select((state: any) => state.Admin.Integration.MarketplaceManagement);
    try {
      const data = {
        retailer_id: currentState.routingState.retailerId,
        marketplace_id: currentState.routingState.marketplaceId,
        language_id: currentState.activeLanguage.id
      };
      const result = yield api.admin.communication.getRetailerMarketplaceEmailConfigByLanguage(data).toPromise();
      yield put({ type: INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS, data: result });
      yield put({ type: INDIVIDUAL_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchGetAllLanguageSuccess() {
  yield takeLatest(ADMIN_INTEGRATION_GET_ALL_LANGUAGE_SUCCESS, function* (action: any) {
    if (action.component === 'INDIVIDUAL_CONFIG') {
      const initActiveLanguage = action.data.find((item) => item.short_name === 'en');
      yield put({ type: INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE, data: initActiveLanguage });
    }
  });
}

function* watchSetActiveLanguage() {
  yield takeLatest(INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.admin.notificationAction.list().toPromise();
      yield put({ type: INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST, data: action.data });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchUpdateNotificationIntegrationRequest,
  watchDeleteNotificationIntegrationRequest,
  watchAdminIntegrationGetRetailerRequest,
  watchCreateNotificationIntegrationRequest,
  watchCreateRetailerDefaultIntegration,
  watchGetAllRetailerEmailConfigRequest,
  watchGetAllLanguageSuccess,
  watchSetActiveLanguage
];
