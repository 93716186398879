import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery, all, call, select, takeLatest } from 'redux-saga/effects';
import {
  RENDER_BULK_PUSH_FORM_REQUESTED,
  UPDATE_BULK_PUSH_INPUT_OPTIONS,
  BULK_PUSH_PRODUCT_REQUESTED,
  DELIST_PRODUCT_REQUESTED,
  DELIST_PRODUCT_SUCCEEDED,
  FETCH_BULK_OPERATION_DATA_REQUESTED,
  FETCH_BULK_OPERATION_DATA_SUCCEEDED,
  BULK_ENABLE_PRODUCT_REQUESTED
} from './bulk-push.actions';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { listRouter } from '../product.const';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import Cookies from 'js-cookie';
import { RETRY_FAILED_ASYNC_PROCESS_REQUESTED } from '../async-process/asnyc-process.action';

function* fetchAllDataBulkPush(action) {
  let result = yield AppInjector.get(ApiService).admin.user.get({ roles: 'marketplace', includes: 'marketplace', per_page: 10000 }).toPromise();
  result = result.items;
  const optionsData = {
    marketplaces: _.map(result, (item, key) => _.assign(item, { value: item.id, label: item.marketplace.company }))
  };
  yield put({ type: UPDATE_BULK_PUSH_INPUT_OPTIONS, data: optionsData });
}

function* watchBulkPushFormRequested() {
  yield takeEvery(RENDER_BULK_PUSH_FORM_REQUESTED, fetchAllDataBulkPush);
}

function* callPublishApi(productId, marketplaces) {
  try {
    let params = { marketplaces: marketplaces };
    let result = yield AppInjector.get(ApiService).admin.product.publish(productId, params).toPromise();
    return result;
  } catch (e) {
    throw e;
  }
}

function* bulkPush(action) {
  AppInjector.get(PreloaderService).show();
  try {
    let result = yield AppInjector.get(ApiService).admin.product.bulkPush(action.data).toPromise();
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(NotificationService).show('success', `Bulk operation submitted`, 3000);
    AppInjector.get(Router).navigate(['admin', 'product', 'bulk-push']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchBulkPushProductTagsRequested() {
  yield takeEvery(BULK_PUSH_PRODUCT_REQUESTED, bulkPush);
}

function* delistProductsFromMarketplaces(action) {
  AppInjector.get(PreloaderService).show();
  try {
    let result = yield AppInjector.get(ApiService).admin.product.delist(action.data).toPromise();
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(NotificationService).show('success', `Bulk operation submitted`, 3000);
    AppInjector.get(Router).navigate(['admin', 'product', 'bulk-push']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDelistRequested() {
  yield takeEvery(DELIST_PRODUCT_REQUESTED, delistProductsFromMarketplaces);
}

function* watchFetchBulkOperationGroupRequested() {
  yield takeEvery(FETCH_BULK_OPERATION_DATA_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).bulkOperation.get(action.data).toPromise();
      console.log(result);
      yield put({ type: FETCH_BULK_OPERATION_DATA_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchBulkRequested() {
  yield takeLatest(BULK_ENABLE_PRODUCT_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.product.bulkAction(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', `Bulk operation submitted`, 3000);
      AppInjector.get(Router).navigate(['admin', 'product', 'bulk-push']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchBulkRequested, watchBulkPushFormRequested, watchBulkPushProductTagsRequested, watchDelistRequested, watchFetchBulkOperationGroupRequested];
