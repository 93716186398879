import { round } from 'lodash';
import Model from './Model';
import OrderProduct from './OrderProduct';
import Shipment from './Shipment';
import Currency from './Currency';

export const PAYMENT_METHOD_NAME_OPTIONS = [
  { label: 'COD', value: 'COD' },
  { label: 'INTERNATIONAL_TRANSFER', value: 'INTERNATIONAL_TRANSFER' },
  { label: 'PAYPAL', value: 'PAYPAL' },
  { label: 'STRIPE', value: 'STRIPE' },
  { label: 'TRANSFER', value: 'TRANSFER' },
  { label: 'VOUCHER', value: 'VOUCHER' }
];

export const PAYMENT_SHIPPING_TO_SEND = 'shipping_to_send';
class Payment extends Model {
  constructor(options) {
    super();
    (this as any).order_product = (d) => new OrderProduct(d.data);
    (this as any).shipment = (d) => new Shipment(d.data);
    (this as any).shipping_fee_to_supplier_currency = (d) => new Currency(d.data);
    this.bind(options);
  }

  getShippingFee() {
    const item = this as any;
    let shippingFee = item.shipping_fee_paid_to_supplier || 0;
    let shippingFeeCurrencyCode = '';
    if (item.shipping_fee_to_supplier_currency) {
      shippingFeeCurrencyCode = item.shipping_fee_to_supplier_currency.symbol;
    }
    return `${round(shippingFee, 2)} ${shippingFeeCurrencyCode}`;
  }


  getAmount() {
    if ((this as any).type === PAYMENT_SHIPPING_TO_SEND) {
      return (this as any).item.total;
    }
    if ((this as any).product && (this as any).product.data) {
      return (this as any).product.data.price.price || (this as any).total;
    }
    return (this as any).total;
  }

}

export default Payment;
