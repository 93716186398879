import { ItemsCost } from './items-cost/items-cost.reducer';
import { Location } from './location/location.reducer';
import { OrderItem } from './order-item/order-item.reducer';
import { Fulfilment } from './fulfilment/fulfilment.reducer';
import { Order } from './order/order.reducer';
import { Notification } from './notification/notification.reducer';
import { collection } from './collection/collection.reducer';
import { Attributes } from './attributes/attributes.reducer';
import { AdminMarketplace } from './marketplace/marketplace.reducer';
import { combineReducers } from 'redux';
import { Product } from './product/product.reducer';
import { Category } from './category/category.reducer';
import { Currency } from './currency/currency.reducer';
import { Acl } from './acl/acl.reducer';
import { Users } from './user/user.reducer';
import { Provider } from './provider/provider.reducer';
import { AdminRetailer } from './retailer/retailer.reducer';
import { Attribute } from './attribute/attribute.reducer';
import { Status } from './status/status.reducer';
import { Tag } from './tag/tag.reducer';
import { AdminCustomer } from './customer/customer.reducer';
import { AdminEmail } from './email/email.reducer';
import { Shipment } from './shipment/shipment.reducer';
import { Integration } from './integration/integration.reducer';
import { Configuration } from './configuration/configuration.reducer';
import { AttributesRetailer } from './attributes-retailer/attributes-retailer.reducer';
import { Language } from './language/language.reducer';
import { Negotiation } from './negotiation/negotiation.reducer';
import { Logistic } from './logistic/logistic.reducer';

export const Admin = combineReducers({
  ItemsCost,
  Location,
  OrderItem,
  Fulfilment,
  Notification,
  Collection: collection,
  Attributes,
  AttributesRetailer,
  AdminMarketplace,
  Product,
  Category,
  Currency,
  Acl,
  Users,
  Provider,
  AdminRetailer,
  Attribute,
  Status,
  Tag,
  AdminCustomer,
  AdminEmail,
  Shipment,
  Integration,
  Configuration,
  Order,
  Language,
  Negotiation,
  Logistic
});
