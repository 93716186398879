import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { edit } from './edit/edit.reducer';
const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export const ItemsCost = combineReducers({
  edit,
  all
});
