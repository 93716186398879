import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Store } from '../../../../store/store.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pick-picture-modal',
  templateUrl: './pick-picture-modal.component.html',
  styleUrls: ['./pick-picture-modal.component.scss']
})
export class PickPictureModelComponent implements OnInit, OnDestroy {
  @Input() public images;
  store;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private activeModal: NgbActiveModal, store: Store) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.images = this.images.map((i) => ({ ...i, ...{ selected: false } }));
  }

  ngOnDestroy() {}

  cancel = () => {
    this.activeModal.dismiss();
  };

  submit = () => {
    this.activeModal.close(this.images);
  };
}
