import * as _ from 'lodash';
import { RETAILER_PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED, RETAILER_IMPORT_PRODUCT_FROM_CSV_SUCCESSED } from './import.actions';

export const Import = (state: any = { fetched: false, imported: false }, action) => {
  switch (action.type) {
    case RETAILER_PREVIEW_PRODUCTS_FROM_CSV_SUCCESSED:
    let numSelected = 0;
    _.forEach(action.data, function(value) {
      if (value.selected) {
        numSelected += 1;
      }
    });
      return _.assign({}, state, { fetched: true, items: action.data, numSelected: numSelected });
    case RETAILER_IMPORT_PRODUCT_FROM_CSV_SUCCESSED:
      return _.assign({}, state, { imported: true, items: [], fetched: false });
    default:
      return state;
  }
};
