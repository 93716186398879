import {
  ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_SUCCESSED,
  FETCH_COLOR_DICTIONARY_SUCCESSED,
  DELETE_COLOR_ATTRIBUTE_SUCCESSED,
  EDIT_COLOR_DICTIONARY_SUCCEEDED,
  ADMIN_ATTRIBUTES_COLOR_FILTER_SELECTED,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_COLOR_SYNCHRONISE_ATTRIBUTES_REQUEST, TOGGLE_COLOR_DICTIONARY_VALIDATION_SUCCEEDED
} from './color.action';
import * as _ from 'lodash';
import { ATTRIBUTE_COMPONENT, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';

export const Color = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    },
    marketplace_colors: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case ADMIN_ATTRIBUTES_FETCH_COLOR_ATTRIBUTE_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_form = false;
        return item;
      });
      return { ...state, ...{ fetched: true, items: action.data, pagination: action.pagination } };

    case ADMIN_ATTRIBUTES_COLOR_CREATE_COLOR_SUCCESS:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            state.items.push(action.data);
            return _.assign({}, state, { create: { created: true, data: {} } });
        }
      }
      return state;

    case ADMIN_ATTRIBUTES_COLOR_EDIT_COLOR_SUCCESS:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            // tslint:disable-next-line:no-shadowed-variable
            const item = _.find(state.items, { id: action.data.id });
            if (!_.isNil(item)) {
              item.is_show_edit_form = false;
              item.name = action.data.name;
            }
            return { ...state, ...{ items: state.items } };
        }
      }
      return state;

    case DELETE_COLOR_ATTRIBUTE_SUCCESSED:
      return {
        ...state,
        ...{
          items: _.filter(state.items, i => i.id !== action.data),
          dictionary: { ...state.dictionary, ...{ items: _.filter(state.dictionary.items, i => i.attribute_id !== action.data) } }
        }
      };

    case FETCH_COLOR_DICTIONARY_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_dictionary_form = false;
        return item;
      });
      return _.assign({}, state, { dictionary: { fetched: true, items: action.data } });

    case EDIT_COLOR_DICTIONARY_SUCCEEDED:
      const item = _.find(state.dictionary.items, { id: action.data.id });
      if (!_.isNil(item)) {
        item.is_show_edit_dictionary_form = false;
        item.value = action.data.value;
      }
      return _.assign({}, state, { dictionary: { items: state.dictionary.items } });

    case ADMIN_ATTRIBUTES_COLOR_FILTER_SELECTED:
      return _.assign({}, state, {
        items: _.map(state.items, color => {
          color.selectedDictionary = color.dictionaries ? color.dictionaries
            .find(d => d.marketplace_id === action.data.marketplace.selected.id) : null;
          return color;
        })
      });
    case TOGGLE_COLOR_DICTIONARY_VALIDATION_SUCCEEDED:
      return _.assign({}, state, {
        items: _.map(state.items, cat => {
          if (cat.selectedDictionary && cat.selectedDictionary.id === action.data.id) {
            return {
              ...cat,
              selectedDictionary: action.data
            };
          } else {
            return {
              ...cat
            }
          }
        })
      });

    case ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_COLORS_SUCCESS:
      return _.assign({}, state, {
        marketplace_colors: {
          fetched: true,
          items: action.data
        },
        items: _.map(state.items, color => {
          let mapped;
          if (color.selectedDictionary) {
            mapped = action.data.find(d => d.id === color.selectedDictionary.source_id && d.name === color.selectedDictionary.value);
          }
          color.mappedAttribute = mapped ? mapped : null;
          return color;
        })
      });

    case ADMIN_ATTRIBUTES_COLOR_UPDATE_DICTIONARY_SUCCESS:
      return _.assign({}, state, {
        items: _.map(state.items, color => {
          if (color.id === action.data.attribute_id) {
            color.is_show_edit_dictionary_form = false;
            color.dictionaries = [...color.dictionaries, action.data];
            color.selectedDictionary = color.dictionaries.find(s => s.id === action.data.id);
          }
          return color;
        })
      });

    case ADMIN_ATTRIBUTES_SELECT_MARKETPLACE:
      if (action.direct) {
        return _.assign({}, state, { marketplace_colors: _.assign({}, state.marketplace_colors, { fetched: false }) });
      } else {
        return state;
      }

    case ADMIN_ATTRIBUTES_COLOR_SYNCHRONISE_ATTRIBUTES_REQUEST:
      return _.assign({}, state, { marketplace_colors: _.assign({}, state.marketplace_colors, { fetched: false }) });

    default:
      return state;
  }
};
