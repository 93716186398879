import Model from './Model';
import { Address } from './Address';
import Country from './Country';

export const WAANT_FR_LOCATION = 'waant-fr';
export const STYLETRIBUTE_SINGAPORE_LOCATION = 'st-sg';
export const ACS_LOCATION = 'ACS';
class Location extends Model {
  constructor(options) {
    super();
    (this as any).address = (d) => new Address(d.data);
    (this as any).country = (d) => new Country(d.data);
    this.bind(options);
  }
}

export default Location;
