import * as _ from 'lodash';
import { FETCH_DASHBOARD_REQUESTED, FETCH_DASHBOARD_SUCCEEDED, GET_ANALYTIC_DATA_SUCCESS } from './analytic.actions';

export const Analytic = (
  state = {
    fetched: false,
    data: {},
    quicksight: null
  },
  action
) => {
  switch (action.type) {
    case GET_ANALYTIC_DATA_SUCCESS:
      return _.assign({}, state, { fetched: true, data: action.data });
    case FETCH_DASHBOARD_REQUESTED:
      return _.assign({}, state, { quicksight: null });
    case FETCH_DASHBOARD_SUCCEEDED:
      return _.assign({}, state, { quicksight: action.data});
    default:
      return state;
  }
};
