import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import * as _ from 'lodash';
import { detailTemplate } from './detailTemplate/detail.reducer';
import { detailEmail } from './detail-email/detail-email.reducer';


export const AdminEmail = combineReducers({
  list,
  detailTemplate,
  detailEmail
});
