import * as _ from 'lodash';
import { ADMIN_FETCH_WEBHOOK_SUCCEEDED } from './webhook.actions';

export const Webhook = (
  state = {
    fetched: false,
    loading: false,
    items: [],
    pagination: {},
    deleted: false
  },
  action
) => {
  switch (action.type) {
    case ADMIN_FETCH_WEBHOOK_SUCCEEDED:
      return _.assign({}, state, {
        fetched: true,
        items: action.data
      });
    default:
      return state;
  }
};
