import { fork, takeEvery, put } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import createSaga from './create/create.saga';
import editSaga from './edit/edit.saga';
import detailSaga from './detail/detail.saga';
import updateSaga from './update/update.saga';
import transactionHistorySaga from './transaction-history/transaction-history.saga';
import { FETCH_ALL_CUSTOMER_GROUP_REQUESTED, FETCH_ALL_CUSTOMER_GROUP_SUCCEEDED } from './customer.actions';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';

function* fetchAllCustomerGroup(action) {
  try {
    AppInjector.get(PreloaderService).show();
    const results = yield AppInjector.get(ApiService)
      .customerGroup.get()
      .toPromise();
    yield put({ type: FETCH_ALL_CUSTOMER_GROUP_SUCCEEDED, data: results });
    AppInjector.get(PreloaderService).hide();
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchAllCustomerGroupRequest() {
  yield takeEvery(FETCH_ALL_CUSTOMER_GROUP_REQUESTED, fetchAllCustomerGroup);
}

export default [...listSaga, ...createSaga, ...editSaga, ...detailSaga, ...updateSaga, ...transactionHistorySaga, watchFetchAllCustomerGroupRequest];
