export const ADMIN_INTEGRATION_GET_RETAILER_REQUEST = 'ADMIN_INTEGRATION_GET_RETAILER_REQUEST';
export const ADMIN_INTEGRATION_GET_RETAILER_SUCCESS = 'ADMIN_INTEGRATION_GET_RETAILER_SUCCESS';

export const CREATE_NOTIFICATION_INTEGRATION_REQUEST = 'CREATE_NOTIFICATION_INTEGRATION_REQUEST';
export const DELETE_NOTIFICATION_INTEGRATION_REQUEST = 'DELETE_NOTIFICATION_INTEGRATION_REQUEST';
export const UPDATE_NOTIFICATION_INTEGRATION_REQUEST = 'UPDATE_NOTIFICATION_INTEGRATION_REQUEST';

export const CREATE_RETAILER_DEFAULT_INTEGRATION_REQUEST = 'CREATE_RETAILER_DEFAULT_INTEGRATION_REQUEST';
export const CREATE_RETAILER_DEFAULT_INTEGRATION_SUCCESS = 'CREATE_RETAILER_DEFAULT_INTEGRATION_SUCCESS';

export const INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST = 'INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_REQUEST';
export const INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS = 'INDIVIDUAL_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS';
export const INDIVIDUAL_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION = 'INDIVIDUAL_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION';

export const INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE = 'INDIVIDUAL_CONFIG_SET_ACTIVE_LANGUAGE';
export const INDIVIDUAL_CONFIG_SAVE_RETAILER_AND_MARKETPLACE_IDS = 'INDIVIDUAL_CONFIG_SAVE_RETAILER_AND_MARKETPLACE_IDS';
