import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_REQUESTED } from './consignment-agreement.actions';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-consignment-agreement',
  templateUrl: './consignment-agreement.component.html',
  styleUrls: ['./consignment-agreement.component.scss']
})
export class ConsignmentAgreementComponent extends BaseComponent implements OnInit {
  public reducer: String = 'ProxyPage.consignmentAgreement';
  public retailerEmail;
  public customerEmail;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.retailerEmail = this.activatedRoute.snapshot.paramMap.get('retailer_email');
        this.activatedRoute.queryParams.subscribe((params) => {
          this.customerEmail = params['customer_email'];
        });
        this.store.dispatch({
          type: SAVE_CUSTOMER_HISTORY_PURCHASE_BY_RETAILER_REQUESTED,
          data: {
            retailerEmail: this.retailerEmail,
            customerEmail: this.customerEmail,
            type: 'save'
          }
        });
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  mapStateToProps(state) {
    return {
      payload: state.ProxyPage.consignmentAgreement
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch
    };
  }
}
