import * as _ from 'lodash';
import {
  FETCH_PRODUCTS_SUCCEEDED,
  SELECT_ALL_PRODUCT,
  FETCH_PRODUCT_IMAGE_SUCCESSED,
  FETCH_CUSTOMER_INFORMATION_SUCCEEDED,
  FETCH_PRODUCT_MARKETPLACE_SUCCESSED,
  FETCH_ALL_MARKETPLACE_SUCCESSED,
  EXPORT_PRODUCT_SUCCEEDED,
  EXPORT_PRODUCT_REQUESTED,
  EXPORT_PRODUCT_FAILED,
  FETCH_ALL_CONDITION_SUCCESSED,
  FETCH_ALL_DESIGNER_SUCCESSED,
  FETCH_ALL_CATEGORY_SUCCESSED,
  GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED,
  UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_REQUESTED,
  UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED,
  FETCH_ALL_STATUS_SUCCESSED,
  ADD_OR_REMOVE_SELECTED_PRODUCT,
  REMOVE_ALL_SELECTED_PRODUCT,
  ADD_ALL_PRODUCTS_IN_PAGE,
  REMOVE_ALL_PRODUCTS_IN_PAGE
} from './list.actions';
import { GET_PRODUCT_DETAIL_SUCCEEDED } from '../detail/detail.actions';
import { AppInjector } from '../../../../app-injector';
import { ActivatedRoute } from '@angular/router';
import { isArray } from 'util';

const Product = (state: any = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_IMAGE_SUCCESSED:
      return _.assign(state, { images: _.filter(action.data, (item) => item.imageable_id === state.getId()) });
    case FETCH_PRODUCT_MARKETPLACE_SUCCESSED:
      const product = _.find(action.data, (item) => item.id === state.id);
      if (!_.isUndefined(product)) {
        return _.assign(state, { marketplaces: product.marketplaces });
      } else {
        return state;
      }
    case FETCH_ALL_MARKETPLACE_SUCCESSED: {
      return _.assign(state, {
        availableMarketplaces: [
          ..._.differenceBy(_.clone(action.data), state.marketplaces, 'id'),
          ..._.clone(state.marketplaces).map((s) => {
            s.selected = true;
            return s;
          })
        ]
      });
    }
    default:
      return state;
  }
};

export const list = (state = {
  fetched: false, items: [], items_status: [], integrated_marketplaces: [], exporting: false, inputs_designer: [], selected_products: {},
  selected_products_count: 0
}, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCEEDED:
      /*const productSelected = state.selected_products;
      const data = _.map(action.data, (item) => {
        if (productSelected[action.data.id]) {
          _.assign(item, { selected: true });
        } else {
          _.assign(item, { selected: false });
        }
        return item;
      });*/

      return _.assign({}, state, {
        fetched: true,
        items: action.data,
        pagination: action.pagination,
        items_status: _.map(action.data, (item) => {
          return { id: item.id, status_id: item.product_items.length ? item.product_items[0].status_id : 0, product_item_id: item.product_items.length ? item.product_items[0].id : 0 };
        })
      });

    case ADD_OR_REMOVE_SELECTED_PRODUCT:
      const product = action.data;
      const total  = Object.keys(state.selected_products).length;
      if (!state.selected_products[product.id]) {
        console.log('added');
        return _.assign({}, state, {
          selected_products: {
            ...state.selected_products,
            [product.id]: product
          },
          selected_products_count: total + 1
        });
      } else {
        console.log('removed');
        const current_products = state.selected_products;
        return _.assign({}, state, {
          selected_products: {
            ...(_.omit(current_products, product.id))
          },
          selected_products_count: total - 1
        });
      }

    case ADD_ALL_PRODUCTS_IN_PAGE:
      console.log('multi-added');
      const products_to_be_added = action.data;
      const productObject = products_to_be_added.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      const finalProduct = {
        ...state.selected_products,
        ...productObject
      };
      return _.assign({}, state, {
        selected_products: {
          ...finalProduct
        },
        selected_products_count: Object.keys(finalProduct).length
      });

    case REMOVE_ALL_PRODUCTS_IN_PAGE:
      console.log('multi-removed');
      const products_to_be_removed = action.data;
      const current_selected_products = state.selected_products;
      const products_after_removed = _.omit(current_selected_products, products_to_be_removed.map(x => x.id));
      return _.assign({}, state, {
        selected_products: {
          ...products_after_removed
        },
        selected_products_count: Object.keys(products_after_removed).length
      });

    case EXPORT_PRODUCT_REQUESTED:
      return _.assign({}, state, {
        exporting: true
      });

    case EXPORT_PRODUCT_SUCCEEDED:
      return _.assign({}, state, {
        exporting: false
      });

    case EXPORT_PRODUCT_FAILED:
      return _.assign({}, state, {
        exporting: false
      });

    case SELECT_ALL_PRODUCT:
      return _.assign({}, state, { fetched: true, items: _.map(state.items, (item) => _.assign(item, { selected: true })), total: action.total });

    case FETCH_PRODUCT_IMAGE_SUCCESSED:
    case FETCH_PRODUCT_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, { items: _.map(state.items, (item) => Product(item, action)) });

    case FETCH_ALL_MARKETPLACE_SUCCESSED:
      return _.assign({}, state, {
        items: _.map(state.items, (item) => Product(item, action)),
        integrated_marketplaces: _.filter(action.data, (item) => item.marketplace.is_integrated)
      });
    case FETCH_CUSTOMER_INFORMATION_SUCCEEDED:
      let products = state.items;
      products = _.map(products, (item) => {
        if (!_.isNil(item.seller_id) && item.seller_id !== 0) {
          item.seller = _.find(action.data, { id: item.seller_id });
        }
        return item;
      });
      return _.assign({}, state, { items: products });
    case GET_PRODUCT_DETAIL_SUCCEEDED:
      return _.assign({}, state, {
        items_status: _.map(state.items_status, (item) => {
          if (item.id === action.data.id) {
            item.status_id = action.data.product_items ? action.data.product_items[0].status_id : 0;
          }
          return item;
        })
      });
    case REMOVE_ALL_SELECTED_PRODUCT:
      return _.assign({}, state, {
        selected_products: {},
        selected_products_count: 0
      });
    case FETCH_ALL_CONDITION_SUCCESSED:
      return _.assign({}, state, { conditions: action.data });
    case FETCH_ALL_DESIGNER_SUCCESSED:
      return _.assign({}, state, { designers: action.data });
    case FETCH_ALL_INTEGRATED_MARKETPLACE_LIST_SUCCESSED:
      return _.assign({}, state, { fetched: true, marketplaces: action.data });
    case FETCH_ALL_CATEGORY_SUCCESSED:
      return _.assign({}, state, { fetched: true, categories: action.data });
    case FETCH_ALL_STATUS_SUCCESSED:
      return _.assign({}, state, { fetched: true, statuses: action.data });
    case GET_ALL_RETAILER_IN_PRODUCT_PAGE_SUCCESSED:
      return _.assign({}, state, { fetched: true, retailers: action.data });
    case UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_SUCCESSED:
      return _.assign({}, state, { inputs_designer: action.data });
    default:
      return state;
  }
};
