import { PipesModule } from '../../common/pipes/pipes.module';
import { ListComponent } from './list/list.component';
import { BlockchainRoutingModule } from './blockchain-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../common/directives/directives.module';
import { SharedModule } from '../../template/shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  imports: [
    CommonModule,
    BlockchainRoutingModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    SharedModule,
    QRCodeModule
  ],
  declarations: [
    ListComponent
  ]
})
export class BlockchainModule { }
