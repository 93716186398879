import { ConsignmentAgreementComponent } from './consignment-agreement/consignment-agreement.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: ':retailer_email',
            component: ConsignmentAgreementComponent
        },
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProxyPageRoutingModule { }
