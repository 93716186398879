import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import { takeEvery, put } from 'redux-saga/effects';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { RETAILER_GET_DETAIL_CUSTOMER_REQUESTED, RETAILER_GET_DETAIL_CUSTOMER_SUCCEEDED } from './detail.actions';

function* adminGetDetailCustomer(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.user.getItemById(action.data).toPromise();
    yield put({ type: RETAILER_GET_DETAIL_CUSTOMER_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchAdminGetDetailCustomerRequest() {
  yield takeEvery(RETAILER_GET_DETAIL_CUSTOMER_REQUESTED, adminGetDetailCustomer);
}

export default [watchAdminGetDetailCustomerRequest];
