import { Component, OnInit } from '@angular/core';
import { Store } from '../../store/store.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.scss']
})
export class AttributeComponent implements OnInit {
  constructor(store: Store, private router: Router) {
  }

  ngOnInit() {
  }
}
