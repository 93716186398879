import Model from './Model';

class Courier extends Model {
  constructor(options) {
    super();
    this.bind(options);
  }
}

export default Courier;
