import { GET_LIST_GROUP_DATASET_REQUEST, GET_LIST_GROUP_DATASET_SUCCESS, DETACH_DATASET_FROM_GROUP_REQUEST, DETACH_DATASET_FROM_GROUP_SUCCESS } from './list-group.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { put, takeLatest } from 'redux-saga/effects';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { listGroupRouter } from '../dataset.const';
import { Router } from '@angular/router';

function* watchGetListGroupDatasetRequest() {
  yield takeLatest(GET_LIST_GROUP_DATASET_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const datasetId = action.data;
    try {
      const result = yield api.dataset.getGroup(datasetId).toPromise();
      yield put({ type: GET_LIST_GROUP_DATASET_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchDetachDatasetFromGroupRequest() {
  yield takeLatest(DETACH_DATASET_FROM_GROUP_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const notification = AppInjector.get(NotificationService);
    const router = AppInjector.get(Router);
    const { datasetId, payload, groupId } = action.data;
    try {
      const result = yield api.dataset.detachFromGroup(groupId, payload).toPromise();
      yield put({ type: DETACH_DATASET_FROM_GROUP_SUCCESS, data: result });
      notification.show('success', 'Remove from group success', 3000);
      router.navigate(listGroupRouter(datasetId));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetListGroupDatasetRequest, watchDetachDatasetFromGroupRequest];
