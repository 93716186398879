import { put, takeEvery } from 'redux-saga/effects';
import { AppInjector } from '../../../../../app-injector';
import { ApiService } from '../../../../../api/api.service';
import { RENDER_CREATE_ADDRESS_FORM_REQUESTED, CREATE_ADDRESS_REQUESTED, CREATE_ADDRESS_SUCCESSED, FILL_DATA_INPUTS_OPTIONS_REQUESTED } from './create.actions';
import { Router } from '@angular/router';
import { API_CALL_ERROR } from '../../../../../store/action';
import { listUserAddressRoute } from '../address.consts';
import { FETCH_USER_DETAIL_REQUESTED } from '../../edit/edit.actions';
import { Countries } from '../countries';
import * as _ from 'lodash';

function* renderForm(action) {
  const COUNTRIES = Countries;
  const optionsData = {
    country: _.map(COUNTRIES, item => _.assign(item, { value: item.name, label: item.name }))
  };
  yield put({
    type: FILL_DATA_INPUTS_OPTIONS_REQUESTED,
    data: optionsData
  });
  yield put({
    type: FETCH_USER_DETAIL_REQUESTED,
    data: action.data.userId
  });
}

function* watchRenderAddressFormRequest() {
  yield takeEvery(RENDER_CREATE_ADDRESS_FORM_REQUESTED, renderForm);
}

function* createAddress(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.address.create(action.data).toPromise();
    yield put({ type: CREATE_ADDRESS_SUCCESSED, data: result });
    router.navigate(listUserAddressRoute(result.user_id));
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateAddressRequest() {
  yield takeEvery(CREATE_ADDRESS_REQUESTED, createAddress);
}

export default [watchRenderAddressFormRequest, watchCreateAddressRequest];
