import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerRoutingModule } from './customer-routing.module';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { SharedModule } from '../../template/shared/shared.module';
import { DirectivesModule } from '../../common/directives/directives.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularReactiveFormModule } from '@reflaunt/reactive-form';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from '../../common/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    CustomerRoutingModule,
    PipesModule,
    HttpClientModule,
    FormsModule,
    DirectivesModule,
    NgSelectModule,
    SharedModule,
    AngularReactiveFormModule,
    SweetAlert2Module
  ],
  declarations: [TransactionHistoryComponent]
})
export class CustomerModule {}
