import { put, takeLatest } from 'redux-saga/effects';
import { GET_LIST_NORMAL_DATASET_REQUEST, GET_LIST_NORMAL_DATASET_SUCCESS, CREATE_DATASET_GROUP_REQUEST, CREATE_DATASET_GROUP_SUCCESS } from './create-group.actions';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { NORMAL_GROUP_TYPE } from '../../../../models/Dataset';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { listGroupRouter } from '../dataset.const';
import { Router } from '@angular/router';

function* watchGetListNormalDatasetRequest() {
  yield takeLatest(GET_LIST_NORMAL_DATASET_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const datasetId = action.data;
    try {
      const result = yield api.dataset.list({ constraints: { group_type: NORMAL_GROUP_TYPE } }).toPromise();
      const filteredItems = result.filter((item) => item.id !== datasetId);
      yield put({ type: GET_LIST_NORMAL_DATASET_SUCCESS, data: filteredItems });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchCreateDatasetGroupRequest() {
  yield takeLatest(CREATE_DATASET_GROUP_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);
    const notification = AppInjector.get(NotificationService);
    const { datasetId, payload } = action.data;
    try {
      const result = yield api.dataset.createGroup(payload).toPromise();
      yield put({ type: CREATE_DATASET_GROUP_SUCCESS, data: result });
      notification.show('success', 'Successfully create dataset group', 3000);
      router.navigate(listGroupRouter(datasetId));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetListNormalDatasetRequest, watchCreateDatasetGroupRequest];
