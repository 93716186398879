import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { UpdateComponent } from './update/update.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { PaymentAccountComponent } from './payment-account/payment-account.component';
import { AddPaymentAccountComponent } from './add-payment-account/add-payment-account.component';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListComponent
      },
      // {
      //   path: 'edit/:id',
      //   component: EditComponent,
      //   loadChildren: './edit/edit.module#EditModule'
      // },
      {
        path: 'edit/:id',
        component: UpdateComponent,
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: ':id/detail',
        component: DetailComponent
      },
      {
        path: ':id/payment-account',
        component: PaymentAccountComponent
      },
      {
        path: ':id/add-payment-account',
        component: AddPaymentAccountComponent
      },
      {
        path: ':id/transaction-history',
        component: TransactionHistoryComponent
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(
      appRoutes
    )
  ],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
