import { BaseComponent } from '../../../base.component';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  DEFAULT_CONFIG_GET_RETAILER_INFO_REQUEST,
  DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_REQUEST,
  DEFAULT_CONFIG_EDIT_NOTIFICATION_ACTION_ITEM,
  DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_REQUEST,
  DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE
} from './default-config.actions';
import { ngSelectOnCompare } from '../../attributes/attributes.const';
import { ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_REQUEST, ADMIN_INTEGRATION_GET_ALL_LANGUAGE_REQUEST } from '../integration.actions';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../api/api.service';

@Component({
  selector: 'app-default-config',
  templateUrl: './default-config.component.html',
  styleUrls: ['./default-config.component.scss']
})
export class DefaultConfigComponent extends BaseComponent implements OnInit {
  reducer = 'Admin.Integration.DefaultConfig';

  public retailerId;
  public ngSelectOnCompare = ngSelectOnCompare;

  constructor(private activatedRoute: ActivatedRoute, private api: ApiService) {
    super();
    this.retailerId = this.activatedRoute.snapshot.params.retailerId;
    const { dipatchGetRetailerInfo, dispatchGetAllNotificationAction, dispatchGetAllAvailableTemplate } = this as any;
    dipatchGetRetailerInfo(this.retailerId);
    dispatchGetAllNotificationAction(this.retailerId);
    dispatchGetAllAvailableTemplate();
  }

  get availableTemplate() {
    return this.store.getState().Admin.Integration.All.availableTemplate;
  }

  get availableLanguage() {
    return this.store.getState().Admin.Integration.All.language;
  }

  ngOnInit() {
    this.init();
  }

  isCheckedAction(action) {
    return action.checked;
  }

  isDisplayActionList() {
    return this.payload.notifyAction.fetched && this.availableTemplate.fetched;
  }

  onChange(event, actionItem) {
    event.preventDefault();
    const { dispatchCreateRetailerEmailConfig, dispatchDeleteRetailerEmailConfig } = this as any;
    const data = {
      notification_action_id: actionItem.id,
      marketplace_management_id: this.payload.retailer.item.default_integration.id,
      template_name: actionItem.default_template,
      language_id: this.payload.activeLanguage.id
    };
    if (!actionItem.checked) {
      dispatchCreateRetailerEmailConfig(data, this.retailerId);
    } else {
      dispatchDeleteRetailerEmailConfig(data, this.retailerId);
    }
  }

  onEdit(actionItem) {
    const { dispatchEditAction } = this as any;
    dispatchEditAction(actionItem);
  }

  onUpdate(actionItem) {
    const { dispatchUpdateRetailerEmailConfig } = this as any;
    const data = {
      notification_action_id: actionItem.id,
      marketplace_management_id: this.payload.retailer.item.default_integration.id,
      template_name: actionItem.config.template_name,
      subject: actionItem.config.subject,
      language_id: this.payload.activeLanguage.id,
      integration: actionItem.config.integration, 

    };
    dispatchUpdateRetailerEmailConfig(data, this.retailerId);
  }

  isActiveLanguageOption(language) {
    return language.id === this.payload.activeLanguage;
  }

  setActiveLanguage(id) {
    const { dispatchSetActiveLanguage } = this as any;
    const activeLanguage = this.availableLanguage.items.find((item) => item.id === Number(id));
    dispatchSetActiveLanguage(activeLanguage);
  }

  mapStateToProps(state) {
    return {
      payload: state.Admin.Integration.DefaultConfig
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch,
      dipatchGetRetailerInfo: (retailerId) => {
        dispatch({ type: DEFAULT_CONFIG_GET_RETAILER_INFO_REQUEST, data: retailerId });
      },
      dispatchGetAllNotificationAction: (retailerId) => {
        dispatch({ type: DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_REQUEST, data: retailerId });
      },
      dispatchEditAction: (item) => {
        dispatch({ type: DEFAULT_CONFIG_EDIT_NOTIFICATION_ACTION_ITEM, data: item });
      },
      dispatchCreateRetailerEmailConfig: (data, retailerId) => {
        dispatch({ type: DEFAULT_CONFIG_CREATE_RETAILER_EMAIL_CONFIGURATION_REQUEST, data, retailerId });
      },
      dispatchDeleteRetailerEmailConfig: (data, retailerId) => {
        dispatch({ type: DEFAULT_CONFIG_DELETE_RETAILER_EMAIL_CONFIGURATION_REQUEST, data, retailerId });
      },
      dispatchUpdateRetailerEmailConfig: (data, retailerId) => {
        dispatch({ type: DEFAULT_CONFIG_UPDATE_RETAILER_EMAIL_CONFIGURATION_REQUEST, data, retailerId });
      },
      dispatchGetAllAvailableTemplate: () => {
        dispatch({ type: ADMIN_INTEGRATION_GET_ALL_AVAILABLE_TEMPLATE_REQUEST });
      },
      dispatchSetActiveLanguage: (data) => {
        dispatch({ type: DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE, data });
      }
    };
  }

  getReminderInfo = async (notification_action_marketplace_management_id) => {
    let html = ``;
    this.api.admin.communication.getRemindersByNotificationActionMarketplaceManagement(notification_action_marketplace_management_id).subscribe((res) => {
      for (const item of res.data) {
        if (item.notification_action) {
          html += `<p style="text-align: left;"><span style="font-weight: bold;">Reminder Action: </span> ${item.notification_action.name}</p>`;
          if (item.notification_conditions.length > 0) {
            html += `<p style="text-align: left;">* Condtion:</p>`;
            for (const condtion of item.notification_conditions) {
              html += `<p style="text-align: left;">- ${condtion.name} (Condtion Object: ${condtion.notification_condition_object.slug})</p>`;
            }
          }
        }
      }
      Swal.fire({
        title: 'Reminder infromation',
        showCancelButton: true,
        html: html,
        focusConfirm: false
      });
    });
  };
}
