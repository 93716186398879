import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerRoutingModule } from './customer-routing.module';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { CreateComponent } from './create/create.component';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { SharedModule } from '../../../template/shared/shared.module';
import { DetailComponent } from './detail/detail.component';
import { UpdateComponent } from './update/update.component';
import { AngularReactiveFormModule } from '@reflaunt/reactive-form';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { PaymentAccountComponent } from './payment-account/payment-account.component';
import { AddPaymentAccountComponent } from './add-payment-account/add-payment-account.component';

@NgModule({
  imports: [CommonModule, FormsModule, CustomerRoutingModule, DirectivesModule, PipesModule, SweetAlert2Module, SharedModule, ReactiveFormsModule, AngularReactiveFormModule],
  declarations: [ListComponent, EditComponent, CreateComponent, DetailComponent, UpdateComponent, TransactionHistoryComponent, PaymentAccountComponent, AddPaymentAccountComponent]
})
export class CustomerModule {}
