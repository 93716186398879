import { Component, OnChanges, OnInit, ViewChild, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Store } from './../../../../store/store.module';
import { CREATE_PRODUCT_REQUESTED, RENDER_CREATE_PRODUCT_FORM_REQUESTED } from './create.actions';
import { AppInjector } from '../../../../app-injector';
import { InputBase, TextBox, Dropdown, TextArea, UploadFile, ListCheckBox, Radio, ButtonGroup, TypeAheadWithoutApi, GroupTitle, TextBoxMask } from '@reflaunt/reactive-form';
import { Validators } from '@angular/forms';
import { listRouter, getSellingPrice, getSellingPriceVip, stepsCreateCollection } from '../product.const';
import { ConsolidatedProduct } from '../../../../common/util/consolidated-product';
import { ApiService } from '../../../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { listAdminCollection, detailAdminCollection } from '../../collection/collection.const';
import * as $ from 'jquery';
import { assign, identity } from 'lodash';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, AfterViewChecked, AfterContentInit {
  public store;
  public from_collection = false;
  public collection_id;
  public collection;
  public listRouter = listRouter();
  public listAdminCollection = listAdminCollection();
  public mandatoryField = ['main_category', 'sub_category', 'item_type', 'child_category', 'condition_id', 'warehouse'];
  public validateExceptionField = ['name'];
  public rejectInvalidateException = ['main_category', 'sub_category', 'item_type', 'child_category', 'retailer_email', 'customer_email', 'warehouse'];
  public titleField = ['item_type_title', 'product_info_title', 'condition_title'];
  public availableItemTypesInput = {
    item_type_title: {
      class: GroupTitle,
      default_data: {
        key: 'item_type_title',
        title: 'ITEM TYPE',
        subtitle: 'What type of item are you listing?',
        classes: ['col-12'],
        group: ['item-type'],
        group_classes: ['col-12', 'item-type-container']
      }
    },
    main_category: {
      class: ButtonGroup,
      default_data: {
        key: 'main_category',
        label: 'Category *',
        classes: ['col-12', 'rf-form-group', 'main-category'],
        group: ['item-type'],
        group_classes: ['col-12', 'item-type-container'],
        order: 1,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        buttonWrapperClasses: ['col-3']
      }
    },
    sub_category: {
      class: ButtonGroup,
      default_data: {
        key: 'sub_category',
        label: 'Sub Category *',
        classes: ['col-12', 'rf-form-group', 'sub-category'],
        group: ['item-type'],
        group_classes: ['col-12', 'item-type-container'],
        order: 2,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        buttonWrapperClasses: ['col-3']
      }
    },
    item_type: {
      class: ButtonGroup,
      default_data: {
        key: 'item_type',
        label: 'Item Type *',
        classes: ['col-12', 'rf-form-group', 'item-type'],
        group: ['item-type'],
        group_classes: ['col-12', 'item-type-container'],
        order: 3,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        buttonWrapperClasses: ['col-3']
      }
    },
    child_category: {
      class: Dropdown,
      default_data: {
        key: 'child_category',
        label: 'Child Category *',
        classes: ['col-12', 'rf-form-group', 'input--select', 'display-none'],
        group: ['item-type'],
        group_classes: ['col-12', 'item-type-container'],
        order: 4
      }
    }
  };
  public availableProductInfoInput = {
    product_info_title: {
      class: GroupTitle,
      default_data: {
        key: 'product_info_title',
        title: 'PRODUCT INFO',
        subtitle: 'Tell us about the details of your item.',
        classes: ['col-12'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container']
      }
    },
    name: {
      class: TextBox,
      default_data: {
        key: 'name',
        label: 'Item Name',
        classes: ['col-6', 'rf-form-group'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 1
      }
    },
    designer_id: {
      class: TypeAheadWithoutApi,
      default_data: {
        key: 'designer_id',
        label: 'Designer *',
        classes: ['col-6', 'rf-form-group', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 2,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }
    },
    color_id: {
      class: TypeAheadWithoutApi,
      default_data: {
        key: 'color_id',
        label: 'Color *',
        classes: ['col-6', 'rf-form-group', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 3
      }
    },
    size_type: {
      class: Dropdown,
      default_data: {
        key: 'size_type',
        label: 'Size Type *',
        classes: ['col-3', 'rf-form-group', 'input--select', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 4
        /*options: [
          { value: 'UK', label: 'UK' },
          { value: 'US', label: 'US' }
        ]*/
      }
    },
    size_id: {
      class: TypeAheadWithoutApi,
      default_data: {
        key: 'size_id',
        label: 'Size *',
        classes: ['col-3', 'rf-form-group', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 5
      }
    },
    material_id: {
      class: TypeAheadWithoutApi,
      default_data: {
        key: 'material_id',
        label: 'Material *',
        classes: ['col-6', 'rf-form-group', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 6
      }
    },
    length_style: {
      class: Dropdown,
      default_data: {
        key: 'length_style',
        label: 'Length Style *',
        classes: ['col-3', 'rf-form-group', 'display-none', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 7
      }
    },
    clothing_size: {
      class: Dropdown,
      default_data: {
        key: 'clothing_size',
        label: 'Clothing Size',
        classes: ['col-3', 'rf-form-group', 'display-none', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 7
      }
    },
    width_style: {
      class: Dropdown,
      default_data: {
        key: 'width_style',
        label: 'Width Style *',
        classes: ['col-3', 'rf-form-group', 'display-none', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 8
      }
    },
    width: {
      class: TextBox,
      default_data: {
        type: 'number',
        min: 1,
        max: 1000000000,
        key: 'width',
        label: 'Width (mm) *',
        classes: ['col-2', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 9
      }
    },
    length: {
      class: TextBox,
      default_data: {
        type: 'number',
        min: 1,
        max: 1000000000,
        key: 'length',
        label: 'Length (mm) *',
        classes: ['col-2', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 10
      }
    },
    height: {
      class: TextBox,
      default_data: {
        type: 'number',
        min: 1,
        max: 1000000000,
        key: 'height',
        label: 'Height (mm) *',
        classes: ['col-2', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 11
      }
    },
    heel_height: {
      class: TextBox,
      default_data: {
        key: 'heel_height',
        label: 'Heel Height (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 12
      }
    },
    platform_height: {
      class: TextBox,
      default_data: {
        key: 'platform_height',
        label: 'Platform height',
        classes: ['col-6', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 13
      }
    },
    heel_height_style: {
      class: Dropdown,
      default_data: {
        key: 'heel_height_style',
        label: 'Heel height style*',
        classes: ['col-3', 'rf-form-group', 'display-none', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 14
      }
    },
    heel_style: {
      class: Dropdown,
      default_data: {
        key: 'heel_style',
        label: 'Heel style *',
        classes: ['col-3', 'rf-form-group', 'display-none', 'input--select'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 15
      }
    },
    strap_length: {
      class: TextBox,
      default_data: {
        key: 'strap_length',
        label: 'Strap Length (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 16
      }
    },
    strap_width: {
      class: TextBox,
      default_data: {
        key: 'strap_width',
        label: 'Strap Width (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 17
      }
    },
    insole_length: {
      class: TextBox,
      default_data: {
        key: 'insole_length',
        label: 'Insole Length (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 18
      }
    },
    insole_width: {
      class: TextBox,
      default_data: {
        key: 'insole_width',
        label: 'Insole Width (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 19
      }
    },
    chest: {
      class: TextBox,
      default_data: {
        key: 'chest',
        label: 'Chest (mm) *',
        type: 'number',
        min: 1,
        max: 1000000000,
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 20
      }
    },
    waist: {
      class: TextBox,
      default_data: {
        key: 'waist',
        label: 'Waist (mm) *',
        type: 'number',
        min: 1,
        max: 1000000000,
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 21
      }
    },
    hips: {
      class: TextBox,
      default_data: {
        key: 'hips',
        label: 'Hips (mm) *',
        type: 'number',
        min: 1,
        max: 1000000000,
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 22
      }
    },
    arm_length: {
      class: TextBox,
      default_data: {
        key: 'arm_length',
        label: 'Arm Length (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 23
      }
    },
    outseam: {
      class: TextBox,
      default_data: {
        key: 'outseam',
        label: 'Outseam (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 24
      }
    },
    inseam: {
      class: TextBox,
      default_data: {
        key: 'inseam',
        label: 'Inseam (mm) *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 25
      }
    },
    wash: {
      class: TextBox,
      default_data: {
        key: 'wash',
        label: 'Wash/Look *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 26
      }
    },
    retailer_email: {
      class: TextBox,
      default_data: {
        key: 'retailer_email',
        label: 'Retailer Email *',
        classes: ['col-6', 'rf-form-group'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 27,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }
    },
    customer_email: {
      class: TextBox,
      default_data: {
        key: 'customer_email',
        label: 'Customer Email *',
        classes: ['col-6', 'rf-form-group'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 28
      }
    },
    location: {
      class: TextBox,
      default_data: {
        key: 'location',
        label: 'Warehouse Item ID',
        classes: ['col-6', 'rf-form-group custom-dropdown'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 29
      }
    },
    warehouse: {
      class: Dropdown,
      default_data: {
        key: 'warehouse',
        label: 'Warehouse location*',
        classes: ['col-6', 'rf-form-group custom-dropdown d-block'],
        group: ['product-info'],
        group_classes: ['col-12', 'product-info-container'],
        order: 30,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }
    }
  };
  public availableConditionInput = {
    condition_title: {
      class: GroupTitle,
      default_data: {
        key: 'condition_title',
        title: 'CONDITION',
        subtitle: 'Tell us about the condition of your item.',
        classes: ['col-12'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container']
      }
    },
    retail_tag: {
      class: Radio,
      default_data: {
        key: 'retail_tag',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ],
        label: 'Does this item still have the retail tags attached?',
        classes: ['col-6', 'rf-form-group', 'condition-group', 'custom-radiofill'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 1
      }
    },
    sign_of_wear: {
      class: Radio,
      default_data: {
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ],
        key: 'sign_of_wear',
        label: 'Does this item still have any signs of wear?',
        classes: ['col-6', 'rf-form-group', 'custom-radiofill'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 2
      }
    },
    condition_id: {
      class: Dropdown,
      default_data: {
        key: 'condition_id',
        label: 'Condition *',
        classes: ['col-6', 'rf-form-group', 'condition-group', 'input--select'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 3,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }
    },
    authenticity_card: {
      class: TextBox,
      default_data: {
        key: 'authenticity_card',
        label: 'Authenticity Card',
        classes: ['col-3', 'rf-form-group', 'condition-group', 'display-none'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 4
      }
    },
    upc: {
      class: TextBox,
      default_data: {
        key: 'upc',
        label: 'Barcode(UPC) *',
        classes: ['col-3', 'rf-form-group', 'condition-group', 'display-none'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 4
      }
    },
    original_price: {
      class: TextBox,
      default_data: {
        key: 'original_price',
        label: 'Price on tag',
        classes: ['col-3', 'rf-form-group', 'condition-group', 'display-none'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 5
      }
    },
    inclusions: {
      class: ListCheckBox,
      default_data: {
        options: [
          { value: 'Dust Bag', label: 'Dust Bag' },
          { value: 'Box', label: 'Box' }
        ],
        key: 'inclusions',
        label: 'What does it include? *',
        classes: ['col-6', 'rf-form-group', 'display-none'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 6
      }
    },
    sign_of_wear_type: {
      class: ListCheckBox,
      default_data: {
        key: 'sign_of_wear_type',
        label: 'Tag any signs of wear? *',
        classes: ['col-12', 'rf-form-group', 'sign-of-wear-box'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 7
      }
    },
    damage_notes: {
      class: TextArea,
      default_data: {
        key: 'damage_notes',
        label: 'Note',
        classes: ['col-12', 'rf-form-group'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 8
      }
    },
    private_note: {
      class: TextArea,
      default_data: {
        key: 'private_note',
        label: 'Private note',
        classes: ['col-12', 'rf-form-group', 'condition-group', 'input--select'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 9
      }
    },
    description: {
      class: TextArea,
      default_data: {
        key: 'description',
        label: 'Description',
        classes: ['col-12', 'rf-form-group', 'condition-group', 'input--select'],
        group: ['condition'],
        group_classes: ['col-12', 'condition-container'],
        order: 9
      }
    }
  };
  public availablePriceInput = {
    price: {
      class: TextBox,
      default_data: {
        key: 'price',
        label: 'Selling Price *',
        classes: ['col-3', 'rf-form-group', 'display-none'],
        group: ['price'],
        group_classes: ['col-12', 'condition-container'],
        order: 1
      }
    }
  };
  public availableImageInput = {
    images: {
      class: UploadFile,
      default_data: {
        key: 'images',
        label: 'Images',
        classes: ['col-12', 'rf-form-group', 'input--upload-file', 'display-none'],
        group: ['image'],
        group_classes: ['col-12', 'image-container'],
        order: 1,
        accept: 'png|jpg|jpeg',
        allowMaxSize: 2,
        apiUpload: '/api/v1/upload ',
        multiple: true,
        resultTransformer: (result) => result.data[0].full_path,
        paramName: 'files'
      }
    }
  };
  public availableInputs = {
    ...this.availableItemTypesInput,
    ...this.availableProductInfoInput,
    ...this.availableConditionInput,
    ...this.availablePriceInput,
    ...this.availableImageInput
  };
  public consolidatedProductJson = (ConsolidatedProduct as any).default;
  public inputs: InputBase<any>[];
  public queryParams = {
    referer: '',
    extra_params: ''
  };
  public mainCategoryInput;
  public subCategoryInput;
  public itemTypeInput;
  public childCategoryInput;
  public sizeInput;
  public submitChildCategory = false;
  public createForm;
  public upcInput;
  public originalPriceInput;
  public sizeTypeInput;
  public signOfWearTypeInput;
  public defaultHide = ['upc', 'original_price'];

  constructor(private router: Router, store: Store, private route: ActivatedRoute, private api: ApiService) {
    this.store = store.getInstance();
    this.route.queryParams.subscribe((params) => {
      if (params) {
        if (params.referer) {
          this.queryParams.referer = params.referer;
        }
        if (params.extra_params) {
          this.queryParams.extra_params = JSON.parse(params.extra_params);
          if (JSON.parse(params.extra_params).collection_id) {
            this.api.admin.collection.show(JSON.parse(params.extra_params).collection_id).subscribe((res) => {
              if (res) {
                this.collection = res;
              }
            });
          }
        }
      }
    });
  }

  ngOnInit() {
    const referer_url = AppInjector.get(ActivatedRoute).snapshot.queryParams.referer;
    let extra_params = AppInjector.get(ActivatedRoute).snapshot.queryParams.extra_params
      ? JSON.parse(AppInjector.get(ActivatedRoute).snapshot.queryParams.extra_params)
      : undefined;
    if (referer_url && referer_url.includes('admin/collection/detail')) {
      this.from_collection = true;
      const i = setInterval(() => {
        if ($('.pcoded-item>.Product').length > 0 && $('.pcoded-item>.Collection').length > 0) {
          $('.pcoded-item>.Product').removeClass('active');
          $('.pcoded-item>.Collection').addClass('menu-text-color--active active');
          clearInterval(i);
        }
      });
    } else {
      this.from_collection = false;
    }
    if (extra_params && extra_params.collection_id) {
      this.collection_id = extra_params.collection_id;
    }
    if (extra_params && extra_params.retailer_slug === 'Zalora') {
      this.availableProductInfoInput = {
        ...this.availableProductInfoInput,
        location: assign(
          {},
          {
            class: TextBox,
            default_data: {
              key: 'location',
              label: 'Warehouse Item ID*',
              classes: ['col-6', 'rf-form-group custom-dropdown'],
              group: ['product-info'],
              group_classes: ['col-12', 'product-info-container'],
              order: 28,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ]
            }
          }
        )
      };
      this.availableInputs = { ...this.availableInputs, ...this.availableProductInfoInput };
    }

    this.inputs = [
      ...Object.keys(this.availableInputs)
        .map((s) => {
          const input = this.availableInputs[s];
          const value = this.queryParams.extra_params ? this.queryParams.extra_params[s] : '';
          let isForceHidden = false;
          let isForceInvalidated = false;
          let forcedGrouping;
          let onChange;
          if (this.queryParams.referer) {
            if (this.queryParams.referer.indexOf('collection') > -1) {
              if (s === 'images' || s === 'price' || s === 'original_price') {
                isForceHidden = true;
                isForceInvalidated = true;
              }
              if (s === 'original_price') {
                // to allow original price on different group if being referred from collection page
                input.group = ['price'];
                if (this.router.url.indexOf('create') < 0) {
                  input.classes = input.classes.filter((x) => x !== 'col-3').concat(['col-12']);
                }
              }
            }
          }
          switch (s) {
            case 'main_category':
              onChange = this.onMainCategoryChange;
              break;
            case 'sub_category':
              onChange = this.onSubCategoryChange;
              break;
            case 'item_type':
              onChange = this.onItemTypeChange;
              break;
            case 'retail_tag':
              onChange = this.onRetailTagChange;
              break;
            case 'size_type':
              onChange = this.onSizeTypeChange;
              break;
            case 'sign_of_wear':
              onChange = this.onSignOfWearChange;
              break;
            case 'condition_id':
              onChange = this.onConditionChange;
              break;
            default:
              break;
          }
          const data = this.consolidatedProductJson.common_data ? this.consolidatedProductJson.common_data[s] : null;
          if (input) {
            return new input.class({
              ...input.default_data,
              ...(value ? { value, disabled: true } : {}),
              classes: [...input.default_data.classes, ...(isForceHidden ? ['display-none'] : [])],
              ...(onChange ? { onChange } : {}),
              ...(data && data.is_required && !isForceInvalidated
                ? {
                    validators: [
                      {
                        validator: Validators.required,
                        message: 'This field is required'
                      }
                    ]
                  }
                : {})
            });
          }
          return null;
        })
        .filter((s) => !!s)
    ];
    this.store.dispatch({ type: RENDER_CREATE_PRODUCT_FORM_REQUESTED, data: { inputs: this.inputs } });
  }

  ngAfterContentInit() {
    this.mainCategoryInput = this.inputs.find((element) => element.key === 'main_category');
    this.subCategoryInput = this.inputs.find((element) => element.key === 'sub_category');
    this.itemTypeInput = this.inputs.find((element) => element.key === 'item_type');
    this.childCategoryInput = this.inputs.find((element) => element.key === 'child_category');
    this.sizeInput = this.inputs.find((element) => element.key === 'size_id');
    this.upcInput = this.inputs.find((input) => input.key === 'upc');
    this.originalPriceInput = this.inputs.find((input) => input.key === 'original_price');
    this.sizeTypeInput = this.inputs.find((element) => element.key === 'size_type');
    this.signOfWearTypeInput = this.inputs.find((element) => element.key === 'sign_of_wear_type');
  }

  ngAfterViewChecked() {}

  onRetailTagChange = (newVal) => {
    if (this.upcInput) {
      const classWithoutDisplayNone = this.upcInput.classes.filter((x) => x !== 'display-none');
      const originalPriceDisplayNone = this.originalPriceInput.classes.filter((x) => x !== 'display-none');
      if (newVal) {
        this.setValidator('upc');
        this.setValidator('original_price');
        this.upcInput.classes = classWithoutDisplayNone;
        this.originalPriceInput.classes = originalPriceDisplayNone;
        this.upcInput.label = this.addMandatoryToLabel(this.upcInput.label);
        this.originalPriceInput.label = this.addMandatoryToLabel(this.originalPriceInput.label);
      } else {
        this.clearValidator('upc');
        this.clearValidator('original_price');
        this.upcInput.classes = classWithoutDisplayNone.concat(['display-none']);
        this.originalPriceInput.classes = originalPriceDisplayNone.concat(['display-none']);
        this.upcInput.label = this.removeMandatoryLabel(this.upcInput.label);
        this.originalPriceInput.label = this.removeMandatoryLabel(this.originalPriceInput.label);
      }
    }
  };

  onSignOfWearChange = (newVal) => {
    const signOfWearTypeInput = this.inputs.find((element) => element.key === 'sign_of_wear_type');
    if (newVal === true) {
      this.setValidator('sign_of_wear_type');
      signOfWearTypeInput.label = this.addMandatoryToLabel(signOfWearTypeInput.label);
    } else {
      this.clearValidator('sign_of_wear_type');
      signOfWearTypeInput.label = this.removeMandatoryLabel(signOfWearTypeInput.label);
    }
  };

  onConditionChange = (newVal) => {
    const api = AppInjector.get(ApiService).category;
    this.signOfWearTypeInput.options = [];
    const subCategory = this.createForm.get('sub_category').value;
    let conditionName;
    if (newVal) {
      conditionName = newVal.name.replace(/ /g, '_');
      const data = {
        category_name: subCategory.value,
        condition_name: conditionName
      };
      const mainSub = api
        .getConditionTags(data)
        .pipe(take(1))
        .subscribe((res) => {
          this.signOfWearTypeInput.options = res.data.map((type) => {
            return {
              value: type,
              label: type
            };
          });
        });
    }
  };

  onSizeTypeChange = (newVal) => {
    if (newVal && newVal.value && newVal.value !== '') {
      this.sizeInput.options = _.sortBy(
        this.store
          .getState()
          .Admin.Product.create.size_options.filter((option) => option.unit === newVal.value && option.type === 'SHOES')
          .map((option) => {
            option.label = option.label.replace(newVal.value, '');
            return option;
          }),
        'name'
      );
    } else {
      this.sizeInput.options = _.sortBy(
        this.store
          .getState()
          .Admin.Product.create.size_options
          .map((option) => {
            option.label = option.label.replace(newVal.value, '');
            return option;
          }),
        'name'
      );
    }
  };

  onMainCategoryChange = (newVal) => {
    const api = AppInjector.get(ApiService).category;
    this.createForm.get('sub_category').setValue(undefined);
    this.createForm.get('item_type').setValue(undefined);
    this.createForm.get('size_type').setValue('');
    this.createForm.get('child_category').setValue('');
    this.subCategoryInput.options = [];
    this.itemTypeInput.options = [];
    this.childCategoryInput.options = [];
    this.submitChildCategory = false;

    const mainSub = api
      .getSubCategory(newVal.value)
      .pipe(take(1))
      .subscribe((res) => {
        this.subCategoryInput.options = res.data.sub_categories.map((subCat) => {
          return {
            value: subCat,
            label: subCat
          };
        });
      });
    this.setChildCategoryVisibility();
  };

  onSubCategoryChange = (newVal) => {
    this.setPlatformHeightInput(newVal);
    const api = AppInjector.get(ApiService).category;
    const mainCategory = this.createForm.get('main_category').value;
    const condition = this.createForm.get('condition_id').value;
    this.createForm.get('item_type').setValue(undefined);
    this.createForm.get('child_category').setValue('');
    this.createForm.get('retail_tag').setValue(undefined);
    this.createForm.get('sign_of_wear').setValue(undefined);
    this.createForm.get('size_type').setValue('');
    this.itemTypeInput.options = [];
    this.childCategoryInput.options = [];
    if (condition) {
      this.signOfWearTypeInput.options = [];
      this.onConditionChange(condition);
    }

    this.submitChildCategory = false;
    this.hideListedField();
    if (mainCategory.value && newVal && newVal.value) {
      api
        .getItemType(mainCategory.value, newVal.value)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.itemTypeInput.options = res.map((subCat) => {
              return {
                value: subCat.item_type,
                label: subCat.item_type,
                id: subCat.id,
                full_name: subCat.name
              };
            });
          }
        });
    }
    this.setChildCategoryVisibility();
  };

  hideListedField() {
    const fields = [this.sizeInput, this.upcInput, this.originalPriceInput, this.sizeTypeInput];
    fields.forEach((field) => {
      this.clearValidator(field.key);
      field.label = this.removeMandatoryLabel(field.label);
      if (field.classes) {
        if (field.classes.indexOf('display-none') < 0) {
          field.classes.push('display-none');
        }
      } else {
        field.classes = ['display-none'];
      }
    });
  }

  onItemTypeChange = (newVal) => {
    this.createForm.get('child_category').setValue('');
    this.childCategoryInput.options = [];
    if (newVal && newVal.value) {
      this.getCustomAttribute(newVal.full_name);
      const isShoes = newVal.full_name.split(' | ')[1] === 'shoes';
      this.setShoesSizeOptions(isShoes);
      const mainCategory = this.createForm.get('main_category').value;
      const subCategory = this.createForm.get('sub_category').value;
      if (mainCategory && subCategory && mainCategory.value && subCategory.value) {
        const api = AppInjector.get(ApiService).category;
        api
          .getChildCategory(mainCategory.value, subCategory.value, newVal.value)
          .pipe(take(1))
          .subscribe((res) => {
            if (res.length > 0) {
              this.submitChildCategory = true;
              this.childCategoryInput.classes = this.childCategoryInput.classes.filter((x) => x !== 'display-none');
              this.childCategoryInput.options = res.map((item) => {
                return {
                  value: item.id,
                  label: item.last_name,
                  full_name: item.name
                };
              });
              this.childCategoryInput.validators = [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ];
              this.createForm.get('child_category').setValidators([Validators.required]);
              this.createForm.get('child_category').updateValueAndValidity();
            } else {
              this.submitChildCategory = false;
              this.childCategoryInput.classes.push('display-none');
              this.childCategoryInput.options = res.map((item) => {
                return {
                  value: item.id,
                  label: item.last_name,
                  full_name: item.name
                };
              });
              this.createForm.get('child_category').clearValidators();
              this.createForm.get('child_category').updateValueAndValidity();
            }
          });
      }
    }
    this.setChildCategoryVisibility();
    this.setPlatformHeightInput(newVal);
  };

  setShoesSizeOptions(isShoes) {
    // this.createForm.get('size_type').setValue(undefined);
    // this.createForm.get('size_id').setValue(undefined);
    // if (isShoes === true) {
    //   this.sizeInput.options = [];
    // } else {
    //   this.sizeInput.options = this.store.getState().Admin.Product.create.size_options;
    // }
  }

  getCustomAttribute(itemType) {
    this.inputs.forEach((s) => {
      let isUncommonDataAvailable = this.consolidatedProductJson.uncommon_data[itemType];
      let data = isUncommonDataAvailable ? this.consolidatedProductJson.uncommon_data[itemType][s.key] : undefined;
      let commonData = this.consolidatedProductJson.common_data[s.key];
      const itemTypeArr = itemType.split(' | ');
      const arrLength = itemTypeArr.length - 1;
      const minArrLength = 1;
      let newItemType;
      if (!isUncommonDataAvailable) {
        for (let i = arrLength; i > minArrLength - 1; i--) {
          newItemType = itemTypeArr.slice(0, i).join(' | ');
          data = this.consolidatedProductJson.uncommon_data[newItemType] ? this.consolidatedProductJson.uncommon_data[newItemType][s.key] : undefined;
          if (data) {
            break;
          }
        }
      }
      if (data || commonData || this.mandatoryField.indexOf(s.key) > -1 || this.titleField.indexOf(s.key) > -1) {
        if (data && !data.is_options_from_api) {
          s.options = data.options
            ? data.options.map((option) => {
                return {
                  value: option,
                  label: option
                };
              })
            : [];
        }
        if (this.defaultHide.indexOf(s.key) < 0) {
          s.classes = s.classes ? s.classes.filter((x) => x !== 'display-none') : [];
          if (
            this.titleField.indexOf(s.key) < 0 &&
            s.key !== this.availableConditionInput.condition_title.default_data.key &&
            s.key !== this.availableItemTypesInput.item_type_title.default_data.key &&
            s.key !== this.availableProductInfoInput.product_info_title.default_data.key &&
            ((data && data.is_required === true) || (commonData && commonData.is_required === true))
          ) {
            this.setValidator(s.key);
            s.label = this.addMandatoryToLabel(s.label);
          }
        }
      } else {
        if (s.classes) {
          if (s.classes.indexOf('display-none') < 0 && s.key !== 'private_note' && s.key !== 'description') {
            s.classes.push('display-none');
          }
        }
        this.clearValidator(s.key);
        s.label = this.removeMandatoryLabel(s.label);
      }
    });
  }

  onBeforeReject = (form) => {
    this.inputs.forEach((input) => {
      if (this.rejectInvalidateException.indexOf(input.key) < 0) {
        this.clearValidator(input.key);
      }
    });
  };

  onReject = (form) => {
    const formCopy = { ...form.value };
    let category_id;
    if (this.submitChildCategory) {
      category_id = formCopy.child_category.value;
    } else {
      category_id = formCopy.item_type.id;
    }
    if (!form.valid) {
      return;
    }
    let extra_params: any = {};
    if (this.queryParams.extra_params) {
      extra_params = this.queryParams.extra_params;
      if (this.collection && this.collection.currency_id) {
        formCopy.currency_id = this.collection.currency_id;
      }
    }
    let data = {
      name: formCopy.name,
      length: formCopy.length || null,
      width: formCopy.width || null,
      height: formCopy.height || null,
      location: formCopy.location,
      warehouse_sku: formCopy.location,
      price: formCopy.price || null,
      original_price: formCopy.original_price || null,
      designer_id: formCopy.designer_id.id,
      size_id: formCopy.size_id ? formCopy.size_id.id : undefined,
      condition_id: formCopy.condition_id.id,
      material_id: formCopy.material_id.id,
      color_id: formCopy.color_id.id,
      customer_email: formCopy.customer_email,
      retailer_email: formCopy.retailer_email,
      category_id: category_id,
      website: formCopy.website,
      images: formCopy.images,
      description: formCopy.description,
      private_note: formCopy.private_note,
      quantity: formCopy.quantity ? formCopy.quantity : 1,
      is_rejected: true,
      upc: formCopy.upc,
      currency_id: formCopy.currency_id,
      platform_height: !_.isEmpty(formCopy.platform_height) || formCopy.platform_height !== '' ? formCopy.platform_height : null,
      custom_attributes: {
        wash: formCopy.wash,
        heel_height_style: formCopy.heel_height_style.value,
        heel_style: formCopy.heel_style.value,
        width_style: formCopy.width_style.value,
        inclusions: formCopy.inclusions,
        authenticity_card: formCopy.authenticity_card,
        retail_tag: formCopy.retail_tag,
        sign_of_wear: formCopy.sign_of_wear,
        sign_of_wear_type: formCopy.sign_of_wear_type,
        damage_notes: formCopy.damage_notes,
        strap_length: formCopy.strap_length,
        length_style: formCopy.length_style ? formCopy.length_style.label : '',
        strap_width: formCopy.strap_width,
        insole_length: formCopy.insole_length,
        insole_width: formCopy.insole_width,
        heel_height: formCopy.heel_height,
        chest: formCopy.chest,
        waist: formCopy.waist,
        hips: formCopy.hips,
        arm_length: formCopy.arm_length,
        outseam: formCopy.outseam,
        inseam: formCopy.inseam
      },
      ...extra_params
    };
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({ type: CREATE_PRODUCT_REQUESTED, data: data, referer: this.queryParams.referer });
  };

  onSubmit = (form) => {
    const formCopy = { ...form.value };
    let category_id;
    if (this.submitChildCategory) {
      category_id = formCopy.child_category.value;
    } else {
      category_id = formCopy.item_type.id;
    }
    if (!form.valid) {
      return;
    }
    let extra_params: any = {};
    if (this.queryParams.extra_params) {
      extra_params = this.queryParams.extra_params;
      if (
        extra_params.retailer_email === 'balenciaga@reflaunt.com' ||
        extra_params.retailer_email === 'modes@reflaunt.com' ||
        extra_params.retailer_email === 'zalora-vip@reflaunt.com'
      ) {
        if (form.value.price) {
          extra_params.partner_selling_price = getSellingPrice(form.value.price);
        }
      }
      if (extra_params.retailer_slug === 'VIP') {
        if (form.value.price) {
          extra_params.partner_selling_price = getSellingPriceVip(form.value.price);
        }
      }
      if (this.collection && this.collection.currency_id) {
        formCopy.currency_id = this.collection.currency_id;
      }
    }
    let data = {
      name: formCopy.name,
      length: formCopy.length || null,
      width: formCopy.width || null,
      height: formCopy.height || null,
      location: formCopy.location,
      warehouse_sku: formCopy.location,
      price: formCopy.price || null,
      original_price: formCopy.original_price || null,
      designer_id: formCopy.designer_id.id,
      size_id: formCopy.size_id ? formCopy.size_id.id : undefined,
      warehouse: formCopy.warehouse ? formCopy.warehouse.value : undefined,
      condition_id: formCopy.condition_id.id,
      material_id: formCopy.material_id.id,
      color_id: formCopy.color_id.id,
      customer_email: formCopy.customer_email,
      retailer_email: formCopy.retailer_email,
      category_id: category_id,
      website: formCopy.website,
      images: formCopy.images,
      description: formCopy.description,
      private_note: formCopy.private_note,
      quantity: formCopy.quantity ? formCopy.quantity : 1,
      upc: formCopy.upc,
      currency_id: formCopy.currency_id,
      steps_create_collection: stepsCreateCollection.step_1,
      platform_height: !_.isEmpty(formCopy.platform_height) || formCopy.platform_height !== '' ? formCopy.platform_height : null,
      custom_attributes: {
        wash: formCopy.wash,
        heel_height_style: formCopy.heel_height_style.value,
        heel_style: formCopy.heel_style.value,
        clothing_size: formCopy.clothing_size.value,
        width_style: formCopy.width_style.value,
        inclusions: formCopy.inclusions,
        authenticity_card: formCopy.authenticity_card,
        retail_tag: formCopy.retail_tag,
        sign_of_wear: formCopy.sign_of_wear,
        sign_of_wear_type: formCopy.sign_of_wear_type,
        damage_notes: formCopy.damage_notes,
        strap_length: formCopy.strap_length,
        length_style: formCopy.length_style ? formCopy.length_style.label : '',
        strap_width: formCopy.strap_width,
        insole_length: formCopy.insole_length,
        insole_width: formCopy.insole_width,
        heel_height: formCopy.heel_height,
        chest: formCopy.chest,
        waist: formCopy.waist,
        hips: formCopy.hips,
        arm_length: formCopy.arm_length,
        outseam: formCopy.outseam,
        inseam: formCopy.inseam
      },
      ...extra_params
    };
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({ type: CREATE_PRODUCT_REQUESTED, data: data, referer: this.queryParams.referer });
  };

  onChangeReative = (form) => {
    this.createForm = form;
  };

  setChildCategoryVisibility = () => {
    if (this.childCategoryInput) {
      if (this.submitChildCategory === true) {
        this.childCategoryInput.classes = this.childCategoryInput.classes.filter((x) => x !== 'display-none');
      } else {
        this.childCategoryInput.classes.push('display-none');
      }
      this.createForm.get('child_category').setValue('');
    }
  };

  setValidator(fieldName) {
    if (this.mandatoryField.indexOf(fieldName) < 0 && this.validateExceptionField.indexOf(fieldName) < 0) {
      // this.createForm.get(fieldName).reset();
      let input = this.inputs.find((element) => element.key === fieldName) as any;
      input.validators = [
        {
          validator: Validators.required,
          message: 'This field is required'
        }
      ];
      this.createForm.get(fieldName).setValidators([Validators.required]);
      this.createForm.get(fieldName).updateValueAndValidity();
    }
  }

  clearValidator(fieldName) {
    this.createForm.get(fieldName).clearValidators();
    this.createForm.get(fieldName).updateValueAndValidity();
  }

  testlog() {
    const controls = this.createForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log('invalid field ', name);
      }
    }
  }

  addMandatoryToLabel(label) {
    if (label.indexOf('*') < 0) {
      label = label.trim();
      label = label.concat(' *');
    }
    return label;
  }

  removeMandatoryLabel(label) {
    if (label.indexOf('*') > -1) {
      label = label.replace('*', '');
      label = label.trim();
    }
    return label;
  }

  setPlatformHeightInput(newVal) {
    if (newVal) {
      if (newVal.full_name && newVal.full_name.includes('shoes')) {
        this.displayPlatformHeightInput();
      } else if (newVal.value && newVal.value === 'shoes') {
        this.displayPlatformHeightInput();
      } else {
        this.hidePlatformHeightInput();
      }
      if ((newVal.full_name && newVal.full_name.includes('accessories')) || (newVal.value && newVal.value.includes('accessories'))) {
        this.clearValidator('inclusions');
        const inclusionsInput = this.inputs.find((element) => element.key === 'inclusions');
        inclusionsInput.label = this.removeMandatoryLabel(inclusionsInput.label);

        this.clearValidator('authenticity_card');
        const authenticityCardInput = this.inputs.find((element) => element.key === 'authenticity_card');
        authenticityCardInput.label = this.removeMandatoryLabel(authenticityCardInput.label);
      }
    }
  }

  displayPlatformHeightInput() {
    this.store.getState().Admin.Product.create.inputs.map((item) => {
      if (item.key === 'platform_height') {
        if (item.classes.includes('display-none')) {
          item.classes.pop();
        }
      }
    });
  }

  hidePlatformHeightInput() {
    this.store.getState().Admin.Product.create.inputs.map((item) => {
      if (item.key === 'platform_height') {
        if (!item.classes.includes('display-none')) {
          item.classes.push('display-none');
        }
      }
    });
  }

  ngOnDestroy() {
    $('.pcoded-item>.Collection').removeClass('menu-text-color--active active');
    $('.pcoded-item>.Product').addClass('active');
  }
}
