import { ConfigMarketplace } from './config-marketplace/config-marketplace.reducer';
import { combineReducers } from 'redux';
import { list } from './list/list.reducer';
import { edit } from './edit/edit.reducer';
import { create } from './create/create.reducer';
import { GET_ALL_INTEGRATIONS_SUCCEEDED } from './integration.action';
import { EDIT_INTEGRATION_SUCCEEDED, DELETE_INTEGRATION_SUCCEEDED } from './edit/edit.actions';
import { CREATE_INTEGRATION_SUCCEEDED } from './create/create.actions';
const all = (state = { fetched: false, items: [] }, action) => {
  switch (action.type) {
    case CREATE_INTEGRATION_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case EDIT_INTEGRATION_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case DELETE_INTEGRATION_SUCCEEDED:
      return {
        fetched: false,
        items: [...state.items]
      };
    case GET_ALL_INTEGRATIONS_SUCCEEDED:
      return {
        fetched: true,
        items: [...action.data]
      };
    default:
      return state;
  }
};
export const Integration = combineReducers({ ConfigMarketplace, list, edit, create, all });
