import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';


const RENDER_CONFIG_SETTING_DEFAULT_VALUE = Object.freeze({
  edit: false,
  new_value: ''
});

@Component({
  selector: 'smart-button-render-config',
  templateUrl: './smart-button-render-config.component.html',
  styleUrls: ['./smart-button-render-config.component.scss']
})
export class SmartButtonRenderConfigComponent implements OnChanges {
  @Input() config = {};
  @Output() saved = new EventEmitter<object>();

  new_render_key: string = '';
  render_keys: Array<string>;
  render_config: object = {};

  constructor(private notification: NotificationService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.render_keys = Object.keys(this.config);
      this.render_config = this.render_keys.reduce((acc, cur) => {
        if (!this.config[cur].rules) {
          this.config[cur] = {
            rules: []
          };
        }
        acc[cur] = {
          rules: this.config[cur].rules.map((s, k) => {
            if (typeof s !== 'object' || !s) {
              s = {};
            }
            return {
              operator: typeof s.operator !== 'undefined' ? s.operator : '=',
              value: typeof s.value !== 'undefined' ? s.value : '',
              render: typeof s.render !== 'undefined'? s.render.toString() : 'true',
              key: k
            };
          }),
          default_rule: {
            render: typeof this.config[cur].default_rule !== 'undefined'? this.config[cur].default_rule.render.toString() : 'true',
          },
          setting: {
            ...RENDER_CONFIG_SETTING_DEFAULT_VALUE
          }
        };
        return acc;
      }, {});
    }
  }

  _validateNewKey(render_key) {
    if (!render_key) {
      this.notification.show('warning', 'Key cannot be empty', 5000);
      return false;
    }
    if (typeof this.render_config[render_key] !== 'undefined') {
      this.notification.show('warning', 'Key exist, please change', 5000);
      return false;
    }
    return true;
  }

  _validateExistingKey(render_key) {
    if (!render_key) {
      this.notification.show('warning', 'Key cannot be empty', 5000);
      return false;
    }
    if (typeof this.render_config[render_key] === 'undefined') {
      this.notification.show('warning', 'Key not exists', 5000);
      return false;
    }
    return true;
  }

  _validateRenderConfig(render_config) {
    const render_keys = Object.keys(render_config);
    for (let i = 0; i < render_keys.length; i++) {
      const key = render_keys[i];
      const rules = render_config[key].rules;
      if (rules.length === 0) {
        this.notification.show('warning', 'Key should have at least 1 rule', 5000);
        return false;
      }
      const values = rules.map(s => s.value).filter(s => s !== '');
      if (values.length !== rules.length) {
        this.notification.show('warning', 'Value inside rules cannot be empty', 5000);
        return false;
      }
      const unique_values = rules.map(s => `${s.value}-${s.operator}`).filter((val, index, arr) => {
        return arr.indexOf(val) === index;
      });
      if (unique_values.length !== values.length) {
        this.notification.show('warning', 'Each rule has to be unique', 5000);
        return false;
      }
    }
    return true;
  }

  addKey(render_key) {
    if (this._validateNewKey(render_key)) {
      this.new_render_key = '';
      this.render_keys.push(render_key);
      this.render_config[render_key] = {
        rules: [],
        setting: {
          ...RENDER_CONFIG_SETTING_DEFAULT_VALUE
        },
        default_rule: {
          render: 'true'
        }
      };
    }
  }

  deleteKey(render_key) {
    delete this.render_config[render_key];
    this.render_keys = Object.keys(this.render_config);
  }

  editKey(render_key, new_key) {
    // if no changes made, just reset
    if (render_key === new_key) {
      this.render_config[new_key].setting = {
        ...RENDER_CONFIG_SETTING_DEFAULT_VALUE
      };
      return;
    }
    if (this._validateNewKey(new_key)) {
      this.render_config[new_key] = JSON.parse(JSON.stringify(this.render_config[render_key]));
      delete this.render_config[render_key];

      this.render_keys = Object.keys(this.render_config);
      this.render_config[new_key].setting = {
        ...RENDER_CONFIG_SETTING_DEFAULT_VALUE
      };
    }
  }

  addItem(render_key) {
    if (this._validateExistingKey(render_key)) {
      this.render_config[render_key].rules.push({
        value: '',
        operator: '=',
        render: 'true',
        key: this.render_config[render_key].rules.length
      });
    }
  }

  deleteItem(render_key, array_key) {
    if (this._validateExistingKey(render_key)) {
      this.render_config[render_key].rules = this.render_config[render_key].rules.filter(s => s.key !== array_key);
    }
  }

  toggleEdit(render_key) {
    this.render_config[render_key].setting.edit = !this.render_config[render_key].setting.edit;
    this.render_config[render_key].setting.new_value = render_key;
  }

  getRenderFromRenderConfig(render_config) {
    return Object.keys(render_config).reduce((acc, cur) => {
      acc[cur] = {
        rules: render_config[cur].rules.map(s => ({
          value: s.value,
          operator: s.operator,
          render: s.render === 'true' || s.render === true
        })),
        default_rule: {
          render: render_config[cur].default_rule.render === 'true' ||
            render_config[cur].default_rule.render === true
        }
      };
      return acc;
    }, {});
  }

  saveRenderConfig() {
    if (this._validateRenderConfig(this.render_config)) {
      this.saved.emit(this.getRenderFromRenderConfig(this.render_config));
    }
  }
}
