import Model from './Model';
import * as _ from 'lodash';
import User from './User';
import { IssueTracking } from './IssueTracking';

export const ACTIVITY_TYPE_PRODUCT = 'product';
export const ACTIVITY_TYPE_RETAILER = 'retailer';
export const ACTIVITY_TYPE_SHIPMENT = 'shipment';
export const ACTIVITY_TYPE_ORDER = 'order';

export class ActivityLog extends Model {
  user: any;
  constructor(options) {
    super();

    (this as any).user = (d) => {
      return new User(d.data);
    };

    (this as any).issue_tracking = (d) => {
      return new IssueTracking(d.data);
    };

    this.bind(options);
  }

  getEmail() {
    if (this.user) {
      return this.user.getEmail();
    }
    return 'system';
  }
}
