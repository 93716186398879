import { AppInjector } from './../../app-injector';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceProvider } from '../service.provider';
import Condition from '../../models/Condition';
import { Observable } from 'rxjs';
import { PreloaderService } from '../../common/services/preloader/preloader.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class ConditionService extends ServiceProvider {
  public url = '/api/v1/conditions';
  public model = Condition;

  list(params = {}): Observable<any> {
    AppInjector.get(PreloaderService).show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(this.apiUrl.getApiUrl(`${this.url}/list`), { params: queryParams }).pipe(
      tap((result) => {
        AppInjector.get(PreloaderService).hide();
      }),
      map((result) => (result as any).data.map((item) => new this.model(item))),
      catchError((error) => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
