import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Address } from '../../../models/Address';

@Injectable()
export class AddressService extends ServiceProvider {
  public url = '/api/v1/addresses';
  public model = Address;

  getSellerAddressByToken(token, data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/landing-page/' + token, data).pipe(
      map(result => new Address((result as any).data)),
      catchError(error => {
        throw error;
      })
    );
  }

  saveAddressByToken(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url) + '/' + data.token + '/save-address', data).pipe(
      map(result => result),
      catchError(error => {
        throw error;
      })
    );
  }

  updateShipmentAddress(shipmentID, data): Observable<any> {
    return this.http.put(this.apiUrl.getApiUrl(this.url) + '/shipment/' + shipmentID, data).pipe(
      map(result => {
        return new this.model((result as any).data);
      }),
      catchError(error => {
        throw error;
      })
    );
  }
  getDetailLocationAddress(location_id): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + `/detail/${location_id}`).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
}
