import { combineReducers } from 'redux';
import { ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_SUCCESS, ADMIN_ATTRIBUTES_PRESEARCH_RETAILER, ADMIN_ATTRIBUTES_PRESELECT_RETAILER } from './attributes-retailer.action';
import * as _ from 'lodash';
import { Category } from './category/category.reducer';
import { Designer } from './designer/designer.reducer';
import { Size } from './size/size.reducer';
import { Condition } from './condition/condition.reducer';
import { Color } from './color/color.reducer';
import { Material } from './material/material.reducer';

const all = (
  state = {
    retailer: { fetched: false, items: [], selected: null, direct: true, inputValue: null }
  },
  action
) => {
  switch (action.type) {
    case ADMIN_ATTRIBUTES_FETCH_ALL_RETAILERS_SUCCESS:
      action.data.sort((a, b) =>
        _.lowerCase(a.retailer && a.retailer.company ? a.retailer.company : a.email) < _.lowerCase(b.retailer && b.retailer.company ? b.retailer.company : b.email) ? -1 : 1
      );
      return _.assign({}, state, {
        retailer: {
          fetched: true,
          items: action.data
        }
      });
    case ADMIN_ATTRIBUTES_PRESELECT_RETAILER:
      return _.assign({}, state, {
        retailer: _.assign({}, state.retailer, {
          inputValue: action.data
        })
      });
    case ADMIN_ATTRIBUTES_PRESEARCH_RETAILER:
      return _.assign({}, state, {
        retailer: _.assign({}, state.retailer, {
          searchValue: action.data
        })
      });
    default:
      return state;
  }
};
export const AttributesRetailer = combineReducers({
  all,
  Category,
  Designer,
  Size,
  Condition,
  Color,
  Material
});
