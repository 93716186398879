import {
  ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_REQUESTED,
  ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_SUCCESSED,
  DELETE_MATERIAL_ATTRIBUTE_REQUESTED,
  DELETE_MATERIAL_ATTRIBUTE_SUCCESSED,
  FETCH_MATERIAL_DICTIONARY_SUCCESSED,
  FETCH_MATERIAL_DICTIONARY_REQUESTED,
  ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_REQUEST,
  ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_SUCCESS,
  ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_SUCCESS,
  ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_REQUESTED,
  ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_SUCCESS,
  ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_SUCCESS,
  ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_REQUESTED,
  ADMIN_ATTRIBUTES_MATERIAL_SYNCHRONISE_ATTRIBUTES_REQUEST,
  ADMIN_ATTRIBUTES_MATERIAL_FILTER_SELECTED,
  TOGGLE_MATERIAL_DICTIONARY_VALIDATION_REQUESTED,
  TOGGLE_MATERIAL_DICTIONARY_VALIDATION_SUCCEEDED
} from './material.action';
import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ADMIN_ATTRIBUTES_SELECT_ANY, ADMIN_ATTRIBUTES_SELECT_MARKETPLACE } from '../attributes.action';
import { Router } from '@angular/router';
import { materialAttributes } from './material.const';

function* fetchMaterials(action) {
  const api = AppInjector.get(ApiService);
  try {
    let results = yield api.admin.material.get(action.data).toPromise();
    let selected_marketplace = yield select(state => state.Admin.Attributes.all.marketplace.selected);
    yield put({ type: ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_SUCCESSED, data: results.items, pagination: results.pagination });
    if (selected_marketplace) {
      yield put({ type: ADMIN_ATTRIBUTES_SELECT_MARKETPLACE, data: selected_marketplace, direct: false, component: 'material' });
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchMaterialAttributeRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_FETCH_MATERIAL_ATTRIBUTE_REQUESTED, fetchMaterials);
}

function* deleteMaterial(action) {
  const api = AppInjector.get(ApiService);
  const notification = AppInjector.get(NotificationService);
  try {
    let result = yield api.admin.material.delete(action.data.id).toPromise();
    yield put({ type: DELETE_MATERIAL_ATTRIBUTE_SUCCESSED, data: action.data.id });
    notification.show('success', 'Delete material success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}
function* fetchMaterialDictionary(action) {
  try {
    const api = AppInjector.get(ApiService);

    let results = yield api.admin.dictionary.list(action.data).toPromise();
    yield put({ type: FETCH_MATERIAL_DICTIONARY_SUCCESSED, data: results, marketplace_id: action.data.marketplace_id });
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDeleteMaterialRequest() {
  yield takeEvery(DELETE_MATERIAL_ATTRIBUTE_REQUESTED, deleteMaterial);
}

function* watchFetchMaterialDictionaryRequested() {
  yield takeEvery(FETCH_MATERIAL_DICTIONARY_REQUESTED, fetchMaterialDictionary);
}

function* watchUpdateMaterialDictionaryRequested() {
  yield takeEvery(ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_REQUEST, updateMaterialDictionary);
}

function* updateMaterialDictionary(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result = yield api.admin.dictionary.updateOrCreate(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_MATERIAL_UPDATE_DICTIONARY_SUCCESS, data: result });
    AppInjector.get(NotificationService).show('success', 'Update dictionary success', 5000);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* toggleMaterialDictionaryValidationRequested() {
  yield takeEvery(TOGGLE_MATERIAL_DICTIONARY_VALIDATION_REQUESTED, toggleMaterialDictionaryValidation);
}

function* toggleMaterialDictionaryValidation(action) {
  const api = AppInjector.get(ApiService);
  try {
    let result;
    if (action.data.is_validated) {
      result = yield api.admin.dictionary.invalidate(action.data.id).toPromise();
    } else {
      result = yield api.admin.dictionary.validate(action.data.id).toPromise();
    }
    yield put({ type: TOGGLE_MATERIAL_DICTIONARY_VALIDATION_SUCCEEDED, data: result });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchSelectAnyFilter() {
  yield takeEvery(ADMIN_ATTRIBUTES_SELECT_ANY, function*(action: any) {
    const attribute = yield select((state: any) => state.Admin.Attributes.all);
    if (action.component && action.component === 'material') {
      yield put({ type: ADMIN_ATTRIBUTES_MATERIAL_FILTER_SELECTED, data: attribute });
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_REQUEST, data: { marketplace_id: attribute.marketplace.selected.id } });
    }
  });
}

function* watchFetchAllMarketplaceMaterialsRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_REQUEST, function*(action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const results = yield api.marketplace.getAllMaterials(action.data).toPromise();
      yield put({ type: ADMIN_ATTRIBUTES_FETCH_ALL_MARKETPLACE_MATERIALS_SUCCESS, data: results });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* createMaterial(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.material.create(action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_SUCCESS, data: result, component: action.component });

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'material']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateMaterialRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_MATERIAL_CREATE_MATERIAL_REQUESTED, createMaterial);
}

function* updateMaterial(action) {
  const api = AppInjector.get(ApiService);
  const router = AppInjector.get(Router);
  try {
    let result = yield api.admin.material.update(action.data.id, action.data).toPromise();
    yield put({ type: ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_SUCCESS, data: result, component: action.component });
    AppInjector.get(NotificationService).show('success', 'Update material success', 5000);

    if (_.isNil(action.component)) {
      router.navigate(['admin', 'material']);
    }
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchUpdateMaterialRequest() {
  yield takeEvery(ADMIN_ATTRIBUTES_MATERIAL_EDIT_MATERIAL_REQUESTED, updateMaterial);
}

function* watchSynchroniseMaterialsRequest() {
  yield takeLatest(ADMIN_ATTRIBUTES_MATERIAL_SYNCHRONISE_ATTRIBUTES_REQUEST, function*(action: any) {
    try {
      const api = AppInjector.get(ApiService);
      const router = AppInjector.get(Router);
      const notify = AppInjector.get(NotificationService);
      const result = yield api.marketplace.syncAttributes(action.data).toPromise();
      const async = yield AppInjector.get(ApiService)
        .asyncProcess.checkAsyncStatus(result.id, 10000)
        .toPromise();
      if (async !== 'Time out') {
        if (async.status === 'COMPLETED') {
          router.navigate(materialAttributes());
          notify.show('success', 'Synchronisation success', 5000);
        }
        if (async.status === 'CANCELLED') {
          AppInjector.get(NotificationService).show('warning', `${async.message}`, 3000);
        }
      } else {
        AppInjector.get(NotificationService).show('warning', `processing will take longer time`, 3000);
      }
      AppInjector.get(PreloaderService).hide();
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [
  watchDeleteMaterialRequest,
  watchFetchMaterialAttributeRequest,
  watchFetchMaterialDictionaryRequested,
  watchUpdateMaterialDictionaryRequested,
  watchSelectAnyFilter,
  watchFetchAllMarketplaceMaterialsRequest,
  watchCreateMaterialRequest,
  watchUpdateMaterialRequest,
  watchSynchroniseMaterialsRequest,
  toggleMaterialDictionaryValidationRequested
];
