import * as _ from 'lodash';
import { FETCH_DATA_ADMIN_ORDER_SUCCESSED } from './detail.actions';
import { SHIPMENT_STATUS_SHIPPED, SHIPMENT_STATUS_DELIVERED } from '../../../../models/Shipment';

export const detail = (state = { fetched: false, item: {}, transaction: {}, locations: [], itemLocations: [], productServiceTypes: [] }, action) => {
  switch (action.type) {
    case FETCH_DATA_ADMIN_ORDER_SUCCESSED:
      if (action.shipment) {
        action.data.order_products.forEach((e) => {
          action.shipment.forEach((i) => {
            if (e.id === i.order_product_id && (i.status === SHIPMENT_STATUS_SHIPPED || i.status === SHIPMENT_STATUS_DELIVERED)) {
              e = _.assign(e, { return_button: true });
            }
          });
        });
      }
      if (action.shipment.length === 0 && action.order_product_shipment.length > 0) {
        action.shipment = action.order_product_shipment;
        action.data.order_products.forEach((e) => {
          if (e.shipment_manys.data[0].status === SHIPMENT_STATUS_SHIPPED || e.shipment_manys.data[0].status === SHIPMENT_STATUS_DELIVERED) {
            e = _.assign(e, { return_button: true });
          }
        });
      }
      if (action.transaction) {
        action.transaction = _.filter(action.transaction, (e) => Number(e.parent_id) === 0);
        action.transaction.forEach((e) => {
          const transaction_status = _.find(action.status, (i) => i.id === e.status);
          if (transaction_status) {
            e = _.assign(e, { transaction_status: transaction_status.name });
          }
        });
        action.data.order_products.forEach((e) => {
          action.transaction.forEach((i) => {
            if (e.id === i.order_product_id) {
              e = _.assign(e, { retailer: i.retailer, seller: i.seller });
            }
          });
        });
      }
      return _.assign(state, {
        fetched: true,
        item: action.data,
        transaction: action.transaction,
        shipment: action.shipment,
        locations: action.locations,
        itemLocations: action.itemLocations,
        productServiceTypes: action.productServiceTypes
      });
    default:
      return state;
  }
};
