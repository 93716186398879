import * as _ from 'lodash';
import { RENDER_ATTRIBUTE_FORM_SUCCEEDED } from './create.action';

export const Create = (
  state = {
    created: false
  },
  action
) => {
  switch (action.type) {
    case RENDER_ATTRIBUTE_FORM_SUCCEEDED:
      return _.assign({}, state, { inputs: action.data.inputs });
    default:
      return state;
  }
};
