import {
  FETCH_ALL_ADMIN_ORDER_SUCCESSED,
  FETCH_ALL_ADMIN_ORDER_REQUESTED,
  GET_ALL_RETAILER_IN_ORDER_PAGE_REQUESTED,
  GET_ALL_RETAILER_IN_ORDER_PAGE_SUCCESSED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_SUCCESSED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_REQUESTED
} from './list.actions';
import * as _ from 'lodash';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchFetchOrdersRequest() {
  yield takeEvery(FETCH_ALL_ADMIN_ORDER_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).order.get(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_ORDER_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getAllRetailerInOrderPage() {
  yield takeLatest(GET_ALL_RETAILER_IN_ORDER_PAGE_REQUESTED, function* (action: any) {
    try {
      let data: any = {};
      if (!_.isNil(action.data)) {
        data.constraints = JSON.stringify(action.data);
      }
      const results = yield AppInjector.get(ApiService).user.list(data).toPromise();
      yield put({ type: GET_ALL_RETAILER_IN_ORDER_PAGE_SUCCESSED, data: results });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* watchFetchAllMarketplaceInOrderPage() {
  yield takeEvery(FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.user.get({ ...action.data, per_page: 10000 })
        .toPromise();
      result = result.items;
      result.sort(function (a, b) {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });
      yield put({ type: FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_PAGE_SUCCESSED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchOrdersRequest, getAllRetailerInOrderPage, watchFetchAllMarketplaceInOrderPage];
