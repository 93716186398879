import { API_CALL_ERROR } from '../../../../store/action';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import * as _ from 'lodash';
import { takeEvery, put } from 'redux-saga/effects';
import { Router } from '@angular/router';
import { listAdminMarketplace } from '../marketplace.const';
import { CREATE_ADMIN_MARKETPLACE_REQUESTED, CREATE_ADMIN_MARKETPLACE_SUCCEEDED } from './create.actions';

function* create(action) {
  try {
    AppInjector.get(PreloaderService).show();
    const result = yield AppInjector.get(ApiService)
      .user.create(action.data)
      .toPromise();

    yield put({
      type: CREATE_ADMIN_MARKETPLACE_SUCCEEDED,
      data: result
    });

    if (action.redirect) {
      AppInjector.get(Router).navigate([action.redirect]);
    } else {
      AppInjector.get(Router).navigate(listAdminMarketplace());
    }

    AppInjector.get(PreloaderService).hide();
  } catch (e) {
    AppInjector.get(PreloaderService).hide();
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchCreateUserRequest() {
  yield takeEvery(CREATE_ADMIN_MARKETPLACE_REQUESTED, create);
}

export default [watchCreateUserRequest];
