import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import ExportQuery from '../../../models/ExportQuery';
import { Observable } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { tap, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import Payment from '../../../models/Payment';

@Injectable()
export class PaymentService extends ServiceProvider {
  public url = '/api/v1/admin/payments';
  public model = Payment;

  exportCsv(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/export-csv'), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  exportCsvTransferwise(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/export-csv-transferwise'), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  bulkPaid(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/bulk-paid'), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  updateOrCreatePaymentDetail(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/update-or-create-payment-customer'), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  createPayment(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/create-payment-after-sold-confirm'), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  createPaymentAsVoucher(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/create-payment-as-voucher'), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  getAllPaymentMethod(): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + '/get-all-payment-method')).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  getPaymentAccountDefault(id): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + `/${id}/get-default-payment-account`)).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  createVoucher(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + `/create-voucher`), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  getCountries(): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + `/get-countries-allowed`)).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
  getDefaultPaymentAccountByUserID(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + `/get-default-payment-account-by-user-id`), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  updatePaymentAccount(data): Observable<any> {
    return this.http.post(this.apiUrl.getApiUrl(this.url + `/update-payment-account-by-user-id`), data).pipe(
      map((result) => {
        return (result as any).data;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
