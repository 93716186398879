import * as _ from 'lodash';
import { FETCH_MEASUREMENT_UNIT_DETAIL_SUCCESSED } from './edit.action';
import { Dropdown, TextBox } from '@reflaunt/reactive-form';

export const Edit = (
  state = {
    detail: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_MEASUREMENT_UNIT_DETAIL_SUCCESSED:
      return _.assign({}, state, { detail: action.data });
    default:
      return state;
  }
};
