import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServiceProvider } from '../../service.provider';
import TemplateEmail from '../../../models/TemplateEmail';
import * as _ from 'lodash';
import Email from '../../../models/Email';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class EmailService extends ServiceProvider {
  public emailHistoryUrl = '/api/v1/emails';
  public url = '/api/v1/admin/email-history';
  public model = Email;
  public modelTemplate = TemplateEmail;
  public modelEmail = Email;
  getListTemplate(): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.emailHistoryUrl) + '/list-template').pipe(
      map((result) => {
        return _.map((result as any).data, (item) => new this.modelTemplate(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  getDetailTemplate(nameTemplate): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.emailHistoryUrl) + '/info-template/' + nameTemplate).pipe(
      map((result) => new this.modelTemplate(result as any)),
      catchError((error) => {
        throw error;
      })
    );
  }

  getListEmail(params = {}): Observable<any> {
    let query = [];
    // tslint:disable-next-line:forin
    for (const prop in params) {
      query.push(prop + '=' + params[prop]);
    }
    return this.http.get(this.apiUrl.getApiUrl(this.emailHistoryUrl) + '/list-email' + '?' + _.join(query, '&')).pipe(
      tap((result) => {}),
      map((result) => {
        return _.map(result as any, (item) => new this.modelEmail(item));
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getDetailEmail(idEmail): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.emailHistoryUrl) + '/info-email/' + idEmail).pipe(
      map((result) => new this.modelEmail(result as any)),
      catchError((error) => {
        throw error;
      })
    );
  }

  getTest(idEmail): Observable<any> {
    return this.http.get(environment.emailHistoryBucket + '/outbox/' + idEmail).pipe(
      tap((result) => {
        console.log('result', result);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  getTextFile(idEmail) {
    return this.http.get(environment.emailHistoryBucket + '/outbox/' + idEmail, { responseType: 'text' }).pipe(
      tap(
        (data) => {},
        (error) => console.log(error)
      )
    );
  }
  getByMandrillId(mandrillId): Observable<any> {
    return this.http.get(this.apiUrl.getApiUrl(this.url) + '/detail/' + mandrillId).pipe(
      map((result) => new this.model((result as any).data)),
      catchError((error) => {
        throw error;
      })
    );
  }
}
