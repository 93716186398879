import * as _ from 'lodash';
import {
  DEFAULT_CONFIG_GET_RETAILER_INFO_SUCCESS,
  DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_SUCCESS,
  DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS,
  DEFAULT_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION,
  DEFAULT_CONFIG_EDIT_NOTIFICATION_ACTION_ITEM,
  DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE
} from './default-config.actions';

const getAllNotifyActionSuccess = (state, action) => {
  return _.assign({}, state, {
    notifyAction: _.assign({}, state.notifyAction, { fetched: true, items: _.map(action.data, (item) => _.assign(item, { isEdit: false, checked: false })) })
  });
};

const resolveSelectedNotifyAction = (state, action) => {
  const { defaultConfig } = state;
  return _.assign({}, state, {
    notifyAction: _.assign(
      {},
      state.notifyAction,
      {
        items: _.map(state.notifyAction.items, (item) => {
          return _.assign(item, { isEdit: false, checked: false, config: null });
        })
      },
      {
        items: _.map(state.notifyAction.items, (item) => {
          const config = _.find(defaultConfig.items, (configItem) => configItem.integration.notification_action.id === item.id);
          if (config) {
            return _.assign(item, { checked: true, config });
          }
          return item;
        })
      }
    )
  });
};

const editNotifyActionItem = (state, action) => {
  const actionItem = action.data;
  return _.assign({}, state, {
    notifyAction: _.assign({}, state.notifyAction, {
      items: _.map(state.notifyAction.items, (item) => {
        const currentState = _.assign({}, item);
        if (item.id === actionItem.id) {
          return _.assign(item, { isEdit: !currentState.isEdit });
        }
        return item;
      })
    })
  });
};

const setActiveLanguage = (state, action) => {
  return _.assign({}, state, { activeLanguage: action.data });
};

export const DefaultConfig = (
  state = {
    retailer: { fetched: false, item: {} },
    notifyAction: { fetched: false, items: [] },
    defaultConfig: { fetched: false, items: [] },
    activeLanguage: {}
  },
  action
) => {
  switch (action.type) {
    case DEFAULT_CONFIG_GET_RETAILER_INFO_SUCCESS: {
      return _.assign({}, state, { retailer: _.assign({}, state.retailer, { fetched: true, item: action.data }) });
    }
    case DEFAULT_CONFIG_GET_ALL_NOTIFICATION_ACTION_SUCCESS: {
      return getAllNotifyActionSuccess(state, action);
    }
    case DEFAULT_CONFIG_GET_ALL_RETAILER_EMAIL_CONFIGURATION_SUCCESS: {
      return _.assign({}, state, { defaultConfig: _.assign({}, state.defaultConfig, { fetched: true, items: action.data }) });
    }
    case DEFAULT_CONFIG_RESOLVE_SELECTED_NOTIFICATION_ACTION: {
      return resolveSelectedNotifyAction(state, action);
    }
    case DEFAULT_CONFIG_EDIT_NOTIFICATION_ACTION_ITEM: {
      return editNotifyActionItem(state, action);
    }
    case DEFAULT_CONFIG_SET_ACTIVE_LANGUAGE: {
      return setActiveLanguage(state, action);
    }
    default:
      return state;
  }
};
