export const ADMIN_MARKETPLACE_COMP = 'ADMIN_MARKETPLACE_COMP';

export const listAdminMarketplace = () => ['/', 'admin', 'marketplace'];
export const editAdminMarketplace = (id) => ['/', 'admin', 'marketplace', id];
export const createAdminMarketplace = () => ['/', 'admin', 'marketplace', 'create'];
export const marketplaceDetailRouter = (id) => ['/', 'admin', 'marketplace', 'edit', id];
export const listMarketplaceCredentialRouter = (id) => {
  return ['/', 'admin', 'marketplace', id, 'credential'];
};
export const listMarketplaceShippingRouter = (id) => {
  return ['/', 'admin', 'marketplace', id, 'retailers'];
};
export const createAdminMarketplaceSettingCostRouter = (id) => {
  return ['/', 'admin', 'marketplace', id, 'configuration'];
};
