import { BaseComponent } from '../../../base.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { CREATE_DATASET_GROUP_REQUEST, GET_LIST_NORMAL_DATASET_REQUEST } from './create-group.actions';
import { FormBuilder, Validators } from '@angular/forms';
import { uniq } from 'lodash';
import { editRouter, listRouter } from '../dataset.const';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent extends BaseComponent implements OnInit {
  public reducer = 'Retailer.Dataset.createGroup';
  public createGroupForm = this.fb.group({
    title: ['', Validators.required],
    children: ['', Validators.required]
  });
  public data = [];
  public datasetId;
  public listRouter = listRouter;
  public editRouter = editRouter;

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    super();
    this.datasetId = Number(this.activatedRoute.snapshot.params.id);
    const { dispatchGetListNormalDatasetRequest } = this as any;
    this.navigationSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      dispatchGetListNormalDatasetRequest(this.datasetId);
    });
  }

  get title() {
    return this.createGroupForm.get('title');
  }

  get children() {
    return this.createGroupForm.get('children');
  }

  get user() {
    return this.store.getState().Auth.login.profile;
  }

  ngOnInit() {
    this.init();
  }

  onSubmit() {
    const { dispatchCreateDatasetGroupRequest } = this as any;
    this.createGroupForm.markAllAsTouched();
    if (this.createGroupForm.valid) {
      const childrenIds = this.createGroupForm.value.children;
      childrenIds.push(this.datasetId);
      const data = {
        title: this.createGroupForm.value.title,
        children_ids: uniq(childrenIds),
        user_id: this.user.id
      };
      dispatchCreateDatasetGroupRequest(this.datasetId, data);
    }
  }

  mapStateToProps(state) {
    return {
      payload: state.Retailer.Dataset.createGroup
    };
  }

  mapDispatchToProps(dispatch) {
    return {
      dispatch: dispatch,
      dispatchGetListNormalDatasetRequest: (datasetId) => {
        dispatch({ type: GET_LIST_NORMAL_DATASET_REQUEST, data: datasetId });
      },
      dispatchCreateDatasetGroupRequest: (datasetId, payload) => {
        dispatch({ type: CREATE_DATASET_GROUP_REQUEST, data: { datasetId, payload } });
      }
    };
  }
}
