import { Router } from '@angular/router';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import { takeEvery, put } from 'redux-saga/effects';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { BUY_VOUCHER_REQUESTED, BUY_VOUCHER_SUCCEEDED } from './buy.actions';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* watchBuyVoucherRequested() {
  yield takeEvery(BUY_VOUCHER_REQUESTED, function*(action: any) {
    try {
      const router = AppInjector.get(Router);
      let results = yield AppInjector.get(ApiService)
        .voucher.create(action.data)
        .toPromise();
      yield put({ type: BUY_VOUCHER_SUCCEEDED, data: results });
      AppInjector.get(NotificationService).show('success', 'Buy Voucher success!', 3000);
      router.navigate(['customer/voucher']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchBuyVoucherRequested];
