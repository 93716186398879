import { Injectable } from '@angular/core';
import { ServiceProvider } from '../../service.provider';
import Notification from '../../../models/Notification';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import LengthAwarePaginator from '../../../models/LengthAwarePaginator';
import * as _ from 'lodash';
import { AppInjector } from '../../../app-injector';
import { PreloaderService } from '../../../common/services/preloader/preloader.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class NotificationService extends ServiceProvider {
  public url = `${environment.emailScrappingSdkUrl}/received-emails`;
  public model = Notification;

  get(params = {}): Observable<any> {
    this.preloader.show();
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get(`${this.url}`, { params: queryParams }).pipe(
      tap(result => {
        this.preloader.hide();
      }),
      map(result =>
        _.assign(
          {},
          {
            items: (result as any).Items.map(item => new this.model(item))
            // pagination: new LengthAwarePaginator((result as any).meta.pagination)
          }
        )
      ),
      catchError(error => {
        this.preloader.hide();
        throw error;
      })
    );
  }

  delete(id): Observable<any> {
    AppInjector.get(PreloaderService).show();
    return this.http.delete(this.url + '/' + id).pipe(
      tap(result => {
        AppInjector.get(PreloaderService).hide();
      }),
      catchError(error => {
        AppInjector.get(PreloaderService).hide();
        throw error;
      })
    );
  }
}
