export const RENDER_EDIT_RETAILER_FORM_REQUESTED = 'RENDER_EDIT_RETAILER_FORM_REQUESTED';
export const FILL_RETAILER_DETAIL_FORM = 'FILL_RETAILER_DETAIL_FORM';
export const GET_RETAILER_REQUESTED = 'GET_RETAILER_REQUESTED';
export const GET_RETAILER_SUCCEEDED = 'GET_RETAILER_SUCCEEDED';
export const EDIT_RETAILER_REQUESTED = 'EDIT_RETAILER_REQUESTED';
export const EDIT_RETAILER_SUCCEEDED = 'EDIT_RETAILER_SUCCEEDED';
export const DELETE_RETAILER_REQUESTED = 'DELETE_RETAILER_REQUESTED';
export const DELETE_RETAILER_SUCCEEDED = 'DELETE_RETAILER_SUCCEEDED';

export const SET_SHIPPING_METHOD_RULE_CONSTANTS = 'SET_SHIPPING_METHOD_RULE_CONSTANTS';

export const UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM = 'UPDATE_INPUTS_DATA_EDIT_RETAILER_FORM';

export const GET_COURIER_RATES_REQUESTED = 'GET_COURIER_RATES_REQUESTED';
export const GET_COURIER_RATES_SUCCEEDED = 'GET_COURIER_RATES_SUCCEEDED';

export const UPDATE_COUNTRIES_INPUT_OPTIONS = 'UPDATE_COUNTRIES_INPUT_OPTIONS';
export const RETAILER_UPDATE_INPUTS = 'RETAILER_UPDATE_INPUTS';
export const RETAILER_REMOVE_INPUTS = 'RETAILER_REMOVE_INPUTS';
