import {
  FETCH_CATEGORY_ATTRIBUTE_SUCCESSED,
  FETCH_CATEGORY_DICTIONARY_SUCCESSED,
  RESET_CATEGORY_DICTIONARY,
  DELETE_CATEGORY_ATTRIBUTE_SUCCESSED,
  EDIT_CATEGORY_DICTIONARY_SUCCEEDED
} from './category.action';
import * as _ from 'lodash';
import { ATTRIBUTE_COMPONENT } from '../attribute.action';
import { CREATE_CATEGORY_SUCCEEDED } from '../../../admin/category/create/create.actions';
import { EDIT_CATEGORY_SUCCEEDED } from '../../category/edit/edit.actions';

export const Category = (
  state = {
    fetched: false,
    items: [],
    create: {
      created: false,
      data: {}
    },
    dictionary: {
      fetched: false,
      items: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORY_ATTRIBUTE_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_form = false;
        return item;
      });
      return { ...state, ...{ fetched: true, items: action.data } };

    case CREATE_CATEGORY_SUCCEEDED:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            state.items.push(action.data);
            return _.assign({}, state, { create: { created: true, data: {} } });
        }
      }
      return state;

    case EDIT_CATEGORY_SUCCEEDED:
      if (!_.isNil(action.component)) {
        switch (action.component) {
          case ATTRIBUTE_COMPONENT:
            // tslint:disable-next-line:no-shadowed-variable
            const item = _.find(state.items, { id: action.data.id });
            if (!_.isNil(item)) {
              item.is_show_edit_form = false;
              item.name = action.data.name;
            }
            return { ...state, ...{ items: state.items } };
        }
      }
      return state;

    case DELETE_CATEGORY_ATTRIBUTE_SUCCESSED:
      return {
        ...state,
        ...{
          items: _.filter(state.items, i => i.id !== action.data),
          dictionary: { ...state.dictionary, ...{ items: _.filter(state.dictionary.items, i => i.attribute_id !== action.data) } }
        }
      };

    case FETCH_CATEGORY_DICTIONARY_SUCCESSED:
      // tslint:disable-next-line:no-shadowed-variable
      _.map(action.data, item => {
        item.is_show_edit_dictionary_form = false;
        return item;
      });
      return _.assign({}, state, { dictionary: { fetched: true, items: action.data } });

    case EDIT_CATEGORY_DICTIONARY_SUCCEEDED:
      const item = _.find(state.dictionary.items, { id: action.data.id });
      if (!_.isNil(item)) {
        item.is_show_edit_dictionary_form = false;
        item.value = action.data.value;
      }
      return _.assign({}, state, { dictionary: { items: state.dictionary.items } });

    case RESET_CATEGORY_DICTIONARY:
      return _.assign({}, state, { dictionary: { fetched: false, items: [] } });

    default:
      return state;
  }
};
