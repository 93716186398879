export const RETAILER_USER_COMP = 'RETAILER_USER_COMP';

export const listRouter = idRetailer => {
  return ['/', 'retailer', idRetailer, 'user'];
};

export const detailRouter = (idRetailer, idCustomer) => {
  return ['/', 'retailer', idRetailer, 'user', idCustomer, 'detail'];
};

export const transactionRouterRetailer = (idRetailer, idCustomer) => {
  return ['/', 'retailer', idRetailer, 'user', idCustomer, 'transaction-history'];
};

