import { Component, OnInit } from '@angular/core';
import { Store } from '../../../store/store.module';
import { FETCH_ALL_MARKETPLACE_REQUESTED } from '../../integration/create/create.actions';
import { ATTRIBUTE_COMPONENT } from './attribute.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.scss']
})
export class AttributeComponent implements OnInit {
  public store;
  constructor(store: Store, private router: Router) {
    this.store = store.getInstance();
  }

  ngOnInit() {
    this.store.dispatch({ type: FETCH_ALL_MARKETPLACE_REQUESTED, component: ATTRIBUTE_COMPONENT });
  }
}
