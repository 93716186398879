import * as _ from 'lodash';
import { GET_ALL_COLLECTION_DATA_SUCCEEDED } from './consignment-agreement.actions';
import { combineReducers } from 'redux';
import { ConsignmentConfirmation } from './confirmation/confirmation.reducer';

export const ConsignmentAgreement = (state: any = { fetched: false, collection_data: undefined }, action) => {
  switch (action.type) {
  case GET_ALL_COLLECTION_DATA_SUCCEEDED:
    return _.assign({}, state, { collection_data: action.data, fetched: true });
  default:
    return state;
  }
};
