import {
  FETCH_ALL_ADMIN_ORDER_ITEM_SUCCESSED,
  FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_REQUESTED,
  GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_SUCCESSED,
  GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_REQUESTED,
  FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_SUCCESSED,
  FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_REQUESTED,
  FETCH_ALL_CATEGORY_ORDER_ITEM_PAGE_SUCCESSED,
  FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_SUCCESSED,
  FETCH_ALL_CONDITION_ORDER_ITEM_PAGE_REQUESTED,
  UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_REQUESTED,
  UPDATE_OPTION_FOR_DESIGNER_DROPDOWN_ORDER_ITEM_PAGE_SUCCESSED,
  ORDER_ITEM_PAGE_CANCEL_ORDER_PRODUCT_REQUESTED,
  ORDER_ITEM_PAGE_UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED,
  ORDER_ITEM_PAGE_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED,
  LIST_ORDER_ITEM_SUPPORT_PARAMS
} from './list.actions';
import * as _ from 'lodash';
import { put, takeEvery, takeLatest, select, call } from 'redux-saga/effects';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';
import { fetchAllConditions } from '../../attribute/condition/condition.saga';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { ORDER_PRODUCT_STATUS_SHIPPED_CONFIRMED, ORDER_PRODUCT_STATUS_SOLD_CONFIRMED } from '../../../../models/OrderProduct';
import { QueryParser } from '../../../../common/util/query-parser';

function* watchFetchOrderItemsRequest() {
  yield takeEvery(FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED, function* (action: any) {
    try {
      let results = yield AppInjector.get(ApiService).admin.fulfilment.get(action.data).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_ORDER_ITEM_SUCCESSED, data: results.items, pagination: results.pagination });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* getAllRetailerInOrderPage() {
  yield takeLatest(GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_REQUESTED, function* (action: any) {
    try {
      let data: any = {};
      if (!_.isNil(action.data)) {
        data.constraints = JSON.stringify(action.data);
      }
      const results = yield AppInjector.get(ApiService).user.list(data).toPromise();
      yield put({ type: GET_ALL_RETAILER_IN_ORDER_ITEM_PAGE_SUCCESSED, data: results });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* watchFetchAllMarketplaceInOrderPage() {
  yield takeEvery(FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_REQUESTED, function* (action: any) {
    try {
      const result = yield AppInjector.get(ApiService)
        .admin.user.get({ ...action.data, per_page: 10000 })
        .toPromise();
      yield put({ type: FETCH_ALL_INTEGRATED_MARKETPLACE_IN_ORDER_ITEM_PAGE_SUCCESSED, data: result.items });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* updateStatusShippingByOrderProductIdInOrderItemPageRequested() {
  yield takeLatest(ORDER_ITEM_PAGE_UPDATE_STATUS_SHIPPING_BY_ORDER_PRODUCT_ID_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.shipment.updateStatusShippingByOrderProductId(action.order_product_id, action.data).toPromise();
      yield AppInjector.get(ApiService).admin.fulfilment.update(action.order_product_id, { status: ORDER_PRODUCT_STATUS_SHIPPED_CONFIRMED }).toPromise();
      AppInjector.get(NotificationService).show('success', 'Update status shipment success', 2000);
      yield put({ type: FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED, data: AppInjector.get(QueryParser).parse(LIST_ORDER_ITEM_SUPPORT_PARAMS) });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}
function* UpdateOrderProductInOrderPageRequested() {
  yield takeLatest(ORDER_ITEM_PAGE_UPDATE_STATUS_ORDER_PRODUCT_REQUESTED, function* (action: any) {
    try {
      if (action.data.status === ORDER_PRODUCT_STATUS_SOLD_CONFIRMED) {
        const transaction = action.order_product.transaction_history;
        if (!transaction) {
          yield AppInjector.get(NotificationService).show('warning', 'Transaction is undefined', 2000);
        } else {
          const marketplace_id = transaction.data.marketplace_id;
          action.data.marketplace_id = marketplace_id;
          yield AppInjector.get(ApiService).admin.fulfilment.update(action.order_product.id, action.data).toPromise();
          yield AppInjector.get(ApiService).admin.product.setProductToSoldConfirm(action.data.product_id, action.data).toPromise();
        }
      } else {
        yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, action.data).toPromise();
      }
      AppInjector.get(NotificationService).show('success', 'Update status order item success', 2000);
      yield put({ type: FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED, data: AppInjector.get(QueryParser).parse(LIST_ORDER_ITEM_SUPPORT_PARAMS) });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

function* OrderItemPageCancelOrderProductRequested() {
  yield takeLatest(ORDER_ITEM_PAGE_CANCEL_ORDER_PRODUCT_REQUESTED, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.fulfilment.update(action.id, { cancelled_at: new Date() }).toPromise();
      yield put({ type: FETCH_ALL_ADMIN_ORDER_ITEM_REQUESTED, data: AppInjector.get(QueryParser).parse(LIST_ORDER_ITEM_SUPPORT_PARAMS) });
    } catch (error) {
      yield put({ type: API_CALL_ERROR, error: error });
    }
  });
}

export default [
  watchFetchOrderItemsRequest,
  getAllRetailerInOrderPage,
  watchFetchAllMarketplaceInOrderPage,
  OrderItemPageCancelOrderProductRequested,
  UpdateOrderProductInOrderPageRequested,
  updateStatusShippingByOrderProductIdInOrderItemPageRequested
];
