import Category from './Category';
import Status from './Status';
import Style from './Style';
import Size from './Size';
import User from './User';
import Media from './Media';
import Currency from './Currency';
import ArrayList from './ArrayList';
import OrderProduct from './OrderProduct';
import ProductBase from './Base/ProductBase';
import * as _ from 'lodash';
import ProcessImage from './ProductImage';
import Condition from './Condition';
import Color from './Color';
import ProductItem from './ProductItem';
import Tag from './Tag';
import Designer from './Designer';
import Material from './Material';
import ServiceType from './ServiceType';
import ProductModel from './ProductModel';
import Order from './Order';

interface ProductInterface {
  id: Number;
  sku: Number;
  name: any;
  description: String;
  color: any;
  size: any;
  categories: any;
  provider: any;
  designer: any;
  process_type: any;
  user: any;
  styles: any;
  destination: any;
  location: any;
  currency: any;
  images: any[];
  deleted_at: any;
  getOriginalPrice(): any;
  hasImage(): Boolean;
  getImageUrl(): String;
  isDeleted(): Boolean;
  getStatuses(): any[];
}

class Product extends ProductBase implements ProductInterface {
  images: any[];
  styles: any;
  id: Number;
  sku: Number;
  name: any;
  description: String;
  color: any;
  material: any;
  size: any;
  categories: any;
  provider: any;
  designer: any;
  process_type: any;
  currency: any;
  user: any;
  destination: any;
  location: any;
  seller: any;
  retailer: any;
  deleted_at: any;

  constructor(options) {
    super(options);
    (this as any).images = (d) => {
      return _.map(d.data, (item) => new ProcessImage(item));
    };
    (this as any).product_items = (d) => {
      return _.map(d.data, (item) => new ProductItem(item));
    };
    (this as any).categories = (d) => {
      return new ArrayList<Category>(Category, d.data);
    };
    (this as any).process_status = (d) => {
      return new Status(d.data);
    };
    (this as any).media = (d) => {
      return new ArrayList<Media>(Media, d.data);
    };
    (this as any).styles = (d) => {
      return new ArrayList<Style>(Style, d.data);
    };
    (this as any).size = (d) => {
      return new Size(d.data);
    };
    (this as any).material = (d) => {
      return new Material(d.data);
    };
    (this as any).model = (d) => {
      return new ProductModel(d.data);
    };
    (this as any).user = (d) => {
      return new User(d.data);
    };
    (this as any).currency = (d) => {
      return new Currency(d.data);
    };
    (this as any).service_type = (d) => {
      return new ServiceType(d.data);
    };
    // (this as any).order_product = d => {
    //   return new OrderProduct(d.data);
    // };
    (this as any).seller = (d) => {
      return new User(d.data);
    };
    (this as any).retailer = (d) => {
      return new User(d.data);
    };
    (this as any).condition = (d) => {
      return new Condition(d.data);
    };
    (this as any).marketplaces = (d) => {
      return _.map(d.data, (item) => new User(item));
    };
    (this as any).product_item_management = (d) => {
      return _.map(d.data, (item) => new Product(item));
    };
    (this as any).color = (d) => {
      return new Color(d.data);
    };
    (this as any).designer = (d) => {
      return new Designer(d.data);
    };
    (this as any).tags = (d) => {
      return _.map(d.data, (item) => new Tag(item));
    };

    this.bind(options);
  }
  getOriginalPrice(): any {
    if (!_.isUndefined((this as any).original_price)) {
      return Number((this as any).original_price);
    } else {
      return '';
    }
  }
  saveChanged() {
    this._backup(['destination_id', 'location_id']);
  }

  hasImage(): Boolean {
    return _.isArray(this.images) && this.images.length > 0;
  }

  getImageUrl(): String {
    if (this.hasImage()) {
      return (this as any).images[0].url;
    } else {
      return '';
    }
  }

  getImageBackgroundStyle(dimension): String {
    let url = this.getImageUrl();
    if (dimension) {
      //hack to by pass roy code to be deleted when done
      // url = url.replace('/100x100/', '/');
      let array = url.slice(8).split('/');
      array.splice(array.length - 1, 0, dimension);
      url = `https://${array.join('/')}`;
    }
    return `url("${url}")`;
  }

  isDeleted() {
    return !_.isNil(this.deleted_at);
  }

  isSavedInfo() {
    const { categories, condition, color, sku } = this as any;
    return categories.data.length > 0 && !_.isNil(condition) && !_.isNil(color) && sku && sku !== '';
  }

  isPricing() {
    const { price } = this as any;
    if (price && price.price && price.price !== 0) return true;
    return false;
  }

  getStatuses() {
    const result = [];
    if (!_.isUndefined((this as any).product_items) && !_.isEmpty((this as any).product_items)) {
      _.forEach((this as any).product_items, function (value) {
        const check = _.find(result, function (o) {
          return o.status_id === value.status_id;
        });
        if (!_.isUndefined(check)) {
          check.quantity += 1;
        } else {
          result.push({
            status_id: value.status_id,
            quantity: 1
          });
        }
      });
      return result;
    } else {
      return [];
    }
  }
}

export default Product;
