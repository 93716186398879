export const RENDER_EDIT_MARKETPLACE_FORM_REQUESTED = 'RENDER_EDIT_MARKETPLACE_FORM_REQUESTED';
export const FILL_MARKETPLACE_DETAIL_FORM = 'FILL_MARKETPLACE_DETAIL_FORM';
export const GET_MARKETPLACE_REQUESTED = 'GET_MARKETPLACE_REQUESTED';
export const GET_MARKETPLACE_SUCCEEDED = 'GET_MARKETPLACE_SUCCEEDED';
export const EDIT_ADMIN_MARKETPLACE_REQUESTED = 'EDIT_ADMIN_MARKETPLACE_REQUESTED';
export const EDIT_ADMIN_MARKETPLACE_SUCCEEDED = 'EDIT_ADMIN_MARKETPLACE_SUCCEEDED';
export const DELETE_ADMIN_MARKETPLACE_REQUESTED = 'DELETE_ADMIN_MARKETPLACE_REQUESTED';
export const DELETE_ADMIN_MARKETPLACE_SUCCESSED = 'DELETE_ADMIN_MARKETPLACE_SUCCESSED';
export const UPDATE_MARKETPLACE_INPUT_OPTIONS = 'UPDATE_MARKETPLACE_INPUT_OPTIONS';

export const RESET_MARKETPLACE_SERVICE_CONFIGURATION_REQUESTED = 'RESET_MARKETPLACE_SERVICE_CONFIGURATION_REQUESTED';
export const UPDATE_OR_CREATE_MARKETPLACE_COMMISSION_REQUESTED = 'UPDATE_OR_CREATE_MARKETPLACE_COMMISSION_REQUESTED';
export const UPDATE_OR_CREATE_MARKETPLACE_COMMISSION_SUCCESSED = 'UPDATE_OR_CREATE_MARKETPLACE_COMMISSION_SUCCESSED';
export const ADD_INPUT_INCREASE_RULE = 'ADD_INPUT_INCREASE_RULE';
