import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ServiceProvider } from '../service.provider';
import MeasurementUnit from '../../models/MeasurementUnit';

@Injectable()
export class MeasurementUnitService extends ServiceProvider {
  public model = MeasurementUnit;
  public url = '/api/v1/measurement-unit';
}
