import * as _ from 'lodash';
import { RENDER_CREATE_PERCENTAGE_FORM_REQUESTED, CREATE_PERCENTAGE_SUCCEEDED } from './create.actions';

export const create = (
  state = {
    created: false,
    inputs: [],
    item: {}
  },
  action
) => {
  switch (action.type) {
    case RENDER_CREATE_PERCENTAGE_FORM_REQUESTED:
      return _.assign({}, state, { inputs: action.data.inputs });
    case CREATE_PERCENTAGE_SUCCEEDED:
      return _.assign({}, state, { item: action.data, created: true });
    default:
      return state;
  }
};
