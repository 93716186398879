import * as _ from 'lodash';
import { support } from '@reflaunt/reactive-form';
import {
  FETCH_RETAILER_COMMISSION_REQUESTED,
  FETCH_RETAILER_COMMISSION_SUCCESSED,
  GET_COMMISSION_CONFIG_SUCCESSED,
  RENDER_COUNTRY_INPUT_FORM,
  SET_RETAILER_COMMISSIOS_CONSTANTS
} from './commission-config.action';

export const CommissionConfig = (
  state = {
    updated: false,
    fetched: false,
    inputs: [],
    retailer: null,
    user: null,
    shipping_cost_constants: null,
    retailer_services: [],
    current_service: {},
    list_currency: []
  },
  action
) => {
  switch (action.type) {
    case SET_RETAILER_COMMISSIOS_CONSTANTS:
      return _.assign({}, state, { shipping_cost_constants: action.data });
    case GET_COMMISSION_CONFIG_SUCCESSED:
      const { retailer_services } = action.data;
      const default_currency: any = _.head(state.shipping_cost_constants.currencies);
      const default_rt_service: any = _.head(retailer_services) || null;
      if (default_rt_service) {
        if (default_rt_service.rs_commissions.data && default_rt_service.rs_commissions.data.length > 0) {
          default_rt_service.rs_commissions.data.map((e, key) => {
            Object.assign(e, { key: key + 1 });
          });
        }
        default_rt_service.rs_commissions.data = _.sortBy(default_rt_service.rs_commissions.data, 'id');
      }
      return _.assign({}, state, {
        fetched: true,
        retailer: action.data.retailer,
        user: action.data,
        locations: action.locations,
        retailer_services: retailer_services,
        current_service: default_rt_service,
        list_currency: action.list_currency
      });
    case RENDER_COUNTRY_INPUT_FORM:
      return _.assign({}, state, {
        inputs: action.inputs
      });
    case FETCH_RETAILER_COMMISSION_SUCCESSED:
      const df_curency: any = _.head(state.shipping_cost_constants.currencies);
      if (action.data.rs_shipping_costs.length === 0) {
        action.data.rs_shipping_costs.push({
          key: action.data.rs_shipping_costs.length,
          from_zone_id: null,
          to_zone_id: null,
          currency_id: df_curency.id,
          cost: null,
          custom_rate: null,
          custom_average_cost: null,
          custom_cost_percentage: null
        });
      }
      if (action.data.rs_commissions.data.length > 0) {
        action.data.rs_commissions.data.map((e, key) => {
          Object.assign(e, { key: key + 1 });
        });
      }
      action.data.rs_commissions.data = _.sortBy(action.data.rs_commissions.data, 'id');
      return _.assign({}, state, {
        current_service: action.data
      });
    default:
      return state;
  }
};
