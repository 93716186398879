import { API_CALL_ERROR } from '../../../../store/action';
import { AppInjector } from '../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery } from 'redux-saga/effects';
import {
  RENDER_BULK_DISCOUNT_FORM_REQUESTED,
  UPDATE_BULK_DISCOUNT_INPUT_OPTIONS,
  BULK_DISCOUNT_PRODUCT_REQUESTED,
  DELIST_PRODUCT_DISCCOUNT_COM_REQUESTED,
  FETCH_BULK_OPERATION_DATA_REQUESTED,
  FETCH_BULK_OPERATION_DATA_SUCCEEDED
} from './bulk-discount.actions';
import { PreloaderService } from '../../../../common/services/preloader/preloader.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* fetchAllDataBulkDiscount(action) {
  let result = yield AppInjector.get(ApiService).admin.user.get({ roles: 'marketplace', includes: 'marketplace', per_page: 10000 }).toPromise();
  result = result.items;
  const optionsData = {
    marketplace_id: _.map(result, (item, key) => _.assign(item, { value: item.id, label: item.marketplace.company }))
  };
  yield put({ type: UPDATE_BULK_DISCOUNT_INPUT_OPTIONS, data: optionsData });
}

function* watchBulkDiscountFormRequested() {
  yield takeEvery(RENDER_BULK_DISCOUNT_FORM_REQUESTED, fetchAllDataBulkDiscount);
}

function* bulkDiscount(action) {
  AppInjector.get(PreloaderService).show();
  try {
    let result = yield AppInjector.get(ApiService).admin.product.bulkDiscount(action.data).toPromise();
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(NotificationService).show('success', `Bulk operation submitted`, 3000);
    AppInjector.get(Router).navigate(['admin', 'product', 'bulk-discount']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchBulkDiscountProductTagsRequested() {
  yield takeEvery(BULK_DISCOUNT_PRODUCT_REQUESTED, bulkDiscount);
}

function* delistProductsFromMarketplaces(action) {
  AppInjector.get(PreloaderService).show();
  try {
    let result = yield AppInjector.get(ApiService).admin.product.delist(action.data).toPromise();
    AppInjector.get(PreloaderService).hide();
    AppInjector.get(NotificationService).show('success', `Bulk operation submitted`, 3000);
    AppInjector.get(Router).navigate(['admin', 'product', 'bulk-discount']);
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchDelistRequested() {
  yield takeEvery(DELIST_PRODUCT_DISCCOUNT_COM_REQUESTED, delistProductsFromMarketplaces);
}

function* watchFetchBulkOperationGroupRequested() {
  yield takeEvery(FETCH_BULK_OPERATION_DATA_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).bulkOperation.get(action.data).toPromise();
      console.log(result);
      yield put({ type: FETCH_BULK_OPERATION_DATA_SUCCEEDED, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchBulkDiscountFormRequested, watchBulkDiscountProductTagsRequested, watchDelistRequested, watchFetchBulkOperationGroupRequested];
