import Model from './Model';
import User from './User';
import Currency from './Currency';
import Product from './Product';
import * as moment from 'moment';

class Negotiation extends Model {
  constructor(options) {
    super();
    (this as any).marketplace = (d) => {
      return new User(d.data);
    };
    (this as any).currency = (d) => {
      return new Currency(d.data);
    };
    this.bind(options);
  }

  getCurrencySymbol() {
    const item = this as any;
    return `${item.currency ? item.currency.symbol : ''}`;
  }

  getDisplayPrice() {
    const item = this as any;
    return `${this.getCurrencySymbol()}${item.price ? item.price : ''}`;
  }

  getMarketplaceEmail() {
    const item = this as any;
    return item.marketplace ? item.marketplace.getEmail() : '';
  }

  getProductAttribute() {
    const item = this as any;
    const product: any = new Product(item.product.data);
    return product;
  }

  getProductSku() {
    return this.getProductAttribute().getSku();
  }

  getProductId() {
    return this.getProductAttribute().getId();
  }

  getDisplayDateOfOffer() {
    const item = this as any;
    return moment(item.date_of_offer).format('DD-MM-YYYY HH:mm:ss');
  }
}

export default Negotiation;
