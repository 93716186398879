import { CREATE_ADMIN_COLLECTION_REQUESTED, CREATE_ADMIN_COLLECTION_SUCCESSED} from './create.actions';
import { Router } from '@angular/router';
import { put, takeEvery } from 'redux-saga/effects';
import { listAdminCollection } from '../collection.const';
import { API_CALL_ERROR } from '../../../../store/action';
import { ApiService } from '../../../../api/api.service';
import { AppInjector } from '../../../../app-injector';

function* watchCreateCollectionRequest() {
  yield takeEvery(CREATE_ADMIN_COLLECTION_REQUESTED, function* (action: any) {
    const router = AppInjector.get(Router);
    try {
      let result = yield AppInjector.get(ApiService).admin.collection.create(action.data).toPromise();
      yield put({ type: CREATE_ADMIN_COLLECTION_SUCCESSED, data: result });
      router.navigate(listAdminCollection());
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchCreateCollectionRequest];
