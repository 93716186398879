import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { InputBase, Dropdown } from '@reflaunt/reactive-form';
import * as _ from 'lodash';
import { listRouter } from '../product.const';
import { BaseComponent } from '../../../base.component';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { AppInjector } from '../../../../app-injector';
import { NavigationEnd, Router } from '@angular/router';
import { REMOVE_BULK_DISCOUNT_PRODUCT_REQUESTED, REMOVE_UPDATE_CURRENCY_LABEL, RENDER_REMOVE_BULK_DISCOUNT_FORM_REQUESTED } from './remove-bulk-discount.actions';

@Component({
  selector: 'app-remove-bulk-discount',
  templateUrl: './remove-bulk-discount.component.html',
  styleUrls: ['./remove-bulk-discount.component.scss']
})
export class RemoveBulkDiscountComponent extends BaseComponent implements OnInit {
  public submitted = false;
  public count;
  public product_ids;
  public pushForm;
  public listRouter = listRouter();
  public reducer = 'Admin.Product.removeBulkDiscount';
  public paramsList = ['sort', 'constraints', 'page', 'per_page', 'search'];

  constructor(private router: Router, private notification: NotificationService) {
    super();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        let data: any = this.queryParser.parse(this.paramsList);
      }
    });
  }

  ngOnInit() {
    this.init();
    let inputs: InputBase<any>[] = [
      new Dropdown({
        key: 'marketplace_id',
        label: 'marketplace',
        classes: ['col-12', 'rf-form-group', 'input--select2'],
        group_classes: ['col-12'],
        order: 1,
        onChange: this.marketplaceChange,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
    ];
    this.dispatch({
      type: RENDER_REMOVE_BULK_DISCOUNT_FORM_REQUESTED,
      data: { inputs: inputs }
    });
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    this.product_ids = productSelected ? Object.keys(productSelected) : [];
    this.count = productSelected ? Object.keys(productSelected).length : 0;
  }

  onSubmit = (form) => {
    this.submitted = true;
    const productSelected = this.store.getState().Admin.Product.list.selected_products;
    let data = _.cloneDeep(form.value);
    const product_ids = Object.keys(productSelected);
    if (form.valid && product_ids && product_ids.length > 0) {
      console.log(form.value)
      this.dispatch({
        type: REMOVE_BULK_DISCOUNT_PRODUCT_REQUESTED,
        data: { product_ids, marketplace_ids: form.value.marketplace_id.id }
      });
    } else {
      AppInjector.get(NotificationService).show('warning', 'Product cannot be empty', 3000);
      this.submitted = false;
    }
  };

  onChangeReative = (form) => {
    this.pushForm = form;
  };

  marketplaceChange = (newVal) => {
    if (newVal && !this.submitted) {
      this.store.dispatch({ type: REMOVE_UPDATE_CURRENCY_LABEL, data: newVal });
    }
  };

  mapStateToProps(state) {
    return { payload: state.Admin.Product.removeBulkDiscount };
  }

  mapDispatchToProps(dispatch) {
    return { dispatch };
  }
}
