import { PipesModule } from './../../../common/pipes/pipes.module';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from './../../../common/directives/directives.module';
import { ListComponent } from './list/list.component';
import { ProductRoutingModule } from './product-routing.module';
import { EditComponent } from './edit/edit.component';
import { DetailComponent } from './detail/detail.component';
import { CreateComponent } from './create/create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../../template/shared/shared.module';
import { ImportComponent } from './import/import.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { EventComponent } from './event/event.component';
import { AngularReactiveFormModule, UPLOAD_DIRECTIVE_HEADERS, UPLOAD_DIRECTIVE_API_URL } from '@reflaunt/reactive-form';
import * as Cookies from 'js-cookie';
import { environment } from '../../../../environments/environment';
import { ClickOutsideModule } from 'ng-click-outside';
import { AddTagComponent } from './add-tag/add-tag.component';
import { BulkPushComponent } from './bulk-push/bulk-push.component';
import { BulkDiscountComponent } from './bulk-discount/bulk-discount.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncProcessComponent } from './async-process/async-process.component';
import { ShowUserComponent } from './sold-product-modal/sold-product-modal.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ModifyDescriptionComponent } from './modify-description/modify-description.component';
import { HistoryComponent } from './history/history.component';
import { PickPictureModelComponent } from './pick-picture-modal/pick-picture-modal.component';
import { RemoveBulkDiscountComponent } from './remove-bulk-discount/remove-bulk-discount.component';
@NgModule({
  imports: [
    CommonModule,
    ProductRoutingModule,
    HttpClientModule,
    FormsModule,
    DirectivesModule,
    PipesModule,
    NgSelectModule,
    SharedModule,
    ReactiveFormsModule,
    AngularReactiveFormModule,
    SweetAlert2Module,
    ClickOutsideModule,
    NgxImageZoomModule,
    NgbModule
  ],
  providers: [
    {
      provide: UPLOAD_DIRECTIVE_HEADERS,
      useValue: {
        Authorization: 'Bearer ' + Cookies.get(environment.jwtTokenKey)
      }
    },
    {
      provide: UPLOAD_DIRECTIVE_API_URL,
      useValue: environment.apiUrl
    }
  ],
  declarations: [
    ListComponent,
    EditComponent,
    DetailComponent,
    CreateComponent,
    ImportComponent,
    EventComponent,
    AddTagComponent,
    BulkPushComponent,
    AsyncProcessComponent,
    ShowUserComponent,
    BulkDiscountComponent,
    RemoveBulkDiscountComponent,
    ModifyDescriptionComponent,
    PickPictureModelComponent,
    HistoryComponent
  ],
  entryComponents: [ShowUserComponent, PickPictureModelComponent]
})
export class ProductModule {}
