import {
  FETCH_RETAILERS_SUCCEEDED,
  FETCH_RETAILERS_REQUESTED,
  SORT_RETAILERS_REQUESTED,
  SORT_RETAILERS_SUCCEEDED,
  CHANGE_USER_STATUS_REQUESTED,
  RETAILER_SYNC_PRODUCTS_REQUEST
} from './list.actions';
import { API_CALL_ERROR } from './../../../../store/action';
import { AppInjector } from './../../../../app-injector';
import * as _ from 'lodash';
import { ApiService } from '../../../../api/api.service';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { NotificationService } from '../../../../common/services/notification/notification.service';

function* getRetailers(action) {
  const api = AppInjector.get(ApiService);
  try {
    let data: any = {};
    let sorts = [];
    // tslint:disable-next-line:forin
    for (const key in action.data.pagination) {
      data[key] = action.data.pagination[key];
    }
    if (_.isNil(data.page)) {
      data.page = 1;
    }
    if (_.isNil(data.per_page)) {
      data.per_page = 100;
    }
    if (!_.isNil(action.data.filter)) {
      data.constraints = JSON.stringify(action.data.filter);
    }
    if (!_.isNil(action.data.sort)) {
      for (const key in action.data.sort) {
        if (!_.isNil(action.data.sort[key])) {
          sorts.push(action.data.sort[key] + key);
        }
      }
    }
    if (sorts.length !== 0) {
      data.sort = _.join(sorts, ',');
    }
    data.includes = 'retailer,service_configurations';
    const results = yield AppInjector.get(ApiService).user.get(data).toPromise();
    yield put({ type: FETCH_RETAILERS_SUCCEEDED, data: results.items, pagination: results.pagination, sortQueryParams: action.data.sortQueryParams });
  } catch (e) {
    yield put({ type: API_CALL_ERROR, error: e });
  }
}

function* watchFetchRetailersRequest() {
  yield takeEvery(FETCH_RETAILERS_REQUESTED, getRetailers);
}

function* watchChangeUserStatusRequested() {
  yield takeLatest(CHANGE_USER_STATUS_REQUESTED, function* (action: any) {
    try {
      let result = yield AppInjector.get(ApiService).admin.user.changeStatus(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Status updated', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchRetailerSyncProductRequest() {
  yield takeLatest(RETAILER_SYNC_PRODUCTS_REQUEST, function* (action: any) {
    try {
      yield AppInjector.get(ApiService).admin.product.syncProducts(action.data).toPromise();
      AppInjector.get(NotificationService).show('success', 'Synchronize success', 3000);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchFetchRetailersRequest, watchChangeUserStatusRequested, watchRetailerSyncProductRequest];
