import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CREATE_NEW_MEASUREMENT_UNIT_REQUESTED } from './create.action';
import { Store } from '../../../store/store.module';
import { AppInjector } from '../../../app-injector';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public selectedOption;
  public roles;
  public createRoleUser;
  public showValid: Boolean = false;
  public idRole;
  public measurementUnit;
  constructor() { }

  ngOnInit() {
    this.measurementUnit = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  get name() {
    return this.measurementUnit.get('name');
  }

  onSubmit(form) {
    if (!form.valid) {
      this.showValid = true;
      return;
    }
    this.showValid = false;
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({ type: CREATE_NEW_MEASUREMENT_UNIT_REQUESTED, data: form.value });
  }
}
