import { select, put, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';
import listSaga from './list/list.saga';
import editSaga from './edit/edit.saga';
import createSaga from './create/create.saga';
import { ApiService } from '../../../api/api.service';
import { API_CALL_ERROR } from '../../../store/action';
import { AppInjector } from '../../../app-injector';

export default [...listSaga, ...editSaga, ...createSaga];
