import { API_CALL_ERROR } from './../../../../store/action';
import { ApiService } from './../../../../api/api.service';
import { AppInjector } from './../../../../app-injector';
import { takeEvery, put } from 'redux-saga/effects';
import * as _ from 'lodash';
import {
  GET_NOTIFICATION_ACTION_REQUESTED,
  EDIT_NOTIFICATION_ACTION_REQUESTED,
  GET_NOTIFICATION_ACTION_SUCCEEDED,
  RENDER_UPDATE_NOTIFICATION_FORM_REQUESTED
} from './edit.actions';
import { Router } from '@angular/router';

function* watchGetNotificationActionRequested() {
  yield takeEvery(GET_NOTIFICATION_ACTION_REQUESTED, function*(action: any) {
    try {
      let result = yield AppInjector.get(ApiService)
        .admin.notificationAction.getItemById(action.data)
        .toPromise();
      yield put({ type: GET_NOTIFICATION_ACTION_SUCCEEDED, data: result });
      yield put({ type: RENDER_UPDATE_NOTIFICATION_FORM_REQUESTED, data: action.inputs });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchEditNotificationActionRequested() {
  yield takeEvery(EDIT_NOTIFICATION_ACTION_REQUESTED, function*(action: any) {
    try {
      yield AppInjector.get(ApiService)
        .admin.notificationAction.update(action.id, action.data)
        .toPromise();
      AppInjector.get(Router).navigate(['/', 'admin', 'configuration']);
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetNotificationActionRequested, watchEditNotificationActionRequested];
