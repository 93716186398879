import { put, takeLatest } from 'redux-saga/effects';
import { AppInjector } from '../../../../app-injector';
import { ApiService } from '../../../../api/api.service';
import { API_CALL_ERROR } from '../../../../store/action';
import { NORMAL_GROUP_TYPE, GROUP_GROUP_TYPE } from '../../../../models/Dataset';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { listGroupRouter } from '../dataset.const';
import {
  ADD_DATASET_TO_GROUP_REQUEST,
  ADD_DATASET_TO_GROUP_SUCCESS,
  ADD_TO_GROUP_GET_LIST_GROUP_DATASET_REQUEST,
  ADD_TO_GROUP_GET_LIST_GROUP_DATASET_SUCCESS
} from './add-to-group.actions';
import { Router } from '@angular/router';

function* watchGetListGroupDatasetRequest() {
  yield takeLatest(ADD_TO_GROUP_GET_LIST_GROUP_DATASET_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    try {
      const result = yield api.dataset.list({ constraints: { group_type: GROUP_GROUP_TYPE } }).toPromise();
      yield put({ type: ADD_TO_GROUP_GET_LIST_GROUP_DATASET_SUCCESS, data: result });
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

function* watchAddDatasetToGroupRequest() {
  yield takeLatest(ADD_DATASET_TO_GROUP_REQUEST, function* (action: any) {
    const api = AppInjector.get(ApiService);
    const router = AppInjector.get(Router);
    const notification = AppInjector.get(NotificationService);
    const { groupId, payload, datasetId } = action.data;
    try {
      const result = yield api.dataset.attachToGroup(groupId, payload).toPromise();
      yield put({ type: ADD_DATASET_TO_GROUP_SUCCESS, data: result });
      notification.show('success', 'Successfully add dataset to group', 3000);
      router.navigate(listGroupRouter(datasetId));
    } catch (e) {
      yield put({ type: API_CALL_ERROR, error: e });
    }
  });
}

export default [watchGetListGroupDatasetRequest, watchAddDatasetToGroupRequest];
