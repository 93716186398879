import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnalyticComponent } from './analytic/analytic.component';
import { ResaleComponent } from './resale/resale.component';
import { SecondHandSalesComponent } from './second-hand-sales/second-hand-sales.component';
import { RepurchaseComponent } from './repurchase/repurchase.component';
import { AcquisitionComponent } from './acquisition/acquisition.component';
import { EndpointComponent } from './endpoint/endpoint.component';
import { CredentialComponent } from '../credential/credential.component';
import { SmartButtonComponent } from './smart-button/smart-button.component';
import { RETAILER_COMP } from './retailer.const';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: AnalyticComponent
      },
      {
        path: 'resale',
        component: ResaleComponent
      },
      {
        path: 'second-hand-sales',
        component: SecondHandSalesComponent
      },
      {
        path: 'repurchase',
        component: RepurchaseComponent
      },
      {
        path: 'acquisition',
        component: AcquisitionComponent
      },
      {
        path: ':id/product',
        loadChildren: () => import('./product/retailer-product.module').then(m => m.RetailerProductModule)
      },
      {
        path: ':id/user',
        loadChildren: () => import('./user/retailer-user.module').then(m => m.RetailerUserModule)
      },
      {
        path: 'dataset',
        loadChildren: () => import('./dataset/dataset.module').then(m => m.DatasetModule)
      },
      {
        path: 'email',
        loadChildren: () => import('../admin/email/email.module').then(m => m.EmailModule)
      },
      {
        path: 'percentage',
        loadChildren: () => import('./percentage/percentage.module').then(m => m.PercentageModule)
      },
      {
        path: 'endpoint',
        component: EndpointComponent
      },
      {
        path: 'credential',
        component: CredentialComponent,
        data: {
          comp: RETAILER_COMP
        }
      },
      {
        path: 'smart-button',
        component: SmartButtonComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetailerRoutingModule {}
