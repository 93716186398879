export const FETCH_SHIPMENT_REQUESTED = 'FETCH_SHIPMENT_REQUESTED';
export const FETCH_SHIPMENT_SUCCEEDED = 'FETCH_SHIPMENT_SUCCEEDED';
export const UPDATE_OPTION_FOR_SHIPPING_FEE_REQUESTED = 'UPDATE_OPTION_FOR_SHIPPING_FEE_REQUESTED';
export const UPDATE_INPUT_FILTER_DATE = 'UPDATE_INPUT_FILTER_DATE';
export const SHIPMENT_FETCH_ALL_COURIERS_REQUESTED = 'SHIPMENT_FETCH_ALL_COURIERS_REQUESTED';
export const SHIPMENT_FETCH_ALL_COURIERS_SUCCESSED = 'SHIPMENT_FETCH_ALL_COURIERS_SUCCESSED';

export const PENDING_STATUS_SHIPMENT = 'pending';
export const CREATED_STATUS_SHIPMENT = 'created';
export const CANCELED_STATUS_SHIPMENT = 'canceled';
export const PICKED_STATUS_SHIPMENT = 'completed';



export const SUPPORT_PARAMS = [
  'sort',
  'constraints',
  'page',
  'per_page',
  'search',
  'shipment_type',
  'date_filter_type',
  'start_date',
  'end_date',
  'shipping_courier',
  'from_warehouse',
  'shipment_status',
  'price_filter_type',
  'max_price',
  'min_price',
  'shipment_id',
  'tracking_number',
  'label'
];
